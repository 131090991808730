import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from '@bty/react-auth'
import { Tooltip } from 'antd'
import dayjs from 'dayjs'
import { RoleCode } from '@apis/authority/type'
import { IconFont, Progress } from '@/components'
import { useVersionStore, useWorkspaceStore } from '@/store'
import {
  POINTS_CONSUME_DESC,
  POINTS_CONSUME_NAME,
} from '@/constants/commercialization'
import {
  ReminderRemainingDays,
  useLimitedModal,
  VersionStatus,
} from '@/features/pay'

export function PointsPanel(props: { className: string; collapsed: boolean }) {
  const { collapsed } = props
  const { access } = useAuth()
  const history = useNavigate()
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const versionInfo = useVersionStore(state => state.versionInfo)
  const [levelupModal] = useLimitedModal()
  const isExpire = useMemo(() => {
    return (
      versionInfo?.status === VersionStatus.LoseEffect ||
      dayjs().isAfter(dayjs(versionInfo?.lose_effect_time))
    )
  }, [versionInfo])
  const isPointExhausted = useMemo(() => {
    return (
      versionInfo &&
      versionInfo.points_consume_num +
        (versionInfo.dosage_package_points_consume_num || 0) >=
        versionInfo.points_count_limit +
          (versionInfo.dosage_package_points_count_total || 0)
    )
  }, [versionInfo])
  const percent = useMemo(() => {
    if (!versionInfo) return 0
    const {
      dosage_package_points_consume_num,
      dosage_package_points_count_total,
      points_consume_num,
      points_count_limit,
    } = versionInfo
    return (
      ((points_consume_num + dosage_package_points_consume_num) * 100) /
      (points_count_limit + dosage_package_points_count_total)
    )
  }, [versionInfo])

  const { currentNum, totalNum } = useMemo(() => {
    if (!versionInfo)
      return {
        currentNum: 0,
        totalNum: 0,
      }
    return {
      currentNum:
        versionInfo.dosage_package_points_consume_num +
        versionInfo.points_consume_num,
      totalNum:
        versionInfo.dosage_package_points_count_total +
        versionInfo.points_count_limit,
    }
  }, [versionInfo])

  if (!access.role([RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER])) {
    return null
  }

  return collapsed ? (
    <div
      className={`${props.className} cursor-pointer`}
      onClick={() => history(`/workspace/${workspaceId}/settings/overview`)}
    >
      <Progress
        type='circle'
        size={48}
        format={() => (
          <div className='w-24px mx-auto text-center text-12px text-#17171d scale-83.333'>
            Yeah积分
          </div>
        )}
        percent={percent}
        trailColor='rgba(98, 105, 153, 0.12)'
        strokeColor={{ '0%': '#7B61FF', '100%': '#678AFF' }}
        strokeWidth={12.5}
      />
    </div>
  ) : (
    <div
      className={`${props.className} border-rd-8px bg-#fff bg-op-40 p-12px b-1 b-#fff b-op-60`}
    >
      <div
        className='flex flex-items-center justify-between c-font_1 cursor-pointer'
        onClick={() => history(`/workspace/${workspaceId}/settings/overview`)}
      >
        <div className='flex items-end gap-4px text-#17171d text-12px'>
          {POINTS_CONSUME_NAME}
          <Tooltip title={POINTS_CONSUME_DESC}>
            <IconFont
              name='jieshishuimeng'
              className='text-12px cursor-pointer text-#626999 text-opacity-32'
            />
          </Tooltip>
        </div>
        <div className='flex flex-items-center justify-between gap-4px text-12px font-300 text-#17171d'>
          {isPointExhausted ? (
            <span className='color-#FF5219 font-400'>积分已耗尽</span>
          ) : (
            <>
              {currentNum}/{totalNum}
            </>
          )}
          <IconFont
            name='left-arrow'
            className='text-#626999 text-8px c-op-60'
          />
        </div>
      </div>
      <div className='relative mt-2px'>
        <Progress
          className='flex'
          showInfo={false}
          type='line'
          size={['100%', 6]}
          percent={percent}
          trailColor='rgba(98, 105, 153, 0.12)'
          strokeColor={{ '0%': '#7B61FF', '100%': '#678AFF' }}
        />
        <div className='line-height-12px font-size-12px color-#8D8D99'>
          <span className='mt-12px inline-block inline-flex items-center'>
            {versionInfo?.product_name && (
              <span
                className='px-4px py-3px rounded-4px text-12px/12px font-500 c-#5F5BFF mr-4px cursor-pointer'
                style={{
                  background:
                    'linear-gradient(112deg, #EAD9FF 3%, #C5E2FF 100%)',
                }}
                onClick={() => levelupModal.open()}
              >
                {versionInfo?.product_name}
              </span>
            )}
            {isExpire ? (
              <span>空间已过期</span>
            ) : (
              versionInfo?.days_remaining &&
              (versionInfo?.days_remaining <= ReminderRemainingDays ? (
                <span>
                  <span className='color-#7B67EE'>
                    {versionInfo.days_remaining}
                  </span>
                  天后到期
                </span>
              ) : (
                <>
                  {dayjs(versionInfo?.lose_effect_time).format('YYYY-MM-DD')}
                  到期
                </>
              ))
            )}
          </span>
        </div>
      </div>
    </div>
  )
}
