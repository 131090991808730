import { DocumentType } from '@apis/datastore/model'
import ErrorImage from '@/assets/dataset/error.png'
import { Button } from '@/components'

interface ErrorProps {
  onRetry: () => void
  file_type?: DocumentType
}
export default function Error(props: ErrorProps) {
  const { onRetry, file_type } = props

  const isWebPage = file_type === DocumentType.WEBPAGE

  const onFailBack = () => {
    if (isWebPage) {
      window.open('/chrome-plugin', '_blank')
    } else {
      onRetry()
    }
  }

  return (
    <div className='w-full h-full flex-center bg-bg'>
      <div className='flex-center flex-col'>
        <img src={ErrorImage} alt='' className='w-117px' />
        <p className='mt-25 c-bg_3 c-op-48'>
          {isWebPage
            ? '该网址无法解析，请使用知识库插件'
            : '文档解析失败，请重试'}
        </p>
        <Button
          type='primary'
          size='large'
          onClick={onFailBack}
          className='w-122px mt-25'
        >
          {isWebPage ? '知识库插件' : '重试'}
        </Button>
      </div>
    </div>
  )
}
