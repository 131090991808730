import type { Node } from 'reactflow'
import MarkdownIt from 'markdown-it'
import { isString, isUndefined, omit } from 'lodash-es'
import type { ActionTypesForNode, FlowNode } from '@apis/flow/type'
import { getNodeMetaByType } from '@/features/nodes'
import { NodeType } from '@/features/nodes/base'
import { convertOldCodeInput } from '@/features/nodes/code/const'

export function convertFlowTypeToNodeType(
  type: NodeType,
  item: FlowNode,
): NodeType {
  const isMemoryNode = [NodeType.INSERT_MEMORY, NodeType.MEMORY].includes(
    type as NodeType,
  )
  if (isMemoryNode) {
    return NodeType.KNOWLEDGE
  }
  const isCodeNode = type === NodeType.CODE
  if (isCodeNode) {
    if (item.data?.inputs?.language === 'python') {
      return NodeType.PYTHON
    } else if (item.data?.inputs?.language === 'javascript') {
      return NodeType.JAVASCRIPT
    }
  }
  return type
}

export function convertFlowNodesToRenderNodes(nodes: FlowNode[]): Node[] {
  return nodes.map(item => {
    const { id, name, type, data, layout, actionType, relation, isEnable } =
      item
    const { width, height, position } = layout

    const isMemoryNode = [NodeType.INSERT_MEMORY, NodeType.MEMORY].includes(
      type as NodeType,
    ) // 兼容旧数据

    const isCodeNode = type === NodeType.CODE
    return {
      id,
      type: convertFlowTypeToNodeType(type as NodeType, item),
      position,
      hidden: false,
      data: {
        ...data,
        isEnable,
        relation,
        actionType,
        name,
        inputs: {
          ...data.inputs,
          ...(isMemoryNode // 兼容旧数据
            ? {
                memoryType: {
                  [NodeType.INSERT_MEMORY]: 'insertMemory',
                  [NodeType.MEMORY]: 'searchMemory',
                }[type],
              }
            : {}),
          ...(isCodeNode && !data.inputs?.version // 兼容旧数据
            ? {
                code: convertOldCodeInput(
                  data.inputs.code,
                  data.inputs.language,
                ),
                version: 1,
              }
            : {}),
        },
        ...(type === 'START' ? { formConfig: data.formConfig } : {}),
      },
      width,
      height,
      zIndex: 20,
    }
  })
}

export function convertRenderNodesToFlowNodes(nodes: Node[]): FlowNode[] {
  return nodes.map(item => {
    const { id, type: typeFromItem, position, width, height, data = {} } = item
    let type = typeFromItem
    let actionType = getNodeMetaByType(type as any)
      ?.actionType as ActionTypesForNode
    // 暂时特殊处理
    if (type === NodeType.PLUGIN) {
      actionType = data.actionType
    }
    if ([NodeType.PYTHON, NodeType.JAVASCRIPT].includes(type as NodeType)) {
      type = NodeType.CODE
    }
    return {
      id,
      isEnable: isUndefined(data.isEnable) ? true : data.isEnable,
      type: type!,
      actionType,
      name: data.name,
      relation: data.relation || null,
      layout: {
        width: width!,
        height: height!,
        position,
      },
      data: {
        ...omit(
          data,
          'handleState',
          'toBeRemoved',
          'handleStatus',
          'updateHandleId',
        ),
        packageName: data.packageName || '',
        type: data.type || null,
        inputs: data.inputs || {},
        formConfig: data.formConfig || null,
        conditions: data.conditions || [],
      },
    }
  })
}

export function isMarkdown(text: string) {
  const md = new MarkdownIt()
  try {
    md.parse(text, {})
    return true
  } catch (error) {
    return false
  }
}

export function isEmptyString(str: any) {
  return isString(str) && !str.trim()
}

export function isJSONString(text: string) {
  try {
    JSON.parse(text)
    return true
  } catch (error) {
    return false
  }
}
