import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import {
  getDatasetTags,
  getDocumentList,
  getDataStoreList,
} from '@apis/datastore'
import type { DataStoreItem, DocumentItem } from '@apis/datastore/type'

import type {
  IPluginItem,
  ISubFlowItem,
  ISupportFileConfig,
  ITemplateItem,
} from '@apis/flow/type'
import {
  fetchSubFlowList,
  fetchPluginList,
  getStartNodeSupportFileConfig,
  fetchTemplateList,
} from '@apis/flow'

interface NodeMetaStore {
  datasetList: DataStoreItem[]
  templateList: ITemplateItem[]
  subFlowList: ISubFlowItem[]
  pluginList: IPluginItem[]
  supportFileConfigList: ISupportFileConfig[]
  initNodeMetaData: () => Promise<void>
  datasetTagsMap: Record<
    DataStoreItem['partition_id'],
    Array<{ tag: string; file_num: number }>
  >
  datasetDocumentsMap: Record<DataStoreItem['partition_id'], DocumentItem[]>
  getDatasetTags: (
    id: DataStoreItem['partition_id'],
    force?: boolean,
  ) => Promise<Array<{ tag: string; file_num: number }>>
  getDatasetTagsLoadingMap: Record<DataStoreItem['partition_id'], boolean>
  fetchSubFlowListByFlowId: (flowId: string) => void
  fetchPluginListByFlowId: (flowId: string) => void
  fetchTemplateListByFlowId: () => void
  getDatasetDocuments: (
    id: DataStoreItem['partition_id'],
    force?: boolean,
  ) => void
  getDatasetDocumentsLoadingMap: Record<DataStoreItem['partition_id'], boolean>
}

export const useNodeMetaStore = create<NodeMetaStore>((set, get) => ({
  datasetList: [],
  subFlowList: [],
  pluginList: [],
  templateList: [],
  datasetTagsMap: {},
  supportFileConfigList: [],
  datasetDocumentsMap: {},
  getDatasetDocumentsLoadingMap: {},
  getDatasetTagsLoadingMap: {},
  initNodeMetaData: async () => {
    const [datastoreListRes, supportFileConfigRes] = await Promise.all([
      getDataStoreList(),
      getStartNodeSupportFileConfig(),
    ])
    set({
      datasetList: datastoreListRes.partitions ?? [],
      supportFileConfigList: supportFileConfigRes ?? [],
    })
  },
  fetchSubFlowListByFlowId: async (flowId: string) => {
    fetchSubFlowList(flowId).then(res => {
      set({ subFlowList: res ?? [] })
    })
  },
  fetchPluginListByFlowId: async (flowId: string) => {
    fetchPluginList(flowId).then(res => {
      set({ pluginList: res ?? [] })
    })
  },
  fetchTemplateListByFlowId: async () => {
    fetchTemplateList().then(res => {
      set({ templateList: res ?? [] })
    })
  },
  getDatasetTags: async (
    id: DataStoreItem['partition_id'],
    force?: boolean,
  ) => {
    const store = get()
    const datasetTagsMap = store.datasetTagsMap
    const getDatasetTagsLoadingMap = store.getDatasetTagsLoadingMap
    if ((force || !datasetTagsMap[id]) && !getDatasetTagsLoadingMap[id]) {
      set({
        getDatasetTagsLoadingMap: {
          ...get().getDatasetTagsLoadingMap,
          [id]: true,
        },
      })
      const tags = await getDatasetTags(id)
      set({
        datasetTagsMap: { ...get().datasetTagsMap, [id]: tags },
        getDatasetTagsLoadingMap: {
          ...get().getDatasetTagsLoadingMap,
          [id]: false,
        },
      })
      return tags
    }
    return datasetTagsMap[id]
  },
  getDatasetDocuments: async (
    id: DataStoreItem['partition_id'],
    force?: boolean,
  ) => {
    const store = get()
    const datasetDocumentsMap = store.datasetDocumentsMap
    const getDatasetDocumentsLoadingMap = store.getDatasetDocumentsLoadingMap
    if (
      (force || !datasetDocumentsMap[id]) &&
      !getDatasetDocumentsLoadingMap[id]
    ) {
      set({
        getDatasetDocumentsLoadingMap: {
          ...get().getDatasetDocumentsLoadingMap,
          [id]: true,
        },
      })
      const documents = await getDocumentList({ partitionId: id })
      set({
        datasetDocumentsMap: { ...get().datasetDocumentsMap, [id]: documents },
        getDatasetDocumentsLoadingMap: {
          ...get().getDatasetDocumentsLoadingMap,
          [id]: false,
        },
      })
    }
  },
}))

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('nodeMetaStore', useNodeMetaStore)
}
