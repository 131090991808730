import { memo, useEffect, useMemo, useRef, useState } from 'react'
import type { ChatProps } from '@bty/chat-ui'
import { MessageInput } from '@bty/chat-ui'
import { useMemoizedFn } from 'ahooks'
import type { RcFile } from 'rc-upload/lib/interface'
import { message, Tooltip } from 'antd'
import type { ApplicationBodyType } from '@apis/application/type'
import { useUploadFileToOss } from '@/hooks/useOssUpload'
import { IconFont } from '@/components'
import { useAgentDiffStore } from '@/store/agentDiffStore'

const DEFAULT_ACCEPT_FILE_SUFFIX = ['.txt', '.pdf', '.doc', '.docx']

function isSupportedFileType(
  file: File,
  supportedExtensions: string[],
): boolean {
  // 获取文件的MIME类型和扩展名
  // const mimeType: string = file.type
  if (supportedExtensions.includes('*')) {
    return true
  }
  const extension: string =
    `.${file.name.split('.').pop()?.toLowerCase()}` || ''

  // 检查文件扩展名是否在支持的列表中
  if (supportedExtensions.includes(extension)) {
    return true
  }

  return false
}

interface AgentDiffInputProps {
  config: Exclude<ApplicationBodyType['config'], string>
  onReNew: () => Promise<void>
  uploadFileConfig: {
    fileAccepts: string[]
    allowFileTypeNameList: string[]
    acceptsAllFileTypes?: boolean
    addFileProcessingPlugin?: () => void
  }
}

export const AgentDiffInput = memo((props: AgentDiffInputProps) => {
  const { config, onReNew, uploadFileConfig } = props

  const { uploadFileToOssApi } = useUploadFileToOss()
  const checkDiffConfigs = useAgentDiffStore(s => s.checkDiffConfigs)
  const publishInput = useAgentDiffStore(s => s.publishMessage)
  const subscribeChange = useAgentDiffStore(s => s.subscribeChange)
  const generating = useAgentDiffStore(s => s.generating)
  const publishStop = useAgentDiffStore(s => s.publishStop)

  const inputRef = useRef<any>(null)
  const [value, setValue] = useState('')

  const enabledSkills = useMemo(() => {
    const utility = config?.utility || []
    const flows = config?.flows || []
    const utilitySkills = utility
      .filter((item: any) => {
        return item.is_enable
      })
      .map((item: any) => {
        return item.name
      })
    const flowSkills = flows
      .filter((item: any) => {
        return item?.is_enable
      })
      .map((item: any) => {
        return item.app_id
      })

    return [...utilitySkills, ...flowSkills]
  }, [config?.utility, config?.flows])

  const fileAccept = useMemo(() => {
    const accept_file_type = DEFAULT_ACCEPT_FILE_SUFFIX
    if (enabledSkills.length) {
      return ['*']
    }
    return accept_file_type
  }, [enabledSkills])

  const handleBeforeFileUpload = useMemoizedFn((file: RcFile) => {
    if (!isSupportedFileType(file, fileAccept)) {
      return {
        success: false,
        message: '文件类型不支持',
      }
    }
    return true
  })

  const handleFileUpload = useMemoizedFn(
    async (params: Record<string, any>) => {
      const res = await uploadFileToOssApi(params)
      return {
        success: true,
        data: res,
      }
    },
  )

  const handleBeforeSubmit = useMemoizedFn(() => {
    const generateMap = useAgentDiffStore.getState().generateMap
    if ([...generateMap.values()].some(e => !!e)) {
      message.warning('运行中，请稍后再试')
      return false
    }

    const configReady = checkDiffConfigs()
    if (!configReady) {
      message.error('请先选择模型')
    }
    return configReady
  })

  const handleSubmit: ChatProps['onSubmit'] = useMemoizedFn(
    async (content: string, options) => {
      publishInput(content, options?.file)
    },
  )

  useEffect(() => {
    return subscribeChange((data: string, file: any) => {
      setValue(data)
      inputRef.current?.setFile(file)
      inputRef.current?.input.focus()
    })
  }, [])

  return (
    <div className='absolute bottom-24px w-full h-72px flex-none flex items-end box-content'>
      <div
        className='pt-14px px-24px w-full z-10 flex items-end'
        style={{
          backgroundImage:
            'linear-gradient(0deg, rgba(247, 247, 250, 1) 90%, rgba(247, 247, 250, 0) 100%)',
        }}
      >
        <Tooltip title='新会话'>
          <div
            className='w-56px h-56px rounded-12px flex flex-center bg-#fff mr-12px cursor-pointer hover:bg-#f3f3f7'
            style={{
              border: '1px solid  rgba(225, 225, 229, 0.6)',
              boxShadow: '0px 2px 4px 0px rgba(23, 26, 29, 0.0784)',
            }}
            onClick={onReNew}
          >
            <IconFont
              className='text-20px text-[rgba(98,105,153,0.6)]'
              name='xinhuihua'
            />
          </div>
        </Tooltip>

        <MessageInput
          ref={inputRef}
          className='w-full min-h-56px [&_.chat-input-wrapper]:min-h-20px [&_.chat-input-wrapper]:py-9px rounded-12px'
          style={{
            boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.08)',
          }}
          generating={generating}
          actions={['stop']}
          value={value}
          onChange={setValue}
          onBeforeSubmit={handleBeforeSubmit}
          onActionClick={action => {
            action === 'stop' && publishStop()
          }}
          onSubmit={handleSubmit}
          inputTips={['enter', 'shift + enter']}
          fileAccept={fileAccept}
          enabledSkills={enabledSkills}
          onBeforeFileUpload={handleBeforeFileUpload}
          onFileUpload={handleFileUpload}
          uploadFileConfig={{
            ...uploadFileConfig,
            acceptsAllFileTypes: true,
          }}
        />
      </div>
    </div>
  )
})
