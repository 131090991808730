import type { PartitionCategoryType } from '../datastore/type'

export type AgentId = string

export type AgentRunStatus = 'SUCCEEDED' | 'FAILED'
export type AgentRunType =
  | 'AGENT_TESTING'
  | 'MULTIPLE_MODELS'
  | 'CHATBOT'
  | 'AGENT'
  | 'AGENT_OAPI'
  | 'COPILOT'
  | 'DINGTALK_STREAM_ROBOT'
  | 'DINGTALK_ORGAPP_ROBOT'
  | 'WEIXIN_KF'
  | 'WEIXIN_MP'
  | 'WEIXIN_WORK_APP'
  | 'FEISHU_APP_BOT'

export interface AgentLogParams {
  agent_id: string
  user_name?: string
  run_status?: AgentRunStatus
  run_type?: AgentRunType
  run_types?: AgentRunType[]
  page_no: number
  page_size: number
  start_time?: string
  end_time?: string
  application_id?: string
}

export interface AgentChatParams {
  robot_id: string
  user_name?: string
  record_status?: AgentRunStatus
  run_type?: string[]
  page_no: number
  page_size: number
  start_time?: string
  end_time?: string
  application_id?: string
}

export interface AgentLogItem {
  conversation_id: string
  agent_id: string
  title: string
  latest_create_time: string
  total_records: number
  run_status: AgentRunStatus
  run_type: AgentRunType
  user_name: string | null
  user_id: number
  total_upgrade_consume: number
  client_type: 'PC' | 'MOBILE'
}

export interface AgentLogsResponse {
  data: AgentLogItem[]
  total: number
  page_no: number
  page_size: number
}

export interface AgentLogDetailParams {
  conversation_id: string
  agent_id: string
  page_no: number
  page_size: number
  application_id?: string
}

export interface AgentLogDetailItem {
  create_time: string
  file_name: string
  file_type: string
  record_status: AgentRunStatus
  response: string | null
  response_ext: any
  session_id: string
  user_content: string
}

export interface AgentLogDetailResponse {
  data_list: AgentLogDetailItem[]
  page_no: number
  page_size: number
  total_count: number
  total_pages: number
}

export interface AgentDataset {
  description: string
  user_description?: string
  id: string
  dataset_id: string
  num: number
  threshold: number
  is_enable: boolean
  partition_id: number
  partition_icon_color: string
  partition_icon: string
  partition_name: string
  partition_category: PartitionCategoryType
  partition_file_count: number
  file_num: number
  hit_strategy: number
  ranking_strategy: number
  show_knowledge_url: boolean
  query_mode?: {
    mode: 'AI' | 'FORCE'
    desc?: string
    tag: boolean
  }
  similarity: number
  random_result: boolean
}

export interface AgentDatasetRequest {
  flow_id: string
  version_id: string
  partition_id: number
  user_description?: string
  description: string
  is_enable: boolean
  num: number
  threshold: number
  hit_strategy: number
  ranking_strategy: number
  show_knowledge_url: boolean
}

export enum AgentFeedbackStatusEn {
  Processed = '已处理',
  Pending = '待处理',
  Ignored = '不处理',
}

export interface AgentChatFeedbackParams {
  app_id: string
  status?: AgentFeedbackStatusEn
  start_time: string
  end_time: string
  content?: string
  response?: string
  user_opinion?: string
  page_no?: number
  page_size?: number
}

export interface AgentChatFeedbackItem {
  content: string
  created_at: string
  created_by: number
  creator_name: string
  feedback_id: string
  handle_notes?: string
  reason_type: string[]
  record_id: string
  response: string
  run_type: string
  status: AgentFeedbackStatusEn
  updated_at: string
  updated_by: number
  updater_name: string
  user_opinion: string
}

export interface BindDatabasePayload {
  flow_id: string
  version_id: string
  database_id: string
  source_id: string
}

export interface EnableDatabasePayload {
  flow_id: string
  version_id: string
  tool_id: string
  enabled: boolean
}

export interface DeleteTableForAgentPayload {
  database_id: string
  table_id: string
}

export interface GetAgentConfigByTypePayload {
  robot_id: string
  run_type: string
}

export interface GetAgentConfigByTypeRes {
  conversation_id: string
  config: {
    index: number
    title_suffix: string
    model_config: {
      model: string
      channel: string
      temperature?: number
      top_p?: number
      presence_penalty?: number
      frequency_penalty?: number
      prompt_plugins?: { time_prompt?: boolean; length_prompt?: number }
    }
  }
}

export interface BindTableForAgentPayload {
  flow_id: AgentId | null
  version_id: string | null
  database_id: string
  table_id: string
  source_id: string
}
