import { useState, useEffect } from 'react'
import OSS from 'ali-oss'
import { getSTSToken, getDatasetSTSToken } from '@apis/oss'
import OssSingleton from './OssSingleton'

export interface IOSSClientProps {
  business?: 'main' | 'dataset'
}
export function useOSSClient(props?: IOSSClientProps) {
  const business = props?.business || 'main'
  const [ossClient, setOssClient] = useState<OSS>()
  const refreshSTSTokenInterval = 10 * 60 * 1000 // 10 minutes

  const fetchOssStsTokenOptions = async () => {
    const apiFn = business === 'dataset' ? getDatasetSTSToken : getSTSToken
    const res = await apiFn()
    return {
      region: 'oss-cn-hangzhou',
      stsToken: res.security_token,
      accessKeyId: res.access_key_id,
      accessKeySecret: res.access_key_secret,
    }
  }

  const initOSSClient = async () => {
    const expiresTime = refreshSTSTokenInterval - 60 * 1000 // 过期时间少1分钟
    const res = await OssSingleton.getInstance<{
      region: string
      stsToken: string
      accessKeyId: string
      accessKeySecret: string
    }>(business, fetchOssStsTokenOptions, expiresTime)
    const ossClient = new OSS({
      ...res.getData(),
      refreshSTSToken: fetchOssStsTokenOptions,
      refreshSTSTokenInterval,
    })

    setOssClient(ossClient)

    return ossClient
  }

  useEffect(() => {
    initOSSClient()
  }, [])

  return {
    ossClient,
    initOSSClient,
  }
}
