import { useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useRequest } from 'ahooks'
import { getApplicationsList } from '@apis/application'
import { UserSearchTable } from '@/components'
import { useWorkspaceStore } from '@/store'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

export default function Users() {
  const { id: workspaceId } = useParams()

  const { setCurrentWorkspace } = useWorkspaceStore(state => {
    return {
      currentWorkspaceItem: state.currentWorkspaceItem,
      setCurrentWorkspace: state.setCurrentWorkspace,
    }
  })

  const { data: appList = [] } = useRequest(() =>
    getApplicationsList(workspaceId!),
  )

  const appOptions = useMemo(() => {
    return appList.map(item => ({
      label: item.name,
      value: item.id,
      applicationType: item.applicationType,
    }))
  }, [appList])

  useEffect(() => {
    setCurrentWorkspace(workspaceId!)
  }, [workspaceId])

  const { scrollRef } = useScrollBar()

  return (
    <div ref={scrollRef} className='adapt:px-64 h-full overflow-scroll'>
      <UserSearchTable appOptions={appOptions} />
    </div>
  )
}
