import { useBoolean, useUnmount } from 'ahooks'
import { message } from 'antd'
import classNames from 'classnames'
import { memo, useMemo, useRef } from 'react'
import type { FitViewOptions } from 'reactflow'
import {
  Background,
  Controls,
  ReactFlow,
  useOnViewportChange,
  useReactFlow,
} from 'reactflow'
import { edgeTypes } from '@/features/flow/edges'
import { flowAutoLayout } from '@/features/flow/layout/autolayout'
import { initFlowNodes } from '@/features/nodes'
import { NodeConfigPanel, NodeType } from '@/features/nodes/base'
import { useDisableSaveKey } from '@/hooks/useDisableSaveKey'
import {
  FLOW_DRAFT_LOCK_STATUS,
  useFlowDraftStore,
  useFlowInteractionStore,
  useMovableOptionStore,
} from '@/store'
import { useNodeStore } from '@/store/node'
import 'reactflow/dist/base.css'
import { useAutoLocateNode } from '../hooks/useAutoLocateNode'
import { useCursorChange } from '../hooks/useCursorChange'
import { useEdgeClick } from '../hooks/useEdgeClick'
import { useEdgeMouseEvents } from '../hooks/useEdgeMouseEvents'
import { useEditUndoRedo } from '../hooks/useEditUndoRedo'
import { useFlowDraftMove } from '../hooks/useFlowDraftMove'
import { useNodeClick } from '../hooks/useNodeClick'
import { useNodeCopy } from '../hooks/useNodeCopy'
import { useNodeRemoveShortcut } from '../hooks/useNodeRemoveShortcut'
import { usePaneEvents } from '../hooks/usePaneEvents'
import { EditModeTipBar } from './components/EditModeTipBar'
import { FlowMessage } from './components/FlowMessage'
import { MovableOption } from './components/MovableOption'
import { ToolBar } from './components/ToolBar'
import { VersionBar } from './components/VersionBar'
import { FlowControlButton } from './components/FlowControlButton'

export interface FlowDataItemType {
  modifiedBy: string
  modifiedAt: string
  createBy: string
  applicationVersion: number
  versionStatus: string
  flowId: string
  versionId: string
  config: { nodes: any; edges: any }
}
interface FlowDraftProps {
  showHeader?: boolean
  isShowVersion: boolean
  onCloseVersion: () => void
  collapsed?: boolean
  toolbarClassnames: string
  applicationInfo?: { applicationVersion?: number; config: any }
  selectedVersion: number
  flowVersionData: FlowDataItemType[]
  onSelectedVersion: (version: number) => void
  onToolBarOpen: () => void
  onToolBarClose: () => void
  onRunFlowVersionList: () => void
  handleRecoverVersion: ({
    flowId,
    versionId,
  }: {
    flowId: string
    versionId: string
  }) => Promise<FlowDataItemType>
}

const flowNodes = initFlowNodes()
const nodeTypes = flowNodes.reduce<Record<string, any>>((pre, cur) => {
  pre[cur.meta.type] = cur.node
  return pre
}, {})

export const FlowDraft = memo((props: FlowDraftProps) => {
  const {
    showHeader,
    isShowVersion,
    onCloseVersion,
    collapsed,
    onToolBarOpen,
    onToolBarClose,
    toolbarClassnames,
    applicationInfo,
    selectedVersion,
    onSelectedVersion,
    flowVersionData,
    onRunFlowVersionList,
    handleRecoverVersion,
  } = props
  const { zoomIn, zoomOut, fitView } = useReactFlow()
  const {
    nodes,
    edges,
    onNodesChange,
    onEdgesChange,
    onConnect,
    reLayoutFlow,
    clear,
    setConfig,
    setFlowLock,
    lockStatus,
  } = useFlowDraftStore()
  const [isShowTips, { set: setShowTips }] = useBoolean(true)
  const { isAddMode } = useFlowInteractionStore()
  const { nodes: layoutedNodes, edges: layoutedEdges } = useMemo(() => {
    return flowAutoLayout(nodes, edges)
  }, [nodes, edges])

  const { activatedNodeId, showAllComment, hideAllComment, isAllCommentHide } =
    useNodeStore()
  const isOpenPanel = useMemo(() => {
    return !!activatedNodeId
  }, [activatedNodeId])

  const panels = useMemo(() => {
    return flowNodes.map(item => {
      return {
        panel: item.panel,
        panelHeader: item.panelHeader,
        meta: item.meta,
      }
    })
  }, [flowNodes])

  useNodeCopy()
  useEditUndoRedo()
  useNodeRemoveShortcut({ canRemove: !isOpenPanel })
  useAutoLocateNode()

  useDisableSaveKey(() => message.success('请放心，已经自动保存'))

  const { changeCursor, resetCursor } = useCursorChange()

  const { handleNodeClick } = useNodeClick()
  const { onEdgeClick } = useEdgeClick()
  const { onEdgeMouseEnter, onEdgeMouseLeave } = useEdgeMouseEvents()
  const { onMoveStart, onMoveEnd, onMove } = useFlowDraftMove({
    startCallback: resetCursor,
    endCallback: changeCursor,
  })
  const { onPaneClick } = usePaneEvents()

  const handleHistoryVersion = (config: any) => {
    setConfig(config)
    setFlowLock(FLOW_DRAFT_LOCK_STATUS.LOCK_IN_VERSION, '预览状态下不可编辑')
  }

  const { visible } = useMovableOptionStore()

  useUnmount(() => {
    clear()
  })

  const fitViewOptions = useMemo(() => {
    const startNode = nodes.find(n => n.type === NodeType.START)
    const options: FitViewOptions = {
      padding: 336,
      nodes: startNode && nodes.length >= 5 ? [{ id: startNode.id }] : [],
      minZoom: nodes.length <= 4 ? 0.8 : undefined,
      maxZoom: nodes.length >= 5 ? 0.8 : undefined,
    }
    return options
  }, [nodes])

  const viewportCorrectRef = useRef(false)
  const { setViewport, getViewport } = useReactFlow()

  useOnViewportChange({
    onChange: _viewport => {
      if (!viewportCorrectRef.current) {
        const currentViewport = getViewport()
        setViewport({
          ...currentViewport,
          x: collapsed ? 80 : 360,
        })
        viewportCorrectRef.current = true
      }
    },
  })

  return (
    <div
      className='relative flex-1 bg-#f4f4f6'
      style={{ transform: 'translate3d(0,0,0)' }}
    >
      {isAddMode && <EditModeTipBar />}
      {isAddMode && visible && <MovableOption showHeader={showHeader} />}
      <ReactFlow
        id='flow'
        nodes={layoutedNodes}
        edges={layoutedEdges}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        elementsSelectable={false}
        // defaultViewport={{
        //   x: 0,
        //   y: 0,
        //   zoom: 0.55
        // }}
        nodesConnectable={false} // 需求：禁止从边上拖出连线
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeClick={handleNodeClick}
        onEdgeClick={onEdgeClick}
        onEdgeMouseEnter={onEdgeMouseEnter}
        onEdgeMouseLeave={onEdgeMouseLeave}
        onPaneClick={onPaneClick}
        onMoveStart={onMoveStart}
        onMoveEnd={onMoveEnd}
        onMove={onMove}
        deleteKeyCode={[]}
        zoomOnDoubleClick={false}
        zoomOnScroll={false}
        panOnScroll={true}
        panOnScrollSpeed={1.2}
        // https://github.com/wbkd/react-flow/issues/2936
        nodesDraggable={false}
        snapToGrid={false}
        maxZoom={1.5}
        fitView
        fitViewOptions={fitViewOptions}
        elevateEdgesOnSelect={true}
        proOptions={{
          hideAttribution: true,
        }}
        // onlyRenderVisibleElements={true}
      >
        <ToolBar
          className={toolbarClassnames}
          collapsed={collapsed}
          onOpen={onToolBarOpen}
          onClose={onToolBarClose}
        />
        <Background />
        <Controls
          className={classNames('flex gap-8px p-4px b-rd-8px bg-#fff', {
            'left-336px!': !collapsed,
          })}
          showZoom={false}
          showFitView={false}
          showInteractive={false}
        >
          <FlowControlButton
            icon='suoxiao'
            tooltip='缩小'
            onClick={() => zoomOut()}
          />
          <FlowControlButton
            icon='fangtai'
            tooltip='放大'
            onClick={() => zoomIn()}
          />
          <FlowControlButton
            icon='shiyinghuabu'
            tooltip='适应画布'
            onClick={() => fitView()}
          />
          <FlowControlButton
            icon='zidongbuju'
            tooltip='自动布局'
            onClick={() => reLayoutFlow()}
          />
          <FlowControlButton
            icon='zhushi'
            tooltip={isAllCommentHide() ? '显示节点注释' : '隐藏节点注释'}
            onClick={() =>
              isAllCommentHide() ? showAllComment(nodes) : hideAllComment()
            }
          />
        </Controls>
        {lockStatus === FLOW_DRAFT_LOCK_STATUS.LOCK_IN_VERSION &&
          isShowTips && (
            <FlowMessage
              width={`calc(100% - ${isShowVersion ? 369 : 80}px)`}
              content='预览状态下不可编辑'
            />
          )}
        {lockStatus === FLOW_DRAFT_LOCK_STATUS.LOCK_BATCH_TEST_RUN && (
          <FlowMessage
            width='calc(100% - 80px)'
            content='批量调试运行状态下不可编辑'
          />
        )}

        {isShowVersion && (
          <VersionBar
            onCloseVersion={onCloseVersion}
            applicationInfo={applicationInfo}
            handleHistoryVersion={handleHistoryVersion}
            setShowTips={setShowTips}
            selectedVersion={selectedVersion}
            onSelectedVersion={onSelectedVersion}
            flowVersionData={flowVersionData}
            onRunFlowVersionList={onRunFlowVersionList}
            handleRecoverVersion={handleRecoverVersion}
          />
        )}
        {isOpenPanel && <NodeConfigPanel panels={panels} />}
      </ReactFlow>
    </div>
  )
})
