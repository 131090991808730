import type { ReactNode } from 'react'
import type { ApplicationBodyType } from '@apis/application/type'
import type { nodeConfigType } from '@apis/run/type'
import { IconFont } from '@/components'
import { StartNodeTypes } from '@/features/nodes/start'
import API from './components/appDetail/tabs/API'
import SingleRun from './components/appDetail/tabs/SingleRun'
import BatchRun from './components/appDetail/tabs/BatchRun'
import TimingRun from './components/appDetail/tabs/TimingRun'
import Webhook from './components/appDetail/tabs/Webhook'
import Log from './components/appDetail/tabs/Log'
import AppRunAccessGuard from './components/appDetail/components/AppRunAccessGuard'
import { FlowAnalyze } from './components/appDetail/tabs/Analyze'

export type tabsType =
  | 'singleRun'
  | 'batchRun'
  | 'timingRun'
  | 'API'
  | 'Webhook'
  | 'log'
  | 'analyze'

export const tabsMap: Record<StartNodeTypes, tabsType[]> = {
  [StartNodeTypes.Form]: [
    'singleRun',
    'batchRun',
    'timingRun',
    'log',
    'analyze',
    'API',
  ],
  [StartNodeTypes.Webhook]: ['Webhook', 'analyze', 'log'],
}

export interface TabData {
  label: string
  render: (props: {
    data: ApplicationBodyType
    firstNodeConfig: nodeConfigType | null
    handleBeforeRun: () => Promise<boolean>
  }) => ReactNode
  runAuth?: boolean
  group?: string
  keepAlive?: boolean
  icon?: ReactNode
  needUpgradeToEnterprise?: boolean
}

export const AppDetailTabs: Record<tabsType, TabData> = {
  log: {
    label: '日志',
    render: ({ data }) => <Log flowName={data.name} flowId={data.flowId} />,
  },
  analyze: {
    label: '分析',
    render: ({ data }) => (
      <FlowAnalyze flowId={data.flowId} flowJumpId={data.id} />
    ),
  },
  API: {
    label: '访问API',
    render: ({ data }) => (
      <AppRunAccessGuard
        appId={data.flowId}
        flowId={data.id}
        isPublished={data.versionStatus === 'PUBLISHED'}
        isEnable={data.isEnable}
      >
        <API flowId={data?.flowId} />
      </AppRunAccessGuard>
    ),
    runAuth: true,
    needUpgradeToEnterprise: true,
  },
  singleRun: {
    group: 'run',
    keepAlive: true,
    icon: (
      <IconFont style={{ color: 'rgba(98, 105, 153, 0.6)' }} name='yunxing' />
    ),
    label: '单次运行',
    render: ({ data, firstNodeConfig, handleBeforeRun }) => (
      <AppRunAccessGuard
        appId={data.id}
        flowId={data.flowId}
        isPublished={data.versionStatus === 'PUBLISHED'}
        isEnable={data.isEnable}
      >
        <SingleRun
          beforeRun={handleBeforeRun}
          firstNodeConfig={firstNodeConfig}
          data={data}
        />
      </AppRunAccessGuard>
    ),
    runAuth: true,
  },
  batchRun: {
    group: 'run',
    keepAlive: true,
    icon: (
      <IconFont
        style={{ color: 'rgba(98, 105, 153, 0.6)' }}
        name='icon-logo-7'
      />
    ),
    label: '批量运行',
    render: ({ data, handleBeforeRun }) => (
      <AppRunAccessGuard
        appId={data.id}
        flowId={data.flowId}
        isPublished={data.versionStatus === 'PUBLISHED'}
        isEnable={data.isEnable}
      >
        <BatchRun
          beforeRun={handleBeforeRun}
          flowId={data?.flowId}
          data={data}
        />
      </AppRunAccessGuard>
    ),
    runAuth: true,
  },
  timingRun: {
    group: 'run',
    icon: (
      <IconFont
        style={{ color: 'rgba(98, 105, 153, 0.6)' }}
        name='dingshiyunxing'
      />
    ),
    label: '定时运行',
    render: ({ data, firstNodeConfig }) => (
      <AppRunAccessGuard
        appId={data.id}
        flowId={data.flowId}
        isPublished={data.versionStatus === 'PUBLISHED'}
        isEnable={data.isEnable}
      >
        <TimingRun firstNodeConfig={firstNodeConfig} data={data} />
      </AppRunAccessGuard>
    ),
    runAuth: true,
  },
  Webhook: {
    label: '访问Webhook',
    render: ({ data }) => (
      <AppRunAccessGuard
        appId={data.id}
        flowId={data.flowId}
        isPublished={data.versionStatus === 'PUBLISHED'}
        isEnable={data.isEnable}
      >
        <Webhook flowId={data?.flowId} />
      </AppRunAccessGuard>
    ),
    runAuth: true,
  },
}
