import { clearCache, useRequest } from 'ahooks'
import { isEmpty, isNil } from 'lodash-es'
import { useCallback, useEffect, useState } from 'react'
import NiceModal from '@ebay/nice-modal-react'
import type { AgentDetailTable } from '@bty/chat-types'
import {
  bindDatabase,
  enableTableForAgent,
  fetchAgentDetail,
  unbindDatabase,
} from '@apis/agent'
import { getTableColumnsHash, tableFieldUpdateBulk } from '@apis/database'
import type { Database, TableFieldUpdateBulkRequest } from '@apis/database/type'
import { usePageModal } from '@/components'
import { useWorkspaceStore } from '@/store'
import TableEditModal from '@/features/database/components/TableEditModal'
import type { BindingTableEvents } from '../types'
import { BindingDatabase } from './BindingDatabase'
import type { BindingTableListProps } from './BindingTableList'
import { BindingTableList } from './BindingTableList'
import { NoBinding } from './NoBinding'
import { BindingTableDataGrid } from './BindingTableDataGrid'

interface DatabasePaneProps {
  agentId: string
  versionId: string
  onUpdate?: () => void
}

export function DatabasePane({
  agentId,
  versionId,
  onUpdate,
}: DatabasePaneProps) {
  const { data: agent, refreshAsync: reload } = useRequest(
    () => fetchAgentDetail(agentId),
    {
      refreshDeps: [agentId],
    },
  )

  const [activatedTable, setActivatedTable] = useState<AgentDetailTable | null>(
    null,
  )

  const handleBack = useCallback(() => {
    setActivatedTable(null)
  }, [])

  const modal = usePageModal()

  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)

  const handleLink = useCallback<BindingTableEvents['onLink']>(
    (databaseId, tableId) => {
      modal.show({
        url: `/db/${workspaceId}/${databaseId}/${tableId}?hide_tables`,
      })
    },
    [workspaceId],
  )

  const handleUpdate = useCallback<BindingTableEvents['onUpdate']>(
    (database, table_id, afterUpdate) => {
      const _database = {
        id: database.database_id,
        sources: [{ id: database.source_id }],
      }
      NiceModal.show(TableEditModal, {
        actionType: 'edit',
        initialId: table_id,
        database: _database as Database,
        onOk: async (_0, _1, values) => {
          const _values = values as TableFieldUpdateBulkRequest
          if (_values.column_bulk) {
            const response = await getTableColumnsHash(table_id)
            _values.column_bulk.hash = response.hash
          }
          await tableFieldUpdateBulk(table_id, _values)
          reload()
          afterUpdate?.()
        },
      })
    },
    [],
  )

  const handleEnableChange = useCallback<BindingTableEvents['onEnableChange']>(
    async (tool_id, enabled) => {
      if (!agent) {
        return
      }
      await enableTableForAgent({
        flow_id: agent.application!.flowId,
        version_id: agent.application!.draftVersionId!,
        tool_id,
        enabled,
      })
      reload()
    },
    [agent],
  )

  useEffect(
    () => () => {
      const dbId = agent?.database?.database_id
      if (dbId) {
        clearCache(`database_${dbId}`)
      }
    },
    [],
  )

  const [searchKey, setSearchKey] = useState<string>()

  const filterOption = useCallback<
    NonNullable<BindingTableListProps['filterOption']>
  >(
    inputValue => {
      if (!searchKey) {
        return true
      }
      return inputValue.includes(searchKey)
    },
    [searchKey],
  )

  const handleBind = useCallback(() => {
    reload()
    onUpdate?.()
  }, [reload, onUpdate])

  if (!agent) {
    return
  }

  const usedDatabase = agent.database

  if (isNil(usedDatabase) || isEmpty(usedDatabase)) {
    return (
      <NoBinding
        agent={{
          name: agent.application!.name!,
          flow_id: agentId,
          version_id: versionId,
        }}
        afterBind={handleBind}
      />
    )
  }

  if (activatedTable) {
    return (
      <div className='flex flex-col p-24 pt-12 h-full'>
        <BindingTableDataGrid
          database={usedDatabase}
          table={activatedTable}
          onBack={handleBack}
          onLink={handleLink}
          onUpdate={handleUpdate}
          onEnableChange={handleEnableChange}
        />
      </div>
    )
  }

  return (
    <div className='flex flex-col p-24 pt-12 h-full'>
      <BindingDatabase
        agent={agent}
        database={usedDatabase}
        afterBind={reload}
        onSearch={setSearchKey}
        onAction={async (action, database) => {
          if (action === 'unbind') {
            await unbindDatabase(agentId, versionId, agent.appId)
          }
          if (action === 'switch' && database) {
            const sourceId = database.sources?.[0].id
            if (sourceId) {
              await bindDatabase({
                flow_id: agentId,
                version_id: versionId,
                database_id: database.id,
                source_id: sourceId,
              })
            }
          }
          reload()
        }}
      />
      <BindingTableList
        database={usedDatabase}
        filterOption={filterOption}
        onLink={handleLink}
        onClick={setActivatedTable}
        onUpdate={handleUpdate}
        onEnableChange={handleEnableChange}
      />
    </div>
  )
}
