export enum StaticTimeTypes {
  DAILY = 'daily',
  WEEKLY = 'weekly',
  MONTHLY = 'monthly',
  QUARTERLY = 'quarterly',
  YEARLY = 'yearly',
  CUMULATIVE = 'cumulative',
  CUSTOM = 'custom',
}

export type AppMonitorType = 'AGENT' | 'FLOW'

export interface AppMonitorOverviewBaseParams {
  flow_id?: string
  flow_type?: AppMonitorType
  run_type?: string[]
}

export interface AppMonitorOverviewParams extends AppMonitorOverviewBaseParams {
  statistic_time: StaticTimeTypes
  start_date?: string
  end_date?: string
}

export interface AppMonitorOverviewResponse {
  run_count: number
  unique_users: number
  consumed_points: number
  run_count_change?: number
  unique_users_change?: number
  consumed_points_change?: number
}

export type ListPageParams<T> = {
  page: number
  page_size: number
} & T

export interface ListResponse<T> {
  totals: number
  page: number
  page_size: number
  rows: T[]
}

export interface AppUsageItem {
  record_date: string
  run_count: number
  unique_users: number
  consumed_points: number
}

export type AppUsageRankingParams = AppMonitorOverviewParams & {
  search_content?: string
  sort_field?: string
  sort_order?: 'desc' | 'asc'
}

export interface AppUsageRankingItem {
  consumed_points: number
  usage_count: number
  user_id: string
  username: string
}

export interface ErrorRecordItem {
  record_date: string
  run_type: string
  user_id: string
  username: string
}

export interface OvertimeRecordItem {
  id: string | number
  irl_time: number // 秒
  biz_id: string // 作为查找记录的record_id
  flow_id: string
  flow_name: string
  user_name: string
  run_type: string
  created_at: string
}

export interface ChatRecordsParams {
  record_id: string | number
  limit: number
}

export interface AgentChatRecordsParams {
  robot_id: string
  page_no: number
  page_size: number
  record_status: 'SUCCEEDED' | 'FAILED'
  user_name?: string
  run_type?: string[]
  start_time?: string
  end_time?: string
}

export interface AgentChatRecordItem {
  create_time: string
  record_id: string
  record_status: 'SUCCEEDED' | 'FAILED'
  run_type: string[]
  uid: number | string
  user_name: string
}

export interface AgentChatRecordsResponse {
  data: AgentChatRecordItem[]
  total: number
  page_no: number
  page_size: number
}

export interface ChatRecordItem {
  id: string
  question: string
  response: string
  conversation_id: string
  session_id: string
  uid: number
  create_time: string
}

export interface UsageTrendRequest extends AppMonitorOverviewParams {
  display_time: string
}
export interface UsageTrendType {
  title: string
  value: { display_time: string; value: number }[]
}

export interface UserUsageRankRequest extends AppMonitorOverviewParams {
  limit: number
}

export interface AppStabilityRequest extends AppMonitorOverviewParams {
  display_time: string
}

export type AppStabilityType = UsageTrendType
export type IrlTrendRequest = AppStabilityRequest
export type IrlTrendType = AppStabilityType

export interface WorkspaceUsageOverview extends Record<string, number> {
  daily_consumed_points: number
  daily_consumed_points_change: number
  weekly_consumed_points: number
  weekly_consumed_points_change: number
  monthly_consumed_points: number
  monthly_consumed_points_change: number
}

export interface UserUsageRankType {
  title: string
  value: { user_id: number; username: string; value: number }[]
}
