import { request } from '../../client'
import type {
  UserItem,
  RoleBodyRequest,
  UpdatePermittedApplicationsRequestBody,
  RemoveUserRequest,
  SearchUserItem,
  InviteMembersRequestBody,
  RoleCode,
  WorkspaceUsersRequest,
} from './type'

export function getWorkspaceUsers(params: WorkspaceUsersRequest) {
  return request.get<UserItem[]>('/v1/authority/members', { query: params })
}

// 角色修改
export function changeUserRole(body: Omit<RoleBodyRequest, 'sourceRoleCode'>) {
  return request.put('/v1/authority/updateRole', body)
}

export function updatePermittedApplicationsForUser(
  body: UpdatePermittedApplicationsRequestBody,
) {
  return request.put('/v1/authority/updateApplication', body)
}

export function removeUserFromWorkspace(body: RemoveUserRequest) {
  return request.put('/v1/authority/removeMember', body)
}

export function getAllUsersFromWorkSpace(workspaceId: string) {
  return request.get<SearchUserItem[]>('/v1/authority/getAllMembers', {
    query: { workspaceId },
  })
}

export function inviteMembers(body: InviteMembersRequestBody) {
  return request.post('/v1/authority/inviteMembers', body)
}

export function sendInviteMsg(body: InviteMembersRequestBody) {
  return request.post('/v1/authority/sendInviteMsg', body)
}

export function getUserRoleAppInfos(workspaceId: string) {
  return request.get(
    `/v1/authority/getUserRoleAppInfos?workspaceId=${workspaceId}`,
    {
      // 在 share agent 和 share chat 使用时，不需要跳转 notAllowed，他们有自己的 403 页面
      noRedirect: true,
      ignoreError: true,
    },
  )
}

interface InviteUserQueryType {
  app_info_list: {
    application_id: string
    application_name: string
  }[]
  effective_end_time: string
  invite_user_id: number
  invite_user_name: string
  is_auth_all_app: boolean
  role_code: RoleCode
  unique_id: string
  workspace_id: string
  workspace_name: string
}

// 获取链接邀请用户基本信息
export function getInviteUserQuery(id: string) {
  return request.get<InviteUserQueryType>(`/v1/link/invite_user/query/${id}`)
}

// 邀请用户到空间
export function linkInviteMembers(
  body: { id: string; phones?: string[] },
  set?: { ignoreError: boolean },
) {
  return request.post('/v1/authority/link/inviteMembers', body, set)
}

// 无需鉴权时查看管理员列表
export function getAdminMembers(params: WorkspaceUsersRequest) {
  return request.get<UserItem[]>('/v1/authority/members_admin', {
    query: params,
  })
}

// 不需要 agentId 查看管理员列表
export function getWorkspaceAdminMembers(workspaceId: string) {
  return request.get<UserItem[]>('/v1/authority/workspace_members_admin', {
    query: { workspaceId },
  })
}

export function getTemplateList() {
  return request.get('/v1/copilot-template/list')
}

export function createAgentByTemplate(body: {
  agent_id: string
  file_ids: string[]
  parameters: { prompt: string }
  is_skip_file_check?: boolean
}) {
  return request.post('/v1/copilot/create_agent_by_copilot_template', body, {
    headers: {
      'Application-Id': body.agent_id,
    },
  })
}

export function getAgentCopilotProgress(agentId: string) {
  return request.get(
    `/v1/copilot/get_copilot_create_agent_progress/${agentId}`,
    {
      timeout: 10 * 6 * 1000,
      headers: {
        'Application-Id': agentId,
      },
    },
  )
}
