import { useLoaderData, useNavigate, useParams } from 'react-router-dom'
import { Modal, Tooltip } from 'antd'
import { useEffect, useState } from 'react'
import type { Bot } from '@bty/chat-types'
import { getWorkspaceList } from '@apis/workspace'
import Chatbot from '../chatbot/index'
import useShareAgentAuthentic from '@/hooks/useShareAgentAuthentic'
import { isMobileDevice } from '@/utils/device.ts'
import { LoginOrRegister } from '@/features/login/LoginOrRegister'
import type { StoreBotItem } from '@/store'
import { Button } from '@/components'
import productDiscussionGroup from '@/assets/productDiscussionGroup.png'
import { CreateWorkspace } from '@/features/login/CreateWorkspace'

const regularChatList: StoreBotItem[] = [
  {
    avatar: '',
    color: '#C7F3F0',
    description: '擅长爆款内容分析及内容创作生成',
    icon: 'https://bty-gemini-resource-prod.oss-cn-hangzhou.aliyuncs.com/ai/agentAvatar/1705904738326%E8%90%A5%E9%94%80-%E5%B0%8F%E7%BA%A2%E4%B9%A6%E7%88%86%E6%AC%BE%E5%86%85%E5%AE%B9AI%E5%B7%A5%E4%BD%9C%E5%8A%A9%E7%90%86.png',
    id: 2523,
    is_ordinary: true,
    max_token: 4096,
    model: 'AI-AGENT',
    name: '小红书爆款内容分析生成',
    robot_id: '0b0adfee-2bb8-43d0-822c-1cc8bcf17e7c',
    type: 'AI-AGENT',
  },
  {
    avatar: '',
    color: '',
    description:
      '我是文章SEO标题专家，能够准确理解用户需求，设计出吸引读者点击，且对搜索引擎友好的标题，助您吸引更多流量',
    icon: 'https://bty-gemini-resource-prod.oss-cn-hangzhou.aliyuncs.com/chat/1121/AvM4_1714112216709.jpg',
    id: 'fef96e44103d46fdaa3e39113db96ef9',
    is_ordinary: true,
    max_token: 4096,
    model: 'AI-AGENT',
    name: '爆款文章标题专家',
    robot_id: 'fef96e44103d46fdaa3e39113db96ef9',
    type: 'AI-AGENT',
  },
  {
    avatar: '',
    color: '#F5D1D1',
    description:
      // eslint-disable-next-line quotes
      "根据喜好、场景、天气，为顾问提供专业的穿搭建议，并向她推荐最新的店铺优惠活动，引导下单。(〃'▽'〃)❀❀❀",
    icon: 'https://bty-gemini-resource-prod.oss-cn-hangzhou.aliyuncs.com/ai/agentAvatar/1706861007925%E5%AE%A2%E6%9C%8D-%E9%BA%A6%E4%B9%90%EF%BC%88Melody%EF%BC%89%E5%A5%B3%E8%A3%85%E5%AE%A2%E6%9C%8D.png',
    id: 2595,
    is_ordinary: true,
    max_token: 4096,
    model: 'AI-AGENT',
    name: '女装店铺导购客服',
    robot_id: 'e2740ac6-5187-4ada-be10-14ef1a722145',
    type: 'AI-AGENT',
  },
  // {
  //   avatar: '',
  //   color: '#C7F3F0',
  //   description:
  //     "您好，我是你的抖音视频分析助手，我将针对您提供的抖音口播视频链接进行深入分析，包含：\n  +【视频文本还原】；\n  +【内容框架结构】； \n  +【内容评论分析】(〃'▽'〃)❀❀❀",
  //   icon: 'https://bty-gemini-resource-prod.oss-cn-hangzhou.aliyuncs.com/ai/agentAvatar/17042838209161704203541533img-PpAhOmA6gTQGMCWeMZBoTjV7.png',
  //   id: 31275,
  //   is_ordinary: true,
  //   max_token: 4096,
  //   model: 'AI-AGENT',
  //   name: '抖音视频分析助手',
  //   robot_id: 'b3923ba7b8e84a1f99128d9cc448ccd6',
  //   type: 'AI-AGENT',
  // },
  // {
  //   avatar: '',
  //   color: '#C7F3F0',
  //   description: '紧跟热点，辅助小红书营销创意',
  //   icon: 'https://resource.bantouyan.com/betteryeah/agent/avatars/avatar-66.png',
  //   id: 33658,
  //   is_ordinary: true,
  //   max_token: 4096,
  //   model: 'AI-AGENT',
  //   name: '小红书热点洞察器：激发营销灵感',
  //   robot_id: 'bf553740221c4e69ba9d6b2120fe026a',
  //   type: 'AI-AGENT',
  // },
  {
    id: 25184,
    robot_id: '97745b0dcdb649938deb239469d67c93',
    name: '实时联网智能分析',
    description:
      '一款集网络数据实时搜索、网页信息智能分析为一体的在线分析小助手，能准确理解用户需求，协助用户进行信息整合和深度总结',
    avatar: '',
    type: 'AI-AGENT',
    model: 'AI-AGENT',
    is_ordinary: true,
    max_token: 4096,
    color: '',
    icon: 'https://resource.bantouyan.com/betteryeah/agent/avatars/avatar-20.png',
  },
  {
    avatar: '',
    color: '#D6D1F2',
    description:
      '我可以提供实时销售分析和策略建议，助力您的业务增长并优化市场表现！',
    icon: 'https://bty-gemini-resource-prod.oss-cn-hangzhou.aliyuncs.com/ai/agentAvatar/1705904814473%E7%94%B5%E5%95%86-%E5%BA%97%E9%93%BA%E6%95%B0%E6%8D%AE%E5%88%86%E6%9E%90%E5%8A%A9%E7%90%86.png',
    id: 2603,
    is_ordinary: true,
    max_token: 4096,
    model: 'AI-AGENT',
    name: '店铺数据分析师',
    robot_id: '0130788a-d9ca-43fb-9840-19123c4de56d',
    type: 'AI-AGENT',
  },
  {
    avatar: '',
    color: '#D9E7FF',
    description:
      '提供专业的二手车购买销售服务，包括车辆推荐及对比 / 图片 / 视频搜索 / 网络口碑与总结 / 贷款金融匹配 / 上门看车预约',
    icon: 'https://resource.bantouyan.com/betteryeah/agent/avatars/avatar-33.png',
    id: 1539,
    is_ordinary: true,
    max_token: 4096,
    model: 'AI-AGENT',
    name: '汽车4S店销售顾问',
    robot_id: 'd42f548b-0c64-4f55-96a6-17efa6bae991',
    type: 'AI-AGENT',
  },
  {
    avatar: '',
    color: '#FECFDA',
    description:
      '作为一个专业的销售分析助理，我随时准备对销售团队的客户拜访记录进行深入分析。无论您在销售过程中遇到的具体问题是什么，或者您希望关注的特定领域是什么，我都能够为您提供支持和见解。',
    icon: 'https://bty-gemini-resource-prod.oss-cn-hangzhou.aliyuncs.com/ai/agentAvatar/1705903952160%E9%94%80%E5%94%AE-StyleHup%E9%94%80%E5%94%AE%E5%88%86%E6%9E%90%E5%8A%A9%E7%90%86.png',
    id: 2604,
    is_ordinary: true,
    max_token: 4096,
    model: 'AI-AGENT',
    name: 'StyleHup销售分析助理',
    robot_id: '73c4ed01-484e-42f4-a3a9-32f67b8ce6a1',
    type: 'AI-AGENT',
  },
  {
    avatar: '',
    color: '#F1DEFF',
    description: '20年工作文案工作经验，擅长撰写日常消费品的产品广告文案',
    icon: 'https://bty-gemini-resource-prod.oss-cn-hangzhou.aliyuncs.com/ai/agentAvatar/1705904769056%E8%90%A5%E9%94%80-%E5%B9%BF%E5%91%8A%E6%96%87%E6%A1%88AI%E5%B7%A5%E4%BD%9C%E5%8A%A9%E6%89%8B.png',
    id: 2526,
    is_ordinary: true,
    max_token: 4096,
    model: 'AI-AGENT',
    name: '广告文案AI工作助理',
    robot_id: '1be21199-01c4-4aad-b90e-bf510e6e07c8',
    type: 'AI-AGENT',
  },
  {
    avatar: '',
    color: '#D9E7FF',
    description:
      '对话MBTI理论提出者 Isabel Briggs Myers：\n个人性格测试 / 优势劣势分析 / 情感关系分析 / 职业发展分析',
    icon: 'https://resource.bantouyan.com/betteryeah/agent/avatars/avatar-13.png',
    id: 1524,
    is_ordinary: true,
    max_token: 4096,
    model: 'AI-AGENT',
    name: 'MBTI测试助手',
    robot_id: 'b49bddef-4b70-4b1d-8d23-54eb98296c13',
    type: 'AI-AGENT',
  },
  {
    avatar: '',
    color: '#F5D1D1',
    description: '自动搜索简历、回复候选人，按要求筛选简历，进行初筛打分',
    icon: 'https://bty-gemini-resource-prod.oss-cn-hangzhou.aliyuncs.com/ai/agentAvatar/1705904622797HR-%E6%8B%9B%E8%81%98%E5%8A%A9%E6%89%8B.png',
    id: 2545,
    is_ordinary: true,
    max_token: 4096,
    model: 'AI-AGENT',
    name: '招聘助手',
    robot_id: 'f149f625-f660-4da1-90cf-259b57d33009',
    type: 'AI-AGENT',
  },
  {
    avatar: '',
    color: '#EAEDFB',
    description:
      '我专精于历史评论查询、评论分类统计、生成分析报告及自动回复差评，提升商品声誉和客户满意度。',
    icon: 'https://bty-gemini-resource-prod.oss-cn-hangzhou.aliyuncs.com/ai/agentAvatar/1705904833285%E7%94%B5%E5%95%86-%E5%BA%97%E9%93%BA%E8%AF%84%E8%AE%BA%E5%88%86%E6%9E%90%E5%8A%A9%E6%89%8B.png',
    id: 2602,
    is_ordinary: true,
    max_token: 4096,
    model: 'AI-AGENT',
    name: '店铺评论分析助手',
    robot_id: '10518030-87cf-4a73-b28a-3ead1cd8153e',
    type: 'AI-AGENT',
  },
  {
    avatar: '',
    color: '#FFEFDB',
    description:
      '作为一名资深的数据分析师，我的专长是分析平台注册数据，以发现潜在客户并为销售团队提供有价值的销售线索，帮助销售团队高效挖掘客户。',
    icon: 'https://bty-gemini-resource-prod.oss-cn-hangzhou.aliyuncs.com/ai/agentAvatar/1705903858285%E9%94%80%E5%94%AE-CloudWav%E5%AE%A2%E6%88%B7%E6%8C%96%E6%8E%98%E5%8A%A9%E7%90%86.png',
    id: 2551,
    is_ordinary: true,
    max_token: 4096,
    model: 'AI-AGENT',
    name: 'CloudWav客户挖掘助理',
    robot_id: '31dd7361-1679-421b-b3cf-13aacbaa6eb0',
    type: 'AI-AGENT',
  },
  {
    avatar: '',
    color: '#EAEDFB',
    description:
      '家电新选择，快乐每一家！\n针对老顾客提供家电售后、活动推荐、维修保养、家电选购知识普及等服务',
    icon: 'https://bty-gemini-resource-prod.oss-cn-hangzhou.aliyuncs.com/ai/agentAvatar/1705903805740%E5%AE%A2%E6%9C%8D-%E4%BA%BA%E4%BA%BA%E4%B9%90%E5%AE%B6%E7%94%B5%E5%95%86%E5%9F%8E%E5%94%AE%E5%90%8E.png',
    id: 2668,
    is_ordinary: true,
    max_token: 4096,
    model: 'AI-AGENT',
    name: '人人乐家电商城售后',
    robot_id: '7b293d4c-21fa-4f9e-bfa4-2fd8edb0ab5a',
    type: 'AI-AGENT',
  },
  {
    avatar: '',
    color: '#C7F3E3',
    description:
      '接收员工的投诉与建议，帮员工查询工资条、年终奖、个税等，帮助发起请假/销假流程，查询社保、医保。',
    icon: 'https://bty-gemini-resource-prod.oss-cn-hangzhou.aliyuncs.com/ai/agentAvatar/1705904092167HR-%E5%91%98%E5%B7%A5HR%E5%8A%A9%E7%90%86.png',
    id: 2525,
    is_ordinary: true,
    max_token: 4096,
    model: 'AI-AGENT',
    name: '员工HR助理',
    robot_id: 'e0d28957-5d17-4bbc-b1d1-6cdedde2f77d',
    type: 'AI-AGENT',
  },
]

export function Text({ text }: { text: string }) {
  return (
    <div className='c-bg_3 text-20px font-500 c-op-40 w-100% h-100vh flex flex-items-center justify-center'>
      {text}
    </div>
  )
}

export function ShareChannel() {
  const bot = useLoaderData() as Bot

  const navigate = useNavigate()

  const [open, setOpen] = useState(false)

  const [type, setType] = useState('login')

  const { isEnable, isPublic, isLogin, authentic, getUser } =
    useShareAgentAuthentic({ bot, manual: false })

  useEffect(() => {
    if (isPublic === false) {
      getUser()
    }
  }, [isPublic, isLogin])

  const checkAndOpenLoginModal = () => {
    if (!isLogin) {
      // setOpen(true)
      return false
    } else {
      return true
    }
  }

  const DOM = (
    <>
      <Chatbot
        showPowerBy={true}
        showLogo={true}
        showChatMenu={true}
        showHistoryList={true}
        regularChatList={regularChatList}
        showDetailIcon={false}
        extraButton={
          <Button
            onClick={() => navigate(isLogin ? '/explore' : '/login')}
            className='c-#fff mr-10px'
            size='large'
            style={{
              background:
                'linear-gradient(143deg, #68caff 0%, #684aff 56%, #963aff 89%)',
            }}
          >
            免费创建智能体
          </Button>
        }
        BottomChildren={
          <div className='flex items-center justify-center py-16px bg-white border-top after:border-b-line after:border-b-op-60'>
            <Tooltip
              title={
                <img src={productDiscussionGroup} className='w-150px h-150px' />
              }
            >
              <Button size='large' type='primary'>
                技术支持与反馈
              </Button>
            </Tooltip>
          </div>
        }
        onBeforeSubmit={checkAndOpenLoginModal}
        handleBeforeFileUpload={checkAndOpenLoginModal}
      />
      <Modal
        open={open}
        footer={null}
        width={type === 'workspace' ? 648 : 448}
        closeIcon={false}
        maskClosable
        onCancel={() => {
          setOpen(false)
          setType('login')
        }}
        styles={{
          body: { padding: 0 },
        }}
        // bodyStyle={{ padding: 0 }}
      >
        {type === 'login' ? (
          <LoginOrRegister
            noRedirect={true}
            onSuccess={() => {
              getWorkspaceList().then(res => {
                if (res?.length > 0) {
                  setOpen(false)
                  return
                }
                if (res?.length <= 0) {
                  setType('workspace')
                }
              })
            }}
          />
        ) : (
          ''
        )}
        {type === 'workspace' ? (
          <CreateWorkspace
            onSuccess={() => {
              setOpen(false)
              setType('login')
            }}
          />
        ) : (
          ''
        )}
      </Modal>
    </>
  )

  if (isEnable === false) {
    // agent未启用
    return <Text text='agent未启用' />
  }

  if (isPublic === false) {
    // agent未公开
    return <Text text='agent未公开' />
  }

  if (!isPublic === false) {
    // agent公开
    return DOM
  }

  if (authentic === null) {
    return
  }

  if (authentic === false) {
    // 暂无权限
    return <Text text='暂无权限' />
  }

  return <div className='h-100vh'>{DOM}</div>
}

export default function ChatWithMobileView() {
  const { botId } = useParams()

  if (isMobileDevice()) {
    location.href = `${
      import.meta.env.VITE_CHATBOT_MOBILE_URL
    }/openchat?botId=${botId}`
    return
  }

  return <ShareChannel />
}
