import type { PromptStructItem } from '@apis/prompt/type'
import { PromptStructType } from '@apis/prompt/type'
import {
  TaskType,
  TaskWriteMethods,
  UncertaintyStrategy,
} from '@/features/prompt/constant/generatePrompt.ts'

export const DEFAULT_WEIGHT = 0.6

const PromptStructType2TitleMap: Record<PromptStructType, string> = {
  [PromptStructType.ROLE]: '角色',
  [PromptStructType.BACKGROUND]: '背景',
  [PromptStructType.SKILL]: '技能',
  [PromptStructType.TASK]: '任务',
  [PromptStructType.REQUIREMENTS]: '要求',
  [PromptStructType.OUTPUT]: '输出格式',
  [PromptStructType.CUSTOM]: '自定义',
}

const promptFormSubmitDefaultMap: Record<
  PromptStructType,
  () => Record<string, any>
> = {
  [PromptStructType.ROLE]: getRoleDefaultFormSubmit,
  [PromptStructType.BACKGROUND]: getBackgroundDefaultFormSubmit,
  [PromptStructType.SKILL]: getSkillDefaultFormSubmit,
  [PromptStructType.TASK]: getTaskDefaultFormSubmit,
  [PromptStructType.REQUIREMENTS]: getRequirementDefaultFormSubmit,
  [PromptStructType.OUTPUT]: getOutputDefaultFormSubmit,
  [PromptStructType.CUSTOM]: getCustomDefaultFormSubmit,
}

const DefaultStructPromptMap = {
  Agent: [
    PromptStructType.ROLE,
    PromptStructType.BACKGROUND,
    PromptStructType.SKILL,
    PromptStructType.TASK,
    PromptStructType.REQUIREMENTS,
    PromptStructType.OUTPUT,
  ],
  Flow: [
    PromptStructType.ROLE,
    PromptStructType.TASK,
    PromptStructType.REQUIREMENTS,
    PromptStructType.OUTPUT,
  ],
}

export function getDefaultStructPrompt(
  type: 'Agent' | 'Flow',
): PromptStructItem[] {
  const items = DefaultStructPromptMap[type]
  return items.map(type => ({
    type,
    title: PromptStructType2TitleMap[type],
    content: '',
    weight: DEFAULT_WEIGHT,
    form_submit: promptFormSubmitDefaultMap[type](),
  }))
}

export function generateCustomStructPrompt() {
  return {
    type: PromptStructType.CUSTOM,
    title: '',
    content: '',
    weight: DEFAULT_WEIGHT,
    form_submit: getCustomDefaultFormSubmit(),
  }
}

function getRoleDefaultFormSubmit() {
  return {
    role: '',
    scene: '',
    task: '',
    personality: ['阳光'],
    tone: ['正式'],
    outputLength: 1,
  }
}

function getBackgroundDefaultFormSubmit() {
  return {
    businessDescription: '',
    customerProfile: '',
  }
}

function getSkillDefaultFormSubmit() {
  return {
    skillInfo: '',
  }
}

function getTaskDefaultFormSubmit() {
  return {
    taskType: TaskType.QUERY,
    handleNoContent: UncertaintyStrategy.UNKNOWN,
    resolveUncertainInput: UncertaintyStrategy.UNKNOWN,
    taggingDesc: '',
    intentDesc: '',
    writeTarget: '',
    writeMethods: [TaskWriteMethods.NUM_EMOTION],
    customMethod: '',
  }
}

function getRequirementDefaultFormSubmit() {
  return {
    contentRequirements: ['认真思考'],
    showWorkings: false,
    checkAgain: true,
    outputLength: 1,
  }
}

function getOutputDefaultFormSubmit() {
  return {
    outputType: 'json',
  }
}

function getCustomDefaultFormSubmit() {
  return {
    customTopicDesc: '',
  }
}
