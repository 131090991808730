import { useMemoizedFn, useRequest } from 'ahooks'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { MessageInput } from '@bty/chat-ui'
import type { SelectProps } from 'antd'
import { Empty, Form, Spin, Switch } from 'antd'
import type { AgentConfigShortcut, Bot } from '@bty/chat-types'
import type { TableProps } from 'antd/lib'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import styled from '@emotion/styled'
import { shortcutsFormat } from '@bty/chat-logic'
import { uniq } from 'lodash-es'
import type { MessageInputInstance } from '@bty/chat-ui/src/components/messageInput/MessageInput'
import { getAgentShortcutsList } from '@apis/agent'
import {
  AppLogo,
  Button,
  Input,
  Modal,
  Select,
  Table,
  TextArea,
} from '@/components'
import {
  DEFAULT_SHORTCUTS,
  DEMO_SHORTCUT,
  PROMPT_SHORTCUT,
  TYPE_OPTIONS,
} from '../constant/shortcut'
import { useWorkspaceStore } from '@/store'

const StyledTable = styled(Table)`
  .ant-table-tbody .ant-table-row .ant-table-cell {
    border-bottom: none;
  }
`
export interface ShortcutsModalProps {
  flowId: string
  versionId: string
  initialData?: AgentConfigShortcut
  onSave?: (value?: Record<string, any>) => void
  toAddFlow?: () => void
  toAddPlugin?: () => void
}

function previewFileUpload(_params: { file: File }) {
  return Promise.resolve({ url: '' })
}

type ShortcutType = 'flows' | 'utility' | 'prompt'

interface SettingTableProps {
  columns?: TableProps['columns']
  dataSource?: any[]
  value?: string[]
  onChange?: (value: string[]) => void
}
function SettingTable({ dataSource, value = [], onChange }: SettingTableProps) {
  const handleChange = useMemoizedFn((key: string, checked: boolean) => {
    const newValue = checked
      ? value.filter(v => v !== key)
      : uniq([...value, key])

    onChange?.(newValue)
  })

  const columns: TableProps['columns'] = useMemo(() => {
    return [
      {
        title: '参数',
        dataIndex: 'label',
        key: 'label',
      },
      {
        title: '类型',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: '默认值',
        dataIndex: 'default',
        key: 'default',
      },
      {
        title: '必填',
        dataIndex: 'required',
        key: 'required',
        render: (value: boolean) => (value ? '必填' : '/'),
      },
      {
        title: '是否显示',
        render: (_, record) => {
          const needShow = record.required && !record.default
          return (
            <Switch
              defaultChecked={!value?.includes?.(record.variableName)}
              size='small'
              disabled={needShow}
              onChange={checked => {
                handleChange(record.variableName, checked)
              }}
            />
          )
        },
      },
    ]
  }, [value, handleChange])

  return (
    <StyledTable
      size='small'
      columns={columns}
      dataSource={dataSource}
      pagination={false}
    />
  )
}

function ToolsSelectLabel({
  name,
  icon,
  color,
}: {
  name: string
  icon: string
  color: string
}) {
  return (
    <div className='flex items-center h-[100%]'>
      <AppLogo
        size={16}
        fillSize={12}
        value={icon}
        color={color}
        style={{ borderRadius: 4 }}
        type='emoji'
        imgStyle={{ borderRadius: 4 }}
      />
      <div className='ml-[6px]'>{name}</div>
    </div>
  )
}

interface FunctionIdOptions {
  label: React.ReactNode
  value: string
  disabled: boolean
}

function buildFunctionIdOptions(
  data: Record<string, any>,
  type: string,
): FunctionIdOptions[] {
  return (data?.[type] || []).map((j: Record<string, any>) => {
    return {
      label: (
        <ToolsSelectLabel
          name={j.display_name || j.name}
          icon={j.icon || j.metadata?.icon || ''}
          color={j.color || j.metadata?.color || ''}
        />
      ),
      value: j.function_id,
      disabled: !j.is_enable,
    }
  })
}

function buildHiddenParametersDataSource(
  data: Record<string, any>,
  type: ShortcutType,
  functionId: string,
) {
  const dataSource =
    shortcutsFormat({
      shortcuts: [
        {
          function_id: functionId,
          type,
          short_name: '',
          hidden_parameters: [],
        },
      ],
      config: {
        ...(data || {}),
      },
    } as unknown as Bot)?.[0]?.formConfig || []
  return dataSource
}
export const ShortcutsModal = NiceModal.create(
  ({
    flowId,
    versionId,
    initialData,
    onSave,
    toAddFlow,
    toAddPlugin,
  }: ShortcutsModalProps) => {
    const [form] = Form.useForm()
    const currentWorkspaceId = useWorkspaceStore(
      state => state.currentWorkspaceId,
    )

    const modal = useModal()

    const [values] = useState<AgentConfigShortcut>(
      initialData || DEFAULT_SHORTCUTS,
    )

    const messageInputRef = useRef<MessageInputInstance>()

    const { data, loading } = useRequest(getAgentShortcutsList, {
      defaultParams: [flowId, versionId, currentWorkspaceId],
      refreshDeps: [flowId, versionId],
    })

    const type: ShortcutType = Form.useWatch('type', form)
    const functionId = Form.useWatch('function_id', form)
    const shortName = Form.useWatch('short_name', form)
    Form.useWatch('hidden_parameters', form)

    const flowOptions = useMemo(() => {
      return data ? buildFunctionIdOptions(data, 'flows') : []
    }, [data])

    const pluginOptions = useMemo(() => {
      return data ? buildFunctionIdOptions(data, 'utility') : []
    }, [data])

    const dataSources = useMemo(() => {
      return buildHiddenParametersDataSource(data || [], type, functionId)
    }, [type, functionId, data])

    useEffect(() => {
      if (!functionId) {
        messageInputRef?.current?.hideShortcutsForm?.()
      }
    }, [functionId])

    useEffect(() => {
      if (!functionId) {
        messageInputRef?.current?.hideShortcutsForm?.()
      }
    }, [functionId])

    const shortcuts = useMemo(() => {
      const formValues: AgentConfigShortcut = form.getFieldsValue()

      const { short_name } = formValues
      if (formValues.type === 'prompt') {
        return [{ ...PROMPT_SHORTCUT, title: short_name, meta: formValues }]
      }

      return formValues?.function_id
        ? shortcutsFormat({
            shortcuts: [formValues],
            config: {
              ...(data || {}),
            },
          } as unknown as Bot)
        : [DEMO_SHORTCUT]
    }, [data, form, functionId, shortName])

    useEffect(() => {
      if (!shortcuts?.[0]?.formConfig?.length) {
        messageInputRef?.current?.hideShortcutsForm?.()
      }
    }, [shortcuts])

    const handleSave = async () => {
      try {
        const values = await form.validateFields()
        onSave?.(values)
      } catch (error) {
        console.warn(error)
      }
    }

    const handleToFlow = useMemoizedFn(() => {
      modal.hide()
      toAddFlow?.()
    })

    const handleToPlugin = useMemoizedFn(() => {
      modal.hide()
      toAddPlugin?.()
    })

    const handleFlowOptionChange: SelectProps['onChange'] = useMemoizedFn(
      value => {
        form.setFieldsValue({
          short_name:
            (data?.flows || []).find(v => v.function_id === value)?.name || '',
        })
      },
    )

    const handlePluginOptionChange: SelectProps['onChange'] = useMemoizedFn(
      value => {
        form.setFieldsValue({
          short_name:
            (data?.utility || []).find(v => v.function_id === value)
              ?.display_name || '',
        })
      },
    )

    return (
      <Modal
        width={1120}
        open={modal.visible}
        title='创建快捷入口'
        footer={null}
        onCancel={modal.remove}
        styles={{
          body: {
            padding: 0,
          },
        }}
      >
        <Spin spinning={loading}>
          <div className='flex'>
            <div className='w-62% flex flex-col'>
              <div className='h-480px px-24px py-16px overflow-auto'>
                <Form form={form} initialValues={values}>
                  <div className='mb-24px'>
                    <div className='mb-8px'>
                      <span>执行内容</span>
                      <span className='text-#FF512B ml-4px'>*</span>
                    </div>
                    <div className='w-full'>
                      <Form.Item name='type' required noStyle>
                        <Select
                          className='w-full h-36px'
                          onChange={() =>
                            form.setFieldsValue({ function_id: '' })
                          }
                          options={TYPE_OPTIONS}
                        />
                      </Form.Item>
                      {type === 'flows' && (
                        <Form.Item
                          className='w-full mt-12px h-36px [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-4px'
                          name='function_id'
                          required
                          rules={[{ required: true, message: '请选择工作流' }]}
                        >
                          <Select
                            className='flex-1 h-36px'
                            placeholder='请选择工作流'
                            options={flowOptions}
                            onChange={handleFlowOptionChange}
                            notFoundContent={
                              <Empty
                                className='ant-empty-small'
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                  <span>
                                    请先在Agent中添加工作流，
                                    <span
                                      className='text-primary cursor-pointer'
                                      onClick={handleToFlow}
                                    >
                                      去添加
                                    </span>
                                  </span>
                                }
                              />
                            }
                          />
                        </Form.Item>
                      )}
                      {type === 'utility' && (
                        <Form.Item
                          className='w-full mt-12px h-36px [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-4px'
                          name='function_id'
                          required
                          rules={[{ required: true, message: '请选择插件' }]}
                        >
                          <Select
                            className='flex-1 h-36px'
                            placeholder='请选择插件'
                            options={pluginOptions}
                            onChange={handlePluginOptionChange}
                            notFoundContent={
                              <Empty
                                className='ant-empty-small'
                                image={Empty.PRESENTED_IMAGE_SIMPLE}
                                description={
                                  <span>
                                    请先在Agent中添加插件，
                                    <span
                                      className='text-primary cursor-pointer'
                                      onClick={handleToPlugin}
                                    >
                                      去添加
                                    </span>
                                  </span>
                                }
                              />
                            }
                          />
                        </Form.Item>
                      )}
                      {type === 'prompt' && (
                        <Form.Item
                          className='w-full mt-12px h-36px [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-4px'
                          name='prompt'
                          required
                          rules={[{ required: true, message: '请填写指令' }]}
                        >
                          <TextArea
                            className='flex-1 h-36px'
                            placeholder='输入你的指令，例如：搜索总结科技新闻'
                          />
                        </Form.Item>
                      )}
                      <div className='mb-24px'>
                        <div className='mb-8px'>
                          <span>快捷按钮名称</span>
                          <span className='text-#FF512B ml-4px'>*</span>
                        </div>
                        <div>
                          <Form.Item
                            className='h-36px [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-4px'
                            name='short_name'
                            required
                            rules={[
                              { required: true, message: '请输入快捷按钮名称' },
                            ]}
                          >
                            <Input placeholder='请输入快捷按钮名称' />
                          </Form.Item>
                        </div>
                      </div>
                    </div>
                    {type !== 'prompt' && (
                      <div className='mb-24px'>
                        <div className='mb-8px'>
                          <span>输入设置</span>
                        </div>
                        <div>
                          <Form.Item
                            className='h-36px [&_.ant-form-item-explain-error]:m-0 [&_.ant-form-item-explain]:mt-4px'
                            name='hidden_parameters'
                            required
                          >
                            <SettingTable
                              columns={[]}
                              dataSource={dataSources}
                            />
                          </Form.Item>
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              </div>
              <div className='h-60px flex  items-center justify-end px-24px b-t-line b-t-1 b-t-op-30'>
                <Button onClick={modal.remove}>取消</Button>
                <Button type='primary' className='ml-12px' onClick={handleSave}>
                  保存
                </Button>
              </div>
            </div>
            <div className='flex-1 bg-line bg-op-30 p-16px flex flex-col  relative'>
              <div className='absolute top-0 left-0 right-0 bottom-0 z-10 w-full h-full'></div>
              <div className='text-14px'>效果预览</div>
              <div className='grid place-items-center h-full z-1'>
                <MessageInput
                  ref={messageInputRef}
                  disabled
                  className='w-full'
                  inputTips={[]}
                  initialFocus
                  preview
                  textareaAutoFocus={false}
                  onFileUpload={previewFileUpload}
                  shortcuts={shortcuts}
                />
              </div>
            </div>
          </div>
        </Spin>
      </Modal>
    )
  },
)
