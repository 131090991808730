import { useMemoizedFn } from 'ahooks'
import { useRef } from 'react'
import { FlowExecuteEnv, FlowStatus } from '@apis/flow/type'
import {
  StreamMessageType,
  useStreamingExecuteFlow,
} from '@/hooks/useStreamingExecuteFlow'
import { useLogger } from '../context'
import { API_CODE_CODE } from '@/constants/request'
import { LIMITED_ERROR_COMBO_NOT_ENOUGH } from '@/constants/pay'
import { tryGetObject } from '../util'

export function useRunFlow(flowId?: string, versionId?: string) {
  const {
    startType,
    form,
    webhookJsonRef,

    setTaskId,
    setLoading,
    setOutput,
  } = useLogger()

  const messageType = useRef(StreamMessageType.PIECEWISE)

  const { startExecute } = useStreamingExecuteFlow({
    socketUrl: '/v1/flow/ws_execute_flow',
    flowId,
    onStepStart: ({ message_type }) => {
      messageType.current = message_type
      setOutput({
        result: '',
      })
    },
    onMessage: message => {
      setOutput(prev => ({
        result:
          messageType.current === StreamMessageType.PIECEWISE
            ? `${prev.result ?? ''}${message}`
            : message,
      }))
    },
    onFinish: (result, hasError) => {
      let { status, run_result, message, code } = result

      setLoading(false)
      if (code === API_CODE_CODE.COMBO_NOT_ENOUGH) {
        message = LIMITED_ERROR_COMBO_NOT_ENOUGH
      }

      if (hasError) {
        setOutput({
          status: FlowStatus.FAIL,
          result: '',
          errorMsg: message,
        })
        return
      }

      const isError = [
        API_CODE_CODE.COMBO_NOT_ENOUGH,
        API_CODE_CODE.COMBO_NOT_GPT4_TIME_ENOUGH,
        API_CODE_CODE.COMBO_NOT_QWENPLUS_TIME_ENOUGH,
      ].includes(code)

      const computedStatus = isError ? FlowStatus.FAIL : status
      setTaskId(result.task_id)
      setOutput({
        status: computedStatus,
        result: status === FlowStatus.SUCCEEDED ? run_result : null,
        errorStepName: message.step_name,
        errorMsg:
          computedStatus === FlowStatus.FAIL
            ? isError
              ? message
              : `运行流程节点在${message.step_name}出错, 详情请查看日志`
            : null,
      })
    },
  })

  const handleRun = useMemoizedFn(async () => {
    let values
    if (startType === 'form') {
      await form.validateFields()
      values = form.getFieldsValue(true)
    } else {
      values = tryGetObject(webhookJsonRef.current)
    }
    if (!versionId || !flowId) return

    const params = {
      flowId,
      versionId,
      env: FlowExecuteEnv.TESTING,
      inputData: values,
    }
    setLoading(true)

    setOutput({
      result: '',
      errorMsg: '',
    })

    startExecute(params)
  })

  return handleRun
}
