import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from '@emotion/styled'
import { useAuth, Access } from '@bty/react-auth'
import { DingtalkOutlined } from '@ant-design/icons'
import { RoleCode } from '@apis/authority/type'
import ContentURL from '../components/ContentURL'
import { TitleWarpper } from '../utils'
import { Button, IconFont, usePageModal } from '@/components'
import { useWorkspaceStore } from '@/store'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

const DivWrapper = styled.div`
  background: rgba(56, 166, 255, 0.16);
  &:hover {
    background: rgba(56, 166, 255, 0.12);
  }
`

export default ({ flowId }: { flowId?: string }) => {
  const { access } = useAuth()
  const [url] = useState(import.meta.env.VITE_AI_API_BASE_URL)
  const navigate = useNavigate()
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)

  const pageModal = usePageModal()
  const { scrollRef } = useScrollBar()

  return (
    <div
      ref={scrollRef}
      className='flex-1 h-100% p-20px overflow-y-auto bg-#fff rounded-12px'
    >
      <div className='adapt:mb-24px flex flex-items-center justify-between'>
        <TitleWarpper text='访问Webhook' marginBottom={0} />
        <Access
          access={access.role([RoleCode.DEVELOPER, RoleCode.ADMINISTRATOR])}
          hide
        >
          <Button
            type='default'
            style={{ height: 32 }}
            onClick={() =>
              navigate(`/workspace/${workspaceId}/settings/overview`)
            }
            icon={<IconFont name='key' className='text-12px' />}
          >
            获取API key
          </Button>
        </Access>
      </div>
      <div
        className='border-rd-8px p-12px flex gap-8px adapt:mb-24'
        style={{ background: 'rgba(56, 166, 255, 0.08)' }}
      >
        <IconFont name='info' className='color-#38A6FF text-14px' />
        <div className='flex flex-col flex-justify-between text-12px'>
          该应用无法直接使用，请在外部系统通过地址调用
        </div>
      </div>
      <Access
        access={access.role([RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER])}
        hide
      >
        <TitleWarpper text='Webhook URL' fontSize={14} />
        <ContentURL
          url={`${url}/v1/public_api/webhook/${flowId}/execute_flow?Access-Key=YOUR_API_KEY&Workspace-Id=${workspaceId}`}
          style={{ marginBottom: 20 }}
        />
        <TitleWarpper text='常用外部系统使用教程' fontSize={14} />
        <div className='flex gap-12px m-t-12px'>
          <DivWrapper
            onClick={() =>
              pageModal.show({
                url: 'https://alidocs.dingtalk.com/i/nodes/NZQYprEoWo5Oep9lTg2rYDdnJ1waOeDk?doc_type=wiki_doc#',
              })
            }
            className='cursor-pointer flex flex-items-center gap-8px p-4px rd-6px text-12px font-500'
          >
            <div className='flex flex-items-center flex-justify-center h-24px w-24px bg-#419AFF rd-4px'>
              <DingtalkOutlined className='color-#fff text-16px' />
            </div>
            钉钉
            <div className='flex flex-items-center flex-justify-center h-16px w-16px'>
              <IconFont
                name='left-arrow'
                className='text-7px'
                style={{ color: 'rgba(141, 141, 153, 0.6)' }}
              />
            </div>
          </DivWrapper>
          {/* <DivWrapper className="cursor-pointer flex flex-items-center gap-8px p-4px rd-6px text-12px font-500"> */}
          {/*  <IconFont name="juxing487" className="text-24px" /> */}
          {/*  飞书 */}
          {/*  <div className="flex flex-items-center flex-justify-center h-16px w-16px"> */}
          {/*    <IconFont name="left-arrow" className="text-7px" style={{ color: 'rgba(141, 141, 153, 0.6)' }} /> */}
          {/*  </div> */}
          {/* </DivWrapper> */}
        </div>
      </Access>
    </div>
  )
}
