import type { AgentRuleConfig } from '@bty/chat-types'
import { getRelatedQuestionPrompt } from '@apis/agent'
import { TextArea } from '@/components'
import { DropdownControl } from './DropdownControl'

const menus = [
  {
    label: 'AI 自动生成',
    key: 'AI',
  },
  {
    label: '自定义规则',
    key: 'CUSTOM_PROMPT',
  },
  {
    label: '关闭',
    key: 'CLOSE',
  },
] as const

type RelatedQuestionValue = AgentRuleConfig['related_questions_obj']

interface RelatedQuestionControlProps {
  agent: {
    flowId: string
    versionId: string
  }
  value?: RelatedQuestionValue
  onChange?: (value: RelatedQuestionValue) => void
}

export function RelatedQuestionControl({
  agent,
  value,
  onChange,
}: RelatedQuestionControlProps) {
  const isCustom = value?.related_questions_type === 'CUSTOM_PROMPT'

  return (
    <DropdownControl
      menu={menus}
      value={value?.related_questions_type}
      label='推荐提问'
      tooltip='Agent 每次回答后，推荐一些提问'
      onChange={key => {
        const type =
          key as NonNullable<RelatedQuestionValue>['related_questions_type']
        if (type === 'CUSTOM_PROMPT') {
          getRelatedQuestionPrompt(agent.flowId, agent.versionId).then(
            prompt => {
              const newValue = {
                related_questions_type: type,
                custom_related_questions_prompt: prompt,
              }
              onChange?.(newValue)
            },
          )
          return
        }
        const newValue = {
          related_questions_type: type,
          custom_related_questions_prompt:
            value?.custom_related_questions_prompt,
        }
        onChange?.(newValue)
      }}
    >
      {isCustom ? (
        <TextArea
          className='!resize-none py-6 mb-16 !bg-white hover:border-primary hover:border-op-100 not-focus:border-line not-focus:border-op-80'
          value={value.custom_related_questions_prompt}
          autoSize={{ minRows: 2, maxRows: 17 }}
          placeholder='根据用户历史聊天记录生成推荐问题'
          onChange={event => {
            const prompt = event.target.value
            onChange?.({
              related_questions_type: value?.related_questions_type,
              custom_related_questions_prompt: prompt,
            })
          }}
        />
      ) : null}
    </DropdownControl>
  )
}
