import classNames from 'classnames'
import { memo, useEffect, useLayoutEffect, useMemo, useRef } from 'react'
import { useMemoizedFn, useSessionStorageState } from 'ahooks'
import { isEqual, isUndefined } from 'lodash-es'
import type { NodeProps } from 'reactflow'
import { NodeResizeControl, Position, useReactFlow } from 'reactflow'
import {
  FLOW_DRAFT_LOCK_STATUS,
  useFlowDraftStore,
  useFlowInteractionStore,
  useNodeStore,
} from '@/store'
import theme from '@/constants/theme'
import { Button, IconFont, usePageModal } from '@/components'
import { DefaultHandle } from '../../flow/components/DefaultHandle'
import { useVariableDeps } from '../hooks/useVariableDeps'
import { isMac } from '../llm/LLMNodePanel/utils'
import { FLOW_ERROR_LOG } from '@/features/logger/constants'
import type { Operation } from './components'
import { NodeHeader, NoteComment } from './components'
import { controlStyle, fullscreenNodePanelType } from './const'
import { NodeContext } from './context'
import {
  NODE_ZINDEX,
  useNodeData,
  useNodeElement,
  useNodeLayout,
  useNodePanel,
  useNodeResize,
  useNodeValidate,
} from './hooks'
import type { GeneralNodeData, NodeComponent } from './types'
import { NodeType, PLUGIN_STATUS, SUB_FLOW_STATUS } from './types'

function TooltipIconWrapper({ iconList }: { iconList: string[] }) {
  return (
    <div className='flex gap-[2px] c-[#fff] text-[14px] items-center h-[100%]'>
      {iconList.map(item => (
        <span
          key={item}
          className='p-[1px] rounded-[2px]'
          style={{ background: 'rgba(255, 255, 255, 0.12)' }}
        >
          <IconFont name={item} />
        </span>
      ))}
    </div>
  )
}

export function withConnectFlowNode<T extends GeneralNodeData>(
  NodeComponent: NodeComponent<T>,
) {
  const { meta } = NodeComponent

  const {
    canDelete = true,
    backgroundColor = theme.colors.bg_1,
    // borderColor,
    icon,
    isStart,
    isEnd,
    isEmpty,
    customSourceHandle = false,
    customTargetHandle = false,
    resizeable = false,
    resizeConfig,
    isToolNode = false,
    type,
  } = meta

  const hasNodeHeader = !isToolNode && !isEmpty

  return memo((props: NodeProps<T>) => {
    // const { data } = props
    const ref = useRef<HTMLDivElement>(null)
    const pageModal = usePageModal()

    const {
      nodeValidateResultMap,
      nodeErrorStatusMap,
      viewPortNodeId,
      lockStatus,
    } = useFlowDraftStore(
      s => ({
        nodeValidateResultMap: s.nodeValidateResultMap,
        nodeErrorStatusMap: s.nodeErrorStatusMap,
        viewPortNodeId: s.viewPortNodeId,
        lockStatus: s.lockStatus,
      }),
      isEqual,
    )
    const toggleNodeEnable = useFlowDraftStore(s => s.toggleNodeEnable)
    const copyNodeById = useFlowDraftStore(s => s.copyNodeById)
    const cutNodes = useFlowDraftStore(s => s.cutNodes)
    const isTemplateFlow = useFlowDraftStore(s => s.isTemplateFlow)
    const { isAddMode } = useFlowInteractionStore()
    const { activatedNodeId, commentVisibleMap } = useNodeStore(
      store => ({
        activatedNodeId: store.activatedNodeId,
        commentVisibleMap: store.commentVisibleMap,
      }),
      isEqual,
    )
    const setCommentVisibleMap = useNodeStore(s => s.setCommentVisibleMap)

    const { getNodes, getEdges } = useReactFlow()

    const commentVisible = useMemo(
      () => commentVisibleMap[props.id],
      [commentVisibleMap, props.id],
    )

    const { onDelete, onSaveChange, data } = useNodeData<T>(props)

    const {
      nodeElement,
      nodeRef,
      focused,
      isFocusWithin,
      toBeRemoved,
      setNodeToBeRemoved,
    } = useNodeElement(props.id, props.selected, data)

    const { validateNode, registerValidateFunction, removeValidateFunction } =
      useNodeValidate(props.id)

    useNodeLayout(nodeRef, { data, isFocusWithin, nodeElement, toBeRemoved })

    const { variables } = useVariableDeps(props.id, getNodes(), getEdges())

    const { onResize, onResizeEnd, onResizeStart, nodeSize, isResizing } =
      useNodeResize(props.id)

    const { onOpenPanel, onClosePanel } = useNodePanel(props.id, meta, data)

    const isOpenedPanel = useMemo(() => {
      return (
        props.id === activatedNodeId &&
        NodeType.START !== props.type &&
        NodeType.END !== props.type &&
        NodeType.CONDITION !== props.type &&
        NodeType.LOOP !== props.type &&
        NodeType.LOOP_RESULT !== props.type
      )
    }, [props.id, activatedNodeId, props.type])

    const onTitleSave = useMemoizedFn((name: string) => {
      onSaveChange({ name } as any, true, {
        newVariableName: name,
        oldVariableName: data.name || '',
      })
    })

    const onCommentSave = useMemoizedFn((comment: string) => {
      onSaveChange({ comment } as any, true)
      !comment &&
        setTimeout(() => {
          setCommentVisibleMap({ [props.id]: false })
        }, 100)
    })

    const onToggleCommentVisible = useMemoizedFn(() => {
      setCommentVisibleMap({ [props.id]: !commentVisible })
    })

    useEffect(() => {
      if (isTemplateFlow && data.comment) {
        setCommentVisibleMap({ [props.id]: true })
      }
    }, [])

    const locked = useMemo(() => {
      return lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK
    }, [lockStatus])

    const onNodeCopy = useMemoizedFn((successMessage?: string) => {
      copyNodeById(props.id, successMessage)
    })

    const nodeEnable = useMemo(() => {
      if (data.actionType === NodeType.FLOW) {
        if (isUndefined(data.isEnable)) {
          return true
        } else {
          return data.isEnable
        }
      } else if (data.actionType === NodeType.PLUGIN) {
        if (isUndefined(data.isEnable)) {
          return true
        } else {
          return (
            data.isEnable &&
            (data.plugin_status === PLUGIN_STATUS.ACTIVE || !data.plugin_id)
          )
        }
      } else {
        return isUndefined(data.isEnable) ? true : data.isEnable
      }
    }, [data.isEnable, data.flow_status, data.actionType, data.flow_id])

    const onStatusChange = useMemoizedFn(status => {
      setNodeToBeRemoved(status === 'error')
    })

    const getPopupContainer = useMemoizedFn(() => {
      return nodeElement as HTMLElement
    })

    const nodeDisabled = useMemo(() => {
      return data.flow_status !== SUB_FLOW_STATUS.ACTIVE && !!data.flow_id
    }, [type, data.isEnable, data.flow_status, data.actionType, data.flow_id])

    const onToggleNodeEnable = useMemoizedFn(() => {
      if (locked) {
        return
      }
      toggleNodeEnable(props.id, !nodeEnable)
    })

    const nodeOperations = useMemo<Operation[]>(() => {
      const commentTooltipsContent = commentVisible
        ? '隐藏注释'
        : data.comment
          ? '展示注释'
          : '添加注释'
      return [
        {
          name: 'help-link',
          icon: 'shiyongshuimeng',
          tooltip: '帮助文档',
          hide: !data.helpLink,
          action: () => {
            pageModal.show({
              url: data.helpLink,
            })
          },
          ignoreHoverControl: true,
        },
        {
          name: 'openPluginInfo',
          icon: 'super-link',
          tooltip: '',
          hide: [
            NodeType.START,
            NodeType.END,
            NodeType.LLM,
            NodeType.CODE,
            NodeType.MEMORY,
            NodeType.KNOWLEDGE,
            NodeType.DATABASE,
            NodeType.INSERT_MEMORY,
            NodeType.API,
            NodeType.TEXT,
            NodeType.EMPTY,
            NodeType.APP,
            NodeType.CONDITION,
            NodeType.CONDITION_RESULT,
            NodeType.INTENT,
            NodeType.INTENT_RESULT,
            NodeType.FLOW,
            NodeType.LOOP,
            NodeType.LOOP_RESULT,
            NodeType.LLM_BATCH,
            NodeType.PYTHON,
            NodeType.JAVASCRIPT,
            NodeType.TEMPLATE,
          ].includes(type),
          action: () => {
            pageModal.show({
              url: `/plugin/info/${data.plugin_id}?pageType=infoOnly`,
            })
          },
          ignoreHoverControl: true,
        },
        {
          name: 'fullscreen',
          icon: fullscreenNodePanelType.includes(type)
            ? 'kuozhanmianban'
            : 'zhankaiyouzhaimianban',
          tooltip: fullscreenNodePanelType.includes(type)
            ? '全屏调试'
            : '侧栏展开',
          action: onOpenPanel,
          disabled: nodeDisabled || locked,
          hide: [
            NodeType.START,
            NodeType.END,
            NodeType.CONDITION,
            NodeType.LOOP,
            NodeType.LOOP_RESULT,
            NodeType.LLM_BATCH,
          ].includes(type),
          ignoreHoverControl: true,
        },
        {
          name: 'more',
          icon: 'gengduo',
          hide: [NodeType.START, NodeType.END].includes(type),
          disabled: locked,
          ignoreHoverControl: true,
          children: [
            {
              name: 'comment',
              icon: 'zhushi',
              text: commentTooltipsContent,
              tooltip: commentTooltipsContent,
              hide: isStart || isEnd,
              disabled: locked,
              action: onToggleCommentVisible,
              ignoreHoverControl: true,
            },
            {
              disabled: nodeDisabled,
              name: 'disable',
              icon: nodeEnable ? 'jinyongjiedian' : 'qiyongjiedian',
              tooltip: nodeEnable ? '禁用节点' : '启用节点',
              text: nodeEnable ? '禁用节点' : '启用节点',
              action: onToggleNodeEnable,
              hide: [
                NodeType.START,
                NodeType.END,
                NodeType.LOOP_RESULT,
              ].includes(type),
            },
            {
              name: 'copy',
              icon: 'copy',
              tooltip: (
                <TooltipIconWrapper
                  iconList={isMac() ? ['command', 'C'] : ['ctrl', 'C']}
                />
              ),
              text: '复制',
              disabled: nodeDisabled,
              action: () =>
                onNodeCopy('复制成功！选择节点后可进行粘贴，Esc取消'),
              hide: [
                NodeType.START,
                NodeType.END,
                NodeType.TEMPLATE,
                NodeType.LOOP_RESULT,
                // NodeType.CONDITION,
                // NodeType.LOOP,
              ].includes(type),
            },
            {
              name: 'cut',
              icon: 'jianqi',
              disabled: nodeDisabled,
              tooltip: (
                <TooltipIconWrapper
                  iconList={isMac() ? ['command', 'X'] : ['ctrl', 'X']}
                />
              ),
              text: '剪切',
              action: () => {
                const currentNode = getNodes().find(n => n.id === props.id)
                if (!currentNode) return
                cutNodes(
                  [currentNode],
                  '剪切成功！选择节点后可进行粘贴，Esc取消',
                )
              },
              hide: [
                NodeType.START,
                NodeType.END,
                NodeType.CONDITION,
                NodeType.INTENT,
                NodeType.LOOP,
                NodeType.TEMPLATE,
                NodeType.LOOP_RESULT,
              ].includes(type),
            },
            {
              name: 'delete',
              icon: 'shanshu',
              tooltip: '删除',
              text: '删除',
              action: onDelete,
              hide: !canDelete,
            },
          ],
        },
      ]
    }, [
      onDelete,
      onOpenPanel,
      data.plugin_id,
      data.comment,
      commentVisible,
      nodeEnable,
      type,
      nodeDisabled,
      locked,
    ])

    const contentWrapperClassName = useMemo(() => {
      return !isToolNode
        ? classNames('relative flex-1 b-t-none p-t-none rounded-b-8px', {
            'w-420px': !resizeable,
            'important:b-t-solid overflow-hidden rounded-8px': isEmpty,
            'cursor-not-allowed': !nodeEnable,
          })
        : ''
    }, [isEmpty, isToolNode, nodeEnable, resizeable])

    const headerStyle = useMemo(() => {
      return {
        backgroundColor,
      }
    }, [backgroundColor])

    const nodeHeaderName = useMemo(() => {
      if ([NodeType.PLUGIN, NodeType.TEMPLATE].includes(meta.type)) {
        const displayName = (data?.displayName as string) || ''
        return displayName
      } else {
        return meta.typeName
      }
    }, [meta, data])

    const nodeHeaderIcon = useMemo(() => {
      if (meta.type === NodeType.PLUGIN) {
        const nodeIcon = (data?.icon as string) || ''
        return nodeIcon
      } else if (meta.type === NodeType.TEMPLATE) {
        const nodeIcon = (data?.icon as string) || ''
        return nodeIcon
      } else {
        return icon
      }
    }, [meta, icon])

    const hasMask = useMemo(() => {
      return (
        isAddMode &&
        ![
          NodeType.LOOP_RESULT,
          NodeType.CONDITION_RESULT,
          NodeType.INTENT_RESULT,
        ].includes(type as NodeType)
      )
    }, [isAddMode, type])

    const nodeDisableAreaVisible = useMemo(() => {
      return (
        !nodeEnable &&
        ![NodeType.CONDITION_RESULT, NodeType.INTENT_RESULT].includes(
          type as NodeType,
        )
      )
    }, [nodeEnable, type])

    const [flowErrorLog] = useSessionStorageState<any>(FLOW_ERROR_LOG)

    useEffect(() => {
      if (
        data.name === flowErrorLog?.errorNodeName &&
        flowErrorLog?.run_type === 'STEP_RUN'
      ) {
        onOpenPanel()
      }
    }, [flowErrorLog])

    const doubleClickNodeHead = () => {
      if (locked || !nodeEnable || nodeDisabled) {
        return
      }
      onOpenPanel()
    }

    useLayoutEffect(() => {
      if (ref.current && viewPortNodeId === props.id) {
        ref.current.focus()
      }
    }, [viewPortNodeId, props.id])

    return (
      <div
        className='relative focus:outline-none'
        style={isFocusWithin ? { zIndex: NODE_ZINDEX } : {}}
        tabIndex={0}
        ref={ref}
      >
        {!isToolNode && commentVisible && (
          <NoteComment content={data.comment} onSave={onCommentSave} />
        )}

        <div
          className={classNames(
            'group nodrag relative b-rd-8px bg-white h-100% flex flex-col outline-solid hover:shadow-[0_8px_24px_0px_rgba(0,0,0,0.12)]',
            {
              'shadow-[0_8px_24px_0px_rgba(0,0,0,0.1)]': focused,
              'pointer-events-none after:content-[""] after:pointer-events-none after:absolute after:z-100 after:top-0 after:left-0 after:w-100% after:h-100% after:bg-#fff after:bg-op-60':
                hasMask,
              'outline-primary outline-2': focused,
              'outline-transparent outline-1': !focused,
              '!outline-error':
                nodeErrorStatusMap[props.id] ||
                nodeValidateResultMap[props.id] === false ||
                toBeRemoved ||
                nodeDisabled,
            },
            meta.classNames,
          )}
        >
          {isOpenedPanel && (
            <div
              onDoubleClick={e => {
                e.stopPropagation()
                onClosePanel()
              }}
              className='bg-[rgba(243,243,247)] absolute w-100% h-100% z-100  b-rd-8px flex flex-col items-center justify-center border-primary b-1'
            >
              <IconFont
                name='shouqiyouzhaimianban'
                className='text-48px c-[rgba(141,141,153,0.24)]'
              ></IconFont>
              <p className='mt-12px c-[rgba(141,141,153,0.6)]'>
                双击此区域收起右侧面板
              </p>
            </div>
          )}
          {/* 节点Header */}
          {hasNodeHeader && (
            <NodeHeader
              nodeElement={nodeElement}
              onDoubleClick={doubleClickNodeHead}
              error={
                toBeRemoved ||
                nodeValidateResultMap[props.id] === false ||
                nodeErrorStatusMap[props.id]
              }
              style={headerStyle}
              icon={nodeHeaderIcon}
              name={nodeHeaderName}
              disabled={locked}
              value={data.name}
              nodeEnable={nodeEnable}
              isFocusWithin={isFocusWithin}
              onSave={onTitleSave}
              onStatusChange={onStatusChange}
              nodeId={props.id}
              getPopupContainer={getPopupContainer}
              nodeType={props.type as NodeType}
              operations={nodeOperations}
            />
          )}
          {/* 节点Header */}
          {/* 主体内容 */}
          <div ref={nodeRef} className={contentWrapperClassName}>
            <NodeContext.Provider
              value={{
                onSaveChange,
                validateNode,
                registerValidateFunction,
                removeValidateFunction,
                focused,
                data,
                id: props.id,
              }}
            >
              <NodeComponent
                id={props.id}
                data={data}
                variables={variables}
                isOpenedPanel={isOpenedPanel}
                isResizing={isResizing}
                nodeSize={nodeSize}
                focused={focused}
                nodeElement={nodeElement}
                onSaveChange={onSaveChange}
                onClosePanel={onClosePanel}
                onOpenPanel={onOpenPanel}
                validateNode={validateNode}
                registerValidateFunction={registerValidateFunction}
                removeValidateFunction={removeValidateFunction}
                onDelete={onDelete}
              />
            </NodeContext.Provider>
            {nodeDisableAreaVisible && (
              <div className='absolute z-21 top-0 left-0 w-full h-full flex-col flex-center rounded-b-8px backdrop-blur-10px'>
                <div className='flex-center w-48px h-48px'>
                  <IconFont
                    name='jinyong'
                    className='text-39px text-#626999 text-op-40'
                  />
                </div>
                <div className='mt-6px text-12px text-#3d3d3d'>节点已禁用</div>
                {![NodeType.LOOP_RESULT].includes(type as NodeType) && (
                  <Button
                    disabled={locked}
                    className='mt-16px h-32px! text-12px font-500'
                    onClick={e => {
                      e.stopPropagation()
                      onToggleNodeEnable()
                    }}
                  >
                    启用节点
                  </Button>
                )}
              </div>
            )}
          </div>
          {/* 主体内容 */}

          {/* Node Handle */}
          {!isToolNode && !customSourceHandle && !isEnd && (
            <DefaultHandle
              nodeId={props.id}
              type='source'
              position={Position.Right}
              isConnectable
              style={{ right: -18 }}
            />
          )}
          {!isToolNode && !customTargetHandle && !isStart && (
            <DefaultHandle
              nodeId={props.id}
              type='target'
              position={Position.Left}
              isConnectable
              style={{ left: -18 }}
            />
          )}

          {/* Node Handle  */}

          {/* Node Resize */}
          {resizeable && (
            <NodeResizeControl
              style={controlStyle}
              maxWidth={resizeConfig?.maxWidth}
              maxHeight={resizeConfig?.maxHeight}
              minWidth={resizeConfig?.minWidth}
              minHeight={resizeConfig?.minHeight}
              onResizeStart={onResizeStart}
              onResizeEnd={onResizeEnd}
              onResize={onResize}
              position='bottom-right'
            >
              <IconFont
                name='resize'
                className='text-10px text-font_1 mt-2px ml-2px'
              />
            </NodeResizeControl>
          )}
          {/* Node Resize */}
        </div>
      </div>
    )
  }, isEqual)
}
