import type { FC } from 'react'
import { useState, useMemo, useEffect } from 'react'
import { Alert, Form, message } from 'antd'
import { sample } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'
import type { ButtonProps } from 'antd/es/button/button'
import type { UploadFileStatus } from 'antd/es/upload/interface'
import { useLocalStorageState } from 'ahooks'
import type {
  CreateTextDocumentRequest,
  CreateFileDocumentRequest,
  CreatePartitionRequest,
  IEmbeddingModalItem,
} from '@apis/datastore/type'
import { PartitionCategoryType } from '@apis/datastore/type'
import { DocumentType } from '@apis/datastore/model'
import { Button, IconFont, Modal } from '@/components'
import { colors } from '@/constants/theme'
import { fontIcons } from '@/constants/icon.ts'
import { Track } from '../../track/Track.tsx'
import type { IStepItem } from '../components/Step.tsx'
import { Step } from '../components/Step.tsx'
import {
  UploadFileForm,
  UploadQAFileForm,
} from '../components/UploadFileForm.tsx'
import { DocumentTypeSelect } from '../components/DocumentTypeSelect.tsx'

export interface CreateDataStoreDocumentsModalProps {
  title: string
  open?: boolean
  onCancel?: () => void
  uploadFileHashPath?: string
  okButtonProps?: ButtonProps
  datastoreType?: PartitionCategoryType
  onFinish?: (
    data: CreateTextDocumentRequest | CreateFileDocumentRequest | null,
    documentType: DocumentType,
  ) => void
  hasDataset?: boolean
  embeddingModelList?: IEmbeddingModalItem[]
}

export const CreateDataStoreDocumentsModal: FC<
  CreateDataStoreDocumentsModalProps
> = props => {
  const {
    open,
    onCancel,
    onFinish,
    okButtonProps,
    title,
    uploadFileHashPath,
    hasDataset = true,
    embeddingModelList = [],
    datastoreType = PartitionCategoryType.Document,
  } = props
  const [step, setStep] = useState<number>(1)

  const [form] = Form.useForm()
  const [dataSourceFormValues] = useState<CreatePartitionRequest>()
  const [documentType, setDocumentType] = useState<DocumentType>()

  const [uploadStatus, setUploadStatus] = useState<UploadFileStatus>('done')

  const [showTips, setShowTips] = useLocalStorageState(
    '__dataset_split_documents_qa_tips',
    {
      defaultValue: '1',
    },
  )

  const handleResetModal = () => {
    onCancel?.()
    form.resetFields()
    setStep(1)
  }

  const hashPath = useMemo(
    () => uploadFileHashPath ?? uuidv4(),
    [open, uploadFileHashPath],
  )

  // 当前是qa知识库时设置documentType为QA
  useEffect(() => {
    if (datastoreType === PartitionCategoryType.QA) {
      setDocumentType(DocumentType.QA)
    }
  }, [documentType])

  const handleFinish = async () => {
    if (!documentType) return
    const partition = {
      ...(documentType !== DocumentType.WEBPAGE && { oss_path: hashPath }),
      partition_name: dataSourceFormValues?.partition_name ?? '',
      partition_describe: dataSourceFormValues?.partition_describe ?? '',
      partition_icon: sample(fontIcons)!,
      partition_icon_color: sample(colors)!,
      partition_category: datastoreType ?? PartitionCategoryType.Document,
      partition_model: dataSourceFormValues?.partition_model,
    }

    form.validateFields().then(async values => {
      if (
        documentType === DocumentType.FILE &&
        values?.fileList?.some((item: any) => item.file_status === 'error')
      ) {
        message.error('有文件上传失败请删除后重新上传')
        return
      }

      const files = getFilesBasedOnDocumentType(documentType, values)
      onFinish?.({ partition, files }, documentType)
    })
  }

  function getFilesBasedOnDocumentType(
    documentType: DocumentType,
    values: any,
  ) {
    switch (documentType) {
      case DocumentType.TEXT:
        return [
          {
            file_name: values.textTitle || values.textContent.slice(0, 6),
            file_content: values.textContent,
            mimetype: 'text/plain',
            file_type: DocumentType.TEXT,
            enable: true,
          },
        ]
      case DocumentType.WEBPAGE:
        return Object.values(values.webUrl || {}).map((value: any) => ({
          file_url: value,
          file_name:
            value.length > 50 ? `${value.slice(0, 47)}...` : value.slice(0, 50),
          file_type: DocumentType.WEBPAGE,
        }))
      default:
        return values.fileList.map((item: any) => ({
          ...item,
          file_type: documentType,
          enable: true,
        }))
    }
  }

  const getSteps = (): IStepItem[] => {
    const _steps = [
      {
        title: '选择添加方式',
        content: (
          <>
            <DocumentTypeSelect
              withTemplate={!hasDataset}
              onSelect={type => {
                setDocumentType(type)
                if (type === DocumentType.TEMPLATE_FILE) {
                  onFinish?.(null, type)
                } else {
                  setStep(step + 1)
                }
              }}
            />
          </>
        ),
      },
      {
        title: '添加文档',
        content: (
          <>
            <UploadFileForm
              getFileStatus={e => e && setUploadStatus(e)}
              documentType={documentType}
              hashPath={hashPath}
            />
            <div className='flex flex-justify-end mt-8 pt-16'>
              <Button
                size='large'
                className='!px-19px'
                loading={uploadStatus === 'uploading'}
                onClick={() => {
                  setStep(step - 1)
                }}
              >
                上一步
              </Button>
              <Track event='datastore_upload'>
                <Button
                  size='large'
                  className='ml-12 !px-27px'
                  type='primary'
                  onClick={handleFinish}
                  {...okButtonProps}
                  disabled={okButtonProps?.disabled || uploadStatus === 'error'}
                  loading={
                    okButtonProps?.loading || uploadStatus === 'uploading'
                  }
                >
                  完成
                </Button>
              </Track>
            </div>
          </>
        ),
      },
    ]
    return _steps.map((item, index) => ({ ...item, stepNum: index + 1 }))
  }
  const steps = getSteps()

  return (
    <Modal
      title={title}
      open={open}
      footer={null}
      onCancel={handleResetModal}
      maskClosable={false}
      width={600}
      styles={{
        body: {
          padding: '24px 32px',
        },
      }}
    >
      <>
        {datastoreType !== PartitionCategoryType.QA && !!Number(showTips) && (
          <Alert
            message='知识库已拆分为问答库与文档库，问答文档请在问答库中添加。'
            type='info'
            showIcon
            closable
            afterClose={() => {
              setShowTips('0')
            }}
            icon={
              <IconFont name='info' className='c-#38A6FF!  text-14px/20px' />
            }
            className='c-#17171D border-[rgba(56,166,255,0.48)] bg-[rgba(56,166,255,0.08)] rounded-8px text-14px/20px mb-24px h-40px'
          />
        )}
        <Form
          requiredMark={false}
          name='createForm'
          initialValues={{
            partition_model: embeddingModelList?.[0]?.model_name,
          }}
          form={form}
          layout='vertical'
        >
          {datastoreType === PartitionCategoryType.QA ? (
            <>
              <UploadQAFileForm
                getFileStatus={e => e && setUploadStatus(e)}
                hashPath={hashPath}
              />
              <div className='flex flex-justify-end mt-8 pt-16'>
                <Button size='large' onClick={onCancel}>
                  取消
                </Button>
                <Track event='datastore_upload'>
                  <Button
                    size='large'
                    className='ml-12 !px-27px'
                    type='primary'
                    onClick={() => {
                      handleFinish?.()
                    }}
                    {...okButtonProps}
                    disabled={
                      okButtonProps?.disabled || uploadStatus === 'error'
                    }
                    loading={
                      okButtonProps?.loading || uploadStatus === 'uploading'
                    }
                  >
                    完成
                  </Button>
                </Track>
              </div>
            </>
          ) : (
            <Step steps={steps} currentStep={step} />
          )}
        </Form>
      </>
    </Modal>
  )
}
