import type { ISupportFileConfig } from '@apis/flow/type'
import { type NodeMeta } from '@/features/nodes/base'
import { NodeOperationTypes, NodeType } from '@/features/nodes/base/types'

export enum StartNodeTypes {
  Form = 'form',
  Webhook = 'webhook',
}

export const FieldTypes = {
  Input: 'input',
  Textarea: 'textarea',
  Select: 'select',
  MultiSelect: 'multiSelect',
  Json: 'json',
  File: 'file',
  Slider: 'slider',
  Switch: 'switch',
  Radio: 'radio',
  MultiInput: 'multiInput',
  ModelSelect: 'modelSelect',
} as const

export type FiledType = (typeof FieldTypes)[keyof typeof FieldTypes]

export enum SelectViewMode {
  SELECT = 'select',
  Flat = 'flat',
}

export interface FieldItem {
  label: string
  type: FiledType
  variableName: string
  required: boolean
  placeholder?: string
  disabled?: boolean
  options?: {
    label: string
    value: string
  }[]
  viewMode?: SelectViewMode
  className?: string
}
export interface FieldExtraItem {
  value?: any
  variableTipsContainer?: HTMLElement
  variables?: Array<{ label: string; type?: string }>
  supportFileTypes?: unknown[]
  supportFileConfig?: ISupportFileConfig
  filePlaceholder?: string
  optionType?: Record<string, any>[]
  limit?: {
    min: number
    max: number
  }
  showAddButton?: boolean
}

export interface StartNodeData {
  name: string
  type: StartNodeTypes
  formConfig: FieldItem[]
  packageName?: string
}

export const NodeTypeOptions = [
  {
    label: '表单',
    value: StartNodeTypes.Form,
  },
  {
    label: 'Webhook',
    value: StartNodeTypes.Webhook,
  },
]

export const Meta: NodeMeta<StartNodeData> = {
  type: NodeType.START,
  operationType: NodeOperationTypes.SINGLE_NODE,
  typeName: '开始',
  actionType: 'TRIGGER',
  icon: 'start',
  backgroundColor: '#525880',
  canDelete: false,
  // description: '开始节点，流程的开始节点',
  isStart: true,
  initialData: {
    name: 'start',
    type: StartNodeTypes.Form,
    formConfig: [
      {
        label: '消息',
        type: FieldTypes.Input,
        variableName: 'message',
        required: true,
        placeholder: '请输入',
      },
      // {
      //   label: '文本域',
      //   type: FieldTypes.Textarea,
      //   variableName: 'area',
      //   required: false,
      //   placeholder: '请输入待xx的内容',
      // },
      // {
      //   label: '选择',
      //   type: FieldTypes.Select,
      //   variableName: 'select',
      //   required: true,
      //   placeholder: '请选择',
      //   options: [
      //     {
      //       label: '小红书',
      //       value: '小红书',
      //     },
      //     {
      //       label: '抖音',
      //       value: '抖音',
      //     },
      //   ],
      // },
      // {
      //   label: '多选',
      //   type: FieldTypes.MultiSelect,
      //   variableName: 'select2',
      //   required: true,
      //   placeholder: '请选择',
      //   options: [
      //     {
      //       label: '小红书',
      //       value: '小红书',
      //     },
      //     {
      //       label: '抖音',
      //       value: '抖音',
      //     },
      //   ],
      // },
    ],
  },
}

export interface IStartNodeFormItemType {
  key: string
  type: FiledType
  required?: boolean
  supportFileTypes?: string[]
  options?: { label: string; value: string }[]
  placeholder?: string
}
