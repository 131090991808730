import styled from '@emotion/styled'
import { useEffect, useMemo, useState } from 'react'
import { useRequest } from 'ahooks'
import { getFlowExecuteLog } from '@apis/flow'
import { FlowStatus } from '@apis/flow/type'
import { IconFont } from '@/components'
import { FlowLogger } from '@/features/logger'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import { useApplicationStore, useFlowLogStore } from '@/store'
import type { FlowOutput } from '@/features/logger/types'
import { transformDataToValidLogs } from '@/features/logger/utils'
import { addChildrenToFlowOutput } from '@/features/logger/utils/logItem'

const ModalWrapper = styled.div<{
  top: number
  right: number
  bottom: number
  zIndex: number
}>`
  position: absolute;
  top: ${({ top }) => top}px;
  bottom: ${({ bottom }) => bottom}px;
  right: ${({ right }) => right}px;
  display: flex;
  flex-direction: column;
  width: 600px;
  border-radius: 8px;
  background-color: #fff;
  backdrop-filter: blur(16px);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
  z-index: ${({ zIndex }) => zIndex};
  & {
    .run-btn {
      &:disabled {
        color: #f7f7ff;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0.8)
          ),
          #7b61ff;
      }
    }
  }
`

// const TestData = {
//   input: {
//     partition_id: 48,
//     question: 'Java是一种什么样的编程语言？',
//     threshold: 0.7,
//     num: 1,
//   },
//   output: '{"match_contents": [{"vector_id": "f0603b44fc0eadb65ae87d97321731a1", "file_id": 235, "file_name": "赵九玲的简历.docx", "mimetype": "application/vnd.openxmlformats-officedocument.wordprocessingml.document", "chunk_id": 25, "content": "对java、maven、node、python、go、php等语言有一定接触", "distance": 0.8544}], "usage": {"prompt_tokens": 14, "completion_tokens": null, "total_tokens": 14}}',
// }

export interface LogModalProps {
  task?: Record<string, any>
  status?: 'success' | 'error'
  icon?: string
  color?: string
  title?: string
  top?: number
  right?: number
  zIndex?: number
  bottom?: number
  onClose?: () => void
}

export function TaskLogModal({
  task = {},
  onClose,
  title = '',
  status,
  bottom = 8,
  top = 8,
  right = 8,
  zIndex = 6,
}: LogModalProps) {
  const { scrollRef } = useScrollBar()
  const { setCurrentFlowId } = useFlowLogStore()
  const { setCurrentApplication } = useApplicationStore()
  const setCurrentLog = useFlowLogStore(s => s.setCurrentLog)

  const { runAsync: getFlowExecuteLogApi, data } = useRequest(
    getFlowExecuteLog,
    {
      manual: true,
      ready: Boolean(task?.data?.content?.task_id),
    },
  )

  const [logs, setLogs] = useState<FlowOutput[]>([])

  useEffect(() => {
    if (task) {
      setCurrentFlowId(task?.data?.content?.input?.flow_id as string)
      setCurrentLog(task?.data?.content as any)
      const flowId = task.data?.content?.input?.flow_id

      const taskId = task?.data?.content?.task_id
      if (flowId) {
        setCurrentApplication(flowId)
        getFlowExecuteLogApi({ flowId, taskId })
      }
    }
  }, [task])

  // const hasLogList = useMemo(() => {
  //   return task?.data?.content?.run_result
  // }, [task])

  useEffect(() => {
    const taskId = task?.data?.content?.task_id

    const log =
      taskId && data?.run_result?.actionOutputs
        ? transformDataToValidLogs(data?.run_result?.actionOutputs || {})
        : [
            {
              hideOutputLabel: true,
              name: title,
              status:
                status === 'success' ? FlowStatus.SUCCEEDED : FlowStatus.FAIL,
              duration: 2000,
              showTime: false,
              showRun: false,
              input: task?.data?.content?.input || '',
              output: task?.data?.content?.output || '',
              type: task?.data?.tool_type,
              errorMessage: task?.data?.message,
            },
          ]

    setLogs(log)
    // console.log('task', task, flowId)
  }, [data, task])

  const activeKeys = useMemo(() => {
    const taskId = task?.data?.content?.task_id

    const failedLog = logs.find(log => log.status === FlowStatus.FAIL)
    return taskId ? ['start', failedLog ? failedLog?.name : 'output'] : [title]
  }, [logs, task])

  const onChange = (item: FlowOutput, open: boolean) => {
    const key = item.value || ''
    console.log('subFlowLogs', item, key, open)

    if (item.type === 'SUB_FLOW' && open) {
      const flowId = item?.attrs?.flow_id
      const taskId = item?.attrs?.task_id

      getFlowExecuteLog({ flowId, taskId }).then(res => {
        const changedLogs = addChildrenToFlowOutput(
          logs,
          key,
          transformDataToValidLogs(res.run_result.actionOutputs, {
            isSingleStepRun: (res.run_type as string) === 'STEP_RUN',
            taskId: res.task_id,
          }),
        )

        setLogs(changedLogs)
      })
    }
  }

  return (
    <ModalWrapper top={top} right={right} bottom={bottom} zIndex={zIndex}>
      <div className='flex flex-items-center flex-justify-between h-56px shrink-0 flex-grow-0 p-l-20px p-r-16px border-b-1 border-b-#e1e1e5 border-b-op-60'>
        <div className='flex flex-items-center'>
          <span className='m-r-12 text-16px font-600 c-#17171d'>{title}</span>
        </div>
        <div
          className='w-24px h-24px b-rd-4px flex flex-items-center flex-justify-center cursor-pointer hover:bg-#626999 hover:bg-op-12'
          onClick={onClose}
        >
          <IconFont name='guanbi' className='text-16px' />
        </div>
      </div>

      <div ref={scrollRef} className='px-20px my-20px overflow-auto h-full'>
        <FlowLogger
          defaultActiveKey={activeKeys}
          outputs={logs}
          onChange={onChange}
        />
      </div>
    </ModalWrapper>
  )
}
