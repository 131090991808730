import type { ReactNode } from 'react'
import type { RoleCode } from '@apis/authority/type'
import type { orderType } from '@apis/order/type'
import { MemberTypes } from '../constant'
import {
  OrderServiceStatus,
  OrderStatus,
  OrderType,
  PayType,
  Version,
} from '@/features/pay'
import { RoleOptionCard } from './components/RoleOptionCard'

export enum AppStatusEnum {
  PUBLISHED = 'PUBLISHED', // 发布
  DRAFT = 'DRAFT', // 未发布
}

interface RoleOption {
  key: RoleCode
  value: RoleCode
  title: string
  label: ReactNode
}

export interface OrderStatusTagType {
  status?: string
  color?: string
  background?: string
}

export interface ModalProps {
  isAgent?: boolean
  applicationId?: string
  showTable?: boolean
  isInviteUser?: boolean
  inviteWithoutAdmin?: boolean
  title?: string
  flowId?: string
  isPublic?: boolean
  isBot?: boolean
  workspaceId: string
  roleOptions?: {
    value: RoleCode
    label: ReactNode
    title: string
  }[]
  appOptions?: {
    value: string
    label: string
    applicationType: string
  }[]
  children?: ReactNode
  openInvitePopover?: boolean
  setOpenInvitePopover?: (e: boolean) => void
  onClose?: () => void
  onInviteCallback?: () => void
  onUserListChange?: () => void
  refreshAppInfo?: () => void
}

export const OrderStatusSetting: Record<OrderStatus, OrderStatusTagType> = {
  [OrderStatus.CLOSED]: {
    status: '已关闭',
    color: '#8D8D99',
    background: 'rgba(141, 141, 153, 0.12)',
  },
  [OrderStatus.WAIT_PAY]: {
    status: '待支付',
    color: '#FE9700',
    background: 'rgba(254, 151, 0, 0.12)',
  },
  [OrderStatus.WAIT_UPLOAD_CERT]: {
    status: '待支付',
    color: '#FE9700',
    background: 'rgba(254, 151, 0, 0.12)',
  },
  [OrderStatus.PLACE_ORDER]: {
    status: '待支付',
    color: '#FE9700',
    background: 'rgba(254, 151, 0, 0.12)',
  },
  [OrderStatus.WAIT_AUDIT]: {
    status: '待审核',
    color: '#FE9700',
    background: 'rgba(254, 151, 0, 0.12)',
  },
  [OrderStatus.COMPLETED]: {
    status: '已完成',
    color: '#2CB969',
    background: 'rgba(44, 185, 105, 0.12)',
  },
  [OrderStatus.AUDIT_DISMISS]: {
    status: '审核驳回',
    color: '#FF5219',
    background: 'rgba(255, 82, 25, 0.12)',
  },
}

export const OrderServerTypeSetting: Record<
  OrderServiceStatus,
  OrderStatusTagType
> = {
  [OrderServiceStatus.WAITING_EFFECT]: {
    status: '待生效',
    color: '#FE9700',
    background: 'rgba(254, 151, 0, 0.12)',
  },
  [OrderServiceStatus.EFFECTING]: {
    status: '生效中',
    color: '#2CB969',
    background: 'rgba(44, 185, 105, 0.12)',
  },
  [OrderServiceStatus.EXPIRED]: {
    status: '已过期',
    color: '#FF5219',
    background: 'rgba(255, 82, 25, 0.12)',
  },
  [OrderServiceStatus.LOSE_EFFECT]: {
    status: '已失效',
    color: '#8D8D99',
    background: 'rgba(141, 141, 153, 0.12)',
  },
}

export const AppStatusSetting: Record<AppStatusEnum, OrderStatusTagType> = {
  [AppStatusEnum.DRAFT]: {
    status: '未发布',
    color: '#8D8D99',
    background: 'rgba(141, 141, 153, 0.12)',
  },
  [AppStatusEnum.PUBLISHED]: {
    status: '已发布',
    color: '#2CB969',
    background: 'rgba(44, 185, 105, 0.12)',
  },
}

export const orderPayName: Record<PayType, string> = {
  [PayType.ALI_PAY]: '支付宝',
  [PayType.TO_B]: '对公转账',
}

export function getNextLevel(level?: Version): Version | string {
  if (level === Version.FREE) {
    return Version.PERSONAL
  } else if (level === Version.PERSONAL) {
    return Version.TEAM
  }
  return ''
}

export function getRoleOptions(useColor = true): RoleOption[] {
  return MemberTypes.filter(item => item.value !== 'all').map(
    ({ value, label, description }) => ({
      key: value as RoleCode,
      value: value as RoleCode,
      title: label,
      label: (
        <RoleOptionCard
          useColor={useColor}
          label={label}
          description={description!}
        />
      ),
    }),
  )
}

// 订单类型是否为加量包大类
export function isDosagePackageOrder(order: orderType) {
  return [
    OrderType.DOSAGE_PACKAGE_YEAH,
    OrderType.DOSAGE_PACKAGE_AGENT_COUNT,
  ].includes(order.orderType)
}
