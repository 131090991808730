import { memo, useEffect, useRef, useState, useMemo } from 'react'
import mermaid from 'mermaid'
import { useMemoizedFn } from 'ahooks'
import { Image } from 'antd'
import { ImgContainerSize } from '@/features/prompt/components/PromptMermaid'

interface MermaidProps {
  value: string // Graph TD value
  descriptionSize:
    | {
        width: number
        height: number
      }
    | undefined
}

export const Mermaid = memo<MermaidProps>(props => {
  const { value, descriptionSize } = props
  const [imageUrl, setImageUrl] = useState('')
  const containerRef = useRef<HTMLDivElement>(null)

  const drawMermaid = useMemoizedFn(async (value: string) => {
    const { svg } = await mermaid.render(
      'mermaid',
      value,
      containerRef.current || undefined,
    )
    const div = document.createElement('div')
    div.innerHTML = svg
    const svgDom = div.children[0] as SVGAElement
    svgDom.style.lineHeight = '1em'

    const rects = svgDom.querySelectorAll('rect, polygon')
    rects.forEach(rect => {
      rect.setAttribute('rx', '4') // 设置水平圆角半径
      rect.setAttribute('ry', '4') // 设置垂直圆角半径
    })

    const paths = svgDom.querySelectorAll('path')
    paths.forEach(path => {
      path.style.strokeWidth = '1px'
    })

    const labels = svgDom.querySelectorAll(
      'span.edgeLabel',
    ) as NodeListOf<HTMLSpanElement>
    labels.forEach(span => {
      span.style.border = '1px solid #EAE6FF'
      span.style.color = '#9581FF'
      span.style.borderRadius = '4px'
      span.style.boxSizing = 'border-box'
      span.style.marginLeft = '-1px'
      span.style.fontSize = '12px'
      span.style.padding = '1px 4px'
    })

    const svgBlob = new Blob([`${div.innerHTML}`], { type: 'image/svg+xml' })
    const url = URL.createObjectURL(svgBlob)
    setImageUrl(url)
  })

  useEffect(() => {
    mermaid.initialize({
      startOnLoad: false,
      themeVariables: {
        lineColor: '#9581FF', // 线条背景色
        textColor: '#17171d', // 字体颜色
        fontSize: '14px', // 字体大小
        fontFamily: 'PingFang SC', // 字体
        nodeBorder: '#E5DFFF', // 流程图边框颜色
        mainBkg: '#E5DFFF', // 流程图元素的背景颜色
        edgeLabelBackground: '#fff', // 线上的文本的字体颜色
      },
    })
  })

  useEffect(() => {
    if (value) {
      drawMermaid(value)
    }
  }, [value])

  const imgHeight = useMemo(() => {
    if (!descriptionSize?.height) {
      return 350
    }
    return ImgContainerSize - descriptionSize.height - 8
  }, [descriptionSize])

  return (
    <div
      className='w-full h-full cursor-pointer flex flex-center'
      onClick={e => e.stopPropagation()}
    >
      <Image
        src={imageUrl}
        style={{ maxHeight: imgHeight }}
        rootClassName='w-100% [&_.ant-image-preview-img]:bg-#fff'
        preview={{
          mask: false,
        }}
      />
    </div>
  )
})
