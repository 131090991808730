import { Form, message, Popover } from 'antd'
import type { ReactNode } from 'react'
import { useState } from 'react'
import styled from '@emotion/styled'
import type { NewPackageForm } from '@apis/noco/type'
import { addCodeEditorPackage } from '@apis/noco'
import { Button, FormItem, IconFont, Input, TextArea } from '@/components'
import { useUserStore } from '@/store'

interface AddNewPackagePopoverProps {
  children: (open: boolean) => ReactNode
  language: 'python' | 'javascript'
}

const StyledFormItem = styled(FormItem)`
  .keyu-form-item-label {
    font-weight: 500 !important;
  }
`

export function AddNewPackagePopover(props: AddNewPackagePopoverProps) {
  const [open, setOpen] = useState(false)
  const [form] = Form.useForm<Omit<NewPackageForm, 'language'>>()
  const user = useUserStore(state => state.user)

  const onSubmit = async () => {
    const values = await form.validateFields().catch(err => {
      console.log(err)
    })
    if (values) {
      await addCodeEditorPackage({
        ...values,
        userId: user?.userId,
        language: props.language,
      })
      message.success('申请已提交，平台审核成功后添加到内置包')
      setOpen(false)
      form.resetFields()
    }
  }

  const PopoverContent = (
    <div>
      <div className='flex-center-between'>
        <span className='text-18px font-500 '>请告诉我你想要的资源包</span>
        <IconFont
          name='guanbi'
          className='text-20px cursor-pointer'
          onClick={() => setOpen(false)}
        />
      </div>
      <Form
        form={form}
        layout='vertical'
        requiredMark={false}
        className='mt-24'
      >
        <StyledFormItem
          label='包名称'
          required
          name='name'
          rules={[{ required: true, message: '请输入包名称' }]}
        >
          <Input placeholder='请输入包名称' />
        </StyledFormItem>
        <StyledFormItem
          label='版本'
          required
          name='version'
          rules={[{ required: true, message: '请输入包版本' }]}
        >
          <Input placeholder='请输入包版本' />
        </StyledFormItem>
        <StyledFormItem name='url' label='package地址'>
          <Input placeholder='请填写npm地址或pipy地址' />
        </StyledFormItem>
        <StyledFormItem name='scene' label='使用场景'>
          <TextArea rows={4} placeholder='请尝试说明使用场景' />
        </StyledFormItem>
      </Form>
      <div className='flex justify-end'>
        <Button type='primary' onClick={onSubmit}>
          提交
        </Button>
      </div>
    </div>
  )

  return (
    <Popover
      open={open}
      trigger={['click']}
      onOpenChange={setOpen}
      placement='rightTop'
      arrow={false}
      overlayInnerStyle={{
        padding: 24,
        width: 480,
      }}
      content={PopoverContent}
    >
      {props.children(open)}
    </Popover>
  )
}
