import { FlowStatus } from '@apis/flow/type'
import { IconFont } from '@/components'
import type { FlowOutput } from '../types'

export function LoggerLabel({
  name,
  duration,
  status,
  showIcon = true,
  showTime = true,
  isNode = false,
}: Pick<
  FlowOutput,
  'name' | 'status' | 'duration' | 'showRun' | 'showIcon' | 'showTime'
> & { isNode?: boolean }) {
  return (
    <div className='h-22px flex justify-between items-center'>
      <div className='flex'>
        {showIcon && (
          <IconFont
            name={status === FlowStatus.SUCCEEDED ? 'chenggongbaocun' : 'cuowu'}
          ></IconFont>
        )}
        <div
          className='ml-8 font-500'
          style={{
            color:
              status === FlowStatus.FAIL
                ? isNode
                  ? '#17171d'
                  : '#ff5219'
                : undefined,
          }}
        >
          {name}
        </div>
      </div>
      {showTime && (
        <div className='text-font_1 text-12px font-400'>
          {(duration / 1000).toFixed(2)} s
        </div>
      )}
    </div>
  )
}
