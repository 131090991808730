import type { FC, ReactNode } from 'react'
import { createContext, useEffect, useRef, useState } from 'react'
import { noop } from 'lodash-es'
import { useRequest } from 'ahooks'
import { message } from 'antd'
import { getDocumentInfo } from '@apis/datastore'
import type { DocumentItem } from '@apis/datastore/type'
import { DocumentStatus, ProgressStage } from '@apis/datastore/model'

export const progressStageMap: Record<
  ProgressStage,
  { label: string; step: number }
> = {
  [ProgressStage.PENDING]: {
    label: '待处理',
    step: 1,
  },
  [ProgressStage.UPDATE_EXTRACT_TEXT]: {
    label: '提取文本',
    step: 2,
  },
  [ProgressStage.CONVERT_FILE_EXT]: {
    label: '转换文本',
    step: 2,
  },
  [ProgressStage.EXTRACT_VIDEO_TEXT]: {
    label: '提取视频文本',
    step: 3,
  },
  [ProgressStage.SPLIT_EXTRACT_TEXT]: {
    label: '文本分段清洗',
    step: 3,
  },
  [ProgressStage.SAVE_VECTOR]: {
    label: '文本向量化并保存',
    step: 4,
  },
  [ProgressStage.DONE]: {
    label: '处理完成',
    step: 5,
  },
}
export const DocumentContext = createContext<{
  documentInfo?: DocumentItem
  documentId?: number
  onReprocessDocument: (
    trigger?: () => Promise<unknown> | unknown,
    successCallBack?: () => void,
  ) => void
  reprocessDocumentLoading: boolean
  loading: boolean
  refreshDocumentInfo: () => void
  setDocumentInfo: (params: DocumentItem) => void
}>({
  onReprocessDocument: noop,
  reprocessDocumentLoading: false,
  refreshDocumentInfo: noop,
  setDocumentInfo: noop,
  loading: true,
})

interface DocumentProviderProps {
  documentId: string
  children: ReactNode
}

export const DocumentProvider: FC<DocumentProviderProps> = ({
  documentId,
  children,
}) => {
  const isNeedFailMessage = useRef(false)
  const [reprocessDocumentLoading, setReprocessDocumentLoading] =
    useState(false)
  const [loading, setLoading] = useState(true)
  const [documentInfo, setDocumentInfo] = useState<DocumentItem>()
  const reprocessSuccessCallback = useRef<() => void>()

  const { refreshAsync, cancel, run } = useRequest(getDocumentInfo, {
    manual: true,
    pollingInterval: 3000,
    onFinally: () => {
      setLoading(false)
    },
    onSuccess: res => {
      setDocumentInfo(res)
      const isIng = [DocumentStatus.Ing, DocumentStatus.Wait].includes(
        res.status,
      )
      setReprocessDocumentLoading(isIng)
      if (!isIng) {
        setLoading(false)
        if (
          res.status === DocumentStatus.Done ||
          res.status === DocumentStatus.Warning
        ) {
          reprocessSuccessCallback.current?.()
        }
        if (res.status === DocumentStatus.Fail) {
          let failReason = ''
          if (
            res.progress_stage &&
            ![ProgressStage.PENDING, ProgressStage.DONE].includes(
              res.progress_stage,
            )
          ) {
            failReason = `: ${progressStageMap[res.progress_stage].label}失败`
          }
          if (isNeedFailMessage.current) {
            message.error(`${res.file_name} 数据处理失败${failReason}`)
            isNeedFailMessage.current = false
          }
        }
        cancel()
      }
    },
    onError: () => {
      cancel?.()
    },
  })

  const {
    cancel: pureRefreshDocumentInfoCancel,
    run: fetchPureRefreshDocumentInfoRun,
  } = useRequest(getDocumentInfo, {
    manual: true,
    pollingInterval: 5000,
    onSuccess: res => {
      setDocumentInfo(res)
    },
    onError: () => {
      pureRefreshDocumentInfoCancel?.()
    },
  })

  const onReprocessDocument = async (
    trigger?: () => Promise<unknown> | unknown,
    successCallBack?: () => void,
  ) => {
    isNeedFailMessage.current = true
    reprocessSuccessCallback.current = successCallBack
    setReprocessDocumentLoading(true)
    const lastStatus = documentInfo?.status
    setDocumentInfo({
      ...documentInfo!,
      status: DocumentStatus.Ing,
      progress_stage: ProgressStage.PENDING,
    })
    try {
      await trigger?.()
      refreshAsync()
    } catch (e) {
      setDocumentInfo({
        ...documentInfo!,
        status: lastStatus!,
        progress_stage: ProgressStage.DONE,
      })
      setReprocessDocumentLoading(false)
    }
  }

  useEffect(() => {
    if (!documentId) return
    run(Number(documentId))
  }, [documentId])

  useEffect(() => {
    if (
      documentInfo?.file_id &&
      documentInfo?.status !== DocumentStatus.Ing &&
      documentInfo?.status !== DocumentStatus.Wait
    ) {
      fetchPureRefreshDocumentInfoRun?.(documentInfo?.file_id!)
    }
  }, [documentInfo?.file_id, documentInfo?.status])

  return (
    <DocumentContext.Provider
      value={{
        documentInfo,
        reprocessDocumentLoading,
        onReprocessDocument,
        loading,
        refreshDocumentInfo: refreshAsync,
        setDocumentInfo,
        documentId: Number(documentId),
      }}
    >
      {children}
    </DocumentContext.Provider>
  )
}
