import { useRequest } from 'ahooks'
import { useRef } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { forkTemplateApp } from '@apis/application'
import { RoleCode } from '@apis/authority/type'
import {
  DetailComponent as AppDetail,
  Button,
  IconFont,
  RoleAccess,
} from '@/components'
import type { AppDetailInstance } from '@/pages/application/components/appDetail/utils'
import { useWorkspaceStore } from '@/store'
import { useFavoriteStore } from '@/store/favorite.ts'

export default function TemplateDetail() {
  const { id } = useParams()
  const [query] = useSearchParams()
  const isTemplate = query.get('isTemplate')
  // const navigate = useNavigate()

  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const { runAsync: forkApplicationApi } = useRequest(
    () =>
      forkTemplateApp({
        applicationId: id!,
        workspaceId,
      }),
    { manual: true },
  )

  const appDetailInstance = useRef<AppDetailInstance | null>(null)
  const favoriteStore = useFavoriteStore()

  const forkAndPublic = async () => {
    const res = await forkApplicationApi()
    if (res) {
      window.open(`/application/${res.id}`, '_blank')
    }
  }

  /**
   * 1. 未登录：弹出登录弹窗
   * 2. 如果运行的是模板应用，并且用户不是当前workspace的开发者/管理员：弹出选择&新建workspace的中间页
   * 3. 运行时，如果是模板，并且此应用没有添加到收藏将当前模板App添加到收藏
   */
  const beforeRun = () => {
    // TODO 做权限控制, 根据其在workspace的权限判断是否有运行权限
    if (id) {
      if (
        isTemplate === 'true' &&
        !favoriteStore.favoriteTemplateAppIdList.includes(id)
      ) {
        favoriteStore.addFavoriteTemplateApp(id)
      }
      return true
    }
    return false
  }

  return (
    <div className='h-full'>
      <div className='py-40 px-60 bg-bg h-full overflow-hidden rounded-12px'>
        {/* <div className="pb-12"> */}
        {/*  <Breadcrumb */}
        {/*    items={[ */}
        {/*      { title: <a onClick={() => navigate('/templates')}>发现</a> }, */}
        {/*      { title: appDetailInstance.current?.appInfo?.name }, */}
        {/*    ]} */}
        {/*  /> */}
        {/* </div> */}
        <div className='h-full'>
          <AppDetail
            id={id!}
            beforeRun={beforeRun}
            ref={appDetailInstance}
            tabs={['singleRun', 'batchRun', 'timingRun']}
          >
            {isTemplate === 'true' && (
              <RoleAccess
                roles={[RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER]}
                hide
              >
                <Button
                  type='primary'
                  className='flex-center'
                  onClick={forkAndPublic}
                >
                  <IconFont name='edit-app' />
                  <span className='ml-7px'>AI应用</span>
                </Button>
              </RoleAccess>
            )}
          </AppDetail>
        </div>
      </div>
    </div>
  )
}
