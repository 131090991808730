import type { AuthRouteObject } from '@bty/react-auth'
import type { RoleCode } from '@apis/authority/type'
import { type User } from './apis/user'
import { checkGrayscale } from './utils/grayscale'

export interface AuthState {
  token?: string
  user: User
  role: RoleCode
  grayscaleList?: any[]
}

export default function auth(state: AuthState) {
  return {
    auth: !!state.token,
    role: (roles: RoleCode[]) => {
      return Boolean(state.role && roles?.includes?.(state.role))
    },
    grayscale: (grayscale_key: string) => {
      return checkGrayscale({
        grayscaleList: state.grayscaleList,
        grayscale_key,
      })
    },
  }
}

export type AuthRoute = AuthRouteObject<typeof auth>
