import { createDocumentOrDataSet } from '@apis/datastore'
import type {
  CreateTextDocumentRequest,
  CreateFileDocumentRequest,
} from '@apis/datastore/type'
import { DocumentType } from '@apis/datastore/model'
import { useComboRequest } from '@/hooks/useComboRequest.tsx'
import { useVersionStore, useWorkspaceStore } from '@/store'
import {
  useLimitedAccessModal,
  useLimitedAccessModalInfo,
} from '@/features/pay/LimitedAccess'

export function useDataStoreCreate() {
  const { runAsync, loading, handleNotEnoughCombo } = useComboRequest(
    createDocumentOrDataSet,
    { manual: true },
  )
  const { fetchVersionByWorkspaceId } = useVersionStore()
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const versionInfo = useVersionStore(state => state.versionInfo)
  const { title, content } = useLimitedAccessModalInfo('dataStore')
  const [limitedAccessModal] = useLimitedAccessModal({
    title,
    content,
  })

  const onCreate = async (
    data: CreateTextDocumentRequest | CreateFileDocumentRequest,
    documentType?: DocumentType,
  ) => {
    if (documentType === DocumentType.FILE) {
      const size = (data as CreateFileDocumentRequest).files.reduce(
        (total: any, item: any) => total + item.file_size,
        0,
      )
      await fetchVersionByWorkspaceId(currentWorkspaceId)
      if (
        (versionInfo?.memory_count_limit || 0) +
          (versionInfo?.dosage_package_memory_total || 0) -
          (versionInfo?.memory_consume_num || 0) -
          size / 1024 / 1024 <
        0
      ) {
        limitedAccessModal.open()
      }
    }
    const res = await runAsync(data)
    documentType === DocumentType.FILE &&
      fetchVersionByWorkspaceId(currentWorkspaceId)
    return res
  }

  return {
    onCreate,
    loading,
    handleNotEnoughCombo,
  }
}
