import { transformAPIRes2MessageList } from '@bty/chat-renderer-pc'
import type { ChatProps, MessageItem } from '@bty/chat-ui'
import { OriginMessageList } from '@bty/chat-ui'
import styled from '@emotion/styled'
import { useBoolean, useRequest, useKeyPress, useMemoizedFn } from 'ahooks'
import { message } from 'antd'
import copy from 'copy-to-clipboard'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { FilePreview } from '@bty/ui-components'
import type { PaginatedMessageHistory, Task } from '@bty/chat-types'
import { type AgentLogDetailParams } from '@apis/agent/type'
import { fetchAgentLogDetail } from '@apis/agent'
import { getPrevChatRecordsById } from '@apis/monitor'
import type { ChatRecordsParams } from '@apis/monitor/type'
import { TaskLogModal } from '@/features/chat'
import { IconFont, Modal } from '@/components'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import { FormMessageComponentMap } from '@/features/agent/components/FormMessage.tsx'
import { TaskLog } from '@/features/agent/Task/TaskLog'
import { usePluginActionOnAgent } from '@/features/chat/hooks/usePluginActionOnAgent'

const PreviewModalWrapper = styled(Modal)`
  .ant-modal-body {
    height: 90vh;
    padding: 0px !important;
  }
`

const ModalWrapper = styled.div<{ top: number; position?: string }>`
  position: ${({ position }) => position || 'absolute'};
  top: ${({ top }) => top}px;
  bottom: 8px;
  right: 8px;
  display: flex;
  flex-direction: column;
  width: 600px;
  border-radius: 8px;
  background-color: #fff;
  backdrop-filter: blur(16px);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
  z-index: 6;
  & {
    .run-btn {
      &:disabled {
        color: #f7f7ff;
        background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.8),
            rgba(255, 255, 255, 0.8)
          ),
          #7b61ff;
      }
    }
  }
`

export interface LogModalProps {
  onClose?: () => void
  agentId?: string
  conversationId?: string
  appId?: string
  recordId?: string
  data?: PaginatedMessageHistory
  position?: 'absolute' | 'fixed'
  robotAvatarInfo?: MessageItem['avatarInfo']
  userAvatarInfo?: MessageItem['avatarInfo']
  mode?: 'onlyContent'
  modalClassName?: string
}

export function LogModal({
  onClose,
  robotAvatarInfo,
  agentId,
  conversationId,
  recordId,
  appId,
  position = 'absolute',
  userAvatarInfo,
  mode,
  modalClassName = '',
}: LogModalProps) {
  const [taskOpen, { setTrue: openTaskModal, setFalse: closeTaskModal }] =
    useBoolean(false)
  const [taskDetailId, setTaskDetailId] = useState<string>()

  const [messageList, setMessageList] = useState<MessageItem[]>([])
  const [task, setTask] = useState<any>({})

  const { pluginItemOnMessageRender } = usePluginActionOnAgent(true)

  const [logModalVisible, { toggle: toggleLogModalVisible }] = useBoolean(false)

  const [params, setParams] = useState<
    AgentLogDetailParams & ChatRecordsParams
  >({
    agent_id: agentId || '',
    conversation_id: conversationId || '',
    page_no: 1,
    page_size: 10,
    application_id: appId,
    record_id: recordId || '',
    limit: 5,
  })

  const [dialogConfig, setDialogConfig] = useState({
    open: false,
    url: '',
    type: '',
  })

  useKeyPress('esc', () => {
    setDialogConfig({ ...dialogConfig, open: false })
  })

  const { data, loading } = useRequest(
    async () => {
      if (params.record_id) {
        const records = await getPrevChatRecordsById({
          record_id: params.record_id || '',
          limit: params.limit,
        })
        return (records || []).reverse()
      }
      {
        const res = await fetchAgentLogDetail(params)
        return res?.data_list || []
      }
    },
    {
      refreshDeps: [params],
      ready: !!params.agent_id || !!params.record_id,
    },
  )

  const handleTaskModalOpen = useMemoizedFn(async (task: Task) => {
    if (!conversationId) return
    setTaskDetailId(task.id)
    openTaskModal()
  })

  useEffect(() => {
    setMessageList(
      [
        ...transformAPIRes2MessageList(data || [], {
          rightActions: ['copy'],
          robotAvatarInfo,
          userAvatarInfo,
          showRegenerateBtn: false,
          filterRelatedQuestion: true,
          footer(item, position) {
            return position === 'left'
              ? `${
                  item?.response_ext?.duration_time
                    ? `耗时 ${item?.response_ext?.duration_time || ''} s ·`
                    : ''
                }  ${dayjs(item.create_time).format('YYYY-MM-DD HH:mm:ss')}`
              : null
          },
        }),
        ...messageList,
      ].filter(v => v.content || v.footer || v.tools?.length),
    )
  }, [data])

  const onMessageActionClick: MessageItem['onActionClick'] = (
    action,
    content,
  ) => {
    if (action === 'copy') {
      copy(content)
      message.success('复制成功')
    }
  }

  const onLoadMore = useMemoizedFn(() => {
    if (
      (data && data?.total_pages <= data?.page_no) ||
      loading ||
      params.record_id
    )
      return
    setParams(prevParams => ({
      ...prevParams,
      page_no: prevParams.page_no + 1,
    }))
  })

  const onMessageToolClick: ChatProps['onMessageToolClick'] = task => {
    toggleLogModalVisible()
    setTask(task)
  }

  const { scrollRef } = useScrollBar()

  return (
    <ModalWrapper top={8} position={position} className={modalClassName}>
      {mode !== 'onlyContent' && (
        <div className='flex flex-items-center flex-justify-between h-56px shrink-0 flex-grow-0 p-l-20px p-r-16px border-b-1 border-b-#e1e1e5 border-b-op-60'>
          <div className='flex flex-items-center'>
            <span className='m-r-12 text-16px font-600 c-#17171d'>
              运行调试
            </span>
          </div>
          <div
            className='w-24px h-24px b-rd-4px flex flex-items-center flex-justify-center cursor-pointer hover:bg-#626999 hover:bg-op-12'
            onClick={onClose}
          >
            <IconFont name='guanbi' className='text-16px' />
          </div>
        </div>
      )}

      <OriginMessageList
        className='h-full w-full'
        data={messageList}
        paddingX={30}
        // toolComponent={TaskBar}
        allowLogger={true}
        hideMessageActions={{ showOnHover: true }}
        onMessageToolClick={onMessageToolClick}
        onLoadMore={onLoadMore}
        onMessageActionClick={onMessageActionClick}
        formMessageConfig={{
          disabled: true,
          formMessageComponentMap: FormMessageComponentMap,
        }}
        onFileClick={(data: any) => {
          setDialogConfig({ ...data, open: true })
        }}
        onMessageTaskClick={handleTaskModalOpen}
        pluginItemOnMessageRender={pluginItemOnMessageRender}
      />

      {logModalVisible && (
        <TaskLogModal
          onClose={toggleLogModalVisible}
          top={0}
          right={0}
          task={task}
          title={task?.title}
          status={task?.status}
        />
      )}
      <PreviewModalWrapper
        style={{
          height: '90vh',
        }}
        onCancel={() => setDialogConfig({ ...data, open: false })}
        className='overflow-hidden !w-[90vw] !max-w-[100vw] !h-[90vh] p-[0px] border-[0px]'
        open={dialogConfig.open}
        footer={null}
      >
        <div className='w-full h-full overflow-auto' ref={scrollRef}>
          <FilePreview url={dialogConfig.url!} mimetype={dialogConfig.type!} />
        </div>
      </PreviewModalWrapper>

      {agentId && conversationId && taskDetailId && (
        <Modal
          centered={false}
          closable={false}
          footer={null}
          mask={false}
          wrapClassName='static!'
          className='absolute top-68px! bottom-8px right-8px p-0px [&_.ant-modal-content]:h-full z-10'
          width={600}
          styles={{
            body: { padding: 0, height: '100%' },
            content: {
              position: 'absolute',
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
            },
          }}
          open={taskOpen}
          destroyOnClose
        >
          <TaskLog
            agentId={agentId}
            taskId={taskDetailId}
            readonly
            logModalPosition={{ top: 68, right: 8, bottom: 8 }}
            getConversationId={() => conversationId}
            onClose={closeTaskModal}
          />
        </Modal>
      )}
    </ModalWrapper>
  )
}
