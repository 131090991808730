import { memo, useMemo } from 'react'
import { useRequest } from 'ahooks'
import { Spin, Tooltip } from 'antd'
import classNames from 'classnames'
import { getInstalledPackages } from '@apis/resources'
import { IconFont } from '@/components'
import { AddNewPackagePopover } from '@/features/nodes/code/CodeNodePanel/Components/NewPackagePopover.tsx'
import { OverflowContent } from './OverflowContent'

interface PackageManagerProps {
  language: 'javascript' | 'python'
}

const PackageTitleTooltipText = {
  javascript: (
    <div className='line-height-18px'>
      环境中默认的工具包，可以更方便的实现复杂流程，比如网络请求，数据加密等等。
      <br />
      使用前需要通过require导入。
    </div>
  ),
  python: (
    <div className='line-height-18px'>
      环境中默认的工具包，可以更方便的实现复杂流程，比如网络请求，数据加密等等。
      <br />
      使用前需要通过import导入。
    </div>
  ),
}

export const PackageManager = memo((props: PackageManagerProps) => {
  const { data, loading } = useRequest(getInstalledPackages, {
    defaultParams: [props.language],
  })

  const packageList = useMemo(() => {
    if (!props?.language) {
      return []
    }
    return data || []
  }, [props?.language, data])

  return (
    <>
      <div className='font-size-16px font-medium p-16px h-48px b-b-1 b-#E1E1E5 b-op-60 flex-center-between'>
        <div className='flex items-center'>
          <span>内置包</span>
          <Tooltip
            title={PackageTitleTooltipText[props?.language]}
            placement='right'
          >
            <IconFont
              name='jieshishuimeng'
              className='text-font_1 text-14px text-op-40 ml-4 cursor-pointer'
            />
          </Tooltip>
        </div>
        <div>
          <AddNewPackagePopover language={props.language}>
            {open => (
              <span
                className={classNames(
                  'w-32px h-32px rd-6px cursor-pointer hover:bg-bg_3 hover:bg-op-8 flex-center',
                  {
                    'bg-bg_3 bg-op-8': open,
                  },
                )}
              >
                <IconFont name='add' className='text-14px' />
              </span>
            )}
          </AddNewPackagePopover>
        </div>
      </div>
      <OverflowContent occupiedHeight='48' className='px-4px'>
        <div className='flex justify-center'>
          <Spin spinning={loading} className='mt-12px' />
        </div>
        {packageList.map(packageItem => (
          <div
            className='flex flex-justify-between flex-items-center px-12px py-10px fw-400 cursor-pointer hover:bg-#626999 hover:bg-op-08 b-rd-6px'
            key={packageItem.name}
            onClick={() => {
              window.open(packageItem.document)
            }}
          >
            <span
              className='text-14px flex'
              style={{ color: 'rgba(23, 23, 29, 1)' }}
            >
              <IconFont
                color='rgba(161,165,191, 1)'
                name='paoguanli'
                className='mr-8px'
              />
              {packageItem.name}
            </span>
            <span
              className='text-12px'
              style={{ color: 'rgba(141, 141, 153, 1)' }}
            >
              {packageItem.version}
            </span>
          </div>
        ))}
      </OverflowContent>
    </>
  )
})
