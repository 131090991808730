import type { MouseEvent } from 'react'
import { memo, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { useMemoizedFn, useRequest } from 'ahooks'
import { Select, message } from 'antd'
import { get, isNil } from 'lodash-es'
import { labelingTestResult } from '@apis/batch-test'
import { IconFont } from '@/components'
import type { CellRenderProps } from './type'

const options = [
  {
    label: '通过',
    value: 'PASSED',
  },
  {
    label: '未通过',
    value: 'NOT_PASSED',
  },
  {
    label: '待标注',
    value: 'INIT',
  },
]

export const SimilarityRender = memo((props: CellRenderProps<any>) => {
  const { className, value, cell, head, onChange } = props

  const wrapRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const handleOpenChange = useMemoizedFn((newOpen: boolean) => {
    if (head.disabled && newOpen) return
    setOpen(newOpen)
  })

  const handleClick = useMemoizedFn((event: MouseEvent) => {
    if (wrapRef.current !== event.target) return
    setOpen(true)
  })

  const isRunning = useMemo(() => {
    return (
      !!head.extra?.runLoading &&
      cell.runtimeOutput?.run_result_status !== 'FINISH'
    )
  }, [head.extra?.runLoading, cell.runtimeOutput])

  const outputInfo = useMemo(() => {
    if (isRunning) return cell.runtimeOutput ?? {}
    return cell.runtimeOutput ?? cell?.[head.outputKey]
  }, [cell, head.outputKey])

  const hasOutput = useMemo(() => {
    return !isNil(get(outputInfo, ['result', 'output']))
  }, [outputInfo])

  const { runAsync: doLabel } = useRequest(
    (label: 'PASSED' | 'NOT_PASSED' | null) =>
      labelingTestResult(outputInfo.test_result_id, label),
    {
      manual: true,
    },
  )

  const isPass = useMemo(() => {
    return value?.result_label === 'PASSED'
  }, [value])

  const isNotPass = useMemo(() => {
    return value?.result_label === 'NOT_PASSED'
  }, [value])

  const handleInit = useMemoizedFn(async () => {
    await doLabel(null)
    onChange?.({ ...value, result_label: null }, [cell.key, head.key], true)
    message.success('已取消标记')
  })

  const handlePass = useMemoizedFn(async () => {
    await doLabel('PASSED')
    onChange?.({ ...value, result_label: 'PASSED' }, [cell.key, head.key], true)

    message.success(
      <span>
        标记为<span className='text-#2CB969 '>通过</span>
      </span>,
    )
  })

  const handleNoPass = useMemoizedFn(async () => {
    await doLabel('NOT_PASSED')
    onChange?.(
      { ...value, result_label: 'NOT_PASSED' },
      [cell.key, head.key],
      true,
    )

    message.success(
      <span>
        标记为<span className='text-#FF5219'>不通过</span>
      </span>,
    )
  })

  const handleChange = useMemoizedFn(async (value: string) => {
    if (value === 'PASSED') {
      handlePass()
      return
    }

    if (value === 'NOT_PASSED') {
      handleNoPass()
      return
    }

    handleInit()
  })

  if (!hasOutput || isRunning) {
    return (
      <div className={classNames(className, 'color-[rgba(141,141,153,0.4)]')} />
    )
  }

  return (
    <div
      ref={wrapRef}
      className={classNames(className, 'test-table-cell select-none')}
      onClick={handleClick}
    >
      <Select
        className={classNames(
          'test-table-select test-table-select-single test-table-select-60px test-table-select-arrow',
          {
            'test-table-select-green': isPass,
            'test-table-select-red': isNotPass,
            'test-table-select-gray': !isPass && !isNotPass,
          },
        )}
        variant='borderless'
        value={value?.result_label || 'INIT'}
        options={options}
        placeholder={head.placeholder}
        onChange={handleChange}
        open={open}
        onDropdownVisibleChange={handleOpenChange}
        suffixIcon={<IconFont name='arrow-1' className='color-#626999' />}
      />
    </div>
  )
})
