import { isInteger } from 'lodash-es'
import type { VersionConfig, VersionInfo } from '@apis/version/type'
import { Version } from '.'

export function padZero(num: number): string {
  if (num < 10) {
    return `0${num}`
  } else {
    return num.toString()
  }
}

export function calculateYearlyPrice(price: number, _discount: number) {
  const yearlyPrice = price * _discount
  return isInteger(yearlyPrice)
    ? yearlyPrice
    : Number.parseInt(`${yearlyPrice}`)
}

export function isDisableCard(
  version: VersionInfo,
  versionConfig: VersionConfig,
) {
  const { product_code } = version
  const { service_code } = versionConfig || {}

  if (product_code === Version.FREE && service_code === Version.FREE) {
    return false
  }
  if (service_code === Version.FREE) {
    return true
  }
  if (service_code === Version.PERSONAL && product_code === Version.TEAM) {
    return true
  }
  return false
}

export function isEnterpriseVersion(
  version: Version,
): version is Version.ENTERPRISE {
  return version === Version.ENTERPRISE
}
