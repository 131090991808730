import { HTTPClientFactory } from '@bty/http-client'

export const REQUEST_TIMEOUT = 60 * 1000

export const httpClientFactory = new HTTPClientFactory()

// App 内大部分接口
export const request = httpClientFactory.create({
  baseURL: process.env.API_BASE_URL_AI,
  timeout: REQUEST_TIMEOUT,
})

// 用户中心服务
export const userCenterRequest = httpClientFactory.create({
  baseURL: process.env.API_BASE_URL_USER_CENTER,
  timeout: REQUEST_TIMEOUT,
})

// 知识库服务
export const datasetRequest = httpClientFactory.create({
  baseURL: process.env.API_BASE_URL_DATASET,
  timeout: REQUEST_TIMEOUT * 5,
})

// 数据库服务
export const dbRequest = httpClientFactory.create({
  baseURL: process.env.API_BASE_URL_DB,
  timeout: REQUEST_TIMEOUT * 5,
})

// 可鱼 数据库服务
const REQUEST_URL = `https://salyut2.bantouyan.com/api/v1/db/data/v1/${
  process.env.API_KEYU_DB_PROJECT_ID
}`
export const nocoRequest = httpClientFactory.create({
  baseURL: REQUEST_URL,
  timeout: REQUEST_TIMEOUT,
  headers: {
    'xc-auth':
      'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxMDc5IiwiY2xpZW50Ijoibm9jb19hcGkiLCJleHAiOjUyOTQ1MDM1MTJ9.cRwJsxHKy8s8U0qmJmsvCBNeYUpx80xs1CEIEjmmH0M',
  },
})
