import { isUndefined } from 'lodash-es'
import type { IterationResult } from '@apis/flow/type'
import dayjs from 'dayjs'
import { FlowStatus } from '@apis/flow/type'
import type { LogItem } from '@apis/run/type'
import type { FlowOutput } from '../types'
import { NodeType } from '@/features/nodes/base'

export function adaptURLBodyOutput(input: any) {
  const formattedInput = isUndefined(input) ? '' : input

  try {
    if (
      typeof formattedInput === 'object' &&
      formattedInput?.url &&
      formattedInput?.body
    ) {
      formattedInput.body = JSON.parse(formattedInput.body)
    }
  } catch (error) {}

  try {
    if (
      typeof formattedInput === 'object' &&
      formattedInput?.url &&
      formattedInput?.headers
    ) {
      formattedInput.headers = JSON.parse(formattedInput.headers)
    }
  } catch (error) {}

  return formattedInput
}

export const SPLIT_TAG = '__bty__'
export function transformDataToValidLogs(
  data: LogItem['run_result']['actionOutputs'],
  options?: { isSingleStepRun?: boolean; taskId?: string },
): FlowOutput[] {
  const { isSingleStepRun = false, taskId } = options || {}
  let list = Object.entries(data)
  if (isSingleStepRun) {
    list = list.filter(item => !['start', 'output'].includes(item[0]))
  }
  return list.map(([key, value]) => {
    const nodeValue = taskId ? `${key}${SPLIT_TAG}${taskId}` : key
    // subFlow节点
    if ('run_result' in value) {
      const { durationTime, status } = value.run_result
      return {
        name: key,
        value: nodeValue,
        status,
        duration: durationTime * 1000,
        type: value.type,
        children: transformDataToValidLogs(value.run_result.actionOutputs),
      }
    }

    const { durationTime, status, message, output, real_inputs, input, type } =
      value
    if (type === NodeType.LOOP && Array.isArray(output)) {
      const loopChildren = output.map((item: IterationResult) => ({
        name: `Run iteration_${item.index + 1}`,
        status: item.status,
        value: nodeValue,
        duration: item.durationTime * 1000,
        children: transformDataToValidLogs(item.output),
      }))
      return {
        name: key,
        status,
        value: nodeValue,
        type: value.type,
        duration: durationTime * 1000,
        real_inputs,
        input,
        children: loopChildren as FlowOutput[],
      }
    }
    return {
      name: key,
      status,
      type: value.type,
      value: nodeValue,
      duration: durationTime * 1000,
      attrs: value?.input?.run_args,
      real_inputs,
      input: value.type === 'SUB_FLOW' ? null : input,
      ...(value.type === NodeType.CODE ? { logs: value.logs } : {}),
      ...(status === FlowStatus.SUCCEEDED
        ? { output: value.type === 'SUB_FLOW' ? null : output }
        : { errorMessage: message }),
    }
  }) as FlowOutput[]
}

export function transformCodeNodeLogsToJSON(
  logs: {
    message: string
    timestamp: number
  }[],
): string {
  if (!logs || logs.length === 0) {
    return '{}'
  }

  const transformedLogs = logs.map(
    log => {
      const { timestamp, message } = log
      const formattedTimestamp = dayjs(timestamp).format('HH:mm:ss')
      const formattedMessage = Array.isArray(message)
        ? message.length === 1
          ? message[0]
          : message
        : message

      return {
        时间: formattedTimestamp,
        输出: formattedMessage,
      }
    },
    {} as Record<string, string | string[] | (string | string[])[]>,
  )

  return JSON.stringify(transformedLogs, null, 2)
}
