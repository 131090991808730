import { useRequest } from 'ahooks'
import {
  getAppMonitorDailyOverview,
  getAppMonitorOverview,
} from '@apis/monitor'
import { StaticTimeTypes } from '@apis/monitor/type'
import { useWorkspaceStore } from '@/store'

interface OverviewOptions {
  flowId?: string
  flowType: 'AGENT' | 'FLOW'
  runType?: string[]
  timeType: StaticTimeTypes
  startDate?: string
  endDate?: string
}

export function useAppMonitorOverview(options: OverviewOptions) {
  const { flowId, flowType, runType, timeType, startDate, endDate } = options

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const { data: dailyData } = useRequest(
    () =>
      getAppMonitorDailyOverview({
        flow_id: flowId,
        flow_type: flowType,
        run_type: runType,
      }),
    {
      refreshDeps: [flowId, flowType, runType, currentWorkspaceId],
    },
  )

  const { data: weeklyData } = useRequest(
    () =>
      getAppMonitorOverview({
        flow_id: flowId,
        flow_type: flowType,
        run_type: runType,
        statistic_time: StaticTimeTypes.WEEKLY,
        start_date: startDate,
        end_date: endDate,
      }),
    {
      refreshDeps: [flowId, flowType, runType, currentWorkspaceId],
    },
  )

  const { data: dynamicData } = useRequest(
    () =>
      getAppMonitorOverview({
        flow_id: flowId,
        flow_type: flowType,
        run_type: runType,
        statistic_time: timeType,
        start_date: startDate,
        end_date: endDate,
      }),
    {
      refreshDeps: [
        flowId,
        flowType,
        runType,
        timeType,
        startDate,
        endDate,
        currentWorkspaceId,
      ],
    },
  )

  return {
    dailyData,
    weeklyData,
    dynamicData,
  }
}
