import type { FC } from 'react'
import { useState } from 'react'
import { Select as AntdSelect } from 'antd'
import type { IEmbeddingModalItem } from '@apis/datastore/type'
import { Select } from '@/components'

interface DocumentTypeSelectProps {
  value?: IEmbeddingModalItem['model_name']
  list: IEmbeddingModalItem[]
  onChange?: (value: string) => void
}

export const EmbeddingModelSelect: FC<DocumentTypeSelectProps> = props => {
  const { list, value } = props
  const [selectedValue, setSelectedValue] = useState(value)
  const handleChange = (selected: string) => {
    setSelectedValue(selected)
    props.onChange?.(selected)
  }
  return (
    <Select
      placeholder='请选择'
      onChange={handleChange}
      value={selectedValue}
      optionLabelProp='label'
      className='mt-4px'
      virtual={false}
    >
      {list?.map(
        ({ model_name, model_display_name, icon, description, recommend }) => (
          <AntdSelect.Option
            value={model_name}
            label={
              <div className='flex gap-x-8px items-center'>
                <img src={icon} alt='model_icon' className='w-24px h-24px' />
                <span>{model_display_name}</span>
                {recommend && (
                  <span className='bg-#FFECE6 text-9px/10px c-#FF8056 rounded-full px-4px py-2px'>
                    推荐
                  </span>
                )}
              </div>
            }
            key={model_name}
            icon={icon}
            description={description}
            recommend={recommend}
          >
            <div className='flex gap-x-8px'>
              <img src={icon} alt='model_icon' className='w-24px h-24px' />
              <div>
                <div className='text-14px/16px c-#17171D flex gap-x-4px'>
                  <span>{model_display_name}</span>
                  {recommend && (
                    <span className='bg-#FFECE6 text-9px/10px c-#FF8056 rounded-full px-4px py-2px'>
                      推荐
                    </span>
                  )}
                </div>
                <p className='text-12px/16px c-font_1 mt-8px'>{description}</p>
              </div>
            </div>
          </AntdSelect.Option>
        ),
      )}
    </Select>
  )
}
