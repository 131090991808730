import type { Database } from '@apis/database/type'
import type { ApplicationBodyType } from '@apis/application/type'

type NormalAppInfo = Pick<
  ApplicationBodyType,
  'name' | 'icon' | 'color' | 'description'
>
type DatabaseInfo = Pick<
  Database,
  'icon_color' | 'title' | 'icon' | 'description'
>

export function normalAppInfo2DatabaseInfo(
  appInfo: NormalAppInfo,
): DatabaseInfo {
  return {
    title: appInfo.name,
    // description: null 是接口可能报错
    description: appInfo.description || '',
    icon: appInfo.icon,
    icon_color: appInfo.color,
  }
}

export function databaseInfo2NormalAppInfo(
  databaseInfo: DatabaseInfo,
): NormalAppInfo {
  return {
    name: databaseInfo.title,
    icon: databaseInfo.icon,
    color: databaseInfo.icon_color,
    description: databaseInfo.description,
  }
}
