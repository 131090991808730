export interface ListResponse<T> {
  total: number
  page_no: number
  pageSize: number
  data: T[]
}

export type AlarmRuleConfigType = 'Workspace' | 'AI' | 'Agent'

export enum AlarmTypes {
  // 空间到期
  WORKSPACE_EXPIRE = 'workspace_expire',
  // 空间积分余量
  WORKSPACE_POINT_MARGIN = 'workspace_points_margin',
  // 空间积分消耗
  WORKSPACE_POINTS_CONSUME = 'workspace_points_consume',
  // 对话首字返回
  FIRST_WORD_RESPONSE = 'first_word_response',
  // flow运行首字返回
  FLOW_RUN_FIRST_WORD_RESPONSE = 'flow_run_first_word_response',
  // 对话错误
  CHAT_ERROR = 'chat_error',
  // 应用积分消耗
  APP_POINTS_CONSUME = 'app_points_consume',
  // 运行错误
  EXECUTE_ERROR = 'execute_error',
}

export enum AlarmLevels {
  // 低
  LOW = 'low',
  // 中
  MIDDLE = 'middle',
  // 高
  HIGH = 'high',
  // 紧急
  EXIGENCY = 'exigency',
}

export interface AlarmRulesParams {
  alarm_rule_name?: string
  alarm_rule_type?: AlarmRuleConfigType
  alarm_type?: AlarmTypes
  alarm_level?: AlarmLevels
  sort_field?: string
  sort_order?: 'asc' | 'desc'
  page_no?: number
  page_size?: number
}

export interface AlarmRuleItem {
  alarm_rule_type: AlarmRuleConfigType
  alarm_rule_name: string
  alarm_channel: {
    ding_notify_channel: {
      enabled: boolean
      notify_user: string[]
      secret: string
      webhook_url: string
    } | null
    sms_notify_channel: {
      enabled: boolean
      notify_user: {
        phone: string
        user_id: number
      }[]
    } | null
    vms_notify_channel?: {
      enabled: boolean
      notify_user: {
        phone: string
        user_id: number
      }[]
    } | null
  }
  alarm_effect_objects?: {
    app_id: string
    app_name: string | null
  }[]
  alarm_rule_id: string
  alarm_type: AlarmTypes
  created_at: string
  created_name: string
  enabled: boolean
  threshold_config: ThresholdConfigItem[]
  updated_at: string
  updated_name: string | null
}

export type AlarmRulesResponse = ListResponse<AlarmRuleItem>

// 告警条件阈值配置
export interface ThresholdConfigItem {
  unit?: string
  alarm_time?: number
  threshold: number
  alarm_level: AlarmLevels
  trigger_type?: 'PERCENT' | 'NUMBER'
}

export interface AlarmRuleConfig {
  alarm_rule_name: string
  alarm_rule_type: AlarmRuleConfigType
  alarm_type: AlarmTypes
  threshold_config: ThresholdConfigItem[]
  app_ids: string[] // 告警规则生效对象
  alarm_channel: {
    sms_notify_channel: {
      enabled: boolean
      notify_user: {
        phone: string
        user_id: number | string
      }[]
    } | null
    ding_notify_channel: {
      enabled: boolean
      webhook_url: string
      secret: string
      notify_user: string[]
    } | null
    vms_notify_channel?: {
      enabled: boolean
      notify_user: {
        phone: string
        user_id: number
      }[]
    } | null
  }
  enabled: boolean
}

export type AlarmRuleEditParams = AlarmRuleConfig & {
  alarm_rule_id: string
}

export interface ToggleAlarmRuleEnableParams {
  alarm_rule_id: string
  enabled: boolean
}

export interface AlarmRecordsParams {
  alarm_rule_type?: AlarmRuleConfigType
  alarm_type?: AlarmTypes
  alarm_level?: AlarmLevels
  record_id?: string
  sort_order?: 'asc' | 'desc'
  sort_field?: string
  page_no?: number
  page_size?: number
}

export interface AlarmRecordItem {
  alarm_level: AlarmLevels
  alarm_object: {
    app_id: string
    app_name: string | null
  }
  alarm_rule_type: AlarmRuleConfigType
  alarm_type: AlarmTypes
  created_at: string
  record_id: string
  threshold_config: ThresholdConfigItem
}

export type AlarmRecordsResponse = ListResponse<AlarmRecordItem>

export interface AlarmChannelResponse {
  alarm_rule_id: string
  ding_notify_channel: {
    enabled: boolean
    webhook_url: string
    secret: string
    notify_user: string[]
  }
  sms_notify_channel: {
    user_id: string | number
    user_name: string
  }[]
  vms_notify_channel: {
    user_id: string | number
    user_name: string
  }[]
}
