import { memo, useEffect, useMemo, useState, useRef } from 'react'
import dayjs from 'dayjs'
import { getWorkspaceErrorRecordsDetail } from '@apis/monitor'
import { StaticTimeTypes } from '@apis/monitor/type'
import MonitorDrawer from '@/components/MonitorDrawer'
import type { StatisticsFlowType } from '..'
import { useStabilityDetailTable } from '../../hooks/useStabilityDetailTable'
import { ErrorDetailTable } from './ErrorDetailTable'

export interface StabilityTimeParams {
  type: StaticTimeTypes | undefined
  start_date?: string | undefined
  end_date?: string | undefined
}

interface InternalUsageRankingModalProps {
  flowType: StatisticsFlowType
  onClose: () => void
  runType: string[]
  timeParams?: StabilityTimeParams
}
function StabilityDetails({
  onClose,
  runType,
  flowType,
  timeParams,
}: InternalUsageRankingModalProps) {
  const totalColumn = [
    {
      title: flowType,
      dataIndex: 'flow_name',
    },
    {
      title: '错误记录条数',
      dataIndex: 'error_count',
      sorter: true,
    },
  ]

  const [filterParams, setFilterParams] = useState(timeParams?.type)
  const lastTimeRange = useRef({
    start_date:
      timeParams?.start_date ??
      dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
    end_date:
      timeParams?.end_date ??
      dayjs().endOf('day').format('YYYY-MM-DD HH:mm:ss'),
    type: timeParams?.type,
  })
  const [page, setPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  const baseParams = useMemo(() => {
    return {
      run_type: runType,
      page,
      page_size: pageSize,
      statistic_time: timeParams?.type || StaticTimeTypes.WEEKLY,
      start_date:
        timeParams?.type === StaticTimeTypes.CUSTOM
          ? timeParams?.start_date
          : undefined,
      end_date:
        timeParams?.type === StaticTimeTypes.CUSTOM
          ? timeParams?.end_date
          : undefined,
      flow_type: flowType,
      sort_field: 'error_count',
      sort_order: 'desc',
    }
  }, [flowType, runType, page, pageSize, timeParams])
  const { fetchList, ...rest } = useStabilityDetailTable({
    api: getWorkspaceErrorRecordsDetail,
    defaultParams: baseParams,
    lastTimeRange: lastTimeRange.current,
  })

  useEffect(() => {
    fetchList(baseParams)
  }, [])

  return (
    <MonitorDrawer
      loading={false}
      title={flowType === 'AGENT' ? '对话错误记录明细' : '运行错误记录'}
      columns={totalColumn as any}
      {...rest}
      onTableChange={(pagination, ...remain) => {
        rest?.onTableChange?.(pagination, ...remain)
        setPage(pagination.current || 1)
        setPageSize(pagination.pageSize || 10)
      }}
      filter={{
        defaultValue: timeParams?.type || StaticTimeTypes.WEEKLY,
        defaultSubValue: [
          dayjs(timeParams?.start_date),
          dayjs(timeParams?.end_date),
        ],
      }}
      onChange={(value: any) => {
        lastTimeRange.current = {
          start_date: value?.subFilterValue?.[0]?.format('YYYY-MM-DD HH:mm:ss'),
          end_date: value?.subFilterValue?.[1]?.format('YYYY-MM-DD HH:mm:ss'),
          type: value?.filterValue,
        }
        setFilterParams(value?.filterValue)
      }}
      onClose={onClose}
      rowKey={'flow_name'}
      expandable={{
        expandedRowRender: record => (
          <ErrorDetailTable
            record={record}
            runType={runType}
            timeParams={lastTimeRange.current}
            filterParams={filterParams}
            flowType={flowType}
          />
        ),
      }}
    />
  )
}

export const StabilityDetailsModel = memo(StabilityDetails)
