/**
 * 设置 plugin 和 flow 的弹窗
 */
import { Modal as AntdModal, Form } from 'antd'
import NiceModal from '@ebay/nice-modal-react'
import styled from '@emotion/styled'
import { useMemo, useRef, useState } from 'react'
import { useBoolean, useMemoizedFn } from 'ahooks'
import type { AgentFlowSkill, AgentListSystemSkillItem } from '@bty/chat-types'
import { updateAgentPlugin, updateAgentSkill } from '@apis/agent'
import { activeSkillTypeDisplayMap, type SkillType } from '../types'
import { Button, IconFont } from '@/components'
import type { AutoDescButtonInstance } from '../components/AutoDescButton'
import { AutoDescButton } from '../components/AutoDescButton'
import { PluginConf } from './PluginConf'
import { FlowConf } from './FlowConf'

const Modal = styled(AntdModal)`
  padding-bottom: 0;
  & > .ant-modal-content {
    padding: 0;
    box-shadow: 0px 8px 32px 0px rgba(153, 162, 176, 0.24);
  }
`

interface SkillConfModalProps {
  type: SkillType
  identifier: string
  conf?: AgentFlowSkill | AgentListSystemSkillItem
  hideSuperLink?: boolean
  hideOutputConfig?: boolean
  isDraft?: boolean
  tips?: string
  disabled?: boolean
  error?: { message?: string; type: string }
  getExtraParams: () => Record<string, unknown>
  onOk: VoidFunction
  customSave?: (values: Record<string, any>) => Promise<void>
}

function InternalSkillConfModal({
  type,
  identifier,
  conf,
  error,
  getExtraParams,
  tips,
  onOk,
  hideSuperLink = false,
  hideOutputConfig = false,
  disabled = false,
  isDraft = false,
  customSave,
}: SkillConfModalProps) {
  const modal = NiceModal.useModal()

  const [form] = Form.useForm()

  const [loading, { set: setLoading }] = useBoolean(false)

  const [saving, setSaving] = useState(false)

  const AutoDescButtonRef = useRef<AutoDescButtonInstance>(null)

  const handleConfirmSave = useMemoizedFn(async () => {
    const formValue = form.getFieldsValue()
    const { parameters, description, ...values } = formValue
    AutoDescButtonRef?.current?.hide()
    setSaving(true)

    if (customSave) {
      customSave(formValue)
        .then(() => {
          onOk()
        })
        .finally(() => {
          AutoDescButtonRef?.current?.hide()
          modal.hide()
          setSaving(false)
        })
      return
    }

    const updater =
      type === 'Plugin'
        ? updateAgentPlugin(identifier, {
            description,
            parameters,
            properties: values,
            ...(getExtraParams() as { agent_id: string; version_id: string }),
          })
        : updateAgentSkill({
            ...values,
            outputs: [{ key: 'output_mode', value: values.output_mode }],
            description,
            id: (conf as AgentFlowSkill)?.id,
            app_id: (conf as AgentFlowSkill)?.app_id,
            rel_flow_id: (conf as AgentFlowSkill)?.flow_id,
            type: 'flow',
            ...(getExtraParams() as { flow_id: string; version_id: string }),
          })
    updater
      .then(() => {
        modal.hide()
        onOk()
      })
      .finally(() => {
        AutoDescButtonRef?.current?.hide()
        setSaving(false)
      })
  })

  const handleSave = async () => {
    if (type === 'Flow') {
      AutoDescButtonRef?.current?.show()
    } else {
      handleConfirmSave()
    }
  }

  const handleCancel = () => {
    AutoDescButtonRef?.current?.hide()
    if (error?.message && type === 'Flow') {
      const { description, ...values } = form.getFieldsValue()
      setSaving(true)

      updateAgentSkill({
        ...values,
        outputs: [{ key: 'output_mode', value: values.output_mode }],
        description,
        id: (conf as AgentFlowSkill)?.id,
        app_id: (conf as AgentFlowSkill)?.app_id,
        rel_flow_id: (conf as AgentFlowSkill)?.flow_id,
        type: 'flow',
        ...(getExtraParams() as { flow_id: string; version_id: string }),
      })
        .then(onOk)
        .finally(() => setSaving(false))
    }

    modal.hide()
  }

  const title = useMemo(() => {
    return type === 'Flow'
      ? `${activeSkillTypeDisplayMap[type]}调用配置`
      : `设置${activeSkillTypeDisplayMap[type]}`
  }, [type])

  const saveText = useMemo(() => {
    if (saving) return '保存中...'
    if (loading) return '生成'
    return '保存'
  }, [saving, loading])

  const hasEmptyDesc = Form.useWatch(values => {
    const hasEmptyDesc =
      !values.description || values?.inputs?.some((v: any) => !v.description)
    return hasEmptyDesc
  }, form)

  const confirmConfig = useMemo(() => {
    return hasEmptyDesc
      ? {
          content: (
            <div className='text-14px font-500'>
              <p>字段未填写完整，是否让AI自动生成后</p>
              <p className='mt-6 mb-6'>保存？</p>
            </div>
          ),
          okText: '自动生成',
          cancelText: '手动补充',
        }
      : {
          content: (
            <div className='text-14px font-500'>
              {isDraft ? (
                <p className='mb-6'>调用配置会在工作流发布后生效</p>
              ) : (
                <>
                  <p>配置内容将覆盖所有该工作流的调用配置，</p>
                  <p className='mt-6 mb-6'>请确认是否发布配置。</p>
                </>
              )}
            </div>
          ),
          okText: isDraft ? '确认' : '确认更新',
          cancelText: '取消',
          onConfirm: handleConfirmSave,
        }
  }, [hasEmptyDesc, isDraft])

  return (
    <Modal
      open={modal.visible}
      width={1120}
      closable={false}
      centered
      destroyOnClose
      onCancel={handleCancel}
      styles={{
        body: {
          maxHeight: '70vh',
          minHeight: 612,
          overflow: 'auto',
        },
      }}
      title={
        <div className='flex items-center px-16px py-12px border-bottom after:border-[rgba(225,225,229,0.6)]'>
          <span className='text-font text-16px/24px font-medium mr-auto flex items-center'>
            <span>{title}</span>
            {!!tips && (
              <span className='text-12px text-#FFA200 bg-#FFA200 bg-op-12 rd-8px p-6px ml-10px'>
                {tips}
              </span>
            )}
          </span>

          <Button
            className='text-16px !p-0 !w-24px !h-24px border-none'
            icon={<IconFont name='guanbi' />}
            onClick={handleCancel}
          />
        </div>
      }
      footer={
        <div className='flex justify-end   px-32px pt-12px pb-24px text-right'>
          <Button
            className='w-80px [&.ant-btn]:font-semibold text-font'
            onClick={handleCancel}
          >
            取消
          </Button>
          <AutoDescButton
            flowId={identifier}
            ref={AutoDescButtonRef}
            okText={confirmConfig?.okText}
            cancelText={confirmConfig?.cancelText}
            isDraft={isDraft}
            trigger='manual'
            description={confirmConfig?.content}
            onLoading={setLoading}
            onConfirm={confirmConfig?.onConfirm}
            onGenerated={flowSchemaResult =>
              form.setFieldsValue(flowSchemaResult)
            }
          >
            <Button
              className='w-80px [&.ant-btn]:font-semibold ml-12px'
              type='primary'
              disabled={disabled}
              loading={saving || loading ? { delay: 200 } : false}
              onClick={handleSave}
            >
              {saveText}
            </Button>
          </AutoDescButton>
        </div>
      }
    >
      <div className='px-32px pt-24px min-h-438px overflow-auto'>
        {type === 'Plugin' ? (
          <PluginConf form={form} conf={conf as AgentListSystemSkillItem} />
        ) : type === 'Flow' ? (
          <FlowConf
            form={form}
            hideSuperLink={hideSuperLink}
            versionId={
              isDraft ? (getExtraParams()?.version_id as string) : undefined
            }
            isDraft={isDraft}
            disabled={disabled}
            hideOutputConfig={hideOutputConfig}
            identifier={identifier}
            onLoading={setLoading}
            conf={conf as AgentFlowSkill}
          />
        ) : null}
      </div>
    </Modal>
  )
}

export const SkillConfModal = NiceModal.create(InternalSkillConfModal)
