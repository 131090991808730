import { memo, useEffect, useMemo } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useMemoizedFn } from 'ahooks'
import { Empty } from 'antd'
import { TaskType } from '@apis/task/type'
import type { TaskListResult } from '@apis/task/type'
import { addTask, getTask, getTaskListDetail, updateTask } from '@apis/task'
import { Button, IconFont } from '@/components'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import { TaskSetting } from './TaskSetting'
import { TaskInput } from './TaskInput'
import { useTaskData } from './TaskContext'
import { DEFAULT_TASK_CONFIG } from './const'

interface TaskDetailProps {
  agentId: string
  versionId: string
  task?: TaskListResult
  getConversationId: () => string
  onUpdateList: (refresh?: boolean, task?: TaskListResult) => void
  onUpdate: (task: TaskListResult) => void
  onBack: () => void
  onClose: () => void
}

export const TaskDetail = memo((props: TaskDetailProps) => {
  const {
    agentId,
    versionId,
    task,

    getConversationId,
    onUpdateList,
    onUpdate,
    onBack,
    onClose,
  } = props

  const disabled = useMemo(() => task?.is_preset, [task])

  const { form, getRunParam, resetConfig } = useTaskData()

  const handleConfirm = useMemoizedFn(async () => {
    try {
      await form.validateFields()
      const taskConfig = form.getFieldsValue()

      const runParam = await getRunParam()

      let newTaskId = task?.task_id

      if (task?.task_id) {
        newTaskId = task.task_id
        await updateTask({
          task_id: task.task_id,
          flow_id: agentId,
          conversation_id: getConversationId(),
          preset_enable: true,
          ...DEFAULT_TASK_CONFIG,
          ...taskConfig,
          run_param:
            taskConfig.execution_method === TaskType.AGENT
              ? taskConfig.run_param
              : runParam,
          update_time: Date.now() / 1000,
        })
        onBack()
      } else {
        newTaskId = await addTask({
          flow_id: agentId,
          conversation_id: getConversationId(),
          preset_enable: true,
          ...DEFAULT_TASK_CONFIG,
          ...taskConfig,
          run_param:
            taskConfig.execution_method === TaskType.AGENT
              ? taskConfig.run_param
              : runParam,
          update_time: Date.now() / 1000,
        })

        const listDetail = await getTaskListDetail(
          newTaskId,
          getConversationId(),
          agentId,
        )

        onUpdate(listDetail)
      }

      resetConfig()
      onUpdateList(true)
      window.dispatchEvent(new Event('taskListUpdate'))
    } catch (err) {
      console.log(err)
    }
  })

  useEffect(() => {
    if (!task?.task_id) {
      resetConfig()
    } else {
      const conversationId = getConversationId()
      getTask(task.task_id, conversationId, task.flow_id).then(task => {
        resetConfig(task.config)
      })
    }
  }, [task?.task_id])

  return (
    <div className='w-full flex flex-col h-full overflow-hidden'>
      <div className='flex flex-center h-64px px-16px flex-none'>
        <button className='chat-icon-btn w-24px h-24px mr-6px' onClick={onBack}>
          <IconFont className='text-16px' name='fanhui' />
        </button>

        <span className='font-600 mr-auto text-16px'>
          {task?.task_id ? '查看任务' : '创建任务'}
        </span>

        <button className='chat-icon-btn w-24px h-24px' onClick={onClose}>
          <IconFont className='text-16px' name='guanbi' />
        </button>
      </div>

      <OverlayScrollbarsComponent
        className='overflow-auto px-20px'
        element='div'
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
      >
        {disabled && (
          <div className='absolute inset-0 z-40 cursor-not-allowed'></div>
        )}
        <TaskSetting
          agentId={agentId}
          versionId={versionId}
          className='border-1px p-16px rounded-8px'
          size='small'
          // disabled={disabled}
          noFlowContent={
            <Empty
              className='ant-empty-small'
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description='当前Agent无可用工作流'
            />
          }
          noPluginContent={
            <Empty
              className='ant-empty-small'
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description='当前Agent无可用插件'
            />
          }
        />

        <TaskInput
          className='mt-16px border-1px p-16px rounded-8px'
          // disabled={disabled}
        />
      </OverlayScrollbarsComponent>

      {!disabled && (
        <div className='mx-20px my-16px flex gap-12px'>
          <Button className='font-normal! basis-80px' onClick={onBack}>
            取消
          </Button>
          <Button
            className='font-normal! flex-1'
            type='primary'
            onClick={handleConfirm}
          >
            完成
          </Button>
        </div>
      )}
    </div>
  )
})
