import type { InputRef } from 'antd'
import { Input } from 'antd'
import type { ChangeEvent } from 'react'
import { memo, useMemo, useRef } from 'react'
import { useDebounceFn, useMemoizedFn, useRequest } from 'ahooks'
import classNames from 'classnames'
import { get, isNil } from 'lodash-es'
import { remarksTestResult } from '@apis/batch-test'
import type { CellRenderProps } from './type'

export const RemarksRender = memo((props: CellRenderProps<string>) => {
  const { className, value = '', cell, head, onChange } = props

  const inputRef = useRef<InputRef>(null)

  const isRunning = useMemo(() => {
    return (
      !!head.extra?.runLoading &&
      cell.runtimeOutput?.run_result_status !== 'FINISH'
    )
  }, [head.extra?.runLoading, cell.runtimeOutput])

  const outputInfo = useMemo(() => {
    if (isRunning) return cell.runtimeOutput ?? {}
    return cell.runtimeOutput ?? cell?.[head.outputKey]
  }, [cell, head.outputKey])

  const hasOutput = useMemo(() => {
    return !isNil(get(outputInfo, ['result', 'output']))
  }, [outputInfo])

  const { runAsync: doRemarks } = useRequest(
    (remarks: string) => remarksTestResult(outputInfo.test_result_id, remarks),
    {
      manual: true,
    },
  )

  const { run: doRemarksDebounced } = useDebounceFn(doRemarks)

  const handleChange = useMemoizedFn(
    async (event: ChangeEvent<HTMLTextAreaElement>) => {
      onChange(event.target.value, [cell.key, head.key], true)
      doRemarksDebounced(event.target.value)
    },
  )

  const handleClick = useMemoizedFn(() => {
    inputRef.current?.focus()
  })

  const sureValue = useMemo(() => {
    if (typeof value !== 'object' || isNil(value)) return value
    return JSON.stringify(value)
  }, [value, head.key])

  if (!hasOutput || isRunning) {
    return (
      <div className={classNames(className, 'color-[rgba(141,141,153,0.4)]')} />
    )
  }

  return (
    <div
      className={classNames(className, 'test-table-cell cursor-text')}
      onClick={handleClick}
    >
      <Input.TextArea
        ref={inputRef}
        className='test-table-area'
        variant='borderless'
        autoSize={false}
        value={sureValue}
        placeholder={head.placeholder}
        onChange={handleChange}
      />
    </div>
  )
})
