import { request } from '../../client'
import type { RoleCode } from '../authority/type'
import type {
  appRunData,
  CreateWorkspaceRes,
  WorkspaceItem,
  workspaceOverview,
} from './type'

export const roles = ['企业管理者', '产品', '技术', '运营']
export const roleSelectOptions = [
  { value: '企业管理者', label: '企业管理者' },
  { value: '产品', label: '产品' },
  { value: '技术', label: '技术' },
  { value: '运营', label: '运营' },
  { value: '其他', label: '其他' },
]

export const NO_FREE_WORKSPACE_CODE = 6003

export function getWorkspaceList(name?: string) {
  return request.get<WorkspaceItem[]>('/v1/workspaces/list', {
    query: {
      name,
    },
    ignoreError: true,
    noRedirect: true,
  })
}
export function deleteWorkSpace(workspaceId: string) {
  return request.delete(`/v1/workspaces/${workspaceId}`)
}

export function createWorkspace({
  name,
  extension,
}: {
  name: string
  extension: { demand: string; source: 'AI' }
}) {
  return request.post<CreateWorkspaceRes>(
    '/v1/workspaces',
    { name, extension },
    { ignoreError: true },
  )
}

export function getWorkspaceApiKey(workspaceId: string) {
  return request.get(`/v1/workspaces/getKey/${workspaceId}`)
}

export function generateWorkspaceApiKey(workspaceId: string) {
  return request.put(`/v1/workspaces/generateKey/${workspaceId}`)
}

export function updateWorkspace(body: any) {
  return request.put(`/v1/workspaces/${body.id}`, body)
}

export function queryWorkspaceFree() {
  return request.post('/v1/workspace/query_workspace_free')
}

// 工作空间app维度数据
export function getWorkspaceOverview(body: {
  workspace_id: string
  version_status?: string | null
  start_time?: string
  end_time?: string
}) {
  return request.post<workspaceOverview>('/v1/workspace/overview', body)
}

// 校验工作空间是否可以邀请
export function workspaceCheckMember(body: {
  sourceRoleCode?: RoleCode
  targetRoleCode: RoleCode
  phone?: string[]
}) {
  return request.post<any>('/v1/workspace/check_member', body)
}

// 根据workspaceId查询应用运行数据
export function getAppRunDataByWorkspaceId(workspace_id: string) {
  return request.get<appRunData>(`/v1/product_service/data/${workspace_id}`)
}

// 根据workspaceId查询应用运行数据
export function updateMemberInfo(body: {
  workspace_id: string
  target_role_code: string
  source_role_code?: string
  deleted?: boolean
}) {
  return request.post<any>('/v1/workspace/update_member_info', {
    ...body,
    deleted: !!body.deleted,
  })
}
