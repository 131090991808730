import { Form, Tooltip } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import { useContext, useMemo, useState } from 'react'
import { Global, css } from '@emotion/react'
import { useRequest } from 'ahooks'
import { LoadingOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import {
  getSynonymsList,
  createSynonyms,
  updateSynonyms,
  getSynonymsTemplate,
  importSynonyms,
  deleteMultipleSynonyms,
} from '@apis/datastore'
import {
  Button,
  ConfirmModal,
  IconFont,
  Input,
  Modal,
  Table,
} from '@/components'
import { useStepUploadFile } from './hooks/useStepUploadFile'
import { DataStoreContext } from './provider/DataStoreProvider'
import { MultipleAddSynonymLibrary } from './components/MultipleAddSynonymLibrary'

function GlobalSynonymLibraryStyle() {
  return (
    <Global
      styles={css`
        .synonymLibraryEditIcon {
          display: none;
        }
        .SynonymLibraryTableRow {
          cursor: pointer;
          &:hover {
            .synonymLibraryEditIcon {
              display: block;
            }
          }
        }
      `}
    />
  )
}

export function SynonymLibrary() {
  const { dataStoreInfo } = useContext(DataStoreContext)
  const [form] = Form.useForm()
  const [currentEditSynonym, setCurrentEditSynonym] = useState<number | null>(
    null,
  )
  const [title, setTitle] = useState<'批量添加近义词' | '添加近义词' | null>(
    null,
  )
  const { StepUploadFile, file, setFile, setCurrentSteps, uploadLoading } =
    useStepUploadFile({
      generateExcel: () => getSynonymsTemplate('近义词模板'),
      needOssDownLoad: false,
      name: `${dataStoreInfo?.partition_name}近义词模板.xlsx`,
      customUploadFn: e =>
        setFile({
          name: (e.file as File)?.name || '',
          file: e.file as File,
        }),
      descriptionChildren: (
        <div>
          <div className='flex gap-10px flex-items-center flex-justify-center line-height-20px'>
            拖拽Excel文件到这里，或者
            <span className='color-#7B61FF line-height-20px'>选择文件</span>
          </div>
          <div
            className='text-12px text-center mt-12px'
            style={{ color: 'rgba(141, 141, 153, 0.6)' }}
          >
            提示：请使用模板上传，否则会解析失败
          </div>
        </div>
      ),
    })
  const {
    loading,
    data,
    run: getSynonymsListFetch,
  } = useRequest(() => getSynonymsList(Number(dataStoreInfo?.partition_id)), {
    manual: false,
    refreshDeps: [dataStoreInfo?.partition_id],
    ready: !!dataStoreInfo?.partition_id,
  })
  const closeModal = () => {
    setTitle(null)
    setFile(null)
    setCurrentSteps(1)
    setCurrentEditSynonym(null)
    form.resetFields()
    getSynonymsListFetch()
  }
  const {
    loading: importSynonymsLoading,
    run: importSynonymsFetch,
    cancel: cancelImportSynonymsFetch,
  } = useRequest(
    () => {
      const formData = new FormData()
      formData.append('partition_id', String(dataStoreInfo?.partition_id))
      formData.append('excel_file', file?.file as File)
      return importSynonyms(formData)
    },
    { manual: true, onSuccess: closeModal },
  )
  const { loading: updateSynonymsLoading, run: updateSynonymsFetch } =
    useRequest(
      (e: { synonym: string[]; master_word: string }) =>
        updateSynonyms(currentEditSynonym!, {
          partition_id: Number(dataStoreInfo?.partition_id),
          ...e,
        }),
      {
        manual: true,
        onSuccess: closeModal,
      },
    )
  const { loading: createSynonymsLoading, run: createSynonymsFetch } =
    useRequest(
      (e: { synonym: string[]; master_word: string }) =>
        createSynonyms(Number(dataStoreInfo?.partition_id), {
          partition_id: Number(dataStoreInfo?.partition_id),
          ...e,
        }),
      {
        manual: true,
        onSuccess: closeModal,
      },
    )
  const [deleteModalVisible, setDeleteModalVisible] = useState(false)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const {
    runAsync: deleteMultipleSynonymsFetch,
    loading: deleteMultipleSynonymsLoading,
  } = useRequest(() => deleteMultipleSynonyms(selectedRowKeys as number[]), {
    manual: true,
    onSuccess: getSynonymsListFetch,
  })
  const rowSelection = {
    selectedRowKeys,
    onChange: setSelectedRowKeys,
  }
  const clickRow = (record: any) => {
    setTitle('添加近义词')
    setCurrentEditSynonym(record.id)
    form.setFieldsValue({
      synonym: record.synonym.join(','),
      master_word: record.master_word,
    })
  }
  const columns: ColumnsType<any> = [
    {
      title: '知识实体词',
      dataIndex: 'master_word',
      width: 240,
      render: value => (
        <div className='flex gap-8px line-height-16px'>
          <Tooltip title={value}>
            {value.slice(0, 13) + (value?.length > 13 ? '...' : '')}
          </Tooltip>
          <IconFont
            className='cursor-pointer synonymLibraryEditIcon text-16px'
            style={{ color: 'rgba(98, 105, 153, 0.6)' }}
            name='bianji'
          />
        </div>
      ),
    },
    {
      title: '近义词',
      dataIndex: 'synonym',
      ellipsis: true,
      render: v => {
        const value = (v || []).join(',')
        return (
          <Tooltip placement='top' arrow={true} title={value}>
            <div className='max-w-100% inline-block truncate pr-8px'>
              {value}
            </div>
          </Tooltip>
        )
      },
    },
    {
      title: '更新时间',
      dataIndex: 'update_time',
      defaultSortOrder: null,
      sorter: (a, b) =>
        new Date(a.update_time).getTime() - new Date(b.update_time).getTime(),
      width: 200,
      render: value => dayjs(value).format('YYYY-MM-DD HH:mm'),
    },
  ]
  const loadingStatus = useMemo(() => {
    return (
      uploadLoading ||
      createSynonymsLoading ||
      updateSynonymsLoading ||
      importSynonymsLoading
    )
  }, [])

  const [value, setValue] = useState('')

  const dataSource = useMemo(() => {
    return data?.filter((item: any) => item.master_word?.includes(value))
  }, [data, value])
  return (
    <div className='w-100% min-h-100% flex flex-col'>
      <GlobalSynonymLibraryStyle />
      <div className='flex justify-between items-center mb-24px'>
        <Input
          size='middle'
          value={value}
          onChange={e => setValue(e.target.value)}
          style={{ width: 400 }}
          prefix={<IconFont name='search' />}
          placeholder='搜索实体词'
        />
        <div className='flex gap-12px'>
          <Button
            size='middle'
            icon={<IconFont name='piliangyunxing' />}
            type='primary'
            onClick={() => setTitle('批量添加近义词')}
          >
            批量添加
          </Button>
          <Button
            size='middle'
            icon={<IconFont name='add' />}
            type='primary'
            onClick={() => setTitle('添加近义词')}
          >
            添加近义词
          </Button>
        </div>
      </div>
      <div
        className='justify-between flex bg-#fff flex-col border-rd-8px'
        style={{ height: 'calc(100% - 50px)' }}
      >
        <div
          className='overflow-y-scroll'
          style={{ height: 'calc(100% - 48px)' }}
        >
          <Table
            rowSelection={rowSelection}
            pagination={false}
            rowKey={'id'}
            onRow={record => {
              return {
                onClick: () => clickRow(record), // 点击行
              }
            }}
            style={{ height: '100%' }}
            rowClassName='SynonymLibraryTableRow'
            dataSource={dataSource}
            columns={columns}
            loading={loading}
          />
        </div>
        <div className='flex items-center justify-between w-full h-50px px-18 border-t border-solid border-line border-op-40'>
          {selectedRowKeys.length > 0 ? (
            <div className='flex items-center'>
              <div>
                <span>已选&nbsp;</span>
                <span className='text-16px text-font font-bold relative top-1px'>
                  {selectedRowKeys?.length}
                </span>
                <span className='mx-3'>/</span>
                <span>{data?.length || 0}&nbsp;条数据</span>
              </div>
              <Button
                size='small'
                className='ml-15 important:h-32px important:px-12px'
                danger
                type='primary'
                loading={deleteMultipleSynonymsLoading}
                onClick={() => setDeleteModalVisible(true)}
              >
                删除
              </Button>
            </div>
          ) : (
            <span className='text-font_1'>共计 {data?.length || 0} 条数据</span>
          )}
        </div>
      </div>
      <Modal
        width={600}
        title={title}
        destroyOnClose
        open={!!title}
        onCancel={() => !loadingStatus && closeModal()}
        okButtonProps={{
          loading: loadingStatus,
          disabled: title === '批量添加近义词' && !file?.file,
          icon: uploadLoading ? <LoadingOutlined /> : null,
        }}
        cancelButtonProps={{
          onClick: () => {
            cancelImportSynonymsFetch()
            closeModal()
          },
          loading: loadingStatus,
        }}
        okText={uploadLoading ? '解析中' : '完成'}
        onOk={() => {
          if (title === '添加近义词') {
            form.validateFields().then(e => {
              const synonym = new Set(e.synonym.split(','))
              const data = {
                master_word: e.master_word,
                synonym: Array.from(synonym).filter(
                  (item: any) => !!item,
                ) as string[],
              }
              currentEditSynonym
                ? updateSynonymsFetch(data)
                : createSynonymsFetch(data)
            })
          } else {
            importSynonymsFetch()
          }
        }}
      >
        <div className='px-16px'>
          {title === '批量添加近义词' ? (
            StepUploadFile
          ) : (
            <MultipleAddSynonymLibrary form={form} />
          )}
        </div>
      </Modal>
      <ConfirmModal
        open={deleteModalVisible}
        okText='删除'
        okButtonProps={{ loading: deleteMultipleSynonymsLoading }}
        onCancel={() => setDeleteModalVisible(false)}
        onOk={async () => {
          await deleteMultipleSynonymsFetch()
          setSelectedRowKeys([])
          setDeleteModalVisible(false)
        }}
      >
        请确认是否删除所选数据？
      </ConfirmModal>
    </div>
  )
}
