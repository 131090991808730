import type { NamedExoticComponent } from 'react'
import { PromptStructType } from '@apis/prompt/type'
import { SkillForm } from './Skill.tsx'
import { BackgroundForm } from './Background.tsx'
import { RoleForm } from './Role.tsx'
import { TaskForm } from './Task.tsx'
import { RequirementsForm } from './Requirements.tsx'
import { OutputForm } from './Output.tsx'
import { CustomForm } from './Custom.tsx'

export const StructPromptGenerateFormMap: Record<
  PromptStructType,
  NamedExoticComponent<{ placeholderMap: Record<string, string> }>
> = {
  [PromptStructType.ROLE]: RoleForm,
  [PromptStructType.BACKGROUND]: BackgroundForm,
  [PromptStructType.TASK]: TaskForm,
  [PromptStructType.SKILL]: SkillForm,
  [PromptStructType.REQUIREMENTS]: RequirementsForm,
  [PromptStructType.OUTPUT]: OutputForm,
  [PromptStructType.CUSTOM]: CustomForm,
}
