import classNames from 'classnames'
import { Upload } from 'antd'
import ImgCrop from 'antd-img-crop'
import OSS from 'ali-oss'
import { getSTSToken } from '@apis/oss'
import { avatars } from '@/features/agent/constant/base.ts'
import { AppLogo, IconFont } from '@/components'

interface ImageSelectProps {
  value?: string
  onChange?: (value: string) => void
  needUpload?: boolean
}

export function AvatarSelect(props: ImageSelectProps) {
  const { value, onChange, needUpload = true } = props

  return (
    <div className='flex gap-24 flex-wrap'>
      {needUpload && <ImageUpload onFinish={onChange} />}
      {avatars.map(avatar => {
        return (
          <div
            key={avatar}
            className={classNames(
              'cursor-pointer b-2 p-2 rounded-full relative',
              {
                'b-primary': value === avatar,
                'b-white': value !== avatar,
              },
            )}
            onClick={() => {
              onChange?.(avatar)
            }}
          >
            <AppLogo size={40} value={avatar} type='image' />
            {value === avatar && (
              <span className='absolute w-20px h-20px rounded-full bg-primary flex-center bottom--4px right--2px'>
                <IconFont
                  name='success'
                  className='c-white text-10px relative top-0.5px'
                />
              </span>
            )}
          </div>
        )
      })}
    </div>
  )
}

function ImageUpload(props: { onFinish?: (url: string) => void }) {
  const handleUploadFile = async (file: any) => {
    try {
      const token = await getSTSToken()
      if (token) {
        const ossClient = new OSS({
          region: 'oss-cn-hangzhou',
          accessKeyId: token!.access_key_id,
          accessKeySecret: token!.access_key_secret,
          stsToken: token!.security_token,
          bucket: import.meta.env.VITE_AI_DATASTORE_RESOURCE_BUCKET,
        })
        const res = await ossClient.multipartUpload(
          `/ai/agentAvatar/${new Date().getTime()}${file?.name}`,
          file,
          {},
        )
        props.onFinish?.((res.res as any)?.requestUrls?.[0]?.split('?')?.[0])
      }
    } catch {}
  }

  return (
    <ImgCrop
      rotationSlider={false}
      zoomSlider={false}
      aspectSlider={false}
      showReset={false}
      showGrid={true}
      cropShape='round'
      modalTitle='编辑图片'
      modalOk='确定'
      modalCancel='取消'
    >
      <Upload
        multiple={false}
        listType='picture'
        customRequest={e => handleUploadFile(e.file)}
        showUploadList={false}
      >
        <div
          style={{ transform: 'translate(4px, 4px)' }}
          className='h-40px w-40px p-2px flex items-center justify-center border-rd-50% bg-primary bg-op-8 hover:bg-op-16'
        >
          <IconFont name='add' className='text-16px c-primary' />
        </div>
      </Upload>
    </ImgCrop>
  )
}
