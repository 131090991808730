import { useMemoizedFn, useRequest } from 'ahooks'
import { Breadcrumb, Empty, Popover, Spin } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { memo, useMemo, useRef, useState } from 'react'
import { filter, get, hasIn, isEmpty, isString, map } from 'lodash-es'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { getLog } from '@apis/run'
import type { LogItem } from '@apis/run/type'
import {
  statusUI,
  typeSelectOption,
} from '@/pages/application/components/appDetail/utils'
import { IconFont } from '@/components'
import { LoggerContent } from '@/features/logger/components/loggerModal'

import { InfiniteScroll } from '@/components/InfiniteScrollComponent'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import { tryParseToJsonObject } from '@/utils/string'

export function getString(data: any): string {
  try {
    return JSON.stringify(data, null, 2)
  } catch (err) {
    return String(data)
  }
}

interface LoggerListModalProps {
  flowId: string
  onClose: () => void
  onReRun: (taskId: string) => void
}

export const LoggerListModal = memo((props: LoggerListModalProps) => {
  const { flowId, onClose, onReRun } = props
  const containerRef = useRef<HTMLDivElement | null>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [currentItem, setCurrentItem] = useState<LogItem | null>(null)
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [logsData, setLogsData] = useState<LogItem[]>([])

  const { loading } = useRequest(
    () =>
      getLog({
        flowId,
        pageNo: page,
        pageSize: 15,
      }),
    {
      refreshDeps: [page],
      onSuccess: res => {
        setLogsData(d => [...d, ...res.data])
        setTotal(res.total)
      },
    },
  )

  const goToDetail = (item: LogItem) => {
    setCurrentItem(item)
  }

  const currentItemName = useMemo(() => {
    if (!currentItem) return '日志'

    const type = typeSelectOption.find(
      item => item.value === currentItem?.run_type,
    )

    return (
      <p>
        <span className='mr-4px'>{type?.label || ''}</span>
        {currentItem.show_name && (
          <span className='mr-8px'>{currentItem.show_name}</span>
        )}
        <span className='mr-8px opacity-60'>
          {dayjs(currentItem.start_time).format('YYYY-MM-DD HH:mm:ss')}
        </span>
      </p>
    )
  }, [currentItem])

  const breadcrumbItems = useMemo(() => {
    const mainItem = {
      title: '运行日志',
      className: classNames('text-16px font-500', {
        'cursor-pointer hover:text-#17171d': !!currentItem,
      }),
      onClick: () => {
        setCurrentItem(null)
      },
    }
    return currentItem
      ? [
          mainItem,
          {
            title: (
              <div className='mt--2px flex items-center'>
                {currentItemName}
                <span
                  className='ml-12px flex-center w-52px h-20px b-rd-4px text-12px font-500'
                  style={{
                    color:
                      statusUI[currentItem.run_status === 'SUCCEEDED' ? 0 : 1]
                        .color,
                    background:
                      statusUI[currentItem.run_status === 'SUCCEEDED' ? 0 : 1]
                        .background,
                  }}
                >
                  {statusUI[currentItem.run_status === 'SUCCEEDED' ? 0 : 1]
                    .text || ''}
                </span>
              </div>
            ),
            className: 'text-16px font-500',
          },
        ]
      : [mainItem]
  }, [currentItem, currentItemName])

  const loadMoreData = useMemoizedFn(() => {
    setPage(p => p + 1)
  })

  const renderContent = useMemoizedFn((record: LogItem, index: number) => {
    const type = typeSelectOption.find(item => item.value === record?.run_type)

    const hasValueConfigs = filter(record.form_config, each =>
      hasIn(record.input, each.variableName),
    )
    const firstConfig = hasValueConfigs[0]
    const value = get(record.input, firstConfig?.variableName ?? '')
    const showText = isString(value) ? value : JSON.stringify(value)

    const content = (
      <div
        className='text-14px'
        style={{
          borderBottom: '1px solid rgba(225, 225, 229, 0.4)',
        }}
        onClick={() => goToDetail(record)}
      >
        <div
          key={index}
          className='p-16px cursor-pointer hover:bg-[rgba(98,105,153,0.08)] rounded-8px'
        >
          <div className='flex items-center mb-12px'>
            <IconFont
              className='text-16px mr-8px'
              name={
                record?.run_status === 'SUCCEEDED' ? 'chenggongbaocun' : 'cuowu'
              }
            />
            <span className='mr-4px'>{type?.label || ''}</span>
            {record.show_name && (
              <span className='mr-8px'>{record.show_name}</span>
            )}
            <span className='mr-8px opacity-60'>
              {dayjs(record.start_time).format('YYYY-MM-DD HH:mm:ss')}
            </span>
            <span className='opacity-60'>{record.durationTime}s</span>

            <span className='text-#7B61FF ml-auto'>详情</span>
          </div>
          <div>
            <span className='w-full inline-block truncate opacity-60'>
              {showText ?? '-'}
            </span>
          </div>
        </div>
      </div>
    )

    if (!hasValueConfigs.length) {
      return content
    }

    const popContent = (
      <OverlayScrollbarsComponent
        className='w-250px max-h-360px overflow-auto mr-[-8px] pr-8px'
        element='div'
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
      >
        {map(hasValueConfigs, each => {
          const sureValue = tryParseToJsonObject(
            get(record.input, each.variableName, '-'),
          )

          return (
            <div key={each.variableName} className='text-12px mb-8px'>
              <p className='leading-18px'>{each.label}</p>
              {isString(sureValue) ? (
                <p className='leading-18px text-#8D8D99 break-all'>
                  {isEmpty(sureValue) ? '-' : sureValue}
                </p>
              ) : (
                <pre className='leading-16px text-#8D8D99 break-all'>
                  {JSON.stringify(sureValue, null, 2)}
                </pre>
              )}
            </div>
          )
        })}
      </OverlayScrollbarsComponent>
    )

    return (
      <Popover
        key={index}
        content={popContent}
        placement='left'
        title={type?.value === 'STEP_RUN' ? '单节点输入' : '工作流输入'}
        align={{ offset: [-16, 0] }}
        destroyTooltipOnHide
      >
        {content}
      </Popover>
    )
  })

  return (
    <div
      ref={containerRef}
      className='absolute z-6 top-68px bottom-20px right-20px flex flex-col w-600px bg-#fff b-1 b-#e1e1e5 b-op-60 b-rd-8px shadow-[0_8px_24px_0px_rgba(0,0,0,0.12)]'
    >
      <div className='flex items-center justify-between shrink-0 h-48px px-16px b-b-1 b-#e1e1e5 b-op-60'>
        <Breadcrumb items={breadcrumbItems} />
        <div
          className='w-24px h-24px b-rd-4px flex-center cursor-pointer hover:bg-#626999 hover:bg-op-12'
          onClick={onClose}
        >
          <IconFont name='guanbi' className='text-16px' />
        </div>
      </div>
      <div className='flex-1 overflow-auto' ref={contentRef}>
        {currentItem ? (
          <LoggerContent
            defaultTab='output'
            flowId={currentItem.flow_id}
            taskId={currentItem.task_id}
            onReRun={res => {
              onReRun(res.task_id)
            }}
          />
        ) : (
          <div className='h-full'>
            {!logsData.length && (
              <Empty
                className='pt-80px overflow-hidden'
                description={<span className='c-font_1 c-op-60'>暂无数据</span>}
              />
            )}
            {!!logsData.length && (
              <OverlayScrollbarsComponent
                className='px-8px h-full overflow-auto'
                element='div'
                options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
                defer
              >
                <InfiniteScroll
                  load={loadMoreData}
                  targetRoot={contentRef}
                  loading={loading}
                  hasMore={total > logsData.length}
                  loader={
                    <div className='w-100% flex flex-center p-20px'>
                      <Spin />
                    </div>
                  }
                >
                  {logsData.map((each, index) => {
                    return renderContent(each, index)
                  })}
                </InfiniteScroll>
              </OverlayScrollbarsComponent>
            )}
          </div>
        )}
      </div>
    </div>
  )
})
