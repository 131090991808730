import { useRequest } from 'ahooks'
import { getSplitPreview } from '@apis/datastore'

export function useSplitConfig() {
  const {
    data: previewList = [],
    runAsync: onPreview,
    loading: previewLoading,
  } = useRequest(getSplitPreview, { manual: true })

  return {
    previewList,
    onPreview,
    previewLoading,
  }
}
