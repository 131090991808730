import { useRequest } from 'ahooks'
import { Empty, Spin, message, Tooltip, Form } from 'antd'
import { useState } from 'react'
import { useMatch } from 'react-router-dom'
import { getDatasetTags, transformQAGroupToSplitItem } from '@apis/datastore'
import type { HitItem } from '@apis/datastore/type'
import { DocumentType } from '@apis/datastore/model'
import { IconFont } from '@/components'
import EmptyImage from '@/assets/dataset/empty@2x.png'
import { TagDropdownSelect } from '@/features/datastore/components/TagDropdownSelect.tsx'
import { HitTestOperationPanel } from '@/pages/datastores/components/HitTestOperationPanel.tsx'
import { useHitTest } from '@/pages/datastores/hooks/useHitTest.ts'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import { useModal } from '@/hooks/useModal.tsx'
import {
  QAParagraphItemEditorModal,
  answerListToMap,
  NormalParagraphEditorModal,
} from '@/pages/datastores/document-detail/paragraph/ParagraphItemEditorModal'
import { getFileIconByFileTypeOrMimeType } from '@/features/datastore/utils'
import { DocumentTypeMap } from '@/features/datastore/Documents'
import { ParagraphHitCard } from './components/ParagraphHitCard'
import { SearchForm } from './components/SearchForm'
import { SimilarityConfig } from './components/SimilarityConfig'

function HitTest() {
  const match = useMatch('/datastores/:workspaceId/:id/:page')
  const id = match?.params.id
  const { data: datasetTags = [] } = useRequest(getDatasetTags, {
    defaultParams: [Number(id)],
  })
  const [tags, setTags] = useState<string[]>([])

  const {
    list: hitList,
    updateList: updateHitList,
    loading: hitLoading,
    onSearch: searchDataApi,
    costTime,
  } = useHitTest(Number(id))

  // 命中测试
  const [searchText, setSearchText] = useState('')
  const onSearch = async ({
    _topK,
    ...params
  }: { _topK?: number } & Record<string, any>) => {
    if (!searchText.trim()) {
      message.warning('请输入查询内容')
      return
    }
    await searchDataApi(searchText, _topK ?? params.top_k, tags, params)
  }

  const [matchThreshold, setMatchThreshold] = useState(6)
  const [form] = Form.useForm()
  const [normalParagraphEditorModal] = useModal(NormalParagraphEditorModal)
  const [qaParagraphEditorModal] = useModal(QAParagraphItemEditorModal)

  const onHitItemEdit = (item: HitItem) => {
    const renderTitle = () => {
      if (!item) {
        return
      }
      return (
        <div className='pl-20px text-12px h-48px flex items-center justify-between b-b-1px b-[rgba(225,225,229,0.6)]'>
          <span className='flex items-center'>
            <img
              className='w-17px h-20px'
              src={getFileIconByFileTypeOrMimeType(
                item.mimetype,
                item.file_type,
              )}
              alt=''
            />
            <span className='ml-8 mt-2 text-16px line-height-16px font-500 bordertext-one-line inline-block'>
              {item.file_name}
            </span>
            <span className='ml-8 px-6px mt-2 text-[#8D8D99] max-w-180px font-400 text-one-line inline-block b-1 b-[rgba(225,225,229,0.6)] line-height-16px text-12px rounded-4px'>
              {DocumentTypeMap[item?.file_type as DocumentType]}
            </span>
          </span>
        </div>
      )
    }
    if (item.file_type === DocumentType.QA) {
      const qaParagraph = transformQAGroupToSplitItem(
        item.answer_group_id!,
        item.extra_info ?? {},
        item,
      )

      qaParagraphEditorModal.open({
        operationType: 'update',
        file_id: item.file_id,
        chunk_id: item.chunk_id!,
        paragraph: qaParagraph,
        matched_keywords: item.matched_keywords,
        title: renderTitle() || '编辑段落',
        isSingle: true,
        callback: async params => {
          const newHitList = hitList.map(item => {
            const questionItem = params.qa_info?.questions?.find(
              e => e.id === item.chunk_id,
            )
            if (!questionItem) return item
            item.content = questionItem.value
            item.keywords = questionItem.keywords ?? []
            item.extra_info = answerListToMap(params.qa_info?.answers ?? [])
            return item
          })
          updateHitList(newHitList)
        },
      })
    } else {
      normalParagraphEditorModal.open({
        operationType: 'update',
        file_id: item.file_id,
        chunk_id: item.chunk_id,
        matched_keywords: item.matched_keywords,
        paragraph: { ...item, key: item.chunk_id, enable: true },
        title: renderTitle() || '编辑段落',
        callback: async (params: Record<string, any>) => {
          const newHitList = [...hitList]
          newHitList.forEach(item => {
            if (item.chunk_id === params.chunk_id) {
              item.keywords = params.keywords
              item.content = params.content
            }
          })
          updateHitList(newHitList)
        },
      })
    }
  }

  const { scrollRef } = useScrollBar()
  const { scrollRef: listPanelScrollRef } = useScrollBar()

  return (
    <div className='flex overflow-hidden flex-1'>
      <div
        className='flex-4 bg-white rounded-8px py-20 adapt:px-20 overflow-y-auto adapt:mr-20'
        ref={scrollRef}
      >
        <HitTestOperationPanel
          className='flex flex-col'
          partition_id={Number(id)}
          hitTestLoading={hitLoading}
          searchText={searchText}
          onSearchTextChange={setSearchText}
          onSearch={() =>
            onSearch({
              ...(form.getFieldsValue() || {}),
              _topK: matchThreshold,
            })
          }
          showSearchForm={false}
          headerActionSlot={
            !!datasetTags.length && (
              <TagDropdownSelect
                allEnable
                width={220}
                contentClassName='max-h-35vh overflow-y-auto'
                options={datasetTags.map(item => ({
                  label: item.tag,
                  value: item.tag,
                  count: item.file_num,
                }))}
                onChange={value => setTags(value as string[])}
                triggerRender={() => {
                  return (
                    <div className='max-w-full flex items-center justify-end overflow-hidden'>
                      <div className='shrink-0  ml-20 text-14px font-400 flex items-center'>
                        <span>请选择标签</span>
                        <Tooltip
                          title={
                            <div>
                              <p className='line-height-18px'>
                                未选标签;命中测试范围：所有文档
                              </p>
                              <p className='line-height-18px'>
                                已选标签;命中测试范围：包含已选标签的所有文档
                              </p>
                            </div>
                          }
                        >
                          <IconFont
                            name='jieshishuimeng'
                            className='ml-4 cursor-pointer text-14px c-bg_3 c-op-32'
                          />
                        </Tooltip>
                        <span>：</span>
                      </div>
                      <div className='overflow-hidden w-auto text-12px line-height-14px flex items-center bg-bg_3 bg-op-6 rounded-6px py-8 px-8 font-400 max-w-100% w-fit cursor-pointer'>
                        {tags.length === 0 && <span>所有文档</span>}
                        {tags.length !== 0 &&
                          tags.length === datasetTags.length && (
                            <span>全部标签</span>
                          )}
                        {tags.length !== 0 &&
                          tags.length !== datasetTags.length && (
                            <span
                              className='truncate flex-1'
                              title={tags.join('，')}
                            >
                              标签：{tags.join('，')}
                            </span>
                          )}
                        <IconFont
                          name='arrow'
                          className='ml-5 c-font_1 text-8px'
                        />
                      </div>
                    </div>
                  )
                }}
              />
            )
          }
        />
      </div>
      <div
        ref={listPanelScrollRef}
        className='flex-7 overflow-y-auto bg-white rounded-8px p-20 pt-12px'
      >
        <div>
          <div className='mb-12 flex-center-between line-height-28px'>
            <div className='flex items-center'>
              <p className='text-16px font-600 text-nowrap'>命中分段</p>
              {costTime && (
                <span className='ml-12px c-#8D8D99 text-12px line-height-16px'>
                  耗时 {costTime} s
                </span>
              )}
            </div>

            <div className='mr--12px flex'>
              <SearchForm
                showResultNumber={false}
                background='none'
                form={form}
                onChange={() => {
                  if (!searchText) return
                  onSearch({
                    ...(form.getFieldsValue() || {}),
                    _topK: matchThreshold,
                  })
                }}
              />
              <SimilarityConfig
                defaultValue={matchThreshold}
                onChange={value => {
                  setMatchThreshold(value)
                  if (!!searchText.trim() && value !== matchThreshold) {
                    onSearch({ ...(form.getFieldsValue() || {}), _topK: value })
                  }
                }}
              />
            </div>
          </div>
          <Spin spinning={hitLoading}>
            <div className='flex items-center justify-center w-full'>
              {hitList.length > 0 ? (
                <div className='flex flex-wrap adapt:gap-20 w-full'>
                  {hitList.map((item, index) => {
                    return (
                      <ParagraphHitCard
                        key={item.vector_id}
                        onClick={() => {
                          onHitItemEdit(item)
                        }}
                        level={index + 1}
                        actionName='查看详情'
                        paragraph={item.content}
                        matched_keywords={item.matched_keywords}
                        extra_info={item.extra_info}
                        relevance_score={item.relevance_score}
                        file_type={item.file_type}
                        mimetype={item.mimetype}
                        rate={10 - index}
                        filename={item.file_name}
                        keywords={item.keywords}
                      />
                    )
                  })}
                </div>
              ) : (
                <Empty
                  className='adapt:mt-130px'
                  description={
                    <span className='text-font_1'>暂无命中数据</span>
                  }
                  image={EmptyImage}
                  imageStyle={{
                    width: 128,
                    height: 128,
                  }}
                />
              )}
            </div>
          </Spin>
        </div>
      </div>
    </div>
  )
}

export default HitTest
