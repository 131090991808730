import styled from '@emotion/styled'
import { Form, Radio } from 'antd'
import type { SegmentedValue } from 'antd/es/segmented'
import { useContext, useMemo } from 'react'
import { useMemoizedFn } from 'ahooks'
import { LLMChannels } from '@apis/llm/model'
import type { PromptStructItem } from '@apis/prompt/type'
import { PromptType } from '@apis/prompt/type'
import {
  type NodeComponent,
  NodeOperationTypes,
  NodeType,
} from '@/features/nodes/base'
import { useModelChange } from '../../hooks/useModelChange'

import type { JsonFormConfig } from '@/features/nodes/components'
import { JsonForm } from '@/features/nodes/components'
import {
  isLLMNodeFormValues,
  transformLLMNodeData2LLMNodeFormData,
} from '@/features/nodes/llm/LLMNodePanel/utils.ts'
import { NodeContext } from '../../base/context'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import {
  checkedJsonModel,
  LLMMessageStructType,
} from '@/features/nodes/utils/llm.ts'
import { LLMContextType } from '../const'
import { LLMPanelOptions } from './components/LLMPanelOptions'
import { LLMContextFormField } from './components/LLMContextFormField'
import { LLMPromptStructEditor } from './components/LLMPromptStructEditor'

const StyledRadio = styled(Radio)`
  margin-right: 8px;
  .ant-radio + span {
    font-size: 12px;
  }
  .ant-radio-inner {
    width: 14px;
    height: 14px;
  }
  &::after {
    content: '';
  }
`

export interface Message {
  role: 'system' | 'user' | 'assistant'
  content: string
}
const LLM_PACKAGE_NAME = 'integration.llm.LLMAction'

export interface LLMNodeData {
  name: string // 节点名称
  inputs: {
    model: string // 模型类型
    channel: LLMChannels // LLM Server 需要区分渠道
    temperature: number // 创造性
    top_p?: number // Top P
    presence_penalty?: number // 重复语句惩罚
    frequency_penalty?: number // 重复主题惩罚
    stream: boolean // 是否开启stream
    messages?: Message[] // chat内容
    plugin: {
      json_mode?: boolean // 启用JSON模式
    }
    system_content?: string
    promptType?: PromptType // prompt编辑类型，结构化or原始文本
    pre_defined_system_content?: string
    pre_defined_messages?: string | Message[]
    context_type?: LLMContextType
    structPrompt?: PromptStructItem[]
  }
  packageName?: typeof LLM_PACKAGE_NAME
}

export interface LLMContextBaseField {
  model: string
  channel: LLMChannels
  system_content?: string
  messages?: Message[]
  context_type?: LLMContextType
  pre_defined_system_content?: string
  pre_defined_messages?: string | Message[]
}

export type LLMNodeDataForm = Omit<LLMNodeData, 'inputs'> & {
  inputs: {
    temperature: number
    top_p?: number
    presence_penalty?: number
    frequency_penalty?: number
    stream: boolean
    // 额外添加的配置字段，提供给 from 使用
    modelSetting: {
      model: string
      channel: LLMChannels
      temperature: number
      top_p?: number
      presence_penalty?: number
      frequency_penalty?: number
      outputType: string
    }
  } & LLMContextBaseField
}

export interface LLMNodeFormValues {
  inputs: LLMNodeDataForm['inputs'] & { plugin: { json_mode?: boolean } }
}

export const LLMNode: NodeComponent<LLMNodeData> = props => {
  const { variables, nodeElement, data } = props
  const { onSaveChange } = useContext(NodeContext)
  const [form] = Form.useForm<LLMNodeDataForm>()

  const modelVal = Form.useWatch(['inputs', 'model'], form)
  const contextTypeVal = Form.useWatch(['inputs', 'context_type'], form)

  const resolvedContextType = useMemo(() => {
    return contextTypeVal || LLMContextType.MSG_LIST
  }, [contextTypeVal])

  const { beforeChange, modelMessageStructType } = useModelChange(
    form,
    props.data.inputs.channel,
  )

  const chatValidator = (_rule: any, value: any, callback: any) => {
    const hasEmptyContent = value.some((item: Message) => {
      if (
        item.role !== 'system' &&
        modelMessageStructType !== LLMMessageStructType.NO_CONTEXT
      ) {
        return !item.content
      }
      return false
    })
    if (hasEmptyContent) {
      callback('Chat不能有空值')
    } else {
      callback()
    }
  }

  const streamingEnabled =
    form.getFieldValue(['inputs', 'modelSetting'])?.channel ===
    LLMChannels.XunFei

  const beforeJsonFormChange = useMemoizedFn((values: unknown) => {
    if (!isLLMNodeFormValues(values)) {
      console.error('数据格式错误')
      return values
    }
    const { modelSetting, stream, messages } = values.inputs

    const inputsData = beforeChange({
      modelSetting,
      stream,
      // messages: context_type === LLMContextType.MSG_LIST ? messages : undefined,
      messages: messages || data.inputs.messages,
    })

    // 旧数据兼容
    inputsData.pre_defined_system_content = undefined
    inputsData.messages = inputsData.messages?.filter(
      item => item.role !== 'system',
    )

    // 一些订正
    if (!checkedJsonModel(inputsData.model)) {
      inputsData.plugin = {}
    }
    const newInput = { ...values.inputs, ...inputsData }
    delete (newInput as any).modelSetting

    const newValues = {
      ...values,
      inputs: newInput,
    }

    return newValues
  })

  const onTypeChange = useMemoizedFn((t: SegmentedValue) => {
    form.setFieldValue(['inputs', 'context_type'], t)
    const values = form.getFieldsValue()
    values.inputs.context_type = t as LLMContextType
    const newValues = beforeJsonFormChange(values)
    onSaveChange?.(newValues as any)
  })
  const lockStatus = useFlowDraftStore(s => s.lockStatus)
  const list = useMemo<JsonFormConfig[]>(() => {
    const rightContent = (
      <span
        className='text-#7B61FF text-12px cursor-pointer hover:bg-[rgba(98,105,153,0.08)] px-8px py-6px inline-block rounded-6px'
        onClick={props.onOpenPanel}
      >
        全屏调试
      </span>
    )
    return [
      {
        required: true,
        name: ['inputs'],
        render: () => (
          <LLMPanelOptions
            streamingEnabled={streamingEnabled}
            nodeElement={nodeElement}
          />
        ),
      },
      ...((data.inputs.promptType === PromptType.RAW || !data.inputs.promptType
        ? [
            {
              label: '提示词',
              rightContent,
              disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
              name: ['inputs', 'system_content'],
              required: true,
              type: 'TextEditor',
              tooltip: '提供给AI的提示，以确保AI按照您的意愿做出响应',
              rules: [{ required: true, message: 'System Prompt不能为空' }],
              componentProps: {
                placeholder: '输入内容，如：“你是一个广告文案撰写专家”',
                variables,
                variableTipsContainer: nodeElement,
              },
            },
          ]
        : [
            {
              label: '提示词',
              rightContent,
              required: true,
              name: ['inputs', 'structPrompt'],
              render: () => {
                return (
                  <LLMPromptStructEditor
                    variables={variables}
                    variableTipsContainer={nodeElement}
                  />
                )
                // return <PromptStructEditor className='max-h-400px overflow-auto nopan nowheel' mini />
              },
            },
          ]) as any[]),
      {
        label: '上下文',
        rules:
          resolvedContextType === LLMContextType.JSON_VARIABLE
            ? []
            : [
                {
                  validator: chatValidator,
                },
              ],
        name:
          resolvedContextType === LLMContextType.JSON_VARIABLE
            ? ['inputs', 'pre_defined_messages']
            : ['inputs', 'messages'],
        validateTrigger: ['onSubmit'],
        tooltip: '可创建或读取上下文故事线，让AI更准确的理解意图并处理',
        hidden: modelMessageStructType === LLMMessageStructType.NO_CONTEXT,
        render: () => (
          <LLMContextFormField
            contextType={resolvedContextType}
            onTypeChange={onTypeChange}
            modelMessageStructType={modelMessageStructType}
            model={modelVal}
            completions={variables}
            variableTipsContainer={nodeElement}
            readOnly={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
          />
        ),
      },
      {
        label: '输出格式',
        name: ['inputs', 'modelSetting', 'outputType'],
        hidden: !checkedJsonModel(data.inputs.model),
        tooltip: '支持设置LLM节点输出的内容格式',
        render: () => {
          return (
            <Radio.Group>
              <StyledRadio value='text'>文本</StyledRadio>
              <StyledRadio value='json'>JSON</StyledRadio>
            </Radio.Group>
          )
        },
      },
    ]
  }, [
    streamingEnabled,
    nodeElement,
    variables,
    data.inputs,
    resolvedContextType,
    onTypeChange,
    modelVal,
  ])

  return useMemo(
    () => (
      <div>
        <div className='p-16 pb-0 w-420px'>
          <JsonForm
            form={form}
            list={list}
            initialValues={transformLLMNodeData2LLMNodeFormData(data)}
            beforeChange={beforeJsonFormChange}
            validateTrigger={['onSubmit']}
            disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
          ></JsonForm>
        </div>
      </div>
    ),
    [form, list, beforeJsonFormChange],
  )
}

LLMNode.meta = {
  type: NodeType.LLM,
  operationType: NodeOperationTypes.SINGLE_NODE,
  typeName: 'LLM',
  actionType: 'INTEGRATION',
  icon: 'llm',
  description: '文本类AI大模型',
  backgroundColor: '#00BD68',
  initialData: {
    name: 'llm_1',
    packageName: LLM_PACKAGE_NAME,
    inputs: {
      model: 'gpt-4o-mini',
      channel: LLMChannels.GPT,
      stream: true,
      temperature: 0.7,
      // promptType: PromptType.STRUCT,
      // messages: [{ role: 'system', content: '' }],
      messages: [],
      plugin: {
        json_mode: false,
      },
      context_type: LLMContextType.JSON_VARIABLE,
      promptType: PromptType.RAW,
    },
  },
}
