import { useState, useEffect } from 'react'
import { queryApp } from '@apis/agent-go'
import type { AuthApp } from '@apis/agent-go/type'

export function useQueryAgentGoApp<T extends AuthApp>(options: {
  source: T['auth_app_source']
  agentId?: string
  onQuerySuccess: (data: T) => void
}) {
  const { source, agentId, onQuerySuccess } = options
  const [loading, setLoading] = useState(true)

  const [app, setApp] = useState<Partial<T>>({
    auth_app_source: source,
  } as Partial<T>)

  const runQueryApp = (agentId: string) => {
    setLoading(true)
    queryApp(source as any, agentId)
      .then(data => {
        if (!data) {
          return
        }
        setApp(data as Partial<T>)
        onQuerySuccess(data as T)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (agentId) {
      runQueryApp(agentId)
    }
  }, [agentId])

  return {
    app,
    loading,
    runQueryApp,
    setApp,
  }
}
