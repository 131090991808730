import { useDebounceFn, useRequest } from 'ahooks'
import { useState, useRef, useEffect } from 'react'
import { message } from 'antd'
import { isNil, findIndex, remove, union, unionBy, cloneDeep } from 'lodash-es'
import type {
  TokenSizeResponse,
  IParagraphExtraInfo,
  SplitItem,
  Operation,
} from '@apis/datastore/type'
import {
  getSplitContent,
  updateDocumentBySplitOperation,
  getAISummaryList,
  generateAISummaryByChunkId,
  fetchTokenSizeByWord,
} from '@apis/datastore'
import type { ExtraParagraphExtraInfoStatus } from '@apis/datastore/model'
import { ParagraphExtraInfoStatus } from '@apis/datastore/model'
import {
  MAX_KEYWORDS_SIZE,
  MAX_SPLIT_SIZE,
} from '@/features/datastore/constant'
import type { Paragraph } from '@/pages/datastores/types/paragraph.ts'
import { wait } from '@/utils/wait'

const KEY_PREFIX = 'temp_'

const DEFAULT_PAGE_SIZE = 10

interface OperationMap {
  Split: Record<
    number | string,
    {
      id: string | number
      sort_id: number
      identifier: string | number
    }[]
  >
  Merge: {
    identifier: string | number
    merge_identifiers: (number | string)[]
    sort_id: number
  }[]
  Update: Record<
    number | string,
    {
      content: boolean
      enable: boolean
      keywords: boolean
      extra_info: boolean
    }
  >
  Delete: Record<number | string, true>
  Add: Record<
    number | string,
    {
      id: string | number
      sort_id: number
      identifier: string | number
      original_identifier: string | number
    }[]
  >
}

export interface SplitItemConfig {
  content: string
  keywords?: string[]
  extra_info?: Record<string, any>
}

export interface ParagraphInstance {
  content: string
  reRender?: (params: SplitItemConfig) => void
  textCount?: number
  validator?: () => void
}

export interface IParagraphContentUsedToken {
  access: boolean
  data: TokenSizeResponse
}

export interface IParagraphSearchParams {
  search_words?: string
  chunk_status?: ParagraphExtraInfoStatus | ExtraParagraphExtraInfoStatus
}

export function useParagraph(
  file_id?: number,
  customGetSplitContent = getSplitContent,
) {
  const startIndex = useRef(0)
  const prevChunkId = useRef<number>()
  const nextChunkId = useRef<number>()
  const paragraphInstances = useRef<Record<string | number, ParagraphInstance>>(
    {},
  )
  const [paragraphs, setParagraphs] = useState<Paragraph[]>([])
  const [paragraphsSnapshot, setParagraphsSnapshot] = useState<Paragraph[]>([])

  const [hasDeleted, setHasDeleted] = useState(false)
  const [viewTotal, setViewTotal] = useState(0)
  const aiProcessListIds = useRef<number[]>([])
  const [hasPrev, setHasPrev] = useState(false)
  const [hasNext, setHasNext] = useState(false)
  const searchParams = useRef<IParagraphSearchParams>({})

  const [aIProcessLoading, setAIprocessLoading] = useState(false)
  const [isInited, setInited] = useState(false)

  const [isEditing, setEditable] = useState(false)

  const hasEdited = paragraphs.some(item => item.isEdited) || hasDeleted

  const operationMap = useRef<OperationMap>({
    Split: {},
    Merge: [],
    Update: {},
    Delete: {},
    Add: {},
  })

  const flagCount = useRef(0)
  const flagSort = useRef(0)

  const transform2Paragraphs = (data: SplitItem[]): Paragraph[] => {
    return data.map(item => ({ ...item, key: item.chunk_id }))
  }

  const {
    loading,
    data: splitInfo,
    runAsync: fetchData,
  } = useRequest(
    (
      next_chunk_id?: number,
      direction: 'up' | 'down' = 'down',
      total?: boolean,
    ) =>
      customGetSplitContent({
        file_id: file_id!,
        next_chunk_id,
        page_size: DEFAULT_PAGE_SIZE,
        direction,
        need_total: total,
        search_words: searchParams?.current.search_words,
        chunk_status: searchParams?.current.chunk_status,
      }),
    { manual: true },
  )

  const { loading: saveLoading, runAsync: handleSave } = useRequest(
    updateDocumentBySplitOperation,
    { manual: true },
  )

  const {
    cancel: cancelAiProcessListPolling,
    runAsync: handlePollAiProcessList,
  } = useRequest(getAISummaryList, {
    manual: true,
    pollingInterval: 3000,
    onSuccess: data => {
      const newParagraphs = [...paragraphs]
      let flag = false
      let count = 0
      data.forEach(item => {
        const {
          chunk_id,
          contents,
          chunk_status,
          error_text,
          enable_ai_support,
        } = item
        if (
          chunk_status === ParagraphExtraInfoStatus.Done ||
          chunk_status === ParagraphExtraInfoStatus.Error
        ) {
          const idx = findIndex(newParagraphs, o => o.chunk_id === chunk_id)
          if (!isNil(chunk_id) && idx !== -1) {
            if (chunk_status === ParagraphExtraInfoStatus.Done) {
              newParagraphs.splice(
                idx,
                1,
                ...contents.map(item => {
                  return {
                    ...item,
                    key: item.chunk_id!,
                  }
                }),
              )
              count = count + contents.length - 1
            }
            if (chunk_status === ParagraphExtraInfoStatus.Error) {
              newParagraphs[idx].extra_info = {
                ...newParagraphs[idx].extra_info,
                chunk_status: ParagraphExtraInfoStatus.Error,
                error_text,
                enable_ai_support,
              }
            }
            remove(aiProcessListIds.current, o => {
              return o === chunk_id
            })
            flag = true
          }
        }
      })
      if (flag) {
        setParagraphs(unionBy(newParagraphs, 'chunk_id'))
        setViewTotal(viewTotal + count)
      }
      if (!aiProcessListIds.current.length) {
        cancelAiProcessListPolling()
      }
    },
  })

  const clean = () => {
    prevChunkId.current = undefined
    nextChunkId.current = undefined
    startIndex.current = 0
    searchParams.current = {}
    aiProcessListIds.current = []
    aiProcessListIds.current = []
    setHasPrev(false)
    setHasNext(false)
    setInited(false)
    setEditable(false)
    setParagraphs(() => [])
    cancelAiProcessListPolling()
  }

  const init = async (nowChunkId?: number) => {
    setHasDeleted(false)
    prevChunkId.current = undefined
    nextChunkId.current = nowChunkId
    startIndex.current = 0
    flagCount.current = 1
    flagSort.current = 1
    operationMap.current = {
      Split: {},
      Merge: [],
      Update: {},
      Delete: {},
      Add: {},
    }
    try {
      let res = await fetchData(nowChunkId, 'down', true)
      nextChunkId.current = res.next_chunk_id
      let list = transform2Paragraphs(res.contents)
      setViewTotal(res.totals)

      // 直接显示中间 chunk 时，需往上再加载一段内容，容纳滚动条
      if (!isNil(nowChunkId)) {
        res = await fetchData(nowChunkId, 'up')
        prevChunkId.current = res.next_chunk_id
        startIndex.current = Math.max(
          res.current_index_id - res.contents.length,
          0,
        )
        list = [...transform2Paragraphs(res.contents), ...list]
      }

      setHasPrev(!isNil(prevChunkId.current))
      setHasNext(!!nextChunkId.current)
      setParagraphs(list)
    } catch {
    } finally {
      setInited(true)
    }
  }

  const loadMore = async (
    position: 'prev' | 'next' = 'next',
    scroll?: HTMLElement,
  ) => {
    if (loading || !splitInfo) return

    if (position === 'next' && nextChunkId.current) {
      const res = await fetchData(nextChunkId.current, 'down')
      nextChunkId.current = res.next_chunk_id
      setHasNext(!!nextChunkId.current)
      setParagraphs(prev => [...prev, ...transform2Paragraphs(res.contents)])
    }

    if (position === 'prev' && !isNil(prevChunkId.current) && hasPrev) {
      const [res] = await Promise.all([
        fetchData(prevChunkId.current, 'up'),
        // 滚动到顶部后有一段时间的延续，即 scrollTop=0 时，其实还在滚动，至少要停顿 1s 与请求一起
        wait(1000),
      ])

      // 为什么要滚动？
      // 浏览器滚动条在顶部时，即 scrollTop=0 时，如果顶部突然出现内容，滚动条的位置依然在顶部，即维持 scrollTop=0
      // 但当滚动条不在顶部时，顶部突然出现内容，滚动条位置会发生变化，保持页面显示内容不变
      // 因此在更新内容前，滚动一小段距离
      // 当滚动条在底部时，无须考虑这个，并不会出现显示内容突然发生变化的情况
      // PS： 可以 behavior: 'smooth' 但需考虑在 smooth 期间，又出发滚动的情况
      if (scroll) {
        scroll!.scrollTo({
          top: 36,
        })
        // 完成滚动需要一定的时间
        await wait(300)
      }

      prevChunkId.current = res.next_chunk_id
      startIndex.current = Math.max(
        res.current_index_id - res.contents.length,
        0,
      )

      setHasPrev(!isNil(prevChunkId.current))
      setParagraphs(prev => [...transform2Paragraphs(res.contents), ...prev])
    }
  }

  const registryParagraphInstance = (
    key: number | string,
    instance: ParagraphInstance,
  ) => {
    paragraphInstances.current[key] = instance
  }

  const destroyParagraphInstance = (key: number | string) => {
    delete paragraphInstances.current[key]
  }

  const handleParagraphValidator = async (
    content: string,
    callback?: (res: IParagraphContentUsedToken) => void,
  ) => {
    let access = false
    let data = {} as TokenSizeResponse
    try {
      data = await fetchTokenSizeByWord({ file_id: file_id!, content })
      access = data.token_consumption <= data.max_token
    } catch (e) {
      console.error('error')
    }
    await callback?.({
      access,
      data,
    })
    return access
  }

  const triggerTargetParagraphItemRender = (
    instanceKey: string | number,
    params: SplitItemConfig,
  ) => {
    const targetItem = paragraphInstances?.current?.[instanceKey]
    targetItem?.reRender?.(params)
  }

  const triggerTargetParagraphItemValidator = (
    instanceKey: Array<string | number>,
  ) => {
    instanceKey.forEach(item => {
      const targetItem = paragraphInstances?.current?.[item]
      targetItem?.validator?.()
    })
  }

  const onSplit = (
    splits: [SplitItemConfig, SplitItemConfig],
    index: number,
  ) => {
    const newParagraphs = [...paragraphs]
    const currentChunkKey = newParagraphs[index].key

    const currentChunkId =
      newParagraphs[index].chunk_id ?? newParagraphs[index].originalChunkId!
    const newSplits: Paragraph[] = splits.map((item, index) => {
      return {
        content: item.content,
        keywords: item.keywords,
        extra_info: {
          ...item.extra_info,
          chunk_status: ParagraphExtraInfoStatus.Done,
        },
        enable: true,
        isEdited: true,
        originalChunkId: currentChunkId,
        key:
          index === 0 ? currentChunkKey : `${KEY_PREFIX}${++flagCount.current}`, // [1] --> [1, 'temp_1']
      }
    })
    // 记录操作
    const key = currentChunkId ?? currentChunkKey
    operationMap.current.Split[key] = operationMap.current.Split[key] ?? []
    operationMap.current.Split[key].push({
      id: newSplits[1].key, // 第一条数据继承了分割之前的Id，所以后分割的分段作为新分段被记录
      sort_id: flagSort.current++,
      identifier: currentChunkKey,
    })

    triggerTargetParagraphItemRender(currentChunkKey, {
      content: splits[0].content,
    })

    const deleteItems = newParagraphs.splice(index, 1, ...newSplits)
    deleteItems.forEach(item => {
      delete operationMap.current.Update[item.key]
    })

    setParagraphs(newParagraphs)
    setViewTotal(viewTotal + 1)
    setTimeout(() => {
      triggerTargetParagraphItemValidator([currentChunkKey, newSplits[1].key])
    }, 100)
  }

  const onAdd = (index: number) => {
    const newParagraphs = [...paragraphs]
    const currentChunkKey = newParagraphs[index].key

    const currentChunkId =
      newParagraphs[index].chunk_id ?? newParagraphs[index].originalChunkId!

    // 记录操作
    const key = currentChunkId ?? currentChunkKey

    const newParagraphKey = `${KEY_PREFIX}${++flagCount.current}`
    const newParagraph: Paragraph = {
      content: '',
      keywords: [],
      extra_info: {
        chunk_status: ParagraphExtraInfoStatus.Done,
      },
      enable: true,
      isEdited: true,
      originalChunkId: currentChunkId,
      key: newParagraphKey,
    }
    operationMap.current.Add[key] = operationMap.current.Add[key] ?? []
    operationMap.current.Add[key].push({
      id: newParagraphKey,
      sort_id: flagSort.current++,
      identifier: newParagraphKey,
      original_identifier: currentChunkKey,
    })

    newParagraphs.splice(index, 0, newParagraph)
    setParagraphs(newParagraphs)
    setViewTotal(viewTotal + 1)
  }

  const onMerge = async (prevIndex: number, currIndex: number) => {
    const newParagraphs = [...paragraphs]

    const prevParagraph = newParagraphs[prevIndex]
    const currParagraph = newParagraphs[currIndex]
    const prevParagraphKey = prevParagraph.key
    const currParagraphKey = currParagraph.key

    const mergeContent = [
      paragraphInstances.current[prevParagraphKey].content,
      paragraphInstances.current[currParagraphKey].content,
    ].join('\n')

    const access = await handleParagraphValidator(mergeContent)
    if (!access) {
      message.error('字数超过限制，无法合并')
      return
    }

    const mergedKeywords = union(prevParagraph.keywords, currParagraph.keywords)

    // merge的extraInfo信息默认是可用的
    const mergedExtraInfo: IParagraphExtraInfo = {
      ...prevParagraph.extra_info,
      ...currParagraph.extra_info,
      chunk_status: ParagraphExtraInfoStatus.Done,
      ai_created: false,
      enable_ai_support: false,
    }

    const newParagraphKey = newParagraphs[prevIndex].key

    /**
     * 性能优化，
     * 条件：如果当前合并的分段原本属于一个Chunk，
     * 这两个分段的内容没有被编辑过：1、不需要记录合并操作 2、删除这两个分段的拆分操作
     * 这两个分段有一个被编辑过：1、不需要记录合并操作 2、 删除分段的拆分操作 3、修改更新操作到合并后的分段
     */
    const prevOriginalChunkId = prevParagraph.originalChunkId
    const currOriginalChunkId = currParagraph.originalChunkId
    const Update = operationMap.current.Update

    const isSameOriginalChunk =
      prevOriginalChunkId &&
      currOriginalChunkId &&
      prevOriginalChunkId === currOriginalChunkId
    const hasUpdate =
      Update[prevParagraphKey]?.content || Update[currParagraphKey]?.content
    if (isSameOriginalChunk) {
      const Split = operationMap.current.Split
      const Update = operationMap.current.Update
      if (Split[prevOriginalChunkId]) {
        const splitCombinations = [
          `${prevParagraphKey}__${currParagraphKey}`,
          `${currParagraphKey}__${prevParagraphKey}`,
        ]
        Split[prevOriginalChunkId] = Split[prevOriginalChunkId].filter(item => {
          const combination = `${item.identifier}__${item.id}`
          return !splitCombinations.includes(combination)
        })
      }
      if (hasUpdate) {
        delete Update[prevParagraphKey]
        delete Update[currParagraphKey]
        Update[newParagraphKey] = {
          content: true,
          enable: true,
          keywords: true,
          extra_info: true,
        }
      }
    } else {
      // 记录操作
      operationMap.current.Merge.push({
        identifier: newParagraphKey,
        merge_identifiers: [prevParagraphKey, currParagraphKey],
        sort_id: flagSort.current++,
      })
    }

    const deleteItems = newParagraphs.splice(prevIndex, 2, {
      content: mergeContent,
      keywords: mergedKeywords,
      extra_info: mergedExtraInfo,
      enable: true,
      isEdited: true,
      originalChunkId: newParagraphs[prevIndex].originalChunkId,
      key: newParagraphKey,
    })

    deleteItems.forEach(item => {
      delete operationMap.current.Update[item.key]
    })

    triggerTargetParagraphItemRender(prevParagraphKey, {
      content: mergeContent,
      keywords: mergedKeywords,
      extra_info: mergedExtraInfo,
    })

    triggerTargetParagraphItemValidator([prevParagraphKey])

    setParagraphs(newParagraphs)
    setViewTotal(viewTotal - 1)
  }

  const onSave = async () => {
    if (file_id) {
      const operations: any[] = []
      const contents: Record<string | number, string> = {}
      const keywords: Record<string | number, any[]> = {}
      const extraInfos: Record<string | number, any> = {}

      const textsCountMap: Record<string | number, number> = {}

      for (let i = 0; i < paragraphs.length; i++) {
        const paragraph = paragraphs[i]
        if (paragraph.isEdited) {
          contents[paragraph.key] =
            paragraphInstances.current[paragraph.key].content
          if (paragraph.keywords) {
            keywords[paragraph.key] = paragraph.keywords
          }
          extraInfos[paragraph.key] = paragraph.extra_info
          textsCountMap[paragraph.key] =
            paragraphInstances.current[paragraph.key].textCount || 0
        }
      }
      const hasMoreThanParagraph = Object.values(textsCountMap).some(
        count => count > MAX_SPLIT_SIZE,
      )
      if (hasMoreThanParagraph) {
        message.error(`单个分段不能超过${MAX_SPLIT_SIZE}`)
        return
      }
      const { Split, Merge, Update, Delete, Add } = operationMap.current

      Object.values(Add).forEach(value => {
        value.forEach(item => {
          operations.push({
            action: 'add',
            original_identifier: item.original_identifier.toString(),
            identifier: item.identifier.toString(),
            sort_id: item.sort_id,
          })
        })
      })

      Object.values(Split).forEach(value => {
        value.forEach(item => {
          operations.push({
            action: 'split',
            original_identifier: item.identifier.toString(),
            identifier: item.id.toString(),
            sort_id: item.sort_id,
          })
        })
      })
      Merge.forEach(item => {
        operations.push({
          ...item,
          identifier: item.identifier.toString(),
          merge_identifiers: item.merge_identifiers.map(id => id.toString()),
          action: 'merge',
        })
      })

      // 删除操作
      const deleteOperation: Operation[] = Object.keys(Delete).map(
        identifier => ({
          action: 'delete',
          identifier,
        }),
      )
      const deletedKeyMap: Record<string | number, true> = Delete

      Object.entries(contents).forEach(([identifier, content]) => {
        if (!content) {
          deletedKeyMap[identifier] = true
          deleteOperation.push({
            action: 'delete',
            identifier,
          })
        }
      })

      const sortedOperations: Operation[] = [
        ...operations.sort((a, b) => a.sort_id - b.sort_id),
        ...Object.entries(Update).map(([identifier, value]) => {
          return {
            action: 'update',
            identifier,
            enable: value.enable ?? true,
          }
        }),
        ...deleteOperation,
      ].map(item => {
        delete item.sort_id
        return item
      })

      // 遍历 keywords，截断超过20个字符的关键字
      Object.entries(keywords).forEach(([key, keywordArray]) => {
        if (keywordArray?.length > 20) {
          keywords[key] = keywordArray.slice(0, MAX_KEYWORDS_SIZE)
        }
      })
      await handleSave({
        file_id,
        operations: sortedOperations,
        content_updates: contents,
        keywords_updates: keywords,
        extra_info_updates: extraInfos,
      }).then(res => {
        if (res.status === 'success') {
          const temp_to_real_id_mappings = res.temp_to_real_id_mappings || {}
          const temp_to_keywords_mappings = res.temp_to_keywords_mappings || {}
          let newParagraphs = [...paragraphs]
          newParagraphs.forEach(item => {
            if (keywords[item.key]) {
              item.keywords = keywords[item.key]
            }
            if (contents[item.key]) {
              item.content = contents[item.key]
            }
            if (extraInfos[item.key]) {
              item.extra_info = extraInfos[item.key]
            }
            if (!isNil(temp_to_real_id_mappings[item.key])) {
              item.content = contents[item.key]
              item.extra_info = extraInfos[item.key]
              if (
                temp_to_keywords_mappings[temp_to_real_id_mappings[item.key]]
              ) {
                item.keywords =
                  temp_to_keywords_mappings[temp_to_real_id_mappings[item.key]]
              }
              item.key = item.chunk_id = temp_to_real_id_mappings[item.key]
            }

            item.isEdited = false
          })
          // 过滤删除的分段
          newParagraphs = newParagraphs.filter(item => !deletedKeyMap[item.key])
          setParagraphs(newParagraphs)
          setHasDeleted(false)
          setEditable(false)
          operationMap.current = {
            Split: {},
            Merge: [],
            Update: {},
            Delete: {},
            Add: {},
          }
          // message.success('保存成功')
        }
      })
    }
  }

  const onSearch = async (params?: IParagraphSearchParams) => {
    clean()

    if (!params) {
      searchParams.current = {}
    } else {
      searchParams.current = params
    }
    await init()
  }

  const onEnableChange = async (
    enable: boolean,
    index: number,
    needFetch = true,
  ) => {
    const newParagraphs = [...paragraphs]
    newParagraphs[index].enable = enable
    newParagraphs[index].isEdited = true

    // 记录操作
    const currentKey = newParagraphs[index].key
    operationMap.current.Update[currentKey] = operationMap.current.Update[
      currentKey
    ] ?? { enable: true }
    operationMap.current.Update[currentKey].enable = enable

    setParagraphs(newParagraphs)
    if (needFetch) {
      await onSave()
    }
  }

  const onKeywordsChange = async (
    keywords: string[],
    index: number,
    needFetch = true,
  ) => {
    const newParagraphs = [...paragraphs]
    newParagraphs[index].keywords = keywords || []
    newParagraphs[index].isEdited = true

    // 记录操作
    const currentKey = newParagraphs[index].key
    operationMap.current.Update[currentKey] = operationMap.current.Update[
      currentKey
    ] ?? { keywords: true }
    operationMap.current.Update[currentKey].keywords = true

    setParagraphs(newParagraphs)
    if (needFetch) {
      await onSave()
    }
  }

  const onEdit = async (index: number, needFetch = true) => {
    const newParagraphs = [...paragraphs]
    if (newParagraphs[index].isEdited) {
      return
    }
    newParagraphs[index].isEdited = true

    // 记录操作
    const currentKey = newParagraphs[index].key
    operationMap.current.Update[currentKey] = operationMap.current.Update[
      currentKey
    ] ?? { content: true }
    operationMap.current.Update[currentKey].content = true

    setParagraphs(newParagraphs)
    if (needFetch) {
      await onSave()
    }
  }

  const onDelete = async (key: number | string, needFetch = true) => {
    const newParagraphs = [...paragraphs]
    const index = newParagraphs.findIndex(item => item.key === key)

    if (index !== -1) {
      newParagraphs.splice(index, 1)

      // 记录操作
      operationMap.current.Delete[key] = true
    }
    setHasDeleted(true)
    setParagraphs(newParagraphs)
    setViewTotal(viewTotal - 1)
    if (needFetch) {
      await onSave()
      if (newParagraphs.length < DEFAULT_PAGE_SIZE) {
        loadMore()
      }
    }
  }

  const updateParagraphsItemById = async (
    value: Paragraph,
    needFetch = true,
  ) => {
    const newParagraphs = [...paragraphs]
    newParagraphs.forEach(item => {
      if (item.chunk_id === value.chunk_id) {
        item.content = value.content
        item.extra_info = value.extra_info
        item.keywords = value.keywords
        item.enable = value.enable ?? item.enable
        if (value.qa_info) {
          item.qa_info = value.qa_info
        }
      }
    })
    setParagraphs(newParagraphs)
    if (needFetch) {
      await onSave()
    }
  }

  const addParagraphsItem = async (value: Paragraph, targetId?: number) => {
    let newParagraphs = [...paragraphs]
    const newItem = {
      ...value,
      key: value.chunk_id!,
    }
    if (!isNil(targetId)) {
      const idx = findIndex(paragraphs, o => o.chunk_id === targetId)
      if (idx > -1) {
        newParagraphs.splice(idx, 0, newItem)
      }
    } else {
      newParagraphs = [newItem, ...newParagraphs]
    }
    setViewTotal(viewTotal + 1)
    setParagraphs(newParagraphs)
  }

  const { run: handleAIProcessFetch } = useDebounceFn(
    async (ids: number | number[]) => {
      const idList = Array.isArray(ids) ? ids : [ids]
      cancelAiProcessListPolling()
      await handlePollAiProcessList({
        ids: idList,
        file_id: file_id!,
      })
    },
    { wait: 500 },
  )

  useEffect(() => {
    paragraphs.forEach(e => {
      if (
        e?.extra_info?.chunk_status &&
        (e?.extra_info?.chunk_status === ParagraphExtraInfoStatus.Process ||
          e?.extra_info?.chunk_status === ParagraphExtraInfoStatus.Pending)
      ) {
        aiProcessListIds.current = union([
          ...aiProcessListIds.current,
          e.chunk_id!,
        ])
      }
    })
    if (aiProcessListIds.current?.length) {
      handleAIProcessFetch(aiProcessListIds.current)
    }
  }, [paragraphs])

  const handleGenerateAISummary = async (id?: number | undefined) => {
    try {
      const newParagraphs = [...paragraphs]
      await generateAISummaryByChunkId({ id, file_id: file_id! })
      const ids: number[] = []
      // 没传id代表的是批量处理
      setAIprocessLoading(true)
      if (isNil(id)) {
        newParagraphs.forEach(item => {
          if (
            item.extra_info?.chunk_status ===
              ParagraphExtraInfoStatus.Pending ||
            item.extra_info?.chunk_status ===
              ParagraphExtraInfoStatus.Process ||
            item.extra_info?.chunk_status === ParagraphExtraInfoStatus.Error
          ) {
            item.extra_info = {
              ...item.extra_info,
              chunk_status: ParagraphExtraInfoStatus.Process,
              enable_ai_support: false,
            }
            ids.push(item.chunk_id!)
          }
        })
      } else {
        newParagraphs.forEach((item, idx) => {
          if (item.chunk_id === id) {
            newParagraphs[idx].extra_info = {
              ...newParagraphs[idx].extra_info,
              chunk_status: ParagraphExtraInfoStatus.Process,
              enable_ai_support: false,
            }
          }
        })
      }
      setParagraphs(newParagraphs)
      aiProcessListIds.current = ids
      if (id || ids.length) {
        await handleAIProcessFetch(id || ids)
      }
    } finally {
      setAIprocessLoading(false)
    }
  }

  const handleStartEdit = () => {
    setParagraphsSnapshot(cloneDeep(paragraphs))
    setEditable(true)
  }

  const handleExitEdit = () => {
    operationMap.current = {
      Split: {},
      Merge: [],
      Update: {},
      Delete: {},
      Add: {},
    }
    setParagraphs(paragraphsSnapshot)
    setParagraphsSnapshot([])
    setEditable(false)
  }
  return {
    startIndex,
    loading,
    hasPrev,
    hasMore: hasNext,
    total: viewTotal,
    clean,
    init,
    onDelete,
    paragraphs,
    onAdd,
    onSplit,
    onMerge,
    onEnableChange,
    onKeywordsChange,
    onEdit,
    hasEdited,
    onSave,
    registryParagraphInstance,
    destroyParagraphInstance,
    loadMore,
    saveLoading: saveLoading || aIProcessLoading,
    updateParagraphsItemById,
    addParagraphsItem,
    handleGenerateAISummary,
    handleParagraphValidator,
    isEmpty: !loading && isInited && paragraphs.length === 0,
    isEditing, // 编辑状态
    isInited,
    cancelAiProcessListPolling,
    onSearch,
    searchParams: searchParams.current,
    handleStartEdit,
    handleExitEdit,
  }
}
