import { useCountDown } from 'ahooks'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import type { OrderDetail } from '@apis/order/type'
import { PayType } from '../../const'
import { padZero } from '../../util'
import { Button } from '@/components'
import { useWorkspaceStore } from '@/store'
import { PayIcon } from './PayIcon'

export interface WaitPayProps {
  order: OrderDetail
  onCancelOrder: (orderId: string) => void
  cancelLoading?: boolean
  onPay?: () => void
}

export function WaitPay({ order, cancelLoading }: WaitPayProps) {
  const [_countdown, formattedRes] = useCountDown({
    targetDate: order?.orderExpireTime,
  })

  const { minutes, seconds } = formattedRes

  const navigate = useNavigate()
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const handleBack = () => {
    navigate(`/workspace/${currentWorkspaceId}/settings/orders`)
  }

  const payUrl = useMemo(() => {
    const baseUrl =
      import.meta.env.MODE === 'development'
        ? '/api'
        : import.meta.env.VITE_AI_API_BASE_URL
    return `${baseUrl}/v1/pay/send_pay?order_no=${order?.orderNo}&pay_type=${PayType.ALI_PAY}`
  }, [order?.orderNo])

  return (
    <div className='flex flex-col items-center'>
      <PayIcon
        type='wait'
        content={
          <div>
            待支付{' '}
            <span className='text-#E54A17'>{`${padZero(minutes)}:${padZero(
              seconds,
            )}`}</span>
          </div>
        }
      ></PayIcon>
      <div className='mt-48'>订单提交成功，请尽快完成支付</div>
      <div className='mt-16'>超时将自动关闭</div>
      <div className='mt-48'>
        <Button
          className='mr-12 w-120px'
          loading={cancelLoading}
          onClick={handleBack}
        >
          暂不支付
        </Button>
        <a href={payUrl} target='_blank' rel='noreferrer'>
          <Button type='primary' className='w-120px'>
            去支付
          </Button>
        </a>
      </div>
    </div>
  )
}
