import { Access, useAuth } from '@bty/react-auth'
import { useRequest } from 'ahooks'
import classNames from 'classnames'
import { useCallback, useMemo, useState } from 'react'
import type { NavigateFunction } from 'react-router-dom'
import { message } from 'antd'
import dayjs from 'dayjs'
import type { ItemType } from 'antd/es/menu/interface'
import {
  checkForkResources,
  forkApplication,
  overWriteApplication,
} from '@apis/application'
import type { ApplicationBodyType } from '@apis/application/type'
import { RoleCode } from '@apis/authority/type'
import { AppLogo, Button, IconFont } from '@/components'
import { NodeType } from '@/features/nodes/base/types'
import { AppDropDown } from '@/pages/agent/components/AppDropDown'
import { useWorkspaceStore } from '@/store'
import { RenderItemWarpper, editTimeText, nodeIcon } from '../utils'
import { useCoverModal } from '@/pages/agent/components/CoverModal'
import { SelectType, useWorkspaceSelectModal } from './WorkSpaceSelect'

export function FlowItem(
  props: Partial<ApplicationBodyType> & {
    phantomItem: any
    flowData: any
    navigate: NavigateFunction
    refresh: () => Promise<any>
    handleGroup: () => void
    item: ApplicationBodyType
    handleOpenGroup: (id: string) => void
    removeToGroup: (app: ApplicationBodyType) => ItemType
  } & React.HTMLAttributes<HTMLDivElement>,
) {
  const {
    navigate,
    id,
    color,
    icon,
    name,
    isPublish,
    modifyBy,
    updateAt,
    flowId,
    refresh,
    nodeType,
    phantomItem,
    item,
    flowData,
    removeToGroup,
    handleOpenGroup,
    handleGroup,
    ...otherProps
  } = props
  const { access } = useAuth()
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  // const setFlowLock = useFlowDraftStore(s => s.setFlowLock)
  const toFlowPage = () => navigate(`/application/${id}`)
  const [open, setOpen] = useState(false)

  const { runAsync: forkApplicationApi } = useRequest(forkApplication, {
    manual: true,
  })
  const { runAsync: overApplicationApi } = useRequest(overWriteApplication, {
    manual: true,
  })
  const { data: isShowConnect, runAsync: fetchCheckResource } = useRequest(
    checkForkResources,
    {
      manual: true,
    },
  )
  const handleFork = useCallback(
    async (workspaceId: string, showConnectComponent?: boolean) => {
      const res = await forkApplicationApi({
        source_appid: id!,
        source_workspace_id: currentWorkspaceId,
        target_workspace_id: workspaceId,
        is_force_fork: showConnectComponent,
      })
      handleOpenGroup(res.app_group_id)
      window.open(
        `/application/${res.application_id}?forkTaskId=${res.task_id}`,
        '_blank',
      )
      onForkSuccess()
    },
    [currentWorkspaceId, id, isShowConnect],
  )
  const handleCover = useCallback(
    async (source_appid: string, target_appid?: string) => {
      await overApplicationApi({
        source_appid,
        target_appid: target_appid || '',
      })
      onCoverSuccess()
      refresh()
      message.success('覆盖成功')
    },
    [currentWorkspaceId],
  )
  const [coverModal] = useCoverModal({
    onConfirm: handleCover,
    dataList: flowData,
    appId: id || '',
    selectType: SelectType.FLOW,
    coverText: '覆盖工作流',
  })
  const [workspaceSelectModal] = useWorkspaceSelectModal({
    onConfirm: handleFork,
    defaultWorkspaceId: currentWorkspaceId,
    showConnectComponent: true,
    appId: id,
    copyType: '复制工作流',
    checkLimit: 'app',
  })
  function onCoverSuccess() {
    coverModal.close()
  }
  function onForkSuccess() {
    workspaceSelectModal.close()
  }

  const filterNodeType = useMemo(() => {
    const excludedTypes = [
      NodeType.START,
      NodeType.END,
      NodeType.CONDITION_RESULT,
      NodeType.INTENT_RESULT,
      NodeType.LOOP_RESULT,
      NodeType.EMPTY,
    ]
    return nodeType?.filter(item => !excludedTypes.includes(item)) || []
  }, [nodeType])

  return (
    <RenderItemWarpper
      {...otherProps}
      open={open}
      className={classNames(
        'p-20 bg-#fff border-rd-12px h-160px flex flex-col justify-between w-100%',
      )}
      onClick={toFlowPage}
      style={{ visibility: phantomItem.id === id ? 'hidden' : 'visible' }}
    >
      <div
        className='flex gap-12px p-b-4px'
        // style={{ borderBottom: '1px solid rgba(225, 225, 229, 0.4)' }}
      >
        <div className='overflow-hidden relative' style={{ flex: '0 0 48px' }}>
          <AppLogo
            value={icon || ''}
            type='emoji'
            color={color}
            size={48}
            className='cursor-pointer'
            imgStyle={{ borderRadius: '10px' }}
          />
          {/* <div
            className='w-48px h-48px border-rd-10px flex flex-justify-center flex-items-center text-26px'
            style={{ background: color }}
          >
            <span>{icon}</span>
          </div> */}
        </div>
        <div className='flex flex-col justify-center w-[calc(100%_-_60px)]'>
          <div title={name} className='truncate text-16px font-600 m-b-12px'>
            {name}
          </div>
          <div className='flex gap-8px flex-items-center'>
            <span
              className={classNames(
                'color-#8D8D99 border-rd-4px text-12px px-8px h-20px text-center line-height-20px',
                {
                  'bg-green c-green bg-op-12': isPublish,
                  'bg-bg_3 c-font_1 bg-op-12': !isPublish,
                },
              )}
            >
              {isPublish ? '已发布' : '未发布'}
            </span>
            {/* {otherProps.flow_lock === FLOW_STATUS.BATCH_TEST_LOCK ? (
              <span className='truncate  flex flex-1 color-#2CB969 text-12px flex flex-items-center'>
                <div className='bg-#2CB969 w-4px h-4px rounded-50% mr-6px'></div>
                <span>批量运行中</span>
              </span>
            ) : ( */}
            <span
              title={`${modifyBy}更新于${editTimeText(dayjs(updateAt))}`}
              className='truncate flex-1 color-#c0c3d6 text-12px font-500 flex flex-items-center'
            >
              <span className='truncate inline-block'>{modifyBy}</span>
              <span className='font-400'>
                更新于{editTimeText(dayjs(updateAt))}
              </span>
            </span>
            {/* )} */}
          </div>
        </div>
      </div>
      <div className='text-12px text-font_1 text-op-60 pb-4px truncate'>
        {otherProps.description !== '暂无描述' ? otherProps.description : ''}
      </div>
      {phantomItem.id !== id && (
        <div className='buttonWarpper flex flex-justify-between w-100%'>
          <div className='flex gap-8px flex-1'>
            <Button
              size='small'
              className='flex flex-items-center border-rd-6px text-12px flex-justify-center flex-1'
              icon={<IconFont className='text-14px' name='enter' />}
              onClick={toFlowPage}
              type='primary'
            >
              应用详情
            </Button>
            <Access
              access={access.role([RoleCode.DEVELOPER, RoleCode.ADMINISTRATOR])}
              hide
            >
              <Button
                size='small'
                className='flex flex-items-center border-rd-6px text-12px flex-justify-center flex-1'
                onClick={e => {
                  // setFlowLock(FLOW_DRAFT_LOCK_STATUS.UNLOCK)
                  e.stopPropagation()
                  navigate(
                    `/application/${currentWorkspaceId}/${id}/flow/${flowId}?source=${location.pathname}`,
                  )
                }}
                icon={<IconFont className='text-14px' name='edit-app' />}
              >
                编辑
              </Button>
              <AppDropDown
                openChange={setOpen}
                removeToGroup={removeToGroup}
                handleDelete={() => refresh().then(handleGroup)}
                app={item}
                handleFork={() => {
                  fetchCheckResource(id ?? '').then(() => {
                    workspaceSelectModal.open()
                  })
                }}
                handleCover={() => {
                  coverModal.open()
                }}
              />
            </Access>
          </div>
        </div>
      )}
      <div className='messageWarpper flex justify-between flex-items-center'>
        <div
          className='flex flex-items-center gap-3px p-8px border-rd-8px'
          style={{ background: 'rgba(98, 105, 153, 0.06)' }}
        >
          {filterNodeType?.slice(0, 3)?.map((item, index) => (
            <div key={index} className='flex flex-items-center gap-3px'>
              <IconFont key={index} name={nodeIcon[item]} />
              {filterNodeType?.length !== index + 1 && (
                <span
                  className='text-20px line-height-16px'
                  style={{ color: 'rgba(98, 105, 153, 0.24)' }}
                >
                  -
                </span>
              )}
            </div>
          ))}
          {Number(filterNodeType.length) > 3 && (
            <IconFont className='text-16px' name='gengduojiedian' />
          )}
        </div>
        <div
          style={{ color: 'rgba(98, 105, 153, 0.4)' }}
          className='flex flex-items-end text-12px font-500 truncate gap-5px'
        >
          <span className='text-32px line-height-24px font-italic'>
            {
              nodeType?.filter(
                item =>
                  ![
                    NodeType.CONDITION_RESULT,
                    NodeType.INTENT_RESULT,
                    NodeType.LOOP_RESULT,
                  ].includes(item),
              ).length
            }
          </span>
          节点
        </div>
      </div>
    </RenderItemWarpper>
  )
}
