import { Form, Space, message } from 'antd'
import { useState } from 'react'
import { useRequest, useCountDown, useBoolean } from 'ahooks'
import { merge } from 'lodash-es'
import { useSearchParams } from 'react-router-dom'
import { AppJoined } from '@apis/authority/type'
import type { SendSMSRequest } from '@apis/sms/type'
import { SendSMS } from '@apis/sms'
import type { UserByPhoneRes } from '@apis/user/type'
import { authorizeChatbot, doLogin, getUserByPhone, signUp } from '@apis/user'
import { IconFont, Input, InputPassword } from '@/components'
import { decryptDes } from '@/utils/crypto'
import { tokenStorage, useIdStorage } from '@/utils/storage'
import { useUserStore, useWorkspaceStore } from '@/store'
import { PRIVACY_URL, SERVICE_URL } from '@/constants/url'
import { PageLink } from '@/components/pageModal/PageLink'
import { PRODUCT_BASE_NAME } from '@/constants/common.ts'
import { LoginButton, StyledCheckbox } from './components/LoginButton'
import type { ModalType } from './LoginModal'

export interface RegisterProps {
  onLogin?: () => void
  onChangType?: (type: ModalType) => void
}

const defaultParams: SendSMSRequest = {
  setting: {
    verificationType: 1,
    verificationLength: 6,
    expirationTime: 10 * 60, // 验证码过期时间
  },
  params: {
    phoneNumbers: '',
    signName: '斑头雁智能科技',
    templateCode: 'SMS_464495595',
    templateParam: 'code',
  },
}

const COUNT_DOWN_TIME = 60 // 60s
const mobileReg = /^1[3456789]\d{9}$/
const usernameReg = /^[\u4E00-\u9FA5a-zA-Z0-9_]+$/
export const mobileRules = [
  { required: true, message: '请输入手机号' },
  {
    pattern: mobileReg,
    message: '请输入正确的手机号',
    validateTrigger: 'onSubmit',
  },
]
const passwordRules = [
  { required: true, message: '密码输入错误，请输入6-16位密码（数字、字母）' },
  // {
  //   // pattern: passwordValidateRegexp,
  //   message: '密码输入错误，请输入6-16位密码（数字、字母）',
  //   validateTrigger: 'onSubmit',
  // },
]
const usernameRules = [
  { required: true, message: '昵称输入错误，最多10个字符（除表情符号）' },
  {
    max: 10,
    message: '昵称输入错误，最多10个字符（除表情符号）',
    validateTrigger: 'onSubmit',
  },
  {
    pattern: usernameReg,
    message: '昵称输入错误，最多10个字符（除表情符号）',
    validateTrigger: 'onSubmit',
  },
]
const codeRules = [
  { required: true, message: '请输入6位数字验证码', length: 6 },
]
function verifyCode(randomCode: string, code: string) {
  return decryptDes(randomCode) === code
}
export function Register(props: RegisterProps) {
  const { onLogin, onChangType } = props
  const [searchParams] = useSearchParams()
  const channel = searchParams.get('channel')

  const {
    runAsync: sendSMSApi,
    data: SMSResData,
    loading,
  } = useRequest(SendSMS, { manual: true })
  const { runAsync: signUpApi, loading: signUpLoading } = useRequest(signUp, {
    manual: true,
  })
  const { runAsync: doLoginApi } = useRequest(doLogin, { manual: true })
  const { runAsync: getUserByPhoneApi } = useRequest(getUserByPhone, {
    manual: true,
  })
  const { runAsync: authChatbotApi } = useRequest(authorizeChatbot, {
    manual: true,
  })

  const [isSendingSMS, { toggle: toggleSendingSMS }] = useBoolean(false)
  const [leftTime, setLeftTime] = useState<number>()
  const [status, setStatus] = useState<'mobile' | 'setting'>('mobile')

  const { fetchWorkspaceList } = useWorkspaceStore()
  const { fetchUser } = useUserStore()

  const [checked, { toggle: toggleChecked }] = useBoolean(false)

  const [SMSCodeForm] = Form.useForm()

  const [fieldValues, setFieldValues] = useState({
    phone: '',
    verifyCode: '',
    username: '',
    password: '',
  })

  const [_countdown, formattedRes] = useCountDown({
    leftTime: leftTime ? leftTime * 1000 : undefined,
    onEnd() {
      toggleSendingSMS()
      setLeftTime(0)
    },
  })

  const handleSendSMS = async () => {
    const values = await SMSCodeForm.validateFields(['phone'])

    const res = await getUserByPhoneApi(values.phone).catch(() => false)
    if (res && (res as UserByPhoneRes)?.activatedStatus === AppJoined.JOINED) {
      message.warning('该手机号已注册，请直接登录')
      return
    }
    await sendSMSApi(
      merge(defaultParams, { params: { phoneNumbers: values.phone } }),
    )
    toggleSendingSMS()
    setLeftTime(COUNT_DOWN_TIME)
  }

  const handleVerifyCode = async () => {
    const values = await SMSCodeForm.validateFields(['verifyCode', 'phone'])
    const verifySuccess = verifyCode(
      SMSResData?.randomCode ?? '',
      values.verifyCode,
    )

    if (verifySuccess) {
      setStatus('setting')
    }
  }

  const handleRegister = async () => {
    const values = await SMSCodeForm.validateFields(['username', 'password'])
    const phone = SMSCodeForm.getFieldValue('phone')
    signUpApi({
      ...values,
      phone,
      userProfile: {
        source: channel || undefined,
        profile: {
          useScope: ['AI'],
        },
      },
    })
      .then(async data => {
        if (data.userId) {
          try {
            await authChatbotApi(data.userId)
          } catch (error) {
            // err
          }
          const res = await doLoginApi({ phone, password: values.password })
          if (res.accessToken) {
            tokenStorage.set(res.accessToken)
            useIdStorage.set(String(res.userId))

            const [workspaceList] = await Promise.all([
              fetchWorkspaceList(),
              fetchUser(),
            ])

            if (!workspaceList.length) {
              onChangType?.('workspace')
            }
          } else {
            onChangType?.('login')
          }
        }
      })
      .catch(error => {
        if (error.business && error.message === '用户已经注册，请直接登录') {
          setTimeout(() => onChangType?.('login'), 2000)
        }
      })
  }

  const validateCode = (_: any, value: string) => {
    if (!verifyCode(SMSResData?.randomCode ?? '', value)) {
      return Promise.reject('短信验证码错误')
    }
    return Promise.resolve()
  }

  return (
    <div className='w-446px relative overflow-hidden px-48 py-60'>
      <div className='w-208px h-132px absolute left-0px top--30px bg-#00FFDD bg-opacity-20 opacity-60 rounded-104px/66px blur-80px'></div>
      <div className='w-259px h-170px absolute right--30px top--50px bg-#A200FF bg-opacity-20 opacity-60 rounded-104px/66px blur-100px'></div>
      <h3 className='text-font font-800 text-24px'>
        欢迎注册{PRODUCT_BASE_NAME}账号 👏
      </h3>
      <p className='text-font_1 text-14px mt-16'>
        已有账号？
        <span className='text-primary cursor-pointer' onClick={onLogin}>
          立即登录
        </span>
      </p>
      <div className='mt-60'>
        <Form
          initialValues={fieldValues}
          form={SMSCodeForm}
          onValuesChange={(_, values) => setFieldValues(values)}
        >
          <Form.Item
            name='phone'
            rules={mobileRules}
            hidden={status !== 'mobile'}
          >
            <Input size='large' type='text' placeholder='手机号'></Input>
          </Form.Item>
          <Form.Item
            name='verifyCode'
            hidden={status !== 'mobile'}
            rules={[
              ...codeRules,
              { validator: validateCode, validateTrigger: 'onSubmit' },
            ]}
          >
            <Space>
              <Form.Item noStyle>
                <Input
                  size='large'
                  style={{ width: 210 }}
                  placeholder='验证码'
                  maxLength={6}
                />
              </Form.Item>
              <LoginButton
                type='primary'
                size='large'
                disabled={isSendingSMS}
                className='w-132px important:rd-8px'
                loading={loading}
                onClick={handleSendSMS}
              >
                {isSendingSMS
                  ? `重新获取 ${formattedRes.seconds} S`
                  : '获取验证码'}
              </LoginButton>
            </Space>
          </Form.Item>
          <Form.Item
            name='username'
            rules={usernameRules}
            hidden={status === 'mobile'}
          >
            <Input
              size='large'
              type='text'
              placeholder='设置昵称，最多20个字符（除表情符号）'
              maxLength={10}
            ></Input>
          </Form.Item>
          <Form.Item
            name='password'
            rules={passwordRules}
            hidden={status === 'mobile'}
          >
            <InputPassword
              size='large'
              type='password'
              className='mt-4'
              placeholder='设置密码，6-16位密码（数字、字母）'
              minLength={6}
              maxLength={16}
              iconRender={visible =>
                visible ? (
                  <IconFont className='text-font_1' name='eye-show' />
                ) : (
                  <IconFont className='text-font_1' name='eye-hide' />
                )
              }
            ></InputPassword>
          </Form.Item>
        </Form>
      </div>

      <div className='mb-10 mt-60'>
        {status === 'mobile' && (
          <StyledCheckbox checked={checked} onChange={toggleChecked}>
            <p className='text-font_1 text-12px'>
              我已阅读并同意
              <PageLink href={SERVICE_URL} className='text-primary'>
                服务协议
              </PageLink>{' '}
              和
              <PageLink href={PRIVACY_URL} className='text-primary'>
                用户隐私协议
              </PageLink>
            </p>
          </StyledCheckbox>
        )}
      </div>
      {status === 'mobile' ? (
        <LoginButton
          type='primary'
          size='large'
          block
          onClick={handleVerifyCode}
          disabled={!checked}
        >
          下一步
        </LoginButton>
      ) : (
        <LoginButton
          className='mt-24px'
          type='primary'
          size='large'
          block
          disabled={fieldValues.username === '' || fieldValues.password === ''}
          onClick={handleRegister}
          loading={signUpLoading}
        >
          注册
        </LoginButton>
      )}
    </div>
  )
}
