import type { ReactNode } from 'react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { Avatar } from '@bty/chat-ui'
import cn from 'classnames'
import { useRequest } from 'ahooks'
import { Popover, QRCode } from 'antd'
import type { AuthAppWeChat, Bot } from '@bty/chat-types'
import { PUBLISH_MODE } from '@bty/chat-types'
import { fetchAgentDetail } from '@apis/agent'
import { forkApplication } from '@apis/application'
import { RoleCode } from '@apis/authority/type'
import { getAvatarFromBot } from '../utils/getAvatar'
import { Button, IconFont, usePageModal } from '@/components'
import { useVersionStore, useWorkspaceStore } from '@/store'
import { useWorkspaceSelectModal } from '@/pages/application/components/WorkSpaceSelect'
import defaultSkillIcon from '@/assets/agent/defult_skill_icon.png'
import WeChatIcon from '@/assets/agent/wechat-icon.png'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

const DEFAULT_SKILL_DISPLAY_COUNT = 10

export interface BotDetailProps {
  className?: string
  bot: Bot
  collapsed?: boolean
  onCollapse: (collapsed: boolean) => void
  onPromptClick: (prompt: string) => void
}

function BotDetail({ className, bot, collapsed, onCollapse }: BotDetailProps) {
  const botId = bot.robot_id as string

  const [skillsExpanded, setSkillsExpanded] = useState(false)

  const {
    currentWorkspaceId,
    workspaceList,
    setCurrentWorkspace,
    fetchWorkspaceList,
  } = useWorkspaceStore()

  const setBotWorkspaceItem = useVersionStore(
    state => state.setBotWorkspaceItem,
  )

  const { data } = useRequest(() => fetchAgentDetail(botId), {
    refreshDeps: [botId, currentWorkspaceId],
    onSuccess: res => {
      if (res?.workspace_id && res?.workspace_id !== currentWorkspaceId) {
        setBotWorkspaceItem({
          workspaceId: res?.workspace_id,
          developerName: res?.developer,
          workspaceName: res?.workspace_name,
        })
      } else {
        setBotWorkspaceItem(undefined)
      }
    },
  })

  const pageModal = usePageModal()

  const agentIdForCreateRef = useRef('')

  const { runAsync: createAgentByTemplate } = useRequest(forkApplication, {
    manual: true,
  })

  // 用于单独打开的 chatbot 页面，获取 workspaceList 和 currentWorkspaceItem
  useEffect(() => {
    if (workspaceList?.length === 0 && currentWorkspaceId) {
      fetchWorkspaceList().then(() => {
        setCurrentWorkspace(currentWorkspaceId)
      })
    }
  }, [workspaceList, currentWorkspaceId])

  // 关掉对话框，重置 botworkspaceItem
  useEffect(() => {
    return () => {
      setBotWorkspaceItem(undefined)
    }
  }, [botId, currentWorkspaceId])

  const [workspaceSelectModal] = useWorkspaceSelectModal({
    copyType: '复制Agent',
    onConfirm: async (workspaceId: string, selectedConnect?: boolean) => {
      const res = await createAgentByTemplate({
        source_appid: agentIdForCreateRef.current,
        source_workspace_id: bot.workspace_id,
        target_workspace_id: workspaceId,
        is_force_fork: selectedConnect,
      })
      // pageModal.show({
      //   url: `/agent/${res.application_id}?forkTaskId=${res.task_id}`,
      // })
      window.open(
        `/agent/${res.application_id}?forkTaskId=${res.task_id}`,
        '_blank',
      )
      workspaceSelectModal.close()
      setCurrentWorkspace(workspaceId)
    },
    defaultWorkspaceId: currentWorkspaceId,
    showConnectComponent: true,
    appId: data?.appId,
    checkLimit: 'app',
  })

  const handleCollapse = () => {
    onCollapse(!collapsed)
  }

  const renderSectionHeader = (
    title: ReactNode,
    icon: string,
    count?: number,
  ) => (
    <span className='flex-items-center inline-flex gap-6 p-16px pb-8px'>
      <IconFont className='text-16px text-bg_3 text-opacity-60' name={icon} />
      <span className='text-14px/16px text-font font-semibold'>{title}</span>
      {count && <span className='text-#8d8d99'>{count}</span>}
    </span>
  )

  const skills = (data?.flows || [])
    .map(el => ({
      name: el.name,
      icon: el.icon,
      color: el.color,
    }))
    .concat(
      (data?.utility || []).map(el => ({
        name: el.display_name,
        icon: el.metadata?.icon || '📖',
        color: el.metadata?.color || '#C4C3CB',
      })),
    )

  const displaySkills = useMemo(
    () =>
      skills.length <= DEFAULT_SKILL_DISPLAY_COUNT
        ? skills
        : skillsExpanded
          ? skills
          : skills.slice(0, DEFAULT_SKILL_DISPLAY_COUNT),
    [skills, skillsExpanded],
  )

  const agentGoData = useMemo(
    () =>
      data?.agent_auth_app.filter(
        item => item.auth_app_source === PUBLISH_MODE.wechat_kf,
      ) as AuthAppWeChat[],
    [data],
  )

  const workspace = workspaceList.find(el => el.id === data?.workspace_id)

  const couldEdit =
    workspace !== undefined &&
    [RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER].includes(workspace.roleCode)

  const onOpenDetail = () => {
    const url =
      bot.type === 'AI-AGENT'
        ? `${location.origin}/agent/${bot.app_id}`
        : `${location.origin}/application/${currentWorkspaceId}/${bot.app_id}/flow/${bot.flow_id}`
    pageModal.show({ url })
  }

  const { scrollRef } = useScrollBar()

  const hasMemory =
    !!data?.dataset?.length || !!data?.database?.table_list?.length

  return (
    <div
      className={cn(
        className,
        'relative pt-34px border-left after:border-l-line after:border-op-60 bg-bg flex flex-col items-stretch',
      )}
    >
      <button
        className='absolute top-24px right-16px chat-icon-btn w-24px h-24px z-1'
        onClick={handleCollapse}
      >
        <IconFont className='text-16px' name='guanbi' />
      </button>

      <div ref={scrollRef} className='w-300px overflow-y-scroll p-16px'>
        <div>
          <section>
            <div className='flex flex-col flex-items-center'>
              <Avatar
                className='shrink-0'
                size={80}
                icon={getAvatarFromBot(bot)}
                color={bot.color}
              />
              <span className='my-12px text-16px/20px text-font font-semibold break-all'>
                {data?.application?.name}
              </span>
              <span className='mb-12px text-center text-14px/20px text-#3f3f44'>
                {data?.application?.description}
              </span>
              <span className='mb-8px text-14px text-#8d8d99 break-all'>
                开发者：{data?.developer}
              </span>
              <span className='text-14px text-#8d8d99 break-all'>
                空间：{data?.workspace_name}
              </span>
            </div>
            {data?.appId && (
              <div className='flex flex-nowrap gap-8px'>
                {couldEdit && (
                  <button
                    className={cn(
                      'mt-16px shrink-0 h-36px inline-flex items-center justify-center gap-6 rounded-8px text-14px font-semibold border border-line border-op-80 text-font bg-white  hover:bg-bg_3 hover:bg-op-8',
                      {
                        'flex-1': !data?.isTemplate,
                        'px-20px': data.isTemplate,
                      },
                    )}
                    onClick={onOpenDetail}
                  >
                    <IconFont
                      className='text-bg_3 text-opacity-60'
                      name='edit-app'
                    />
                    编辑
                  </button>
                )}
                {data?.isTemplate && (
                  <button
                    className='mt-16px shrink-0 flex-1 min-w-170px h-36px inline-flex items-center justify-center gap-6 rounded-8px text-14px font-semibold text-white bg-primary  border-0 hover:bg-op-80'
                    onClick={() => {
                      agentIdForCreateRef.current = data.appId
                      workspaceSelectModal.open()
                    }}
                  >
                    <IconFont className='text-white' name='add' />
                    复制到我的空间
                  </button>
                )}
              </div>
            )}
          </section>

          {!!skills.length && (
            <section className='mt-30 pb-16px bg-#fff b-rd-8px'>
              {renderSectionHeader('技能', 'jitai', skills.length)}
              <ul className='mt-8px'>
                {displaySkills.map((item, index) => (
                  <li
                    key={index.toString()}
                    className='flex items-center px-16 last-of-type:mb-0 mb-16'
                  >
                    {item?.icon?.startsWith('http') ? (
                      <img
                        className='w-24px h-24px b-rd-6px'
                        src={item.icon || defaultSkillIcon}
                        alt=''
                      />
                    ) : (
                      <div
                        className='flex-center w-24px h-24px b-rd-6px'
                        style={{ backgroundColor: item.color }}
                      >
                        {item.icon}
                      </div>
                    )}
                    <span className='ml-8px text-14px text-#17171d break-all'>
                      {item.name}
                    </span>
                  </li>
                ))}
              </ul>
              {skills.length > DEFAULT_SKILL_DISPLAY_COUNT &&
                !skillsExpanded && (
                  <div
                    className='flex flex-items-center mt-16px px-16px cursor-pointer'
                    onClick={() => setSkillsExpanded(true)}
                  >
                    <span className='text-#8d8d99'>展开全部</span>
                    <IconFont
                      className='ml-8px text-#8d8d99'
                      name='zhankaimianban'
                    />
                  </div>
                )}
            </section>
          )}

          {hasMemory && (
            <section className='mt-16px bg-#fff rounded-8px'>
              {renderSectionHeader('记忆', 'jiyi')}
              <ul className='mt-8'>
                {data.dataset?.length ? (
                  <li className='flex items-center px-16 pb-16'>
                    <span
                      className='block w-24px h-24px mr-8 text-14px/24px text-center rounded-6px'
                      style={{
                        background:
                          ' linear-gradient(315deg, #DCEFFF 1%, #E6F2FF 100%)',
                      }}
                    >
                      📑
                    </span>
                    <span className='text-font'>
                      {data.dataset.length}个专业文档
                    </span>
                  </li>
                ) : null}
                {data.database?.table_list?.length ? (
                  <li className='flex items-center px-16 pb-16'>
                    <span
                      className='block w-24px h-24px mr-8 text-14px/24px text-center rounded-6px'
                      style={{
                        background:
                          ' linear-gradient(315deg, #DCEFFF 1%, #E6F2FF 100%)',
                      }}
                    >
                      📊
                    </span>
                    <span className='text-font'>
                      {data.database.table_list.length}个数据库表
                    </span>
                  </li>
                ) : null}
              </ul>
            </section>
          )}

          {!!agentGoData?.length && (
            <section className='mt-16px bg-#fff b-rd-8px'>
              {renderSectionHeader('Agent Go', 'agentgo')}
              <div className='mt-8px px-16px pb-16px'>
                {agentGoData.map(item => (
                  <Popover
                    key={item.id}
                    overlayInnerStyle={{
                      width: 231,
                    }}
                    content={
                      <div className='flex flex-col flex-items-center'>
                        <QRCode
                          size={102}
                          bordered={false}
                          className='mt--4px'
                          value={`https://work.weixin.qq.com/kfid/${item.auth_app_config.KfIds[0]}`}
                        />
                        <span className='text-center text-14px/21px'>
                          微信扫一扫添加
                          <br />【{item.auth_app_name}】
                        </span>
                      </div>
                    }
                  >
                    <Button
                      block
                      icon={
                        <img
                          className='block w-18px h-18px translate-y-4px'
                          src={WeChatIcon}
                          alt='wechatIcon'
                        />
                      }
                      className='last-of-type:mb-0 mb-16px'
                    >
                      在微信使用
                    </Button>
                  </Popover>
                ))}
              </div>
            </section>
          )}
        </div>
      </div>
    </div>
  )
}

export default React.memo(BotDetail)
