import classNames from 'classnames'
import { IconFont } from '@/components'
import { SearchContentSelect } from '../SearchContentSelect'
import { TextEditor } from '@/features/editor/TextEditor'
import { NodeFormItem } from '@/features/nodes/components'
import type { KnowledgeDataValue, KnowledgeNodeData } from '../../KnowledgeNode'
import type { InnerNodeProps } from '@/features/nodes/base'
import { HitStrategy } from '../../constants'

interface SearchContentProps {
  value?: KnowledgeDataValue
  nodeElement?: InnerNodeProps<KnowledgeNodeData>['nodeElement']
  variables?: InnerNodeProps<KnowledgeNodeData>['variables']
  onChange?: (values: KnowledgeDataValue) => void
  disabled?: boolean
  errorContent: string[]
}
export default (props: SearchContentProps) => {
  const { value, onChange, disabled, errorContent } = props

  const handleSearchContentTypeChange = (type: unknown) => {
    value && onChange?.({ ...value, hitStrategy: type as number })
  }

  const handleSearchContentChange = (content?: string) => {
    value && onChange?.({ ...value, searchContent: content || '' })
  }
  return (
    <>
      <div className='flex justify-between items-center mb-8px'>
        <div className='font-500 text-12px flex items-center'>
          查询内容
          <span className='c-#ff5219 text-18px ml-5px block h-12px'>*</span>
        </div>
        <NodeFormItem required className='!mb-0px'>
          <SearchContentSelect
            suffixIcon={
              <IconFont name='arrow' className='text-7px !text-font_1' />
            }
            getPopupContainer={triggerNode =>
              triggerNode.parentNode as HTMLElement
            }
            onChange={handleSearchContentTypeChange}
            virtual={false}
            dropdownStyle={{ minWidth: '102px' }}
            popupMatchSelectWidth={false}
            variant='borderless'
            size='small'
            className='nodrag nopan'
            value={value?.hitStrategy || HitStrategy.MIX}
            disabled={disabled}
            options={[
              {
                label: (
                  <div className='flex gap-4px text-12px items-center justify-center line-height-16px'>
                    混合查询
                    <span className='c-#FF8056 py-2px text-9px font-500 px-4px bg-#FFECE6 border-rd-100px'>
                      推荐
                    </span>
                  </div>
                ),
                value: HitStrategy.MIX,
              },
              {
                label: (
                  <div className='text-12px line-height-16px'>语义查询</div>
                ),
                value: HitStrategy.SEMANTIC,
              },
              {
                label: (
                  <div className='text-12px line-height-16px'>关键词查询</div>
                ),
                value: HitStrategy.KEYWORD,
              },
            ]}
          />
        </NodeFormItem>
      </div>
      <NodeFormItem required className='flex-1'>
        <TextEditor
          placeholder={
            [
              '',
              `输入变量或文本，从知识库中通过语义和关键词进行查询，并综合排序
，效果最好
`,
              `输入变量或文本，基于关键词进行查询，并综合排序，推荐在查询特定
名词、缩写词、短语、ID时使用
`,
              `输入变量或文本，基于语义相关度进行查询，并综合排序，推荐在需要
理解语义相关度或跨语言查询时使用
`,
            ]?.[value?.hitStrategy || HitStrategy.MIX]
          }
          focusScroll
          minHeight={120}
          maxHeight={480}
          aria-invalid={errorContent.length > 0 ? 'true' : 'false'}
          className={classNames('text-12px', {
            'op-40': disabled,
          })}
          disabled={disabled}
          onChange={handleSearchContentChange}
          variables={props.variables}
          variableTipsContainer={props.nodeElement}
          value={value?.searchContent}
        />
      </NodeFormItem>
    </>
  )
}
