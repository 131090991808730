import { Form } from 'antd'
import classNames from 'classnames'
import { useMemo } from 'react'
import { PUBLISH_MODE } from '@bty/chat-types'
import { enableApp } from '@apis/agent-go'
import type { AuthAppDingTalkWeb } from '@apis/agent-go/type'
import type { AgentGoPublishConfProps } from '@/features/agent/components/AgentGoPublishConf'
import { AgentGoPublishConf } from '@/features/agent/components/AgentGoPublishConf'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider.tsx'
import { useQueryAgentGoApp } from '@/features/agent/hooks/useQueryAgentGoApp.ts'
import { Checkbox, Input } from '@/components'
import { LinkText } from '@/features/agent/components/LinkText.tsx'
import renderLabel from '@/features/agent/components/renderLabel.tsx'
import { AgentGoFormItem } from '@/features/agent/components/AgentGoFormItem.tsx'
import { CopyBox } from '../components/CopyBox'
import { SERVER_IP_ADDRESSES } from '@/constants/common'
import { DING_TALK_WEB_DOCS } from './common'

interface DingTalkWebBindRequest {
  AppKey: string
  AppSecret: string
  auth_app_name: string
  confirmOnWebApp?: boolean
  confirmOnSafeSetting?: boolean
  confirmBeforeComplete?: boolean
}

interface AgentGoDingTalkWebProps {
  mode?: string
  modeIcon?: string
  onBindSuccess?: () => void
  onUnBindSuccess?: () => void
}

export default function AgentGoDingTalkWeb({
  mode,
  modeIcon,
  onBindSuccess,
  onUnBindSuccess,
}: AgentGoDingTalkWebProps) {
  const [form] = Form.useForm<DingTalkWebBindRequest>()
  const { applicationInfo } = useAgentEdit()

  const { runQueryApp, app, setApp, loading } =
    useQueryAgentGoApp<AuthAppDingTalkWeb>({
      agentId: applicationInfo?.flowId,
      source: PUBLISH_MODE.dingtalk_web,
      onQuerySuccess: data => {
        const { auth_app_name, auth_app_config } = data
        form.setFieldsValue({
          auth_app_name,
          ...auth_app_config,
          confirmOnWebApp: false,
          confirmOnSafeSetting: false,
          confirmBeforeComplete: false,
        })
      },
    })

  const handleComplete: NonNullable<
    AgentGoPublishConfProps['onComplete']
  > = appId => {
    onBindSuccess?.()
    if (appId && applicationInfo) {
      runQueryApp(applicationInfo.flowId)
    }
  }

  const steps = [
    {
      title: '填写应用信息',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium text-font'>
            登录{' '}
            <LinkText href='https://open-dev.dingtalk.com/'>
              钉钉开放平台
            </LinkText>{' '}
            获取应用信息
          </div>
          <p className='mt-8px text-12px text-font_2 font-normal mb-24'>
            注意！需要使用<span className='text-font'>企业管理员</span>
            的钉钉账号扫码登录
          </p>
        </>
      ),
    },
    {
      title: '配置网页应用',
      disabled: true,
      content: (
        <div className='text-16px font-medium mb-24'>
          复制以下地址，到{' '}
          <LinkText href='https://open-dev.dingtalk.com/'>
            钉钉开放平台
          </LinkText>{' '}
          配置「网页应用」
        </div>
      ),
      confirminfo: {
        fieldName: 'confirmOnWebApp',
        title: '我已配置「网页应用」',
        description: '未配置「网页应用」将无法正常使用',
      },
    },
    {
      title: '完善安全设置',
      disabled: true,
      content: (
        <div className='text-16px font-medium mb-24'>
          复制以下参数，到{' '}
          <LinkText href='https://open-dev.dingtalk.com/'>
            钉钉开放平台
          </LinkText>{' '}
          完善「安全设置」
        </div>
      ),
      confirminfo: {
        fieldName: 'confirmOnSafeSetting',
        title: '我已完善「安全设置」',
        description: '未完善「安全设置」将无法正常使用',
      },
    },
    {
      title: '发布钉钉应用',
      disabled: true,
      content: (
        <div className='}text-16px font-medium mb-24'>
          在{' '}
          <LinkText href='https://open-dev.dingtalk.com/'>
            钉钉开放平台
          </LinkText>{' '}
          版本管理中发布当前应用
        </div>
      ),
      confirminfo: {
        fieldName: 'confirmBeforeComplete',
        title: '钉钉应用已发布',
        description: '未发布的应用将无法正常使用',
      },
    },
  ]

  const handleBeforeComplete = (value: DingTalkWebBindRequest) => {
    delete value.confirmBeforeComplete
    return value
  }

  const handleValuesChange: NonNullable<
    AgentGoPublishConfProps['onValuesChange']
  > = changedValues => {
    if ('is_enable' in changedValues) {
      setApp({ ...app, is_enable: changedValues.is_enable })
      enableApp(app.auth_app_id!, changedValues.is_enable)
    }
  }

  const handleUnBindSuccess = async () => {
    setApp({})
    onUnBindSuccess?.()
  }

  const corpId = Form.useWatch('CorpId', form)

  const url = useMemo(
    () =>
      `${
        import.meta.env.VITE_AGENT_CHAT_URL
      }/chat/${applicationInfo?.flowId}?workspaceId=${applicationInfo?.workspaceId}&corpId=$CORPID$`,
    [applicationInfo, corpId],
  )

  return loading ? null : (
    <AgentGoPublishConf
      form={form}
      mode={mode}
      app={app}
      steps={steps}
      guides={DING_TALK_WEB_DOCS}
      serviceAvatar={modeIcon}
      completeDeps={['confirmBeforeComplete']}
      onComplete={handleComplete}
      onBeforeComplete={handleBeforeComplete}
      onValuesChange={handleValuesChange}
      onUnBindSuccess={handleUnBindSuccess}
    >
      {step => (
        <>
          {steps[step].content}
          <AgentGoFormItem
            label={renderLabel('CorpId', true)}
            name='CorpId'
            required
            hidden={step !== 0}
            normalize={(value: string) => value?.trim()}
            rules={[{ required: true, message: 'CorpId 不能为空' }]}
          >
            <Input
              placeholder='在「钉钉开放平台首页」中获取'
              spellCheck={false}
            />
          </AgentGoFormItem>
          <AgentGoFormItem
            label={renderLabel('Client ID', true)}
            name='AppKey'
            required
            hidden={step !== 0}
            normalize={(value: string) => value?.trim()}
            rules={[{ required: true, message: 'Client ID 不能为空' }]}
          >
            <Input
              placeholder='在「钉钉应用-凭证与基础信息」中获取'
              spellCheck={false}
            />
          </AgentGoFormItem>
          <AgentGoFormItem
            label={renderLabel('Client Secret', true)}
            name='AppSecret'
            required
            hidden={step !== 0}
            normalize={(value: string) => value?.trim()}
            rules={[{ required: true, message: 'Client Secret 不能为空' }]}
          >
            <Input
              placeholder='在「钉钉应用-凭证与基础信息」中获取'
              spellCheck={false}
            />
          </AgentGoFormItem>
          <AgentGoFormItem
            label={renderLabel('应用名称', true)}
            name='auth_app_name'
            required
            hidden={step !== 0}
            normalize={(value: string) => value?.trim()}
            rules={[{ required: true, message: '应用名称不能为空' }]}
          >
            <Input placeholder='在「钉钉应用-凭证与基础信息」中获取' />
          </AgentGoFormItem>

          <AgentGoFormItem
            label={renderLabel('应用首页地址', true)}
            hidden={step !== 1}
          >
            <CopyBox value={url} />
          </AgentGoFormItem>
          <AgentGoFormItem
            label={renderLabel('PC 端首页地址', true)}
            hidden={step !== 1}
          >
            <CopyBox value={url} />
          </AgentGoFormItem>

          <AgentGoFormItem
            label={renderLabel('服务器出口 IP', true)}
            hidden={step !== 2}
          >
            <CopyBox value={SERVER_IP_ADDRESSES.join(',')} />
          </AgentGoFormItem>
          <AgentGoFormItem
            label={renderLabel('重定向 URL（回调域名）', true)}
            hidden={step !== 2}
          >
            <CopyBox value={url} />
          </AgentGoFormItem>

          <div
            className={classNames(
              'bg-bg_3 bg-op-6 rounded-8px p-12',
              steps[step]?.confirminfo ? '' : 'hidden',
            )}
          >
            <Form.Item
              name={steps[step]?.confirminfo?.fieldName}
              valuePropName='checked'
              preserve={true}
              hidden={!steps[step]?.confirminfo}
              rules={[
                {
                  validator: (_, value) => {
                    if (steps[step]?.confirminfo) {
                      return value ? Promise.resolve() : Promise.reject()
                    }
                    return Promise.resolve()
                  },
                },
              ]}
              noStyle
            >
              <Checkbox className='!mb-4'>
                <span className='text-font text-14px/20px'>
                  {steps[step].confirminfo?.title}
                </span>
              </Checkbox>
            </Form.Item>
            <p className='text-font_2 text-12px/20px ml-24'>
              {steps[step].confirminfo?.description}
            </p>
          </div>
        </>
      )}
    </AgentGoPublishConf>
  )
}
