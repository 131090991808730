import styled from '@emotion/styled'
import type dayjs from 'dayjs'
import { isNumber, isNaN } from 'lodash-es'
import { Button } from 'antd'
import type { ApplicationBodyType } from '@apis/application/type'
import { FLOW_DISPLAYNAME } from '@/constants/common'

export const status: {
  background: string
  color: string
  text: string
  boxShadow: string
}[] = [
  {
    background: 'rgba(254, 151, 0, 0.12)',
    color: '#FE9700',
    text: '已停用',
    boxShadow: '0px 0px 6px 0px rgba(254, 151, 0, 0.4)',
  },
  {
    background: 'rgba(44, 185, 105, 0.12)',
    color: '#2CB969',
    text: '启用中',
    boxShadow: '0px 0px 6px 0px rgba(49, 204, 116, 0.4)',
  },
]

export const nodeIcon: Record<string, string> = {
  CODE: 'daimajiedian',
  API: 'api-1',
  WEBHOOK: 'api-1',
  LLM: 'llm-1',
  TEXT: 'wenbenjiedian-libiao',
  APP: 'ziyingyongjiedian',
  INSERT_DATA: 'zharushujujiedian-libiaoqiapian',
  MEMORY: 'shujujijiedian',
  KNOWLEDGE: 'shujujijiedian',
  CONDITION: 'luojijiedian',
  INTENT: 'yitufenleijiedian-libiao',
  SUB_FLOW: 'flowjiedian',
  TEMPLATE: 'flowjiedian',
  PLUGIN: 'pluginjiedian-libiaoqiapian',
  DATABASE: 'shujukujiedian-1',
  LOOP: 'xunhuanjiedian-libiaoqiapian',
  LLM_BATCH: 'llm-1',
}

export const RenderItemWarpper = styled.div<{ open: boolean }>`
  cursor: pointer;
  position: relative;
  overflow: hidden;
  .buttonWarpper {
    display: flex;
    position: absolute;
    padding: 5px 0;
    box-sizing: border-box;
    bottom: ${({ open }) => (open ? '17px' : '-50px')};
    width: calc(100% - 40px);
    background: #fff;
    transition: bottom 0.3s;
  }
  .messageWarpper {
    display: flex;
  }
  &:hover {
    box-shadow: 0px 12px 20px 0px rgba(23, 26, 29, 0.12);
    .buttonWarpper {
      bottom: 17px;
    }
  }
`

export const CreateButton = styled(Button)`
  &:hover {
    box-shadow: 0px 12px 20px 0px rgba(23, 26, 29, 0.12);
  }
`

export function editTimeText(data: dayjs.Dayjs): string {
  const time = new Date().getTime() - new Date(data.toDate()).getTime()
  const m = 60 * 1000
  const h = 60 * 60 * 1000
  const d = 24 * 60 * 60 * 1000
  const M = 30 * 24 * 60 * 60 * 1000
  const y = 365 * 24 * 60 * 60 * 1000
  if (time > y) {
    return `${Math.floor(time / (1000 * 60 * 60 * 24 * 365))}年前`
  }
  if (time > M) {
    return `${Math.floor(time / (1000 * 60 * 60 * 24 * 30))}月前`
  }
  if (time > d) {
    return `${Math.floor(time / (1000 * 60 * 60 * 24))}天前`
  }
  if (time > h) {
    return `${Math.floor(time / (1000 * 60 * 60))}小时前`
  }
  if (time > m) {
    return `${Math.floor(time / (1000 * 60))}分钟前`
  }
  return `${Math.floor(time / 1000)}秒前`
}

export function getName(
  list: ApplicationBodyType[],
  _type: 'agent' | 'app' = 'app',
): string {
  const tag =
    _type === 'agent' ? 'Agent未命名应用' : `未命名${FLOW_DISPLAYNAME}`
  const findStartNum = (str: string) => str?.split(tag)[1]?.match(/^\d+/)?.[0]
  const arr = list
    .filter(item => {
      const flag = findStartNum(item.name)
      return (
        item.name?.includes(tag) &&
        isNumber(Number(flag)) &&
        !isNaN(Number(flag))
      )
    })
    .sort((a, b) => {
      return Number(findStartNum(b.name)) - Number(findStartNum(a.name))
    })
  return `${tag}${Number(findStartNum(arr[0]?.name) || 0) + 1}`
}
