import NiceModal from '@ebay/nice-modal-react'

import { useWorkspaceStore } from '@/store'
import { LimitedModalId } from '@/features/pay/LimitedPanel/LimitedPanel'
import { API_CODE_CODE, KEYU_API_BUSINESS_CODE } from './constants'

export class BusinessError extends Error {
  name: string
  message: string
  code?: string | number
  status?: number
  business? = true
  constructor(message: string, code?: string | number, status = 200) {
    super(message)
    this.name = 'businessError'
    this.message = message
    this.code = code
    this.status = status
  }
}

export function handleGeneralBusinessError(code: number) {
  if (
    [
      KEYU_API_BUSINESS_CODE.POINTS_NOT_ENOUGH,
      API_CODE_CODE.COMBO_NOT_ENOUGH,
    ].includes(code)
  ) {
    const role = useWorkspaceStore.getState().currentWorkspaceItem?.roleCode
    if (role) {
      // 开发时下面这行代码触发了关于StartNode文件的循环引用,具体见文件 features/nodes/start/components/FieldsConfig.tsx
      // console.log(LimitedModal)
      NiceModal.show(LimitedModalId, { role })
      return true
    }
    return false
  }
}
