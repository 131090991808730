import copy from 'copy-to-clipboard'
import { Spin, message } from 'antd'
import { MessageList, type MessageItem } from '@bty/chat-ui'
import { useEffect, useMemo, useState } from 'react'
import { useRequest } from 'ahooks'
import { transformAPIRes2MessageList } from '@bty/chat-renderer-pc'
import type { Bot, ConversationData } from '@bty/chat-types'
import {
  getConversationListBySnapshotId,
  getConversationListByConversationId,
  shareAgentContent,
} from '@apis/agent'
import { TaskBar } from '..'
import { getAvatarFromBot } from '../utils/getAvatar'
import { generateBotMessage } from '../utils/chatMessage'
import { useStsToken } from '../hooks/useStsToken'
import { Button, IconFont, Modal } from '@/components'
import { useUserStore } from '@/store'
import { ChatAvatar } from './ChatAvatar'

export interface ShareChatProps {
  open: boolean
  bot: Bot
  currentConversation?: ConversationData
  onCancel?: () => void
  getMessageList: () => MessageItem[]
  snapshotId?: string
}

export function ShareMessageList({
  bot,
  conversation_id,
  getMessageList,
  type = 'snapshot',
}: {
  bot: Bot
  conversation_id?: string
  getMessageList?: () => MessageItem[]
  type?: 'snapshot' | 'conversation'
}) {
  const user = useUserStore(state => state.user)
  const { getImageUrlByToken, stsTokenData, loading } = useStsToken()
  const [index, setIndex] = useState(1) //  临时解决antd的modal中react-virtuoso渲染失败的bug，原因不详
  const [list, setList] = useState<MessageItem[]>([])

  const userAvatarInfo = useMemo<MessageItem['avatarInfo']>(
    () => ({
      color: 'rgba(20, 171, 241, 0.12)',
      shape: 'circle',
      icon: user?.avatar || user?.username.substring(0, 1) || (
        <IconFont name='user' />
      ),
    }),
    [user],
  )

  const robotAvatarInfo = useMemo(() => {
    return { icon: getAvatarFromBot(bot), color: bot.color }
  }, [bot])

  const welcomeText = useMemo(() => {
    return bot.welcome_message
      ? [
          {
            ...generateBotMessage({
              content: bot.welcome_message,
              avatarInfo: robotAvatarInfo,
            }),
            id: '-2',
            actions: [],
            showRegenerateBtn: false,
          },
        ]
      : []
  }, [bot])

  const { run, loading: getListLoading } = useRequest(
    () =>
      type === 'snapshot'
        ? getConversationListBySnapshotId(conversation_id || '')
        : getConversationListByConversationId(
            conversation_id || '',
            String(bot.id) || '',
          ),
    {
      manual: true,
      ready: !!stsTokenData.access_key_id,
      onSuccess: e => {
        setList(
          [
            ...welcomeText,
            ...transformAPIRes2MessageList(
              (type === 'snapshot' ? e : e.data_list) || [],
              {
                filterRelatedQuestion: true,
              },
            )
              ?.filter(item => !!item.content || !!item.footer)
              ?.map((item: any) => {
                return {
                  ...item,
                  avatarInfo:
                    item.position === 'left' ? robotAvatarInfo : userAvatarInfo,
                  actions: [],
                  showRegenerateBtn: false,
                }
              }),
          ].map(v => {
            if (v.file?.url) {
              v.file.url = getImageUrlByToken(v.file?.url)
            }
            return v
          }),
        )
      },
    },
  )
  useEffect(() => {
    if (conversation_id && !loading) {
      run()
      return
    }
    if (getMessageList) {
      setList(
        getMessageList()?.map(item => ({
          ...item,
          actions: [],
          showRegenerateBtn: false,
          ...(item.relatedQuestionInfo
            ? { relatedQuestionInfo: undefined }
            : {}),
        })),
      )
    }
  }, [loading, getMessageList])

  useEffect(() => {
    if (!getListLoading) {
      setTimeout(() => setIndex(index + 1), 1000)
    }
  }, [list])
  if (index === 1) {
    return <Spin style={{ width: '100%', height: '100%' }} />
  }
  const props = {
    needManualScrollToBottom: false,
    toolComponent: TaskBar,
    className: 'h-full w-full',
  }
  return <MessageList {...props} data={list} />
}

export function ShareChat({
  open,
  onCancel,
  currentConversation,
  bot,
  getMessageList /* snapshotId */,
}: ShareChatProps) {
  const { runAsync, loading } = useRequest(
    () => shareAgentContent(currentConversation?.conversation_id || ''),
    { manual: true },
  )
  const shareAgent = async () => {
    // if (snapshotId) {
    //   copy(`${location.origin}/shareAgent/${snapshotId}/${bot.robot_id}?title=${currentConversation?.title}`)
    //   message.success('复制成功！')
    //   return
    // }
    const shareConversationId = await runAsync()
    copy(
      `${
        import.meta.env.VITE_AGENT_LINKS_URL
      }/shareAgent/${shareConversationId}/${bot.robot_id}?title=${
        currentConversation?.title
          ? encodeURIComponent(currentConversation.title)
          : ''
      }`,
    )
    message.success('复制成功！')
  }
  return (
    <Modal
      width={'70%'}
      destroyOnClose
      title={
        <div className='flex items-center h-72px border-b border-[rgba(225,225,229,0.6)]'>
          <ChatAvatar bot={bot} currentConversation={currentConversation} />
        </div>
      }
      footer={
        <div
          className='px-20px py-10px'
          style={{ borderTop: '1px solid #eee' }}
        >
          <Button loading={loading} onClick={shareAgent} type='primary'>
            复制链接
          </Button>
        </div>
      }
      open={open}
      onCancel={() => onCancel?.()}
    >
      <div className='h-80vh'>
        {open && (
          <ShareMessageList
            bot={bot}
            conversation_id={currentConversation?.conversation_id || ''}
            type='conversation'
            getMessageList={getMessageList}
          />
        )}
      </div>
    </Modal>
  )
}
