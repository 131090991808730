import { Form } from 'antd'
import { get } from 'lodash-es'
import { memo } from 'react'
import { PromptType } from '@apis/prompt/type'
import { getPromptFromStructPrompt } from '@/features/prompt/utils/prompt.ts'
import type { RuleFormValues } from '@/features/agent/types/rule.ts'
import { SYSTEM_PROMPT_NAME_PATH } from '@/features/agent/constant/base.ts'
import { PromptOptimizationModal } from '@/features/prompt/components/PromptOptimizationModal'

export const PromptMermaidWithFormItem = memo(() => {
  return (
    <Form.Item<RuleFormValues>
      noStyle
      shouldUpdate={(prev, next) => {
        if (prev.promptType !== next.promptType) {
          return true
        } else {
          if (next.promptType === PromptType.STRUCT) {
            return (
              getPromptFromStructPrompt(prev.structPrompt ?? []) !==
              getPromptFromStructPrompt(next.structPrompt ?? [])
            )
          } else {
            return (
              get(prev, SYSTEM_PROMPT_NAME_PATH) !==
              get(next, SYSTEM_PROMPT_NAME_PATH)
            )
          }
        }
      }}
    >
      {form => {
        const promptType = form.getFieldValue('promptType')
        const prompt =
          promptType === PromptType.STRUCT
            ? getPromptFromStructPrompt(
                form.getFieldValue('structPrompt') ?? [],
              )
            : (form.getFieldValue(SYSTEM_PROMPT_NAME_PATH) ?? '')

        const disabled = !prompt.trim()
        return (
          <PromptOptimizationModal
            prompt={prompt}
            templateType='Agent'
            disabled={disabled}
          />
        )
      }}
    </Form.Item>
  )
})
