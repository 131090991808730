export enum TaskType {
  QUERY = 'query', // 查询，比如知识库
  TAGGING = 'tagging', // 打标
  DETECT_INTENT = 'detectIntent', // 意图检测
  WRITE = 'write', // 文案撰写
}

// 任务生成的方法论
export enum TaskWriteMethods {
  NUM_EMOTION = 0, // 数字情感法
  ADJ_KILL = 1, // 杀死形容词法
  CONFLICT = 2, // 矛盾冲突法
  PROVERB = 3, // 大众俗语法
  CUSTOM = -1, // 自定义方法
}

// AI 遇到不确定因素的行动
export enum UncertaintyStrategy {
  UNKNOWN = 0, // 输出不知道
  RECONFIRM = 1, // 再次确认问题
  CUSTOM = 2, // 自定义动作
}

export interface KeyNodeAndRoute {
  KeyNodes: string
  ConsistencyCheck: string
  ExampleCheck: string
  ContentCheck: string
  Suggestions: string[] | string
}

export interface CheckResult {
  CheckResult: string
  Suggestions: string[] | string
}

export interface OptimizationAutoPrompt {
  KeyNodesandRoutes: KeyNodeAndRoute[]
  OverallCheckResults: {
    ConsistencyCheck: CheckResult
    ExampleCheck: CheckResult
    ContentCheck: CheckResult
  }
}

export interface OptimizationCustomPrompt {
  'Analysis Result': {
    'Problem Identification': {
      'Problem Description': string
      'Possible Causes': string
    }
    'Flowchart Analysis': Array<{
      Step: string
      Problem: string
    }>
  }
  'Optimization Suggestions': {
    'Suggestion Content': Array<{
      'Modification Point': string
      'Example Modification': string
    }>
  }
}
