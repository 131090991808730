import { useRequest } from 'ahooks'
import { message } from 'antd'
import { useCallback } from 'react'
import { publishFlow } from '@apis/flow'
import { useFlowDraftStore } from '@/store'
import { useFlowDraftValidate } from './useFlowDraftValidate'

export function useFlowDraftPublish(
  versionId?: string,
  onRefreshApp?: () => void,
  onRunFlowVersionList?: () => void,
) {
  const setIsModified = useFlowDraftStore(s => s.setIsModified)
  const { validate } = useFlowDraftValidate()

  const { runAsync: publish, loading: isPublishing } = useRequest(publishFlow, {
    manual: true,
    onSuccess: () => {
      message.destroy()
      message.success('发布成功')
    },
  })

  const onPublish = useCallback(async () => {
    const result = await validate()
    if (result) {
      if (versionId) {
        message.loading({
          content: '正在发布',
          duration: 0,
        })
        const res = await publish({ draftVersionId: versionId })
        if (res) {
          setIsModified(res.is_modified)
        }
      }
    }
    onRefreshApp && onRefreshApp()
    onRunFlowVersionList && onRunFlowVersionList()
  }, [versionId, validate, publish, setIsModified, onRefreshApp])

  return {
    isPublishing,
    onPublish,
  }
}
