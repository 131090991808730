import { useEffect, useMemo, useRef, useState } from 'react'
import { message, Form, Spin } from 'antd'
import copy from 'copy-to-clipboard'
import classNames from 'classnames'
import { useMemoizedFn, useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { isString } from 'lodash-es'
import styled from '@emotion/styled'
import type { ApplicationBodyType } from '@apis/application/type'
import { state_enum, log_state_enum } from '@apis/run/type'
import type {
  ScheduledTaskOptionsType,
  nodeConfigType,
  scheduledTaskDetailType,
  scheduledTaskLog,
  scheduledType,
} from '@apis/run/type'
import {
  getScheduledTaskList,
  saveScheduledTask,
  scheduledTaskDetail,
  setScheduledTaskOptions,
  deleteScheduledTask,
  openOrStopScheduledTask,
  getScheduledTaskOptions,
  getScheduledTaskLogList,
} from '@apis/run'
import {
  Input,
  Button,
  IconFont,
  Markdown,
  Table,
  NodeForm,
  GeneratedByAIText,
} from '@/components'
import TimeSelect, {
  formInitValues,
  getTimeFormValue,
} from '../components/TimeSelect'
import {
  DrawerWawrpper,
  DivWarpper,
  StatusWarpper,
  useTimingData,
} from '../utils'

import empty from '@/assets/empty.png'
import { RequiredFormItem } from '@/components/createAppModal/constant'
import theme from '@/constants/theme'
import { hexToRgb } from '@/utils/color'
import { isMarkdown } from '@/pages/flowPage/util'
import { CodeEditor } from '@/features/editor'
import { useComboRequest } from '@/hooks/useComboRequest'
import { NodeType } from '@/features/nodes/base'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import { transformConfig2SchemaConfig } from '@/features/nodes/start/utils.ts'
import { InfiniteScroll } from '@/components/InfiniteScrollComponent'
import { moreRef } from '@/utils/react'
import { getString } from './Log'

const CodeEditorWrapper = styled(CodeEditor)`
  .ace_editor {
    width: 100% !important;
  }
`

function RenderItem(
  args: Partial<scheduledTaskLog> & {
    onRunProps?: (e: scheduledTaskLog) => void
    outputNodeKey: string
  },
) {
  const { onRunProps, ...props } = args
  const isFailed = props.run_status === log_state_enum.FAILED
  const messageStr = isFailed
    ? `失败原因：${getString(props.output)}`
    : props.output
  return (
    <div
      className='border-rd-8px bg-#fff m-b-8px p-16px'
      style={{ border: '1px solid rgba(225, 225, 229, 0.6)' }}
    >
      <div className='flex flex-justify-between flex-items-center font-500'>
        <div>{dayjs(props.finish_time).format('YYYY-MM-DD HH:mm:ss')}</div>
        <StatusWarpper status={props.run_status as any} />
      </div>
      {props.run_status !== log_state_enum.TO_BE_RUN && (
        <>
          <div
            className={classNames({
              'line-height-24px my-12px': true,
              [`color-${isFailed ? '#FF5219' : '#17171D'}`]: true,
            })}
          >
            {messageStr && isString(messageStr) && isMarkdown(messageStr) ? (
              <Markdown text={messageStr} />
            ) : (
              messageStr &&
              !isString(messageStr) && (
                <CodeEditorWrapper
                  style={{ width: 'auto!important' }}
                  readOnly
                  value={JSON.stringify(messageStr, null, 2)}
                  mode='json'
                />
              )
            )}
          </div>
          <div className='flex flex-items-center justify-between'>
            <GeneratedByAIText />
            <div className='flex gap-12px'>
              <Button
                className='text-12px flex flex-items-center'
                style={{ height: 28, fontWeight: 400, padding: '0 12px' }}
                onClick={() => props && onRunProps?.(props as scheduledTaskLog)}
                icon={
                  <IconFont
                    name='yunxingcenshu'
                    className='text-16px color-#8d8d99'
                  />
                }
              >
                运行参数
              </Button>
              <Button
                disabled={isFailed}
                className='text-12px flex flex-items-center'
                style={{ height: 28, fontWeight: 400, padding: '0 12px' }}
                icon={
                  <IconFont name='copy' className='text-16px color-#8d8d99' />
                }
                onClick={() => {
                  messageStr && copy(messageStr)
                  message.success('复制成功！')
                }}
              >
                复制
              </Button>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const TableWrapper = styled(Table)<any>`
  border-radius: 8px;
  border: 1px solid rgba(225, 225, 229, 0.6);
  .ant-table-tbody .ant-table-row .ant-table-cell {
    padding: 5px 16px;
  }
  .ant-table-row[data-row-key='${({ selectRowKey }) => selectRowKey}'] {
    background: rgba(
      ${() => {
        const { r, g, b } = hexToRgb(theme.colors.primary)
        return `${r}, ${g}, ${b}`
      }},
      0.08
    );
  }
`

export default ({
  data,
  firstNodeConfig,
}: {
  data?: Partial<ApplicationBodyType>
  firstNodeConfig: nodeConfigType | null
}) => {
  const [name, setName] = useState('')
  const [current, setCurrent] = useState<scheduledTaskLog | undefined>() // 选中的任务对应不同的Drawer
  const [currentTarget, setCurrentTarget] = useState<
    Partial<ScheduledTaskOptionsType> | undefined
  >() // 选中的任务对应不同的Drawer
  const [editTaskForm] = NodeForm.useForm()
  const [form] = Form.useForm()
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const [detail, setDetail] = useState<scheduledTaskDetailType | undefined>()
  const type = Form.useWatch('type', form)
  const [nowTimingTaskId, setNowTimingTaskId] = useState<string>('')

  const outputNodeKey = useMemo(() => {
    if (!firstNodeConfig || !firstNodeConfig.config?.nodes) {
      return 'output'
    }
    const { nodes } = firstNodeConfig.config
    const outputNode = nodes.find(n => n.type === NodeType.END)
    return outputNode?.name || 'output'
  }, [firstNodeConfig])
  const {
    data: list,
    runAsync: getList,
    loading: getListLoading,
  } = useRequest(() => getScheduledTaskList(data?.flowId || '', name), {
    manual: true,
  })
  const { loading: getDetailLoading, runAsync: getDetail } = useRequest(
    (taskId: string) => scheduledTaskDetail(taskId, data?.flowId || ''),
    { manual: true, onSuccess: setDetail },
  )
  const { loading: getOptionsLoading, runAsync: getOptions } = useRequest(
    (taskId: string) => getScheduledTaskOptions(taskId),
    { manual: true },
  )
  const { loading: changeStateLoading, runAsync: changeState } = useRequest(
    (state: number, taskId: string) => openOrStopScheduledTask(state, taskId),
    { manual: true, onSuccess: () => getList() },
  )
  const clickName = (record: scheduledType) => {
    setSelectedRowKeys([record?.key || 0])
    setCurrentTarget(undefined)
    getDetail(record.task_id)
    setNowTimingTaskId(record.task_id)
  }
  const { loading: deleteLoading, runAsync: deleteTask } = useRequest(
    async e => {
      const res = await deleteScheduledTask(e)
      return { ...res, task_id: e }
    },
    {
      manual: true,
      onSuccess: e => {
        if (e.task_id === list?.[selectedRowKeys?.[0] as number]?.task_id) {
          getList().then(result =>
            result.length > 0 ? clickName(result?.[0]) : setDetail(undefined),
          )
        } else {
          getList()
        }
      },
    },
  )
  const { loading: saveOrEditLoading, runAsync: saveOrEdit } = useComboRequest(
    async () => {
      const formData = await form.validateFields()
      const run_param = await editTaskForm.validateFields()
      const body = {
        ...(formData.week
          ? { ...formData, week: formData.week?.join(',') }
          : formData),
        flow_id: data?.flowId,
        run_param,
      }
      const res = currentTarget?.task_id
        ? await setScheduledTaskOptions(body, currentTarget?.task_id)
        : await saveScheduledTask(body)
      return { ...res, task_id: currentTarget?.task_id }
    },
    {
      manual: true,
      onSuccess: (e: any) => {
        getList().then(result =>
          clickName(
            result?.find(item => item.task_id === e.task_id) || result?.[0],
          ),
        )
        setCurrentTarget(undefined)
      },
    },
    undefined,
    { flowId: data?.flowId, applicationId: data?.id },
  )
  const { columns } = useTimingData({
    deleteTask,
    changeState,
    handleEdit: (e: scheduledType) =>
      e.state === state_enum.RUNNING
        ? message.warning('请先停止任务！')
        : getOptions(e.task_id).then(setCurrentTarget),
    clickName,
    selectedRowKey: selectedRowKeys[0],
  })

  const timingTaskLogRef = useRef<HTMLDivElement>(null)
  const [timingTaskLogLoading, setTimingTaskLogLoading] = useState(true)
  const [timingTaskLog, setTimingTaskLog] = useState<scheduledTaskLog[]>([])
  const [timingTaskLogTotal, setTimingTaskLogTotal] = useState(0)
  const nowPageNo = useRef(1)

  const loadMoreTaskLog = useMemoizedFn(async () => {
    setTimingTaskLogLoading(true)
    const res = await getScheduledTaskLogList(
      nowTimingTaskId,
      data?.flowId || '',
      nowPageNo.current,
    )
    if (nowPageNo.current === 1) {
      setTimingTaskLog(res.result_log)
    } else {
      setTimingTaskLog(prev => [...prev, ...res.result_log])
    }
    setTimingTaskLogTotal(res.total)
    setTimingTaskLogLoading(false)
    nowPageNo.current += 1
  })

  useEffect(() => {
    if (!nowTimingTaskId) return
    nowPageNo.current = 1
    loadMoreTaskLog()
  }, [nowTimingTaskId])

  useEffect(() => {
    getList().then(e => e?.[0] && clickName(e?.[0]))
  }, [])

  useEffect(() => {
    type === 1 &&
      form.getFieldValue('min') < 10 &&
      form.setFieldsValue({ min: '10' })
  }, [type])

  useEffect(() => {
    if (currentTarget?.task_id) {
      editTaskForm.setFieldsValue(currentTarget?.run_param || {})
      form.setFieldsValue({
        min: getTimeFormValue(currentTarget.config?.min) || formInitValues.min,
        hour:
          getTimeFormValue(currentTarget.config?.hour) || formInitValues.hour,
        week:
          getTimeFormValue(currentTarget.config?.week)?.split(',') ||
          formInitValues.week,
        day: getTimeFormValue(currentTarget.config?.day) || formInitValues.day,
        type: currentTarget?.type || formInitValues.type,
        cron: currentTarget.type === 6 ? currentTarget?.cron : '',
        name: currentTarget?.name,
      })
    } else {
      editTaskForm.resetFields()
      form.resetFields()
    }
  }, [currentTarget])

  const { scrollRef: tabScrollRef } = useScrollBar()
  const { scrollRef: detailScrollRef } = useScrollBar()
  const { scrollRef: editPanelScrollRef } = useScrollBar()

  return (
    <div className='h-full flex flex-justify-between adapt:gap-20 flex-1'>
      <div className='adapt:px-20 adapt:py-24 overflow-hidden flex flex-col flex-3 at-sm:flex-4 lt-sm:flex-4 bg-#fff border-rd-12px'>
        <div className='flex flex-justify-between flex-items-center adapt:mb-20 adapt:gap-20'>
          <Input
            prefix={<IconFont name='search' className='text-16px' />}
            className='flex-1 max-w-280px'
            placeholder='搜索任务名称'
            value={name}
            size='middle'
            onKeyDown={e => e.keyCode === 13 && getList()}
            onChange={e => setName(e.target.value)}
          />
          <Button
            size='middle'
            className='shadow-none flex flex-items-center'
            type='primary'
            onClick={() => setCurrentTarget({})}
            icon={<IconFont name='add' className='text-16px' />}
            loading={
              getListLoading ||
              deleteLoading ||
              changeStateLoading ||
              getOptionsLoading ||
              getDetailLoading
            }
          >
            新建任务
          </Button>
        </div>
        <div ref={tabScrollRef} className='flex-1 overflow-y-scroll'>
          <TableWrapper
            selectRowKey={selectedRowKeys[0]}
            loading={
              getListLoading ||
              deleteLoading ||
              changeStateLoading ||
              getDetailLoading
            }
            pagination={false}
            dataSource={
              list?.map((item, index) => ({ ...item, key: index })) || []
            }
            columns={columns as any}
          />
        </div>
      </div>
      <div className='flex-4 h-100% border-rd-12px px-20 py-24px bg-#fff'>
        {!getDetailLoading &&
        (Number(timingTaskLog?.length) > 0 ||
          (detail?.next_execution_time &&
            detail?.state === state_enum.RUNNING)) ? (
          <>
            <div className='text-16px font-500 adapt:mb-20 flex flex-justify-between flex-items-center'>
              <div className='truncate max-w-350px'>{detail?.task_name}</div>
              <DivWarpper status={detail?.state || state_enum.STOP}>
                {detail?.run_rule}
              </DivWarpper>
            </div>
            <div
              ref={moreRef(detailScrollRef, timingTaskLogRef)}
              className='overflow-y-scroll h-[calc(100%_-_30px)]'
            >
              <InfiniteScroll
                loading={timingTaskLogLoading}
                targetRoot={timingTaskLogRef}
                hasMore={timingTaskLogTotal > timingTaskLog.length}
                loader={
                  <div className='w-100% h-28px flex items-center flex-center'>
                    <Spin />
                  </div>
                }
                load={loadMoreTaskLog}
              >
                {detail?.next_execution_time &&
                  detail?.state === state_enum.RUNNING && (
                    <RenderItem
                      key='next_execution_time'
                      outputNodeKey={outputNodeKey}
                      run_status={log_state_enum.TO_BE_RUN}
                      finish_time={detail?.next_execution_time}
                      onRunProps={setCurrent}
                    />
                  )}

                {(timingTaskLog || []).map((item, index) => (
                  <RenderItem
                    key={index}
                    outputNodeKey={outputNodeKey}
                    {...item}
                    onRunProps={setCurrent}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </>
        ) : (
          <div className=' c-bg_3 c-op-40 h-[calc(100%_-_36px)] flex flex-justify-center flex-items-center flex-col text-14px'>
            <img src={empty} className='h-124px' />
            惊喜即将发生
          </div>
        )}
      </div>
      <DrawerWawrpper
        title='运行参数'
        width={640}
        placement='right'
        open={!!current}
        onClose={() => setCurrent(undefined)}
        closeIcon={<IconFont name='guanbi' className='text-16px' />}
      >
        <div className='flex flex-col gap-40px'>
          {Object.keys(detail?.run_param || {})?.map((item, index) => (
            <div key={index}>
              <div className='m-b-16px font-500'>{item}</div>
              <div className='c-font_1'>
                {typeof detail?.run_param?.[item] === 'string'
                  ? detail?.run_param?.[item]
                  : JSON.stringify(detail?.run_param?.[item])}
              </div>
            </div>
          ))}
        </div>
      </DrawerWawrpper>
      <DrawerWawrpper
        width={640}
        placement='right'
        open={!!currentTarget}
        title={currentTarget?.id ? '编辑任务' : '新建任务'}
        closeIcon={<IconFont name='guanbi' className='text-16px' />}
        bodyStyle={{ padding: 0 }}
        onClose={() => setCurrentTarget(undefined)}
      >
        <div className='h-full flex flex-col flex-justify-between'>
          <div ref={editPanelScrollRef} className='overflow-y-scroll flex-1'>
            <div className='flex flex-col p-24 gap-24px'>
              <div className='font-500 text-16px'>任务设置</div>
              <div>
                <Form
                  layout='vertical'
                  form={form}
                  initialValues={formInitValues}
                >
                  <RequiredFormItem
                    name='name'
                    label='任务名称'
                    rules={[{ required: true, message: '请输入任务名称' }]}
                  >
                    <Input placeholder='请输入任务名称' />
                  </RequiredFormItem>
                  <div className='text-14px color-#17171D font-500 m-b-20px'>
                    运行频率
                  </div>
                  <TimeSelect form={form} />
                </Form>
              </div>
              <div className='w-100% bg-#ededef h-1px'></div>
              <div className='font-500 text-16px'>运行参数</div>
              <NodeForm
                schema={transformConfig2SchemaConfig(
                  firstNodeConfig?.form_config || [],
                )}
                form={editTaskForm}
              />
            </div>
          </div>
          <div className='flex flex-justify-end gap-12 w-full py-12px pr-12 border-t-1px border-t-solid border-t-color-#ededef'>
            <Button
              style={{ height: 36 }}
              loading={saveOrEditLoading}
              onClick={() => setCurrentTarget(undefined)}
            >
              取消
            </Button>
            <Button
              style={{ height: 36 }}
              type='primary'
              loading={saveOrEditLoading}
              onClick={saveOrEdit}
            >
              保存
            </Button>
          </div>
        </div>
      </DrawerWawrpper>
    </div>
  )
}
