import { useEffect, useMemo, useRef, useState } from 'react'
import { Divider, Switch, Tooltip } from 'antd'
import classNames from 'classnames'
import styled from '@emotion/styled'
import { useDebounceFn, useFocusWithin, useHover } from 'ahooks'
import { isNil } from 'lodash-es'
import { LoadingOutlined } from '@ant-design/icons'
import { ParagraphExtraInfoStatus } from '@apis/datastore/model'
import type { DocumentType } from '@apis/datastore/model'

import { MAX_KEYWORDS_SIZE } from '@/features/datastore/constant'

import { CommonParagraphItemWrap } from '../ParagraphEditorStyle'
import DatastoreBaseEditor, {
  createDataBaseEditorFeatures,
  DatastoreBaseEditorFeatures,
} from '../../../components/DatastoreBaseEditor'
import KeywordsEditor from '@/pages/datastores/components/KeywordsEditor'
import { IconFont } from '@/components'
import { Track } from '@/features/track'

import ParagraphPreview from './components/ParagraphPreview'
import { Tips } from './components/Tips'
import {
  OperationAction,
  OperationActionType,
} from './components/OperationAction'
import type { IRenderParams, ParagraphListItemProps } from './types'

const BgWrapper = styled.div`
  border-color: rgba(225, 225, 229, 0.6);
  border-radius: 8px;
  width: 100%;
  height: 100%;
  top: 1px;
  left: 0px;
  position: absolute;
  pointer-events: none;
  content: '';
  background: linear-gradient(94deg, #68caff 0%, #684aff 62%, #963aff 99%);
  border-width: 0px;
`

const GenerateByAI = styled.div`
  font-weight: 500;
  font-variation-settings: 'opsz' auto;
  background: linear-gradient(100deg, #2cb5ff 0%, #6b4eff 62%, #963aff 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  z-index: 1;
  font-size: 12px;
  line-height: 14px;
`

const Wrap = styled(CommonParagraphItemWrap)`
  position: relative;

  .header-opt:hover {
    .info-box,
    .delete-btn {
      opacity: 1;
      width: auto;
    }
    .keywords-view {
      display: block;
    }
    .num-text {
      display: none;
    }
  }

  .display-header-opt {
    .info-box,
    .delete-btn {
      opacity: 1;
      width: auto;
    }
    .keywords-view {
      display: block;
    }
    .num-text {
      display: none;
    }
  }
`

export default function NormalParagraphListItem(
  props: ParagraphListItemProps<Exclude<DocumentType, DocumentType.QA>>,
) {
  const {
    first,
    last,
    border,
    numText,
    paragraph,
    readonly,
    onEnableChange,
    onKeywordsChange,
    onDelete,
    onMerge,
    onAdd,
    handleGenerateAISummary,
    handleParagraphValidator,
    file_id,
    onEdit,
    onEditorLoad,
    onEditorDestroy,
    onListItemClick,
    onSelect,
    activeId,
    isEditing,
    onSplit,
  } = props
  const wrapperRef = useRef<HTMLDivElement | null>(null)
  const paragraphEditorRef = useRef<any>(null)
  const [textCount, setTextCount] = useState<undefined | number>(undefined)
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false)
  const [merging, setMerging] = useState(false)
  const [text, setText] = useState(paragraph.content)

  const [maxCount, setMaxCount] = useState<undefined | number>(undefined)
  const isFocusWithin = useFocusWithin(() => wrapperRef.current)
  const hovering = useHover(wrapperRef.current)

  const handleSelect = () => {
    onSelect?.((paragraph.chunk_id || (paragraph as any).originalChunkId) ?? 0)
  }

  const handleActionClick = (type: OperationActionType) => {
    switch (type) {
      case OperationActionType.ADD:
        onAdd?.()
        break
      case OperationActionType.DELETE:
        onDelete(paragraph.key, !isEditing)
        break
      case OperationActionType.AI:
        handleGenerateAISummary(paragraph?.chunk_id!)
        break
    }
  }

  const handleMerge = async () => {
    try {
      setMerging(true)
      await onMerge?.()
    } finally {
      setMerging(false)
    }
  }

  const handleSplit = (splitConfig: [string, string]) => {
    onSplit?.([
      {
        content: splitConfig[0],
        keywords: paragraph.keywords,
        extra_info: {
          ...paragraph.extra_info,
          enable_ai_support: false,
          ai_created: false,
        },
      },
      {
        content: splitConfig[1],
        extra_info: {
          ...paragraph.extra_info,
          enable_ai_support: false,
          ai_created: false,
        },
      },
    ])
  }

  const chunkReadOnly = useMemo(() => {
    return (
      paragraph.extra_info?.chunk_status === ParagraphExtraInfoStatus.Pending ||
      paragraph.extra_info?.chunk_status === ParagraphExtraInfoStatus.Process ||
      paragraph.extra_info?.chunk_status === ParagraphExtraInfoStatus.Error
    )
  }, [paragraph.extra_info?.chunk_status])

  const moreThanMaxSize = !!(textCount && maxCount) && textCount > maxCount
  const moreThanMaxKeywords =
    (paragraph?.keywords?.length || 0) > MAX_KEYWORDS_SIZE

  const actionList = useMemo(() => {
    let list = Object.values(OperationActionType)

    const filterConditions = {
      default: [OperationActionType.EDIT],
      notEditing: [OperationActionType.ADD, OperationActionType.EDIT],
      editing: [OperationActionType.AI, OperationActionType.EDIT],
    }

    if (!isEditing) {
      list = list.filter(item => !filterConditions.notEditing.includes(item))
    } else {
      list = list.filter(item => !filterConditions.editing.includes(item))
    }

    if (!paragraph?.extra_info?.enable_ai_support) {
      list = list.filter(item => item !== OperationActionType.AI)
    }

    return list
  }, [paragraph?.extra_info?.chunk_status, isEditing, first])

  const bgStyle = useMemo(() => {
    if (
      paragraph?.extra_info?.chunk_status ===
        ParagraphExtraInfoStatus.Pending ||
      paragraph?.extra_info?.chunk_status === ParagraphExtraInfoStatus.Process
    ) {
      return <BgWrapper />
    } else if (
      paragraph?.extra_info?.chunk_status === ParagraphExtraInfoStatus.Error ||
      moreThanMaxKeywords ||
      moreThanMaxSize
    ) {
      let color = '#FF5219'
      if (moreThanMaxKeywords && !moreThanMaxSize) {
        color = '#FE9700'
      }
      return (
        <div
          style={{
            outline: `1px solid ${color}`,
            outlineOffset: '0px -1px 0px -1px',
            width: '100%',
            height: '100%',
            position: 'absolute',
            borderRadius: '8px',
          }}
        ></div>
      )
    }
    return null
  }, [
    paragraph?.extra_info?.chunk_status,
    moreThanMaxKeywords,
    moreThanMaxSize,
  ])

  // render相关
  const reRender = (params: IRenderParams) => {
    setText(params.content)
  }

  const { run: debounceValidateUsedToken } = useDebounceFn(
    async () => {
      handleParagraphValidator(text, res => {
        setTextCount(res?.data?.token_consumption)
        setMaxCount(res?.data?.max_token)
      })
    },
    {
      wait: 200,
    },
  )

  const onEditorChange = (content: string) => {
    if (!paragraph.enable) {
      onEnableChange(true)
    }
    onEdit?.()
    setText(content)
    debounceValidateUsedToken()
  }

  useEffect(() => {
    if (onEditorLoad && paragraphEditorRef.current) {
      onEditorLoad({
        content: text,
        reRender,
        textCount,
        validator: () => debounceValidateUsedToken(),
      })
    }
  }, [
    onEditorLoad,
    text,
    textCount,
    debounceValidateUsedToken,
    paragraphEditorRef.current,
  ])

  useEffect(() => {
    if (activeId !== paragraph.chunk_id) return
    handleSelect()
  }, [activeId])

  useEffect(() => {
    return () => onEditorDestroy?.()
  }, [])

  const renderUsedToken = useMemo(() => {
    return (
      !isNil(textCount) &&
      isEditing && (
        <div className='flex items-center h-24px'>
          <div
            className={classNames(
              'c-font_1 text-[12px] font-normal flex items-center',
              {
                'c-error': moreThanMaxSize,
              },
            )}
          >
            <span>共</span>
            <span className='ml-[3px] flex items-center'>
              <span
                className={classNames({
                  'c-font_1': focus,
                  '!c-error': moreThanMaxSize,
                })}
              >
                {textCount}
              </span>
              <span className='mx-[3px]'> / </span>
              <span>{maxCount}</span>
            </span>
          </div>
          <Divider type='vertical' />
        </div>
      )
    )
  }, [textCount, isEditing])

  const renderTips = useMemo(() => {
    if (moreThanMaxKeywords || moreThanMaxSize) {
      let desc = '段落字数超长'
      if (moreThanMaxKeywords && !moreThanMaxSize) {
        desc = '关键词超过数量限制，将会自动保存前20个。'
      }
      return (
        <div className='rounded-[8px_8px_0_0] overflow-hidden relative top-[2px]'>
          <Tips
            state={
              moreThanMaxKeywords && !moreThanMaxSize
                ? ParagraphExtraInfoStatus.Warning
                : ParagraphExtraInfoStatus.Error
            }
            showBtn={false}
            text={desc}
          />
        </div>
      )
    } else {
      return (
        paragraph?.extra_info?.chunk_status && (
          <div className='rounded-[8px_8px_0_0] overflow-hidden relative top-[2px]'>
            <Tips
              state={paragraph?.extra_info?.chunk_status}
              showBtn={!isEditing && paragraph?.extra_info.enable_ai_support}
              onClick={() => {
                handleGenerateAISummary(paragraph.chunk_id!)
              }}
              text={
                paragraph?.extra_info?.chunk_status ===
                ParagraphExtraInfoStatus.Error
                  ? paragraph?.extra_info?.error_text
                  : 'AI增强处理中，段落不可编辑'
              }
            />
          </div>
        )
      )
    }
  }, [
    paragraph?.extra_info,
    paragraph.chunk_id,
    handleGenerateAISummary,
    isEditing,
    moreThanMaxSize,
    moreThanMaxKeywords,
  ])

  const features = useMemo(() => {
    return createDataBaseEditorFeatures([
      DatastoreBaseEditorFeatures.Split,
      DatastoreBaseEditorFeatures.Upload,
      DatastoreBaseEditorFeatures.Highlight,
    ])
  }, [])

  const uploadOpt = useMemo(() => {
    return {
      uploadDir: 'datastore_normal',
      file_id,
      className: 'bg-[rgba(246,246,249,0.9)] rounded-8px',
    }
  }, [])
  return (
    <Wrap
      disabled={!paragraph.enable}
      isEdited={paragraph.isEdited}
      onClick={() => {
        if (paragraph?.extra_info?.page_bboxes) {
          onListItemClick?.('highlight', paragraph?.extra_info?.page_bboxes)
        }
      }}
      className={classNames('relative rounded-[8px]', {
        'ml-48px': isEditing,
        'ml-24px ': !isEditing,
      })}
    >
      {bgStyle}
      {/* 是否编辑的mark标记 */}
      <div
        className={classNames(
          'edited-mark w-full h-full absolute left-1px top-0 overflow-hidden rounded-[8px] pointer-events-none z-10',
          {
            '!left-[-1px]': !!bgStyle,
          },
        )}
      />
      <div
        className={classNames('relative m-1px box-border header-opt', {
          'display-header-opt': isEditing,
        })}
      >
        {renderTips}
        <div
          ref={wrapperRef}
          className={classNames('pt-16 pb-20 bg-white border-1', {
            'border-0!': !border && !isEditing,
            border,
            '!b-primary':
              !readonly &&
              props.isEditing &&
              isFocusWithin &&
              !moreThanMaxSize &&
              !moreThanMaxKeywords &&
              paragraph?.extra_info?.chunk_status !==
                ParagraphExtraInfoStatus.Error,
            'b-white': readonly || chunkReadOnly || isEditing,
            'hover:b-primary':
              !readonly &&
              props.isEditing &&
              !moreThanMaxSize &&
              !moreThanMaxKeywords &&
              paragraph?.extra_info?.chunk_status !==
                ParagraphExtraInfoStatus.Error,
            '!b-error': deleteConfirmOpen,
            'rounded-[8px]':
              paragraph.extra_info?.chunk_status !==
                ParagraphExtraInfoStatus.Error &&
              paragraph.extra_info?.chunk_status !==
                ParagraphExtraInfoStatus.Process &&
              paragraph.extra_info?.chunk_status !==
                ParagraphExtraInfoStatus.Pending,
            'rounded-[0_0_8px_8px]':
              paragraph.extra_info?.chunk_status ===
                ParagraphExtraInfoStatus.Error ||
              paragraph.extra_info?.chunk_status ===
                ParagraphExtraInfoStatus.Process ||
              paragraph.extra_info?.chunk_status ===
                ParagraphExtraInfoStatus.Pending,
            'paragraph-blink': activeId === paragraph.chunk_id && !isEditing,
          })}
        >
          <div className='flex-center-between px-20 gap-8 flex-1 w-full items-start'>
            <div className='w-full overflow-hidden'>
              <span
                className={classNames(
                  'num-text text-[12px] !line-height-26px c-font_1 font-normal py-1 h-[26px]',
                  {
                    hidden: hovering,
                  },
                )}
              >
                {numText}
              </span>

              <div
                className={classNames(
                  `${hovering ? 'block' : 'hidden'}`,
                  'keywords-view',
                )}
              >
                <KeywordsEditor
                  layout='horizontal'
                  value={paragraph.keywords || []}
                  readOnly={!isEditing}
                  onChange={onKeywordsChange}
                  maxKeywordsCount={MAX_KEYWORDS_SIZE}
                  placeholder={
                    isEditing && !!paragraph.keywords?.length
                      ? '输入关键词，使用回车、逗号填加'
                      : '【保存】后，AI将自动生成关键词。也可手动输入，使用逗号、回车添加。'
                  }
                />
              </div>
            </div>
            {renderUsedToken}
            <div className='flex items-center shrink-0'>
              <div
                className={classNames('flex items-center info-box ', {
                  'op-0': !hovering,
                })}
              >
                {!paragraph.enable && !chunkReadOnly && (
                  <div className='mr-12 py-2 text-12px px-8 rounded-4px c-#FE9700 bg-#FE9700 bg-op-12'>
                    <span className='w-8px h-8px rounded-full mr-6 inline-block bg-#FE9700'></span>
                    <span>已禁用</span>
                  </div>
                )}
                {!readonly && !chunkReadOnly && (
                  <>
                    <Switch
                      size='small'
                      checked={paragraph.enable}
                      onChange={onEnableChange}
                    />
                  </>
                )}
              </div>
              {!readonly &&
                paragraph.extra_info?.chunk_status !==
                  ParagraphExtraInfoStatus.Pending &&
                paragraph.extra_info?.chunk_status !==
                  ParagraphExtraInfoStatus.Process && (
                  <span
                    className={classNames('flex items-center delete-btn', {
                      'op-0': !deleteConfirmOpen,
                      'w-0': !deleteConfirmOpen,
                    })}
                  >
                    <Divider type='vertical' className='mx-12' />
                    {isEditing && (
                      <div
                        className='mr-8px cursor-pointer w-24px h-24px flex-center hover:bg-[rgba(98,105,153,0.08)] rounded-4px text-16px/16px'
                        onClick={() => {
                          paragraphEditorRef.current?.triggerUpload?.()
                        }}
                      >
                        <Tooltip title='图片/视频上传'>
                          <IconFont
                            name='shangzhuantupian'
                            className='c-[rgba(154,155,181)]'
                          />
                        </Tooltip>
                      </div>
                    )}
                    <OperationAction
                      actionList={actionList as any}
                      onActionClick={handleActionClick}
                      deleteConfirmOpen={deleteConfirmOpen}
                      setDeleteConfirmOpen={setDeleteConfirmOpen}
                    />
                  </span>
                )}
            </div>
          </div>
          {/* 渲染相关逻辑 */}
          <>
            <div
              className={classNames('px-20px py-10px', {
                hidden: !isEditing,
              })}
            >
              <DatastoreBaseEditor
                features={features}
                uploadOptions={uploadOpt}
                onSplit={handleSplit}
                ref={paragraphEditorRef}
                value={text}
                enable={paragraph?.enable}
                onChange={onEditorChange}
              />
            </div>
            <div
              className={classNames({
                hidden: isEditing,
              })}
            >
              <ParagraphPreview
                className='mt-12 px-20px'
                value={paragraph.content}
                enable={!paragraph?.enable}
              />
              {paragraph?.extra_info?.ai_created && (
                <div className='absolute right-24px bottom-14px flex items-center'>
                  <GenerateByAI>AI生成</GenerateByAI>
                </div>
              )}
            </div>
          </>
        </div>
      </div>
      {isEditing && (
        <div className='absolute left-[-24px]'>
          <>
            {!last && isEditing && (
              <Tooltip title='合并分段' placement='left'>
                <Track event='datastore_doc_merge'>
                  <span
                    className={classNames(
                      'w-20px h-20px hover:bg-primary flex-center rounded-4px cursor-pointer hover:c-white c-bg_3 c-op-48',
                      {
                        'op-0 pointer-events-none': readonly,
                      },
                    )}
                  >
                    {merging ? (
                      <LoadingOutlined />
                    ) : (
                      <IconFont name='merge' onClick={handleMerge} />
                    )}
                  </span>
                </Track>
              </Tooltip>
            )}
          </>
        </div>
      )}
    </Wrap>
  )
}
