import { Radio } from 'antd'
import styled from '@emotion/styled'
import { OutputTypes } from '@apis/datastore/model'

interface MemoryOutputTypeSelectProps {
  value?: string
  onChange?: (value: string) => void
}

const StyledRadio = styled(Radio)`
  margin-right: 8px;
  .ant-radio-inner {
    width: 14px;
    height: 14px;
  }
  &::after {
    content: '';
  }
`

const options: Array<{
  label: string
  value: string
  tips: string
}> = [
  {
    label: '文本格式',
    value: OutputTypes.TEXT,
    tips: '输出给模型分析：消耗小',
  },
  {
    label: 'JSON',
    value: OutputTypes.JSON,
    tips: '输出给模型分析：分析准确',
  },
]
export function MemoryOutputTypeSelect(props: MemoryOutputTypeSelectProps) {
  const { value, onChange } = props

  return (
    <div className='flex gap-24px'>
      {options.map(option => {
        return (
          <div
            key={option.value}
            className='flex items-center text-12px line-height-16px font-400 mt--8px'
            onClick={() => {
              onChange?.(option.value)
            }}
          >
            <StyledRadio checked={value === option.value} />
            <span>{option.label}</span>
          </div>
        )
      })}
    </div>
  )
}
