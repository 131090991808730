import { omit } from 'lodash-es'
import type { EventSourceDefined } from '@bty/http-client'
import { dbRequest, request } from '../../client'
import type {
  CreateOrUpdateRequest,
  CreateTableRequest,
  Database,
  TableFieldUpdateBulkRequest,
  UpdateTableInfoRequest,
  Schema,
} from './type'
import type { GridColumnType, TableType } from './smartsheet'

const prefix = '/api/v1'

export function getDBList(): Promise<{ list: Database[] }> {
  return dbRequest.get(`${prefix}/db/meta/projects`)
}

export function bulkGetDBAppIds(ids: string[]) {
  return request.post<
    Array<{
      database: string
      applications: string[]
    }>
  >('/v1/agent/database/applications', {
    database_ids: ids,
  })
}

export function bulkGetDBTableCount(ids: string[]) {
  return dbRequest.post<Record<string, number>>(
    `${prefix}/db/meta/projects/tables/count`,
    ids,
  )
}

export function getSchemas(
  projectId: string,
): Promise<{ table_list: Schema[] }> {
  return dbRequest.get(`/api/v2/meta/bases/detail/${projectId}`)
}

export function createDatabase(req: CreateOrUpdateRequest) {
  return dbRequest.post(`${prefix}/db/meta/projects`, req)
}

export function updateDatabase(id: string, data: CreateOrUpdateRequest) {
  return dbRequest.patch(`${prefix}/db/meta/projects/${id}`, data)
}

export function getDataBaseInfo(id: string): Promise<Database> {
  return dbRequest.get(`${prefix}/db/meta/projects/${id}`)
}

export function deleteDatabase(id: string) {
  return dbRequest.delete(`${prefix}/db/meta/projects/${id}`)
}

export function getTables(id: string): Promise<{ list: TableType[] }> {
  return dbRequest.get(`${prefix}/db/meta/projects/${id}/tables`)
}

export function createTable(
  databaseId: string,
  sourceId: string,
  params: CreateTableRequest,
) {
  return dbRequest.post<TableType>(
    `${prefix}/db/meta/projects/${databaseId}/${sourceId}/tables`,
    params,
  )
}

export function setColumnToPrimary(column_id: string) {
  return dbRequest.post(`${prefix}/db/meta/columns/${column_id}/primary`)
}

export function importDataToTable(id: string, base_id: string, data: any[]) {
  return dbRequest.post(`${prefix}/db/data/bulk/noco/${base_id}/${id}`, data, {
    ignoreError: true,
  })
}

export function updateTableInfo(req: UpdateTableInfoRequest) {
  return dbRequest.patch(
    `${prefix}/db/meta/tables/${req.table_id}`,
    omit(req, 'table_id'),
  )
}

export function deleteTable(id: string) {
  return dbRequest.delete(`${prefix}/db/meta/tables/${id}`)
}

export function copyTable(
  table_name: string,
  base_id: string,
  table_id: string,
  excludeData: boolean,
) {
  return dbRequest.post<{ id: string }>(
    `${prefix}/db/meta/duplicate/${base_id}/table/${table_id}`,
    {
      title: table_name,
      options: {
        excludeHooks: false,
        excludeViews: false,
        excludeData,
      },
    },
  )
}

export function getTableDetail(id: string): Promise<TableType> {
  return dbRequest.get(`${prefix}/db/meta/tables/${id}`)
}

export function getTableColumns(viewId: string): Promise<GridColumnType[]> {
  return dbRequest
    .get(`${prefix}/db/meta/views/${viewId}/columns`)
    .then(response => response?.list ?? [])
}

export function getTableColumnsHash(id: string) {
  return dbRequest.get<{ hash: string }>(
    `${prefix}/db/meta/tables/${id}/columns/hash`,
  )
}

export function tableFieldUpdateBulk(
  id: string,
  req: TableFieldUpdateBulkRequest,
) {
  return dbRequest.post(`${prefix}/db/meta/tables/edit/${id}`, req)
}

export function generalTableDesc(
  table_name: string,
  config: EventSourceDefined<{
    column_description: string
    finishReason: string
  }>,
) {
  request.sse(
    '/v1/agent/database/gen_table_desc',
    {
      table_name,
    },
    config,
  )
}

export function generalTableColumnDesc(
  column: {
    column_name: string
    column_type: string
    is_required: boolean
  },
  config: EventSourceDefined<{
    column_description: string
    finishReason: string
  }>,
) {
  request.sse('/v1/agent/database/gen_column_desc', column, config)
}

export function generalTableAndColumnDesc(
  table_name: string,
  columns: Array<{
    column_name: string
    column_type: string
    is_required: boolean
  }>,
) {
  return request.post<{
    table_name: string
    table_description: string
    columns: {
      column_description: string
      column_name: string
    }[]
  }>('/v1/agent/database/table/gen_desc', {
    table_name,
    columns,
  })
}

export function tableReorder(id: string, order: number) {
  return dbRequest.post(`${prefix}/db/meta/tables/${id}/reorder`, { order })
}
