import { request } from '../../client'
import type { VersionConfig, VersionInfo } from './type'

export function getVersionList() {
  return request.get<VersionConfig[]>('/v1/product_package/query')
}

export function getVersionInfoByWorkspaceId(workspaceId: string) {
  return request.get<VersionInfo>(`/v1/product_service/${workspaceId}`, {
    ignoreError: true,
    noRedirect: true,
  })
}
