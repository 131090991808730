import { useMemo, useState } from 'react'
import { omit } from 'lodash-es'
import type { TableColumnsType } from 'antd'
import type { Dayjs } from 'dayjs'
import dayjs from 'dayjs'
import type {
  AppMonitorOverviewResponse,
  AppMonitorType,
  AppUsageItem,
} from '@apis/monitor/type'
import { StaticTimeTypes } from '@apis/monitor/type'
import { getAppMonitorUsageDetail } from '@apis/monitor'
import { useAppMonitorOverview } from '../hooks/useAppMonitorOverview'
import type { MonitorDrawerProps } from '@/components/MonitorDrawer'
import MonitorDrawer from '@/components/MonitorDrawer'
import type { GeneralTimeParams } from '../type'
import { useMonitorDetailReq } from '../hooks/useMonitorDetailReq'
import { AnalyzeTimeFilter } from './AnalyzeTimeFilter'
import { AnalyzeCard } from './AnalyzeCard'

function transformOverviewData(
  runKey: string,
  data?: AppMonitorOverviewResponse,
) {
  if (!data) {
    return [{ title: '消耗积分' }, { title: runKey }, { title: '使用者(人)' }]
  }
  const {
    run_count,
    run_count_change,
    unique_users,
    unique_users_change,
    consumed_points,
    consumed_points_change,
  } = data
  return [
    {
      title: '消耗积分',
      count: consumed_points,
      change: consumed_points_change,
    },
    {
      title: runKey,
      count: run_count,
      change: run_count_change,
    },
    {
      title: '使用者(人)',
      count: unique_users,
      change: unique_users_change,
    },
  ]
}

function getUsageDetailColumns(options: {
  flowType: AppMonitorType
  onLogJump: ((date: string) => void) | false
}) {
  const { flowType, onLogJump } = options
  const columns: TableColumnsType<AppUsageItem> = [
    {
      title: '时间',
      dataIndex: 'record_date',
      width: '170px',
      key: 'record_date',
      sorter: true,
      render: (_, record) => {
        return dayjs(record.record_date).format('YYYY-MM-DD')
      },
    },
    {
      title: '消耗积分',
      dataIndex: 'consumed_points',
      key: 'consumed_points',
      sorter: true,
    },
    {
      title: flowType === 'AGENT' ? '生成消息(条)' : '运行次数',
      dataIndex: 'run_count',
      key: 'run_count',
      sorter: true,
    },
    {
      title: '使用者(人)',
      dataIndex: 'unique_users',
      key: 'unique_users',
      sorter: true,
    },
    ...(onLogJump
      ? [
          {
            title: '操作',
            key: 'action',
            render: (_: unknown, record: AppUsageItem) => {
              return (
                <div
                  className='c-#7b61ff cursor-pointer'
                  onClick={e => {
                    e.stopPropagation()
                    onLogJump(record.record_date)
                  }}
                >
                  查看日志
                </div>
              )
            },
          },
        ]
      : []),
  ]
  return columns
}

interface MonitorOverviewPaneProps {
  flowId?: string
  flowType: AppMonitorType
  runType: string[]
  onLogJump: ((date: string) => void) | false
}

export function MonitorOverviewPane(props: MonitorOverviewPaneProps) {
  const { flowId, flowType, runType, onLogJump } = props

  const [usageModalOpen, setUsageModalOpen] = useState(false)
  const [filter, setFilter] = useState<MonitorDrawerProps['filter']>()
  const [overViewTimeParams, setOverviewTimeParams] =
    useState<GeneralTimeParams>({
      type: StaticTimeTypes.YEARLY,
    })
  const [dates, setDates] = useState<[Dayjs | null, Dayjs | null]>([null, null])

  const runKey = useMemo(
    () => (flowType === 'AGENT' ? '生成消息(条)' : '运行次数'),
    [flowType],
  )

  const { dailyData, weeklyData, dynamicData } = useAppMonitorOverview({
    flowId,
    flowType,
    runType,
    timeType: overViewTimeParams.type,
    ...omit(overViewTimeParams, 'type'),
  })

  const baseParams = useMemo(() => {
    return {
      flow_id: flowId,
      flow_type: flowType,
      run_type: runType,
      page: 1,
      page_size: 10,
      start_date: overViewTimeParams.startDate,
      end_date: overViewTimeParams.endDate,
    }
  }, [flowId, flowType, runType, overViewTimeParams])

  const { fetchList, ...rest } = useMonitorDetailReq({
    api: getAppMonitorUsageDetail,
    defaultParams: baseParams,
    outerTimeType: filter
      ? (filter.defaultValue as StaticTimeTypes)
      : overViewTimeParams.type,
    open: usageModalOpen,
    startDateKey: 'start_date',
    endDateKey: 'end_date',
  })

  const onUsageOpen = async (type: StaticTimeTypes) => {
    await fetchList({
      ...baseParams,
      statistic_time:
        type === StaticTimeTypes.DAILY ? StaticTimeTypes.CUSTOM : type,
      start_date:
        type === StaticTimeTypes.DAILY
          ? dayjs().startOf('day').toISOString()
          : overViewTimeParams.startDate,
      end_date:
        type === StaticTimeTypes.DAILY
          ? dayjs().endOf('day').toISOString()
          : overViewTimeParams.endDate,
    })
    setUsageModalOpen(true)
  }

  return (
    <>
      <div className='w-full overflow-auto flex items-center gap-24px flex-1'>
        <AnalyzeCard
          className='flex-1'
          title='今日用量'
          data={transformOverviewData(runKey, dailyData)}
          onDetailEnter={async () => {
            setFilter({
              defaultValue: StaticTimeTypes.CUSTOM,
              defaultSubValue: [dayjs().startOf('day'), dayjs().endOf('day')],
            })
            onUsageOpen(StaticTimeTypes.DAILY)
          }}
        />
        <AnalyzeCard
          className='flex-1'
          title='本周用量'
          data={transformOverviewData(runKey, weeklyData)}
          onDetailEnter={async () => {
            setFilter({
              defaultValue: StaticTimeTypes.WEEKLY,
            })
            onUsageOpen(StaticTimeTypes.WEEKLY)
          }}
        />
        <AnalyzeCard
          className='flex-1'
          title={
            <AnalyzeTimeFilter
              onTypeChange={data => {
                setOverviewTimeParams(data)
                setDates([dayjs(data.startDate), dayjs(data.endDate)])
              }}
            />
          }
          data={transformOverviewData(runKey, dynamicData)}
          onDetailEnter={async () => {
            setFilter({
              defaultValue: [
                StaticTimeTypes.DAILY,
                StaticTimeTypes.CUMULATIVE,
              ].includes(overViewTimeParams.type)
                ? StaticTimeTypes.YEARLY
                : overViewTimeParams.type,
              defaultSubValue: dates,
            })
            onUsageOpen(overViewTimeParams.type)
          }}
        />
      </div>

      {usageModalOpen && (
        <MonitorDrawer
          title='用量明细'
          columns={getUsageDetailColumns({
            flowType,
            onLogJump,
          })}
          {...rest}
          filter={filter}
          onClose={() => {
            setUsageModalOpen(false)
          }}
        />
      )}
    </>
  )
}
