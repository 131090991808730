import { request } from '../../client'
import type { dislikeParams, ReportIssueParams } from './type'

export function reportIssue(body: ReportIssueParams) {
  return request.post('/v1/report', body)
}

// 这里的 recordIssue 其实同 chatAPI.Feedback.doRecordFeedbackDislike
export function dislike(body: dislikeParams) {
  return request.post('/v1/chat/feedback/dislike', body)
}
