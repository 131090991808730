import { memo } from 'react'
import type { StyledComponent } from '@emotion/styled'
import { Input, InputNumber, Segmented, Select } from '@/components'
import { FormSwitch, Steps } from '..'
import type { Variable } from '@/features/editor'
import { CodeEditor } from '@/features/editor'
import { CodeBlock } from '@/components/CodeBlock'
import { DatasetSelect } from '@/features/nodes/components/DatasetSelect'
import { TextEditor } from '@/features/editor/TextEditor'

export interface RegisteredComponent<T> {
  component: React.ComponentType<T> | StyledComponent<any, any, any>
  defaultProps?: Partial<T>
}

type RegisteredComponentValue<T extends (...args: any[]) => any> =
  RegisteredComponent<Parameters<T>[0]>

export type BaseConfig =
  | SegmentedConfig
  | InputConfig
  | CodeEditorConfig
  | InputNumberConfig
  | SelectConfig
  | CustomComponentConfig
  | StepsConfig
  | TextEditorConfig
  | SwitchConfig
  | DatasetSelectConfig
  | CodeBlockConfig

interface SegmentedConfig {
  type: 'Segmented'
  componentProps?: RegisteredComponentsType['Segmented']['defaultProps']
}

interface InputConfig {
  type: 'Input'
  componentProps?: RegisteredComponentsType['Input']['defaultProps']
}

interface CodeEditorConfig {
  type: 'CodeEditor'
  componentProps?: RegisteredComponentsType['CodeEditor']['defaultProps']
}

interface TextEditorConfig {
  type: 'TextEditor'
  componentProps?: RegisteredComponentsType['TextEditor']['defaultProps']
}

interface InputNumberConfig {
  type: 'InputNumber'
  componentProps?: RegisteredComponentsType['InputNumber']['defaultProps']
}

interface SelectConfig {
  type: 'Select'
  componentProps?: RegisteredComponentsType['Select']['defaultProps']
}

interface StepsConfig {
  type: 'Steps'
  componentProps?: RegisteredComponentsType['Steps']['defaultProps']
}

interface SwitchConfig {
  type: 'Switch'
  componentProps?: RegisteredComponentsType['Switch']['defaultProps']
}

interface DatasetSelectConfig {
  type: 'DatasetSelect'
  componentProps?: RegisteredComponentsType['DatasetSelect']['defaultProps']
}

interface CustomComponentConfig {
  render: (props?: any) => React.ReactNode
  componentProps?: Record<string, any>
}

interface CodeBlockConfig {
  type: 'CodeBlock'
  componentProps?: RegisteredComponentsType['CodeBlock']['defaultProps']
}

export interface RegisteredComponentsType {
  Segmented: RegisteredComponentValue<typeof Segmented>
  Input: RegisteredComponentValue<typeof Input>
  CodeEditor: RegisteredComponentValue<typeof CodeEditor>
  InputNumber: RegisteredComponentValue<typeof InputNumber>
  Select: RegisteredComponentValue<typeof Select>
  Steps: RegisteredComponentValue<typeof Steps>
  TextEditor: RegisteredComponentValue<typeof TextEditor>
  Switch: RegisteredComponentValue<typeof FormSwitch>
  DatasetSelect: RegisteredComponentValue<typeof DatasetSelect>
  CodeBlock: RegisteredComponentValue<typeof CodeBlock>
}

const systemVariables: Variable[] = [
  {
    label: 'betterAI',
    type: 'BetterAI',
    hideQuickSelect: true,
    property: [
      {
        label: 'session',
        type: 'BetterAISession',
        property: [
          {
            label: 'agentId',
            type: 'string',
          },
          {
            label: 'conversationId',
            type: 'string',
          },
          {
            label: 'sessionId',
            type: 'string',
          },
          {
            label: 'metaData',
            type: 'any',
          },
          {
            label: 'setMetaData',
            type: 'function',
          },
          {
            label: 'addChatRecord',
            type: 'function',
          },
          {
            label: 'getChatRecord',
            type: 'function',
          },
        ],
      },
    ],
  },
]

export const RegisteredComponents: RegisteredComponentsType = {
  Segmented: {
    component: memo(Segmented) as typeof Segmented,
    defaultProps: {
      size: 'small',
      block: true,
      options: [],
    },
  },
  Switch: {
    component: memo(FormSwitch),
  },
  Input: {
    component: memo(Input) as typeof Input,
    defaultProps: {
      size: 'small',
    },
  },
  CodeEditor: {
    component: memo(CodeEditor) as typeof CodeEditor,
    defaultProps: {
      width: '100%',
      theme: 'tomorrow',
      defaultVariables: systemVariables,
    },
  },
  CodeBlock: {
    component: memo(CodeBlock),
    defaultProps: {
      lang: 'javascript',
      str: '',
    },
  },
  TextEditor: {
    component: memo(TextEditor),
    defaultProps: {
      className: 'text-12px',
      minHeight: 64,
      maxHeight: 360,
      focusScroll: true,
    },
  },
  Select: {
    component: memo(Select),
    defaultProps: { size: 'small' },
  },
  InputNumber: {
    component: memo(InputNumber),
    defaultProps: { size: 'small' },
  },
  Steps: { component: memo(Steps) },
  DatasetSelect: { component: memo(DatasetSelect) },
}
