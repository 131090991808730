import type { FC } from 'react'
import classNames from 'classnames'
import { Radio } from 'antd'
import type { IEmbeddingModalItem } from '@apis/datastore/type'

interface DocumentTypeSwitchProps {
  value?: IEmbeddingModalItem['model_name']
  list: IEmbeddingModalItem[]
  onChange?: (value: string) => void
}
export const EmbeddingModelSwitch: FC<DocumentTypeSwitchProps> = props => {
  const { list, value } = props
  return (
    <div className='flex flex-1 justify-between'>
      {list.map((item, index) => {
        return (
          <div
            key={index}
            className={classNames(
              'flex p-16 bg-white border border-line border-opacity-80 hover:bg-bg_3 hover:bg-opacity-8 active:border-primary rounded-8px mt-4px cursor-pointer w-262px box-border',
              {
                '!b-primary': value === item.model_name,
              },
            )}
            onClick={() => {
              props.onChange?.(item.model_name)
            }}
          >
            <div className='flex justify-center items-start'>
              <img src={item.icon} className='w-32px h-32px' />
            </div>
            <div className='ml-12px flex-1'>
              <div className='flex items-center'>
                <div className='flex items-center flex-1'>
                  <p className='font-500 text-14px line-height-16px'>
                    {item.model_display_name}
                  </p>
                  {!!item.recommend && (
                    <p className='rounded-8px text-10px/14px ml-4px px-4px py-2px c-#FF8056 bg-#FFECE6'>
                      推荐
                    </p>
                  )}
                </div>
                <Radio checked={item.model_name === value} className='mr-0px' />
              </div>

              <p className='text-12px mt-8 text-font_1 line-height-1em multi-line-text'>
                {item.description}
              </p>
            </div>
          </div>
        )
      })}
    </div>
  )
}
