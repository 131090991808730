import { HTTPClient } from './client'
import type { HttpDefine, HttpInjectInfo } from './http/type'

export type RequestCallType = NonNullable<HttpInjectInfo['onRequest']>
export type ResponseCallType = NonNullable<HttpInjectInfo['onResponse']>
export type ErrorCallType = NonNullable<HttpInjectInfo['onError']>

export class HTTPClientFactory {
  RequestCall: RequestCallType[] = []

  ResponseCall: ResponseCallType[] = []

  ErrorCall: ErrorCallType[] = []

  removeRequestHandle(call: RequestCallType) {
    this.RequestCall.filter(e => e !== call)
  }

  addRequestHandle(call: RequestCallType) {
    this.RequestCall = [...this.RequestCall, call]

    return () => {
      this.removeRequestHandle(call)
    }
  }

  async handleRequest(define: HttpDefine) {
    let sureDefine = define
    for (const call of this.RequestCall) {
      sureDefine = await call(sureDefine)
    }
    return sureDefine
  }

  removeResponseHandle(call: ResponseCallType) {
    this.ResponseCall.filter(e => e !== call)
  }

  addResponseHandle(call: ResponseCallType) {
    this.ResponseCall = [...this.ResponseCall, call]

    return () => {
      this.removeResponseHandle(call)
    }
  }

  async handleResponse(response: Response, define: HttpDefine) {
    for (const call of this.ResponseCall) {
      await call(response, define)
    }
  }

  removeErrorHandle(call: ErrorCallType) {
    this.ErrorCall.filter(e => e !== call)
  }

  addErrorHandle(call: ErrorCallType) {
    this.ErrorCall = [...this.ErrorCall, call]

    return () => {
      this.removeErrorHandle(call)
    }
  }

  async handleError(error: Error, define: HttpDefine) {
    for (const call of this.ErrorCall) {
      call(error, define)
    }
  }

  create(base: HttpDefine = {}) {
    return new HTTPClient(base, {
      onRequest: req => this.handleRequest(req),
      onResponse: (res, define) => this.handleResponse(res, define),
      onError: (error, define) => this.handleError(error, define),
    })
  }
}
