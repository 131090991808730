import '../PromptAICreateModal/index.css'
import classNames from 'classnames'
import { memo } from 'react'
import { message, Tooltip } from 'antd'
import { useBoolean, useMemoizedFn } from 'ahooks'
import type { PromptStructItem } from '@apis/prompt/type'
import { IconFont, Modal } from '@/components'
import { Track } from '@/features/track'
import { OptimizationModal } from './OptimizationModal'

interface PromptOptimizationModalProps {
  className?: string
  onConfirm?: (struct: PromptStructItem[]) => void
  templateType?: 'Agent' | 'Flow'
  prompt: string
  disabled?: boolean
}

export const PromptOptimizationModal = memo(
  (props: PromptOptimizationModalProps) => {
    const { className, disabled, prompt } = props

    const [open, { setTrue, setFalse }] = useBoolean(false)
    const [loading, { set: setLoading }] = useBoolean(false)
    const [genTip, { setTrue: openTip, setFalse: closeTip }] = useBoolean(false)

    const handleOpen = useMemoizedFn(() => {
      if (disabled) {
        message.warning('未找到可分析的提示词，请填写后重试')
        return
      }
      closeTip()
      setTrue()
    })

    const handleClose = useMemoizedFn(() => {
      if (loading) {
        openTip()
      }
      setFalse()
    })

    return (
      <div className={classNames(className, 'relative')}>
        <Tooltip title='解读提示词，给出优化建议'>
          <span className='prompt-ai-text-bg rounded-6px h-24px inline-flex'>
            <Track event='agent_prompt_graph'>
              <span
                className='inline-flex flex-center cursor-pointer rounded-6px h-24px gap-4px px-8px hover:bg-bg_3/8'
                onClick={handleOpen}
              >
                {genTip && (
                  <div className='w-6px h-6px rd-full bg-error absolute top-0 right-0 z-2'></div>
                )}
                <IconFont name='auto' className='text-16px' />
              </span>
            </Track>
          </span>
        </Tooltip>
        <Modal
          maskClosable={false}
          open={open}
          title={null}
          width={1120}
          footer={null}
          styles={{ body: { padding: 0 } }}
          onCancel={handleClose}
        >
          {/* Header */}
          <div className='flex-center-between py-16 px-20 pr-16 b-b-1 b-line/60'>
            <span className='font-500 text-16px'>优化分析提示词</span>
            <span
              className='w-24px h-24px hover:bg-bg_3/8 flex-center cursor-pointer rd-4px'
              onClick={handleClose}
            >
              <IconFont name='guanbi' />
            </span>
          </div>
          <OptimizationModal setLoading={setLoading} prompt={prompt} />
        </Modal>
      </div>
    )
  },
)
