import type { Dispatch, MutableRefObject, SetStateAction } from 'react'
import {
  createContext,
  memo,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import type { FormInstance } from 'antd'
import { useMemoizedFn, useRequest } from 'ahooks'
import { getFlowExecuteLog } from '@apis/flow'
import type { LogItem } from '@apis/run/type'
import { StartNodeTypes, type FieldItem } from '@/features/nodes/start'
import { NodeForm } from '@/components'
import type { FlowOutput } from '../../types'
import { transformDataToValidLogs } from '../../utils'
import { logItem2FormValues } from '../../utils/logItem'
import type { FormattedOutput } from './type'
import { getOutput } from './components/util'
import { useSubscribe } from './hooks/useSubscribe'

export interface LoggerModalContextProps {
  flowId: string
  mode: 'log' | 'debug'
  startType: 'form' | 'webhook'
  startConfig: FieldItem[]
  form: ReturnType<typeof NodeForm.useForm>[0]
  webhookJson: string
  webhookJsonRef: MutableRefObject<string>
  setJson: (json: string) => void
  setTaskId: (taskId: string) => void

  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  flowLog?: LogItem
  setFlowLog: Dispatch<SetStateAction<LogItem | undefined>>
  output: FormattedOutput
  setOutput: Dispatch<SetStateAction<FormattedOutput>>
  logs: FlowOutput[]
  setLogs: Dispatch<SetStateAction<FlowOutput[]>>

  diff: boolean
  setDiff: Dispatch<SetStateAction<boolean>>
  correctResult: string
  setCorrectResult: Dispatch<SetStateAction<string>>
  remarks: string
  setRemarks: Dispatch<SetStateAction<string>>

  subscribeRun: (call: () => void) => void
  publishRun: () => void
  subscribeClear: (call: () => void) => void
  publishClear: () => void
}

const LoggerModalContext = createContext<LoggerModalContextProps>({
  flowId: '',
  mode: 'log',
  startType: 'form',
  startConfig: [],
  form: {} as FormInstance,
  webhookJson: '',
  webhookJsonRef: { current: '' },
  setJson: () => {},
  setTaskId: () => {},
  loading: false,
  setLoading: () => {},
  flowLog: undefined,
  setFlowLog: () => {},
  output: {},
  setOutput: () => {},
  logs: [],
  setLogs: () => {},

  diff: false,
  setDiff: () => {},
  correctResult: '',
  setCorrectResult: () => {},
  remarks: '',
  setRemarks: () => {},

  subscribeRun: () => {},
  publishRun: () => {},
  subscribeClear: () => {},
  publishClear: () => {},
})

export interface LoggerModalProviderProps {
  flowId: string
  taskId?: string
  mode?: 'log' | 'debug'
  startType?: 'form' | 'webhook'
  startConfig?: FieldItem[]
  onLog?: (logs: FlowOutput[]) => void
  onDiff?: (diff: boolean) => void
  children: React.ReactNode
}

export const LoggerModalProvider = memo((props: LoggerModalProviderProps) => {
  const {
    flowId,
    taskId: debugTaskId,
    mode = 'log',
    startType,
    startConfig,
    onLog,
    onDiff,
    children,
  } = props

  const setResult = useRef(mode === 'log')

  const [form] = NodeForm.useForm()
  const [webhookJson, setJson] = useState('')
  const webhookJsonRef = useRef('')
  const [taskId, setTaskId] = useState<string>(debugTaskId!)
  const [loading, setLoading] = useState(false)
  const [flowLog, setFlowLog] = useState<LogItem>()
  const [output, setOutput] = useState<FormattedOutput>({})
  const [logs, setLogs] = useState<FlowOutput[]>([])

  const [diff, setDiff] = useState(false)
  const [correctResult, setCorrectResult] = useState('')
  const [remarks, setRemarks] = useState('')

  const [subscribeRun, publishRun] = useSubscribe()
  const [subscribeClear, publishClear] = useSubscribe()

  const handleChangeJson = useMemoizedFn((newValue: string) => {
    webhookJsonRef.current = newValue
  })

  const handleChangeTaskId = useMemoizedFn((newTaskId: string) => {
    setResult.current = true
    setTaskId(newTaskId)
  })

  useRequest(
    () => {
      return getFlowExecuteLog({
        flowId: flowId!,
        taskId: taskId!,
      })
    },
    {
      ready: !!flowId && !!taskId,
      refreshDeps: [flowId, taskId],
      onSuccess: res => {
        const logs = transformDataToValidLogs(
          res?.run_result.actionOutputs || {},
          {
            isSingleStepRun: (res?.run_type as string) === 'STEP_RUN',
            taskId: res.task_id,
          },
        )
        setFlowLog(res)

        const inputValue = logItem2FormValues(res)

        if (res?.flow_node_type === StartNodeTypes.Webhook) {
          const text = JSON.stringify(inputValue, null, 2)
          setJson(text)
          webhookJsonRef.current = text
        } else {
          form.setFieldsValue(inputValue)
        }

        if (setResult.current) {
          setOutput(getOutput(res))
          setLogs(logs)
          onLog?.(logs)
        }
      },
    },
  )

  const handleRun = useMemoizedFn(() => {
    setResult.current = false
    setOutput({})
    setLogs([])
    onLog?.([])
  })

  const handleClear = useMemoizedFn(() => {
    setDiff(false)
    setCorrectResult('')
    setRemarks('')
  })

  useEffect(() => {
    return subscribeRun(handleRun)
  }, [])

  useEffect(() => {
    return subscribeClear(handleClear)
  }, [])

  useEffect(() => {
    onDiff?.(diff)
  }, [diff])

  useEffect(() => {
    if (!debugTaskId) return
    setTaskId(debugTaskId)
  }, [debugTaskId])

  const value = useMemo(() => {
    return {
      flowId,
      mode,
      startType: startType ?? flowLog?.flow_node_type ?? StartNodeTypes.Form,
      startConfig: startConfig ?? flowLog?.form_config ?? [],
      form,
      webhookJson,
      webhookJsonRef,
      setJson: handleChangeJson,
      setTaskId: handleChangeTaskId,
      loading,
      setLoading,
      flowLog,
      setFlowLog,
      output,
      setOutput,
      logs,
      setLogs,

      diff,
      setDiff,
      correctResult,
      setCorrectResult,
      remarks,
      setRemarks,

      subscribeRun,
      publishRun,
      subscribeClear,
      publishClear,
    }
  }, [
    flowId,
    mode,
    startType,
    startConfig,
    form,
    webhookJson,
    loading,
    flowLog,
    output,
    logs,
    diff,
    correctResult,
    remarks,
  ])

  return (
    <LoggerModalContext.Provider value={value}>
      {children}
    </LoggerModalContext.Provider>
  )
})

export function useLogger() {
  return useContext(LoggerModalContext)
}
