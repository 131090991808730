import styled from '@emotion/styled'
import { Affix } from 'antd'
import type { FC } from 'react'
import { useMemo, useState } from 'react'
import { rgba } from 'polished'
import classNames from 'classnames'
import type { TemplateApp } from '@apis/template/type'
import { Segmented, IconFont, Button } from '@/components'
import { useTemplateStore } from '@/store/template.ts'
import BannerBg from '@/assets/template/banner_bg.png'
import BannerFront from '@/assets/template/banner_front.png'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

const ColorfulTitle = styled.h4`
  font-size: 24px;
  font-weight: 900;
  background: linear-gradient(281deg, #00ddeb 0%, #5b42f3 47%, #af40ff 99%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  width: fit-content;
  height: 24px;
`
const TemplateAppCard = styled.div`
  .action {
    transition: all 0.15s ease-in-out;
  }
  &:hover {
    box-shadow: 0px 12px 20px 0px rgba(23, 23, 29, 0.12);
    .action {
      bottom: 18px;
    }
  }
`
const BannerBox = styled.div`
  @keyframes move {
    from {
      left: 0;
    }
    to {
      left: 120%;
    }
  }
  .scan-light-container {
    position: absolute;
    top: -200px; /* Initially place it below the container so it's hidden */
    left: 0; /* Offset the starting position */
    width: 320px; /* Ensure it covers the entire width */
    height: 600px; /* Thickness of the light beam */
    background: linear-gradient(
      90deg,
      #d8d8d8 0%,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.24) 51%,
      rgba(216, 216, 216, 0) 100%
    );
    transform: rotate(30deg);
    animation: move 1.5s ease-in-out forwards;
    filter: blur(20px);
  }
`

const StyledSegmented = styled(Segmented)`
  padding: 0;
  background: none;
  .ant-segmented-item {
    color: ${props => props.theme.colors.font};
    padding: 12px 16px;
    font-size: 16px;
    font-weight: 500;
    margin-right: 12px;
  }
  .ant-segmented-item-selected {
    color: ${props => props.theme.colors.primary};
  }
`

interface Props {
  className?: string
  onTemplateSetup: (app: TemplateApp) => void
}
const TemplatePage: FC<Props> = props => {
  const [type, setType] = useState<string>('all')
  const templateStore = useTemplateStore()

  const options: Array<{ label: string; value: string }> = useMemo(() => {
    return [
      {
        label: '全部应用',
        value: 'all',
      },
      ...templateStore.app_groups,
    ]
  }, [templateStore.app_groups])

  const apps = useMemo(() => {
    if (type === 'all') {
      return templateStore.apps
    }
    return templateStore.apps.filter(app => app.sub_type === type)
  }, [type, templateStore.apps])

  const { scrollRef } = useScrollBar()

  return (
    <div className={classNames('h-full', props.className)}>
      <div
        className='bg-bg h-full rounded-12px py-40 px-60 overflow-y-auto relative template-container'
        ref={scrollRef}
      >
        <ColorfulTitle>探索AI应用</ColorfulTitle>
        <p className='mt-12 font-400'>
          使用这些应用模版，或者根据模版构建自己的应用。
        </p>
        <BannerBox className='mt-40 w-full relative'>
          <img className='w-full rounded-12px' src={BannerBg} alt='' />
          <img
            className='h-[calc(100%_+_24px)] absolute right-0 z-1 top--24px'
            src={BannerFront}
            alt=''
          />
          {/* <div className="w-full h-full absolute top-0 left-0 overflow-hidden"> */}
          {/*  <div className="scan-light-container"></div> */}
          {/* </div> */}
        </BannerBox>
        <div className='mt-50'>
          <Affix target={() => scrollRef.current!} offsetTop={0}>
            <div className='bg-bg py-10'>
              <StyledSegmented
                defaultValue='all'
                size='small'
                options={options}
                onChange={value => setType(value as string)}
              />
            </div>
          </Affix>
        </div>
        <div className='mt-10 flex flex-wrap gap-20'>
          {apps.map(item => {
            return (
              <TemplateAppCard
                className='flex relative flex-col justify-between p-20 overflow-hidden rounded-8px bg-white cursor-pointer min-w-316px h-160px w-[calc(25%_-_20px)]'
                key={item.id}
              >
                <div className='flex items-start'>
                  <div
                    className='relative shrink-0 top--3px rounded-8px w-48px h-48px flex items-center justify-center'
                    style={{
                      backgroundColor: rgba(item.color, 0.32),
                    }}
                  >
                    <span className='text-24px'>{item.icon}</span>
                  </div>
                  <div className='ml-12px'>
                    <p className='font-600 text-16px'>{item.name}</p>
                    <p className='font-400 text-font_1 text-12px mt-8 line-clamp-3 max-h-68px line-height-20px overflow-hidden'>
                      {item.description}
                    </p>
                  </div>
                </div>
                <div>
                  <div className='inline-flex text-font_1 text-12px font-400 rounded-6px bg-bg_3 bg-opacity-6 px-9 py-5'>
                    <IconFont
                      name={(templateStore.types as any)[item.type].icon}
                      className='text-font_1 text-op-60'
                    />
                    <span className='ml-6'>
                      {(templateStore.types as any)[item.type].name}
                    </span>
                  </div>
                  <div className='action absolute w-full px-20 left-0 bottom--32px'>
                    <Button
                      className='flex-center important:shadow-none important:rounded-6px w-full py-0 important:font-400'
                      size='small'
                      type='primary'
                      onClick={() => props.onTemplateSetup(item)}
                    >
                      <IconFont name='enter' className='text-14px' />
                      <span className='ml-7'>立即体验</span>
                    </Button>
                  </div>
                </div>
              </TemplateAppCard>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default TemplatePage
