import { useMemoizedFn } from 'ahooks'
import { message } from 'antd'
import { memo, useRef, useState, useMemo, useEffect } from 'react'
import type { LogItem } from '@apis/run/type'
import {
  DEBUG_TASK_MSG_MAP,
  DEBUG_TASK_ID_MAP,
} from '@/features/logger/constants'
import { ALL_TABS } from '../const'
import type { LoggerModalProviderProps } from '../context'
import { LoggerModalProvider, useLogger } from '../context'
import { useRunFlow } from '../hooks/useRunFlow'
import { useSessionValue, useSetSessionValue } from '../hooks/useSessionValue'
import type { LoggerTab } from '../type'
import { Button, IconFont } from '@/components'
import { LoggerDetail } from './Detail'
import { LoggerInputEditable } from './InputEditable'
import { LoggerInputReadonly } from './InputReadonly'
import { LoggerLog } from './Log'
import { LoggerOutput } from './Output'
import { StyledSegmented } from './StyledSegmented'

export interface LoggerContentPureProps {
  versionId?: string
  defaultTab?: LoggerTab
  tabs?: LoggerTab[]
  onSaveToTest?: () => void
  onRun?: () => void
  onReRun?: (flowLog: LogItem) => void
}

export const LoggerContentInner = memo((props: LoggerContentPureProps) => {
  const {
    versionId,
    tabs = ['detail', 'input', 'output', 'log'],
    defaultTab = 'detail',
    onSaveToTest,
    onRun,
    onReRun,
  } = props

  const wrapDom = useRef<HTMLDivElement>(null)
  const [messageApi, contextHolder] = message.useMessage({
    top: 30,
    getContainer: () => wrapDom.current!,
  })

  const { flowId, form, publishRun, publishClear } = useLogger()
  const [taskMsg, setTaskMsg] = useSessionValue(DEBUG_TASK_MSG_MAP, flowId)
  const setDebugTaskId = useSetSessionValue(DEBUG_TASK_ID_MAP)

  const {
    mode,
    flowLog,
    loading,

    output,
    logs,
    setDiff,
  } = useLogger()

  const handleRunFlow = useRunFlow(flowId, versionId)

  const [activeTab, setActiveTab] = useState<LoggerTab>(defaultTab)

  const handleChangeTab = useMemoizedFn((tab: LoggerTab) => {
    if (activeTab === 'output' && tab !== 'output' && loading) {
      messageApi.info('内容生成中')
      return
    }
    if (activeTab === 'output' && tab !== 'output') {
      setDiff(false)
    }
    setActiveTab(tab)
  })

  const handleClear = useMemoizedFn(() => {
    setActiveTab('input')
    publishClear()
  })

  const handleRun = useMemoizedFn(async () => {
    await form.validateFields()
    // 执行后清空 session
    setDebugTaskId(null, flowId)
    publishRun()
    setActiveTab('output')
    handleRunFlow()
    onRun?.()
  })

  const showTabs = useMemo(() => {
    return ALL_TABS.filter(e => tabs?.includes(e.value)).map(e => {
      let disabled = false
      if (mode === 'debug') {
        if (e.value === 'output') {
          disabled = !output.result && !output.errorMsg
        } else if (e.value === 'log') {
          disabled = !logs?.length
        }
      }

      return {
        ...e,
        disabled,
      }
    })
  }, [tabs, mode, output, logs])

  const runButton = useMemo(() => {
    const isLogMode = mode === 'log'

    if (isLogMode) {
      return (
        <div className='w-full p-16px b-t-1 b-t-line b-t-op-60' key='rerun'>
          <Button
            className='w-full'
            type='primary'
            onClick={() => onReRun?.(flowLog as LogItem)}
          >
            重新运行
          </Button>
        </div>
      )
    } else if (loading) {
      return (
        <div className='w-full p-16px b-t-1 b-t-line b-t-op-60' key='loading'>
          <Button className='w-full' type='primary' loading={loading} disabled>
            运行中
          </Button>
        </div>
      )
    } else {
      return (
        <div className='flex w-full p-16px b-t-1 b-t-line b-t-op-60' key='run'>
          {activeTab === 'input' && (
            <Button
              className='w-36px! flex-none mr-12px'
              icon={<IconFont name='qingkongrizhi' />}
              onClick={handleClear}
            />
          )}
          <Button className='w-full' type='primary' onClick={handleRun}>
            开始运行
          </Button>
        </div>
      )
    }
  }, [flowLog, loading, activeTab, mode])

  useEffect(() => {
    if (taskMsg?.message) {
      messageApi.success(taskMsg.message)
    }
    setTaskMsg(null)
  }, [taskMsg?.message])

  return (
    <div className='h-full flex flex-col overflow-hidden' ref={wrapDom}>
      {contextHolder}
      <div className='p-16px pb-0'>
        <StyledSegmented
          block
          value={activeTab}
          options={showTabs}
          onChange={handleChangeTab as any}
        />
      </div>

      <div className='flex-1 overflow-hidden py-16px'>
        {activeTab === 'detail' && <LoggerDetail />}

        {mode !== 'log' && activeTab === 'input' && (
          <LoggerInputEditable onSaveToTest={onSaveToTest} />
        )}

        {mode === 'log' && activeTab === 'input' && <LoggerInputReadonly />}

        {activeTab === 'output' && <LoggerOutput onChangeTab={setActiveTab} />}

        {activeTab === 'log' && <LoggerLog />}
      </div>

      {runButton}
    </div>
  )
})

export type LoggerProps = LoggerContentPureProps &
  Omit<LoggerModalProviderProps, 'children'>

export const LoggerContent = memo((props: LoggerProps) => {
  const {
    mode = 'log',
    startType,
    startConfig,
    flowId,
    taskId,
    onLog,
    onDiff,

    ...rest
  } = props

  const [sessionTaskID] = useSessionValue(DEBUG_TASK_ID_MAP, flowId)

  return (
    <LoggerModalProvider
      mode={mode}
      startType={startType}
      startConfig={startConfig}
      flowId={flowId}
      taskId={taskId ?? sessionTaskID}
      onLog={onLog}
      onDiff={onDiff}
    >
      <LoggerContentInner {...rest}></LoggerContentInner>
    </LoggerModalProvider>
  )
})
