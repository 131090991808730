import type { MenuProps } from 'antd'
import { Dropdown, Switch } from 'antd'
import dayjs from 'dayjs'
import type { ReactNode } from 'react'
import { useEffect, useMemo, useState } from 'react'
import { appConnectTest, enableApp } from '@apis/agent-go'
import { Button, IconFont } from '@/components'

interface RobotCardProps {
  appId: string
  dingIcon: string
  agentAvatar?: string
  name: string
  confTime: string
  operator: string
  enable?: boolean
  connectState: 'off' | 'testing' | 'fail' | 'success'
  needConnectTest?: boolean
  onReset: () => void
  onUnbind: () => void
  onRefresh?: () => void
  onTestFinish?: () => void
}

export function AgentGoDingTalkRobotCard(props: RobotCardProps) {
  const {
    appId,
    dingIcon,
    agentAvatar,
    name,
    confTime,
    operator,
    enable,
    connectState,
    needConnectTest,
    onReset,
    onUnbind,
    onRefresh,
    onTestFinish,
  } = props

  const [state, setConnectState] =
    useState<RobotCardProps['connectState']>(connectState)
  const [errorMessage, setErrorMessage] = useState('')

  const menu: { items: MenuProps['items']; onClick: MenuProps['onClick'] } = {
    items: [
      {
        label: (
          <span className='pl-12px text-14px/32px text-font'>重新配置</span>
        ),
        key: 'reset',
      },
      {
        label: (
          <span className='pl-12px text-14px/32px text-error'>解绑账号</span>
        ),
        key: 'unbind',
      },
    ],
    onClick: ({ key }) => {
      if (key === 'reset') {
        onReset()
        return
      }
      if (key === 'unbind') {
        onUnbind()
      }
    },
  }

  const stateEle = useMemo(() => {
    let stateElement: ReactNode
    switch (state) {
      case 'off':
        stateElement = (
          <span className='inline-block ml-8px text-center w-52px h-20px text-12px/20px font-medium text-font_1 rounded-4px bg-[rgba(98,105,153,.1216)]'>
            未启用
          </span>
        )
        break
      case 'testing':
        stateElement = (
          <span className='inline-block ml-8px text-center w-52px h-20px text-12px/20px font-medium text-tips rounded-4px bg-[rgba(56,166,255,.1216)]'>
            测试中
          </span>
        )
        break
      case 'fail':
        stateElement = (
          <span
            title={`连接失败：${errorMessage}`}
            className='inline-block ml-8px text-center truncate max-w-300px px-4 h-20px text-12px/20px font-medium text-error rounded-4px bg-[rgba(255,82,25,.1216)]'
          >
            连接失败：{errorMessage}
          </span>
        )
        break
      case 'success':
        stateElement = (
          <span className='inline-block ml-8px text-center w-52px h-20px text-12px/20px font-medium text-#2CB969 rounded-4px bg-[rgba(44,185,105,.12)]'>
            正常
          </span>
        )
        break
      default:
        break
    }
    return stateElement
  }, [state, errorMessage])

  const handleTest = async (withCallback?: boolean) => {
    try {
      if (appId) {
        setConnectState('testing')
        const res = await appConnectTest(appId)
        if (res === 'FAILED') {
          setConnectState('fail')
        } else if (res === 'SUCCEED') {
          setConnectState('success')
        }
      }
    } catch (error) {
      setErrorMessage((error as Error).message ?? '未知原因')
      setConnectState('fail')
    } finally {
      if (withCallback) {
        onTestFinish?.()
      }
    }
  }

  const handleEnableToggle = async (checked: boolean) => {
    try {
      await enableApp(appId, checked)
      if (checked) {
        handleTest()
      }
      await onRefresh?.()
    } catch (error) {}
  }

  useEffect(() => {
    enable && handleTest()
  }, [])

  useEffect(() => {
    setConnectState(enable ? 'success' : 'off')
  }, [enable])

  useEffect(() => {
    if (needConnectTest) {
      handleTest(true)
    }
  }, [needConnectTest])

  return (
    <div className='grow-0 flex-[calc(50%-12px)] h-160px p-20px b-rd-8px b-1 b-#e1e1e5 b-op-40'>
      <div className='flex items-start'>
        <div className='flex-1 overflow-hidden'>
          <div className='flex items-center'>
            <img
              className='w-64px h-64px rounded-full object-contain'
              src={dingIcon}
            />
            <IconFont
              className='text-[rgba(98,105,153,0.6)] text-16px mx-4px'
              name='link'
            />
            <img
              className='w-64px h-64px rounded-full object-contain'
              src={agentAvatar}
            />
          </div>
        </div>
        <div className='shrink-0 ml-auto inline-flex items-center'>
          <Switch
            className='mr-8px'
            size='small'
            value={enable}
            onChange={handleEnableToggle}
          />
          <Button
            className='w-76px !px-0 text-font align-top !border-[rgba(225,225,229,0.8)]'
            size='small'
            disabled={!enable}
            onClick={() => handleTest()}
          >
            连接测试
          </Button>
          <Dropdown
            overlayClassName='[&>.ant-dropdown-menu-root]:rounded-8px [&>.ant-dropdown-menu-root]:w-156px [&>.ant-dropdown-menu-root]:shadow-[0px_8px_24px_0px_rgba(0,0,0,0.12)]'
            menu={menu}
            placement='bottomRight'
          >
            <a
              className='inline-flex justify-center items-center ml-8px w-32px h-32px text-16px text-[rgba(98,105,153,0.6)] rounded-8px cursor-pointer hover:text-[rgba(23,23,29,0.6)] hover:bg-[rgba(98,105,153,0.08)] transition-colors duration-200 ease-[cubic-bezier(0.645,0.045,0.355,1)]'
              onClick={event => event.preventDefault()}
            >
              <IconFont name='gengduo' />
            </a>
          </Dropdown>
        </div>
      </div>
      <span className='flex items-center block mt-12px text-14px/16px font-medium text-font'>
        <div className='truncate max-w-[40%]'>{name}</div>
        {stateEle}
      </span>
      <p className='text-12px/16px text-font_1 mt-8px'>
        接入时间：{dayjs(confTime).format('YYYY-MM-DD HH:mm')} | 最后操作人：
        {operator}
      </p>
    </div>
  )
}
