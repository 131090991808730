import { Form } from 'antd'
import { PUBLISH_MODE } from '@bty/chat-types'
import { enableApp, createApp, updateApp } from '@apis/agent-go'
import type { AuthAppFlyBook } from '@apis/agent-go/type'
import { useAgentEdit } from '../provider/AgentEditProvider'
import { useQueryAgentGoApp } from '@/features/agent/hooks/useQueryAgentGoApp.ts'
import { AgentGoPublishConf } from '@/features/agent/components/AgentGoPublishConf'
import type { AgentGoPublishConfProps } from '@/features/agent/components/AgentGoPublishConf'
import { FLY_BOOK_DOCS } from '@/features/agent/AgentGo/common.tsx'
import { LinkText } from '@/features/agent/components/LinkText.tsx'
import { CopyBox } from '@/features/agent/components/CopyBox.tsx'
import { AgentGoFormItem } from '@/features/agent/components/AgentGoFormItem.tsx'
import { Input } from '@/components'
import renderLabel from '@/features/agent/components/renderLabel.tsx'
import { CheckContainer } from '../components/CheckContainer'

interface AgentGoFlyBookProps {
  mode?: string
  modeIcon?: string
  onBindSuccess?: () => void
  onUnBindSuccess?: () => void
}

export default function AgentGoFlyBook(props: AgentGoFlyBookProps) {
  const { mode, modeIcon, onUnBindSuccess, onBindSuccess } = props
  const [form] = Form.useForm()
  const { applicationInfo } = useAgentEdit()

  const { runQueryApp, app, loading, setApp } =
    useQueryAgentGoApp<AuthAppFlyBook>({
      agentId: applicationInfo?.flowId,
      source: PUBLISH_MODE.feishu,
      onQuerySuccess: data => {
        const { auth_app_config } = data
        form.setFieldsValue({
          ...auth_app_config,
        })
      },
    })
  const handleValuesChange: NonNullable<
    AgentGoPublishConfProps['onValuesChange']
  > = changedValues => {
    if ('is_enable' in changedValues) {
      setApp({ ...app, is_enable: changedValues.is_enable })
      enableApp(app.auth_app_id!, changedValues.is_enable)
    }
  }
  const handleStepChange: NonNullable<
    AgentGoPublishConfProps['onStepChange']
  > = async step => {
    step === 3 &&
      form.setFieldsValue({
        auth_app_name: '',
      })
    if (step !== 1) {
      return
    }
    if (app.auth_app_id) {
      const { ...values } = form.getFieldsValue()
      const data = await updateApp({
        auth_app_id: app.auth_app_id,
        auth_app_name: '飞书百事通',
        auth_app_config: values,
      })
      setApp(data as any as AuthAppFlyBook)
      return
    }
    if (applicationInfo?.flowId) {
      const { ...values } = form.getFieldsValue()
      const data = await createApp({
        agent_id: applicationInfo?.flowId,
        auth_app_name: '飞书百事通',
        auth_app_source: PUBLISH_MODE.feishu,
        auth_app_config: values,
      })
      setApp(data as AuthAppFlyBook)
    }
  }
  const handleComplete: NonNullable<
    AgentGoPublishConfProps['onComplete']
  > = appId => {
    onBindSuccess?.()
    if (appId && applicationInfo) {
      runQueryApp(applicationInfo.flowId)
    }
  }
  const handleBeforeComplete = (value: any) => {
    return value
  }
  const handleUnBindSuccess = async () => {
    setApp({})
    onUnBindSuccess?.()
  }
  const steps = [
    {
      title: '填写应用信息',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium text-#17171d mb-24px'>
            登录{' '}
            <LinkText href='https://open.feishu.cn/'>飞书开放平台</LinkText>{' '}
            获取以下参数
          </div>
        </>
      ),
    },
    {
      title: '配置事件订阅',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium'>
            在<LinkText href='https://open.feishu.cn/'>飞书开放平台</LinkText>{' '}
            配置订阅的请求地址并添加事件
          </div>
          <div className='font-500 mt-24 line-height-16px'>请求地址</div>
          <Form.Item
            shouldUpdate={(prev, curr) => prev.AppID !== curr.AppID}
            noStyle
          >
            {({ getFieldValue }) => {
              const app_id = getFieldValue('AppID')
              return (
                <CopyBox
                  className='mt-8 mb-24'
                  value={`${
                    import.meta.env.VITE_AI_API_BASE_URL
                  }/v1/webhook/agent/feishu_app_bot/${app_id}/receive_message`}
                />
              )
            }}
          </Form.Item>
          <div className='font-500 mt-24 line-height-16px mb-8px'>添加事件</div>
          <Form.Item
            name='event'
            rules={[
              () => ({
                validator(_, value) {
                  if (value !== true) {
                    return Promise.reject(new Error('使用前必须确保添加客服'))
                  }
                  return Promise.resolve()
                },
              }),
            ]}
          >
            <CheckContainer
              title='我已添加事件「接收消息v2.0」'
              label='添加事件需开通对应权限才可使用'
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: '机器人和权限设置',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium mb-24px'>
            在<LinkText href='https://open.feishu.cn/'>飞书开放平台</LinkText>{' '}
            应用中添加机器人能力
          </div>
          <div className='font-500 mt-24 line-height-16px mb-8px'>
            添加机器人
          </div>
          <AgentGoFormItem
            name='checkBot'
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error('使用前必须确保配置事件订阅')),
              },
            ]}
          >
            <CheckContainer
              title='我已添加机器人'
              label='未添加机器人将无法正常使用'
            />
          </AgentGoFormItem>
          <div className='font-500 mt-24 line-height-16px mb-8px'>开通权限</div>
          <AgentGoFormItem
            name='addEvent'
            rules={[
              {
                validator: (_, value) =>
                  value
                    ? Promise.resolve()
                    : Promise.reject(new Error('使用前必须确保配置事件订阅')),
              },
            ]}
          >
            <CheckContainer
              title='已确认开通以下权限'
              label={[
                '-获取与发送单聊、群组消息；',
                '-获取用户在群组中@机器人的消息；',
                '-接收群聊中@机器人事件；',
                '-获取群组中所有消息；',
                ' -获取用户发给机器人的单聊消息；',
                '-读取用户发给机器人的单聊消息',
              ]}
            />
          </AgentGoFormItem>
        </>
      ),
    },
    {
      title: '发布飞书应用',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium mb-24px'>
            在<LinkText href='https://open.feishu.cn/'>飞书开放平台</LinkText>{' '}
            版本管理中发布当前应用
          </div>
          <AgentGoFormItem
            name='auth_app_name'
            valuePropName='unchecked'
            getValueFromEvent={(value: boolean) =>
              value ? '飞书百事通' : undefined
            }
            rules={[
              {
                validator: (_, value) => {
                  return value
                    ? Promise.resolve()
                    : Promise.reject(new Error('使用前必须确保发布飞书应用'))
                },
              },
            ]}
          >
            <CheckContainer
              title='飞书应用已发布'
              label='未发布的应用将无法正常使用'
            />
          </AgentGoFormItem>
        </>
      ),
    },
  ]
  return loading ? null : (
    <AgentGoPublishConf
      form={form}
      mode={mode}
      app={app}
      steps={steps}
      guides={FLY_BOOK_DOCS}
      serviceAvatar={app?.bot_info?.avatar_url || modeIcon}
      onStepChange={handleStepChange}
      onComplete={handleComplete}
      onValuesChange={handleValuesChange}
      onUnBindSuccess={handleUnBindSuccess}
      onBeforeComplete={handleBeforeComplete}
    >
      {step => (
        <>
          {steps[step].content}
          <AgentGoFormItem
            label={renderLabel('App ID', true)}
            name='AppID'
            required
            hidden={step !== 0}
            normalize={(value: any) => value?.trim()}
            rules={[{ required: true, message: 'App ID 不能为空' }]}
          >
            <Input
              placeholder='在「凭证与基础信息」中获取'
              spellCheck={false}
            />
          </AgentGoFormItem>
          <AgentGoFormItem
            label={renderLabel('App Secret', true)}
            name='AppSecret'
            required
            hidden={step !== 0}
            normalize={(value: any) => value?.trim()}
            rules={[{ required: true, message: 'App Secret 不能为空' }]}
          >
            <Input
              placeholder='在「凭证与基础信息」中获取'
              spellCheck={false}
            />
          </AgentGoFormItem>
          <AgentGoFormItem
            label={renderLabel('Encrypt Key', true)}
            name='EncryptKey'
            required
            hidden={step !== 0}
            normalize={(value: any) => value?.trim()}
            rules={[{ required: true, message: 'Encrypt Key不能为空' }]}
          >
            <Input placeholder='在「事件与回调」中获取' />
          </AgentGoFormItem>
          <AgentGoFormItem
            label={renderLabel('Verification Token', true)}
            name='VerificationToken'
            required
            hidden={step !== 0}
            normalize={(value: any) => value?.trim()}
            rules={[{ required: true, message: 'Verification Token不能为空' }]}
          >
            <Input placeholder='在「事件与回调」中获取' />
          </AgentGoFormItem>
        </>
      )}
    </AgentGoPublishConf>
  )
}
