import NiceModal from '@ebay/nice-modal-react'
import { useRequest } from 'ahooks'
import { useCallback, useState } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import type { AgentFlowSkill } from '@bty/chat-types'
import { deleteAgentSkill, enableAgentSkill } from '@apis/agent'
import type { ApplicationBodyType } from '@apis/application/type'
import EmptyImage from '@/assets/app/rocket2.png'
import { SkillEditorModal } from '@/features/agent/SkillEditModal/Modal.tsx'
import type { SkillValue } from '@/features/agent/SkillTabs/SkillListItem'
import { SkillListItem } from '@/features/agent/SkillTabs/SkillListItem'
import { Empty } from '@/components/empty'
import type { SkillError } from '@/features/agent/hooks/useSkillConfig.ts'
import { useModal } from '@/hooks/useModal.tsx'
import type { SkillBaseInfo } from '../types'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import { FLOW_DISPLAYNAME } from '@/constants/common'
import { Button, IconFont, Input } from '@/components'
import { useAgentEdit } from '../provider/AgentEditProvider'
import { SkillConfModal } from './SkillConfModal'

interface SkillsConfigProps {
  flowInfo: ApplicationBodyType
  list: Array<SkillBaseInfo & { meta: AgentFlowSkill; error?: SkillError }>
  selectedSkillMap: Record<'flow' | 'tool', Record<string, true>>
  onListChange: () => void
}

export function FlowList({
  flowInfo,
  selectedSkillMap,
  list,
  onListChange,
}: SkillsConfigProps) {
  const {
    applicationInfo,
    updateStatistics,
    refreshAgentUploadLoadSupportFileTypes,
  } = useAgentEdit()

  const config = applicationInfo?.config as
    | Exclude<ApplicationBodyType['config'], string>
    | undefined

  const enforceExecution = config?.rule?.enforce_execution

  const forcedInvokeFlow =
    enforceExecution?.type === 'FLOW' ? enforceExecution.tool_id : undefined

  const [skillEditorModal] = useModal(SkillEditorModal)

  const { runAsync: enableAgentSkillApi } = useRequest(enableAgentSkill, {
    manual: true,
  })
  const { runAsync: deleteAgentSkillApi } = useRequest(deleteAgentSkill, {
    manual: true,
  })

  const handleCreate = () => {
    skillEditorModal.open({
      flowInfo,
      selectedSkillMap,
      activeSkillType: 'Flow',
      onFinish: () => {
        onListChange()
        skillEditorModal.close()
        refreshAgentUploadLoadSupportFileTypes()
        updateStatistics()
      },
    })
  }

  const handleEdit = useCallback(
    (skill: SkillValue) => {
      const flow = skill as SkillBaseInfo & {
        meta: AgentFlowSkill
        error?: SkillError | undefined
      }

      NiceModal.show(SkillConfModal, {
        type: 'Flow',
        identifier: flow.meta.flow_id,
        conf: { ...flow.meta },
        error: flow.error,
        getExtraParams: () => ({
          // 我也不明白为什么这里用 flow_id 下面用 agent_id，但是这样就对了
          flow_id: flowInfo.flowId,
          version_id: flowInfo.versionId,
        }),
        onOk: onListChange,
      })
    },
    [flowInfo.flowId, flowInfo.versionId],
  )

  const handleEnableChange = useCallback(
    async (enabled: boolean, skill: SkillValue) => {
      await enableAgentSkillApi({
        id: skill.id,
        flow_id: flowInfo.flowId,
        version_id: flowInfo.versionId,
        enabled,
      })
      onListChange()
    },
    [flowInfo.flowId, flowInfo.versionId],
  )

  const handleDelete = useCallback(
    async (id: string) => {
      await deleteAgentSkillApi(id, flowInfo.flowId, flowInfo.versionId)
      onListChange()
      refreshAgentUploadLoadSupportFileTypes()
      updateStatistics()
    },
    [flowInfo.flowId, flowInfo.versionId],
  )

  const [searchKey, setSearchKey] = useState<string>()

  if (!list?.length) {
    return (
      <Empty
        text={`添加 ${FLOW_DISPLAYNAME}，让 Agent 做更多的事情`}
        btnText={`添加${FLOW_DISPLAYNAME}`}
        image={EmptyImage}
        onCreate={handleCreate}
      />
    )
  }

  const filteredList = searchKey
    ? list.filter(el => el.name.includes(searchKey))
    : list

  return (
    <div className='p-24 pt-12 flex flex-col h-full'>
      <h2 className='flex items-center h-36px text-16px font-medium text-font shrink-0'>
        工作流
        <Input
          className='rounded-8px ml-auto w-140px'
          placeholder='搜索'
          prefix={<IconFont className='text-16px' name='search' />}
          onChange={event => {
            const searchKey = event.target.value
            setSearchKey(searchKey)
          }}
        />
        <span className='mx-12 px-4 h-16px border-right after:right-4 after:border-line' />
        <Button className='!p-0 w-110px' type='primary' onClick={handleCreate}>
          <IconFont name='add' />
          <span className='ml-4'>添加{FLOW_DISPLAYNAME}</span>
        </Button>
      </h2>
      <OverlayScrollbarsComponent
        className='flex-1 mt-20'
        element='div'
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
      >
        {filteredList
          .filter(el => el.id === forcedInvokeFlow)
          .map(skill => (
            <SkillListItem
              key={skill.id}
              type='Flow'
              skill={skill}
              forcedInvoke
              onEdit={handleEdit}
              onEnableChange={handleEnableChange}
              onDelete={handleDelete}
            />
          ))}
        {filteredList
          .filter(el => el.id !== forcedInvokeFlow)
          .map(skill => (
            <SkillListItem
              key={skill.id}
              type='Flow'
              skill={skill}
              onEdit={handleEdit}
              onEnableChange={handleEnableChange}
              onDelete={handleDelete}
            />
          ))}
      </OverlayScrollbarsComponent>
    </div>
  )
}
