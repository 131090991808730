import { http } from '../http'
import { HttpDefine, type HttpInjectInfo } from '../http/type'
import { getBytes, getLines, getMessages } from './parse'
import type { EventSourceDefined } from './type'

export const EventStreamContentType = 'text/event-stream, application/json'

const DefaultRetryInterval = 1000
const LastEventId = 'last-event-id'

export function sse<M>(
  {
    onOpen = defaultOnOpen,
    onMessage,
    onClose,
    onError,
    openWhenHidden,
    ...rest
  }: EventSourceDefined<M>,
  inject?: HttpInjectInfo,
) {
  // make a copy of the input headers since we may modify it below:
  const headers = HttpDefine.getHeader(rest)
  headers.accept = EventStreamContentType

  let curRequestController: AbortController

  function onVisibilityChange() {
    curRequestController.abort() // close existing request on every visibility change
    if (!document.hidden) {
      create() // page is now visible again, recreate request.
    }
  }

  if (!openWhenHidden) {
    document.addEventListener('visibilitychange', onVisibilityChange)
  }

  let retryInterval = DefaultRetryInterval
  let retryTimer = 0
  function dispose() {
    document.removeEventListener('visibilitychange', onVisibilityChange)
    window.clearTimeout(retryTimer)
    curRequestController.abort()
  }

  // if the incoming signal aborts, dispose resources and resolve:
  rest?.signal?.addEventListener('abort', () => {
    dispose()
  })

  async function create() {
    curRequestController = new AbortController()
    try {
      const response = await http(
        { ...rest, abort: curRequestController },
        inject,
      )

      await onOpen(response)

      await getBytes(
        response.body!,
        getLines(
          getMessages(
            id => {
              if (id) {
                // store the id and send it back on the next retry:
                headers[LastEventId] = id
              } else {
                // don't send the last-event-id header anymore:
                delete headers[LastEventId]
              }
            },
            retry => {
              retryInterval = retry
            },
            event => {
              if (!event.data) return
              // 后端返回都是jsonstr
              const newData = JSON.parse(event.data)
              onMessage?.(newData)
            },
          ),
        ),
      )

      onClose?.()
      dispose()
    } catch (err) {
      if (!curRequestController.signal.aborted) {
        // if we haven't aborted the request ourselves:
        try {
          // check if we need to retry:
          const interval = onError?.(err as Error) ?? retryInterval
          window.clearTimeout(retryTimer)
          retryTimer = window.setTimeout(create, interval)
        } catch (innerErr) {
          // we should not retry anymore:
          dispose()
        }
      }
    }
  }

  create()

  return () => dispose()
}

function defaultOnOpen(response: Response) {
  const contentType = response.headers.get('content-type')
  if (!contentType?.startsWith(EventStreamContentType)) {
    throw new Error(
      `Expected content-type to be ${EventStreamContentType}, Actual: ${contentType}`,
    )
  }
}
