import { request } from '../../client'
import type { ApplicationType } from '../authority/type'
import type {
  AppInfo,
  GroupedApplication,
  ApplicationBodyType,
  GroupType,
  AppAbilityStatistics,
  ForkAppRequest,
  ForkAppResponse,
  ForkDataBaseResponse,
  ForkDataSetResponse,
  OverWriteResponse,
} from './type'

export function getAppList(appIdList: string[] = []) {
  return request.post<{ list: AppInfo[] }>('/v1/app/list', { appIdList })
}

export function getGroupedAppList(workspaceId: string) {
  return request.get<GroupedApplication[]>(
    `/v1/applications/grouped/${workspaceId}`,
    {
      query: {
        applicationType: 'AI,AGENT',
      },
    },
  )
}

export function getApplicationsList(
  workspaceId: string,
  params: { applicationType?: ApplicationType } = {
    applicationType: 'AI',
  },
) {
  return request.get<ApplicationBodyType[]>(
    `/v1/applications/list/${workspaceId}`,
    { query: params },
  )
}

export function getApplicationById(applicationId: string) {
  return request.get<ApplicationBodyType>(`/v1/applications/${applicationId}`, {
    headers: {
      'Application-Id': applicationId,
    },
  })
}

export function getAppAbilityStatistics(
  appId: string,
): Promise<AppAbilityStatistics> {
  return request.get(`/v1/applications/${appId}/skill_statistics`)
}

export function createApplication(
  body: Partial<ApplicationBodyType>,
  workspaceId: string,
) {
  return request.post<ApplicationBodyType>(
    `/v1/applications?workspaceId=${workspaceId}`,
    body,
  )
}

export function deleteApplication(applicationsId: string) {
  return request.delete<any>(`/v1/applications/${applicationsId}`)
}

export function editApplication(body: Partial<ApplicationBodyType>) {
  return request.put(`/v1/applications/${body.id}`, body)
}

export function forkTemplateApp(req: {
  workspaceId: string
  applicationId: string
}) {
  return request.post<ApplicationBodyType>(
    `/v1/applications/${req.applicationId}/fork/${req.workspaceId}`,
    req,
  )
}

export function forkApplication(req: ForkAppRequest) {
  return request.post<ForkAppResponse>('/v1/app/fork', req)
}

export function forkDataSet(body: ForkDataSetResponse) {
  return request.post('/v1/agent/dataset/fork', body)
}
export function forkDataBase(body: ForkDataBaseResponse) {
  return request.post('/v1/agent/database/fork', body)
}

export function overWriteApplication(body: OverWriteResponse) {
  return request.post('/v1/app/overwrite', body)
}

export function checkForkResources(app_id: string) {
  return request.get(`/v1/app/fork/resources/detail/check/${app_id}`)
}

// 复制时的关联资源详情
export function forkingResourcesDetail(app_id: string) {
  return request.get(`/v1/app/fork/resources/detail/${app_id}`)
}

export function changeApplicationAccess(req: {
  applicationId: string
  publicAccess: boolean
}) {
  return request.put(`/v1/applications/${req.applicationId}/changeAccess`, {
    publicAccess: req.publicAccess,
  })
}

export interface TemplateListQueryParams {
  applicationType?: ApplicationType
  labels?: string
}

export function fetchTemplateList(
  req: TemplateListQueryParams = { applicationType: 'AGENT' },
) {
  const { applicationType, labels } = req

  return request.get<ApplicationBodyType[]>(
    '/v1/applications/getTemplateList',
    { query: { applicationType: applicationType || 'AGENT', labels } },
  )
}

// 应用分组列表
export function getGroupList({
  applicationType,
  workspaceId,
}: {
  applicationType: ApplicationType
  workspaceId: string
}) {
  return request.get<GroupType[]>(
    `/v1/app-group/list/${workspaceId}?applicationType=${applicationType}`,
  )
}

// 应用分组创建
export function createGroup(data: {
  workspaceId: string
  AppGroup: { name: string; applicationType: ApplicationType }
}) {
  return request.post<any>(
    `/v1/app-group?workspaceId=${data.workspaceId}`,
    data.AppGroup,
  )
}

// 应用分组更新
export function updateGroup(data: { id: string; AppGroup: { name: string } }) {
  return request.put<any>(`/v1/app-group/${data.id}`, data.AppGroup)
}

// 应用分组排序
export function sortGroup(groupList: { id: string; sort: number }[]) {
  return request.post<any>('/v1/app-group/sort', groupList)
}

// 应用分组删除
export function deleteGroup(id: string) {
  return request.delete<any>(`/v1/app-group/delete/${id}`)
}

// 应用设置分组
export function setAppToGroup(data: {
  id: string
  AppGroup: { appGroupId: string }
}) {
  return request.put<any>(`/v1/applications/${data.id}`, data.AppGroup)
}

export function getRecommendedTemplateList() {
  return request.get<ApplicationBodyType[]>(
    '/v1/applications/getRecommendedTemplateList?applicationType=AGENT',
  )
}

export function getTemplateLabels() {
  return request.get<string[]>(
    '/v1/applications/getTemplateLabelsList?applicationType=AGENT',
  )
}

export function getAppListByIds(ids: string[]) {
  return request.post<ApplicationBodyType[]>('/v1/app/list', {
    appIdList: ids,
  })
}

export function linkInvitUser(body: any) {
  return request.post('/v1/link/invite_user_baseInfo/save', body)
}

export function getFlowVersionList(appId: string) {
  return request.get(`/v1/applications/versions/${appId}`)
}

// 恢复指定版本
export function recoverVersion(body: { flowId: string; versionId: string }) {
  return request.post('/v1/flow/recover/version', body)
}
