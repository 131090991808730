import { useRequest } from 'ahooks'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { cloneDeep } from 'lodash-es'
import { Dropdown, message } from 'antd'
import styled from '@emotion/styled'
import type { ItemType } from 'antd/es/menu/interface'
import classNames from 'classnames'
import { updateDataStore } from '@apis/datastore'
import {
  createGroup as createGroupApi,
  deleteGroup as deleteGroupApi,
  getGroupList,
  setAppToGroup as setAppToGroupApi,
  sortGroup,
  updateGroup as updateGroupApi,
} from '@apis/application'
import type { ApplicationBodyType, GroupType } from '@apis/application/type'
import type { ApplicationType } from '@apis/authority/type'
import { useWorkspaceStore } from '@/store'
import { IconFont, useConfirmModal } from '@/components'

interface DropdownMenuProps {
  open: boolean
  openChange: (e: string) => void
  title: string
  item: GroupType
  subMenu?: ItemType[]
  createAppToAgent: (key?: string) => void
  handleReName: (item: GroupType) => void
}

const MoreDiv = styled.div`
  padding: 9px;
  cursor: pointer;
  border-radius: 6px;
  &:hover {
    background: rgba(98, 105, 153, 0.08);
  }
`

export const GroupWrap = styled.div`
  .group-item:not(:first-of-type) {
    .group-item-header {
      border-top: 1px solid rgba(225, 225, 229, 0.32);
    }
  }
`

export const DragDiv = styled.div<{ open: boolean }>`
  box-sizing: border-box;
  height: 56px;
  line-height: 56px;
  .dragBtn,
  .MoreDiv {
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
  }
  .MoreDiv {
    ${({ open }) => (open ? 'background: rgba(98, 105, 153, 0.12)' : '')}
  }
  &:hover {
    .dragBtn,
    .MoreDiv {
      visibility: visible;
    }
  }
`

export const noGroupId = 'noGroup'

export function useGroup<T = ApplicationBodyType>({
  originList,
  applicationType,
  refresh,
  groupType,
  appIdKey = 'id',
  groupIdKey = 'appGroupId',
}: {
  originList?: T[]
  applicationType: ApplicationType
  refresh: () => Promise<any>
  groupType: string
  appIdKey?: string
  groupIdKey?: string
}) {
  const [list, setList] = useState<GroupType[]>([])
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const {
    data: groupList,
    loading: getListLoading,
    runAsync,
  } = useRequest(
    () => getGroupList({ applicationType, workspaceId: currentWorkspaceId }),
    { refreshDeps: [currentWorkspaceId] },
  )

  const { runAsync: deleteGroupFn, loading: deleteLoading } = useRequest(
    e => deleteGroupApi(e),
    { manual: true, onSuccess: runAsync },
  )

  const { runAsync: setAppToGroupFn, loading: setAppToGroupLoading } =
    useRequest(
      (e: { id: string; AppGroup: { appGroupId: string } }) =>
        setAppToGroupApi(e),
      { manual: true, onSuccess: () => message.success('移动成功') },
    )

  const { runAsync: sortGroupFn, loading: sortLoading } = useRequest(
    (e: any[]) => sortGroup(e),
    { manual: true, onSuccess: runAsync },
  )

  const { runAsync: updateDatastore, loading: updateDatastoreLoading } =
    useRequest(updateDataStore, {
      manual: true,
      onSuccess: () => message.success('更新成功'),
    })

  const { runAsync: updateGroupFn, loading: updateLoading } = useRequest(
    (e: { id: string; AppGroup: { name: string } }) => updateGroupApi(e),
    { manual: true, onSuccess: runAsync },
  )

  const { runAsync: createGroupFn, loading: createLoading } = useRequest(
    name =>
      createGroupApi({
        workspaceId: currentWorkspaceId,
        AppGroup: { name, applicationType },
      }),
    { manual: true, onSuccess: runAsync },
  )

  const [DragType, setDragType] = useState<'sort' | 'join' | null>(null)
  const [dragItem, setDragItem] = useState<any>({}) // 设置拖动元素样式
  const [dragOverItem, setDragOverItem] = useState<any>({}) // 设置拖动元素样式
  const [phantomItem, setPhantomItem] = useState<any>({}) // 设置快照即拖动创建的“幻影”元素样式
  const [openGroupMap, setOpenGroupMap] = useState<Record<string, boolean>>(
    JSON.parse(localStorage.getItem(groupType) || '{}'),
  )
  const [modal] = useConfirmModal()

  const loading = useMemo(() => {
    return (
      getListLoading ||
      deleteLoading ||
      setAppToGroupLoading ||
      updateDatastoreLoading ||
      sortLoading ||
      updateLoading ||
      createLoading
    )
  }, [
    getListLoading,
    deleteLoading,
    setAppToGroupLoading,
    sortLoading,
    updateLoading,
    createLoading,
  ])

  const openAllGroup = () => {
    const newOpenGroupMap: any = {}
    list.forEach(item => (newOpenGroupMap[item.id] = true))
    setOpenGroupMap(newOpenGroupMap)
  }

  useEffect(() => {
    if (loading) {
      return
    }
    const storageGroup = JSON.parse(localStorage.getItem(groupType) || '{}')
    if (Object.values(storageGroup)?.filter(item => item)?.length <= 0) {
      // 没有记录到任何打开的分组
      openAllGroup()
    } else {
      if (
        Object.keys(storageGroup)
          ?.filter(item => storageGroup[item])
          ?.filter(
            item => (list.find(i => i.id === item)?.children || [])?.length > 0,
          )?.length <= 0
      ) {
        // 记录打开的分组都没有应用
        const findItem = list.find(item => (item.children || [])?.length > 0)
        findItem && setOpenGroupMap({ ...storageGroup, [findItem?.id]: true })
      }
    }
  }, [list])

  useEffect(() => {
    if (loading) {
      return
    }
    localStorage.setItem(groupType, JSON.stringify(openGroupMap))
  }, [openGroupMap])

  const handleGroup = (appList?: T[]) => {
    const arr = cloneDeep(groupList || [])
    const noGroup = {
      applicationType: '未分组',
      id: noGroupId,
      name: '未分组',
      sort: '999999999',
      workspaceId: 'currentWorkspaceId',
      children: [] as T[],
    }
    const list = appList || originList || []
    list.forEach(item => {
      const targetIndex = arr.findIndex(i => i.id === (item as any)[groupIdKey])
      if (targetIndex >= 0) {
        arr[targetIndex].children = [
          ...(arr[targetIndex]?.children || []),
          item,
        ] as any
      } else {
        noGroup.children.push(item)
      }
    })
    setList([...arr?.sort((a, b) => a.sort - b.sort), noGroup] as GroupType[])
  }

  const handleJoinToGroup = async (groupId: string, appId: string) => {
    const task =
      applicationType === 'DATASET'
        ? updateDatastore({
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            partition_id: appId,
            partition_group_id: groupId,
          })
        : setAppToGroupFn({
            id: appId,
            AppGroup: { appGroupId: groupId },
          })
    // 不在useEffect中监听originList调用handleGroup方法会有无限循环调用的问题
    task.then(() => refresh().then(handleGroup))
  }

  const handleDragStart = useCallback(
    ({
      DragType,
      item,
      e,
    }: {
      DragType: 'sort' | 'join' | null
      item: any
      e: React.DragEvent<any>
    }) => {
      e.dataTransfer.setData('sourceItem', JSON.stringify(item))
      setPhantomItem(item)
      setDragItem(item)
      setDragType(DragType)
      setTimeout(() => setPhantomItem({}), 1)
      e.stopPropagation()
    },
    [],
  )

  const reSet = () => {
    setDragItem({})
    setDragType(null)
    setDragOverItem({})
  }

  const handleDrop = async (
    e: React.DragEvent<HTMLDivElement>,
    targetItem: GroupType,
  ) => {
    const sourceItem = JSON.parse(e.dataTransfer.getData('sourceItem'))
    if (DragType === 'join') {
      if (
        noGroupId === targetItem.id &&
        list[list.length - 1]?.children?.find(
          item => item.id === sourceItem[appIdKey],
        )
      ) {
        reSet()
        return
      }
      sourceItem[groupIdKey] !== targetItem.id &&
        (await handleJoinToGroup(targetItem.id, sourceItem[appIdKey]))
    }
    if (DragType === 'sort' && sourceItem.id !== targetItem.id) {
      const targetIndex = list.findIndex(i => i.id === targetItem.id)
      const sourceIndex = list.findIndex(i => i.id === sourceItem.id)
      const arr = cloneDeep(list)
      arr.splice(sourceIndex, 1)
      arr.splice(targetIndex - 1 < 0 ? 0 : targetIndex - 1, 0, sourceItem)
      await sortGroupFn(
        arr?.map((item, index) => ({ id: item.id, sort: index })),
      )
    }
    reSet()
  }

  useEffect(() => {
    if (!groupList) {
      return
    }
    handleGroup()
  }, [groupList, originList])

  const DropdownMenu = ({
    item,
    subMenu,
    createAppToAgent,
    handleReName,
    title,
    open,
    openChange,
  }: DropdownMenuProps) => {
    return (
      <Dropdown
        trigger={['hover']}
        rootClassName='DropdownWrapper'
        onOpenChange={e => (!e ? openChange('') : openChange(item.id))}
        open={open}
        menu={{
          expandIcon: (
            <IconFont name='left-arrow' className='text-font_1 text-10px' />
          ),
          items: [
            {
              label: `新建${title}到分组`,
              className: classNames(
                'h-32px !px-10 [&_.ant-dropdown-menu-submenu-title]:!-ml-10 [&_.ant-dropdown-menu-submenu-title]:flex [&_.ant-dropdown-menu-submenu-title]:items-center [&_.ant-dropdown-menu-submenu-title]:h-full',
                { '!pr-0': !!subMenu },
              ),
              key: '1',
              children: subMenu,
              onClick: ({ key, domEvent }) => {
                domEvent.stopPropagation()
                createAppToAgent(key)
                openChange('')
              },
            },
            {
              type: 'divider',
              className: '!bg-line !bg-op-60 !-ml-8 !-mr-8 !my-8',
            },
            {
              label: '重命名',
              className: 'h-32px !px-10',
              key: '2',
              onClick: ({ domEvent }) => {
                domEvent.stopPropagation()
                handleReName(item)
                openChange('')
              },
            },
            {
              label: '删除',
              className: '!text-error h-32px !px-10',
              key: '3',
              onClick: ({ domEvent }) => {
                domEvent.stopPropagation()
                modal.open({
                  title: `删除后，该分组${title}会被移入未分组中`,
                  cancelText: '取消',
                  okText: '删除',
                  okButtonProps: { danger: true },
                  onOk: () => {
                    openChange('')
                    item.id && deleteGroupFn(item.id).then(modal.close)
                  },
                })
              },
            },
          ],
        }}
      >
        <MoreDiv
          onClick={e => {
            e.preventDefault()
            e.stopPropagation()
          }}
          className='MoreDiv'
        >
          <IconFont className='text-16px text-bg_3 text-op-60' name='gengduo' />
        </MoreDiv>
      </Dropdown>
    )
  }

  const removeToGroup = (app: ApplicationBodyType): ItemType =>
    list.length <= 1
      ? null
      : {
          label: '移动至分组',
          expandIcon: (
            <IconFont name='left-arrow' className='color-#8D8D99 text-10px' />
          ),
          className:
            '[&>.ant-dropdown-menu-submenu-title]:flex [&>.ant-dropdown-menu-submenu-title]:h-32px [&>.ant-dropdown-menu-submenu-title]:items-center [&>.ant-dropdown-menu-submenu-title]:gap-26 [&>.ant-dropdown-menu-submenu-title]:!pr-6',
          style: { height: 32 },
          key: '1',
          onClick: ({ domEvent }) => {
            domEvent.stopPropagation()
            domEvent.preventDefault()
          },
          children: list.map((i, index) => ({
            key: i.id,
            label: <div>{i.name}</div>,
            style: {
              background:
                (app as any)[groupIdKey] === i.id
                  ? 'rgba(123, 97, 255, 0.08)'
                  : '',
              padding: '10px 28px 10px 10px',
              marginBottom: list.length - 1 === index ? 0 : 4,
            },
            onClick: async e => {
              e.domEvent.stopPropagation()
              e.domEvent.preventDefault()
              if ((app as any)[groupIdKey] !== i.id) {
                handleJoinToGroup(i.id, (app as any)[appIdKey])
              }
            },
          })),
        }

  return {
    list,
    groupLoading: loading,
    createGroup: createGroupFn,
    updateGroup: updateGroupFn,
    handleDragStart,
    handleDrop,
    DragType,
    setDragType,
    dragItem,
    setDragItem,
    dragOverItem,
    setDragOverItem,
    phantomItem,
    DropdownMenu,
    removeToGroup,
    handleGroup,
    reSet,
    openGroupMap,
    setOpenGroupMap,
    openAllGroup,
  }
}
