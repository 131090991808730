import { request, datasetRequest } from '../../client'
import type { STSTokenResponse } from './type'

const version = 'v1'

export function getSTSToken() {
  return request.get<STSTokenResponse>(`${version}/oss_key`)
}

// 知识库服务的oss_key后续与主站服务不是同一个权限因此需要牵出
export function getDatasetSTSToken() {
  return datasetRequest.get<STSTokenResponse>(`${version}/dataset/oss_key`)
}
