import type { HttpDefine } from '@bty/http-client'
import { BusinessError } from './error'

const commonWarningMessage = '系统开小差了，请稍后再试'

export async function onResponse(response: Response, config: HttpDefine) {
  const { customErrorMessage } = config

  const { status } = response

  // 仅校验 json 数据格式
  if (!response.headers.get('content-type')?.includes('application/json')) {
    return
  }

  const jsonData = await response.clone().json()
  const { success, message, code } = jsonData

  if (success !== false) return

  const error = new BusinessError(
    customErrorMessage || message || commonWarningMessage,
    code,
    status,
  )

  throw error
}
