import NiceModal from '@ebay/nice-modal-react'
import type { Database } from '@apis/database/type'
import { Button, IconFont, Modal } from '@/components'
import { DatabaseSelectItem } from './DatabaseSelectItem'

interface DatabaseSelectModalProps {
  data?: Database[]
  onOk: (database: Database) => void
}

function InternalDatabaseSelectModal({ data, onOk }: DatabaseSelectModalProps) {
  const modal = NiceModal.useModal()

  return (
    <Modal
      styles={{
        body: {
          padding: 0,
        },
      }}
      open={modal.visible}
      width={1120}
      footer={null}
      closable={false}
      centered
      destroyOnClose
      onCancel={modal.hide}
    >
      <div className='flex justify-between items-center text-16px/24px font-medium text-font px-16px py-12px border-bottom after:border-[rgba(225,225,229,0.6)]'>
        选择数据库
        <Button
          className='text-16px !p-0 !w-24px !h-24px border-none'
          icon={<IconFont name='guanbi' />}
          onClick={modal.hide}
        />
      </div>
      <div className='px-24 py-8 max-h-672px min-h-672px overflow-auto'>
        {data?.map(el => (
          <DatabaseSelectItem
            key={el.id}
            name={el.title}
            description={el.description}
            icon={el.icon}
            iconColor={el.icon_color}
            onClick={() => {
              onOk(el)
              modal.hide()
            }}
          />
        ))}
      </div>
    </Modal>
  )
}

export const DatabaseSelectModal = NiceModal.create(InternalDatabaseSelectModal)
