import type { Edge } from 'reactflow'
import type { FieldItem } from '@bty/global-types/agent'
import type {
  FlowStatus,
  StartNodeTypes,
  FlowNode,
  FlowInputSchema,
} from '../flow/type'

export interface executeDetailres {
  batch_result: {
    fail: number
    total: number
    execute: number
    success: number
  }
  created: string
  deleted: boolean
  download_file_url: string
  flow_id: string
  id: number
  state: number
  task_ids: string[]
  updated: string
  upload_file_url: string
  user_id: number
}

export interface LogItem {
  consume_points: number
  created_at: string
  updated_at: string
  flow_id: string
  durationTime: number
  show_name: string
  run_type: string
  run_result: {
    status: string
    actionOutputs: Record<string, any>
    durationTime: number
    message: {
      step_err_info: string
      step_name: string
    }
  }
  input?: string
  run_status: FlowStatus
  start_time: string
  user_name: string
  finish_time: string
  task_id: string
  test_data_id: string
  test_result_id: string
  version_id: string
  user_id: string
  first_node_type?: StartNodeTypes
  flow_node_type: StartNodeTypes
  form_config?: FieldItem[]
}

export interface logType {
  total: number
  page_no: number
  page_size: number
  data: LogItem[]
}

export interface timRuning {
  name: string
  cron: string
  type: number
  min: string
  hour: string
  week: string
  day: string
  flow_id: string
  run_param: Record<string, any>
}

export enum state_enum {
  RUNNING = 1,
  STOP = 2,
}

export enum log_state_enum {
  SUCCEEDED = 'SUCCEEDED',
  FAILED = 'FAILED',
  TO_BE_RUN = 'TO_BE_RUN',
}

export interface scheduledType {
  key: number
  log_state: log_state_enum
  state: state_enum
  task_id: string
  task_name: string
}

type envtype = 'TESTING' | 'ONCE_RUN' | 'BATCH_RUN' | 'CORN_RUN'

export enum SkillFlowViewMode {
  RAW = 'raw',
  FORM = 'form',
}

export interface nodeConfigType {
  version_id: string
  version_no: number
  first_node_type: StartNodeTypes
  description: string
  form_config: FieldItem[]
  config?: {
    nodes: FlowNode[]
    edges: Edge[]
  }
  inputs_properties?: {
    inputsViewMode: SkillFlowViewMode
    formWelcome: string
  }
  skill_desc?: string
  desc_schema?: {
    description: string
    inputs: FlowInputSchema[]
  }
}

export interface singleExecuteType {
  flow_id: string
  task_id: string
  run_result: string
  durationTime: number
  message: string & {
    step_err_info?: string
    step_name?: string
  }
  run_type: string
  status: FlowStatus
  code?: number
}

export interface ScheduledTaskOptionsType {
  config: {
    day: string
    hour: string
    min: string
    week: string
  }
  created: string
  cron: string
  deleted: boolean
  flow_id: string
  id: number
  job_id: string
  name: string
  run_param: Record<string, any>
  state: number
  task_id: string
  type: number
  updated: string
  user_id: number
}

export interface scheduledTaskLog {
  finish_time: string
  id: number
  output: string
  run_status: string
  task_id: string
}

export interface scheduledTaskDetailType {
  next_execution_time: string
  run_param: Record<string, any>
  run_record: scheduledTaskLog[]
  run_rule: string
  task_name: string
  state: state_enum
}

export interface singleExecuteParams {
  flowId: string
  env: envtype
  versionId: string
  inputData: Record<string, any>
}
