import { useMemo, useRef, useEffect, useState, useContext } from 'react'
import { Switch, Form, Tooltip } from 'antd'
import { useRequest } from 'ahooks'
import { assign, get, isEmpty, isNil, pick } from 'lodash-es'
import { PartitionCategoryType } from '@apis/datastore/type'
import type { InnerNodePanelProps } from '@/features/nodes/base'
import type { JsonFormConfig } from '@/features/nodes/components'
import { JsonForm } from '@/features/nodes/components'
import { useNodeMetaStore } from '@/store/nodeMeta.ts'
import { MemoryTagSelect } from '@/features/nodes/memory/MemoryTagSelect.tsx'
import { MemoryOutputTypeSelect } from '@/features/nodes/memory/MemoryOutputTypeSelect.tsx'
import { VariableRegex } from '@/constants/common.ts'
import { Button, IconFont, Segmented } from '@/components'
import { DocumentSelect } from '../insert-memory/DocumentSelect'
import { DebugResultPanelContext } from '../base/DebugResultPanel'
import { useDynamicValues } from '../hooks/useDynamicValues'
import { DynamicForm } from '../base/components/DynamicForm'
import useStartFileNodeVariableKeyList from '../hooks/useStartFileNodeVariableKeyList'
import { ShowKnowledgeReferenceComponent } from '@/features/agent/components/ShowKnowledgeReferenceComponent'
import { KnowledgeContentExample } from '../components/KnowledageContentExample'
import { CodeEditor } from '@/features/editor/CodeEditor'
import { DatasetFileSelect } from '../components/DatasetSelect/DatasetFileSelect'
import { SearchRangeSelect } from './components/SearchRangeSelect'
import OperationSelect from './components/OperationSelect'
import SimilaritySlider from './components/SimilaritySlider'
import ResultReRank from './components/ResultReRank'
import MaxResultSlider from './components/MaxResultSlider'
import SearchContent from './components/SearchContent'
import { RankingStrategy } from './constants'
import { SearchScopeType } from '.'
import type { KnowledgeDataValue, KnowledgeNodeData } from '.'

export function KnowledgeNodePanel(
  props: InnerNodePanelProps<KnowledgeNodeData>,
) {
  const { data, activatedNodeId, variables, startNodeFormItemType = [] } = props
  const datasetList = useNodeMetaStore(state => state.datasetList)
  const datasetTagsMap = useNodeMetaStore(state => state.datasetTagsMap)
  const getDatasetTags = useNodeMetaStore(state => state.getDatasetTags)
  const selectPopupRef = useRef<any>(null)
  const [form] = Form.useForm()

  const [dataState, setDataState] = useState(props.data)

  const [operationType, setOperationType] = useState<'create' | 'update'>(
    dataState.inputs?.file_name ? 'create' : 'update',
  )
  const searchScopeVal = Form.useWatch(['inputs', 'searchScope'], form)
  const memoryVal = Form.useWatch(['inputs', 'memory'], form)
  const rankingStrategyVal = Form.useWatch(['inputs', 'rankingStrategy'], form)
  const memoryTypeVal = Form.useWatch(['inputs', 'memoryType'], form)
  const tagsVal = Form.useWatch(['inputs', 'tags'], form)

  const { fileVariableKeyList } = useStartFileNodeVariableKeyList(
    startNodeFormItemType,
    variables,
  )

  const fetchDatasetTags = async () => {
    if (dataState.inputs?.memory) {
      if (!VariableRegex.test(String(dataState.inputs?.memory))) {
        getDatasetTags(dataState.inputs.memory, true)
      }
    }
    return true
  }

  useRequest(fetchDatasetTags, { refreshOnWindowFocus: true })

  const handleFilterTags = (
    tags?: Array<{ tag: string; file_num: number }>,
  ) => {
    const inputs = dataState.inputs
    if (
      inputs?.memory &&
      inputs.tags?.length &&
      inputs?.searchScope === SearchScopeType.Tags
    ) {
      const datasetTags = tags || datasetTagsMap?.[inputs.memory] || []
      const newTags = inputs.tags.filter(
        tag =>
          VariableRegex.test(tag) ||
          datasetTags.findIndex(i => i.tag === tag) !== -1,
      )
      return newTags
    }
  }

  useEffect(() => {
    const inputs = dataState.inputs
    if (
      inputs?.memory &&
      inputs.tags?.length &&
      datasetTagsMap?.[inputs.memory]
    ) {
      const newTags = handleFilterTags()
      setDataState({
        ...dataState,
        inputs: {
          ...dataState.inputs,
          tags: newTags,
        } as KnowledgeDataValue,
      })
    }
  }, [datasetTagsMap])

  const handleFormChange = (params: Record<string, any>) => {
    const formData = form.getFieldsValue()
    const newData: KnowledgeNodeData = {
      ...formData,
      ...data,
      inputs: {
        ...data.inputs,
        ...formData.inputs,
        ...params,
      },
    }
    const inputs = formData.inputs as KnowledgeDataValue
    if (inputs.memoryType === 'insertMemory') {
      newData.inputs = (
        operationType === 'create'
          ? pick(inputs, ['memoryType', 'memory', 'file_name', 'content'])
          : pick(inputs, ['memoryType', 'memory', 'file_id', 'content'])
      ) as KnowledgeDataValue
    } else {
      newData.inputs = pick(inputs, [
        'memoryType',
        'memory',
        'tags',
        'hitStrategy',
        'searchContent',
        'maxResultNum',
        'rankingStrategy',
        'searchScope',
        'similarity',
        'show_knowledge_url',
        'outputType',
        'max_files_num',
        'group_files_num',
        'fileIds',
        'ai_prompt',
      ]) as KnowledgeDataValue
    }
    newData.inputs.partition_id = data?.inputs?.memory
    props.onSaveChange(newData)
  }

  const {
    registerTriggerNode,
    run,
    loading: btnLoading,
    variables: debugVariables,
    setVariables,
  } = useContext(DebugResultPanelContext)

  const val = useDynamicValues({
    nodeId: activatedNodeId!,
    data,
    values: form?.getFieldsValue(),
    variables: variables?.map(item => item.label),
    ruleCallback: (value: InnerNodePanelProps<any>['data']) => {
      const message =
        dataState.inputs?.memoryType === 'insertMemory'
          ? [{ content: get(value, 'inputs.content') }]
          : [
              { content: get(value, 'inputs.tags') },
              { content: get(value, 'inputs.searchContent') },
            ]
      return message
    },
  })

  const { usedKeyListByNodeId } = val

  const checkByBeforeInvoke = async () => {
    const val: any = {}
    usedKeyListByNodeId.forEach(item => {
      if (isNil(debugVariables[item])) {
        val[item] = ''
      }
    })

    let time = 0
    if (!isEmpty(val)) {
      await setVariables(val, true)
      time = 600
    }
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('')
      }, time)
    })
  }

  const runBySingleStep = async () => {
    await checkByBeforeInvoke()
    await form?.validateFields()
    run({
      ...data,
      inputs:
        data.inputs?.memoryType === 'insertMemory'
          ? {
              memoryType: data.inputs?.memoryType,
              memory: data.inputs?.memory,
              file_id: data.inputs?.file_id,
              content: data.inputs?.content,
            }
          : {
              memoryType: data.inputs?.memoryType,
              memory: data.inputs?.memory,
              hitStrategy: data.inputs?.hitStrategy,
              maxResultNum: data.inputs?.maxResultNum,
              outputType: data.inputs?.outputType,
              rankingStrategy: data.inputs?.rankingStrategy,
              searchContent: data.inputs?.searchContent,
            },
    })
  }

  useEffect(() => {
    registerTriggerNode(
      () => (
        <Button
          loading={btnLoading}
          type='primary'
          className='text-12px bg-op-60  !h-32px'
        >
          运行
        </Button>
      ),
      () => runBySingleStep(),
    )
  }, [btnLoading])

  const onDynamicValuesChange = (key: string, value: any) => {
    const usedVal: any = {}
    usedKeyListByNodeId?.forEach(item => {
      usedVal[item] = debugVariables[item] || ''
    })
    const values = assign({}, usedVal, { [key]: value })
    setVariables(values, false)
  }

  const isSelectedQADataSet = useMemo(() => {
    if (memoryTypeVal === 'insertMemory' && memoryTypeVal) {
      const dataset = datasetList.find(item => item.partition_id === memoryVal)
      if (dataset?.partition_category === PartitionCategoryType.QA) {
        return true
      }
    }
    return false
  }, [datasetList, memoryTypeVal])

  const operationTypeFormItem = {
    render: () => (
      <Segmented
        value={operationType}
        options={[
          {
            label: (
              <Tooltip
                title='将数据内容更新在某个文件中'
                getPopupContainer={() =>
                  selectPopupRef.current ?? document.body
                }
                destroyTooltipOnHide
              >
                更新文件
              </Tooltip>
            ),
            value: 'update',
          },
          {
            label: (
              <Tooltip
                title='根据文件名、数据内容新建文件'
                getPopupContainer={() =>
                  selectPopupRef.current ?? document.body
                }
                destroyTooltipOnHide
              >
                新建文件
              </Tooltip>
            ),
            value: 'create',
          },
        ]}
        size='small'
        onChange={value => {
          setOperationType(value as 'create' | 'update')
          let formValue
          if (value === 'create') {
            form.setFieldValue(['inputs', 'file_id'], null)
            formValue = { file_id: null }
          } else {
            form.setFieldValue(['inputs', 'file_name'], null)
            formValue = { file_name: null }
          }
          handleFormChange(formValue)
        }}
      />
    ),
  }

  const inputFileNameFormItem = {
    label: '文件名称',
    name: ['inputs', 'file_name'],
    required: true,
    rules: [{ required: true, message: '请输入文件名称' }],
    render: () => (
      <CodeEditor
        className='ace-gray h-32px flex-1'
        wrapEnabled={false}
        width='100%'
        setOptions={{ maxLines: 1 }}
        variableTipsContainer={props.nodeElement}
        variables={props.variables}
        placeholder='输入新增文件的名称，可通过变量设置'
        singleLine
        onChange={value => handleFormChange({ file_name: value })}
      />
    ),
  }

  const selectFileNameFormItem = {
    label: '数据文件',
    name: ['inputs', 'file_id'],
    required: true,
    hidden: !memoryVal,
    rules: [{ required: true, message: '请选择数据文件' }],
    render: () => (
      <DocumentSelect
        memoryId={memoryVal}
        onChange={e => handleFormChange({ file_id: e })}
      />
    ),
  }

  const fileContentFormItem = {
    label: '数据内容',
    name: ['inputs', 'content'],
    required: true,
    type: 'TextEditor' as const,
    rules: [{ required: true, message: '内容不能为空' }],
    tooltip: '文档库仅支持纯文本数据；问答库仅支持 JSON 格式',
    className: '!mb-0',
    componentProps: {
      placeholder: isSelectedQADataSet
        ? '填写或用变量输入 QA 问答的 JSON 数据'
        : '输入上下文的变量或纯文本数据，并将变量的数据或者纯文本数据导入知识库中',
      variables: props.variables,
      variableTipsContainer: props.nodeElement,
      onChange: (e: string) => {
        handleFormChange({ content: e })
      },
      innerTooltipContent: isSelectedQADataSet && <KnowledgeContentExample />,
    },
  }

  const searchListForm = [
    {
      noStyle: true,
      name: ['inputs'],
      render: () => {
        const errorContent = form.getFieldError(['inputs', 'searchContent'])
        return (
          <SearchContent
            nodeElement={props.nodeElement}
            variables={props.variables}
            errorContent={errorContent}
          />
        )
      },
    },
    {
      label: (
        <div className='font-500 text-12px flex items-center'>
          查询范围
          <span
            className='text-8px rounded-8px color-#fff ml-4px flex items-center justify-center h-14px w-26px'
            style={{
              background: 'linear-gradient(270deg, #FF3A5A 0%, #FF69BB 100%)',
            }}
          >
            new
          </span>
        </div>
      ),
      name: ['inputs'],
      render: () => {
        return <SearchRangeSelect popupContainerRef={selectPopupRef} />
      },
    },
    {
      noStyle: true,
      hidden: !(memoryVal && searchScopeVal === SearchScopeType.Tags),
      render: () => (
        <>
          <div className='flex items-center text-12px font-500 mb-12'>
            <div className='flex items-center'>选择标签</div>
          </div>
          <Form.Item
            noStyle
            shouldUpdate={(prev, curr) =>
              prev.inputs.memory !== curr.inputs.memory
            }
          >
            {({ getFieldValue }) => {
              const memory = getFieldValue(['inputs', 'memory'])
              const tagsList = datasetTagsMap[memory] ?? []
              return (
                <div className='mb-16px'>
                  <MemoryTagSelect
                    tags={tagsList}
                    allEnable={true}
                    onChange={e => {
                      const res = e
                      form.setFieldValue(
                        ['inputs', 'tags'],
                        !e?.length ? null : e,
                      )
                      handleFormChange({ tags: res })
                    }}
                    value={tagsVal || []}
                    variables={props.variables}
                    variableTipsContainer={props.nodeElement as HTMLElement}
                  />
                </div>
              )
            }}
          </Form.Item>
        </>
      ),
    },
    {
      label: '选择文件',
      name: ['inputs', 'fileIds'],
      required: true,
      hidden: !(memoryVal && searchScopeVal === SearchScopeType.SpecifyFileIds),
      rules:
        memoryVal && searchScopeVal === SearchScopeType.SpecifyFileIds
          ? [{ required: true, message: '请选择数据文件' }]
          : [],
      render: () => {
        return (
          <DatasetFileSelect
            memoryId={memoryVal}
            onChange={e => {
              form.setFieldValue(['inputs', 'fileIds'], e)
              handleFormChange({ fileIds: e })
            }}
            variables={props.variables}
            variableTipsContainer={props.nodeElement as HTMLElement}
          />
        )
      },
    },
    {
      label: '最大结果数',
      name: ['inputs', 'maxResultNum'],
      tooltip:
        '从知识库中查询 X 条结果（ X 等于你设置的数字，X 的最大值为30） 建议 X 值不超过 10，避免超出大模型支持的最大 Token 数量',
      render: () => <MaxResultSlider />,
    },
    {
      noStyle: true,
      name: ['inputs', 'rankingStrategy'],
      render: () => <ResultReRank />,
    },
    {
      label: '最低相似度',
      hidden: !(rankingStrategyVal === RankingStrategy.ON),
      name: ['inputs', 'similarity'],
      tooltip:
        '设定的一个阈值，过滤掉相似度低于此值的匹配结果，一般0.4属于适中，0.5以上属于精确。',
      render: () => <SimilaritySlider />,
    },
    {
      noStyle: true,
      name: ['inputs', 'show_knowledge_url'],
      render: () => {
        const showKnowledgeUrl = form.getFieldValue([
          'inputs',
          'show_knowledge_url',
        ])
        return (
          <div className='flex flex-col mb-16px'>
            <div className='flex flex-1 items-center'>
              <div className='font-500 text-12px'>展示知识原文下载链接</div>
              <Tooltip title='开启后，Flow 的使用者可以通过日志中的链接下载原文件。建议保持关闭状态，以避免知识泄漏风险。'>
                <IconFont
                  className='ml-[4px] mr-8px'
                  style={{ color: 'rgba(141, 141, 153, 0.4)' }}
                  name='jieshishuimeng'
                />
              </Tooltip>
              <Switch
                size='small'
                value={showKnowledgeUrl}
                checked={showKnowledgeUrl}
                onChange={e => {
                  form.setFieldValue(['inputs', 'show_knowledge_url'], e)
                  handleFormChange({ show_knowledge_url: e })
                }}
              />
            </div>
            {showKnowledgeUrl && (
              <ShowKnowledgeReferenceComponent business='flow' />
            )}
          </div>
        )
      },
    },
    {
      label: '输出设置',
      required: true,
      className: 'important:mb-0',
      name: ['inputs', 'outputType'],
      render: () => (
        <MemoryOutputTypeSelect
          onChange={e => {
            form.setFieldValue(['inputs', 'outputType'], e)
            handleFormChange({ outputType: e })
          }}
        />
      ),
    },
  ]

  const list = useMemo<JsonFormConfig[]>(() => {
    const returnValue = [
      {
        noStyle: true,
        name: ['inputs'],
        render: () => <OperationSelect />,
      },
      {
        label: '知识库',
        name: ['inputs', 'memory'],
        required: true,
        rules: [
          {
            required: true,
            message:
              memoryTypeVal === 'insertMemory'
                ? '请选择知识库'
                : '请选择知识库或输入表示知识库ID的变量',
          },
        ],
        type: 'DatasetSelect',
        componentProps: {
          variables: memoryTypeVal === 'insertMemory' ? [] : props.variables,
          variableTipsContainer: props.nodeElement,
          onChange: (val: number) => {
            if (val && !VariableRegex.test(String(val))) {
              getDatasetTags(val, true).then(tags => {
                const newTags = handleFilterTags(tags)
                form.setFields([
                  { name: ['inputs', 'fileIds'], value: [] },
                  { name: ['inputs', 'file_id'], value: undefined },
                  { name: ['inputs', 'tags'], value: newTags },
                ])
                // 切换知识库时，清空所有的选择文件
                handleFormChange({
                  file_id: undefined,
                  fileIds: [],
                  memory: val,
                  tags: newTags,
                })
              })
            } else {
              handleFormChange({ memory: val })
            }
          },
        },
      },
    ] as JsonFormConfig[]

    if (memoryTypeVal !== 'insertMemory') {
      returnValue.push(...searchListForm)
      return returnValue
    }

    if (memoryVal) {
      returnValue.push(operationTypeFormItem)
      if (operationType === 'create') {
        returnValue.push(inputFileNameFormItem, fileContentFormItem)
      } else {
        returnValue.push(selectFileNameFormItem, fileContentFormItem)
      }
    }

    return returnValue
  }, [
    datasetList,
    props.variables,
    props.nodeElement,
    memoryTypeVal,
    searchScopeVal,
    memoryVal,
    rankingStrategyVal,
    tagsVal,
    operationType,
    form,
    isSelectedQADataSet,
  ])
  return (
    <div
      ref={selectPopupRef}
      className='p-16 w-full'
      onWheel={e => e.stopPropagation()}
    >
      <JsonForm form={form} list={list} />
      <div className='mt-8'>
        <DynamicForm
          usedKeyList={usedKeyListByNodeId}
          dynamicValues={debugVariables}
          onValueChange={onDynamicValuesChange}
          fileVariableKeyList={fileVariableKeyList}
        />
      </div>
    </div>
  )
}
