import type { Edge } from 'reactflow'
import type { LogItem } from '../run/type'

export enum StartNodeTypes {
  Form = 'form',
  Webhook = 'webhook',
}

export enum FlowStatus {
  SUCCEEDED = 'SUCCEEDED',
  FAIL = 'FAILED',
}

export enum FlowVersionStatusTypes {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum ActionTypesForNode {
  TRIGGER = 'TRIGGER',
  CODE = 'CODE',
  INTEGRATION = 'INTEGRATION',
  LOOP = 'LOOP',
  BRANCH = 'BRANCH',
  END = 'END',
  MISSING = 'MISSING',
}

export interface FlowNode {
  id: string
  isEnable?: boolean
  actionType: ActionTypesForNode
  relation: Record<string, any> | null
  type: string
  name: string
  layout: {
    width: number
    height: number
    position: {
      x: number
      y: number
    }
  }
  data: {
    type: StartNodeTypes | null
    inputs: Record<string, any>
    formConfig?: any
  }
}

export enum FLOW_STATUS {
  NO_LOCK = 0,
  COPILOT_LOCK = 1,
  BATCH_TEST_LOCK = 2,
}

export interface FlowData {
  icon?: string
  color?: string
  flow_id: string
  flow_name: string
  created_by: string | null
  updated_by: string | null
  created_at: string
  updated_at: string
  app_id: string
  workspace_id: string
  description: string | null
  version_id: string
  version_status: FlowVersionStatusTypes
  config: {
    nodes: FlowNode[]
    edges: Edge[]
  }
  form_config: any
  is_published: boolean
  is_modified: boolean
  is_enable: boolean
  flow_lock: FLOW_STATUS
  max_version_no: number
}

export interface FlowUpdateParams {
  flowId: string
  appId: string
  flowName?: string
  description?: string
  config?: {
    nodes: FlowNode[]
    edges: Edge[]
    // 用于节点数据校验
    node_fields?: Record<
      string,
      {
        name: string
        fields: {
          name: string
          desc: string
          required: boolean
        }[]
      }
    >
  }
}

export interface FlowUpdateResponse {
  flowId: string
  appId: string
  flowName?: string
  description?: string
  config?: {
    nodes: FlowNode[]
    edges: Edge[]
  }
  is_modified: boolean
}

export enum FlowExecuteEnv {
  TESTING = 'TESTING', // 调试
  ONCE_RUN = 'ONCE_RUN', // 单次执行
  BATCH_RUN = 'BATCH_RUN', // 批量执行
  CORN_RUN = 'CORN_RUN', // 定时执行
  BATCH_TEST = 'BATCH_TEST', // 批量测试
  API = 'API',
  WEBHOOK = 'WEBHOOK',
  CHATBOT = 'CHATBOT',
  AGENT = 'AGENT',
}

export interface FlowExecuteParams {
  flowId: string
  versionId: string
  env: FlowExecuteEnv
  inputData: any
}

export type FlowExecuteResponse =
  | {
      flow_id: string
      durationTime: number
      message: {
        step_name?: string
        step_error_info?: string
      }
      run_result: unknown
      run_type: FlowExecuteEnv
      status: FlowStatus.FAIL
      task_id: string
    }
  | {
      flow_id: string
      durationTime: number
      message: string
      run_result: string
      run_type: FlowExecuteEnv
      status: FlowStatus.SUCCEEDED
      task_id: string
    }

export interface FlowPublishParams {
  draftVersionId: string
}

export interface IterationResult {
  index: number
  durationTime: number
  status: FlowStatus
  output: Record<string, NodeRunResult | LogItem>
}

export interface NodeRunResult {
  actionType: string
  durationTime: number
  input: any
  output: string | Record<string, any> | Array<IterationResult>
  message: string
  status: FlowStatus
  type: string
}

export interface FlowPublishResponse {
  config?: {
    nodes: FlowNode[]
    edges: Edge[]
  }
  created_at: string
  created_by: string | number
  flowId: string
  id: string | number
  is_modified: boolean
  updated_at: string
  updated_by: string | number
  version_id: string
  version_no: number
  version_status: FlowVersionStatusTypes
}

export interface IUploadFileInfo {
  file_type: string
  upload_record_id: string
  byte_size: number
  file_name: string
}

export interface FlowInputSchema {
  key: string
  description: string
  filed_type: string
  required: boolean
  default_value: string
}

export interface FlowSchema {
  description: string
  inputs: FlowInputSchema[]
  output: { key: string; value: string }
}

export interface ExportFlowLogsParams {
  flowId: string
  userName?: string
  runTypes?: FlowExecuteEnv[]
  runStatus?: FlowStatus
  startTime?: string
  endTime?: string
}

export interface ITemplateItem {
  category: string
  id: string
  icon: string
  color: string
  name: string
  description: string
  thumbnail: string
  thumbnail_width: number
  related_blog: string
  source_id: string
  source_workspace_id: string
}

export interface ISupportFileConfig {
  name: string
  type: string
  support: string[]
  size: number
  extension: string[]
}

export interface IPluginItem {
  function_id: string
  name: string
  description: string
  display_name: string
  icon: string
  color: string
  package_name: string
  output: {
    comment: string
    ip_label: string
  }[]
  form_config: {
    description: string
    example: unknown
    type: string
    /**
     * 是否选择模型
     */
    llm_model?: boolean
    label: string
    required: boolean
    placeholder: string
    variableName: string
    value?: string
    variableType: string
    layout?: 'normal' | 'inline'
    tooltip?: string
    hasExpand?: boolean
  }[]
  action_type: 'INTEGRATION'
  type: 'PLUGIN'
  plugin_label: string
}

export interface ISubFlowItem {
  app_id: string
  color: string
  description: string
  flow_id: string
  flow_name: string
  form_config: Record<string, any>[]
  icon: string
  workspace_id: string
  visible: boolean
}
