import { useParams } from 'react-router-dom'
import { useContext } from 'react'
import { PartitionCategoryType } from '@apis/datastore/type'
import Documents from '@/features/datastore/Documents.tsx'
import { DataStoreContext } from '@/pages/datastores/provider/DataStoreProvider.tsx'

export default function DocumentsPage() {
  const { id } = useParams()
  const { dataStoreInfo } = useContext(DataStoreContext)

  if (!dataStoreInfo) return null

  return (
    <Documents
      datastoreHashPath={dataStoreInfo?.oss_path ?? ''}
      datastoreName={dataStoreInfo?.partition_name}
      datastoreType={
        dataStoreInfo?.partition_category ?? PartitionCategoryType.Document
      }
      openDocumentInNewWindow={false}
      id={Number(id)}
      showExtraSearch
    />
  )
}
