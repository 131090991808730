import { request } from '../../client'
import type {
  AppMonitorOverviewBaseParams,
  AppMonitorOverviewResponse,
  AppMonitorOverviewParams,
  AppUsageItem,
  AppUsageRankingItem,
  AppUsageRankingParams,
  ErrorRecordItem,
  ListPageParams,
  ListResponse,
  OvertimeRecordItem,
  AgentChatRecordsParams,
  AgentChatRecordsResponse,
  ChatRecordsParams,
  AppStabilityRequest,
  AppStabilityType,
  ChatRecordItem,
  IrlTrendRequest,
  IrlTrendType,
  UsageTrendRequest,
  UsageTrendType,
  UserUsageRankRequest,
  WorkspaceUsageOverview,
  UserUsageRankType,
} from './type'

export function getAppMonitorDailyOverview(
  params: AppMonitorOverviewBaseParams,
) {
  return request.get<AppMonitorOverviewResponse>(
    '/v1/app_monitor/usage-daily-overview',
    {
      query: params,
      querySerializer: {
        indices: false,
      },
    },
  )
}

export function getAppMonitorOverview(params: AppMonitorOverviewParams) {
  return request.get<AppMonitorOverviewResponse>(
    '/v1/app_monitor/usage-overview',
    {
      query: params,
      querySerializer: {
        indices: false,
      },
    },
  )
}

// 用量明细
export function getAppMonitorUsageDetail(
  params: ListPageParams<AppMonitorOverviewParams>,
) {
  return request.get<ListResponse<AppUsageItem>>(
    '/v1/app_monitor/usage-detail',
    {
      query: params,
      querySerializer: {
        indices: false,
      },
    },
  )
}

// 空间用量明细
export function getWorkspaceMonitorUsageDetail(
  params: ListPageParams<AppMonitorOverviewParams>,
) {
  return request.get<ListResponse<any>>(
    '/v1/app_monitor/workspace-application-usage-detail',
    {
      query: params,
      querySerializer: {
        indices: false,
      },
    },
  )
}

// 排行榜明细
export function getAppMonitorUsageRankingDetail(
  params: ListPageParams<AppUsageRankingParams>,
) {
  return request.get<ListResponse<AppUsageRankingItem>>(
    '/v1/app_monitor/user-usage-ranking-detail',
    {
      query: params,
      querySerializer: {
        indices: false,
      },
    },
  )
}

// 工作空间维度异常
export function getWorkspaceErrorRecordsDetail(
  params: ListPageParams<AppMonitorOverviewParams>,
) {
  return request.get<ListResponse<ErrorRecordItem>>(
    '/v1/app_monitor/workspace-error-records-detail',
    {
      query: params,
      querySerializer: {
        indices: false,
      },
    },
  )
}

// 工作空间维度超时异常
export function getWorkspaceOverTimeDetail(
  params: ListPageParams<AppMonitorOverviewParams>,
) {
  return request.get<ListResponse<ErrorRecordItem>>(
    '/v1/app_monitor/flow-irl-count',
    {
      query: params,
      querySerializer: {
        indices: false,
      },
    },
  )
}

// 错误明细
export function getAppMonitorErrorRecordsDetail(
  params: ListPageParams<AppMonitorOverviewParams>,
) {
  return request.get<ListResponse<ErrorRecordItem>>(
    '/v1/app_monitor/dialogue-error-records-detail',
    {
      query: params,
      querySerializer: {
        indices: false,
      },
    },
  )
}

// 超时明细
export function getAppMonitorOvertimeDetail(
  params: ListPageParams<AppMonitorOverviewParams>,
) {
  return request.get<ListResponse<OvertimeRecordItem>>(
    '/v1/app_monitor/irl-detail',
    {
      query: params,
      querySerializer: {
        indices: false,
      },
    },
  )
}

export function fetchAgentChatRecords(body: AgentChatRecordsParams) {
  return request.post<AgentChatRecordsResponse>('/v1/chat/agent_log', body)
}

export function getPrevChatRecordsById(params: ChatRecordsParams) {
  return request.get<ChatRecordItem[]>(
    '/v1/chat/records/previous_limit_records',
    {
      query: params,
      querySerializer: {
        indices: false,
      },
    },
  )
}

export function queryUsageTrend(
  params: UsageTrendRequest,
): Promise<UsageTrendType[]> {
  return request.get('/v1/app_monitor/usage-trend', {
    query: params,
    querySerializer: {
      indices: false,
    },
  })
}

export function queryWorkspaceUsageTrend(
  params: UsageTrendRequest,
): Promise<UsageTrendType[]> {
  return request.get('/v1/app_monitor/workspace-usage-trend', {
    query: params,
    querySerializer: {
      indices: false,
    },
  })
}

export function queryUserUsageRank(
  params: UserUsageRankRequest,
): Promise<UserUsageRankType[]> {
  return request.get('/v1/app_monitor/user-usage-ranking', {
    query: params,
    querySerializer: {
      indices: false,
    },
  })
}

export function queryFlowUsageRank(
  params: AppMonitorOverviewParams,
): Promise<any[]> {
  return request.get('/v1/app_monitor/flow-usage-ranking', {
    query: params,
    querySerializer: {
      indices: false,
    },
  })
}

export function queryAppStability(
  params: AppStabilityRequest,
): Promise<AppStabilityType[]> {
  return request.get('/v1/app_monitor/dialogue-error-records', {
    query: params,
    querySerializer: {
      indices: false,
    },
  })
}

export function queryIrlTrend(params: IrlTrendRequest): Promise<IrlTrendType> {
  return request.get('/v1/app_monitor/irl-trend', {
    query: params,
    querySerializer: {
      indices: false,
    },
  })
}

export function getIrlTimeout(
  flowId: string,
): Promise<{ irl_time_out?: number } | null> {
  return request.get(`/v1/app_monitor/conf/${flowId}`)
}

export function updateIrlTimeout(flowId: string, value: number) {
  return request.post(`/v1/app_monitor/conf/${flowId}`, { irl_time_out: value })
}

export function getWorkspaceUsageOverview(
  params: AppMonitorOverviewParams,
): Promise<WorkspaceUsageOverview> {
  return request.get('/v1/app_monitor/workspace-usage-overview', {
    query: params,
    querySerializer: {
      indices: false,
    },
  })
}

// 通过当前flowId或agentId查找旧的applicationId
export function getFormalApplicationId(id: string) {
  return request.get(`/v1/flow/detail?flow_id=${id}`)
}
