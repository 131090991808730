import { memo, useMemo, useState } from 'react'
import type { FormInstance } from 'antd'
import { Form, Popconfirm } from 'antd'
import { useMemoizedFn } from 'ahooks'
import type { PromptStructItem } from '@apis/prompt/type'
import { PromptStructType } from '@apis/prompt/type'
import { Button, IconFont } from '@/components'
import { StructPromptGenerateFormMap } from '@/features/prompt/components/PromptStructEditor/PromptStructAIFrom'
import { tokenStorage } from '@/utils/storage.ts'
import {
  State as PromptGenerateState,
  useGeneratePromptSocket,
} from '@/features/agent/hooks/useGeneratePromptSocket.ts'
import AIEmptyPng from '@/assets/ai-empty.png'
import {
  getGeneratePrompt,
  getPromptFromStructPrompt,
} from '@/features/prompt/utils/prompt.ts'
import { FormListName } from '@/features/prompt/components/PromptStructEditor/PromptStructEditor.tsx'
import { TaskType } from '@/features/prompt/constant/generatePrompt.ts'

export interface PromptGenerateProps {
  title: string
  listForm: FormInstance<{ [FormListName]: PromptStructItem[] }>
  type: PromptStructType
  onClose?: () => void
  currentPrompt?: string
  value?: Record<string, any>
  onChange?: (value: Record<string, any>) => void
  onPromptReplace?: (prompt: string) => void
  placeholderMap: Record<string, string>
  skills: { name: string; description: string }[]
}

export const PromptGenerate = memo<PromptGenerateProps>(props => {
  const {
    title,
    type,
    value,
    listForm,
    onChange,
    onPromptReplace,
    currentPrompt,
    placeholderMap,
    onClose,
    skills,
  } = props
  const token = tokenStorage.get()
  const [form] = Form.useForm()
  const [customText, setCustomText] = useState<null | string>(null)

  const socketUrl = useMemo(() => {
    return `${
      import.meta.env.VITE_AI_WS_BASE_URL
    }/v1/ws/prompt/generate_optimize?Authorization=${token}`
  }, [])

  const {
    state: generateState,
    content: aiGenertedPrompt,
    handleSendMessage,
    handleStop,
  } = useGeneratePromptSocket(socketUrl)

  const prompt = useMemo(() => {
    if (customText) {
      return customText
    }
    return aiGenertedPrompt
  }, [aiGenertedPrompt, customText])

  const Component = useMemo(() => {
    return StructPromptGenerateFormMap[type]
  }, [type])

  const handleGenerate = useMemoizedFn(() => {
    const formValue = form.getFieldsValue()
    if (type === PromptStructType.OUTPUT) {
      // 当用户选择JSON/表格/文本类型的输出时不经过AI，直接给模板
      switch (formValue.outputType) {
        case 'json':
          setCustomText(
            '按照Json格式输出，输出示例如下：' +
              '{\n' +
              '“产品”：{\n' +
              '“描述”：“适合专业人士的高性能笔记本电脑”，\n' +
              '},\n' +
              '“评论”：[\n' +
              '{\n' +
              '“作者”：“Alice”，\n' +
              '“评论”：“很棒的产品，但有点重。”\n' +
              '}]}',
          )
          return
        case 'table':
          setCustomText(
            '按照表格markdwon的格式输出，输出示例如下：\n' +
              '| 名称 | 价格 | 数量 |\n' +
              '| ---- | ---- | ---- |\n' +
              '| 苹果 | 5 元 | 10 个 |\n' +
              '| 香蕉 | 3 元 | 8 根 |',
          )
          return
        case 'text':
          setCustomText(
            '1、按照符合人类阅读习惯的文本格式输出；\n' +
              '2、对重要的关键词，可以使用不同的格式突出显示，比如：\n' +
              '\t**这是加粗的文字**\n' +
              '\t*这是斜体的文字*',
          )
          return
        default:
          setCustomText(null)
          break
      }
    }

    const require = getGeneratePrompt(type, formValue, placeholderMap, skills)
    const promptStructs = listForm.getFieldsValue()[FormListName] ?? []

    const sendMessage: Record<string, any> = {
      type,
      theme: title,
      require,
      prompt: getPromptFromStructPrompt([...promptStructs]),
    }

    if (type === PromptStructType.TASK) {
      sendMessage.ext = {
        taskType: formValue.taskType || TaskType.QUERY,
      }
    }

    handleSendMessage(
      JSON.stringify({ action: 'struct_prompt_part', params: sendMessage }),
    )
  })

  const handleValueChange = useMemoizedFn(
    (_value: any, values: Record<string, any>) => {
      onChange?.(values)
    },
  )

  const handleConfirmUsePrompt = useMemoizedFn(() => {
    onPromptReplace?.(prompt)
    onClose?.()
  })

  return (
    <div className='h-full flex flex-col'>
      {/* Header */}
      <div className='flex-center-between py-16 px-20 pr-16 b-b-1 b-line/60'>
        <span className='font-500 text-16px'>生成{title}提示词</span>
        <span
          className='w-24px h-24px hover:bg-bg_3/8 flex-center cursor-pointer rd-4px'
          onClick={onClose}
        >
          <IconFont name='guanbi' />
        </span>
      </div>
      {/* Body */}
      <div className='flex flex-1 h-full of-hidden'>
        {/* 左侧表单 */}
        <div className='w-480px flex-1'>
          <Form
            form={form}
            layout='vertical'
            initialValues={value}
            onValuesChange={handleValueChange}
            component='div'
          >
            <div className='max-h-68vh px-24 pt-24 overflow-y-auto w-full'>
              <Component placeholderMap={placeholderMap} />
            </div>
            <div className='flex justify-end pt-12 px-24 pb-24'>
              {generateState === 'start' ? (
                <Button
                  icon={<IconFont name='tingzhishengcheng' />}
                  onClick={handleStop}
                >
                  <span>停止生成</span>
                </Button>
              ) : (
                <Button
                  type='primary'
                  loading={generateState === PromptGenerateState.pending}
                  onClick={handleGenerate}
                >
                  {generateState === PromptGenerateState.none
                    ? '生成'
                    : '重新生成'}
                </Button>
              )}
            </div>
          </Form>
        </div>
        {/* 右侧结果 */}
        <div className='w-480px min-h-430px p-24 flex-1 b-l-1 flex flex-col b-line/60'>
          {[PromptGenerateState.none, PromptGenerateState.pending].includes(
            generateState,
          ) && !customText ? (
            <div className='w-full h-full flex-center'>
              <div className='flex-center flex-col'>
                <img
                  className='w-70px h-72px'
                  src={AIEmptyPng}
                  alt='waiting...'
                />
                <div className='w-168px text-center mt-25px c-font_1/60 line-height-22px'>
                  根据左侧指令生成的提示词 将会显示在这里
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className='font-500'>生成的提示词</div>
              <div className='h-full p-1 of-hidden flex-1 mt-12 relative'>
                <div
                  className='absolute w-full h-full top-0 rd-8px left-0 pointer-events-none'
                  style={{
                    background:
                      'linear-gradient(137deg, rgba(79, 237, 255, 0.8) 1%, rgba(84, 122, 255, 0.8) 16%, rgba(111, 71, 255, 0.8) 70%, rgba(169, 71, 255, 0.8) 99%)',
                  }}
                ></div>
                <pre className='z-2 relative h-full py-7 px-12 whitespace-pre-line of-y-auto rd-8px bg-#f6f6f9 font-400 line-height-22px'>
                  {prompt}
                </pre>
              </div>
              <div className='flex justify-end pt-12'>
                <Popconfirm
                  title={null}
                  description={
                    <div className='w-240px line-height-22px font-400'>
                      使用提示词会覆盖现有配置，是否确认使用？
                    </div>
                  }
                  icon={null}
                  trigger={['click']}
                  okText='确认'
                  cancelText='取消'
                  onConfirm={handleConfirmUsePrompt}
                  okButtonProps={{ size: 'middle' }}
                  cancelButtonProps={{ size: 'middle' }}
                  disabled={!currentPrompt?.trim()}
                >
                  <Button
                    type='primary'
                    disabled={
                      generateState !== PromptGenerateState.result &&
                      !customText
                    }
                    onClick={() => {
                      if (!currentPrompt?.trim()) {
                        handleConfirmUsePrompt()
                      }
                    }}
                  >
                    使用
                  </Button>
                </Popconfirm>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
})

PromptGenerate.displayName = 'PromptGenerate'
