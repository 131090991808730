import styled from '@emotion/styled'
import { Tooltip } from 'antd'
import { useMemo, useRef, useState } from 'react'

import type { InputRef } from 'antd'
import { find } from 'lodash-es'
import { useReactFlow } from 'reactflow'
import type { IPluginItem } from '@apis/flow/type'
import { IconFont, Input, Segmented } from '@/components'
import { APINode } from '../api'
import { JavaScriptCodeNode, PythonCodeNode } from '../code'
import { ConditionNode } from '../condition'
import { KnowledgeNode } from '../knowledge'
import { LLMNode } from '../llm'
import { SubFlowNode } from '../subflow/SubFlowNode'

import {
  NodeOperationTypes,
  NodeType,
  PLUGIN_STATUS,
  type NodeComponent,
  type NodeMeta,
} from '@/features/nodes/base'
import { useScrollBar } from '@/hooks/useScrollBar'
import { useFlowDraftStore } from '@/store'
import { useNodeMetaStore } from '@/store/nodeMeta'
import { Empty } from '../components/Empty'
import { DatabaseNode } from '../database'
import { LLMBatchNode } from '../lllm-batch'
import { LoopNode } from '../loop'
import { PluginNode } from '../plugin'
import { generateNodeName } from '../utils'
import NodeOption from './components/NodeOption'
import PluginOption from './components/PluginOption'
import { EmptyNodeTypeOptions, EmptyNodeTypes } from './constants'
import { useLoopDepth } from './useLoopDepth'

const StyledSegmented = styled(Segmented)`
  padding: 2px;

  .ant-segmented-item {
    padding: 8px 16px;
    width: 192px;
    font-size: 12px;
    font-weight: 500;
  }
`

export interface EmptyNodeData {
  type?: string
  sourceHandleId?: string
  relation?: any
}

const nodeOptions = [
  LLMNode,
  LLMBatchNode,
  KnowledgeNode,
  DatabaseNode,
  PythonCodeNode,
  JavaScriptCodeNode,
  APINode,
  SubFlowNode,
  ConditionNode,
  LoopNode,
]

export const EmptyNode: NodeComponent<EmptyNodeData> = props => {
  const [type, setType] = useState<any>(EmptyNodeTypes.BASE)
  const { replaceEmptyNode, replaceEmptyByCloneNodes } = useFlowDraftStore()
  const { getNodes } = useReactFlow()
  const pluginList = useNodeMetaStore(state => state.pluginList)
  const subFlowList = useNodeMetaStore(state => state.subFlowList)
  const [searchValue, setSearchValue] = useState('')
  const inputRef = useRef<InputRef>(null)
  const { scrollRef } = useScrollBar()

  const pluginListBySearch = useMemo(() => {
    if (!searchValue.trim()) {
      return pluginList
    }
    const searchList = pluginList.filter(item => {
      return item?.display_name?.includes(searchValue)
    })
    return searchList
  }, [pluginList, searchValue])

  const onSelect = (meta: NodeMeta) => {
    const { sourceHandleId, relation } = props.data
    const nodes = getNodes()
    replaceEmptyNode({
      id: props.id,
      node: {
        type: meta.type,
        data: {
          ...(meta.initialData || {}),
          relation,
          actionType: meta.actionType,
          name: generateNodeName(nodes, meta.type),
        },
      },
      ...(sourceHandleId ? { sourceHandleId } : {}),
    })
  }

  const onPluginSelect = (id: string) => {
    const { sourceHandleId, relation } = props.data
    const meta = PluginNode.meta
    const currentPlugin =
      find(pluginList, item => item.function_id === id) || ({} as IPluginItem)
    const defaultInputs = {} as Record<string, any>
    currentPlugin?.form_config?.forEach(item => {
      if (item.value) {
        defaultInputs[item.variableName] = item.value
      }
    })
    const nodes = getNodes()
    const node = {
      type: (currentPlugin.type as any) || meta.type,
      data: {
        ...(meta.initialData || {}),
        inputs: defaultInputs,
        relation,
        plugin_id: id,
        output: currentPlugin.output,
        packageName: currentPlugin.package_name,
        actionType: currentPlugin.action_type,
        icon: currentPlugin.icon,
        plugin_status: PLUGIN_STATUS.ACTIVE,
        displayName: currentPlugin.display_name,
        name: generateNodeName(nodes, meta.type, currentPlugin.name),
        pluginName: currentPlugin.name,
      },
    }
    replaceEmptyNode({
      id: props.id,
      node,
      ...(sourceHandleId ? { sourceHandleId } : {}),
    })
  }

  const loopDepth = useLoopDepth(props.id)

  const displayNodeOptions = useMemo(() => {
    // 循环嵌套超过2层不展示循环节点
    return nodeOptions
  }, [props.data])

  const onPaste = () => {
    replaceEmptyByCloneNodes(props.id)
  }

  const baseNode = useMemo(() => {
    return (
      type === EmptyNodeTypes.BASE &&
      displayNodeOptions.map(({ meta }) => {
        const showSubFlowTips =
          meta.type === NodeType.FLOW && !subFlowList.length
        const disableLoop = meta.type === NodeType.LOOP && loopDepth >= 2
        return (
          <NodeOption
            key={meta.type}
            meta={meta}
            onSelect={onSelect}
            className={
              disableLoop || showSubFlowTips
                ? 'opacity-40 pointer-events-none'
                : ''
            }
            extra={
              showSubFlowTips && (
                <div className='flex items-center self-center flex-1 justify-end c-[rgba(141,141,153,0.6)]'>
                  暂无可用Flow
                </div>
              )
            }
          />
        )
      })
    )
  }, [type, displayNodeOptions, onSelect, subFlowList, loopDepth])

  const pluginNode = useMemo(() => {
    return (
      type === EmptyNodeTypes.PLUGIN && (
        <>
          <Input
            className='w-100% mb-8px'
            prefix={<IconFont name='search' className='text-16px' />}
            placeholder='搜索 Plugin 名称'
            ref={inputRef}
            size='small'
            value={searchValue}
            onChange={e => {
              setSearchValue(e.target.value)
            }}
          />
          <div
            ref={scrollRef}
            className='h-380px nodrag nopan nowheel overflow-y-auto relative'
          >
            <div>
              {pluginListBySearch?.length ? (
                <>
                  <div>
                    {pluginListBySearch.map(item => (
                      <PluginOption
                        key={item.function_id}
                        name={item.display_name}
                        icon={item.icon}
                        id={item.function_id}
                        description={item.description}
                        onSelect={onPluginSelect}
                      />
                    ))}
                  </div>
                </>
              ) : (
                <Empty />
              )}
            </div>
          </div>
        </>
      )
    )
  }, [type, pluginList, onPluginSelect])

  return (
    <div className='nodrag nopan'>
      <div className='flex flex-items-center justify-between pr-8 w-[420px] h-[40px] p-l-16px c-[#17171d] font-medium border-border border-b-[#e1e1e5] border-b-op-60'>
        <span className='flex-1'>选择节点类型</span>
        <div className='flex justify-between gap-[8px]'>
          <Tooltip title='粘贴节点' arrow={false}>
            <div
              onClick={onPaste}
              className='flex items-center justify-center w-[24px] h-[24px] text-[rgba(161,165,191)] rounded-4px hover:bg-[#626999] hover:bg-op-8'
            >
              <IconFont name='niantie' className='text-[16px] cursor-pointer' />
            </div>
          </Tooltip>
          <div
            onClick={props.onDelete}
            className='flex items-center justify-center w-[24px] h-[24px] text-[#626999] text-op-60 rounded-4px hover:bg-error bg-op-40 hover:bg-opacity-12 hover:text-error'
          >
            <IconFont name='shanshu' className='text-[16px] cursor-pointer' />
          </div>
        </div>
      </div>
      <div className='b-b-1 b-solid b-[rgba(225,225,229,0.6)] w-100% flex-1 mb-16px' />
      <div className='flex flex-1 items-center justify-center mt-[16px] mb-8px'>
        <StyledSegmented
          type={type}
          size='small'
          options={EmptyNodeTypeOptions}
          onChange={value => {
            setType(value as EmptyNodeTypes)
          }}
        />
      </div>
      <div className='px-16px pt-8 pb-4  max-w-420px flex flex-col'>
        {baseNode}
        {pluginNode}
      </div>
    </div>
  )
}

EmptyNode.meta = {
  type: NodeType.EMPTY,
  operationType: NodeOperationTypes.SINGLE_NODE,
  actionType: 'EMPTY',
  typeName: 'Empty',
  canDelete: false,
  description: '添加节点',
  isEmpty: true,
}
