import './index.css'

import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { Pagination, Spin, message, Modal } from 'antd'
import { useMemoizedFn, useRequest, useThrottleFn, useBoolean } from 'ahooks'
import { isEqual, isNil } from 'lodash-es'
import classNames from 'classnames'
import {
  deleteBatchTestData,
  getBatchTestData,
  getBatchTestExecuteResult,
  getBatchTestRunResult,
  getPageSize,
  updateBatchTestData,
  getBatchTestIds,
  getBatchTestProgress,
} from '@apis/batch-test'
import type { IShead } from '@apis/batch-test/type'
import { ResizeTable } from '@/components/resize-table'
import type { IHead, IRow } from '@/components/resize-table/type'
import { BatchTestHeader } from '../BatchTestHeader'
import { Button } from '@/components'
import { wait } from '@/utils/wait'
import { RunResultPanel } from '../RunResultPanel'
import { useUploadFileToOss } from '@/hooks/useOssUpload'
import {
  LoggerTitle,
  LoggerModal,
} from '@/features/logger/components/loggerModal'
import { useSocket } from '@/hooks/useSocket'
import {
  checkHeads,
  DEFAULT_LIMIT_SIZE,
  DEFAULT_PAGESIZE,
  placeholderMap,
  widthMap,
} from './const'
import { nextIcon, prevIcon } from './icon'
import { getRender, getTitle } from './render'
import type { TestTableProps } from './type'

export const TestTable = memo((props: TestTableProps) => {
  const { show, flowId, onClose, debugTask } = props

  const { uploadFileToOssApi } = useUploadFileToOss()

  const addRowRef = useRef(false)
  const tableRef = useRef<HTMLDivElement>(null)
  const [runLoading, setRunLoading] = useState(false)
  const [head, setHead] = useState<IShead[]>([])
  const [data, setData] = useState<IRow[]>([])
  const [pageNo, setPageNo] = useState(1)
  const [pageSize] = useState(DEFAULT_PAGESIZE)
  const [total, setTotal] = useState(0)
  const [select, setSelect] = useState<string[]>([])
  const [currentRow, setCurrentRow] = useState<IRow>()
  const [currentHead, setCurrentHead] = useState<IHead>()

  const [
    logModalVisible,
    { setTrue: showLogModalVisible, setFalse: hideLogModalVisible },
  ] = useBoolean(false)
  const [
    resultPanelVisible,
    { setTrue: showResultPanel, setFalse: hideResultPanel },
  ] = useBoolean(false)

  const { data: limited, loading: limitedLoading } = useRequest(getPageSize)

  const {
    data: tableInfo,
    loading: getDataLoading,
    run: getTableData,
  } = useRequest(
    (_noScroll?: boolean) => {
      return getBatchTestData(flowId, pageNo, Math.min(limited!, pageSize))
    },
    {
      manual: true,
      onSuccess(res, params) {
        if (!res.table_header) return
        const noChange = isEqual(
          res.table_header.filter(e => !e.is_result),
          head.filter(e => !e.is_result),
        )
        if (!noChange) {
          hideLogModalVisible()
          hideResultPanel()
        }
        setHead(res.table_header)
        setTotal(res.total)
        const formatData = res?.table_data.map((each, i) => {
          const newData: IRow = {
            key: each.test_data_id ?? String(Date.now()),
            ...each,
            index: i + (pageNo - 1) * pageSize,
          }
          return newData
        })
        setData(formatData ?? [])
        if (addRowRef.current) {
          addRowRef.current = false
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
          handleRowAdd()
        }
        if (params[0] || noChange) return
        tableRef.current?.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
      },
    },
  )

  useEffect(() => {
    if (!show || !limited) return
    getTableData()
  }, [show, pageNo, limited])

  useEffect(() => {
    if (!pageNo) return
    tableRef.current?.scrollTo({
      top: 0,
    })
  }, [pageNo])

  const { runAsync: updateNewRow } = useRequest(
    (data, id?) => updateBatchTestData(flowId, data, id),
    {
      manual: true,
    },
  )

  const { runAsync: deleteRows, loading: deleteDataLoading } = useRequest(
    deleteBatchTestData,
    {
      manual: true,
    },
  )

  const {
    runAsync: getBatchTestExecuteResultApi,
    loading: batchResultLoading,
    data: currentLogItem,
  } = useRequest(getBatchTestExecuteResult, {
    manual: true,
  })

  const { subscribe, open, close } = useSocket()
  const batchIdRef = useRef('')

  const { data: batchTestRunResult, run: updateBatchResult } = useRequest(
    () => {
      return getBatchTestRunResult(
        batchIdRef.current,
        pageNo,
        Math.min(limited ?? pageSize, pageSize),
      )
    },
    {
      manual: true,
    },
  )

  const { runAsync: fetchBatchTestIds } = useRequest(getBatchTestIds, {
    manual: true,
  })

  const { run: updateBatchResultTR } = useThrottleFn(updateBatchResult, {
    wait: 1000,
    leading: false,
  })

  useEffect(() => {
    return subscribe(() => {
      if (!batchIdRef.current) return
      updateBatchResultTR()
    })
  }, [])

  const { run: updateRow } = useThrottleFn(updateNewRow, { wait: 200 })

  const handleRowAdd = useMemoizedFn(async () => {
    if (runLoading) {
      message.warning('批量测试中，请稍后')
      return
    }
    const lastPageNo = Math.ceil((total + 1) / pageSize)
    if (lastPageNo !== pageNo) {
      setPageNo(lastPageNo)
      addRowRef.current = true
      return
    }
    const newRow = await updateNewRow({})
    setTotal(prev => prev + 1)
    setData(prev => [
      ...prev,
      { key: newRow.test_data_id, ...newRow, autoFocus: true, index: total },
    ])
    await wait(100)
    tableRef.current?.scrollTo({
      top: tableRef.current.scrollHeight,
    })
  })

  const handleCellEdit = useMemoizedFn(
    async (value: any, path: string[], noUpdate?: boolean) => {
      const index = data.findIndex(each => each.key === path[0])
      if (isNil(index)) return
      const newRow = {
        ...data[index],
        [path[1]]: value,
      }
      delete newRow.autoFocus
      const newData = [...data]
      newData[index] = newRow
      setData(newData)
      if (newRow.key === currentRow?.key) {
        setCurrentRow(newRow)
      }
      if (noUpdate) return
      await updateRow({ [path[1]]: value }, newRow.key)
    },
  )

  const handleCellClick = useMemoizedFn(async (row: IRow, head: IHead) => {
    setCurrentRow(row)
    setCurrentHead(head)

    if (
      runLoading &&
      (!row.runtimeOutput || row.runtimeOutput?.run_result_status !== 'FINISH')
    ) {
      hideLogModalVisible()
      hideResultPanel()
      return
    }

    const outputInfo = row.runtimeOutput || row[head.outputKey || '']
    const isOutput = [head.outputKey].includes(head.key)
    const resultStatus = outputInfo?.result?.status

    if (isOutput && (resultStatus === 'FAILED' || resultStatus === 'ERROR')) {
      hideResultPanel()
      const testResultId = outputInfo?.test_result_id
      testResultId && (await getBatchTestExecuteResultApi(testResultId))
      showLogModalVisible()
      return
    }

    if (isOutput) {
      hideLogModalVisible()
      showResultPanel()

      if (resultPanelVisible) return
      await wait(100)
      tableRef.current?.scrollTo({
        left: tableRef.current.scrollWidth,
      })
    }
  })

  const handleUpdate = useMemoizedFn(
    (type: 'start' | 'end' | 'now' = 'now') => {
      getTableData(!type || type === 'now')
    },
  )

  const handleSelect = useMemoizedFn((newSelect: string[]) => {
    setSelect(newSelect)
  })

  const handleDelete = useMemoizedFn(async () => {
    Modal.confirm({
      icon: null,
      content: '是否确认删除选择的测试内容？',
      cancelText: '取消',
      okText: '确认',
      okButtonProps: {
        color: 'primary',
        className: 'bg-#FF5219! hover:opacity-80!',
      },
      className: 'mt-[calc(50vh-173px)]',
      onOk: async () => {
        await deleteRows(select)
        if ((pageNo - 1) * pageSize >= total - select.length && pageNo !== 1) {
          setPageNo(prev => (prev > 1 ? prev - 1 : 1))
          return
        }
        getTableData()
      },
    })
  })

  const handleLabelChange = useMemoizedFn((label: string | null) => {
    const index = data.findIndex(each => each.key === currentRow?.key)
    if (isNil(index)) return

    const newRow = {
      ...data[index],
      similarity: {
        result_label: label,
      },
    }
    const newData = [...data]
    newData[index] = newRow
    setCurrentRow(newRow)
    setData(newData)
  })

  const handleRemarkChange = useMemoizedFn((remarks: string) => {
    const index = data.findIndex(each => each.key === currentRow?.key)
    if (isNil(index)) return

    const newRow = {
      ...data[index],
      remarks,
    }
    const newData = [...data]
    newData[index] = newRow
    setCurrentRow(newRow)
    setData(newData)
  })

  const handleRun = useMemoizedFn(async (batchId: string) => {
    hideLogModalVisible()
    hideResultPanel()
    setRunLoading(true)
    batchIdRef.current = batchId
    updateBatchResult()

    // 重置打标数据
    const newData = data.map(each => {
      return {
        ...each,
        similarity: {},
      }
    })
    setData(newData)

    tableRef.current?.scrollTo({
      left: tableRef.current.scrollWidth,
      behavior: 'smooth',
    })
  })

  const handleSelectRun = useMemoizedFn(async (batchId: string) => {
    hideLogModalVisible()
    hideResultPanel()
    batchIdRef.current = batchId

    tableRef.current?.scrollTo({
      left: tableRef.current.scrollWidth,
      behavior: 'smooth',
    })
  })

  const handleAfterRun = useMemoizedFn(() => {
    setRunLoading(false)
  })

  const { refresh: refreshBatchTestProgress } = useRequest(
    () => getBatchTestProgress(flowId),
    {
      refreshDeps: [flowId],
      ready: !!flowId,
      manual: true,
      onSuccess: data => {
        if (data.batch_test_run_status === 'FINISH') {
          close()
          handleAfterRun?.()
          handleUpdate?.('now')
        }
      },
    },
  )

  const handleReturn = async () => {
    Modal.confirm({
      icon: null,
      content: '是否运行选择的测试内容？',
      cancelText: '取消',
      okText: '确认',
      okButtonProps: {
        color: 'primary',
        className: 'hover:bg-#9580ff!',
      },
      className: 'mt-[calc(50vh-173px)]',
      onOk: async () => {
        open()
        const res = await fetchBatchTestIds(flowId, select)
        handleSelectRun(res?.batch_test_run_id)
        refreshBatchTestProgress()
      },
    })
  }

  const handleUpload = useMemoizedFn(uploadFileToOssApi)

  useEffect(() => {
    if (!batchTestRunResult) return

    const newData = data.map(each => {
      const res = batchTestRunResult.find(e => e.test_data_id === each.key)
      if (!res) return each
      return {
        ...each,
        runtimeOutput: res,
      }
    })

    setData(newData)
  }, [batchTestRunResult])

  const allHeads = useMemo(() => {
    if (!head) return []
    const outputKey = head.find(each => each.is_result)?.variableName

    const base = head.map((each, index) => {
      const isForm = each.is_edit
      const isOutput = each.is_result
      const key = each.variableName ?? 'output'
      const type = isForm ? each.type : isOutput ? 'output' : key
      const widthInfo = widthMap[key] || widthMap[each.type] || widthMap.input

      return {
        key,
        type,
        title: getTitle(type, each.label, each.required),
        outputKey,
        placeholder:
          each.placeholder || placeholderMap[key] || placeholderMap[type],
        minWidth: widthInfo[1],
        width: widthInfo[0],
        className:
          isForm || ['similarity', 'remarks'].includes(each.variableName)
            ? 'test-table-form-cell'
            : '',
        disabled: runLoading,
        render: getRender(type, handleCellEdit),
        grow: key === 'correct_result' || type === 'output',
        require: each.required,
        autoFocus: index === 0,
        options: each.options ?? [],
        fileTypes: each?.supportFileConfig?.support ?? [],
        onClick: handleCellClick,
        extra: { uploadFile: handleUpload, runLoading },
      }
    })

    return [...checkHeads, ...base]
  }, [tableInfo, runLoading, handleUpload])

  const validateData = useMemoizedFn(() => {
    if (!allHeads) return { pass: true }
    for (const row of data) {
      for (const head of allHeads) {
        if (head.require && !row[head.key]) {
          return { pass: false, head: head.key, row: row.key }
        }
      }
    }
    return { pass: true }
  })

  const handleBeforeRun = useMemoizedFn(() => {
    const info = validateData()
    if (!info.pass) {
      message.error('请填写必填项')
    }

    const dom = tableRef.current?.querySelector(
      `[data-id="${info.row}-${info.head}"]`,
    ) as HTMLElement

    if (dom) {
      dom.click()
      dom.scrollIntoView({
        block: 'center',
        inline: 'center',
        behavior: 'smooth',
      })
    }

    return info.pass
  })

  const onLogClick = useMemoizedFn((testResultId: string) => {
    testResultId &&
      getBatchTestExecuteResultApi(testResultId).then(showLogModalVisible)
  })

  const handleRetry = useMemoizedFn(() => {
    onClose()
    if (!currentRow || !currentHead) return
    debugTask?.(currentRow[currentHead.outputKey].run_result_id, {
      from: 'batchTest',
      message: `已选择测试集第${currentRow?.index + 1}条`,
    })
  })

  const loggerTitle = useMemo(() => {
    const { start_time, run_status } = currentLogItem || {}
    return <LoggerTitle time={start_time} status={run_status} />
  }, [currentLogItem])

  return (
    <div className='h-full flex flex-col relative'>
      <BatchTestHeader
        flowId={flowId}
        beforeRun={handleBeforeRun}
        onRun={handleRun}
        afterRun={handleAfterRun}
        onClose={onClose}
        onUpdate={handleUpdate}
      />
      <div className='flex flex-1 overflow-hidden'>
        <div
          className={classNames(
            'flex-1 flex flex-col overflow-hidden relative',
            {
              'w-50%': resultPanelVisible,
            },
          )}
        >
          {(limitedLoading || getDataLoading || deleteDataLoading) && (
            <div className='flow-test-table-loading'>
              <Spin />
            </div>
          )}
          <div className='flex-1 overflow-hidden'>
            <ResizeTable
              ref={tableRef}
              className='flow-test-table'
              heads={allHeads}
              rows={data}
              addRow={total < (limited || DEFAULT_LIMIT_SIZE)}
              onAddRow={handleRowAdd}
              onSelect={handleSelect}
            />
          </div>
          <div className='h-56px flex items-center px-24 border-t-1 border-[rgba(225, 225, 229, 0.4)]'>
            {!select.length && (
              <div className='color-#8D8D99'>
                总计 {total} 条，上限 {limited} 条
              </div>
            )}
            {!!select.length && (
              <div>
                <span className='text-#8D8D99'>
                  已选 <span className='text-#FF5219'>{select.length}</span>/
                  <span>{total}</span> 条数据
                </span>
                <Button
                  className='ml-8px w-48px !h-32px'
                  type='default'
                  onClick={handleReturn}
                >
                  运行
                </Button>
                <Button
                  className='ml-8px w-48px !h-32px !p-0'
                  danger
                  type='primary'
                  onClick={handleDelete}
                >
                  删除
                </Button>
              </div>
            )}
            <div className='flex ml-auto'>
              <Pagination
                showSizeChanger={false}
                current={pageNo}
                pageSize={pageSize}
                total={total}
                onChange={setPageNo}
                prevIcon={prevIcon}
                nextIcon={nextIcon}
              />
            </div>
          </div>
        </div>
        {resultPanelVisible && (
          <RunResultPanel
            data={currentRow}
            className='w-50% b-l-1'
            cellKey={currentHead?.outputKey}
            onClose={hideResultPanel}
            onLogClick={onLogClick}
            onReTry={handleRetry}
            onLabelChange={handleLabelChange}
            onRemarkChange={handleRemarkChange}
          />
        )}
      </div>
      {!!currentLogItem && !batchResultLoading && logModalVisible && (
        <LoggerModal
          defaultTab='output'
          title={loggerTitle}
          flowId={currentLogItem.flow_id}
          taskId={currentLogItem.task_id}
          onReRun={handleRetry}
          onClose={hideLogModalVisible}
        />
      )}
    </div>
  )
})
