import { get, omit } from 'lodash-es'
import { AlarmLevels, AlarmTypes } from '@apis/alarm/type'
import type {
  AlarmRuleConfigType,
  AlarmRuleItem,
  ThresholdConfigItem,
} from '@apis/alarm/type'
import type { AlarmRuleFormValues } from './type'

function formatTimeText(time: number) {
  return time >= 60 ? `${Math.floor(time / 60)} 小时` : `${time} 分钟`
}

export function getAlarmConditionText(options: {
  alarmType: AlarmTypes
  time: number
  count: number
  unit?: string
}) {
  const { alarmType, time, count, unit } = options
  if (
    alarmType === AlarmTypes.APP_POINTS_CONSUME ||
    alarmType === AlarmTypes.WORKSPACE_POINTS_CONSUME
  ) {
    return `${formatTimeText(time)}内消耗达到 ${count} 积分`
  }
  if (
    alarmType === AlarmTypes.CHAT_ERROR ||
    alarmType === AlarmTypes.FIRST_WORD_RESPONSE ||
    alarmType === AlarmTypes.EXECUTE_ERROR ||
    alarmType === AlarmTypes.FLOW_RUN_FIRST_WORD_RESPONSE
  ) {
    return `${formatTimeText(time)}内出现 ${count} 次`
  }
  if (alarmType === AlarmTypes.WORKSPACE_POINT_MARGIN) {
    const number = unit?.startsWith('%') ? `${count}${unit.slice(0, 1)}` : count
    const suffix = unit?.startsWith('%') ? unit.slice(1) : unit
    return `仅剩余 ${number} ${suffix || '积分'}`
  }
  if (alarmType === AlarmTypes.WORKSPACE_EXPIRE) {
    return `距离工作空间到期前 ${count} 天`
  }
  return ''
}

export function transformTimeOptions(options: string[]) {
  return options.map(t => {
    let label = ''
    let value = 0
    const n = parseInt(t.match(/\d+/g)?.[0] || '')
    if (t.endsWith('min')) {
      label = `${n}分钟内`
      value = n
    } else if (t.endsWith('h')) {
      label = `${n}小时内`
      value = n * 60
    }
    return {
      label,
      value,
    }
  })
}

// 添加告警规则时构造表单部分默认值
export function getDefaultRuleValuesByRuleType(
  ruleType: AlarmRuleConfigType,
  defaultConfig: {
    userId?: number
    defaultLeftPoints: number
  },
) {
  const { userId, defaultLeftPoints } = defaultConfig
  const res: {
    alarm_rule_type: AlarmRuleConfigType
    alarm_type: AlarmTypes
    threshold_config: ThresholdConfigItem[]
    alarm_channel: any
  } = {
    alarm_rule_type: ruleType,
    alarm_channel: {
      ding_notify_channel: {
        enabled: true,
      },
      sms_notify_channel: {
        enabled: false,
        notify_user: userId ? [userId] : [],
      },
      vms_notify_channel: {
        enabled: false,
        notify_user: userId ? [userId] : [],
      },
    },
    alarm_type: AlarmTypes.WORKSPACE_POINT_MARGIN,
    threshold_config: [],
  }
  if (ruleType === 'Workspace') {
    res.alarm_type = AlarmTypes.WORKSPACE_POINT_MARGIN
    res.threshold_config.push({
      threshold: defaultLeftPoints || 100,
      alarm_level: AlarmLevels.HIGH,
      trigger_type: 'NUMBER',
    })
  } else if (ruleType === 'Agent') {
    res.alarm_type = AlarmTypes.CHAT_ERROR
    res.threshold_config.push({
      alarm_time: 5,
      threshold: 3,
      alarm_level: AlarmLevels.HIGH,
    })
  } else if (ruleType === 'AI') {
    res.alarm_type = AlarmTypes.EXECUTE_ERROR
    res.threshold_config.push({
      alarm_time: 5,
      threshold: 3,
      alarm_level: AlarmLevels.HIGH,
    })
  }

  return res
}

// 规则列表单项数据转化为规则表单输入值
export function transformRuleItemToFormValues(record: AlarmRuleItem) {
  const { alarm_channel, alarm_effect_objects } = record
  const smsNotifyUsers = get(
    alarm_channel,
    ['sms_notify_channel', 'notify_user'],
    [],
  )
  const dingNotifyUsers = get(
    alarm_channel,
    ['ding_notify_channel', 'notify_user'],
    [],
  )
  const vmsNotifyUsers = get(
    alarm_channel,
    ['vms_notify_channel', 'notify_user'],
    [],
  )
  const channels = {
    ...alarm_channel,
    sms_notify_channel: alarm_channel.sms_notify_channel
      ? {
          ...alarm_channel.sms_notify_channel,
          notify_user: smsNotifyUsers.map(item => item.user_id),
        }
      : null,
    ding_notify_channel: alarm_channel.ding_notify_channel
      ? {
          ...alarm_channel.ding_notify_channel,
          notify_user: dingNotifyUsers.join('\n'),
        }
      : null,
    vms_notify_channel: alarm_channel.vms_notify_channel
      ? {
          ...alarm_channel.vms_notify_channel,
          notify_user: vmsNotifyUsers.map(item => item.user_id),
        }
      : null,
  }
  const appIds = alarm_effect_objects?.map(item => item.app_id) ?? []

  const values: AlarmRuleFormValues = {
    ...omit(
      record,
      'alarm_rule_id',
      'alarm_effect_objects',
      'created_at',
      'created_name',
      'updated_at',
      'updated_name',
    ),
    alarm_channel: channels,
    app_ids: appIds,
  }
  return values
}
