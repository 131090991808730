import { request } from '../../client'
import type { LLMModalItem, LLMModelLimitedItem } from './type'

export function getLLMModelList(generate_type: 'textGenerate') {
  return request.get<LLMModalItem[]>('/v1/llm/active_channels', {
    query: {
      generate_type,
    },
  })
}

export function getLLMModelListLimited(workspaceId: string) {
  return request.get<LLMModelLimitedItem[]>(
    `/v1/workspace/model_limited?workspace_id=${workspaceId}`,
  )
}

export function getAsyncSupportLLMModelListLimited(workspaceId: string) {
  return request.get<LLMModelLimitedItem[]>(
    `/v1/async_batch/workspace/model_limited?workspace_id=${workspaceId}`,
  )
}

export function getLLMModelContextExample() {
  return request.get<Record<string, any>>('/v1/flow/llm/context_example')
}
