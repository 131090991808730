import { request } from '../../client'
import type {
  CodeExampleListType,
  IInstalledPackages,
  IIntergrationResource,
} from './type'

export function getIntegrationResources(flow_id: string) {
  return request.get<IIntergrationResource>('/v1/integration/resources', {
    query: {
      flow_id,
    },
  })
}

export function getInstalledPackages(language: 'python' | 'javascript') {
  return request.get<IInstalledPackages[]>(
    `/v1/action/installed-packages/${language}`,
  )
}

export async function getCodeExample(
  filename: string,
): Promise<CodeExampleListType> {
  return await request.get(
    `https://resource.bantouyan.com/betteryeah/flow/${filename}.json`,
    {
      query: {
        t: new Date(),
      },
    },
  )
}
