import { memo, useContext, useMemo } from 'react'
import classNames from 'classnames'
import JsonView from 'react-json-view'
import { isUndefined, isString, isObjectLike } from 'lodash-es'
import { FlowStatus } from '@apis/flow/type'
import { DebugResultPanelContext } from '@/features/nodes/base/DebugResultPanel'
import theme from '@/constants/theme'
import { safeJSONStringify } from '../../utils'
import { IconFont, RunningLoading } from '@/components'
import { isMarkdown } from '@/pages/flowPage/util'
import { OverflowContent } from './OverflowContent'

function CodeOutputContent() {
  const { result, loading } = useContext(DebugResultPanelContext)
  const status = useMemo(() => result?.status, [result])
  const showLoading = useMemo(() => loading, [loading, result?.output])

  const durationTime = useMemo(() => {
    return `${result?.durationTime} s`
  }, [result?.durationTime])

  const isMarkdownType = useMemo(
    () =>
      result?.output && isString(result.output) && isMarkdown(result.output),
    [result?.output],
  )

  const MemoCodeElement = useMemo(() => {
    const isObjectOutput = isObjectLike(result?.output)
    if (result) {
      return (
        <div
          className='b-rd-8px p-12px text-14px'
          style={{ background: '#FFFFFF' }}
        >
          {isObjectOutput ? (
            <div
              className='b-rd-8px p-12px text-14px'
              style={{ background: '#FFFFFF' }}
            >
              <JsonView
                src={result.output as object}
                displayDataTypes={false}
                enableClipboard={false}
                displayObjectSize={false}
                name={false}
                style={{
                  fontSize: '11px',
                  fontWeight: '400',
                  letterSpacing: '-0.195px',
                  lineHeight: '13px',
                  wordBreak: 'break-all',
                }}
              />
            </div>
          ) : (
            <div
              style={{
                whiteSpace: 'pre-wrap',
                wordBreak: 'break-all',
                lineHeight: '18px',
              }}
            >
              {safeJSONStringify(result.output)}
            </div>
          )}
        </div>
      )
    }
    return null
  }, [result?.output])

  return (
    <>
      <div className='w-100% h-100%'>
        <div className='text-16px color-#17171D font-500 mb-16px pt-16px px-16px'>
          输出
        </div>
        <OverflowContent
          occupiedHeight='32'
          className={classNames('p-16px pt-0', {
            'flex items-center': showLoading || isUndefined(result?.output),
          })}
        >
          {!showLoading && status === FlowStatus.SUCCEEDED && (
            <div className='flex-1 flex justify-between text-12px line-height-14px mb-12px p-12px bg-#EFFAF3 b-1 b-#2CB969 b-op-12 b-rd-8px'>
              <p
                className='flex items-center justify-center'
                style={{
                  color: theme.colors.success,
                }}
              >
                <span className='text-14px'>
                  <IconFont name='chenggongbaocun' />
                </span>
                <span className='mx-8px font-500 text-14px'>运行成功</span>
              </p>
              <p className='c-#8D8D99'>{durationTime}</p>
            </div>
          )}
          <div
            className={classNames('flex-1 shrink-0', {
              'flex flex-col overflow-y-auto': isMarkdownType,
            })}
          >
            {showLoading && (
              <div className='flex-center flex-col h-100%'>
                <RunningLoading loading={loading} loadingText='运行中' />
              </div>
            )}
            {!showLoading &&
              (status === FlowStatus.FAIL ? (
                <div className='b-rd-8px bg-#FFF1ED b-1 b-#FF5219 b-op-12 p-12px text-14px'>
                  <div className='flex justify-between pb-12px b-b-1 b-#E1E1E5 b-op-40'>
                    <p
                      className='flex items-center justify-center'
                      style={{
                        color: theme.colors.error,
                      }}
                    >
                      <span className='text-14px'>
                        <IconFont name='cuowu' />
                      </span>
                      <span className='mx-8px font-500'>运行失败</span>
                    </p>
                    <p className='c-#8D8D99 text-12px'>{durationTime}</p>
                  </div>
                  <div className='c-#E54A16 lh-22px mt-8px break-all'>
                    {result?.message}
                  </div>
                </div>
              ) : (
                <>
                  {isUndefined(result?.output) && (
                    <RunningLoading
                      loading={loading}
                      emptyText='点击“开始运行”按钮发送请求'
                    />
                  )}
                  {MemoCodeElement}
                </>
              ))}
          </div>
        </OverflowContent>
      </div>
    </>
  )
}

export const CodeOutput = memo(CodeOutputContent)
