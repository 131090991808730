import type { HttpDefine } from '@bty/http-client'
import { useApplicationStore, useWorkspaceStore } from '@/store'
import { tokenStorage } from '@/utils/storage'
import {
  NO_APPLICATION_ID_API_LIST,
  NO_AUTH_API_LIST,
} from '@/constants/request'

export async function onRequest(config: HttpDefine) {
  const { currentApplicationId } = useApplicationStore.getState()
  const { currentWorkspaceId } = useWorkspaceStore.getState()
  const token = tokenStorage.get()
  const workspaceId = currentWorkspaceId
  const applicationId = currentApplicationId

  if (!config.headers) {
    config.headers = {}
  }

  if (token && !NO_AUTH_API_LIST.some(api => config.url?.includes(api))) {
    config.headers.Authorization = `${token}`
  }

  if (!config.headers.Accept) {
    config.headers.Accept = 'application/json'
  }

  if (!config.headers['Workspace-Id']) {
    config.headers['Workspace-Id'] = workspaceId // ai
  }

  const isIgnoreUrl = NO_APPLICATION_ID_API_LIST.some(item => {
    return item.test(config.url || '')
  })

  if (applicationId && !isIgnoreUrl && !config.headers['Application-Id']) {
    config.headers['Application-Id'] = applicationId
  }

  return config
}
