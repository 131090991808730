import type { ReactNode } from 'react'
import { useState } from 'react'
import classNames from 'classnames'
import styled from '@emotion/styled'
import type { ApplicationBodyType } from '@apis/application/type'
import { AppLogo, IconFont } from '@/components'

const RenderItemWrapper = styled.div<{ open: boolean }>`
  cursor: grab;
  position: relative;
  width: 100%;
  .buttonWrapper {
    display: flex;
    position: absolute;
    padding: 10px 0 5px 0;
    box-sizing: border-box;
    bottom: ${({ open }) => (open ? '17px' : '-50px')};
    width: calc(100% - 40px);
    background: #fff;
    transition: bottom 0.3s;
  }
  .imgBorder {
    z-index: 10;
    background: linear-gradient(
      to bottom,
      rgba(0, 216, 216, 0.6),
      rgba(109, 80, 255, 0.6)
    );
    border-radius: 50%;
    img {
      border: 4px solid #fff;
    }
  }
  .messageWrapper {
    display: flex;
  }
  &:hover {
    .content {
      box-shadow: 0px 12px 20px 0px rgba(23, 26, 29, 0.12);
      .buttonWrapper {
        bottom: 17px;
      }
    }
  }
`

export function AgentCardItem(
  props: Partial<ApplicationBodyType> & {
    onClick?: () => void
    buttonWrapper?: (e: { setOpen: (e: boolean) => void }) => JSX.Element
    messageWrapper?: ReactNode
    className?: string
    grayBorder?: boolean
  } & React.HTMLAttributes<HTMLDivElement>,
) {
  const {
    onClick,
    className,
    grayBorder,
    icon,
    color,
    name,
    buttonWrapper: CombuttonWrapper,
    messageWrapper,
    ...otherProps
  } = props
  const [open, setOpen] = useState(false)
  return (
    <RenderItemWrapper
      {...otherProps}
      open={open}
      onClick={() => onClick?.()}
      className={classNames(
        'h-204px relative flex flex-col justify-end border-rd-12px',
        className,
      )}
    >
      <div
        style={{
          pointerEvents: 'none',
          transform: 'translate(-50%, 0%)',
          background: grayBorder ? 'rgba(98, 105, 153, 0.12)' : '',
        }}
        className='absolute w-88px h-88px flex-center top-0px left-50% border-rd-50% overflow-hidden p-1px bg-#fff imgBorder'
      >
        <AppLogo
          type='image'
          value={icon ?? ''}
          color={color}
          size={86}
          style={{
            background: '#fff',
            borderRadius: '50%',
          }}
        />
      </div>
      <div className='cursor-grab content border-rd-12px p-20 h-156px bg-#fff flex flex-col relative justify-between overflow-hidden'>
        <div
          title={name}
          className='truncate text-16px font-600 m-b-12px line-height-18px mt-44px'
        >
          {name}
        </div>
        {CombuttonWrapper && (
          <div className='buttonWrapper flex flex-justify-between w-100%'>
            <CombuttonWrapper setOpen={setOpen} />
          </div>
        )}
        {messageWrapper}
      </div>
    </RenderItemWrapper>
  )
}

export function TemplateAbility({ icon, num }: { icon: string; num: number }) {
  return (
    <div className='text-12px c-font_1 flex'>
      <IconFont name={icon} />
      <span className='px-4px'>{num}</span>
    </div>
  )
}

const StyledTemplateCard = styled.div`
  position: relative;

  .buttonWrapper {
    display: flex;
    position: absolute;
    padding: 7px 0 0 0;
    box-sizing: border-box;
    bottom: -70px;
    left: 16px;
    width: calc(100% - 32px);
    background: #fff;
    transition: bottom 0.3s;
  }
  &:hover {
    box-shadow: 0px 12px 20px 0px rgba(23, 26, 29, 0.12);
    .content {
      .buttonWrapper {
        bottom: 9px;
      }
    }
  }
`

export function AgentTemplateCard(
  props: Partial<ApplicationBodyType> & {
    onClick?: () => void
    buttonWrapper?: () => JSX.Element
    footerWrapper?: () => JSX.Element
    messageWrapper?: ReactNode
    className?: string
    grayBorder?: boolean
  } & React.HTMLAttributes<HTMLDivElement>,
) {
  const {
    className,
    icon,
    messageWrapper,
    buttonWrapper: CombuttonWrapper,
    footerWrapper: CustomFooterWrapper,
    onClick,
  } = props
  return (
    <StyledTemplateCard
      className={classNames(
        ' bg-#fff b-rd-12px relative px-16px pt-16px pb-10px cursor-pointer  overflow-hidden',
        className,
      )}
      onClick={() => onClick?.()}
    >
      <div className='flex pb-16px'>
        <div className='h-100% b-rd-50% b-#fff'>
          <img
            className='object-cover b-rd-50%  overflow-hidden'
            src={icon}
            alt=''
            style={{
              width: 70,
              height: 70,
            }}
          />
        </div>
        <div className='ml-12px flex-1 content'>
          {CombuttonWrapper && (
            <div className='buttonWrapper flex flex-justify-between w-100%'>
              <CombuttonWrapper />
            </div>
          )}
          {messageWrapper}
        </div>
      </div>
      {CustomFooterWrapper && (
        <div className='b-t-1'>
          <CustomFooterWrapper />
        </div>
      )}
    </StyledTemplateCard>
  )
}
