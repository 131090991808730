import { memo, useMemo } from 'react'
import { useBoolean, useMemoizedFn } from 'ahooks'
import { Tooltip } from 'antd'
import classNames from 'classnames'
import { PromptType } from '@apis/prompt/type'
import type { PromptStructItem } from '@apis/prompt/type'
import { useTrack } from '@/features/track/Track'
import { IconFont } from '@/components'
import { PromptToStructModal } from './PromptToStructModal'
import { PromptToRawModal } from './PromptToRawModal'

interface PromptSyncTransformerProps {
  className?: string
  promptType?: PromptType
  getPromptType: () => PromptType
  getPromptText: () => string
  getPromptStructText: () => string
  onPromptTypeChange: (
    value: PromptType,
    prompt?: { raw?: string; struct?: PromptStructItem[] },
  ) => void
}

export const PromptSyncTransformer = memo<PromptSyncTransformerProps>(props => {
  const {
    className,
    promptType,
    onPromptTypeChange,
    getPromptText,
    getPromptStructText,
    getPromptType,
  } = props
  const tooltip = useMemo(
    () =>
      promptType === PromptType.STRUCT
        ? '同步文本模式的prompt'
        : '同步结构化模式的prompt',
    [promptType],
  )
  const [rawOpen, { setTrue: openRaw, setFalse: closeRaw }] = useBoolean(false)
  const [structOpen, { setTrue: openStruct, setFalse: closeStruct }] =
    useBoolean(false)

  const { doTrack } = useTrack()

  const handleToRaw = useMemoizedFn((raw: string) => {
    onPromptTypeChange?.(PromptType.RAW, { raw })
    closeRaw()
    doTrack('agent_prompt_struct_transform_use', {
      target: PromptType.RAW,
    })
  })

  const handleToStruct = useMemoizedFn((struct: PromptStructItem[]) => {
    onPromptTypeChange?.(PromptType.STRUCT, { struct })
    closeStruct()
    doTrack('agent_prompt_struct_transform_use', {
      target: PromptType.STRUCT,
    })
  })

  const handleSync = () => {
    const type = getPromptType()
    if (type === PromptType.STRUCT) {
      const prompt = getPromptText()
      if (prompt) {
        openStruct()
      }
    } else {
      const p = getPromptStructText()
      if (p) {
        openRaw()
      }
    }
  }

  return (
    <>
      <Tooltip title={tooltip}>
        <div
          className={classNames(
            'flex-center w-24px h-24px rd-4px cursor-pointer hover:bg-bg_3/8',
            className,
          )}
          onClick={handleSync}
        >
          <IconFont name='tongbuneiyong' className='text-16px' />
        </div>
      </Tooltip>

      <PromptToRawModal
        open={rawOpen}
        getPrompt={getPromptStructText}
        onCancel={closeRaw}
        onConfirm={handleToRaw}
      />

      <PromptToStructModal
        open={structOpen}
        getPrompt={getPromptText}
        onCancel={closeStruct}
        onConfirm={handleToStruct}
        onOpenTip={() => {}}
      />
    </>
  )
})
