import { request } from '../../client'
import type {
  TaskResultDetail,
  TaskDetail,
  TaskResult,
  TaskListResult,
  TaskLogList,
} from './type'

export function getTask(
  task_id: string,
  conversation_id: string,
  flow_id: string,
) {
  return request.get<TaskResultDetail>('/v1/task_list/detail', {
    query: { task_id, conversation_id, flow_id },
  })
}

export function addTask(task: TaskDetail) {
  return request.post<string>('/v1/task_list/add', task)
}

export function updateTask(task: TaskResult) {
  return request.put('/v1/task_list/update', task)
}

export function deleteTask(task_id: string, conversation_id: string) {
  return request.delete('/v1/task_list/delete', {
    body: { task_id, conversation_id },
  })
}

export function enableTask(
  task: TaskListResult,
  conversation_id: string,
  enable: boolean,
) {
  return request.put('/v1/task_list/enable', {
    task_id: task.task_id,
    conversation_id,
    is_preset: task.is_preset,
    enable,
  })
}

export function executeTask(task_list_id: number) {
  return request.post('/v1/task_list/execute/task', {
    task_list_id,
  })
}

export function getTaskList(flow_id: string, conversation_id: string) {
  return request.get<TaskListResult[]>('/v1/task_list/list', {
    query: { flow_id, conversation_id },
  })
}

export function getTaskListDetail(
  task_id: string,
  conversation_id: string,
  flow_id: string,
) {
  return request.get<TaskListResult>('/v1/task_list/task/show/detail', {
    query: { task_id, conversation_id, flow_id },
  })
}

export function getTaskLog(
  task_id: string,
  conversation_id: string,
  page_no: number,
  page_size = 10,
) {
  return request.post<TaskLogList>('/v1/task_list/log/page', {
    task_id,
    conversation_id,
    page_no,
    page_size,
  })
}

export function getNextTaskRunTime(task_id: string, conversation_id: string) {
  return request.get<{ next_run_time: number }>('/v1/task_list/next_run_time', {
    query: { task_id, conversation_id },
  })
}

export function getConversationTaskInfo(
  flow_id: string,
  conversation_id: string,
) {
  return request.get<{ count: number; is_new_task: boolean }>(
    '/v1/task_list/conversation/task_count',
    {
      query: { flow_id, conversation_id },
    },
  )
}

export function refreshConversationTaskInfo(conversation_id: string) {
  return request.put('/v1/chat/conversation/change_task_time', {
    conversation_id,
  })
}
