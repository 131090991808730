import type { LLMChannels } from '@bty/global-types/model'

export interface Message {
  role: 'system' | 'user' | 'assistant'
  content: string
}

export enum PromptType {
  STRUCT = 'struct', // 结构化编辑
  RAW = 'raw', // 原始格式，文本编辑
}

export enum LLMContextType {
  JSON_VARIABLE = 'jsonVariable',
  MSG_LIST = 'messageList',
}

export enum PromptStructType {
  ROLE = 'role',
  BACKGROUND = 'background',
  SKILL = 'skill',
  TASK = 'task',
  REQUIREMENTS = 'requirements',
  OUTPUT = 'output',
  CUSTOM = 'custom',
}

export interface PromptStructItem {
  type: PromptStructType
  title: string // 标题
  content: string // 内容
  weight: number // 权重
  form_submit?: Record<string, any>
}

export interface LLMNodeData {
  name: string // 节点名称
  inputs: {
    model: string // 模型类型
    channel: LLMChannels // LLM Server 需要区分渠道
    temperature: number // 创造性
    top_p?: number // Top P
    presence_penalty?: number // 重复语句惩罚
    frequency_penalty?: number // 重复主题惩罚
    stream: boolean // 是否开启stream
    messages?: Message[] // chat内容
    plugin: {
      json_mode?: boolean // 启用JSON模式
    }
    system_content?: string
    promptType?: PromptType // prompt编辑类型，结构化or原始文本
    pre_defined_system_content?: string
    pre_defined_messages?: string | Message[]
    context_type?: LLMContextType
    structPrompt?: PromptStructItem[]
  }
  packageName?: 'integration.llm.LLMAction'
}

export interface IPromptListRequest {
  page_number: number
  page_size: number
  node_id: string
  flow_id: string
}

export interface IPromptListResponse {
  [key: string]: any
}

export interface IPromptItem {
  history_id: number
  node_id: string
  messages: LLMNodeData['inputs']['messages']
  params: Omit<LLMNodeData['inputs'], 'messages'>
  response: string
  usage?: Record<string, any>
  finish_reason: string
  flow_input: Record<string, any>
  variable: Record<string, any>
  created_by: number
  created_time: Date
  favorite_id: string
  show_name: string
  run_env: string
}

export interface IPromptTemplate {
  template_name: string
  prompt: string
  icon: string

  ext: Record<string, string[]>
}
