import { userCenterRequest, request } from '../../client'
import type { SendSMSRequest } from './type'

export function SendSMS(data: SendSMSRequest) {
  return request.post<{ randomCode: string }>('/v1/sms/sendSms', data)
}

export function sendVerifyCode(data: { phone: string }) {
  const formData = new FormData()
  formData.append('phone', data.phone)
  return userCenterRequest.post(
    '/user-center/auth/sendVerifyCode',
    formData,
    // {
    //   headers: {
    //     'Content-Type': 'application/x-www-form-urlencoded',
    //   },
    // }
  )
}
