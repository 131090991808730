import { Button, Form, Upload, message } from 'antd'
import React from 'react'
import OSS from 'ali-oss'
import { getSTSToken } from '@apis/oss'
import { updateUser } from '@apis/user'
import type { User } from '@apis/user/type'
import { Input, Avatar } from '@/components'

interface FormItemAvatarProps {
  value?: string
  onChange?: (value?: string) => void
}

function FormItemAvatar({ value, onChange }: FormItemAvatarProps) {
  const username = Form.useWatch<string>('username')

  return (
    <div className='flex items-center'>
      <Avatar name={username} src={value} size='large' />
      <Upload
        customRequest={async opt => {
          const file = opt.file as File
          try {
            const token = await getSTSToken()
            if (token) {
              const ossClient = new OSS({
                region: 'oss-cn-hangzhou',
                accessKeyId: token.access_key_id,
                accessKeySecret: token.access_key_secret,
                stsToken: token.security_token,
                bucket: import.meta.env.VITE_AI_DATASTORE_RESOURCE_BUCKET,
              })
              const response = await ossClient.multipartUpload(
                `/ai/agentAvatar/${new Date().getTime()}${file?.name}`,
                file,
                {},
              )
              const url = (
                (response.res as any).requestUrls as string[] | undefined
              )?.[0]?.split('?')[0]
              onChange?.(url)
            }
          } catch (_) {}
        }}
        multiple={false}
        maxCount={1}
        showUploadList={false}
      >
        <Button
          className='ml-10 p-0 w-76px h-36px text-center font-medium border-line rounded-8px'
          type='text'
          htmlType='button'
        >
          更换头像
        </Button>
      </Upload>
    </div>
  )
}

type FormValues = Pick<User, 'avatar' | 'username'>

interface FormProfileProps {
  user: User
  onOk: (user: User) => void
}

function InternalFormProfile({ user, onOk }: FormProfileProps) {
  const [form] = Form.useForm<FormValues>()

  const handleFinish = async (values: FormValues) => {
    updateUser(values)
      .then(() => {
        message.success('保存成功')
        onOk({ ...user, ...values })
      })
      .catch(_ => {
        message.error('保存失败')
      })
  }

  return (
    <Form
      className='flex-1 p-20'
      form={form}
      initialValues={{
        avatar: user.avatar,
        username: user.username,
      }}
      layout='vertical'
      requiredMark={false}
      onFinish={handleFinish}
    >
      <Form.Item
        className='[&_.ant-form-item-label]:font-medium'
        name='avatar'
        label='头像'
      >
        <FormItemAvatar />
      </Form.Item>
      <Form.Item
        className='[&_.ant-form-item-label]:font-medium'
        name='username'
        label='昵称'
        rules={[{ required: true, message: '请输入昵称' }]}
      >
        <Input
          className='w-278px'
          size='large'
          placeholder='请输入昵称'
          maxLength={50}
        />
      </Form.Item>
      <Form.Item shouldUpdate noStyle>
        {form => (
          <Button
            className='w-92px rounded-8px !text-14px font-medium not-disabled:hover:opacity-80 disabled:!text-white disabled:!bg-#7b61ff disabled:!bg-op-40'
            type='primary'
            size='large'
            htmlType='submit'
            disabled={!form.isFieldsTouched()}
          >
            保存
          </Button>
        )}
      </Form.Item>
    </Form>
  )
}

export const FormProfile = React.memo(InternalFormProfile)
