import { http } from '@bty/http-client'
import type { TemplateAppData } from './type'

export async function getTemplateAppData(): Promise<TemplateAppData> {
  const res = await http({
    url: `${import.meta.env.API_TEMPLATE_OSS_URL}/template_app.json`,
    query: {
      t: +new Date(),
    },
  })
  return res.data
}
