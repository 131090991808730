import type { FC } from 'react'
import { useState } from 'react'
import { message } from 'antd'
import { useRequest } from 'ahooks'
import { difference, findIndex, uniq } from 'lodash-es'
import { getDatasetTags, updateDocument } from '@apis/datastore'
import { Modal, Button } from '@/components'
import { DocumentTag } from '@/features/datastore/components/DocumentTag.tsx'

import { DATASTORE_MAX_TAG_COUNT } from '@/features/datastore/constant'
import KeywordsEditor from './KeywordsEditor'

interface TagEditorModalProps {
  title: string
  documentId: number
  datasetId: number
  defaultValue?: string[]
  open?: boolean
  onCancel?: () => void
  onFinish?: () => void
}

export const TagEditorModal: FC<TagEditorModalProps> = props => {
  const {
    open,
    onCancel: onModalClose,
    title,
    documentId,
    datasetId,
    onFinish,
  } = props
  const [value, setValue] = useState<string[]>(props.defaultValue ?? [])
  const [tags, setTags] = useState<string[]>([])
  const getDatasetTasAndTransform = async (id: number) => {
    const res = await getDatasetTags(id)
    return res?.map(item => item.tag)
  }
  const { data: originTags = [] } = useRequest(getDatasetTasAndTransform, {
    defaultParams: [datasetId],
    onSuccess: res => {
      setTags(res)
    },
  })
  const { runAsync: updateDocumentTags, loading: saveLoading } = useRequest(
    updateDocument,
    { manual: true },
  )

  const addNewTag = (tagValue: string) => {
    if (tagValue.trim()) {
      if (value.includes(tagValue)) {
        message.warning(`文档已存在【${tagValue}】tag`)
      } else {
        // 添加tag
        if (value.length < DATASTORE_MAX_TAG_COUNT) {
          setValue(uniq([...value, tagValue]))
          // 添加tag池
          setTags(uniq([...tags, tagValue]))

          // setValue([...value, tagValue])
          // // 添加tag池
          // setTags([...tags, tagValue])
        }
      }
    }
  }

  const onTagRemove = (tagName: string, index: number) => {
    const newValue = [...value]
    newValue.splice(index, 1)
    if (!originTags.includes(tagName)) {
      setTags(tags.filter(tag => tag !== tagName))
    }
    setValue(newValue)
  }

  const onTagAdd = (tag: string) => {
    if (value.includes(tag)) {
      onTagRemove(tag, value.indexOf(tag))
      return
    }
    if (value.length < DATASTORE_MAX_TAG_COUNT) {
      setValue([...value, tag])
    }
  }

  const onTagsChange = async (allTags: string[]) => {
    if (allTags.length > value.length) {
      const tag = difference(allTags, value)
      addNewTag(tag[0])
    } else if (allTags.length < value.length) {
      const tag = difference(value, allTags)
      const idx = findIndex(value, o => o === tag[0])
      onTagRemove(tag[0], idx)
    }
  }

  const onSave = async () => {
    await updateDocumentTags({
      file_id: documentId,
      tags: value,
    })
    try {
      await onFinish?.()
    } finally {
      onModalClose?.()
    }
  }

  return (
    <Modal
      title={title}
      open={open}
      onCancel={onModalClose}
      footer={null}
      width={600}
      bodyStyle={{
        padding: '0 0 56px',
        minHeight: 380,
        maxHeight: '70vh',
        overflowY: 'auto',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div className='px-32 py-24 h-full'>
        <div className='pb-12 b-b b-line b-op-60'>
          <p>
            <span className='font-500'>已选标签</span>
            <span className='ml-8 font-400 line-height-14px text-12px c-font_1'>
              （{value.length}/{DATASTORE_MAX_TAG_COUNT}）
            </span>
          </p>
          <div className='mt-12 flex flex-wrap gap-8'>
            <KeywordsEditor
              value={value}
              readOnly={false}
              onChange={onTagsChange}
              showHeader={false}
              maxKeywordsCount={DATASTORE_MAX_TAG_COUNT}
              maxValueLength={30}
            />
          </div>
        </div>
        <div className='pt-24'>
          <p className='font-500'>全部标签</p>
          <div className='mt-12 flex flex-wrap gap-8'>
            {tags.map((tag, index) => {
              const isSelected = value.includes(tag)
              return (
                <DocumentTag
                  className='line-height-14px! text-14px'
                  maxTagWidth='fit-content'
                  key={tag + index}
                  selected={isSelected}
                  disabled={
                    value.length >= DATASTORE_MAX_TAG_COUNT && !isSelected
                  }
                  closable={false}
                  onClick={() => onTagAdd(tag)}
                >
                  {tag}
                </DocumentTag>
              )
            })}
          </div>
        </div>
      </div>
      <div className='px-32 pb-24 flex items-center justify-end gap-12 absolute bottom-0 w-full left-0 bg-white rounded-[0_0_10px_10px]'>
        <Button onClick={onModalClose}>取消</Button>
        <Button type='primary' onClick={onSave} loading={saveLoading}>
          确定
        </Button>
      </div>
    </Modal>
  )
}
