import { useMemo, useState } from 'react'
import type { TableColumnsType } from 'antd'
import dayjs from 'dayjs'
import type {
  AppMonitorType,
  AppUsageRankingItem,
  UserUsageRankType,
} from '@apis/monitor/type'
import { StaticTimeTypes } from '@apis/monitor/type'
import {
  getAppMonitorUsageRankingDetail,
  queryUserUsageRank,
} from '@apis/monitor'
import { ChartDashboardPane } from '@/features/agent/AgentAnalysis/ChartDashboardPane'
import MonitorDrawer from '@/components/MonitorDrawer'
import type { GeneralTimeParams } from '../type'
import { useMonitorDetailReq } from '../hooks/useMonitorDetailReq'

function getRankColumns(options: {
  flowType: 'AGENT' | 'FLOW'
  onLogJump: (username: string) => void
}) {
  const { flowType, onLogJump } = options
  const columns: TableColumnsType<AppUsageRankingItem> = [
    {
      title: '序号',
      key: 'index',
      render: (_, _record, index) => {
        return index + 1
      },
    },
    {
      title: '用户',
      dataIndex: 'username',
      key: 'user_id',
    },
    {
      title: '消耗积分',
      dataIndex: 'consumed_points',
      key: 'consumed_points',
      sorter: true,
    },
    {
      title: flowType === 'AGENT' ? '生成消息' : '运行次数',
      dataIndex: 'usage_count',
      key: 'usage_count',
      sorter: true,
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => {
        return (
          <div
            className='c-#7b61ff cursor-pointer'
            onClick={e => {
              e.stopPropagation()
              onLogJump(record.username)
            }}
          >
            查看日志
          </div>
        )
      },
    },
  ]
  return columns
}

interface UsageRankPaneProps {
  flowId: string
  flowType: AppMonitorType
  runType?: string[]
  onLogJump: (username: string) => void
}

export function UsageRankPane(props: UsageRankPaneProps) {
  const { flowId, flowType, runType, onLogJump } = props

  const [rankModalOpen, setRankModalOpen] = useState(false)
  const [timeParams, setTimeParams] = useState<GeneralTimeParams>({
    type: StaticTimeTypes.CUSTOM,
    startDate: dayjs().subtract(8, 'day').format('YYYY-MM-DD HH:mm:ss'),
    endDate: dayjs().subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
  })

  const baseParams = useMemo(
    () => ({
      flow_id: flowId,
      flow_type: flowType,
      run_type: runType,
      page: 1,
      page_size: 10,
      statistic_time: timeParams.type,
      start_date: timeParams.startDate,
      end_date: timeParams.endDate,
    }),
    [flowId, flowType, runType, timeParams],
  )

  const { fetchList, ...rest } = useMonitorDetailReq({
    api: getAppMonitorUsageRankingDetail,
    open: rankModalOpen,
    defaultParams: baseParams,
    outerTimeType: timeParams.type,
    sortFieldsMap: {
      usage_count: 'run_count',
    },
  })

  const onDetailEnter = async () => {
    await fetchList({
      ...baseParams,
      page: 1,
    })
    setRankModalOpen(true)
  }

  const params = useMemo(
    () => ({
      flow_id: flowId,
      flow_type: flowType,
      statistic_time: 'weekly',
    }),
    [flowId, flowType],
  )

  return (
    <>
      <ChartDashboardPane<UserUsageRankType>
        className='mt-24'
        chartClassName='flex-0.3333 last:mr-72'
        type='bar'
        xField='value'
        yField='username'
        title='用量排行 Top10'
        color={['#7B61FF', '#5784FD']}
        addonAfterTitle={
          <span
            className='text-14px text-#9e9e9e mr-12 cursor-pointer'
            onClick={event => {
              event.stopPropagation()
              onDetailEnter()
            }}
          >
            完整排行
          </span>
        }
        filter={{
          ignoreSubOptions: true,
          onChange: value => {
            setTimeParams({
              type: value.type as any,
              startDate: value.start_date,
              endDate: value.end_date,
            })
          },
        }}
        service={{
          call: queryUserUsageRank,
          params,
        }}
        showTooltip
        onDetailClick={(xFieldValue: string) => {
          if (xFieldValue) {
            onLogJump(xFieldValue)
          }
        }}
      />
      {rankModalOpen && (
        <MonitorDrawer
          title='用量排行'
          columns={getRankColumns({
            flowType,
            onLogJump,
          })}
          filter={{
            defaultValue: timeParams.type,
            defaultSubValue: [
              dayjs(timeParams.startDate) || null,
              dayjs(timeParams.endDate) || null,
            ],
          }}
          searcher={{
            placeholder: '搜索用户',
          }}
          {...rest}
          onClose={() => {
            setRankModalOpen(false)
          }}
        />
      )}
    </>
  )
}
