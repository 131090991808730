import type {
  ChatMessagePCInstance,
  SendMessageParams,
} from '@bty/chat-renderer-pc'
import { forwardRef, memo, useEffect, useMemo, useRef } from 'react'
import { useMemoizedFn, useRequest } from 'ahooks'
import { isEmpty } from 'lodash-es'
import { message } from 'antd'
import { ChatbotRunType, type GenerateTitleParams } from '@bty/chat-types'
import { generateTitleFromDiffConversation } from '@apis/chat-bot'
import emptyImg from '@/assets/empty.png'
import { AgentChatMessage as AgentChatMessagePure } from '@/features/chat/AgentChatMessage'
import { useAgentDiffStore } from '@/store/agentDiffStore'
import { moreRef } from '@/utils/react'
import { wait } from '@/utils/wait'
import { usePluginActionOnAgent } from '@/features/chat/hooks/usePluginActionOnAgent'

interface AgentChatProps {
  id: number
  robotId: string
  onDelete: (ids: (string | undefined)[]) => void
}

const AgentMessageChatInner = forwardRef<ChatMessagePCInstance, AgentChatProps>(
  (props, ref) => {
    const { id, robotId, onDelete } = props

    const chatRef = useRef<ChatMessagePCInstance>(null)

    const { runAsync: onTitleGenerate } = useRequest(
      generateTitleFromDiffConversation,
      {
        manual: true,
      },
    )

    const open = useAgentDiffStore(s => s.open)
    const variableValue = useAgentDiffStore(s => s.variableValue)
    const { conversationId, modelConfig } = useAgentDiffStore(
      s => s.diffConfigs[id],
    )
    const changeDiffId = useAgentDiffStore(s => s.changeDiffId)
    const subscribeVariableValue = useAgentDiffStore(
      s => s.subscribeVariableValue,
    )
    const subscribeInput = useAgentDiffStore(s => s.subscribeMessage)
    const subscribeRuleChange = useAgentDiffStore(s => s.subscribeRuleChange)
    const subscribeStop = useAgentDiffStore(s => s.subscribeStop)
    const publishMessage = useAgentDiffStore(s => s.publishMessage)
    const publishChange = useAgentDiffStore(s => s.publishChange)
    const changeGenerate = useAgentDiffStore(s => s.changeGenerate)

    const getVariables = useMemoizedFn(() => variableValue)

    const handleEditMessage = useMemoizedFn(
      (message: string, file: SendMessageParams['file']) => {
        publishChange(message, file)
      },
    )

    const { pluginItemOnMessageRender } = usePluginActionOnAgent()

    const handleQuestionSend = useMemoizedFn((data: string) => {
      const generateMap = useAgentDiffStore.getState().generateMap
      if ([...generateMap.values()].some(e => !!e)) {
        message.warning('运行中，请稍后再试')
        return false
      }

      publishMessage(data)
    })

    const handleRegenerate = useMemoizedFn(
      (message: string, file: SendMessageParams['file']) => {
        // 重新生成不需要同步
        // publishMessage(message, file, 'regenerate')
        if (!conversationId) return
        chatRef.current?.sendMessage({
          conversationId,
          content: message,
          file,
          action: 'regenerate',
        })
      },
    )

    const handleChatSend = useMemoizedFn(async () => {
      changeGenerate(id, true)
    })

    const handleChatEnd = useMemoizedFn(() => {
      changeGenerate(id, false)
    })

    const handleVariableChange = useMemoizedFn(
      async (_newValue, update: boolean) => {
        if (update) {
          changeDiffId(id, '')
          await onDelete([conversationId])
        }
      },
    )

    const handleInputChange = useMemoizedFn(async (message, file, action) => {
      if (isEmpty(modelConfig)) return

      let tempId = conversationId
      if (!tempId) {
        const res = await chatRef.current?.create({
          index: id,
          title_suffix: `#0${id + 1}`,
          model_config: {
            model: modelConfig.model,
            channel: modelConfig.channel,
            temperature: modelConfig.temperature,
            prompt_plugins: {
              time_prompt: modelConfig.time_prompt,
              length_prompt: modelConfig.length_prompt,
            },
          },
        })
        tempId = res?.conversationId
        if (!tempId) return
        changeDiffId(id, tempId)

        if (res?.sessionId) {
          await chatRef.current?.saveVariables(
            getVariables(),
            tempId,
            res.sessionId,
          )
        }

        await wait(100)
      }

      chatRef.current?.sendMessage({
        conversationId: tempId,
        content: message,
        file,
        action,
      })
    })

    const handleRuleChange = useMemoizedFn(async () => {
      await chatRef.current?.refresh()
    })

    const handleStop = useMemoizedFn(() => {
      chatRef.current?.stop()

      changeGenerate(id, false)
    })

    const handleTitleGenerate = useMemoizedFn((data: GenerateTitleParams) => {
      onTitleGenerate({
        ...data,
        is_main_model: id === 0,
      })
    })

    useEffect(() => {
      if (isEmpty(modelConfig)) return
      return subscribeVariableValue(handleVariableChange)
    }, [modelConfig])

    useEffect(() => {
      if (isEmpty(modelConfig)) return
      return subscribeInput(handleInputChange)
    }, [modelConfig])

    useEffect(() => {
      if (isEmpty(modelConfig)) return
      return subscribeRuleChange(handleRuleChange)
    }, [modelConfig])

    useEffect(() => {
      if (isEmpty(modelConfig)) return
      return subscribeStop(handleStop)
    }, [modelConfig])

    useEffect(() => {
      if (!open) return
      chatRef.current?.scrollToBottom()
    }, [open])

    const placeholder = useMemo(() => {
      return (
        <div className='h-60% flex-center flex-col'>
          <img className='w-124px' src={emptyImg} alt='' />
          <div className='text-font_1 text-opacity-60'>
            {isEmpty(modelConfig) ? '请选择模型' : '输入问题后开始'}
          </div>
        </div>
      )
    }, [modelConfig])

    return (
      <AgentChatMessagePure
        ref={moreRef(ref, chatRef)}
        robotId={robotId}
        conversationId={conversationId}
        runType={ChatbotRunType.MULTIPLE_MODELS}
        clearText='规则已修改，新会话开始'
        // botMessageProps={{
        //   backgroundColor: 'transparent',
        // }}
        paddingX={20}
        showPlaceholder={isEmpty(modelConfig)}
        placeholder={placeholder}
        getVariables={getVariables}
        onTitleGenerate={handleTitleGenerate}
        onEditMessage={handleEditMessage}
        onQuestionSend={handleQuestionSend}
        onRegenerate={handleRegenerate}
        onChatSend={handleChatSend}
        onChatEnd={handleChatEnd}
        onChatError={handleChatEnd}
        pluginItemOnMessageRender={pluginItemOnMessageRender}
      />
    )
  },
)

export const AgentChatMessage = memo(AgentMessageChatInner)
