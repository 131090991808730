import { type Node } from 'reactflow'
import type { NodeType } from '@/features/nodes/base'
import { NodeOperationTypes } from '@/features/nodes/base'
import { getNodeMetaByType } from '@/features/nodes'
import { SingleNodeOperation } from './SingleNodeOperation'
import { MultiBranchNodeOperation } from './MultiBranchNodeOperation'
import { LoopNodeOperation } from './LoopNodeOperation'
import { TemplateNodeOperation } from './TemplateNodeOperation'

export const nodeOperationMap = {
  [NodeOperationTypes.SINGLE_NODE]: new SingleNodeOperation(),
  [NodeOperationTypes.TEMPLATE_NODE]: new TemplateNodeOperation(),
  [NodeOperationTypes.MULTI_BRANCH_NODE]: new MultiBranchNodeOperation(),
  [NodeOperationTypes.LOOP_NODE]: new LoopNodeOperation(),
}

export function getOperationByNodeType(type: NodeType) {
  const meta = getNodeMetaByType(type)
  if (!meta) {
    console.error('目标节点无对应元数据')
    return null
  }
  return nodeOperationMap[meta.operationType]
}

// 判断当前node是否在nodes中存在
export function checkNodeInNodes(
  currentNode: Node,
  nodes: Node[],
  nodeMap: Record<string, Node>,
) {
  function getParentNodes(nodeId: string): string[] {
    const parentNodes: string[] = []
    let node = nodeMap[nodeId]
    // 预防循环依赖
    const visited = new Set<string>()

    while (node && node.data.relation && !visited.has(node.id)) {
      visited.add(node.id)
      const relation = node.data.relation
      const parentId =
        relation.loopNodeId ||
        relation.templateNodeId ||
        relation.branchNodeId ||
        relation.conditionNodeId

      if (parentId && nodeMap[parentId]) {
        parentNodes.push(parentId)
        node = nodeMap[parentId]
      } else {
        break
      }
    }

    return parentNodes
  }

  const nodeIds = new Set(nodes.map(node => node.id))
  const parentNodes = getParentNodes(currentNode.id)

  return parentNodes.some(parentId => nodeIds.has(parentId))
}
