import { request } from '../../client'
import { getApolloConfig } from '../apollo'
import type { IPromptItem, IPromptListRequest, IPromptTemplate } from './type'

// 获取历史记录列表接口
export async function getLLMNodePromptRecordList(
  req: IPromptListRequest,
): Promise<{ list: IPromptItem[]; total: number }> {
  const result = await request.get<any>(`/v1/prompt/${req.node_id}/history`, {
    query: req,
  })
  return {
    list: result.data_list,
    total: result.total_count,
  }
}

// 设置收藏状态接口
export async function addPrompt2CollectedById(
  history_id: any,
  favorite_reason: string,
): Promise<any> {
  const result = await request.post<any>('/v1/prompt/favorite/add', {
    history_id,
    favorite_reason,
  })
  return result
}

export async function removePrompt2CollectedById(
  favorite_id: string,
): Promise<any> {
  const result = await request.delete<any>(`/v1/prompt/favorite/${favorite_id}`)
  return result
}

export async function updatePrompt2CollectedById(
  favorite_id: string,
  favorite_reason: string,
): Promise<any> {
  const result = await request.put<any>(
    `/v1/prompt/favorite/${favorite_id}?favorite_reason=${favorite_reason}`,
    {
      favorite_reason,
      favorite_id,
    },
  )
  return result
}

export async function getLLMNodePromptFavorList(
  req: IPromptListRequest,
): Promise<any> {
  const result = await request.get<any>('/v1/prompt/favorite/list', {
    query: req,
  })
  return {
    list: result.data_list,
    total: result.total_count,
  }
}

export async function getLLMNodeSnapshotPromptByHistoryId(
  history_id: string,
): Promise<any> {
  const result = await request.get<any>(`/v1/prompt/history/${history_id}`)
  const inputs = {
    ...result.params,
    messages: result.messages,
    ...result?.inputs,
  }
  return {
    inputs,
    variables: result.variable || {},
    resultInfo: {
      response: result.response,
      finish_reason: result.finish_reason,
    },
    created_time: result.created_time,
  }
}

export async function getLLMNodeSnapshotPromptByFavoriteId(
  favorite_id: string,
): Promise<any> {
  const result = await request.get<any>(`/v1/prompt/favorite/${favorite_id}`)
  const inputs = {
    ...result.params,
    messages: result.messages,
  }
  return {
    inputs,
    variables: result.variable || {},
    resultInfo: {
      response: result.response,
      finish_reason: result.finish_reason,
    },
  }
}

export async function getPromptRecommendList(): Promise<any> {
  const res = await getApolloConfig('prompt-recommend-list')
  let data: any = []
  try {
    data = JSON.parse(res.value)
  } catch (e) {
    console.error(e, 'getPromptRecommendList-error')
  }
  return data
}

export async function getPromptImproveData(): Promise<{
  prompt: string
  model: string
  stream: boolean
  temperature: string
}> {
  const res = await getApolloConfig('prompt_improve_params')
  let data: any = {}

  try {
    data = JSON.parse(res.value)
  } catch (e) {
    console.error(e, 'getPromptImproveData-error')
  }
  return data
}

export interface IGetUniversalPromptParam {
  module: string
  prompt_type: string
}

export async function getUniversalPrompt(
  params: IGetUniversalPromptParam,
): Promise<string> {
  const res = await request.get<any>('/v1/common/universal_prompt', {
    query: params,
  })
  return res?.prompt || ''
}

export function getPromptTemplate(): Promise<IPromptTemplate[]> {
  return request.get<IPromptTemplate[]>('/v1/prompt/templates')
}
