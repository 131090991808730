import { memo, useEffect, useRef, useState } from 'react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useBoolean, useMemoizedFn, useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { Spin } from 'antd'
import classNames from 'classnames'
import { getNextTaskRunTime, getTaskListDetail, getTaskLog } from '@apis/task'
import type { TaskListResult } from '@apis/task/type'
import { IconFont } from '@/components'
import { InfiniteScroll } from '@/components/InfiniteScrollComponent'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import emptyImg from '@/assets/empty.png'
import { TaskListItem } from './TaskListItem'
import { LogItem, LogTag } from './TaskLogItem'

interface TaskLogProps {
  agentId: string
  taskId: string
  taskRecordId?: string
  readonly?: boolean
  isDelete?: boolean
  logModalPosition?: { top?: number; right?: number; bottom?: number }
  getConversationId: () => string
  onUpdateList?: (refresh?: boolean, task?: TaskListResult) => void
  toEdit?: (task: TaskListResult) => void
  onBack?: () => void
  onClose?: () => void
}

export const TaskLog = memo((props: TaskLogProps) => {
  const {
    agentId,
    taskId,
    readonly,
    logModalPosition,
    isDelete = false,
    taskRecordId,
    getConversationId,
    onUpdateList,
    toEdit,
    onBack,
    onClose,
  } = props

  const [taskInit, { setTrue: setTaskInit }] = useBoolean(false)

  const { data: task, refresh: refreshTask } = useRequest(
    () => getTaskListDetail(taskId, getConversationId(), agentId),
    {
      ready: !!taskId,
      refreshDeps: [taskId],
      onFinally: setTaskInit,
    },
  )

  const { data: nextInfo } = useRequest(
    () => getNextTaskRunTime(taskId, getConversationId()),
    {
      ready: !!taskId,
      refreshDeps: [taskId],
    },
  )

  const ref = useRef<HTMLDivElement>(null)
  const pageNoRef = useRef(1)
  const [loading, setLoading] = useState(true)
  const [logs, setLogs] = useState<any[]>([])
  const [totalPage, setTotalPage] = useState(1)

  const handleLoadLog = useMemoizedFn(async () => {
    if (!taskId) return
    setLoading(true)
    const isFirst = pageNoRef.current === 1
    const newLogs = await getTaskLog(
      taskId,
      getConversationId(),
      pageNoRef.current,
    )
    setLogs(prev => {
      return isFirst ? newLogs.data_list : [...prev, ...newLogs.data_list]
    })
    setTotalPage(newLogs.total_pages)
    setLoading(false)
    pageNoRef.current += 1
  })

  const handleEdit = useMemoizedFn(() => {
    if (!task) return
    toEdit?.(task)
  })

  useEffect(() => {
    if (logs.length) return
    handleLoadLog()
  }, [taskId])

  useEffect(() => {
    const handle = async () => {
      pageNoRef.current = 1
      handleLoadLog()
      refreshTask()
    }

    window.addEventListener('chatMessageUpdate', handle)
    return () => {
      window.removeEventListener('chatMessageUpdate', handle)
    }
  }, [])

  useEffect(() => {
    const handle = () => {
      refreshTask()
    }
    window.addEventListener('taskListUpdate', handle)

    return () => {
      window.removeEventListener('taskListUpdate', handle)
    }
  }, [])

  return (
    <div className='w-full flex flex-col h-full overflow-hidden'>
      <div className='flex flex-center h-64px px-16px flex-none'>
        {onBack && (
          <button
            className='chat-icon-btn w-24px h-24px mr-6px'
            onClick={onBack}
          >
            <IconFont className='text-16px' name='fanhui' />
          </button>
        )}

        <span className='font-600 mr-auto text-16px'>任务详情</span>

        {onClose && (
          <button className='chat-icon-btn w-24px h-24px' onClick={onClose}>
            <IconFont className='text-16px' name='guanbi' />
          </button>
        )}
      </div>

      <Spin
        spinning={!taskInit && !isDelete}
        wrapperClassName={classNames('h-full', { hidden: taskInit })}
      >
        {isDelete && (
          <div className='h-60% flex-center flex-col'>
            <img className='w-128px' src={emptyImg} alt='' />
            <div className='text-font_1 text-opacity-60'>任务已被删除</div>
          </div>
        )}
      </Spin>

      {task && (
        <TaskListItem
          task={task}
          readonly={readonly}
          getConversationId={getConversationId}
          onUpdateList={onUpdateList}
          onEdit={handleEdit}
          onDelete={onBack}
          className='border-none! px-20px! pt-0px! pb-16px! mb-0px! cursor-unset!'
        />
      )}

      {task && (
        <div className='flex-1 overflow-hidden' ref={ref}>
          <OverlayScrollbarsComponent
            className='overflow-auto h-full px-16px'
            element='div'
            options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
            defer
          >
            <InfiniteScroll
              loading={loading}
              targetRoot={ref}
              hasMore={totalPage >= pageNoRef.current}
              loader={
                <div className='w-100% h-28px flex items-center flex-center'>
                  <Spin />
                </div>
              }
              load={handleLoadLog}
            >
              {task.is_enable && nextInfo?.next_run_time && (
                <div className='p-16px border-1px rounded-8px mb-10px'>
                  <div className='flex flex-center'>
                    <LogTag status='WAITING' />
                    <div className='mr-auto ml-8'>
                      {dayjs(nextInfo.next_run_time * 1000).format(
                        'YYYY-MM-DD HH:mm',
                      )}
                    </div>
                  </div>
                </div>
              )}
              {logs.map(each => (
                <LogItem
                  key={each.record_id}
                  initialExpanded={taskRecordId === each.record_id}
                  log={each}
                  logModalPosition={logModalPosition}
                />
              ))}
            </InfiniteScroll>
            {!loading && !logs.length && !nextInfo?.next_run_time && (
              <div className='h-60% flex-center flex-col'>
                <img className='w-128px' src={emptyImg} alt='' />
                <div className='text-font_1 text-opacity-60'>暂无执行日志</div>
              </div>
            )}
          </OverlayScrollbarsComponent>
        </div>
      )}
    </div>
  )
})
