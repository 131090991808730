import { Tooltip } from 'antd'
import classNames from 'classnames'
import { memo, useEffect, useRef, useState } from 'react'
import { useMemoizedFn, useRequest } from 'ahooks'
import {
  getConversationTaskInfo,
  refreshConversationTaskInfo,
} from '@apis/task'
import { IconFont } from '@/components'
import { useGetConversationId } from './hooks/useGetConversationId'

interface TaskChatIconProps {
  agentId: string
  conversationId: string
  open: boolean
  onClick: () => void
}

export const TaskChatIcon = memo((props: TaskChatIconProps) => {
  const { agentId, conversationId, open, onClick } = props

  const ref = useRef<HTMLButtonElement>(null)
  const [tip, setTip] = useState(false)

  const { data, refresh } = useRequest(
    () => getConversationTaskInfo(agentId, conversationId),
    {
      refreshDeps: [agentId, conversationId],
      onSuccess(data) {
        setTip(!!data.is_new_task)
      },
    },
  )

  const handleClick = useMemoizedFn(() => {
    onClick()
    if (tip) {
      setTip(false)
      refreshConversationTaskInfo(conversationId)
    }
  })

  useEffect(() => {
    const handle = () => {
      refresh()
    }
    window.addEventListener('taskListUpdate', handle)

    return () => {
      window.removeEventListener('taskListUpdate', handle)
    }
  }, [])

  return (
    <Tooltip
      getPopupContainer={() => ref.current || document.body}
      open={tip}
      title='有新预设任务可用'
      rootClassName='[&_.ant-tooltip-inner]:bg-primary! [&_.ant-tooltip-arrow:after]:bg-primary!'
    >
      <button
        ref={ref}
        className={classNames('chat-icon-btn w-auto px-8px mr-8px relative', {
          'bg-bg_3 bg-op-8': open,
        })}
        onClick={handleClick}
      >
        <IconFont className='color-[#17171D]  text-16px' name='task' />
        <span className='ml-4px text-nowrap'>
          任务
          {!!data?.count && <span className='ml-4px'>{data?.count}</span>}
        </span>
      </button>
    </Tooltip>
  )
})

interface TaskAgentIconProps {
  agentId: string
  // conversationId: string
  getConversationId: () => string
  onClick: () => void
}

export const TaskAgentIcon = memo((props: TaskAgentIconProps) => {
  const { agentId, getConversationId, onClick } = props

  const conversationId = useGetConversationId(getConversationId)

  const ref = useRef<HTMLButtonElement>(null)
  const [tip, setTip] = useState(false)

  const { data, refresh } = useRequest(
    () => getConversationTaskInfo(agentId, conversationId),
    {
      ready: !!agentId && !!conversationId,
      refreshDeps: [agentId, conversationId],
      onSuccess(data) {
        setTip(!!data.is_new_task)
      },
    },
  )

  const handleClick = useMemoizedFn(() => {
    onClick()
    if (tip) {
      setTip(false)
      refreshConversationTaskInfo(conversationId)
    }
  })

  useEffect(() => {
    const handle = () => {
      refresh()
    }
    window.addEventListener('taskListUpdate', handle)
    window.addEventListener('agentRuleChange', handle)

    return () => {
      window.removeEventListener('taskListUpdate', handle)
      window.removeEventListener('agentRuleChange', handle)
    }
  }, [])

  return (
    <Tooltip
      getPopupContainer={() => ref.current || document.body}
      open={tip}
      title='有新预设任务可用'
      rootClassName='[&_.ant-tooltip-inner]:bg-primary! [&_.ant-tooltip-arrow:after]:bg-primary!'
    >
      <span
        ref={ref}
        className='flex flex-center text-14px text-#7B61FF! mr-8px cursor-pointer rounded-6px p-4px hover:bg-[rgba(98,105,153,0.08)]'
        onClick={handleClick}
      >
        <IconFont name='task' className='mr-4px' />
        <span>
          任务{!!data?.count && <span className='ml-4px'>{data?.count}</span>}
        </span>
      </span>
    </Tooltip>
  )
})
