import { omit, isNil } from 'lodash-es'

import { datasetRequest } from '../../client'
import type { ForkTaskStatus } from './model'
import type {
  QAGroupItem,
  DeleteQAGroupRequest,
  SaveQAGroupRequest,
  SplitItem,
  QAContentResponse,
  DataStoreResponse,
  CreateTextDocumentRequest,
  CreateFileDocumentRequest,
  DataStoreItem,
  UpdateDataStoreInfoRequest,
  SearchHistoryRequest,
  SearchHistoryItem,
  DocumentItem,
  DocumentSplitRequest,
  SplitContentResponse,
  SearchRequest,
  SearchResponse,
  SplitContentRequest,
  AddSplitContentRequest,
  UpdateSplitContentRequest,
  AssociatedApplication,
  DocumentUpdateRequest,
  UpdateDocumentBySplitRequest,
  BaseParagraphItem,
  IDocumentListReq,
  Paragraph,
  TokenSizeResponse,
  IEmbeddingModalItem,
  SynonymsType,
} from './type'

const version = 'v1'

export function downloadTemplate(urlPath: string, name: string) {
  return datasetRequest
    .get(`/v1/${urlPath}`)
    .then(response => response.blob())
    .then(blob => {
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${name}.xlsx`
      a.click()
      window.URL.revokeObjectURL(url)
    })
}

/**
 * @desc 获取知识库列表
 */
export function getDataStoreList() {
  return datasetRequest.get<DataStoreResponse>(`${version}/dataset/partition`)
}

export async function getAISummaryList(params: {
  ids: number[]
  file_id: number
}) {
  const addonAfter = params.ids ? `/${params.ids.join(',')}` : ''
  return datasetRequest.get<
    {
      chunk_id: string | number
      contents: Paragraph[]
      chunk_status: string
      error_text: string
      enable_ai_support: boolean
    }[]
  >(
    `${version}/dataset/${params.file_id}/contents/generate-summary-status${addonAfter}`,
  )
}

export async function generateAISummaryByChunkId(params: {
  id?: number | undefined
  file_id?: number
}) {
  const addonAfter = !isNil(params.id) ? `/${params.id}` : ''
  return datasetRequest.post<
    {
      id: string | number
      contents: Paragraph[]
      chunk_status: string
    }[]
  >(
    `${version}/dataset/${params.file_id}/contents/generate-summary${addonAfter}`,
  )
}

export function getDataStoreDetail(partitionId: number) {
  return datasetRequest.get<{ partition: DataStoreItem }>(
    `${version}/dataset/partition/${partitionId}`,
  )
}

// 创建知识库 新接口
export function createDataSet(data: {
  partition: CreateTextDocumentRequest['partition']
}) {
  return datasetRequest.post<{
    partition_id: number
  }>(`${version}/dataset/partition`, data)
}
/**
 * @desc 通过文本方式创建知识库，或者新建知识库文档
 */
export function createDocumentOrDataSet(
  data: CreateTextDocumentRequest | CreateFileDocumentRequest,
) {
  return datasetRequest.post<{
    partition_id: number
    file_ids?: number[]
  }>(`${version}/dataset/file`, data)
}

/**
 * @desc 获取知识库下文档列表
 */
export function getDocumentList<T extends boolean = false>(
  params: IDocumentListReq,
  pagination?: T,
) {
  return datasetRequest.get<
    T extends true
      ? {
          rows: DocumentItem[]
          totals: number
          page: number
          page_size: number
          failed_totals: number
        }
      : DocumentItem[]
  >(`${version}/dataset/partition-file/${params.partitionId}`, {
    query: {
      ...params,
      pagination,
    },
  })
}

/**
 * @desc 复制文档到其他知识库中
 */
export function copyDocumentsToDataset(data: {
  target_partition_id: number | string
  file_ids: number[]
}) {
  return datasetRequest.post(`${version}/dataset/file/fork`, data)
}

/**
 * @desc 批量删除文档
 */
export function deleteDocuments(ids: number[]) {
  return datasetRequest.delete(`${version}/dataset/file/${ids.join(',')}`)
}

export function deleteFailedDocumentsByPartitionId(partition_id: number) {
  return datasetRequest.delete(`${version}/dataset/failed_files`, {
    query: {
      partition_id,
    },
  })
}

/**
 * @desc 更新知识库信息
 */
export function updateDataStore(data: UpdateDataStoreInfoRequest) {
  return datasetRequest.patch(`${version}/dataset/partition`, data)
}

/**
 * @desc 删除知识库
 */
export function deleteDataStore(partitionId: number) {
  return datasetRequest.delete(`${version}/dataset/partition/${partitionId}`)
}

/**
 * @desc 命中查询
 */
export function searchData(req: SearchRequest) {
  const tagsQuery = req?.tags?.map(tag => `tags=${tag}`).join('&')
  return datasetRequest.get<SearchResponse>(
    `${version}/dataset/content-match${
      req.tags?.length ? `?${tagsQuery}` : ''
    }`,
    {
      query: {
        ...omit(req, 'tags'),
      },
    },
  )
}

/**
 *
 * @description 获取搜索历史
 */
export function getSearchHistory(req: SearchHistoryRequest) {
  return datasetRequest.get<{
    rows: SearchHistoryItem[]
    total: number
  }>(`${version}/dataset/history/content-match`, {
    query: {
      page: req.page,
      page_size: req.pageSize,
      partition_id: req.partition_id,
      file_ids: req.file_ids,
    },
    customErrorMessage: '获取搜索历史失败',
  })
}

/**
 *
 * @description 获取文档详情
 */
export function getDocumentDetail(file_id: number) {
  return datasetRequest.get<DocumentItem>(
    `${version}/dataset/file/extracted-text`,
    {
      query: {
        file_id,
      },
      timeout: 20 * 1000,
    },
  )
}

/**
 * @description 获取文档信息
 */
export function getDocumentInfo(field_id: number) {
  return datasetRequest.get<DocumentItem>(
    `${version}/dataset/file/summary/${field_id}`,
  )
}

export function updateDocument(req: DocumentUpdateRequest) {
  return datasetRequest.patch(
    `${version}/dataset/file/${req.file_id}`,
    omit(req, ['file_id']),
  )
}

/**
 * @description 更新文档信息
 */
export function updateDocumentContent(req: {
  file_id: number
  extracted_text: string
}) {
  return datasetRequest.patch(`${version}/dataset/file/extracted-text`, req, {
    timeout: 1000 * 20,
  })
}

export function updateDocumentBySplitOperation(
  req: UpdateDocumentBySplitRequest,
) {
  const { file_id, ...restData } = req
  return datasetRequest.post(
    `${version}/dataset/${file_id}/contents/perform-operations`,
    restData,
  )
}

/**
 * 更新文档分段信息并重新分段
 */
export function updateDocumentSplit(req: DocumentSplitRequest) {
  return datasetRequest.post(`${version}/dataset/file/split-file`, req)
}

export function getSplitPreview(req: DocumentSplitRequest) {
  return datasetRequest.post<BaseParagraphItem[]>(
    `${version}/dataset/file/split-preview`,
    req,
  )
}

/**
 * @description 分段信息查询
 */
export function getSplitContent(req: SplitContentRequest) {
  return datasetRequest.get<SplitContentResponse>(
    `${version}/dataset/${req.file_id}/contents`,
    {
      query: {
        next_chunk_id: req.next_chunk_id,
        page_size: req.page_size,
        need_total: req.need_total,
        direction: req.direction,
        search_words: req.search_words,
        chunk_status: req.chunk_status,
      },
    },
  )
}

/**
 * @description 知识库分段信息查询
 * @description 知识库相似问题添加了问题分组的概念，返回的结构与之前差异较大，因此需要转换成之前的结构兼容现有逻辑
 * 具体细节
 * 1. 需要将几个问题合并成一组
 * 2. 分页加载由之前的 chunk_id -> group_id
 * 3. 问答内容需要合并显示一组问题
 *
 * 为适应之前结构
 * 1. 仍使用 chunk_id 作为分页加载的依据，只不过其内容是 group_id
 * 2. 添加 qa_info 字段放分好组的问题和答案列表，渲染和更新逻辑主要依据该字段
 * 3. 其余字段仅用第一个问答的内容，保持字段不缺失避免报错
 */
export function transformQAGroupToSplitItem(
  answer_group_id: number,
  answers: Record<string, string> = {},
  ...contents: BaseParagraphItem[]
) {
  return {
    key: answer_group_id,
    chunk_id: answer_group_id,
    vector_id: contents[0].vector_id,
    file_id: contents[0].file_id,
    content: contents[0].content,
    enable: contents[0].enable,
    keywords: contents[0].keywords,
    extra_info: contents[0].extra_info,
    qa_info: {
      questions: contents.map(e => {
        return {
          id: e.chunk_id,
          keywords: e.keywords,
          value: e.content,
        }
      }),
      answers: Object.entries(answers || {}).map(([key, value], index) => {
        return {
          id: key + index,
          title: key,
          value,
        }
      }),
    },
  } as SplitItem
}

export async function getQAGroupList(req: SplitContentRequest) {
  const res = await datasetRequest.get<QAContentResponse>(
    `${version}/dataset/${req.file_id}/faq_contents`,
    {
      query: {
        next_group_id: req.next_chunk_id,
        page_size: req.page_size,
        need_total: req.need_total,
        direction: req.direction,
        search_words: req.search_words,
        chunk_status: req.chunk_status,
      },
    },
  )

  return {
    contents: res.groups.map(g => {
      return transformQAGroupToSplitItem(
        g.answer_group_id,
        g.answers,
        ...g.contents,
      )
    }),
    current_index_id: res.current_index_id,
    prev_chunk_id: res.prev_group_id,
    next_chunk_id: res.next_group_id,
    page_size: res.page_size,
    totals: res.totals,
  }
}

export async function saveQAGroup(req: SaveQAGroupRequest) {
  const groupList = await datasetRequest.post<QAGroupItem[]>(
    `${version}/dataset/${req.file_id}/faq_contents/save`,
    {
      answer_group_id: req.answer_group_id,
      insert_above_answer_group_id: req.insert_above_answer_group_id,
      chunks_to_delete: req.chunks_to_delete,
      contents: req.contents,
      answers: req.answers,
    },
  )

  const groupItem = groupList?.[0]
  if (!groupItem) return {} as SplitItem

  return transformQAGroupToSplitItem(
    groupItem.answer_group_id,
    groupItem.answers,
    ...groupItem.contents,
  )
}

export function deleteQAGroup(req: DeleteQAGroupRequest) {
  return datasetRequest.delete(
    `${version}/dataset/${req.file_id}/faq_contents/${req.answer_group_id}`,
  )
}

/**
 * @description 分段信息新增
 */
export function addSplitContent(req: AddSplitContentRequest) {
  return datasetRequest.post<BaseParagraphItem>(
    `${version}/dataset/${req.file_id}/contents`,
    {
      content: req.content,
      enable: req.enable,
      keywords: req.keywords,
      extra_info: req?.extra_info,
      insert_above_chunk_id: req?.insert_above_chunk_id,
    },
  )
}

/**
 * @description 当前文案长度的token信息
 */
export function fetchTokenSizeByWord(req: {
  file_id: number
  content: string
}) {
  return datasetRequest.post<TokenSizeResponse>(
    `${version}/dataset/calculate-token-consumption`,
    {
      content: req.content,
      file_id: req.file_id,
    },
  )
}

/**
 * @description 分段信息修改
 */
export function updateSplitContent(req: UpdateSplitContentRequest) {
  return datasetRequest.patch(
    `${version}/dataset/${req.file_id}/contents/${req.chunk_id}`,
    {
      content: req.content,
      enable: req.enable,
      keywords: req.keywords,
      extra_info: req?.extra_info,
    },
  )
}

/**
 * @description 获取知识库关联的应用列表
 */
export function getAssociatedApplication(partition_id: number) {
  return datasetRequest.get<AssociatedApplication[]>(
    `${version}/dataset/partition-application/${partition_id}`,
  )
}

export function getDatasetTags(partition_id: number) {
  return datasetRequest.get<Array<{ tag: string; file_num: number }>>(
    `${version}/dataset/partition-tags/${partition_id}`,
  )
}

/**
 * @description 检查知识库Fork状态
 */
export function checkForkTaskStatus(task_id: string) {
  return datasetRequest.get<{ state: ForkTaskStatus }>(
    `${version}/dataset/task_state/${task_id}`,
  )
}

/**
 * @description 获取可使用的embedding模型列表
 */
export function fetchEmbeddingModelList(): Promise<any[]> {
  return datasetRequest.get<IEmbeddingModalItem[]>(
    `${version}/dataset/embedding_models`,
  )
}

export function fileRetry(file_id: number) {
  const form = new FormData()
  form.append('file_id', String(file_id))
  return datasetRequest.post(`${version}/dataset/re_process_file`, form)
}

// 获取数据集同义词列表
export function getSynonymsList(partition_id: number) {
  return datasetRequest.get(
    `${version}/dataset/synonyms/partition/${partition_id}`,
  )
}

// 创建同义词
export function createSynonyms(partition_id: number, data: SynonymsType) {
  return datasetRequest.post(
    `${version}/dataset/synonyms/partition/${partition_id}`,
    {
      ...data,
      module: 'dataset',
    },
  )
}

// 删除数据集下的所有同义词
export function deleteSynonyms(partition_id: number) {
  return datasetRequest.delete<any>(
    `${version}/dataset/synonyms/partition/${partition_id}`,
  )
}

// 替换更新数据集同义词
export function updateSynonyms(synonyms_id: number, data: SynonymsType) {
  return datasetRequest.put<any>(`${version}/dataset/synonyms/${synonyms_id}`, {
    ...data,
    module: 'dataset',
  })
}

// 部分更新数据集同义词
export function updatePartSynonyms(
  synonyms_id: number,
  data: Partial<SynonymsType>,
) {
  return datasetRequest.patch<any>(
    `${version}/dataset/synonyms/${synonyms_id}`,
    {
      ...data,
      module: 'dataset',
    },
  )
}

// 删除单个同义词
export function deleteSingleSynonyms(synonyms_id: number) {
  return datasetRequest.delete<any>(
    `${version}/dataset/synonyms/${synonyms_id}`,
  )
}

// 批量删除同义词
export function deleteMultipleSynonyms(synonyms_ids: number[]) {
  return datasetRequest.delete<any>(
    `${version}/dataset/synonyms?synonym_ids=${synonyms_ids?.join(',')}`,
  )
}

// 获取同义词模板文件
export function getSynonymsTemplate(name: string) {
  return downloadTemplate('dataset/synonyms/template', name)
}

export function getQATemplate(name: string) {
  return downloadTemplate('dataset/qa/template', name)
}

// 从模板导入创建数据集同义词
export function importSynonyms(data: any) {
  return datasetRequest.post<any>(
    `${version}/dataset/synonyms/partition/import-synonyms`,
    data,
    { headers: { 'Content-Type': 'multipart/form-data' } },
  )
}

export function downloadFailReasonFile(
  partition_id: number,
  name = '失败记录',
) {
  return downloadTemplate(
    `dataset/file/record/fail/download?partition_id=${partition_id}`,
    name,
  )
}
