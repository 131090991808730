import { message, Popover } from 'antd'
import type { CSSProperties, PropsWithChildren } from 'react'
import { memo, useRef, useState } from 'react'
import { useRequest } from 'ahooks'
import classNames from 'classnames'
import type { TextAreaRef } from 'antd/es/input/TextArea'
import { dislike } from '@apis/report'
import { TextArea } from '../input'
import { Button } from '../button'
import { IconFont } from '../icon'

export enum SceneTypes {
  AGENT_EDIT = 1,
  AGENT_USE = 2,
  FLOW = 3,
  KNOWLEDGE = 4,
}

export interface IssueReportProps extends PropsWithChildren {
  sceneType: SceneTypes
  defaultDesc?: string
  className?: string
  extraData?: any
  getPopupContainer?: () => HTMLElement
  reportCallback?: (info: { status: 'success' | 'error'; e?: unknown }) => void
}

export const IssueReport = memo((props: IssueReportProps) => {
  const {
    defaultDesc = '',
    className,
    extraData,
    children,
    getPopupContainer,
    reportCallback,
  } = props
  const ref = useRef<HTMLDivElement>(null)
  const textareaRef = useRef<TextAreaRef>()
  const [submited, setSubmited] = useState(false)
  const [wrapperStyle, setWrapperStyle] = useState<CSSProperties>({})
  const [open, setOpen] = useState(false)
  const [description, setDescription] = useState(defaultDesc)
  const { runAsync: report, loading } = useRequest(dislike, {
    manual: true,
  })

  const onSubmit = async () => {
    if (loading) return
    try {
      const req = {
        record_id: extraData.record_id,
        user_opinion: defaultDesc,
      }
      await report(req)

      setSubmited(true)
      setDescription('')
      reportCallback?.({
        status: 'success',
        e: req,
      })
    } catch (error) {
      reportCallback?.({
        status: 'error',
        e: error,
      })
      // err
    }
  }

  const onShow = () => {
    if (extraData?.feedback_id) {
      message.warning('意见已反馈，不可重复提交')
      return
    }
    setOpen(true)
    setDescription(defaultDesc)
  }

  const onClose = () => {
    setSubmited(false)
    setOpen(false)
  }

  return (
    <Popover
      open={open}
      zIndex={990}
      // getPopupContainer={() => (children ? document.body : ref.current!)}
      getPopupContainer={getPopupContainer || (() => ref.current!)}
      destroyTooltipOnHide
      content={
        <div className='relative flex flex-col min-h-354px p-24px'>
          <div
            className='absolute top-24px right-24px flex-center w-20px h-20px b-rd-4px cursor-pointer hover:bg-#626999 hover:bg-op-12'
            onClick={onClose}
          >
            <IconFont name='guanbi' onClick={() => setOpen(false)} />
          </div>
          <div className='mb-24px text-18px font-500 text-#17171d'>
            问题上报
          </div>
          {submited ? (
            <div className='flex-col flex-center flex-1'>
              <div className='flex-center w-40px h-40px b-rd-50% bg-#00b042'>
                <IconFont name='success' className='text-20px text-#fff' />
              </div>
              <div className='mt-24px line-height-26px font-600'>上报成功</div>
              <div className='line-height-26px'>我们会尽快处理你的问题</div>
            </div>
          ) : (
            <>
              <div
                className='relative b-1 b-transparent b-rd-8px bg-#626999 bg-op-6'
                style={wrapperStyle}
              >
                <TextArea
                  ref={textareaRef}
                  className='b-none bg-transparent! resize-none! hover:bg-inherit hover:b-color-inherit focus:shadow-none'
                  rows={9}
                  placeholder='请输入问题描述'
                  value={description}
                  onChange={e => setDescription(e.target.value.trim())}
                  onFocus={() => {
                    setWrapperStyle({
                      borderColor: '#7b61ff',
                    })
                  }}
                  onBlur={() => {
                    setWrapperStyle({
                      borderColor: 'transparent',
                    })
                  }}
                ></TextArea>
              </div>
              <div className='flex justify-end mt-24px'>
                <Button type='primary' loading={loading} onClick={onSubmit}>
                  提交
                </Button>
              </div>
            </>
          )}
        </div>
      }
      overlayInnerStyle={{
        padding: 0,
        width: 480,
      }}
      overlayClassName='issue-report-popover'
    >
      <div ref={ref} onClick={onShow}>
        {children || (
          <Button className={classNames('text-12px', className)} type='link'>
            问题上报
          </Button>
        )}
      </div>
    </Popover>
  )
})
