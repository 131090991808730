import { useState, useMemo, useRef, useContext, useEffect } from 'react'
import { Form, Spin, Tooltip } from 'antd'

import styled from '@emotion/styled'
import { assign, find, get, isArray, isEmpty, isNil } from 'lodash-es'
import classNames from 'classnames'
import type { IPluginItem } from '@apis/flow/type'
import { DynamicForm } from '../../base/components/DynamicForm'
import { type InnerNodePanelProps, PLUGIN_STATUS } from '@/features/nodes/base'
import { JsonForm, NodeFormItem } from '@/features/nodes/components'
import type { JsonFormConfig } from '@/features/nodes/components'
import { DebugResultPanelContext } from '../../base/DebugResultPanel'
import { Button, IconFont, RequiredMark } from '@/components'

import { useNodeMetaStore } from '@/store/nodeMeta'
import { useDynamicValues } from '../../hooks/useDynamicValues'
import { componentMap as compMap } from '../components/componentMap'
import { WarningTips } from '../components/WarningTips'
import { OutputExample } from '../../components/OutputExample'
import useStartFileNodeVariableKeyList from '../../hooks/useStartFileNodeVariableKeyList'
import { PluginNodeFieldTypes } from '../constants'

const CodeNodePanelWrapper = styled.div`
  width: 100%;
  padding-top: 0px;
  padding-bottom: 20px;
`

export function PluginPanelNode(props: InnerNodePanelProps<any>) {
  const { variables, data, activatedNodeId, startNodeFormItemType = [] } = props
  const containerRef = useRef<HTMLDivElement>(null)
  const [form] = Form.useForm()
  const [loading] = useState<boolean>(false)
  const pluginList = useNodeMetaStore(state => state.pluginList)

  const { fileVariableKeyList } = useStartFileNodeVariableKeyList(
    startNodeFormItemType,
    variables,
  )
  const getPlugInfo = (id: string) => {
    let currentPlugin: any = {}
    if (id) {
      currentPlugin = find(
        pluginList,
        item => item.function_id === id,
      )! as IPluginItem
    }
    return currentPlugin
  }
  const pluginFormConfig = useMemo(() => {
    let formConfig: Record<string, any> = []
    if (data.plugin_id && data.plugin_status === PLUGIN_STATUS.ACTIVE) {
      const pluginInfo = getPlugInfo(data.plugin_id)
      formConfig = pluginInfo?.form_config
    }
    return formConfig as IPluginItem['form_config']
  }, [pluginList, data.plugin_id, data.plugin_status, data.actionType])

  const onBeforeChange = (value: any) => {
    return { ...value }
  }

  const val = useDynamicValues({
    nodeId: activatedNodeId!,
    data,
    values: form?.getFieldsValue(),
    variables: variables?.map(item => item.label),
    ruleCallback: (value: InnerNodePanelProps<any>['data']) => {
      const message = pluginFormConfig?.map((item: any) => {
        const currentValue = get(value, `inputs.${item.variableName}`)
        const flag = !!value && isArray(currentValue)
        let formatValue = flag ? (currentValue || []).join(',') : currentValue
        if (item.type === PluginNodeFieldTypes.MultiInput) {
          // 如果是MultiInput类型 则需要关心定义schema内部的value字段 因为如果是MultiInput类型的value为 [{value: "xxx"}, {value: 'xxx1'}]
          formatValue = flag
            ? (currentValue || []).map(obj => obj.value).join(',')
            : currentValue
        }
        return {
          content: formatValue,
        }
      })
      return message
    },
  })

  const { usedKeyListByNodeId } = val

  const {
    registerTriggerNode,
    run,
    loading: btnLoading,
    variables: debugVariables,
    setVariables,
  } = useContext(DebugResultPanelContext)

  const checkByBeforeInvoke = async () => {
    const val: any = {}
    usedKeyListByNodeId.forEach(item => {
      if (isNil(debugVariables[item])) {
        val[item] = ''
      }
    })

    let time = 0
    if (!isEmpty(val)) {
      await setVariables(val, true)
      time = 600
    }
    return new Promise(resolve => {
      setTimeout(() => {
        resolve('')
      }, time)
    })
  }

  const runBySingleStep = async () => {
    await checkByBeforeInvoke()
    await form?.validateFields()
    run(data)
  }

  useEffect(() => {
    registerTriggerNode(
      () => (
        <Button
          loading={btnLoading}
          type='primary'
          className='text-12px bg-op-60 !h-32px'
        >
          运行
        </Button>
      ),
      () => runBySingleStep(),
    )
  }, [data, btnLoading, debugVariables])

  const onDynamicValuesChange = (key: string, value: any) => {
    const usedVal: any = {}
    usedKeyListByNodeId?.forEach(item => {
      usedVal[item] = debugVariables[item] || ''
    })
    const values = assign({}, usedVal, { [key]: value })
    setVariables(values, false)
  }

  const list = useMemo<JsonFormConfig[]>(() => {
    const formConfig = pluginFormConfig || []
    const items = formConfig.map((item, index: any) => {
      const componentMap = compMap as any
      const Component = componentMap[item.type]
      return {
        className: classNames('w-100% px-16px  !mb-0px', { 'pt-16px': !index }),
        required: item.required,
        render: () => {
          const isInline = item?.layout === 'inline'
          const com = (
            <NodeFormItem
              {...item}
              shouldUpdate
              className={classNames({
                '!mb-0px': index === formConfig.length - 1,
              })}
              name={['inputs', item.variableName]}
              key={`${item.variableName}_${index}`}
              rules={[
                {
                  required: item.required,
                  message: item.placeholder,
                },
              ]}
              layout={item.layout === 'inline' ? 'horizontal' : 'vertical'}
              noStyle={isInline}
            >
              {Component({
                ...item,
                variables: props.variables,
                variableTipsContainer: containerRef?.current,
              })}
            </NodeFormItem>
          )
          if (isInline) {
            return (
              <NodeFormItem noStyle>
                <div className='flex justify-start'>
                  <span className='font-500 c-#17171D text-12px/16px'>
                    {item.label}
                  </span>
                  {!!item.required && <RequiredMark />}
                  {!!item.tooltip && (
                    <Tooltip title={item.tooltip || ''}>
                      <IconFont
                        name='jieshishuimeng'
                        className='cursor-pointer mt-1px mr-8px c-font_1 c-op-40 text-14px/14px ml-4px'
                      />
                    </Tooltip>
                  )}
                  {com}
                </div>
              </NodeFormItem>
            )
          }
          return com
        },
      }
    })
    return items
  }, [
    props.nodeElement,
    props.variables,
    containerRef.current,
    props.data,
    pluginFormConfig,
    form,
  ])

  const warningTips = useMemo(() => {
    if (data.plugin_status !== PLUGIN_STATUS.ACTIVE && data.plugin_id) {
      return <WarningTips status={data.plugin_status!} />
    }
    return null
  }, [data, activatedNodeId])

  const jsonForm = useMemo(() => {
    return <JsonForm list={list} form={form} beforeChange={onBeforeChange} />
  }, [list, form, data, onBeforeChange, pluginList, activatedNodeId])

  const output = useMemo(() => {
    return getPlugInfo(data.plugin_id!)?.output || ''
  }, [data.plugin_id!, pluginList, getPlugInfo])

  return (
    <Spin spinning={loading} delay={1000}>
      <CodeNodePanelWrapper ref={containerRef}>
        {warningTips}
        {jsonForm}
        <div className='px-[20px]  pt-[16px]'>
          <DynamicForm
            usedKeyList={usedKeyListByNodeId}
            dynamicValues={debugVariables}
            onValueChange={onDynamicValuesChange}
            fileVariableKeyList={fileVariableKeyList}
          />
        </div>
        <div className='b-b-1 b-solid border-[rgba(225,225,229,0.6)] border-solid w-[100%] flex-1 mb-[16px] mt-[8px]' />
        <OutputExample
          className='px-[16px]'
          name={data.name}
          value={output}
          showExpanded={true}
          tooltip='节点运行完成后，这些变量将被赋值为返回的内容， 下游节点可以引用这些变量。'
        />
      </CodeNodePanelWrapper>
    </Spin>
  )
}
