import { Progress, message } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRequest } from 'ahooks'
import { LoadingOutlined } from '@ant-design/icons'
import type { ApplicationBodyType } from '@apis/application/type'
import type { executeDetailres } from '@apis/run/type'
import {
  batchExecute,
  batchResetting,
  executeCancel,
  executeDetail,
  generateExcel,
} from '@apis/run'
import { downloadOssFile } from '../utils'
import { Button, IconFont } from '@/components'
import empty from '@/assets/empty.png'
import { useStepUploadFile } from '@/pages/datastores/hooks/useStepUploadFile'
import { API_CODE_CODE } from '@/constants/request'
import { LimitedAlertContent } from '@/features/pay/LimitedAlert'

interface Props {
  flowId?: string
  data?: Partial<ApplicationBodyType>
  beforeRun: () => boolean | Promise<boolean>
}

export default ({ flowId = '', data, beforeRun }: Props) => {
  const [result, setResult] = useState<Partial<
    executeDetailres['batch_result'] & {
      state: number
      download_file_url: string
      comboNotEnough?: boolean
    }
  > | null>()
  const [targetRun, setTargetRun] = useState(true) // 是否有任务在进行
  const time = useRef<any>(null)
  const { StepUploadFile, file, setFile, setCurrentSteps, currentSteps } =
    useStepUploadFile({
      generateExcel: () => generateExcel(flowId),
      name: `${data?.name}批量运行模板.xlsx`,
      descriptionChildren: (
        <div className='flex gap-10px flex-items-center flex-justify-center line-height-20px'>
          <IconFont name='excel' className='text-20px' />
          拖拽Excel文件到这里，或者
          <span className='color-#7B61FF line-height-20px'>选择文件</span>
        </div>
      ),
    })
  const { runAsync: executeCancelFN, loading: cancelLoading } = useRequest(
    () => executeCancel({ flow_id: flowId }),
    { manual: true },
  )
  const { runAsync: getDetail, loading: detailLoading } = useRequest(
    () => executeDetail(flowId),
    {
      manual: true,
      onError: () => time.current && clearInterval(time.current),
    },
  )
  const { run: batchExecuteFN, loading } = useRequest(
    () => batchExecute({ flow_id: flowId, file_url: file?.url || '' }),
    {
      manual: true,
      onError: (err: any) => {
        if (err?.code !== 'ECONNABORTED') {
          setTargetRun(false)
          if (err?.code === API_CODE_CODE.COMBO_NOT_ENOUGH) {
            setResult({
              state: 1,
              comboNotEnough: true,
            })
            return
          }
          setResult(null)
          message.error(err.message)
        }
      },
    },
  )
  const { runAsync: reset, loading: resetLoading } = useRequest(
    () => batchResetting({ flow_id: flowId }),
    {
      manual: true,
      onSuccess: () => {
        setFile(null)
        setCurrentSteps(1)
        setResult(null)
      },
    },
  )

  const handleTime = () => {
    getDetail().then(res => {
      if (!res) {
        setTargetRun(false)
        return
      }
      res.state === 1 && setTargetRun(false)
      res.batch_result && setResult({ ...res.batch_result, ...res })
    })
  }

  const setTime = (t: number) => {
    time.current && clearInterval(time.current)
    time.current = setInterval(() => handleTime(), t)
  }

  useEffect(() => {
    if (targetRun) {
      handleTime()
      setTime(2000)
    } else {
      clearInterval(time.current)
    }
  }, [targetRun])

  useEffect(() => {
    return () => clearInterval(time.current)
  }, [])

  const loadingStatus = useMemo(
    () => cancelLoading || loading || detailLoading || resetLoading,
    [cancelLoading, loading, detailLoading, resetLoading],
  )

  const handleRun = () => {
    batchExecuteFN()
    setResult(null)
    setTimeout(() => setTargetRun(true), 1000) // 等待服务器重置上一次任务,即使使用重置接口也是需要用到settimeout等待后端任务状态
  }

  const onRun = async () => {
    if (beforeRun) {
      const allowRun = await beforeRun()
      allowRun && handleRun()
    } else {
      handleRun()
    }
  }

  return (
    <div className='h-full flex flex-justify-between flex-1 adapt:gap-20'>
      <div className='flex-2 at-sm:flex-3 lt-sm:flex-3 bg-#fff border-rd-12px flex flex-col flex-justify-between adapt:px-20 adapt:py-24'>
        {StepUploadFile}
        <div className='flex gap-12px'>
          <Button
            style={{ height: 36, width: 80 }}
            loading={loadingStatus && !time.current}
            onClick={() =>
              getDetail().then(data =>
                data.state !== 1
                  ? executeCancelFN().then(() => reset())
                  : reset(),
              )
            }
          >
            重置
          </Button>
          {targetRun ? (
            <Button
              style={{ height: 36, flex: 1 }}
              icon={<LoadingOutlined />}
              loading={cancelLoading}
              type='primary'
              onClick={() =>
                executeCancelFN().then(() => {
                  setResult(null)
                  setTargetRun(false)
                })
              }
            >
              取消运行
            </Button>
          ) : (
            <Button
              type='primary'
              style={{ height: 36, flex: 1 }}
              disabled={currentSteps === 1 || !file}
              loading={loadingStatus}
              onClick={onRun}
            >
              运行
            </Button>
          )}
        </div>
      </div>
      <div className='flex-3 h-full flex flex-col border-rd-12px bg-#fff adapt:px-20 adapt:py-24 bg-#fff'>
        <div className='text-16px font-500 m-b-20px'>生成结果</div>
        <div className='c-bg_3 c-op-40 flex-1 flex flex-justify-center flex-items-center adapt:gap-25 relative'>
          {!result?.state ? (
            <div className='flex flex-justify-center flex-items-center flex-col text-14px'>
              <img src={empty} className='w-124px' />
              惊喜即将发生
            </div>
          ) : result?.state === 1 && result?.comboNotEnough ? (
            <div className='color-#17171D'>
              <LimitedAlertContent prefix='该工作流所在的空间' />
            </div>
          ) : (
            <div className='flex flex-col w-70%'>
              <Progress
                className='w-100%'
                strokeWidth={16}
                showInfo={false}
                status='active'
                trailColor='#rgba(225, 225, 229, 0.4)'
                strokeColor='#31CC74'
                percent={
                  result.total
                    ? ((result.success || 0 + (result.fail || 0)) * 100) /
                      result.total
                    : 0
                }
              />
              <div className='color-#2CB969 text-14px font-600'>
                {result.state === 2 || result.state === 1 ? (
                  <>
                    {result.state === 2 ? '任务进行中' : '任务完成'}：
                    {result.success || 0 + (result.fail || 0)} / {result.total}
                    （
                    {result.total
                      ? Math.floor(
                          ((result.success || 0 + (result.fail || 0)) * 100) /
                            result.total,
                        )
                      : 0}
                    %）
                  </>
                ) : result.state === 3 ? (
                  '文件校验中...'
                ) : (
                  ''
                )}
              </div>

              {result?.total && result.state === 1 && (
                <>
                  <div className='translate-[-50%] flex flex-col flex-justify-center top-72px w-200px color-#8D8D99 text-14px left-50% relative'>
                    <div className='flex flex-items-center flex-nowrap text-14px m-b-10px'>
                      <span
                        className='color-#2CB969 text-12px font-500 border-rd-4px px-14px py-4px'
                        style={{ background: 'rgba(44, 185, 105, 0.12)' }}
                      >
                        成功
                      </span>
                      <span className='m-r-13px m-l-8px'>成功处理</span>
                      <span>{result.success}条</span>
                    </div>
                    <div className='flex flex-items-center flex-nowrap text-14px m-b-10px'>
                      {Boolean(result.fail) && (
                        <>
                          <span
                            className='color-#FF5219 text-12px font-500 border-rd-4px px-14px py-4px'
                            style={{ background: 'rgba(255, 82, 25, 0.12)' }}
                          >
                            失败
                          </span>
                          <span className='m-r-13px m-l-8px'>失败处理</span>
                          <span>{result.fail}条</span>
                        </>
                      )}
                    </div>
                  </div>
                  <Button
                    type='primary'
                    className='top-72px w-120px left-50% translate-[-50%]'
                    style={{ height: 36 }}
                    disabled={!result.total}
                    icon={<IconFont name='xiazai' className='text-16px' />}
                    onClick={async () => {
                      const allowRun = await beforeRun?.()
                      allowRun &&
                        result.total &&
                        downloadOssFile(
                          `${data?.name}批量运行结果.xlsx`,
                          result.download_file_url || '',
                        )
                    }}
                  >
                    下载文件
                  </Button>
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
