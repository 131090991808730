export enum RankingStrategy {
  ON = 1,
  OFF = 2,
}

export enum HitStrategy {
  MIX = 1,
  KEYWORD = 2,
  SEMANTIC = 3,
}
