import Cookies from 'js-cookie'
import {
  API_TOKEN_KEY,
  WORKSPACE_ID_KEY,
  USER_APP_TOP_KEY,
  APPLICATION_ID_KEY,
  USER_GUIDE_KEY,
  ROBOT_KEY,
  SIDEBAR_COLLAPSE,
  SHOW_START_TIPS,
  ID_TOKEN_KEY,
} from '@/constants/storage'
import { getTopLevelDomain } from '@/utils/url.ts'

export const useIdStorage = initListenStorageUtil(ID_TOKEN_KEY)
export const workspaceStorage = initStorageUtil(WORKSPACE_ID_KEY)
export const userAppTopStorage = initStorageUtil<string[]>(
  USER_APP_TOP_KEY,
  true,
)
export const applicationStorage = initStorageUtil(APPLICATION_ID_KEY)

export const robotStorage = initStorageUtil(ROBOT_KEY)
export const sideBarCollapseStorage = initStorageUtil<boolean>(
  SIDEBAR_COLLAPSE,
  true,
)
export const showStartTipsStorage = initStorageUtil<boolean>(
  SHOW_START_TIPS,
  true,
)

// 用来缓存用户引导状态，判断是否要给用户交互引导
export const userGuideStorage = initStorageUtil<{
  isAddedNode: boolean // 已经添加过Node
}>(USER_GUIDE_KEY, true)

function initStorageUtil<T = string>(
  storageKey: string,
  autoSerialization = false,
) {
  return {
    get: (): T | null => {
      const value = window.localStorage.getItem(storageKey)
      if (autoSerialization) {
        return value ? (JSON.parse(value) as T) : null
      } else {
        return value as T | null
      }
    },
    set: (value: string | T) => {
      if (autoSerialization) {
        const stringValue = JSON.stringify(value)
        window.localStorage.setItem(storageKey, stringValue)
      } else {
        window.localStorage.setItem(storageKey, value as string)
      }
    },
    clear: () => {
      window.localStorage.removeItem(storageKey)
    },
  }
}

function initListenStorageUtil<T = string>(storageKey: string) {
  const instance = {
    listenCall: [] as ((value: T) => void)[],
    listen: (call: (value: T) => void) => {
      instance.listenCall.push(call)
      call(instance.get() as T)
    },
    get: (): T | null => {
      const value = window.localStorage.getItem(storageKey)
      return value as T | null
    },
    set: (value: string | T) => {
      window.localStorage.setItem(storageKey, value as string)
      instance.listenCall.forEach(call => {
        call(value as T)
      })
    },
    clear: () => {
      window.localStorage.removeItem(storageKey)
      instance.listenCall.forEach(call => {
        call(undefined as T)
      })
    },
  }

  return instance
}

export const tokenStorage = {
  get: () => {
    return Cookies.get(API_TOKEN_KEY)
  },
  clear: () => {
    Cookies.remove(API_TOKEN_KEY, {
      domain: getTopLevelDomain(),
    })
  },
  set: (value: string) => {
    Cookies.set(API_TOKEN_KEY, value, {
      domain: getTopLevelDomain(),
      expires: 14,
    })
  },
}
