import { Form } from 'antd'
import CryptoJS from 'crypto-js'
import { useState } from 'react'
import { PUBLISH_MODE } from '@bty/chat-types'
import { enableApp, queryApp, updateApp, createApp } from '@apis/agent-go'
import type { AuthAppWebChatOpenApp } from '@apis/agent-go/type'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider.tsx'
import { useQueryAgentGoApp } from '@/features/agent/hooks/useQueryAgentGoApp.ts'
import type { AgentGoPublishConfProps } from '@/features/agent/components/AgentGoPublishConf.tsx'
import { AgentGoPublishConf } from '@/features/agent/components/AgentGoPublishConf.tsx'
import { WECHAT_OPEN_APP_DOCS } from '@/features/agent/AgentGo/common.tsx'
import { LinkText } from '@/features/agent/components/LinkText.tsx'
import { Input, Checkbox } from '@/components'
import { CopyBox } from '@/features/agent/components/CopyBox.tsx'
import { SERVER_IP_ADDRESSES } from '@/constants/common.ts'
import renderLabel from '@/features/agent/components/renderLabel.tsx'
import { AgentGoWeChatOpenAppShareQRCode } from '@/features/agent/AgentGo/shareQRCode/AgentGoWeChatOpenAppShareQRCode.tsx'
import { AgentGoFormItem } from '@/features/agent/components/AgentGoFormItem.tsx'

interface AgentGoWeChatOpenAppProps {
  mode?: string
  modeIcon?: string
  onBindSuccess?: () => void
  onUnBindSuccess?: () => void
}

interface AgentGoWebChatOpenAppRequest {
  AppID: string
  AppSecret: string
  Token: string
  EncodingAESKey: string
  OriginId: string
  auth_app_name: string
}

function CheckWeChatKF(props: {
  defaultValue?: boolean
  onChange?: (value: boolean) => void
}) {
  const [value, setValue] = useState(props.defaultValue)
  return (
    <div
      className='flex items-center bg-bg_3 bg-op-6 rounded-8px p-12 cursor-pointer'
      onClick={() => {
        setValue(!value)
        props.onChange?.(!value)
      }}
    >
      <Checkbox className='!m-0' checked={value} />
      <div className='ml-8'>
        <p className='line-height-20px mb-4 font-400'>我已添加客服</p>
        <p className='text-font_2 text-12px font-400'>
          未添加客服将无法正常使用
        </p>
      </div>
    </div>
  )
}

export default function AgentGoWeChatOpenApp(props: AgentGoWeChatOpenAppProps) {
  const { mode, modeIcon, onUnBindSuccess, onBindSuccess } = props
  const [form] = Form.useForm<AgentGoWebChatOpenAppRequest>()
  const { applicationInfo } = useAgentEdit()

  const { app, loading, setApp, runQueryApp } =
    useQueryAgentGoApp<AuthAppWebChatOpenApp>({
      agentId: applicationInfo?.flowId,
      source: PUBLISH_MODE.wechat_open_app,
      onQuerySuccess: data => {
        const { auth_app_name, auth_app_config } = data
        form.setFieldsValue({
          auth_app_name,
          ...auth_app_config,
        })
      },
    })

  const handleStepChange: NonNullable<
    AgentGoPublishConfProps['onStepChange']
  > = async step => {
    if (step !== 1) {
      return
    }
    if (app.auth_app_id) {
      const { auth_app_name, ...values } = form.getFieldsValue()
      const data = await updateApp({
        auth_app_id: app.auth_app_id,
        auth_app_name,
        auth_app_config: values,
      })
      setApp(data as unknown as AuthAppWebChatOpenApp)
      return
    }
    // 微信需要第二步完成之后创建 app，点击完成的时候其实是修改应用了
    if (applicationInfo) {
      const { auth_app_name, ...values } = form.getFieldsValue()
      const data = await createApp({
        agent_id: applicationInfo.flowId,
        auth_app_name,
        auth_app_source: PUBLISH_MODE.wechat_open_app,
        auth_app_config: Object.assign(values),
      })
      setApp(data as unknown as AuthAppWebChatOpenApp)
    }
  }

  const handleAppIdBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!value) {
      console.warn('Generate Token & EncodingAESKey Error, Not App Id')
      return
    }
    if (applicationInfo) {
      const value = await queryApp(
        PUBLISH_MODE.wechat_open_app,
        applicationInfo.flowId,
      )
      const config = value?.auth_app_config
        ? value.auth_app_config
        : {
            Token: CryptoJS.lib.WordArray.random(16).toString(),
            EncodingAESKey: CryptoJS.lib.WordArray.random(22)
              .toString()
              .slice(0, 43),
          }
      form.setFieldsValue(config)
    }
  }

  const handleComplete: NonNullable<
    AgentGoPublishConfProps['onComplete']
  > = appId => {
    onBindSuccess?.()
    if (appId && applicationInfo) {
      runQueryApp(applicationInfo.flowId)
    }
  }

  const handleBeforeComplete = (value: any) => {
    delete value.checkWeChatKF
    return value
  }

  const handleValuesChange: NonNullable<
    AgentGoPublishConfProps['onValuesChange']
  > = changedValues => {
    if ('is_enable' in changedValues) {
      setApp({ ...app, is_enable: changedValues.is_enable })
      enableApp(app.auth_app_id!, changedValues.is_enable)
    }
  }

  const handleUnBindSuccess = async () => {
    setApp({})
    onUnBindSuccess?.()
  }

  const steps = [
    {
      title: '填写应用信息',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium text-#17171d'>
            登录{' '}
            <LinkText href='https://mp.weixin.qq.com/'>微信公众号后台</LinkText>{' '}
            获取以下参数
          </div>
          <p className='mt-8px text-12px text-font_2 font-400 mb-24'>
            注意！需要使用<span className='text-font ml-4'>企业认证订阅号</span>
          </p>
        </>
      ),
    },
    {
      title: '完成服务器配置',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium mb-24'>
            复制信息，去{' '}
            <LinkText href='https://mp.weixin.qq.com/'>微信公众号后台</LinkText>{' '}
            完成并启用服务器配置
          </div>
        </>
      ),
    },
    {
      title: '填写IP白名单',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium mb-24'>
            去{' '}
            <LinkText href='https://mp.weixin.qq.com/'>微信公众号后台</LinkText>{' '}
            开发基本配置填写IP白名单
          </div>
          <AgentGoFormItem label={renderLabel('IP地址', false)}>
            <CopyBox value={SERVER_IP_ADDRESSES.join('\n')}>
              {SERVER_IP_ADDRESSES.map(item => (
                <div key={item} className='line-height-20px'>
                  {item}
                </div>
              ))}
            </CopyBox>
          </AgentGoFormItem>
        </>
      ),
    },
    {
      title: '添加客服',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium mb-24'>
            去{' '}
            <LinkText href='https://mp.weixin.qq.com/'>微信公众号后台</LinkText>{' '}
            添加客服服务
          </div>
          <AgentGoFormItem
            name='checkWeChatKF'
            rules={[
              {
                validator: (_rule, value, callback) => {
                  if (value !== true) {
                    callback('使用前必须确保添加客服')
                  } else {
                    callback()
                  }
                },
              },
            ]}
          >
            <CheckWeChatKF />
          </AgentGoFormItem>
        </>
      ),
    },
  ]

  const toUseAction = (
    <AgentGoWeChatOpenAppShareQRCode
      authAppName={app.auth_app_name ?? ''}
      originId={app.auth_app_config?.OriginId ?? ''}
      avatar={applicationInfo?.icon ?? ''}
    />
  )

  return loading ? null : (
    <AgentGoPublishConf
      form={form}
      mode={mode}
      app={app}
      steps={steps}
      ignoreCompleteRequest
      guides={WECHAT_OPEN_APP_DOCS}
      serviceAvatar={modeIcon}
      onUnBindSuccess={handleUnBindSuccess}
      onValuesChange={handleValuesChange}
      onComplete={handleComplete}
      onBeforeComplete={handleBeforeComplete}
      onStepChange={handleStepChange}
      connectTestCardActionSlot={toUseAction}
    >
      {step => (
        <>
          {steps[step].content}
          <AgentGoFormItem
            required
            label={renderLabel('公众号名称', true)}
            name='auth_app_name'
            hidden={step !== 0}
            normalize={value => value?.trim()}
            rules={[{ required: true, message: '公众号名称 不能为空' }]}
          >
            <Input placeholder='公众号名称' spellCheck={false} />
          </AgentGoFormItem>
          <AgentGoFormItem
            required
            label={renderLabel('公众号原始ID', true)}
            name='OriginId'
            hidden={step !== 0}
            normalize={value => value?.trim()}
            rules={[{ required: true, message: '公众号原始Id 不能为空' }]}
          >
            <Input
              placeholder='在设置与开发-公众号设置-注册信息中获取'
              spellCheck={false}
            />
          </AgentGoFormItem>
          <AgentGoFormItem
            required
            label={renderLabel('开发者ID(AppID)', true)}
            name='AppID'
            hidden={step !== 0}
            normalize={value => value?.trim()}
            rules={[{ required: true, message: '开发者ID 不能为空' }]}
          >
            <Input
              placeholder='在设置与开发-基本配置中获取'
              spellCheck={false}
              onBlur={handleAppIdBlur}
            />
          </AgentGoFormItem>
          <AgentGoFormItem
            required
            label={renderLabel('开发者密钥(AppSecret)', true)}
            name='AppSecret'
            hidden={step !== 0}
            normalize={value => value?.trim()}
            rules={[{ required: true, message: '开发者密钥 不能为空' }]}
          >
            <Input
              placeholder='在设置与开发-基本配置中获取'
              spellCheck={false}
            />
          </AgentGoFormItem>
          <AgentGoFormItem
            label={renderLabel('URL', false)}
            dependencies={['AppID']}
            hidden={step !== 1}
          >
            {form => {
              const AppID = form.getFieldValue('AppID')
              return (
                <CopyBox
                  // prettier-ignore
                  value={AppID ? `${import.meta.env.VITE_AI_API_BASE_URL}/v1/webhook/agent/weixin_mp/${AppID}/receive_message` : ''}
                />
              )
            }}
          </AgentGoFormItem>
          <AgentGoFormItem
            name='Token'
            label={renderLabel('Token', false)}
            hidden={step !== 1}
          >
            <CopyBox />
          </AgentGoFormItem>
          <AgentGoFormItem
            name='EncodingAESKey'
            label={renderLabel('EncodingAESKey', false)}
            hidden={step !== 1}
          >
            <CopyBox />
          </AgentGoFormItem>
        </>
      )}
    </AgentGoPublishConf>
  )
}
