import { useRequest } from 'ahooks'
import { useSearchParams } from 'react-router-dom'
import type { User } from '@apis/user/type'
import { getUserProfile, saveOrUpdateUserProfile } from '@apis/user'

export function useUpdateSourceForNewUsers() {
  const [searchParams] = useSearchParams()
  const channel = searchParams.get('channel')
  const { runAsync: fetchUserProfile } = useRequest(getUserProfile, {
    manual: true,
  })

  const { runAsync: updateUserProfileApi } = useRequest(
    saveOrUpdateUserProfile,
    { manual: true },
  )

  const checkSourceExist = async (userId: number) => {
    const res = await fetchUserProfile(userId)
    return !!res?.profileJSON?.source
  }

  const updateSource = async (user: User) => {
    if (!user?.userId) return
    const exist = await checkSourceExist(user.userId)
    if (!exist && channel) {
      await updateUserProfileApi(
        user.userId,
        {
          source: channel,
          profile: {
            useScope: ['AI'],
          },
        },
        channel,
      )
    }
  }

  return { updateSource }
}
