import { useMemo, useRef } from 'react'
import { useBoolean, useLatest, useMemoizedFn, useRequest } from 'ahooks'

import { find, omit } from 'lodash-es'
import { Form } from 'antd'
import classNames from 'classnames'
import { getFlowRunResult } from '@apis/flow'
import type { ActionTypesForNode } from '@apis/flow/type'
import type { NodeComponent } from '@/features/nodes/base'
import {
  NodeType,
  NodeOperationTypes,
  SUB_FLOW_STATUS,
} from '@/features/nodes/base'
import type { JsonFormConfig } from '../components'
import { JsonForm } from '../components'
import { NodeFormItem } from '../components/Forms/NodeFormItem'
import { useNodeMetaStore } from '@/store/nodeMeta'
import { OutputExample } from '../components/OutputExample'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import { componentMap as compMap } from './components/componentMap'
import { FlowSelect } from './components/FlowSelect'
import { WarningTips } from './components/WarningTips'

const FLOW_PACKAGE_NAME = 'integration.flow.flowAction'

export interface SubFlowNodeData {
  name: string
  packageName: string
  comment?: string
  flow_id?: string
  flow_name: string
  flow_status?: SUB_FLOW_STATUS
  inputs?: {
    formConfig?: Record<string, any>
  }
  actionType?: ActionTypesForNode.INTEGRATION
  output?: Record<string, any>
}

export const SubFlowNode: NodeComponent<SubFlowNodeData> = props => {
  const [expanded] = useBoolean(false)
  const containerRef = useRef<HTMLDivElement>(null)
  const subFlowList = useNodeMetaStore(state => state.subFlowList)
  const [form] = Form.useForm()
  const { data } = props
  const latestFlowId = useLatest(data.flow_id)
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const getSubFlowInfo = useMemoizedFn((id: string) => {
    return id ? find(subFlowList, item => item.flow_id === id) : undefined
  })

  const onBeforeChange = useMemoizedFn((value: any) => {
    if (latestFlowId.current !== value.flow_id) {
      latestFlowId.current = value.flow_id
      value.inputs = {}
      const subFlowInfo = getSubFlowInfo(value.flow_id)
      const config = subFlowInfo?.form_config || []
      config.forEach((item: any) => {
        value.inputs[item.variableName] = ''
      })
      value.flow_name = subFlowInfo?.flow_name
      value.flow_status = SUB_FLOW_STATUS.ACTIVE
      form.setFieldsValue({
        inputs: value.inputs,
        flow_name: value.flow_name,
        flow_status: value.flow_status,
      })
    }
    return { ...value }
  })

  const subFlowFormConfig = useMemo(() => {
    let formConfig: Record<string, any> = []
    if (data.flow_id && data.flow_status === SUB_FLOW_STATUS.ACTIVE) {
      const subFlowInfo = getSubFlowInfo(data.flow_id)
      formConfig = subFlowInfo?.form_config || []
    }
    return formConfig
  }, [subFlowList, data.flow_id, data.flow_status])

  const list = useMemo<JsonFormConfig[]>(() => {
    const formConfig = subFlowFormConfig || []

    const divider = []
    if (formConfig?.length) {
      divider.push({
        className: '!h-1px',
        render: () => (
          <div className='b-b-1 b-solid b-[rgba(225,225,229,0.6)] w-100% flex-1' />
        ),
      })
    }
    const items = formConfig.map((item: any, index: any) => {
      const componentMap = compMap as any
      const Component = componentMap[item.type]
      item.variables = props.variables
      item.variableTipsContainer = containerRef?.current
      item.disabled = lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK
      return {
        className: classNames('w-100% px-16px  !mb-0px', { 'pt-16px': !index }),
        required: item.required,
        render: () => {
          return (
            <NodeFormItem
              {...omit(item, 'variableName')}
              shouldUpdate
              className={classNames({
                '!mb-0px': index === formConfig.length - 1,
              })}
              name={['inputs', item.variableName]}
              key={`${item.variableName}_${data.flow_id}`}
              rules={[{ required: item.required, message: item.placeholder }]}
            >
              {Component(item)}
            </NodeFormItem>
          )
        },
      }
    })
    return [
      {
        label: '选择Flow',
        required: true,
        className: 'px-16px !mb-0px',
        render: () => {
          return (
            <>
              <div className='flex items-start flex-col flex-1'>
                <NodeFormItem
                  className='px-16px'
                  noStyle
                  name={['flow_id']}
                  rules={[{ required: true, message: '请选择Flow' }]}
                >
                  <FlowSelect
                    className='w-100% nodrag nopan'
                    onPreChange={form.resetFields}
                    disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
                    lastSelectedInfo={{
                      name: data.flow_name,
                      status: data.flow_status!,
                    }}
                  />
                </NodeFormItem>
              </div>
            </>
          )
        },
      },
      ...divider,
      ...items,
    ]
  }, [
    props.nodeElement,
    props.variables,
    containerRef.current,
    expanded,
    props.data,
    subFlowFormConfig,
    form,
  ])

  const { data: output, run: getFlowRunResultApi } = useRequest(
    () => getFlowRunResult(data?.flow_id || ''),
    {
      ready: !!data.flow_id,
      refreshDeps: [data.flow_id],
      manual: true,
    },
  )

  const jsonForm = useMemo(() => {
    return (
      <JsonForm
        list={list}
        form={form}
        beforeChange={onBeforeChange}
        disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
      />
    )
  }, [list, form, data, onBeforeChange, subFlowList, lockStatus])

  const warningTips = useMemo(() => {
    if (data.flow_status !== SUB_FLOW_STATUS.ACTIVE && data.flow_id) {
      return <WarningTips flowStatus={data.flow_status!} />
    }
    return null
  }, [data])

  const handleToggle = useMemoizedFn((expanded: boolean) => {
    if (expanded) {
      getFlowRunResultApi()
    }
  })

  return (
    <div ref={containerRef} className='w-[420px] pt-[16px] pb-[16px]'>
      <div className='px-[16px]'>{warningTips}</div>
      {jsonForm}
      <div className='b-b-1 b-solid border-[rgba(225,225,229,0.6)] border-solid w-[100%] flex-1 my-[16px]' />
      <OutputExample
        className='px-[16px]'
        name={data.name}
        onToggle={handleToggle}
        value={output}
        tooltip='节点运行完成后，这些变量将被赋值为返回的内容， 下游节点可以引用这些变量。'
      />
    </div>
  )
}

SubFlowNode.meta = {
  type: NodeType.FLOW,
  operationType: NodeOperationTypes.SINGLE_NODE,
  typeName: 'Flow',
  actionType: NodeType.FLOW,
  icon: 'flowjiedian-1',
  description: '调用已发布的Flow',
  backgroundColor: '#00C2A2',
  canDelete: true,
  initialData: {
    flow_id: undefined,
    inputs: {},
    name: 'flow',
    packageName: FLOW_PACKAGE_NAME,
    flow_name: '',
    output: {},
  },
}
