import type { ColumnsType } from 'antd/lib/table'
import { useRequest, useDebounceFn, useMount, useLockFn } from 'ahooks'
import dayjs from 'dayjs'
import React, {
  useState,
  type Key,
  useMemo,
  useEffect,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react'
import copy from 'copy-to-clipboard'

import classNames from 'classnames'
import styled from '@emotion/styled'
import { useLocation, useNavigate } from 'react-router-dom'
import type { TableProps } from 'antd'
import { Tag, message, Tooltip, Divider, Pagination, Switch } from 'antd'
import { clone, find, isNil } from 'lodash-es'
import { isString } from 'markdown-it/lib/common/utils'
import {
  getDocumentList,
  deleteDocuments,
  fileRetry,
  getDatasetTags,
  getDataStoreList,
  copyDocumentsToDataset,
  generateAISummaryByChunkId,
  downloadFailReasonFile,
  updateDocument,
  deleteFailedDocumentsByPartitionId,
} from '@apis/datastore'
import { DocumentStatus, DocumentType } from '@apis/datastore/model'
import type { DocumentItem, IDocumentListReq } from '@apis/datastore/type'
import { PartitionCategoryType } from '@apis/datastore/type'
import {
  Button,
  ConfirmModal,
  IconFont,
  Table,
  usePageModal,
} from '@/components'
import useUrlState from '@/hooks/useUrlState'
import { useDataStoreCreate } from '@/features/datastore/hooks/useDataStoreCreate.ts'
import { useModal } from '@/hooks/useModal.tsx'
import { CreateDataStoreDocumentsModal } from '@/features/datastore/CreateDataStoreModal/DocumentsModal'
import { CopyDocumentsModal } from '@/features/datastore/CopyDocumentsModal'

import { TagEditorModal } from '@/pages/datastores/components/TagEditorModal.tsx'
import { useVersionStore, useWorkspaceStore } from '@/store'

import { Track } from '../track'
import { DocumentTagList } from '@/features/datastore/components/DocumentTagList'
import { LimitedAccess } from '../pay/LimitedAccess'
import {
  formatFileSize,
  formatSize,
  getFileDisplayName,
  getFileIconByFileTypeOrMimeType,
} from './utils'
import { useFileProcessWatcher } from './utils/useFIleProcessWatcher'

import NewFeatureBadge from './components/NewFeatureBadge'
import { DATASTORE_MAX_TAG_COUNT, DocumentStatusMap } from './constant'
import { StatusTag } from './components/StatusTag'
import { DocumentsSearch } from './DocumentsSearch'

const StyledTable = styled(Table)<{ isEmpty?: boolean }>`
  .ant-table-column-sorters {
    justify-content: flex-start !important;
  }

  .ant-table-column-title {
    flex: initial;
  }
  .ant-table-cell-fix-right {
    background: white !important;
  }
  .ant-table-sticky-scroll-bar {
    width: 0px;
    height: 0px;
  }

  .ant-table-body {
    overflow: auto !important;
  }

  .ant-table-sticky-scroll {
    height: 0px !important;
  }

  ${({ isEmpty }) => {
    if (isEmpty) {
      return `.ant-table-body{
          overflow: hidden !important;
        }`
    } else {
      return ''
    }
  }}
`

const StyledPagination = styled(Pagination)`
  .ant-select-selector {
    box-shadow: none !important;
  }
  .ant-pagination-options-quick-jumper {
    input {
      box-shadow: none !important;
    }
  }
  .ant-pagination-item-active {
    box-shadow: none !important;
  }
`

export const DocumentTypeMap: Record<DocumentType, string> = {
  [DocumentType.FILE]: '文件',
  [DocumentType.TEXT]: '文本',
  [DocumentType.WEBPAGE]: '网页',
  [DocumentType.TEMPLATE_FILE]: '模版文件',
  [DocumentType.QA]: '问答',
  [DocumentType.VIDEO]: '视频',
}

type Columns =
  | 'file_name'
  | 'file_size'
  | 'extracted_text_length'
  | 'update_time'
  | 'status'
  | 'tags'
  | 'segment_count'
  | 'enable'

interface DocumentsProps {
  hideHeader?: boolean
  openDocumentInNewWindow?: boolean
  datastoreHashPath: string
  id: number
  datastoreName?: string
  datastoreType?: PartitionCategoryType
  className?: string
  fields?: Columns[]
  dateFormat?: string
  widthMap?: { [key in Columns]?: number }
  showExtraSearch?: boolean
  onRowClick?: (file_id: number) => void
  onShowDetail?: (file_id: number) => boolean | undefined
}

type OnTableChange = NonNullable<TableProps<Columns>['onChange']>

type GetSingle<T> = T extends (infer U)[] ? U : never
type Sorts = GetSingle<Parameters<OnTableChange>[2]>

const LinkText = styled.a`
  &:hover {
    .link {
      opacity: 1;
    }
  }
`

function SortIcon(props: { sortOrder: 'asc' | 'desc' }) {
  const { sortOrder } = props
  return (
    <div className='ml-8'>
      <IconFont
        name='asc'
        className={classNames('text-6px text-font_1', {
          'text-opacity-40': sortOrder !== 'asc',
        })}
      />
      <IconFont
        name='desc'
        className={classNames('text-6px text-font_1', {
          'text-opacity-40': sortOrder !== 'desc',
        })}
      />
    </div>
  )
}
export interface DocumentsInstance {
  create: (afterCreate?: VoidFunction) => void
  onSearch: (params: { file_name: string; [key: string]: any }) => void
}

const DEFAULT_PAGE_OPTIONS = {
  page: 1,
  page_size: 20,
  file_name: '',
  tags: [],
  file_status: -1,
  sort_order: '',
  sort_by: '',
}

function Documents(
  props: DocumentsProps,
  ref: React.ForwardedRef<DocumentsInstance>,
) {
  const {
    hideHeader,
    id,
    openDocumentInNewWindow,
    fields,
    widthMap,
    dateFormat,
    datastoreHashPath,
    showExtraSearch = false,
    onRowClick,
    onShowDetail,
    datastoreName,
    datastoreType,
  } = props
  const navigate = useNavigate()
  const [retryFileId, setRetryFileId] = useState(-1)
  const location = useLocation()
  const { onCreate: onDocumentCreateApi, loading: documentCreateLoading } =
    useDataStoreCreate()
  const [createDataStoreDocumentsModal] = useModal(
    CreateDataStoreDocumentsModal,
    {
      okButtonProps: { loading: documentCreateLoading },
      uploadFileHashPath: datastoreHashPath,
      datastoreType,
    },
  )
  const wrapperRef = useRef(null)

  const [tagOptions, setTagOptions] = useState<any>([])

  const [selectedRowKeys, setSelectedRowKeys] = useState<number[]>([])

  const selectOptions = useMemo(() => {
    const items = Object.entries(DocumentStatusMap)
      .map(item => {
        const [key, value] = item
        return {
          label: value.text,
          value: Number(key),
        }
      })
      .filter(item => {
        return item.value !== DocumentStatus.Ing
      })
    items.unshift({
      label: '全部状态',
      value: -1,
    })
    return items
  }, [])

  const checkSearchParamsSafe = (
    params: Omit<IDocumentListReq, 'partitionId'>,
  ) => {
    if (Number(params.page) < 0) {
      params.page = DEFAULT_PAGE_OPTIONS.page
    }
    if (
      params.page_size &&
      (Number(params.page_size < 0) || Number(params.page_size > 100))
    ) {
      params.page_size = DEFAULT_PAGE_OPTIONS.page_size
    }
    if (!isNil(params?.file_status) && Number(params?.file_status) !== -1) {
      const items = selectOptions.filter(item => {
        return item.value === Number(params.file_status)
      })
      if (!items.length) {
        params.file_status = DEFAULT_PAGE_OPTIONS.file_status
      }
      if (params.sort_by && params.sort_by !== 'update_time') {
        params.sort_by = ''
        params.sort_order = ''
      }
      if (
        params.sort_order &&
        params.sort_order !== 'asc' &&
        params.sort_order !== 'desc'
      ) {
        params.sort_order = ''
      }
    }
    if (params.sort_by && params.sort_by !== 'update_time') {
      params.sort_by = ''
      params.sort_order = ''
    }
    if (
      params.sort_order &&
      params.sort_order !== 'asc' &&
      params.sort_order !== 'desc'
    ) {
      params.sort_order = ''
    }
    return params
  }

  const [_pageOptions, setPageOptions] = useUrlState<
    Omit<IDocumentListReq, 'partitionId'>
  >(DEFAULT_PAGE_OPTIONS!, {
    navigateMode: 'replace',
  })

  const pageOptions = useMemo(() => {
    return checkSearchParamsSafe(_pageOptions)
  }, [_pageOptions])
  // 搜索
  const [searchInfo, setSearchInfo] = useState<{
    file_name: string
    file_status: number
    tags: string[]
  }>({
    file_name: pageOptions.file_name!,
    file_status: Number(pageOptions.file_status!),
    tags: (isString(pageOptions.tags!)
      ? [pageOptions.tags!]
      : pageOptions.tags)!,
  })

  const [failDocumentsCount, setFailDocumentsCount] = useState<number>(0)

  const getDatasetTasAndTransform = async (id: number) => {
    const res = await getDatasetTags(id)
    return res?.map(item => ({ label: item.tag, value: item.tag }))
  }

  const { runAsync: refreshDataSetTags } = useRequest(
    getDatasetTasAndTransform,
    {
      defaultParams: [props.id],
      onSuccess: res => {
        setTagOptions(res)
      },
    },
  )

  const { data: datasetList } = useRequest(getDataStoreList)

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const { fetchVersionByWorkspaceId } = useVersionStore()

  const fileWatcher = useFileProcessWatcher()

  const [retryLoadingMap, setRetryLoadingMap] = useState<
    Record<number, boolean>
  >({})
  const { runAsync: retryFile } = useRequest(fileRetry, { manual: true })
  const [loading, setLoading] = useState<boolean>(false)

  const {
    data: getDocumentsData,
    run: runDocumentsApi,
    refreshAsync: refreshDocumentsApi,
    cancel: cancelPolling,
  } = useRequest(params => getDocumentList(params, true), {
    pollingInterval: 3000,
    manual: true,
    onSuccess: res => {
      const data = res.rows
      fileWatcher.add(
        data
          .filter(
            item =>
              item.status === DocumentStatus.Wait ||
              item.status === DocumentStatus.Ing ||
              item.status === DocumentStatus.AIProcess,
          )
          .map(item => item.file_id),
      )

      const failFileIds = data
        .filter(item => item.status === DocumentStatus.Fail)
        .map(item => item.file_id)
      setFailDocumentsCount(res.failed_totals)

      fileWatcher.process(failFileIds, fileIds => {
        fileIds.forEach(id => {
          const [item] = data.filter(item => item.file_id === id)
          if (item) {
            message.error(item.failed_reason)
          }
        })
      })
    },
    onFinally: () => {
      setLoading(false)
    },
  })

  const fetchDocumentsList = async (
    params?: Omit<IDocumentListReq, 'partitionId'>,
  ) => {
    fileWatcher.clean()
    setLoading(true)
    const baseReq = checkSearchParamsSafe({
      ...pageOptions,
      page: 1,
      ...params,
    })
    await runDocumentsApi({
      partitionId: id,
      ...baseReq,
      tags: isString(baseReq.tags) ? [baseReq.tags] : baseReq.tags,
      file_status:
        Number(baseReq.file_status) === -1 ? undefined : baseReq.file_status,
    })
    setPageOptions(baseReq)
  }

  useMount(() => {
    fetchDocumentsList(pageOptions)
  })

  const [tagEditorModal] = useModal(TagEditorModal)

  const currentDocumentsInfo = useMemo(() => {
    return find(datasetList?.partitions!, o => {
      return o.partition_id === id
    })
  }, [datasetList, id])

  const pageModal = usePageModal()

  const toDocumentDetail = (file_id: number, openModal = false) => {
    if (!openModal && onShowDetail && onShowDetail(file_id)) {
      return
    }

    const url = `/datastores/${currentWorkspaceId}/${id}/${file_id}/content${
      openDocumentInNewWindow ? '?independent=1' : location.search
    }`

    if (openDocumentInNewWindow) {
      pageModal.show({ url })
    } else {
      navigate(url)
    }
  }

  const handleEditTag = (row: DocumentItem) => {
    tagEditorModal.open({
      title: row.tags?.length ? '编辑标签' : '添加标签',
      defaultValue: row.tags,
      documentId: row.file_id,
      datasetId: id,
      onFinish: async () => {
        await refreshDocumentsApi()
        refreshDataSetTags(props.id)
      },
    })
  }

  const { run: onFileRetry } = useDebounceFn(
    async (file_id: number, type: 'fail' | 'ai_process' = 'fail') => {
      fileWatcher.add([file_id])
      if (!retryLoadingMap[file_id]) {
        setRetryLoadingMap({
          ...retryLoadingMap,
          [file_id]: true,
        })
        if (type === 'fail') {
          await retryFile(file_id).finally(() => {
            setRetryLoadingMap({
              ...retryLoadingMap,
              [file_id]: false,
            })
          })
        }
        if (type === 'ai_process') {
          await generateAISummaryByChunkId?.({
            file_id,
          }).finally(() => {
            setRetryLoadingMap({
              ...retryLoadingMap,
              [file_id]: false,
            })
          })
        }

        refreshDocumentsApi()
      }
    },
    {
      leading: true,
      trailing: false,
      wait: 500,
    },
  )

  const checkHasIngDocument = (documents: any[]) => {
    return documents.some(
      item =>
        item.status === DocumentStatus.Ing ||
        item.status === DocumentStatus.Wait ||
        item.status === DocumentStatus.AIProcess,
    )
  }

  const refreshDocuments = async () => {
    fetchDocumentsList({ page: 1 })
  }

  const { run: toggleDocumentEnableStatus } = useRequest(updateDocument, {
    manual: true,
    debounceWait: 100,
    onSuccess: () => {
      message.success('更新成功')
      fetchDocumentsList()
    },
    onError: () => {
      message.success('更新失败，请联系开发人员')
    },
  })

  const renderPopContent = (record: DocumentItem) => {
    switch (record.status) {
      case DocumentStatus.Fail:
        return {
          title: (
            <div className='c-#17171D text-12px/16px flex items-center'>
              <p className='text-16px/16px mr-6px'>
                <IconFont name='cuowurizhi'></IconFont>
              </p>
              <span>文档处理失败</span>
            </div>
          ),
          content: (
            <div className='ml-22px w-250px flex flex-col'>
              <div className='flex  flex-col line-height-18px'>
                <span className='mr-8px text-#17171d text-12px/18px'>
                  {record.failed_reason}
                </span>
                <div className='mt-8px flex justify-end'>
                  <Button
                    className='mr-8px !px-8px !py-0px !h-24px !text-12px/12px'
                    size='small'
                    onClick={async () => {
                      await copy(record.file_name)
                      message.success('已复制')
                    }}
                  >
                    复制文件名称
                  </Button>
                </div>
              </div>
            </div>
          ),
        }
      case DocumentStatus.Warning:
        return {
          title: (
            <div className='c-#17171D text-12px/16px flex items-center'>
              <p className='text-16px/16px mr-6px'>
                <IconFont name='jingzhi'></IconFont>
              </p>
              <span>段落异常</span>
            </div>
          ),
          content: (
            <div className='flex items-center line-height-18px'>
              <span className='mr-8px text-#17171d text-12px'>
                {record.failed_reason}
              </span>
            </div>
          ),
        }
      case DocumentStatus.AIProcess:
        return {
          title: null,
          content: (
            <div className='flex items-center line-height-18px'>
              <span className='mr-8px text-#17171d text-12px'>
                段落AI处理中
                {!!record?.extra_info?.process_content_count && (
                  <>
                    , 正在进行
                    <span className='mx-4px c-#7B61FF'>
                      {record?.extra_info?.process_content_count}
                    </span>
                    段
                  </>
                )}
              </span>
            </div>
          ),
        }

      default:
        return {
          content: null,
          title: null,
        }
    }
  }

  const columnMap: Record<Columns, any> = {
    file_name: {
      key: 'file_name',
      dataIndex: 'file_name',
      title: datastoreType === PartitionCategoryType.QA ? '问答' : '文档',
      width: widthMap?.file_name ?? 300,
      render: (text: string, record: any) => (
        <Track event='datastore_doc_open'>
          <LinkText
            title={text}
            className='hover:text-font flex items-center cursor-pointer file_name'
          >
            <div
              className='flex items-center truncate'
              onClick={event => {
                event.stopPropagation()
                toDocumentDetail(record.file_id)
              }}
            >
              <img
                className='w-20px h-24px shrink-0'
                src={getFileIconByFileTypeOrMimeType(
                  record.mimetype,
                  record.file_type,
                )}
                alt=''
              />
              <span className='ml-8 mt-2 line-height-18px text truncate'>
                {getFileDisplayName(text, record.file_type)}
              </span>
            </div>
            <span
              className='link op-0 shrink-0 text-16px ml-8 relative cursor-pointer c-font_1 c-op-60 flex-center'
              onClick={event => {
                event.stopPropagation()
                toDocumentDetail(record.file_id, true)
              }}
            >
              <IconFont name='super-link' />
            </span>
          </LinkText>
        </Track>
      ),
    },
    tags: {
      key: 'tags',
      dataIndex: 'tags',
      width: widthMap?.tags ?? 310,
      title: '标签',
      render: (tags: string[], record: DocumentItem) => (
        <div
          className='flex items-center gap-8 w-100%'
          onClick={e => e.stopPropagation()}
        >
          <div className='flex flex-1 items-center overflow-hidden'>
            {tags?.length < DATASTORE_MAX_TAG_COUNT && (
              <Tag
                className='rounded-4px b-dashed bg-white hover:bg-bg_3 hover:bg-op-8 cursor-pointer'
                onClick={() => handleEditTag(record)}
              >
                添加标签
              </Tag>
            )}
            <DocumentTagList
              className='h-22px! !text-12px'
              list={clone(tags)?.reverse() || []}
              // getPopupContainer={() => scrollRef.current!}
              showMaxCount={tags.length < DATASTORE_MAX_TAG_COUNT ? 2 : 3}
              onClick={() => {
                handleEditTag(record)
              }}
            />
          </div>
        </div>
      ),
    },
    file_size: {
      title: '大小',
      key: 'file_size',
      dataIndex: 'file_size',
      align: 'left',
      width: widthMap?.file_size ?? 100,
      render: (file_size: number) =>
        file_size ? formatFileSize(file_size) : '--',
    },
    segment_count: {
      title: datastoreType === PartitionCategoryType.QA ? '问答数' : '段落数',
      key: 'segment_count',
      dataIndex: 'segment_count',
      align: 'left',
      width: widthMap?.segment_count ?? 120,
      render: (segment_count: number) => segment_count ?? '--',
    },
    extracted_text_length: {
      title: '字符数',
      key: 'extracted_text_length',
      dataIndex: 'extracted_text_length',
      align: 'left',
      width: widthMap?.extracted_text_length ?? 120,
      render: (extractedTextLength: number) =>
        extractedTextLength ? formatSize(extractedTextLength) : '--',
    },
    update_time: {
      title: '更新时间',
      width: widthMap?.update_time ?? 160,
      key: 'update_time',
      dataIndex: 'update_time',
      sortIcon: ({ sortOrder }: { sortOrder: 'asc' | 'desc' }) => {
        return <SortIcon sortOrder={sortOrder} />
      },
      sorter: true,
      sortDirections: ['asc', 'desc'],
      render: (updateTime: string) =>
        dayjs(updateTime).format(dateFormat ?? 'YYYY-MM-DD HH:mm'),
    },
    status: {
      fixed: 'right',
      width: widthMap?.status ?? 120,
      title: '状态',
      key: 'status',
      dataIndex: 'status',
      className: '!right-80px',
      render: (status: DocumentStatus, record: DocumentItem) => {
        const { content, title } = renderPopContent(record)
        return (
          <div
            className='flex items-center gap-4'
            onClick={e => e.stopPropagation()}
          >
            <StatusTag
              status={status}
              className='rounded-4px font-500 text-12px min-w-52px text-center !m-0 cursor-pointer'
              popTitle={title || null}
              popContent={content || null}
            />
            {retryFileId === record.file_id &&
              status === DocumentStatus.Fail && (
                <div className='ml-4px w-24px h-24px cursor-pointer hover:bg-[rgba(98,105,153,0.08)] flex items-center justify-center rounded-4px'>
                  <IconFont
                    name='tongshi'
                    className='shrink-0 text-16px text-#8d8d99'
                    onClick={() => {
                      if (status === DocumentStatus.Fail) {
                        onFileRetry(record.file_id)
                      }
                    }}
                  />
                </div>
              )}
            {status === DocumentStatus.Warning && (
              <div
                className='ml-4px w-24px h-24px cursor-pointer text-16px/16px flex items-center justify-center hover:bg-[rgba(98,105,153,0.08)] rounded-4px'
                onClick={async () => {
                  onFileRetry(record.file_id, 'ai_process')
                }}
              >
                <Tooltip title='一键AI处理所有异常段落'>
                  <IconFont name='AIcengjiangchuli' />
                </Tooltip>
              </div>
            )}
          </div>
        )
      },
    },
    enable: {
      fixed: 'right',
      width: widthMap?.enable ?? 80,
      title: '启用',
      key: 'enable',
      dataIndex: 'enable',
      className: '!right-0px',
      render: (enable: boolean, record: DocumentItem) => (
        <div onClick={e => e.stopPropagation()}>
          <Switch
            size='small'
            checked={enable}
            onClick={async () => {
              toggleDocumentEnableStatus({
                file_id: record.file_id,
                enable: !enable,
              })
            }}
          />
        </div>
      ),
    },
  }

  const columns: ColumnsType<object> = fields
    ? fields.map(field => columnMap[field])
    : Object.values(columnMap)

  const handleCreate = (afterCreate?: VoidFunction) => {
    createDataStoreDocumentsModal.open({
      title:
        datastoreType === PartitionCategoryType.QA
          ? '批量导入问答'
          : '添加文档',
      onFinish: async (value, documentType) => {
        if (value) {
          const data = await onDocumentCreateApi(
            {
              ...value,
              partition: {
                partition_id: Number(id),
              },
            },
            documentType,
          )
          fileWatcher.add(data.file_ids)
          createDataStoreDocumentsModal.close()
          refreshDocuments()
          afterCreate?.()
        }
      },
    })
  }

  useImperativeHandle(
    ref,
    () => ({
      create: handleCreate,
      onSearch: (params: Record<string, any>) => {
        fetchDocumentsList(params)
      },
    }),
    [handleCreate],
  )

  const total = getDocumentsData?.totals ?? 0

  useRequest(getDatasetTags, {
    defaultParams: [Number(id)],
  })

  const onSearchInfoChange = useCallback(
    (params: any) => {
      setSearchInfo(() => {
        // fetchTableData
        const opt = { ...pageOptions, ...searchInfo, ...params }
        fetchDocumentsList(opt)
        console.log(`请求数据中 请求参数${JSON.stringify(searchInfo)}`)
        return { ...searchInfo, ...params }
      })
    },
    [searchInfo],
  )
  const handleTableChange: OnTableChange = (_pagination, _filters, sorter) => {
    // setFilteredInfo(filters)
    const sortInfo = sorter as Sorts
    fetchDocumentsList({
      sort_by: sortInfo?.column?.key || ('' as any),
      sort_order: sortInfo.order || '',
    })
  }

  const documents = useMemo(() => {
    const documents = getDocumentsData?.rows || []
    const result = documents
    return result
  }, [getDocumentsData, searchInfo, tagOptions])

  const onRowSelectedChange = (selectedRowKeys: Key[]) => {
    setSelectedRowKeys(selectedRowKeys as number[])
  }

  const [copyDocumentsModal] = useModal(CopyDocumentsModal, {
    datasetList: datasetList?.partitions.filter(
      item =>
        item.partition_id !== id &&
        currentDocumentsInfo?.partition_model === item.partition_model,
    ),
    onFinish: async data => {
      try {
        await copyDocumentsToDataset({
          file_ids: selectedRowKeys,
          target_partition_id: data.target_partition_id,
        })
        message.success('复制成功')
      } catch (e) {
        message.error('复制失败')
      } finally {
        copyDocumentsModal.close()
      }
    },
  })

  const { runAsync: deleteDocumentsApi, loading: deleteLoading } = useRequest(
    deleteDocuments,
    { manual: true },
  )

  const {
    runAsync: batchDeleteFailedDocumentsByPartitionId,
    loading: batchDeleteLoading,
  } = useRequest(deleteFailedDocumentsByPartitionId, { manual: true })

  const [deleteDocumentsModal] = useModal(ConfirmModal, {
    okButtonProps: {
      loading: deleteLoading || batchDeleteLoading,
    },
    okText: '删除',
  })

  useEffect(() => {
    if (getDocumentsData) {
      // 判断是否有正在处理的文档
      const hasIngDocument = checkHasIngDocument(getDocumentsData.rows)
      if (!hasIngDocument) {
        cancelPolling()
      }
    }
  }, [getDocumentsData])

  const onDeleteLockFn = useLockFn(async () => {
    await deleteDocumentsApi(selectedRowKeys)
    setSelectedRowKeys([])
    refreshDocuments()
    deleteDocumentsModal.close()
    fetchVersionByWorkspaceId(currentWorkspaceId)
  })

  const onDelete = () => {
    deleteDocumentsModal.open({
      children: '请确认是否删除所选数据？',
      onOk: onDeleteLockFn,
    })
  }

  const cleanAllFailDocuments = () => {
    if (!failDocumentsCount) {
      return
    }
    deleteDocumentsModal.open({
      children: '请确认是否删除所有失败的文档？',
      onOk: async () => {
        try {
          await batchDeleteFailedDocumentsByPartitionId(id)
          message.success('失败文档已清空')
          refreshDocuments()
          deleteDocumentsModal.close()
          fetchVersionByWorkspaceId(currentWorkspaceId)
        } catch (_e) {
          message.error('失败文档清空失败')
        }
      },
    })
  }

  const { run: downloadFailReasonFileFn } = useDebounceFn(
    downloadFailReasonFile,
    {
      wait: 500,
    },
  )

  return (
    <div
      className={classNames(
        'flex flex-col flex-1 h-full w-full',
        props.className,
      )}
    >
      {!hideHeader && (
        <div className='flex flex-items-center flex-justify-between'>
          <DocumentsSearch
            showExtraSearch={showExtraSearch}
            selectOptions={selectOptions}
            tagOptions={tagOptions}
            onSearch={params => {
              setSelectedRowKeys([])
              fetchDocumentsList(params)
            }}
            onSearchInfoChange={onSearchInfoChange}
            searchInfo={searchInfo}
            setSearchInfo={setSearchInfo}
            placeholder={
              datastoreType === PartitionCategoryType.QA
                ? '搜索问答名称'
                : '搜索文档名称'
            }
          />
          <div className='relative'>
            <LimitedAccess limitedType='dataStore'>
              <Button
                type={
                  datastoreType === PartitionCategoryType.QA
                    ? 'default'
                    : 'primary'
                }
                icon={<IconFont name='add' />}
                onClick={() => handleCreate()}
              >
                <span>
                  {datastoreType === PartitionCategoryType.QA
                    ? '导入问答'
                    : '添加文档'}
                </span>
                {datastoreType !== PartitionCategoryType.QA && (
                  <NewFeatureBadge className='right-0 top-[-8px] z-10 absolute'>
                    新增邮件
                  </NewFeatureBadge>
                )}
              </Button>
            </LimitedAccess>
          </div>
        </div>
      )}
      <div
        className={classNames(
          'adapt:mt-24 relative bg-white flex-1 overflow-hidden flex flex-col',
          {
            'rounded-8px': showExtraSearch,
          },
        )}
      >
        <div className='flex-1 overflow-auto'>
          <StyledTable
            isEmpty={documents.length === 0}
            rowKey='file_id'
            sticky={{
              getContainer: () => wrapperRef.current!,
            }}
            rowSelection={{
              type: 'checkbox',
              onChange: onRowSelectedChange,
              selectedRowKeys,
              getCheckboxProps: record => ({
                disabled:
                  record.status === DocumentStatus.Ing ||
                  record.status === DocumentStatus.Wait ||
                  record.status === DocumentStatus.AIProcess,
              }),
            }}
            scroll={{ x: 'none', y: 'auto' }}
            columns={
              datastoreType === PartitionCategoryType.QA
                ? columns.filter(e => e.key !== 'extracted_text_length')
                : columns
            }
            dataSource={documents}
            loading={loading}
            pagination={false}
            onChange={handleTableChange}
            onRow={(record: DocumentItem) => ({
              onMouseEnter: () => {
                setRetryFileId(record.file_id)
              },
              onMouseLeave: () => {
                setRetryFileId(-1)
              },
              onClick: () => {
                onRowClick?.(record.file_id)
              },
            })}
          />
        </div>

        <div className='flex items-center justify-between w-full h-64px px-18 b-t-1 b-solid b-[rgba(225,225,229,0.4)] py-16px'>
          <div className='flex items-center justify-between'>
            {selectedRowKeys.length > 0 ? (
              <div className='flex items-center'>
                <div>
                  <span>已选&nbsp;</span>
                  <span className='text-16px text-font font-bold relative top-1px'>
                    {selectedRowKeys.length}
                  </span>
                  <span className='mx-3'>/</span>
                  <span>{total}&nbsp;条数据</span>
                </div>
                <Button
                  size='small'
                  className='ml-16px px-12px'
                  onClick={() => {
                    copyDocumentsModal.open()
                  }}
                >
                  复制到
                </Button>
                <Button
                  size='small'
                  className='ml-15 important:h-32px important:px-12px'
                  danger
                  type='primary'
                  onClick={onDelete}
                >
                  删除
                </Button>
              </div>
            ) : (
              <div className='text-font_1 font-400 flex items-center'>
                <span>共计 {total} 文档</span>
                {!!failDocumentsCount && (
                  <>
                    <Divider className='mx-12px' type='vertical' />
                    <span>
                      失败{' '}
                      <span className='c-#FF5219'>{failDocumentsCount}</span>{' '}
                      文档
                    </span>
                  </>
                )}
                {!!failDocumentsCount && (
                  <div className='flex items-center ml-12px'>
                    <Tooltip title='导出失败记录'>
                      <div
                        className={classNames(
                          'rounded-4px text-16px/16px w-24px h-24px flex-center c-[rgba(164,165,191)] hover:bg-[rgba(98,105,153,0.08)] cursor-pointer',
                          {
                            '!cursor-not-allowed !op-40': !failDocumentsCount,
                          },
                        )}
                        onClick={() => {
                          if (!failDocumentsCount) {
                            return
                          }
                          downloadFailReasonFileFn(
                            id,
                            datastoreName
                              ? `${dayjs().format(
                                  'YYYY年MM月DD HH时mm分',
                                )}-${datastoreName}-失败记录`
                              : '失败记录',
                          )
                        }}
                      >
                        <IconFont name='daochu-1' />
                      </div>
                    </Tooltip>

                    <Tooltip title='清空失败文档'>
                      <div
                        className={classNames(
                          'rounded-4px w-24px h-24px hover:bg-error hover:bg-op-8 cursor-pointer  c-[rgba(164,165,191)]  flex-center text-16px/16px ml-8px',
                          {
                            '!cursor-not-allowed !op-40 ': !failDocumentsCount,
                            'hover:c-error': failDocumentsCount,
                          },
                        )}
                        onClick={cleanAllFailDocuments}
                      >
                        <IconFont name='shanshu2' />
                      </div>
                    </Tooltip>
                  </div>
                )}
              </div>
            )}
          </div>
          <div>
            <StyledPagination
              current={pageOptions.page}
              pageSize={pageOptions.page_size}
              onChange={async (page, pageSize) => {
                const options = {
                  ...pageOptions,
                  page,
                  page_size: pageSize,
                }
                await fetchDocumentsList(options)
                setSelectedRowKeys([])
              }}
              total={total}
              showSizeChanger
              showQuickJumper
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default React.forwardRef(Documents)
