import type { FormInstance } from 'antd'
import { Form, message, Spin, Tooltip } from 'antd'
import { useBoolean, useRequest } from 'ahooks'
import copy from 'copy-to-clipboard'
import { useEffect, useRef } from 'react'
import type { AgentFlowSkill } from '@bty/chat-types'
import { getFirstNodeConfig } from '@apis/run'
import { SkillFlowViewMode } from '@apis/run/type'
import {
  AppLogo,
  FormItem,
  IconFont,
  JsonEditor,
  Select,
  TextArea,
  usePageModal,
} from '@/components'
import { FieldTypes } from '@/features/nodes/start'
import { useWorkspaceStore } from '@/store'
import type { AutoDescButtonInstance } from '../components/AutoDescButton'
import { AutoDescButton } from '../components/AutoDescButton'
import { FiledTypeSelectOptions } from '@/features/nodes/start/components/FieldTypeSelect'
import { activeSkillTypeDisplayMap } from '@/features/agent/types'
import { InputsViewModeSelect } from '@/features/agent/SkillTabs/InputsViewModeSelect.tsx'
import { FLOW_OUTPUT_MODE_OPTIONS } from '../const'

const FieldTypeMap: Partial<{
  [K in (typeof FieldTypes)[keyof typeof FieldTypes]]: string
}> = {
  [FieldTypes.MultiSelect]: FieldTypes.MultiSelect,
  [FieldTypes.Json]: 'json',
  [FieldTypes.File]: 'string',
}

const exampleJsonSchema = {
  type: 'object',
  properties: {
    name: {
      type: 'string',
      description: '姓名',
    },
    age: {
      type: 'number',
      description: '年龄',
    },
  },
  required: ['name', 'age'],
}

interface FlowConfProps {
  form: FormInstance
  hideSuperLink?: boolean
  hideOutputConfig?: boolean
  versionId?: string
  isDraft?: boolean
  disabled?: boolean
  identifier: string
  conf: AgentFlowSkill
  onLoading?: (loading: boolean) => void
}

export function FlowConf({
  form,
  identifier,
  conf,
  onLoading,
  hideSuperLink = false,
  hideOutputConfig = false,
  disabled = false,
  isDraft = false,
  versionId,
}: FlowConfProps) {
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const AutoDescButtonRef = useRef<AutoDescButtonInstance>(null)

  const pageModal = usePageModal()
  const { data: nodeConf, loading } = useRequest(getFirstNodeConfig, {
    defaultParams: [
      {
        flow_id: identifier,
        isDraft,
        version_id: versionId,
      },
    ],
    refreshDeps: [identifier, isDraft, versionId],
  })

  const [generating, { set: setGenerating }] = useBoolean(false)

  const onAutoLoading = (loading: boolean) => {
    setGenerating(loading)
    onLoading?.(loading)
  }

  const getKeyText = (key: string) => {
    const type = nodeConf?.form_config?.find(v => v.variableName === key)?.type
    return FiledTypeSelectOptions?.find(v => v.value === type)?.title
  }

  const handleAutoDesc = () => {
    AutoDescButtonRef?.current?.generate()
  }

  useEffect(() => {
    // const defaultInputDescriptionMap =
    //   conf.inputs?.reduce<Record<string, string>>((init, input) => {
    //     init[input.key] = input.description
    //     return init
    //   }, {}) ?? {}

    const outputMap = conf.outputs?.reduce<Record<string, string>>(
      (init, output) => {
        init[output.key] = output.value
        return init
      },
      {},
    )

    const inputsViewMode =
      nodeConf?.inputs_properties?.inputsViewMode ?? SkillFlowViewMode.RAW

    form.setFieldsValue({
      description: nodeConf?.skill_desc
        ? nodeConf?.skill_desc
        : (conf?.description ?? nodeConf?.desc_schema?.description ?? ''),
      inputs_properties: nodeConf?.inputs_properties ?? {
        inputsViewMode,
        formWelcome: '',
      },
      inputs:
        nodeConf?.form_config?.map(item => {
          const currSchema =
            nodeConf?.desc_schema?.inputs?.find(
              input => input.key === item.variableName,
            ) || conf.inputs?.find(input => input.key === item.variableName)
          const defaultValue = item.type === FieldTypes.Json ? '' : item.label
          return {
            key: item.variableName,
            name: item.label,
            description: currSchema?.description ?? defaultValue ?? '',
            field_type: FieldTypeMap[item.type] ?? 'string',
            required: item.required,
          }
        }) ?? [],
      output_mode: outputMap?.output_mode || 'agent_processed',
    })
  }, [conf, nodeConf])

  const onInputViewModeChange = (mode: string) => {
    if (mode === SkillFlowViewMode.FORM) {
      form.setFieldValue('output_mode', 'direct_output')
    }
  }

  if (loading) {
    return (
      <div className='w-full h-612px flex-center'>
        <Spin />
      </div>
    )
  }

  return (
    <div>
      <div
        className='flex items-center cursor-pointer'
        onClick={() => {
          !hideSuperLink &&
            pageModal.show({
              url: `/application/${currentWorkspaceId}/${conf.app_id}/flow/${identifier}`,
            })
        }}
      >
        <AppLogo
          size={40}
          color={conf.color}
          value={conf.icon}
          type='emoji'
          fillSize={20}
        />
        <span className='ml-12px font-medium text-16px/20px text-font'>
          {conf.name}
        </span>
        {!hideSuperLink && (
          <span className='text-16px ml-8px w-24px h-24px hover:bg-bg_3 hover:bg-op-12 c-#626999 c-op-60 flex-center'>
            <IconFont name='super-link' />
          </span>
        )}
      </div>
      <div className='relative'>
        {!disabled && (
          <AutoDescButton
            flowId={identifier}
            ref={AutoDescButtonRef}
            isDraft={isDraft}
            className='absolute right-6 top-2 z-10'
            onLoading={onAutoLoading}
            onGenerated={flowSchemaResult =>
              form.setFieldsValue(flowSchemaResult)
            }
            trigger='manual'
          >
            <div
              className='text-primary text-12px cursor-pointer flex hover:text-opacity-60'
              onClick={handleAutoDesc}
            >
              <IconFont name='auto' className='text-16px'></IconFont>
              <p className='ml-4 mt-1px text-14px'>自动生成描述</p>
            </div>
          </AutoDescButton>
        )}
        <Form
          form={form}
          layout='vertical'
          className='mt-24px relative [&_.keyu-form-item-label]:font-medium [&_.keyu-form-item-label>span]:leading-16px'
          requiredMark={false}
          disabled={generating || disabled}
        >
          <FormItem
            name='description'
            label={`${activeSkillTypeDisplayMap.Flow}描述`}
            rules={[{ required: true, message: '技能描述不能为空' }]}
            padding={12}
            required
          >
            <TextArea
              className='!resize-none rounded-8px !h-68px'
              maxLength={300}
              showCount
              placeholder='告诉 Agent 这个技能可以做什么，让它能更好的调用'
            />
          </FormItem>
          <FormItem
            label='输入设置'
            padding={12}
            required
            hidden={!nodeConf?.form_config.length}
          >
            <Form.List name='inputs'>
              {fields => {
                if (!fields.length) {
                  return null
                }
                return (
                  <>
                    <div className='flex items-center h-38px rd-8px border-y border-none border-op-60 bg-bg_3 bg-op-4 font-medium text-14px mt-4px text-font_1'>
                      <span className='pl-16px w-128px truncate'>参数名称</span>
                      <span className='w-106px truncate'>类型</span>
                      <div className='flex items-center flex-1'>
                        <span>告诉 Agent 参数的描述</span>
                        {/* <span className='ml-5 text-error'>*</span> */}
                        <Tooltip
                          title='告诉 Agent 这个变量的含义，让它能更好的调用'
                          placement='top'
                        >
                          <IconFont
                            name='jieshishuimeng'
                            className='ml-5 text-font_1 text-op-40 cursor-pointer'
                          />
                        </Tooltip>
                      </div>
                      <div className='pl-0px pr-12px'>必填</div>
                    </div>
                    {fields.map(inputItem => {
                      const inputValue = form.getFieldValue([
                        'inputs',
                        inputItem.name,
                      ])
                      return (
                        <div
                          className='border-none last-of-type:mb-0 text-14px'
                          key={inputItem.key}
                        >
                          <div className='flex items-center pl-16 py-12'>
                            <span className='flex items-center shrink-0 w-112px'>
                              {inputValue.field_type === FieldTypes.Json && (
                                <IconFont
                                  name='json'
                                  className='c-primary mr-5'
                                />
                              )}
                              <span
                                className='truncate flex-1 text-14px'
                                title={inputValue.key}
                              >
                                {inputValue.key}
                              </span>
                            </span>
                            <div className='w-134px'>
                              {getKeyText(inputValue.key as string)}
                            </div>
                            <Form.Item
                              name={[inputItem.name, 'description']}
                              rules={[
                                {
                                  required: true,
                                  message: '变量描述不能为空',
                                },
                                {
                                  validator: (_rule, value, callback) => {
                                    if (
                                      inputValue.field_type ===
                                        FieldTypes.Json &&
                                      typeof value === 'string'
                                    ) {
                                      try {
                                        JSON.parse(value)
                                        callback()
                                      } catch (e) {
                                        callback('请输入有效的 JSON SCHEMA')
                                      }
                                    } else {
                                      callback()
                                    }
                                  },
                                },
                              ]}
                              noStyle
                            >
                              {inputValue.field_type === FieldTypes.Json ? (
                                <JsonEditor
                                  setOptions={{
                                    minLines: 17,
                                    hasCssTransforms: false,
                                  }}
                                  placeholder={JSON.stringify(
                                    exampleJsonSchema,
                                    null,
                                    2,
                                  ).replaceAll(' ', '  ')}
                                  onCopy={text => {
                                    copy(text)
                                    message.success('内容已复制到剪切板')
                                  }}
                                />
                              ) : (
                                <TextArea
                                  className='!resize-none !min-h-40px !pt-8'
                                  placeholder='说明这个参数代表什么意思'
                                  autoSize={{ minRows: 1 }}
                                />
                              )}
                            </Form.Item>
                            <div className='m-12px w-40px'>
                              {inputValue.required ? '必填' : ''}
                            </div>
                          </div>
                        </div>
                      )
                    })}
                  </>
                )
              }}
            </Form.List>
          </FormItem>
          <FormItem
            name={['inputs_properties', 'inputsViewMode']}
            label='输入展示设置'
            required={true}
          >
            <InputsViewModeSelect onChange={onInputViewModeChange} />
          </FormItem>
          <FormItem
            noStyle={true}
            shouldUpdate={(prevValues, nextValues) =>
              prevValues.inputs_properties?.inputsViewMode !==
              nextValues.inputsViewMode?.inputsViewMode
            }
          >
            {form => {
              const inputsViewMode = form.getFieldValue([
                'inputs_properties',
                'inputsViewMode',
              ])
              return (
                <>
                  <FormItem
                    noStyle
                    hidden={inputsViewMode === SkillFlowViewMode.RAW}
                  >
                    <FormItem
                      label='表单开场白'
                      name={['inputs_properties', 'formWelcome']}
                    >
                      <TextArea placeholder='例如，请填写表单' />
                    </FormItem>
                  </FormItem>
                  {!hideOutputConfig && (
                    <FormItem
                      name='output_mode'
                      label='输出设置'
                      padding={12}
                      wrapperCol={{ span: 12 }}
                    >
                      <Select
                        // disabled={inputsViewMode === SkillFlowViewMode.FORM}
                        className='!h-auto'
                        options={
                          inputsViewMode === SkillFlowViewMode.FORM
                            ? FLOW_OUTPUT_MODE_OPTIONS.filter(
                                v => v.value !== 'agent_processed',
                              )
                            : FLOW_OUTPUT_MODE_OPTIONS
                        }
                      />
                    </FormItem>
                  )}
                </>
              )
            }}
          </FormItem>
        </Form>
      </div>
    </div>
  )
}
