import type { TableProps, TableColumnsType } from 'antd'
import { Spin, Table } from 'antd'
import { isNil, isArray, omit } from 'lodash-es'
import styled from '@emotion/styled'
import { useCallback, useMemo, useState, useEffect } from 'react'
import { useRequest } from 'ahooks'
import { useNavigate, useSearchParams, useParams } from 'react-router-dom'
import type {
  ParametersWithInput,
  ParametersWithProperty,
  Tool,
} from '@bty/chat-types'
import { useAuth } from '@bty/react-auth'
import { Chat, ToolBar } from '@bty/chat-ui'
import { transformAPIRes2MessageList } from '@bty/chat-logic'
import { RoleCode } from '@apis/authority/type'
import { FlowStatus } from '@apis/flow/type'
import type { Metadata } from '@apis/plugins/type'
import { PluginDataType } from '@apis/plugins/type'
import { getPluginInfo, setPluginData } from '@apis/plugins'
import { Button, IconFont } from '@/components'
import { FlowLogger } from '@/features/logger'
import { JSONEditor } from '@/features/logger/components/JSONEditor'
import SelectAgentModal from '../components/SelectAgentModal'

const StyledTable = styled(Table)`
  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:first-child {
    border-start-start-radius: 0px !important;
  }

  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-container
    table
    > thead
    > tr:first-child
    > *:last-child {
    border-start-end-radius: 0px !important;
  }

  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-cell,
  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-thead
    > tr
    > th {
    padding: 10px 12px;
  }

  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-thead
    > tr
    > th,
  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-thead
    > tr
    > td {
    font-weight: 500;
    line-height: 16px;
    color: #17171d;
    font-family: PingFang SC;
  }

  :where(.css-dev-only-do-not-override-rztbr1).ant-table-wrapper
    .ant-table-thead
    > tr
    > th {
    border-bottom: 1px solid rgba(225, 225, 229, 0.6);
    border-top: 1px solid rgba(225, 225, 229, 0.6);
  }

  :where(.css-dev-only-do-not-override-rztbr1)[class^='ant-table']
    [class^='ant-table']::before {
    display: none;
  }

  .ant-table-tbody .ant-table-cell {
    padding: 16px 12px;
  }
`

export enum PageType {
  // 页面中展示 有侧边栏
  infoWithSidebar = 'infoWithSidebar',
  // 弹窗中展示 无侧边栏 无“立即使用” 无“返回”
  infoOnly = 'infoOnly',
  // agent添加插件弹窗中展示
  infoInAgent = 'infoInAgent',
}

interface Parameter {
  type: string
  name: string
  key: string
  required: boolean
  defaultValue: any
  description: any
  component?: string
  example: any
  extraConf: Record<string, any>
  options?: Array<{ label: string; value: any }>
}

interface Props {
  pageType?: PageType
  id?: string
}

function getPadding(pageType: PageType) {
  switch (pageType) {
    case PageType.infoWithSidebar: {
      return { padding: '32px 48px' }
    }
    case PageType.infoOnly: {
      return { padding: '30px 45px' }
    }
    case PageType.infoInAgent: {
      return { padding: '24px 32px' }
    }
    default:
      return {}
  }
}

function formatValue(value: any, col: Parameter | Metadata) {
  let finalValue: string | undefined

  if (value === '' || isNil(value)) {
    finalValue = '-'
    return finalValue
  }

  if (isArray(col.options)) {
    finalValue = col.options.find(
      (item: { label: string; value: any }) => item.value === value,
    )?.label
  }
  return finalValue ?? JSON.stringify(value, null, 2)
}

function ToolComponent(props: Tool) {
  const [showLog, setShowLog] = useState(true)

  const logs = [
    {
      hideOutputLabel: true,
      name: props.title,
      status:
        props.status === 'success' ? FlowStatus.SUCCEEDED : FlowStatus.FAIL,
      duration: 2000,
      showTime: false,
      showRun: false,
      input: props?.data?.content?.input || '',
      output: props?.data?.content?.output || '',
      type: props?.data?.tool_type,
      errorMessage: props?.data?.message,
    },
  ]

  return (
    <div>
      <ToolBar onClick={() => setShowLog(!showLog)} {...props} />
      <div className='h-[4px] bg-[transparent]'></div>
      {showLog ? (
        <div className={'bg-white rounded-[12px] py-[8px] px-[12px]'}>
          <FlowLogger defaultActiveKey={[props.title]} outputs={logs} />
        </div>
      ) : null}
    </div>
  )
}

function PluginInfo(props: Props) {
  const {
    state: { role },
  } = useAuth()

  const [isModalOpen, setModalOpen] = useState(false)
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const { pluginId } = useParams()
  const id = pluginId ?? props.id
  const pageType = searchParams.get('pageType') ?? props.pageType

  const functionId = id

  useEffect(() => {
    id && setPluginData(PluginDataType.view, id)
  }, [id])

  const handleCancel = useCallback(() => {
    setModalOpen(false)
  }, [])

  const { data, error, loading } = useRequest(getPluginInfo, {
    defaultParams: [id!],
  })

  const pluginInfoData = data

  const goBack = () => {
    navigate(-1)
  }

  const messageList = useMemo(() => {
    if (!Array.isArray(pluginInfoData?.usage_demo?.context)) {
      return []
    }
    return transformAPIRes2MessageList(
      pluginInfoData?.usage_demo.context ?? [],
      {
        rightActions: ['copy'],
        robotAvatarInfo: {
          icon: 'https://resource.bantouyan.com/betteryeah/agent/avatars/avatar-23.png',
          name: 'plugin测试',
        },
        userAvatarInfo: {
          color: 'rgba(20, 171, 241, 0.12)',
          icon: 'j',
          shape: 'circle',
        },
        layout: 'horizontal',
        filterRelatedQuestion: true,
        botMessageProps: {
          responseExtend: true,
          // backgroundColor: 'rgb(241,241,246)',
          backgroundColor: 'rgba(247, 247, 250, 0.6)',
        },
      },
    )
  }, [pluginInfoData])

  const consumeTableConfig = useMemo(() => {
    if (!Array.isArray(pluginInfoData?.consume_info?.model_list)) {
      return {
        columns: [],
        dataSource: [],
      }
    }

    const { consume_mode } = pluginInfoData?.consume_info ?? {}

    return {
      columns: [
        {
          title: consume_mode?.includes('模型')
            ? '模型'
            : consume_mode?.includes('解析方式')
              ? '解析方式'
              : '模式',
          key: 'model',
          dataIndex: 'model',
        },
        {
          title: '积分',
          key: 'consume',
          dataIndex: 'consume',
        },
      ],
      dataSource: pluginInfoData?.consume_info.model_list,
    }
  }, [pluginInfoData])

  const parameters = useMemo(() => {
    if (!pluginInfoData?.parameters) return []

    try {
      if ((pluginInfoData.parameters as ParametersWithInput).inputs) {
        return (pluginInfoData.parameters as ParametersWithInput).inputs.map(
          item => ({
            type:
              (item.field_type as { type: string })?.type ?? item.field_type,
            name: item.name ?? '未命名',
            key: item.key,
            required: item.required,
            defaultValue: item.default,
            description:
              (item.description as { description: string })?.description ??
              item.description,
            component: item.component,
            example: item.example,
            extraConf: omit(item, [
              'name',
              'key',
              'required',
              'default',
              'description',
              'component',
            ]),
          }),
        )
      } else if (
        (pluginInfoData.parameters as ParametersWithProperty).properties
      ) {
        return Object.entries(
          (pluginInfoData.parameters as ParametersWithProperty).properties,
        ).map(([key, property]) => {
          return {
            key,
            name: property.name ?? '未命名',
            type: (property.type as { type: string })?.type ?? property.type,
            description:
              (property.description as { description: string })?.description ??
              property.description,
            defaultValue: property.default,
            required: (
              pluginInfoData.parameters as ParametersWithProperty
            ).required.includes(key),
            component: property.component,
            example: property.example,
            extraConf: omit(property, [
              'name',
              'required',
              'default',
              'description',
              'component',
            ]),
          }
        })
      }
    } catch (e) {}

    return []
  }, [pluginInfoData])

  const parameterTableConfig = useMemo(() => {
    if (!parameters)
      return {
        columns: [],
        dataSource: [],
      }
    return {
      columns: [
        {
          // ellipsis: true,
          width: '25%',
          title: '参数名称',
          key: 'parameter',
          render: (_: any, col: Parameter) => {
            return (
              <div>
                <div className='flex gap-[6px]'>
                  <span>{col.name}</span>
                  {col.required ? <span className='c-[#FF5219]'>*</span> : null}
                  <div
                    style={{
                      height: 'fit-content',
                      border: '1px solid #E1E1E5',
                      borderRadius: 4,
                      padding: '2px 4px',
                    }}
                    className='c-[#8D8D99] text-[10px] font-medium'
                  >
                    {col.type}
                  </div>
                </div>
                <p className='mt-[4px] c-[#8D8D99] text-[12px] leading-[12px]'>
                  {col.key}
                </p>
              </div>
            )
          },
        },
        {
          // ellipsis: true,
          width: '35%',
          title: '描述',
          key: 'description',
          dataIndex: 'description',
          render: (value: string) => (
            <span className='text-[14px] leading-[16px] c-[#17171D]'>
              {value}
            </span>
          ),
        },
        {
          ellipsis: true,
          width: '15%',
          title: '默认值',
          key: 'defaultValue',
          dataIndex: 'defaultValue',
          render: (value: any, col: Parameter) => {
            return (
              <span className='text-[14px] leading-[16px] c-[#17171D]'>
                {formatValue(value, col)}
              </span>
            )
          },
        },
        {
          // ellipsis: { rows: 2 },
          width: '25%',
          title: '示例',
          key: 'example',
          dataIndex: 'example',
          render: (value: any) => {
            return (
              <span className='text-[14px] leading-[16px] c-[#17171D]'>
                {value === '' || isNil(value)
                  ? '-'
                  : JSON.stringify(value, null, 2)}
              </span>
            )
          },
        },
      ],
      dataSource: parameters,
    }
  }, [parameters])

  const metadataTableConfig = useMemo(() => {
    let columns: TableColumnsType<Metadata & { key: string }> = []
    let dataSource: Array<Metadata & { key: string }> = []

    const metadata = pluginInfoData?.metadata_config?.properties?.inputs
    if (isNil(metadata)) {
      return { columns, dataSource }
    } else {
      columns = [
        {
          // ellipsis: true,
          title: '参数名称',
          width: '25%',
          key: 'parameter',
          render: (_: any, col: Metadata & { key: string }) => {
            return (
              <div>
                <div className='flex gap-[6px]'>
                  <span>{col.display_name}</span>
                  {col.required ? <span className='c-[#FF5219]'>*</span> : null}
                  <div
                    style={{
                      height: 'fit-content',
                      border: '1px solid #E1E1E5',
                      borderRadius: 4,
                      padding: '2px 4px',
                    }}
                    className='c-[#8D8D99] text-[10px] font-medium'
                  >
                    {col.type}
                  </div>
                </div>
                <p className='mt-[4px] c-[#8D8D99] text-[12px] leading-[12px]'>
                  {col.key}
                </p>
              </div>
            )
          },
        },
        {
          width: '50%',
          title: '描述',
          key: 'description',
          dataIndex: 'description',
          render: (value: string) => (
            <span className='text-[14px] leading-[16px] c-[#17171D]'>
              {value}
            </span>
          ),
        },
        {
          ellipsis: true,
          width: '25%',
          title: '默认值',
          key: 'value',
          dataIndex: 'value',
          render: (value: any, col: Metadata) => {
            return (
              <span className='text-[14px] leading-[16px] c-[#17171D]'>
                {formatValue(value, col)}
              </span>
            )
          },
        },
      ]
      dataSource = Object.entries(metadata).map(([k, v]) => {
        return Object.assign(v, { key: k })
      })

      return { columns, dataSource }
    }
  }, [pluginInfoData])

  const MemoChat = useMemo(() => {
    if (!messageList?.length) return null

    return (
      <div
        id='plugin-chat-preview'
        style={{
          borderLeft: '1px solid rgba(225, 225, 229, 0.6)',
          width: '40%',
          height:
            pageType === PageType.infoWithSidebar
              ? 'calc(100vh - 72px)'
              : '100%',
        }}
        className='overflow-scroll'
      >
        {/* @ts-expect-error: onFileUpload is not neccessary */}
        <Chat
          toolComponent={ToolComponent}
          staticPreview={true}
          uniqueId='827a7128a8e94439b90c1e7054eba4de'
          data={messageList}
          features={{ header: true }}
          title={
            <header className='flex items-center h-56px px-24 bg-[rgba(247,247,250,0.6)] border-bottom after:border-[rgba(225,225,229,0.6)] after:w-auto after:left-24 after:right-24'>
              <h1 className='font-medium text-16px text-font'>效果预览</h1>
            </header>
          }
        />
      </div>
    )
  }, [messageList])

  if (error) {
    return null
  }

  return (
    <div className='flex flex-col w-100% h-100% bg-[#fff] rounded-b-[10px]'>
      <Spin wrapperClassName='h-100% plugin-info-spin' spinning={loading}>
        {pageType !== PageType.infoInAgent ? (
          <div className='pl-[24px] pr-[24px] w-100% h-[72px] flex flex-row justify-between border-b-1 border-[rgba(225, 225, 229, 0.6)] items-center'>
            <div className='flex gap-[12px] items-center'>
              {pageType === PageType.infoWithSidebar ? (
                <div
                  onClick={goBack}
                  className='w-32px h-32px flex justify-center items-center rounded-[6px] hover:bg-bg_3 hover:bg-op-8 cursor-pointer'
                >
                  <IconFont name='fanhui' className='text-16px' />
                </div>
              ) : null}
              <div className='flex gap-[8px] items-center'>
                <img
                  className='w-[40px] h-[40px]'
                  src={pluginInfoData?.metadata_config?.icon}
                />
                <div className='leading-[32px] text-[20px] font-semibold c-#17171D'>
                  {pluginInfoData?.display_name}
                </div>
              </div>
            </div>
            {pageType === PageType.infoOnly ||
            role === RoleCode.VIEWER ? null : (
              <Button
                onClick={() => setModalOpen(true)}
                style={{ width: 88 }}
                className='h-36px'
                type='primary'
                block
              >
                立即使用
              </Button>
            )}
          </div>
        ) : null}
        <div className='flex flex-row flex-1 overflow-hidden'>
          <div
            style={{
              ...getPadding(pageType as PageType),
              scrollbarWidth: 'none',
            }}
            className='flex flex-col gap-[32px] flex-1 overflow-auto scrollbar-hide'
          >
            <div className='flex flex-col gap-[12px]'>
              <h1 className='text-[16px] font-semibold leading-[20px] c-[#17171D]'>
                功能描述
              </h1>
              <p className='text-[14px] leading-[22px] c-[#17171D]'>
                {pluginInfoData?.description}
              </p>
            </div>
            <div className='flex flex-col gap-[12px]'>
              <h1 className='text-[16px] font-semibold leading-[20px] c-[#17171D]'>
                积分消耗规则
              </h1>
              <p className='text-[14px] leading-[22px] c-[#17171D]'>
                {pluginInfoData?.consume_info?.consume_mode === '固定计费'
                  ? pluginInfoData.consume_info.consume
                  : pluginInfoData?.consume_info?.consume_mode}
              </p>
              {Array.isArray(pluginInfoData?.consume_info?.model_list) ? (
                <StyledTable pagination={false} {...consumeTableConfig} />
              ) : null}
            </div>
            <div className='flex flex-col gap-[12px]'>
              <h1 className='text-[16px] font-semibold leading-[20px] c-[#17171D]'>
                可配置项
              </h1>
              {!parameterTableConfig?.dataSource?.length ? (
                <p className='text-[14px] leading-[22px] c-[#17171D]'>
                  该插件无可配置项
                </p>
              ) : (
                <StyledTable
                  pagination={false}
                  {...(parameterTableConfig as TableProps)}
                />
              )}
            </div>

            {metadataTableConfig?.dataSource?.length ? (
              <div className='flex flex-col gap-[12px]'>
                <h1 className='text-[16px] font-semibold leading-[20px] c-[#17171D]'>
                  固定配置
                </h1>
                <StyledTable
                  pagination={false}
                  {...(metadataTableConfig as TableProps)}
                />
              </div>
            ) : null}

            {isNil(pluginInfoData?.resp_sample) ||
            (isArray(pluginInfoData?.resp_sample) &&
              !pluginInfoData?.resp_sample.length) ? null : (
              <div className='flex flex-col gap-[12px]'>
                <h1 className='text-[16px] font-semibold leading-[20px] c-[#17171D]'>
                  返回示例
                </h1>
                <JSONEditor
                  className='pt-10px!'
                  value={JSON.stringify(pluginInfoData.resp_sample, null, 2)}
                />
              </div>
            )}
          </div>
          {MemoChat}
        </div>
        <SelectAgentModal
          open={isModalOpen}
          handleCancel={handleCancel}
          functionId={functionId}
        />
      </Spin>
    </div>
  )
}

export default PluginInfo
