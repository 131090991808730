import styled from '@emotion/styled'
import type { ReactNode } from 'react'
import React, { useMemo, createContext, useContext, useRef } from 'react'

import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import { isUndefined, isString, isNull } from 'lodash-es'
import { message } from 'antd'
import { FlowStatus } from '@apis/flow/type'
import {
  IconFont,
  Button,
  GeneratedByAIText,
  Markdown,
  RunningLoading,
} from '@/components'
import { CodeEditor } from '@/features/editor'
import { isMarkdown, isEmptyString } from '@/pages/flowPage/util'

import theme from '@/constants/theme'

interface DebugResultPanelProps {
  run: (params?: Record<string, any>) => Promise<any>
  result: Record<string, any> | null
  setResult?: (newResult: Record<string, any>) => void

  variables: Record<string, any>
  setVariables: (params: Record<string, any>, isMergeUpdate?: boolean) => void

  visible: boolean
  setVisible: (visible: boolean) => any

  loading: boolean
  triggerNode?: React.ReactNode | null
  registerTriggerNode: (
    node: React.FC,
    callback: () => Promise<void | any>,
  ) => void

  setDebuggerPanelExtraButton: (node: ReactNode) => void
  setExtraLeftButton: (node: ReactNode) => void
  setExtraRightButton: (node: ReactNode) => void
}

export const DebugResultPanelContext = createContext<DebugResultPanelProps>({
  run: async () => {},
  result: null,
  setResult: () => null,

  visible: false,
  setVisible: () => null,

  variables: {},
  setVariables: () => {},

  loading: false,
  triggerNode: null,
  registerTriggerNode: () => null,
  setExtraLeftButton: () => null,
  setExtraRightButton: () => null,
  setDebuggerPanelExtraButton: () => null,
})

const DebuggerResultWrapper = styled.div`
  border-top: 1px solid rgba(225, 225, 229, 0.6);
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: #ffffff;
`

const Content = styled.div<{ visible: boolean; height: string }>`
  height: ${({ visible, height }) => (visible ? height : '0px')};
  width: 100%;
  // background: red;
  transition: height 500ms cubic-bezier(0.075, 0.82, 0.165, 1);
  will-change: height;
  overflow-y: auto;
  padding: 0 16px;
`
export function DebugResultPanel(props: {
  height?: number
  resultExtraButton?: ReactNode
}) {
  const { height = 500, resultExtraButton } = props

  const { visible, setVisible, result, loading } = useContext(
    DebugResultPanelContext,
  )

  const wrapRef = useRef<HTMLDivElement | null>(null)

  const h = useMemo(() => `${height}px`, [height])
  const icon = useMemo(
    () => (visible ? 'mianbanxiangxiashouqi' : 'mianbanxiangshangzhankai'),
    [visible],
  )

  const isMarkdownType = useMemo(
    () =>
      result?.output && isString(result.output) && isMarkdown(result.output),
    [result?.output],
  )

  const isCodeType = useMemo(() => result && !isString(result.output), [result])
  const isEmpty = useMemo(
    () => result && (isNull(result.output) || isEmptyString(result.output)),
    [result],
  )

  const status = useMemo(() => result?.status, [result])

  const onCopy = (text: string) => {
    copy(text)
    message.success('复制成功')
  }

  const MemoCodeElement = useMemo(() => {
    if (isCodeType && result) {
      return (
        <CodeEditor
          readOnly
          value={JSON.stringify(result?.output, null, 2)}
          mode='json'
        />
      )
    }
    return null
  }, [isCodeType, result?.output])

  const MarkdownElement = useMemo(() => {
    if (isMarkdownType && !isEmpty && result?.output) {
      return (
        <>
          <div
            ref={wrapRef}
            className='h-[calc(100%-44px)] p-24px b-rd-8px b-1 b-#e1e1e5 b-op-60 overflow-y-auto'
          >
            <Markdown text={result.output} />
          </div>
          <div className='mt-12px flex justify-between items-center'>
            <GeneratedByAIText />
            <div className='flex items-center'>
              {!!resultExtraButton && (
                <div className='flex-center mr-8px'>{resultExtraButton}</div>
              )}
              <Button
                className='flex-center h-32px! text-12px font-500 b-op-80'
                icon={
                  <IconFont name='copy' className='text-16px text-font_1' />
                }
                onClick={() => {
                  result?.output && onCopy(result.output)
                }}
              >
                复制
              </Button>
            </div>
          </div>
        </>
      )
    }
    return null
  }, [isMarkdownType, result?.output, isEmpty, resultExtraButton])

  const durationTime = useMemo(() => {
    return `${result?.durationTime} s`
  }, [result?.durationTime])

  const showLoading = useMemo(() => loading, [loading, result?.output])

  return (
    <DebuggerResultWrapper>
      <div
        className='flex justify-between items-center flex-1 p-16px'
        onDoubleClick={e => {
          e.preventDefault()
          setVisible(!visible)
        }}
      >
        <p className='text-14px line-height-16px c-#17171D font-500'>
          运行结果
        </p>
        <div className='cursor-pointer p-8px rounded-4px hover:bg-[rgba(98,105,109,0.08)]'>
          <IconFont
            className='text-16px'
            name={icon}
            onClick={() => setVisible(!visible)}
          />
        </div>
      </div>
      <Content height={h} visible={visible}>
        {status && !showLoading && (
          <div className='flex-1 flex justify-between text-12px line-height-14px mb-12px'>
            <p
              className='flex items-center justify-center'
              style={{
                color:
                  status === FlowStatus.SUCCEEDED
                    ? theme.colors.font
                    : theme.colors.error,
              }}
            >
              <span className='text-14px'>
                {status === FlowStatus.SUCCEEDED && (
                  <IconFont name='chenggongbaocun' />
                )}
                {status === FlowStatus.FAIL && <IconFont name='cuowu' />}
              </span>
              <span className='mx-8px'>Run</span>
              <span></span>
            </p>
            <p className='c-#8D8D99'>{durationTime}</p>
          </div>
        )}
        <>
          <div
            className={classNames('flex-1 shrink-0', {
              'flex flex-col overflow-y-auto': isMarkdownType,
            })}
          >
            {showLoading && (
              <div className='flex-center flex-col h-100%'>
                <RunningLoading loading={loading} />
              </div>
            )}
            {!showLoading &&
              (status === FlowStatus.FAIL ? (
                <div className='flex flex-items-center flex-justify-center b-rd-8px bg-#ff5219 bg-op-8 px-12px py-8px'>
                  <IconFont name='cuowu' className='text-16px m-r-8' />
                  <span className='c-#99310f'>{result?.message}</span>
                </div>
              ) : (
                <>
                  {isUndefined(result?.output) && (
                    <RunningLoading loading={loading} />
                  )}
                  {MarkdownElement}
                  {isEmpty && (
                    <div className='h-full flex-center c-bg_3 c-op-40'>
                      emmm, 流程貌似没有输出内容
                    </div>
                  )}
                  {MemoCodeElement}
                </>
              ))}
          </div>
        </>
      </Content>
    </DebuggerResultWrapper>
  )
}
