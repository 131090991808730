import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import type { Bot } from '@bty/chat-types'
import { getBotList } from '@apis/chat-bot'
import { robotStorage } from '@/utils/storage'

export type StoreBotItem = Partial<Bot> & {
  id: number | string
  name: string
  description: string
  avatar: string
}
export interface BotStore {
  botList: StoreBotItem[]
  setBotList: (list: StoreBotItem[]) => void
  fetchBotList: () => Promise<Bot[]>
  sortListByLatestId: (latestBotId: string | number) => void
}

export const useBotStore = create<BotStore>((set, get) => ({
  botList: [],
  setBotList: list => {
    set({
      botList: list,
    })
  },
  fetchBotList: async () => {
    const list = await getBotList()
    if (list) {
      set({
        botList: [
          // ...get().botList,
          ...list,
        ],
      })

      const currentRobotId = robotStorage.get()
      if (
        !currentRobotId ||
        (currentRobotId && !list.find(v => v.robot_id === currentRobotId))
      ) {
        robotStorage.set(`${list?.[0]?.robot_id}`)
      }
    }
    return list || []
  },
  sortListByLatestId: latestBotId => {
    const { botList } = get()
    const latestBot = botList.find(item => item.id === latestBotId)
    if (latestBot) {
      const rest = botList.filter(item => item.id !== latestBotId)
      set({
        botList: [latestBot, ...rest],
      })
    }
  },
}))

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('botStore', useBotStore)
}
