import { Form } from 'antd'
import { useMemo, type ChangeEventHandler, useState } from 'react'
import { useBoolean, useRequest } from 'ahooks'
import type { LoginOrRegisterParams } from '@apis/user/type'
import { loginOrRegister, saveOrUpdateUserProfile } from '@apis/user'
import { Input } from '@/components'
import { codeRules, mobileReg, mobileRules } from '../constant'
import { PageLink } from '@/components/pageModal/PageLink'
import { PRIVACY_URL, SERVICE_URL } from '@/constants/url'
import { tokenStorage, useIdStorage } from '@/utils/storage'
import { useSendVerifyCode } from '@/hooks/useSendVerifyCode'
import { PrivateDeployGuard } from '@/features/guard/PrivateDeployGuard.tsx'
import { isPrivateVersion } from '@/constants/common.ts'
import { sendAdEvent, AdEvents } from '@/utils/sendAdEvent'
import useHandleQueryParams from '../hooks/useHandleQueryParams'
import { LoginButton, LoginTextButton, StyledCheckbox } from './LoginButton'

export interface LoginFormProps {
  onSuccess: () => void
}

export function VerifyLoginForm(props: LoginFormProps) {
  const { onSuccess } = props
  const [checked, { toggle: toggleChecked }] = useBoolean(isPrivateVersion)
  const [form] = Form.useForm<LoginOrRegisterParams>()
  const [phoneValue, setPhoneValue] = useState('')
  const { utm_term, utm_sou, channel } = useHandleQueryParams()
  const { sending, isSendingSMS, verifyButtonText, onSendVerifyCode } =
    useSendVerifyCode()

  const sendDisabled = useMemo(() => {
    return isSendingSMS || !mobileReg.test(phoneValue)
  }, [isSendingSMS, phoneValue])

  const { runAsync: loginOrRegisterApi, loading } = useRequest(
    loginOrRegister,
    { manual: true },
  )
  const { runAsync: saveResigterUserProfile } = useRequest(
    saveOrUpdateUserProfile,
    { manual: true },
  )

  const handleChangeUserName: ChangeEventHandler<HTMLInputElement> = e => {
    // 禁止空格
    form.setFieldValue('phone', e.target.value.replace(/\s/g, ''))
  }

  const handleSendVerifyCode = async () => {
    const { phone } = await form.validateFields(['phone'])
    onSendVerifyCode({ phone })
  }

  const handleLogin = async () => {
    const values = await form.validateFields()
    const { phone, verifyCode } = values
    if (verifyCode) {
      const res = await loginOrRegisterApi({ phone, verifyCode })
      if (res.accessToken) {
        tokenStorage.set(res.accessToken)
        useIdStorage.set(String(res.userId))
        onSuccess()
      }
      if (res?.isNewUser) {
        // 发送广告投流事件
        sendAdEvent(AdEvents.SIGNUP)
        channel &&
          utm_term &&
          saveResigterUserProfile(
            res?.userId,
            {
              utm_term,
              ...(utm_sou ? { utm_sou } : {}),
            },
            channel,
          )
      }
    }
  }

  return (
    <>
      <Form
        form={form}
        layout='vertical'
        requiredMark={false}
        onValuesChange={values => {
          if (values.phone) {
            setPhoneValue(values.phone)
          }
        }}
      >
        <Form.Item name='phone' label='手机号' required rules={mobileRules}>
          <Input
            size='large'
            type='text'
            placeholder='输入手机号'
            maxLength={11}
            onChange={handleChangeUserName}
            autoFocus
          />
        </Form.Item>
        <Form.Item name='verifyCode' label='验证码' required rules={codeRules}>
          <Input
            size='large'
            placeholder='输入验证码'
            maxLength={6}
            suffix={
              isSendingSMS ? (
                <span>{verifyButtonText}</span>
              ) : (
                <LoginTextButton
                  type='text'
                  disabled={sendDisabled}
                  className='w-102px rd-8px! mr--14px'
                  loading={sending}
                  onClick={handleSendVerifyCode}
                >
                  {verifyButtonText}
                </LoginTextButton>
              )
            }
          />
        </Form.Item>
      </Form>
      <div className='mb-10 mt-48'>
        <PrivateDeployGuard>
          <StyledCheckbox checked={checked} onChange={toggleChecked}>
            <p className='text-font_1 text-12px'>
              同意{' '}
              <PageLink href={SERVICE_URL} className='text-primary'>
                服务协议
              </PageLink>{' '}
              和{' '}
              <PageLink href={PRIVACY_URL} className='text-primary'>
                用户隐私协议
              </PageLink>
              ，未注册的手机号将自动创建账号
            </p>
          </StyledCheckbox>
        </PrivateDeployGuard>
      </div>
      <LoginButton
        type='primary'
        block
        size='large'
        disabled={!checked}
        loading={loading}
        onClick={handleLogin}
      >
        {isPrivateVersion ? '登录' : '登录/注册'}
      </LoginButton>
    </>
  )
}
