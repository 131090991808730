import { Access, useAuth } from '@bty/react-auth'
import { Spin } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import type { ApplicationBodyType, GroupType } from '@apis/application/type'
import { RoleCode } from '@apis/authority/type'
import { Button, IconFont } from '@/components'
import {
  ResponsiveCard,
  ResponsiveCardGrid,
} from '@/components/list/ResponsiveCardGrid.tsx'
import {
  FLOW_GROUP,
  FLOW_LIST_SCROLL_TOP,
  FLOW_LIST_SEARCH,
} from '@/constants/storage'
import { useCreateFlow } from '@/features/application/hooks/useCreateFlow.ts'
import { useFlowList } from '@/features/application/hooks/useFlowList.ts'
import { DragDiv, GroupWrap, noGroupId, useGroup } from '@/hooks/useGroup'
import { useBackBefore, useScrollBar } from '@/hooks/useScrollBar.ts'
import { useVersionStore, useWorkspaceStore } from '@/store'
import { NewGroupModal } from '@/features/home/NewGroupModal'
import { FLOW_DISPLAYNAME } from '@/constants/common'
import { showStartTipsStorage } from '@/utils/storage'
import { useCache } from '@/hooks/useCache'
import { LimitedAlertBar } from '@/features/pay/LimitedAlert'
import { LimitedAccess } from '@/features/pay/LimitedAccess'
import { PageHeader } from '../home/PageHeader'
import Empty from './components/Empty'
import { FlowItem } from './components/FlowItem'

export default () => {
  const navigate = useNavigate()
  const [searchName, setSearchName] = useCache(FLOW_LIST_SEARCH, '')
  const [data, setData] = useState<ApplicationBodyType[]>([])
  const { access } = useAuth()
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const { fetchVersionByWorkspaceId } = useVersionStore()
  const { loading, refreshAsync } = useFlowList({
    onSuccess: (e: ApplicationBodyType[]) => setData(e),
  })
  const { create: createApp } = useCreateFlow(data, data => {
    showStartTipsStorage.set(true)
    fetchVersionByWorkspaceId(workspaceId)
    navigate(`/application/${workspaceId}/${data.id}/flow/${data.flowId}`)
  })

  const {
    list,
    createGroup,
    updateGroup,
    groupLoading,
    DragType,
    setDragType,
    dragItem,
    setDragItem,
    dragOverItem,
    setDragOverItem,
    phantomItem,
    handleDragStart,
    handleDrop,
    removeToGroup,
    DropdownMenu,
    handleGroup,
    reSet,
    openGroupMap,
    setOpenGroupMap,
    openAllGroup,
  } = useGroup({
    originList: data,
    applicationType: 'AI',
    refresh: refreshAsync,
    groupType: FLOW_GROUP,
  })
  const [open, setOpen] = useState(false)
  const [group, setGroup] = useState<Partial<GroupType>>({}) // 重命名分组
  const [openDropdownMenuId, setOpenDropdownMenuId] = useState('')

  useEffect(() => {
    !!openDropdownMenuId && setOpen(false)
  }, [openDropdownMenuId])

  useEffect(() => {
    open && setOpenDropdownMenuId('')
  }, [open])
  const handleOpenGroup = (id: string) => {
    setOpenGroupMap({ ...openGroupMap, [id]: true })
  }
  const CreateButton = ({ showLine = true }: { showLine?: boolean }) => (
    <Access
      access={access.role([RoleCode.DEVELOPER, RoleCode.ADMINISTRATOR])}
      hide
    >
      <LimitedAccess limitedType='flow'>
        {showLine && <div className='h-20px mr-8px w-1px bg-#E1E1E5'></div>}
        <Button
          type='primary'
          size='middle'
          onClick={() => createApp()}
          icon={<IconFont name='fangtai' />}
        >
          新建{FLOW_DISPLAYNAME}
        </Button>
      </LimitedAccess>
    </Access>
  )

  const filterSearch = (list: ApplicationBodyType[]) =>
    list?.filter((item: any) =>
      item.name.toLowerCase().includes?.(searchName?.toLowerCase()),
    )

  const nothing = useMemo(() => {
    const filterList = filterSearch(data || [])
    return (
      (list.length <= 1 && filterList.length <= 0) ||
      (searchName && filterList.length === 0)
    )
  }, [list, data, searchName])

  const { scrollRef, scrollBar } = useScrollBar()
  useBackBefore(scrollBar, FLOW_LIST_SCROLL_TOP, [!!list.length])

  return (
    <div className='w-full h-full'>
      {loading && groupLoading && (
        <div className='flex-center absolute top-0 left-0 h-full w-full'>
          <Spin />
        </div>
      )}
      <div className='adapt:pt-40 h-full bg-bg border-rd-12px flex flex-col overflow-hidden'>
        <PageHeader
          title={FLOW_DISPLAYNAME}
          searchKey={searchName}
          renderCreate={() => (
            <Access
              access={access.role([RoleCode.DEVELOPER, RoleCode.ADMINISTRATOR])}
              hide
            >
              <LimitedAccess limitedType='flow'>
                <Button
                  className='ml-12'
                  type='primary'
                  onClick={() => createApp()}
                  icon={<IconFont name='fangtai' />}
                >
                  新建{FLOW_DISPLAYNAME}
                </Button>
              </LimitedAccess>
            </Access>
          )}
          showCreateGroup
          onSearch={searchKey => {
            setSearchName(searchKey || '')
            openAllGroup()
          }}
          onGroupCreate={createGroup}
        />
        <div
          ref={scrollRef}
          className='adapt:px-64 h-100% flex-1 overflow-y-scroll'
        >
          <LimitedAlertBar className='mt-20px' />
          <GroupWrap>
            {list.map((item, index) => {
              const filteredChildren = filterSearch(item.children || [])
              return (
                ((searchName && filteredChildren.length > 0) ||
                  !searchName) && (
                  <div
                    className={classNames(
                      'group-item px-4 py-0',
                      dragItem.id === item.id ? 'op-40' : 'op-100',
                      {
                        'bg-primary bg-op-4 border-2px border-solid border-primary rounded-8px':
                          dragOverItem.id === item.id && DragType === 'join',
                        'border-t-2px border-t-solid border-t-primary':
                          dragOverItem.id === item.id && DragType === 'sort',
                      },
                    )}
                    key={item.id}
                    onDragOver={e => {
                      e.preventDefault()
                      item.id !== dragItem?.id && setDragOverItem(item)
                    }} // 解决拖拽结束后渲染滞后的问题
                    onDrop={async e => handleDrop(e, item)}
                    onDragEnd={() => reSet()}
                  >
                    {list.length !== 1 &&
                    (item.id !== noGroupId ||
                      (item.id === noGroupId &&
                        (item.children || []).length > 0)) ? (
                      <DragDiv
                        open={openDropdownMenuId === item.id}
                        onClick={() =>
                          setOpenGroupMap({
                            ...openGroupMap,
                            [item.id]: !openGroupMap[item.id],
                          })
                        }
                        className='group-item-header cursor-pointer flex gap-8 items-center relative py-20'
                      >
                        {index !== list.length - 1 && (
                          <span
                            className='cursor-grab p-7px dragBtn absolute left--24px'
                            onMouseDown={() => setDragType('join')}
                            onMouseUp={() => setDragType(null)}
                            onDragEnd={() => {
                              reSet()
                              setDragItem({})
                            }}
                            draggable={!!DragType && index !== list.length - 1}
                            onDragStart={e =>
                              handleDragStart({ item, DragType: 'sort', e })
                            }
                          >
                            {phantomItem.id === item.id ? (
                              <div
                                className='bg-primary px-24px py-12px text-14px font-600 border-rd-6px color-#fff'
                                key={item.id}
                              >
                                移动一个分组
                              </div>
                            ) : (
                              <IconFont
                                onClick={e => {
                                  e.preventDefault()
                                  e.stopPropagation()
                                }}
                                name='drag'
                                style={{
                                  color: 'rgba(98, 105, 153, 0.6)',
                                  boxSizing: 'content-box',
                                }}
                              />
                            )}
                          </span>
                        )}

                        {phantomItem.id !== item.id && (
                          <div className='cursor-pointer flex gap-4px'>
                            <IconFont
                              name='arrow-1'
                              style={{
                                transition: 'all .2s',
                                transform: openGroupMap[item.id]
                                  ? 'rotate(0deg)'
                                  : 'rotate(-90deg)',
                              }}
                            />
                            <div className='text-16px font-600'>
                              {item.name}
                            </div>
                          </div>
                        )}

                        {index !== list.length - 1 && (
                          <DropdownMenu
                            open={openDropdownMenuId === item.id}
                            openChange={setOpenDropdownMenuId}
                            item={item}
                            title={FLOW_DISPLAYNAME}
                            createAppToAgent={() => createApp(item.id)}
                            handleReName={e => {
                              setGroup(e)
                              setOpen(true)
                            }}
                          />
                        )}
                      </DragDiv>
                    ) : (
                      <div className='h-20px'></div>
                    )}
                    {openGroupMap[item.id] && filteredChildren.length > 0 && (
                      <ResponsiveCardGrid className='flex-1 content-start relative w-full items-start pb-20'>
                        {filteredChildren.map((item, index) => (
                          <ResponsiveCard
                            key={item.id}
                            className='flex justify-center flex-items-center relative'
                            onDragStart={e =>
                              handleDragStart({ item, DragType: 'join', e })
                            }
                            style={{
                              opacity: dragItem.id === item.id ? 0.4 : 1,
                            }}
                            draggable
                          >
                            {phantomItem.id === item.id && (
                              <div
                                className='bg-primary px-24px py-12px text-14px font-600 border-rd-6px color-#fff h-38px w-100% whitespace-nowrap'
                                key={item.id}
                              >
                                移动一个{FLOW_DISPLAYNAME}
                              </div>
                            )}
                            <FlowItem
                              key={index}
                              phantomItem={phantomItem}
                              navigate={navigate}
                              refresh={refreshAsync}
                              handleGroup={handleGroup}
                              {...item}
                              item={item}
                              removeToGroup={removeToGroup}
                              handleOpenGroup={handleOpenGroup}
                              flowData={data}
                              nodeType={item.nodeType?.filter(
                                item => item !== 'START' && item !== 'END',
                              )}
                            />
                          </ResponsiveCard>
                        ))}
                      </ResponsiveCardGrid>
                    )}
                  </div>
                )
              )
            })}
            {nothing ? (
              !access.role([RoleCode.DEVELOPER, RoleCode.ADMINISTRATOR]) ? (
                <Empty />
              ) : (
                <Empty viewer={true} desc={`暂无${FLOW_DISPLAYNAME}`}>
                  <CreateButton showLine={false} />
                </Empty>
              )
            ) : (
              ''
            )}
          </GroupWrap>
        </div>
      </div>
      <NewGroupModal
        open={open}
        initValue={{ groupName: group.name }}
        title={group?.id ? '分组重命名' : undefined}
        onCancel={() => {
          setOpen(false)
          setGroup({})
        }}
        onOk={async e => {
          if (group.id) {
            await updateGroup({ id: group.id, AppGroup: { name: e } })
          }
          setOpen(false)
          setGroup({})
        }}
      />
    </div>
  )
}
