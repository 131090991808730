import { Empty, Spin } from 'antd'
import classNames from 'classnames'
import type { MutableRefObject } from 'react'
import { forwardRef, useRef, useImperativeHandle, useMemo } from 'react'
import type OSS from 'ali-oss'

import { useUnmount } from 'ahooks'
import { DocumentType } from '@apis/datastore/model'
import type { ParagraphItem } from '@/pages/datastores/document-detail/paragraph/ParagraphListItem'
import ParagraphListItem from '@/pages/datastores/document-detail/paragraph/ParagraphListItem'
import ParagraphEditorStyle from '@/pages/datastores/document-detail/paragraph/ParagraphEditorStyle.tsx'
import EmptyImage from '@/assets/dataset/empty@2x.png'
import MarkdownStyle from '@/pages/datastores/components/MarkdownStyle.tsx'
import type { Paragraph } from '../../types/paragraph'

import type {
  SplitItemConfig,
  IParagraphSearchParams,
  ParagraphInstance,
  IParagraphContentUsedToken,
} from '../../hooks/useParagraph'
import { InfiniteScroll } from '@/components/InfiniteScrollComponent'

interface ParagraphListProps {
  border?: boolean
  total: number
  isEmpty: boolean
  className?: string
  paragraphs: ParagraphItem[]
  file_id: number
  onSplit: (splits: [SplitItemConfig, SplitItemConfig], index: number) => void
  onMerge: (prevIndex: number, currIndex: number) => Promise<void>
  onAdd: (idx: number) => void
  onEnableChange: (enable: boolean, index: number, needFetch?: boolean) => void
  onKeywordsChange: (
    keywords: string[],
    index: number,
    needFetch?: boolean,
  ) => void

  onEdit: (index: number, needFetch?: boolean) => void
  registryParagraphInstance: (
    key: number | string,
    instance: ParagraphInstance,
  ) => void
  destroyParagraphInstance: (key: number | string) => void
  loadMore: (position?: 'prev' | 'next') => void
  readonly?: boolean
  onDelete: (key: number | string) => void
  ossClient?: OSS
  file_type: DocumentType
  updateParagraphsItemById: (value: Paragraph) => void
  onSelectParagraph?: (id: number | string) => void
  startIndex?: MutableRefObject<number>
  hasPrev?: boolean
  addParagraphsItem: (value: Paragraph, targetId?: number) => void
  handleGenerateAISummary: (id?: number | undefined) => void
  handleParagraphValidator: (
    content: string,
    callback?: (params: IParagraphContentUsedToken) => void,
  ) => void
  cancelAiProcessListPolling?: () => void
  onListItemClick?: (type: string, params: Record<string, any>) => void
  hasMore?: boolean
  saveLoading?: boolean
  isInited?: boolean
  loading?: boolean
  initReady?: boolean
  activeChunkId?: number
  searchParams?: IParagraphSearchParams
  isEditing: boolean
}

export default forwardRef<HTMLDivElement, ParagraphListProps>((props, ref) => {
  const {
    border,
    total,
    paragraphs,
    readonly,
    file_id,
    isEmpty,
    ossClient,
    onSplit,
    onDelete,
    onMerge,
    onAdd,
    onEnableChange,
    onKeywordsChange,
    onEdit,
    registryParagraphInstance,
    destroyParagraphInstance,
    loadMore,
    file_type,
    startIndex,
    hasPrev,
    hasMore,
    loading,
    initReady,
    activeChunkId,
    updateParagraphsItemById,
    addParagraphsItem,
    handleGenerateAISummary,
    handleParagraphValidator,
    saveLoading,
    onSelectParagraph,
    onListItemClick,
    cancelAiProcessListPolling,
    searchParams,
    isInited,
    isEditing,
  } = props

  const targetRef = useRef<any>()

  useUnmount(() => {
    cancelAiProcessListPolling?.()
  })

  const paragraphListItem = useMemo(() => {
    return paragraphs.map((paragraph, index) => {
      const nowIndex = (startIndex?.current || 0) + index + 1

      const domKey = !isEditing
        ? paragraph.chunk_id || paragraph.key
        : `temp_key_${paragraph.chunk_id || paragraph.key}`
      return (
        <div
          key={domKey}
          data-id={paragraph.chunk_id || paragraph.key}
          className={classNames({ 'mt-20px': index !== 0 })}
        >
          {file_type === DocumentType.QA ? (
            <ParagraphListItem.QAparagraphListItem
              first={index === 0}
              last={index === paragraphs.length - 1}
              border={border}
              numText={`${nowIndex} / ${total}`}
              rank={`${nowIndex}`}
              readonly={readonly}
              onEdit={() => onEdit(index)}
              paragraph={paragraph}
              file_id={file_id}
              activeId={activeChunkId}
              onEditorLoad={instance =>
                registryParagraphInstance(paragraph.key, instance)
              }
              onEditorDestroy={() => destroyParagraphInstance(paragraph.key)}
              onEnableChange={enable => onEnableChange(enable, index)}
              onDelete={onDelete}
              updateParagraphsItemById={updateParagraphsItemById}
              addParagraphsItem={addParagraphsItem}
              onSelect={onSelectParagraph}
            />
          ) : (
            <>
              <ParagraphListItem.NormalParagraphListItem
                isEditing={isEditing}
                first={index === 0}
                last={index === paragraphs.length - 1}
                border={border}
                numText={`${nowIndex} / ${total}`}
                rank={`${nowIndex}`}
                readonly={readonly}
                paragraph={paragraph}
                file_id={file_id}
                activeId={activeChunkId}
                onEdit={() => {
                  onEdit(index, false)
                }}
                onEditorLoad={instance =>
                  registryParagraphInstance(paragraph.key, instance)
                }
                handleParagraphValidator={handleParagraphValidator}
                onEditorDestroy={() => destroyParagraphInstance(paragraph.key)}
                onEnableChange={enable =>
                  onEnableChange(enable, index, !isEditing)
                }
                onKeywordsChange={keywords => {
                  onKeywordsChange(keywords, index, !isEditing)
                }}
                onDelete={onDelete}
                onSplit={splits => onSplit(splits, index)}
                onMerge={() => onMerge(index, index + 1)}
                onAdd={() => onAdd(index)}
                handleGenerateAISummary={handleGenerateAISummary}
                onSelect={onSelectParagraph}
                onListItemClick={onListItemClick}
              />
            </>
          )}
        </div>
      )
    })
  }, [
    paragraphs,
    readonly,
    ossClient,
    file_id,
    activeChunkId,
    onMerge,
    isEditing,
  ])

  useImperativeHandle(ref, () => targetRef.current, [targetRef])

  return (
    <div
      className={classNames(
        props.className,
        'py-20 h-full flex-1 overflow-y-auto',
      )}
    >
      <div
        className={classNames(
          'box-border h-full overflow-y-auto pr-24 overflow-x-hidden',
          {
            'pb-96px': DocumentType.QA === file_type,
          },
        )}
        ref={targetRef}
      >
        <Spin spinning={saveLoading || !isInited} className='min-h-50vh'>
          <InfiniteScroll
            targetRoot={targetRef?.current}
            disabled={!initReady || paragraphs.length === 0}
            hasPrev={!!hasPrev}
            hasMore={!!hasMore}
            loading={loading}
            load={loadMore}
            loader={
              <div className='w-100% flex items-center flex-center py-8'>
                <Spin />
              </div>
            }
          >
            <MarkdownStyle />
            <ParagraphEditorStyle />
            <div>{paragraphListItem}</div>

            {!hasMore && isEmpty && (
              <div className='w-full h-full flex-center min-h-70vh'>
                <Empty
                  description={
                    <span className='text-font_1'>
                      {searchParams?.chunk_status || searchParams?.search_words
                        ? '没有搜索到相关段落'
                        : '内容为空'}
                    </span>
                  }
                  image={EmptyImage}
                  imageStyle={{
                    width: 128,
                    height: 128,
                  }}
                />
              </div>
            )}
          </InfiniteScroll>
        </Spin>
      </div>
    </div>
  )
})
