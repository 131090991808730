import type { ISubFlowItem } from '@apis/flow/type'
import { FLOW_DISPLAYNAME } from '@/constants/common'
import { FlowItemCard } from './FlowItemCard'

interface FlowListProps {
  data: ISubFlowItem[]
  activeKey: string
  onSelect: (id: string) => void
}

export function FlowList(props: FlowListProps) {
  const { data, activeKey, onSelect } = props

  return (
    <>
      <div className='pt-12px pb-24px text-16px font-500 c-#17171d'>
        {FLOW_DISPLAYNAME}
      </div>
      {data.length ? (
        data.map(({ flow_id, flow_name, icon, color, description }) => (
          <FlowItemCard
            key={flow_id}
            id={flow_id}
            name={flow_name}
            icon={icon}
            iconColor={color}
            description={description}
            selected={flow_id === activeKey}
            onClick={onSelect}
          />
        ))
      ) : (
        <div className='mb-10px line-height-18px text-#8d8d99 text-op-60'>
          暂无可用工作流
        </div>
      )}
    </>
  )
}
