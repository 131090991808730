export const API_TOKEN_KEY =
  !window.__DEFINE_ENV__ || window.__DEFINE_ENV__ === 'production'
    ? 'API_TOKEN'
    : 'API_TOKEN' + `_${window.__DEFINE_ENV__}`
export const ID_TOKEN_KEY = 'ID_TOKEN_KEY'
export const WORKSPACE_ID_KEY = 'WORKSPACE_ID'
export const USER_APP_TOP_KEY = 'USER_APP_TOP_KEY'
export const APPLICATION_ID_KEY = 'APPLICATION_ID'

export const USER_GUIDE_KEY = 'USER_GUIDE'

export const APPLICATION_KEEP_ROUTER = 'APPLICATION_KEEP_ROUTER'
export const AGENT_KEEP_ROUTER = 'AGENT_KEEP_ROUTER'
export const DATASTORES_KEEP_ROUTER = 'DATASTORES_KEEP_ROUTER'

export const AGENT_GROUP = 'AGENT_GROUP'
export const FLOW_GROUP = 'FLOW_GROUP'
export const DATASTORE_GROUP = 'DATASTORE_GROUP'

export const ROBOT_KEY = 'ROBOT_KEY'
export const SIDEBAR_COLLAPSE = 'SIDEBAR_COLLAPSE'
export const SHOW_START_TIPS = 'SHOW_START_TIPS'

export const EXPLORE_LEAVE_TAB = 'EXPLORE_LEAVE_TAB'
export const EXPLORE_LIST_SCROLL_TOP = 'EXPLORE_LIST_SCROLL_TOP'
export const AGENT_LIST_SEARCH = 'AGENT_LIST_SEARCH'
export const AGENT_LIST_SCROLL_TOP = 'AGENT_LIST_SCROLL_TOP'
export const FLOW_LIST_SEARCH = 'FLOW_LIST_SEARCH'
export const FLOW_LIST_SCROLL_TOP = 'FLOW_LIST_SCROLL_TOP'
export const DB_LIST_SEARCH = 'DB_LIST_SEARCH'
export const DB_LIST_SCROLL_TOP = 'DB_LIST_SCROLL_TOP'
export const KG_LIST_SEARCH = 'KG_LIST_SEARCH'
export const KG_LIST_SCROLL_TOP = 'KG_LIST_SCROLL_TOP'

export const FLOW_PATCH_TEST_STORAGE = 'FLOW_PATCH_TEST_STORAGE'
