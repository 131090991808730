import { request } from '../../client'
import type {
  CalculatePriceInfo,
  CalculatePriceParams,
  CreateOrderData,
  DoPayParams,
  OrderDetail,
  OrderPayCertParams,
  orderType,
} from './type'

// 查询workspace下的所有订单
export function getorderList(workspace_id: string) {
  return request.get<orderType[]>(`/v1/order/list?workspace_id=${workspace_id}`)
}

export function getOrderCalculatedPrice(data: CalculatePriceParams) {
  return request.post<CalculatePriceInfo>('/v1/order/charging', data)
}

export function createOrder(data: CreateOrderData) {
  return request.post<string>('/v1/order', data, { ignoreError: true })
}

export function getOrderDetailById(orderId: string) {
  return request.get<OrderDetail>(`/v1/order/detail?order_no=${orderId}`)
}

export function doToBPay(data: DoPayParams) {
  return request.post<string>('/v1/pay/send_tob', data)
}

export function cancelPay(orderId: string) {
  return request.put<string>(`/v1/order/cancel?order_no=${orderId}`)
}

export function uploadPayCert(data: OrderPayCertParams) {
  return request.post<string>('/v1/order/trade_cert', data)
}
