import type { FormInstance } from 'antd'
import { Form, message, Popover, Radio, Space, Switch, Tooltip } from 'antd'
import type { ReactNode } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useRequest } from 'ahooks'
import classNames from 'classnames'
import { get } from 'lodash-es'
import { testAlarmDingTalkConnection } from '@apis/alarm'
import { AlarmLevels, AlarmTypes } from '@apis/alarm/type'
import type { AlarmRuleConfigType, ThresholdConfigItem } from '@apis/alarm/type'
import { getApplicationsList } from '@apis/application'
import {
  Button,
  FormItem,
  IconFont,
  Input,
  InputNumber,
  Select,
  TextArea,
} from '@/components'
import { FLOW_DISPLAYNAME } from '@/constants/common'
import { useUserStore, useWorkspaceStore } from '@/store'
import dingexampleImg from '@/assets/alarm/dingexample@2x.png'
import {
  AlarmTypeNames,
  AlarmTypeTargets,
  configTypeMap,
  ConsumeAlarmTimeOptions,
  DisabledAlarmTypes,
  ErrorAlarmTimeOptions,
  MAX_CONFIG_LENTH,
  MAX_TAGS_COUNT,
} from './constants'
import { AlarmLevelsSelect } from './LevelsSelect'
import type { AlarmRuleFormValues } from './type'
import { useDefaultPointsConfig } from './hooks/useDefaultPointsConfig'

const ruleTypeOptions = Object.entries(configTypeMap).map(([k, v]) => ({
  label: v,
  value: k,
  style: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    height: 36,
    padding: '10px 8px',
    background: 'rgba(98, 105, 153, .06)',
    borderRadius: '8px',
  },
}))

function ChannelCard(props: { children: ReactNode }) {
  return (
    <div className='mb-8px p-16px b-rd-8px b-1 b-#e1e1e5 b-op-80 bg-#fff'>
      <Space direction='vertical' className='w-full'>
        {props.children}
      </Space>
    </div>
  )
}

interface AlarmRuleFormProps {
  isEdit: boolean
  form: FormInstance<AlarmRuleFormValues>
  userOptions: {
    label: string
    value: string | number
  }[]
}

export function AlarmRuleForm(props: AlarmRuleFormProps) {
  const { isEdit, form, userOptions } = props

  const prevValues = useRef<AlarmRuleFormValues>(form.getFieldsValue())

  const [ruleType, setRuleType] = useState<AlarmRuleConfigType>('Workspace')

  const { defaultLeftPoints } = useDefaultPointsConfig()

  const alarmTypeOptions = useMemo(() => {
    if (!ruleType) {
      return Object.entries(AlarmTypeNames).map(([k, v]) => ({
        label: v,
        value: k as AlarmTypes,
        disabled: DisabledAlarmTypes.includes(k as AlarmTypes),
      }))
    }
    return Object.entries(AlarmTypeNames)
      .filter(([k]) => {
        const types = AlarmTypeTargets[k as AlarmTypes]
        return types.includes(ruleType)
      })
      .map(([k, v]) => ({
        label: DisabledAlarmTypes.includes(k as AlarmTypes)
          ? `${v}(即将上线)`
          : v,
        value: k as AlarmTypes,
        disabled: DisabledAlarmTypes.includes(k as AlarmTypes),
      }))
  }, [ruleType])

  const hasAppsField = useMemo(
    () => ruleType === 'AI' || ruleType === 'Agent',
    [ruleType],
  )

  const { user } = useUserStore()
  const { currentWorkspaceId } = useWorkspaceStore()

  const { data: appsData } = useRequest(
    () =>
      getApplicationsList(currentWorkspaceId, {
        applicationType: ruleType === 'Agent' ? 'AGENT' : 'AI',
      }),
    {
      ready: hasAppsField,
      refreshDeps: [ruleType],
    },
  )

  const appOptions = useMemo(() => {
    if (!appsData) return []
    return appsData.map(item => ({
      label: item.name,
      value: item.flowId,
    }))
  }, [appsData])

  const { runAsync: testDingTalkWebhook } = useRequest(
    testAlarmDingTalkConnection,
    { manual: true },
  )

  const onValuesChange = (
    values: Partial<AlarmRuleFormValues>,
    allValues: AlarmRuleFormValues,
  ) => {
    if (values.alarm_rule_type) {
      const { alarm_rule_type } = values
      setRuleType(values.alarm_rule_type)
      form.setFieldValue('app_ids', [])
      if (alarm_rule_type === 'Agent') {
        form.setFieldValue('alarm_type', AlarmTypes.CHAT_ERROR)
        form.setFieldValue('threshold_config', [
          {
            alarm_time: 5,
            threshold: 3,
            alarm_level: AlarmLevels.HIGH,
          },
        ])
      } else if (alarm_rule_type === 'AI') {
        form.setFieldValue('alarm_type', AlarmTypes.EXECUTE_ERROR)
        form.setFieldValue('threshold_config', [
          {
            alarm_time: 5,
            threshold: 3,
            alarm_level: AlarmLevels.HIGH,
          },
        ])
      } else if (alarm_rule_type === 'Workspace') {
        form.setFieldValue('alarm_type', AlarmTypes.WORKSPACE_POINT_MARGIN)
        form.setFieldValue('threshold_config', [
          {
            threshold: defaultLeftPoints,
            alarm_level: AlarmLevels.HIGH,
            trigger_type: 'NUMBER',
          },
        ])
      }
    }
    if (values.alarm_type) {
      const { alarm_type } = values
      form.setFieldValue('threshold_config', [
        {
          alarm_level: AlarmLevels.HIGH,
          trigger_type:
            alarm_type === AlarmTypes.WORKSPACE_POINT_MARGIN
              ? 'NUMBER'
              : undefined,
        },
      ])
    }
    if (values.threshold_config) {
      // 对比新旧触发条件，切换单位时清空数值
      const { threshold_config } = values
      const prevConfig = prevValues.current?.threshold_config
      threshold_config.forEach((item, index) => {
        if (item?.trigger_type && prevConfig?.[index]) {
          const changed = item.trigger_type !== prevConfig[index]?.trigger_type
          if (changed) {
            form.setFieldValue(
              ['threshold_config', index, 'threshold'],
              undefined,
            )
          }
        }
      })
    }
    const isSmsChannelEnable = !!get(values, [
      'alarm_channel',
      'sms_notify_channel',
      'enabled',
    ])
    // 打开短信通知时，默认添加创建者
    if (!isEdit && isSmsChannelEnable) {
      if (user) {
        const ids: number[] =
          form.getFieldValue([
            'alarm_channel',
            'sms_notify_channel',
            'notify_user',
          ]) ?? []
        if (!ids.includes(user.userId)) {
          ids.unshift(user.userId)
        }
        form.setFieldValue(
          ['alarm_channel', 'sms_notify_channel', 'notify_user'],
          ids,
        )
      }
    }
    prevValues.current = allValues
  }

  useEffect(() => {
    const type = form.getFieldValue('alarm_rule_type')
    setRuleType(type)
  }, [])

  return (
    <Form
      form={form}
      layout='vertical'
      requiredMark={false}
      // autoComplete="on"
      onValuesChange={onValuesChange}
    >
      <FormItem
        name='alarm_rule_name'
        label='规则名称'
        required
        rules={[
          {
            required: true,
            message: '规则名称不能为空',
          },
        ]}
      >
        <Input placeholder='请输入规则名称' />
      </FormItem>
      <FormItem name='alarm_rule_type' label='应用场景' required>
        <Radio.Group
          className='flex'
          options={ruleTypeOptions}
          disabled={isEdit}
        />
      </FormItem>
      <FormItem
        name='alarm_type'
        label='告警类型'
        required
        rules={[
          {
            required: true,
            message: '告警类型不能为空',
          },
        ]}
      >
        <Select
          disabled={isEdit}
          placeholder='请选择告警类型'
          options={alarmTypeOptions}
        />
      </FormItem>
      <FormItem
        label='触发条件'
        required
        shouldUpdate={(prev, cur) => prev.alarm_type !== cur.alarm_type}
      >
        {form => (
          <Form.List
            name='threshold_config'
            rules={[
              {
                validator(_, value: ThresholdConfigItem[]) {
                  if (!value.length) {
                    return Promise.reject('至少有一个触发条件')
                  }
                  const isInvalid = value.some(
                    item => !item || typeof item.threshold !== 'number',
                  )
                  if (isInvalid) {
                    return Promise.reject('请配置正确的触发条件')
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map(field => {
                  const alarmType = form.getFieldValue('alarm_type')

                  let fieldItem
                  if (alarmType === AlarmTypes.WORKSPACE_EXPIRE) {
                    fieldItem = (
                      <Space>
                        空间到期前
                        <FormItem
                          name={[field.name, 'threshold']}
                          noStyle
                          // rules={[
                          //   {
                          //     required: true,
                          //     message: '填写正确的告警触发配置'
                          //   }
                          // ]}
                        >
                          <InputNumber
                            className='w-408px!'
                            min={1}
                            precision={0}
                          />
                        </FormItem>
                        天，
                      </Space>
                    )
                  } else if (
                    alarmType === AlarmTypes.APP_POINTS_CONSUME ||
                    alarmType === AlarmTypes.WORKSPACE_POINTS_CONSUME
                  ) {
                    fieldItem = (
                      <Space>
                        <FormItem name={[field.name, 'alarm_time']} noStyle>
                          <Select
                            className='w-210px!'
                            options={ConsumeAlarmTimeOptions}
                          />
                        </FormItem>
                        消耗达到
                        <FormItem name={[field.name, 'threshold']} noStyle>
                          <InputNumber
                            className='w-210px!'
                            min={1}
                            precision={0}
                          />
                        </FormItem>
                        积分时，
                      </Space>
                    )
                  } else if (
                    alarmType === AlarmTypes.CHAT_ERROR ||
                    alarmType === AlarmTypes.EXECUTE_ERROR ||
                    alarmType === AlarmTypes.FIRST_WORD_RESPONSE ||
                    alarmType === AlarmTypes.FLOW_RUN_FIRST_WORD_RESPONSE
                  ) {
                    fieldItem = (
                      <Space>
                        <FormItem name={[field.name, 'alarm_time']} noStyle>
                          <Select
                            className='w-240px!'
                            options={ErrorAlarmTimeOptions}
                          />
                        </FormItem>
                        出现
                        <FormItem name={[field.name, 'threshold']} noStyle>
                          <InputNumber
                            className='w-240px!'
                            min={1}
                            precision={0}
                          />
                        </FormItem>
                        次时，
                      </Space>
                    )
                  } else if (alarmType === AlarmTypes.WORKSPACE_POINT_MARGIN) {
                    fieldItem = (
                      <Space>
                        积分仅剩余
                        <FormItem noStyle shouldUpdate>
                          {form => {
                            const triggerType = form.getFieldValue([
                              'threshold_config',
                              field.name,
                              'trigger_type',
                            ])
                            return (
                              <FormItem
                                name={[field.name, 'threshold']}
                                noStyle
                              >
                                <InputNumber
                                  className='w-400px! [&_.ant-input-number-suffix]:!text-14px [&_.ant-input-number-suffix]:!text-font_1'
                                  controls={false}
                                  min={1}
                                  max={
                                    triggerType === 'PERCENT' ? 50 : undefined
                                  }
                                  precision={0}
                                  placeholder={
                                    triggerType === 'PERCENT'
                                      ? '请输入1～50之间的整数'
                                      : '请输入大于 1 的整数'
                                  }
                                />
                              </FormItem>
                            )
                          }}
                        </FormItem>
                        <FormItem name={[field.name, 'trigger_type']} noStyle>
                          <Select
                            className='w-70px!'
                            options={[
                              {
                                label: '分',
                                value: 'NUMBER',
                              },
                              {
                                label: '%',
                                value: 'PERCENT',
                              },
                            ]}
                          />
                        </FormItem>
                        时，
                      </Space>
                    )
                  }

                  return (
                    <div
                      key={field.name}
                      className='mb-12px flex items-center justify-between'
                    >
                      <div className='flex-1 flex items-center whitespace-nowrap'>
                        {fieldItem}
                        <FormItem name={[field.name, 'alarm_level']} noStyle>
                          <AlarmLevelsSelect className='flex-1' />
                        </FormItem>
                      </div>
                      <div
                        className={classNames(
                          'parent w-36px h-36px flex-center cursor-pointer',
                          {
                            'op-40 cursor-not-allowed': fields.length <= 1,
                          },
                        )}
                        onClick={() => {
                          if (fields.length <= 1) return
                          remove(field.name)
                        }}
                      >
                        <IconFont
                          name='shanshu'
                          className={classNames(
                            'text-#626999 text-op-60 parent-hover:text-#7b67ee',
                            {
                              'text-op-40': fields.length <= 1,
                            },
                          )}
                        />
                      </div>
                    </div>
                  )
                })}
                <Form.ErrorList errors={errors} />
                {fields.length < MAX_CONFIG_LENTH && (
                  <FormItem noStyle>
                    <Button
                      className='font-400!'
                      icon={<IconFont name='tianjiahuihua' />}
                      onClick={() => add({})}
                    >
                      添加条件
                    </Button>
                  </FormItem>
                )}
              </>
            )}
          </Form.List>
        )}
      </FormItem>
      {hasAppsField && (
        <FormItem
          name='app_ids'
          label='生效对象'
          required
          rules={
            ruleType !== 'Workspace'
              ? [
                  {
                    required: true,
                    message: '至少选择一个生效对象',
                  },
                ]
              : []
          }
        >
          <Select
            mode='multiple'
            placeholder={`选择或搜索${ruleType === 'Agent' ? 'Agent' : FLOW_DISPLAYNAME}`}
            options={appOptions}
            // maxTagCount={MAX_TAGS_COUNT}
            virtual={false}
            showSearch
            optionFilterProp='label'
          />
        </FormItem>
      )}
      <FormItem
        name='alarm_channel'
        label='通知渠道'
        required
        rules={[
          {
            validator(_, value) {
              const dingChannelEnable = get(value, [
                'ding_notify_channel',
                'enabled',
              ])
              const smsChannelEnable = get(value, [
                'sms_notify_channel',
                'enabled',
              ])
              const vmsChannelEnable = get(value, [
                'vms_notify_channel',
                'enabled',
              ])
              if (
                !dingChannelEnable &&
                !smsChannelEnable &&
                !vmsChannelEnable
              ) {
                return Promise.reject('至少设置一种告警通知渠道')
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <ChannelCard>
          <div className='flex items-center justify-between'>
            <div className='flex items-center flex-1'>
              <span className='mr-4px'>钉钉群机器人</span>
              <FormItem
                name={['alarm_channel', 'ding_notify_channel', 'enabled']}
                noStyle
              >
                <Switch size='small' />
              </FormItem>
            </div>
            <div className='flex items-center gap-8px'>
              <Popover
                content={
                  <div className='flex-col flex-center'>
                    <img className='w-211px' src={dingexampleImg} alt='' />
                    <div className='mt-16px text-#8d8d99 text-op-60'>
                      通过「钉钉」通知
                    </div>
                  </div>
                }
              >
                <Button className='h-16px! p-0! font-400!' type='link'>
                  示例
                </Button>
              </Popover>
              <Button
                className='h-16px! p-0! font-400!'
                type='link'
                target='_blank'
                href='https://open.dingtalk.com/document/robots/custom-robot-access'
              >
                帮助文档
              </Button>
            </div>
          </div>
          <FormItem
            noStyle
            shouldUpdate={(prev, cur) =>
              prev.alarm_channel?.ding_notify_channel?.enabled !==
              cur.alarm_channel?.ding_notify_channel?.enabled
            }
          >
            {form => {
              const channelEnabled = form.getFieldValue([
                'alarm_channel',
                'ding_notify_channel',
                'enabled',
              ])
              if (!channelEnabled) return null
              return (
                <Space direction='vertical' className='w-full'>
                  <FormItem
                    className='m-0!'
                    name={[
                      'alarm_channel',
                      'ding_notify_channel',
                      'webhook_url',
                    ]}
                    rules={[
                      {
                        required: true,
                        message: '输入webhook地址',
                      },
                    ]}
                  >
                    <Input placeholder='钉钉群机器人 webhook url（必填）' />
                  </FormItem>
                  <FormItem
                    className='m-0!'
                    name={['alarm_channel', 'ding_notify_channel', 'secret']}
                    rules={[
                      {
                        required: true,
                        message: '输入钉钉secret',
                      },
                    ]}
                  >
                    <Input placeholder='钉钉群机器人加签密钥（必填）' />
                  </FormItem>
                  <FormItem
                    name={[
                      'alarm_channel',
                      'ding_notify_channel',
                      'notify_user',
                    ]}
                    className='m-0!'
                  >
                    <TextArea
                      autoSize
                      placeholder='群成员的手机号，钉钉机器人会自动@对应群成员；每行一个手机号，按回车键换行'
                      autoComplete='on'
                    />
                  </FormItem>
                  <Tooltip title='机器人在群聊中发送 1 条问候语消息'>
                    <Button
                      className='p-0! font-400!'
                      type='link'
                      onClick={async () => {
                        const { webhook_url, secret } = form.getFieldValue([
                          'alarm_channel',
                          'ding_notify_channel',
                        ])
                        if (!webhook_url || !secret) {
                          message.warning('请先完成机器人必填配置')
                          return
                        }
                        await testDingTalkWebhook({
                          webhook_url,
                          secret,
                        })
                        message.success('消息已发送')
                      }}
                    >
                      测试机器人
                    </Button>
                  </Tooltip>
                </Space>
              )
            }}
          </FormItem>
        </ChannelCard>
        <ChannelCard>
          <div className='flex items-center'>
            <span className='mr-4px'>短信通知</span>
            <FormItem
              name={['alarm_channel', 'sms_notify_channel', 'enabled']}
              noStyle
            >
              <Switch size='small' />
            </FormItem>
          </div>
          <FormItem
            noStyle
            shouldUpdate={(prev, cur) =>
              prev.alarm_channel?.sms_notify_channel?.enabled !==
              cur.alarm_channel?.sms_notify_channel?.enabled
            }
          >
            {form => {
              const channelEnabled = form.getFieldValue([
                'alarm_channel',
                'sms_notify_channel',
                'enabled',
              ])
              if (!channelEnabled) return null
              return (
                <FormItem
                  name={['alarm_channel', 'sms_notify_channel', 'notify_user']}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: '请选择通知用户',
                    },
                  ]}
                >
                  <Select
                    mode='multiple'
                    placeholder='选择通知用户'
                    options={userOptions}
                    maxTagCount={MAX_TAGS_COUNT}
                    virtual={false} // 虚拟滚动开启列表高度有问题，会吃掉最后一项
                    showSearch
                    optionFilterProp='label'
                  />
                </FormItem>
              )
            }}
          </FormItem>
        </ChannelCard>
        <ChannelCard>
          <div className='flex items-center justify-between'>
            <div className='flex items-center flex-1'>
              <span className='mr-4px'>紧急电话通知</span>
              <FormItem
                noStyle
                shouldUpdate={(prev, cur) =>
                  prev.threshold_config !== cur.threshold_config
                }
              >
                {form => {
                  const thresholdConfig = form.getFieldValue(
                    'threshold_config',
                  ) as ThresholdConfigItem[]
                  const disabled = !thresholdConfig.find(
                    item => item?.alarm_level === AlarmLevels.EXIGENCY,
                  )
                  return (
                    <FormItem
                      name={['alarm_channel', 'vms_notify_channel', 'enabled']}
                      noStyle
                    >
                      <Switch size='small' disabled={disabled} />
                    </FormItem>
                  )
                }}
              </FormItem>
            </div>
            <div className='text-#8D8D99'>
              仅在触发严重度<span className='text-#ff5219'>「紧急」</span>
              告警时生效
            </div>
          </div>
          <FormItem
            noStyle
            shouldUpdate={(prev, cur) =>
              prev.alarm_channel?.vms_notify_channel?.enabled !==
              cur.alarm_channel?.vms_notify_channel?.enabled
            }
          >
            {form => {
              const channelEnabled = form.getFieldValue([
                'alarm_channel',
                'vms_notify_channel',
                'enabled',
              ])
              if (!channelEnabled) return null
              return (
                <FormItem
                  name={['alarm_channel', 'vms_notify_channel', 'notify_user']}
                  noStyle
                  rules={[
                    {
                      required: true,
                      message: '请选择通知用户',
                    },
                  ]}
                >
                  <Select
                    mode='multiple'
                    placeholder='选择通知用户'
                    options={userOptions}
                    maxTagCount={MAX_TAGS_COUNT}
                    virtual={false}
                    showSearch
                    optionFilterProp='label'
                  />
                </FormItem>
              )
            }}
          </FormItem>
        </ChannelCard>
      </FormItem>
    </Form>
  )
}
