import { Dropdown } from 'antd'
import React from 'react'
import { useRequest } from 'ahooks'
import NiceModal from '@ebay/nice-modal-react'
import type { AgentDetailDatabase, AgentDetailResponse } from '@bty/chat-types'
import { bindTableForAgent } from '@apis/agent'
import { createTable, getDBList } from '@apis/database'
import type { CreateTableRequest, Database } from '@apis/database/type'
import { Button, IconFont, Input, SuperLink, usePageModal } from '@/components'
import { useWorkspaceStore } from '@/store'
import theme from '@/constants/theme'
import TableImportModal from '@/features/database/components/TableImportModal'
import TableEditModal from '@/features/database/components/TableEditModal'
import { useAgentEdit } from '../provider/AgentEditProvider'
import { DatabaseSelectModal } from './DatabaseSelectModal'

interface BindingDatabaseProps {
  agent: AgentDetailResponse
  database: AgentDetailDatabase
  afterBind: VoidFunction
  onSearch: (searchKey: string) => void
  onAction: (action: 'switch' | 'unbind', database?: Database) => void
}
function InternalBindingDatabase({
  agent,
  database,
  afterBind,
  onSearch,
  onAction,
}: BindingDatabaseProps) {
  const { updateStatistics } = useAgentEdit()

  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)

  const { data: allDatabases } = useRequest(getDBList, {
    refreshDeps: [workspaceId],
  })

  const modal = usePageModal()

  const items = [
    {
      key: 'switch',
      label: (
        <div
          className='border-0px w-88px px-12px py-10px c-#17171D'
          onClick={() => {
            NiceModal.show(DatabaseSelectModal, {
              data: allDatabases?.list,
              onOk: database => {
                onAction('switch', database)
              },
            })
          }}
        >
          切换
        </div>
      ),
    },
    {
      key: 'unbind',
      label: (
        <div
          style={{ color: theme.colors.error }}
          className='border-0 w-88px px-12 py-10'
          onClick={() => {
            onAction('unbind')
          }}
        >
          解绑
        </div>
      ),
    },
  ]

  return (
    <div className='flex items-center shrink-0'>
      {database.icon?.startsWith('http') ? (
        <img
          className='w-36px h-36px object-contain rounded-8px shrink-0'
          src={database.icon}
        />
      ) : (
        <span
          className='w-36px h-36px rounded-8px text-center text-16px/36px shrink-0'
          style={{
            backgroundColor: database.icon_color,
          }}
        >
          {database.icon}
        </span>
      )}
      <span
        className='text-16px/20px font-medium text-font ml-12 mr-8 cursor-pointer truncate'
        onClick={() => {
          modal.show({
            url: `/db/${workspaceId}/${database.database_id}`,
          })
        }}
      >
        {database.database_name}
      </span>
      <SuperLink
        className='shrink-0 mr-auto'
        onClick={() => {
          modal.show({
            url: `/db/${workspaceId}/${database.database_id}`,
          })
        }}
      />
      <Input
        className='rounded-8px ml-24 w-140px shrink-0'
        placeholder='搜索'
        prefix={<IconFont className='text-16px' name='search' />}
        onChange={event => {
          const searchKey = event.target.value
          onSearch(searchKey)
        }}
      />
      <span className='mx-12 px-4 h-16px shrink-0 border-right after:right-4 after:border-line' />
      <Button
        className='flex justify-center items-center w-82px !p-0 shrink-0'
        type='primary'
        onClick={() => {
          NiceModal.show(TableEditModal, {
            database: {
              id: database.database_id,
              sources: [{ id: database.source_id }],
            } as Database,
            onOk: async (database_id, source_id, db_params) => {
              const table = await createTable(
                database_id,
                source_id,
                db_params as CreateTableRequest,
              )
              await bindTableForAgent({
                flow_id: agent.application!.flowId,
                version_id: agent.application!.draftVersionId!,
                database_id,
                table_id: table.id,
                source_id,
              })
              modal.show({
                url: `/db/${workspaceId}/${database_id}/${table.id}?hide_tables`,
              })
              afterBind()
              updateStatistics()
            },
          })
        }}
      >
        <IconFont className='text-16px mr-4' name='add' />
        创建表
      </Button>
      <Button
        className='mx-12 flex justify-center items-center w-82px !p-0 shrink-0'
        onClick={() => {
          NiceModal.show(TableImportModal, {
            useInAgent: {
              flow_id: agent.appId,
              version_id: agent.application!.draftVersionId!,
            },
            database: {
              id: database.database_id,
              sources: [{ id: database.source_id }],
            } as Database,
            onOk: async tableId => {
              modal.show({
                url: `/db/${workspaceId}/${database.database_id}/${tableId}`,
              })
              afterBind()
              updateStatistics()
            },
            onError: () => {
              afterBind()
              updateStatistics()
            },
          })
        }}
      >
        <IconFont className='text-16px mr-4' name='import' />
        导入表
      </Button>
      <Dropdown menu={{ items }} placement='bottomLeft'>
        <Button className='w-36px !px-10 box-border rounded-8px'>
          <span className='vertical-top'>...</span>
        </Button>
      </Dropdown>
    </div>
  )
}

export const BindingDatabase = React.memo(InternalBindingDatabase)
