import { request } from '../../client'
import type { LogItem } from '../run/type'
import type {
  BatchTestProgressType,
  BatchTestResult,
  BatchTestRunResult,
  IRunResult,
  IShead,
  ISRow,
} from './type'

export const BATCH_TEST_API_PREFIX = '/v1/batch_test'

export function getBatchTestTemplate(flowId: string) {
  return request.get<string>(
    `${BATCH_TEST_API_PREFIX}/generate_excel/${flowId}`,
  )
}

export function importBatchTestData(flowId: string, fileUrl: string) {
  return request.post(`${BATCH_TEST_API_PREFIX}/import_data`, {
    flow_id: flowId,
    file_url: fileUrl,
  })
}

export function getBatchTestIds(flowId: string, testDataIds: string[]) {
  return request.post(`${BATCH_TEST_API_PREFIX}/execute/datas/run`, {
    flow_id: flowId,
    test_data_ids: testDataIds,
  })
}

export function getBatchTestData(
  flowId: string,
  pageNo: number,
  pageSize: number,
) {
  return request.get<{
    table_header: IShead[]
    table_data: ISRow[]
    total: number
  }>(`${BATCH_TEST_API_PREFIX}/show_table/${flowId}`, {
    query: {
      page_no: pageNo,
      page_size: pageSize,
    },
  })
}

export async function updateBatchTestData(
  flowId: string,
  data: Record<string, any>,
  id?: string,
) {
  const res = await request.post(`${BATCH_TEST_API_PREFIX}/data/add`, {
    flow_id: flowId,
    test_data: data,
    test_data_id: id,
  })

  return {
    test_data_id: res.test_data_id,
    ...res.test_data,
  } as ISRow
}

export function deleteBatchTestData(ids: string[]) {
  return request.delete(`${BATCH_TEST_API_PREFIX}/data/del`, {
    body: {
      test_data_ids: ids,
    },
  })
}

export function exportBatchTestData(flowId: string) {
  return request.post<string>(`${BATCH_TEST_API_PREFIX}/export/${flowId}`)
}

export function resetBatchTestData(flowId: string) {
  return request.post<Record<string, any>>(
    `${BATCH_TEST_API_PREFIX}/reset/${flowId}`,
  )
}

export function runBatchTest(flowId: string) {
  return request.post<BatchTestRunResult>(
    `${BATCH_TEST_API_PREFIX}/execute/run`,
    { flow_id: flowId },
  )
}

export function stopBatchTest(flowId: string) {
  return request.post<Record<string, any>>(
    `${BATCH_TEST_API_PREFIX}/execute/stop`,
    { flow_id: flowId },
  )
}

export function getBatchTestProgress(flowId: string) {
  return request.get<BatchTestProgressType>(
    `${BATCH_TEST_API_PREFIX}/progress_display/${flowId}`,
  )
}

export function labelingTestResult(
  testResultId: string,
  label: 'PASSED' | 'NOT_PASSED' | null,
) {
  return request.post<Record<string, any>>(
    `${BATCH_TEST_API_PREFIX}/labeling`,
    { test_result_id: testResultId, result_label: label },
  )
}

export function remarksTestResult(testResultId: string, remarks: string) {
  return request.post<Record<string, any>>(
    `${BATCH_TEST_API_PREFIX}/result/remarks`,
    { test_result_id: testResultId, remarks },
  )
}

export async function getCorrectResult(testResultId: string) {
  const res = await request.get<BatchTestResult>(
    `${BATCH_TEST_API_PREFIX}/data/${testResultId}`,
  )

  return res
}

export function getBatchTestExecuteResult(testResultId: string) {
  return request.get<LogItem>(
    `${BATCH_TEST_API_PREFIX}/execute/result_detail/${testResultId}`,
  )
}

export function getBatchTestRunResult(
  testResultId: string,
  pageNo: number,
  pageSize: number,
) {
  return request.get<IRunResult[]>(
    `${BATCH_TEST_API_PREFIX}/execute/show_run_result/${testResultId}`,
    {
      query: {
        page_no: pageNo,
        page_size: pageSize,
      },
    },
  )
}

export async function getPageSize() {
  const res = await request.get('/v1/system-config/key/batch_test_data_limit')
  return Number(res.value) || 50
}

export async function getBatchTestCount(flowId: string) {
  return await request.get<{ total: number }>(
    `${BATCH_TEST_API_PREFIX}/data_count/${flowId}`,
  )
}
