import { request } from '../../client'
import type { CreateAgentCopilotConversationRes } from './type'

export function createAgentCopilotConversation(appId: string, agentId: string) {
  return request.put<CreateAgentCopilotConversationRes>(
    `/v1/copilot/insert_conversation_info?agent_id=${agentId}`,
    {},
    {
      headers: {
        'Application-Id': appId,
      },
    },
  )
}
