import { Form } from 'antd'
import type { ChangeEventHandler } from 'react'
import styled from '@emotion/styled'
import { useBoolean, useRequest } from 'ahooks'
import type { LoginOrRegisterParams } from '@apis/user/type'
import { loginOrRegister } from '@apis/user'
import { mobileRules } from '../constant'
import { IconFont, Input, InputPassword } from '@/components'
import { PageLink } from '@/components/pageModal/PageLink'
import { PRIVACY_URL, SERVICE_URL } from '@/constants/url'
import { tokenStorage, useIdStorage } from '@/utils/storage'
import { PrivateDeployGuard } from '@/features/guard/PrivateDeployGuard.tsx'
import { isPrivateVersion } from '@/constants/common.ts'
import { LoginButton, StyledCheckbox } from './LoginButton'

export const StyledInputPassword = styled(InputPassword)`
  .ant-input-password-icon {
    font-size: 16px;
    color: rgba(98, 105, 153, 0.48);
  }
`

interface PasswordLoginFormProps {
  onSuccess: () => void
  onForgetPwd: () => void
}

export function PasswordLoginForm(props: PasswordLoginFormProps) {
  const { onSuccess, onForgetPwd } = props
  const [checked, { toggle: toggleChecked }] = useBoolean(isPrivateVersion)
  const [form] = Form.useForm<LoginOrRegisterParams>()

  const { runAsync: loginOrRegisterApi, loading } = useRequest(
    loginOrRegister,
    { manual: true },
  )
  const handleChangeUserName: ChangeEventHandler<HTMLInputElement> = e => {
    // 禁止空格
    form.setFieldValue('phone', e.target.value.replace(/\s/g, ''))
  }

  const handleLogin = async () => {
    const values = await form.validateFields()
    const { phone, password } = values
    if (password) {
      const res = await loginOrRegisterApi({ phone, password })
      console.log('登录成功', res)
      if (res.accessToken) {
        tokenStorage.set(res.accessToken)
        useIdStorage.set(String(res.userId))
        onSuccess()
      }
    }
  }
  return (
    <>
      <Form
        form={form}
        layout='vertical'
        className='relative'
        requiredMark={false}
      >
        <Form.Item name='phone' label='手机号' required rules={mobileRules}>
          <Input
            size='large'
            type='text'
            placeholder='输入手机号'
            maxLength={11}
            onChange={handleChangeUserName}
            autoFocus
          />
        </Form.Item>
        <Form.Item
          name='password'
          label='密码'
          required
          rules={[{ required: true, message: '请输入密码' }]}
        >
          <StyledInputPassword
            size='large'
            type='password'
            placeholder='输入密码'
            iconRender={visible =>
              visible ? (
                <IconFont
                  className='text-16px text-#626999 text-op-48'
                  name='eye-show'
                />
              ) : (
                <IconFont className='text-16px text-#626999' name='eye-hide' />
              )
            }
          />
        </Form.Item>
        <div
          className='absolute bottom--22px w-full text-right text-#8d8d99 cursor-pointer hover:text-#17171d'
          onClick={onForgetPwd}
        >
          忘记密码
        </div>
      </Form>
      <div className='mb-10 mt-48'>
        <PrivateDeployGuard>
          <StyledCheckbox checked={checked} onChange={toggleChecked}>
            <p className='text-font_1 text-12px'>
              同意{' '}
              <PageLink href={SERVICE_URL} className='text-primary'>
                服务协议
              </PageLink>{' '}
              和{' '}
              <PageLink href={PRIVACY_URL} className='text-primary'>
                用户隐私协议
              </PageLink>
            </p>
          </StyledCheckbox>
        </PrivateDeployGuard>
      </div>
      <LoginButton
        type='primary'
        block
        size='large'
        disabled={!checked}
        loading={loading}
        onClick={handleLogin}
      >
        登录
      </LoginButton>
    </>
  )
}
