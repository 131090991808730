import { Divider, Empty, message, Radio } from 'antd'
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useMemoizedFn, useThrottleFn } from 'ahooks'
import classNames from 'classnames'
import copy from 'copy-to-clipboard'
import { ReactComponent as AICreate } from '@/assets/agent/ai-create.svg'
import { useTrack } from '@/features/track/Track'
import { Button, IconFont, Markdown, TextArea } from '@/components'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import { tokenStorage } from '@/utils/storage'
import {
  State,
  useGeneratePromptSocket,
} from '@/features/agent/hooks/useGeneratePromptSocket'
import { tryGetObject } from '@/features/logger/components/loggerModal/util'
import { getContentFromOptimizationPrompt } from '../../utils/prompt'
import { PromptMermaid } from '../PromptMermaid'

interface OptimizationModalProps {
  prompt: string
  setLoading: (loading: boolean) => void
}

export interface ICacheStore {
  cacheGraph: CacheGraphType | null
  cacheAnalyze: CacheAnalyzeType | null
  prompt: string
  setCacheGraph(data: CacheGraphType): void
  clearCacheData(): void
}
export interface CacheGraphType {
  description: string
  graph: string
  prompt: string
}

export interface CacheAnalyzeType {
  type: 'custom' | 'auto'
  question: string
  content: string
}

class CacheStore {
  private cachePrompt = ''
  public cacheAnalyze: CacheAnalyzeType | null = null
  public cacheGraph: CacheGraphType | null = null

  get prompt() {
    return this.cachePrompt
  }

  set prompt(prompt: string) {
    this.cachePrompt = prompt
  }

  setCacheGraph(data: CacheGraphType) {
    this.cacheGraph = data
  }

  setCacheAnalyze(data: CacheAnalyzeType) {
    this.cacheAnalyze = data
  }

  clearCacheData() {
    this.cachePrompt = ''
    this.cacheGraph = null
    this.cacheAnalyze = null
  }
}

const cacheStore = new CacheStore()

export const OptimizationModal = memo((props: OptimizationModalProps) => {
  const { prompt, setLoading } = props
  const token = tokenStorage.get()

  const { doTrack } = useTrack()

  const [graphData, setGraphData] = useState<string | null>(null)
  const [type, setType] = useState<'custom' | 'auto'>('custom')
  const lastGenerateType = useRef<'custom' | 'auto'>(type)
  const [question, setQuestion] = useState('')
  const [showContent, setShowContent] = useState('')

  const socketUrl = useMemo(() => {
    return `${
      import.meta.env.VITE_AI_WS_BASE_URL
    }/v1/ws/prompt/generate_optimize?Authorization=${token}`
  }, [])

  const { state, content, handleSendMessage, handleStop, resetState } =
    useGeneratePromptSocket(socketUrl)

  const handleGenerate = useMemoizedFn(() => {
    lastGenerateType.current = type
    setLoading(true)
    doTrack('agent_prompt_optimization', {
      type,
    })
    handleSendMessage(
      JSON.stringify({
        action: 'prompt_analytics',
        params: {
          prompt,
          graph: graphData,
          question,
          type,
        },
      }),
    )
  })

  const scrollRef = useRef<OverlayScrollbarsComponentRef>(null)

  const { run: scrollToBottom, cancel } = useThrottleFn(() => {
    setTimeout(() => {
      const viewport = scrollRef.current?.osInstance()?.elements().viewport
      viewport?.scroll({
        top: viewport.scrollHeight + 200,
        behavior: 'smooth',
      })
    })
  })

  const scrollToTop = useMemoizedFn(() => {
    cancel()
    const viewport = scrollRef.current?.osInstance()?.elements().viewport
    viewport?.scroll({
      top: 0,
      behavior: 'smooth',
    })
  })

  useEffect(() => {
    if (prompt.trim()) {
      if (prompt !== cacheStore.prompt) {
        resetState()
        cacheStore.clearCacheData()
        setShowContent('')
        setQuestion('')
        setType('custom')
      } else if (cacheStore.cacheAnalyze) {
        scrollToTop()
        setShowContent(cacheStore.cacheAnalyze.content)
        setQuestion(cacheStore.cacheAnalyze.question || '')
        setType(cacheStore.cacheAnalyze.type)
      }
      cacheStore.prompt = prompt
    }
  }, [prompt])

  useEffect(() => {
    if ([State.end, State.result].includes(state)) {
      setLoading(false)
      scrollToTop()
      const struct = tryGetObject(content)
      const optimizedContent =
        JSON.stringify(struct) === '{}'
          ? content
          : getContentFromOptimizationPrompt(
              struct ?? {},
              lastGenerateType.current,
            ) || ''
      setShowContent(optimizedContent)
      cacheStore.setCacheAnalyze({
        type: lastGenerateType.current,
        question,
        content: optimizedContent,
      })
    } else if (state === State.start) {
      scrollToBottom()
      setShowContent(content ?? '')
    }
  }, [content, state, setLoading, scrollToTop, scrollToBottom])

  const isError = useMemo(() => {
    if (![State.end, State.result].includes(state)) return false
    if (content && !showContent) return true
    return false
  }, [state, showContent, content])

  const handleTypeChange = (e: any) => {
    setType(e.target.value)
    setQuestion('') // 切换时清空 question
  }

  const handleCopy = useMemoizedFn(() => {
    if (showContent) {
      copy(showContent)
      message.success('复制成功')
    }
  })

  const isGenerating = useMemo(() => {
    return !!showContent && [State.start].includes(state)
  }, [state, showContent])

  return (
    <div className='h-720px flex flex-col'>
      <div className='flex h-full min-h-0px'>
        <div className='flex-1 h-full p-24px'>
          <PromptMermaid
            prompt={prompt}
            disabled={!prompt}
            setGraphData={setGraphData}
            cacheStore={cacheStore}
            setOutStatusLoading={setLoading}
          />
          <div className='mt-24px'>
            <div className='text-14px/24px font-500'>选择分析方式</div>
            <Radio.Group
              value={type}
              onChange={handleTypeChange}
              className='mt-8px'
              disabled={!graphData || isGenerating}
            >
              <Radio value='custom'>自定义</Radio>
              <Radio value='auto'>AI自动分析</Radio>
            </Radio.Group>
            {type === 'custom' && (
              <TextArea
                disabled={isGenerating}
                className='mt-11px'
                placeholder='请输入提示词存在的问题，自动分析出优化建议'
                value={question}
                style={{ height: '60px', resize: 'none' }}
                onChange={e => setQuestion(e.target.value)}
              />
            )}
            <div className='mt-24px flex justify-end'>
              {isGenerating ? (
                <Button
                  onClick={handleStop}
                  icon={<IconFont name='tingzhishengcheng' />}
                >
                  停止分析
                </Button>
              ) : (
                <Button
                  type='primary'
                  onClick={handleGenerate}
                  disabled={(type === 'custom' && !question) || !graphData}
                  loading={state === State.pending}
                >
                  {showContent ? '重新分析' : '分析'}
                </Button>
              )}
            </div>
          </div>
        </div>
        <Divider type='vertical' className='h-full' style={{ margin: 0 }} />
        <div className='flex-1 h-full p-24px'>
          {!showContent && !isError && (
            <div className='h-full flex flex-center'>
              <Empty
                className='ant-empty-small [&_.ant-empty-image]:h-auto!'
                image={
                  <div className='h-100px!'>
                    <AICreate />
                  </div>
                }
                description={
                  <div className='text-[rgba(141,141,153,0.6)]'>
                    <p>根据左侧图示和指令生成的分析建议</p>
                    <p className='mt-8px'>将会显示在这里</p>
                  </div>
                }
              />
            </div>
          )}

          {(showContent || isError) && (
            <div className='h-full flex flex-col'>
              <div className='text-14px/24px font-500 mb-8px'>分析建议</div>
              <div
                className={classNames('min-h-0px flex-1 relative rounded-8px', {
                  'outline-error outline-1px outline-solid': isError,
                })}
              >
                <OverlayScrollbarsComponent
                  ref={scrollRef}
                  className='overflow-y-auto h-full bg-bg_3/6 p-12px rounded-8px text-#17171d'
                  element='div'
                  options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
                  defer
                >
                  <Markdown text={showContent} />
                </OverlayScrollbarsComponent>
                {isError && (
                  <span className='text-error absolute top-100% mt-12px'>
                    分析失败，请重新分析
                  </span>
                )}
              </div>

              <div className='flex justify-end mt-24'>
                <Button
                  disabled={
                    ![State.end, State.result].includes(state) || isError
                  }
                  icon={<IconFont name='copy' className='text-16px' />}
                  onClick={handleCopy}
                >
                  复制
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  )
})
