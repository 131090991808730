import type { AccessProps } from '@bty/react-auth'
import { Access, useAuth } from '@bty/react-auth'
import type { RoleCode } from '@apis/authority/type'

export interface RoleAccessProps extends Omit<AccessProps, 'access'> {
  roles: RoleCode[]
}

export function RoleAccess({ roles, children, ...restProps }: RoleAccessProps) {
  const { access } = useAuth()
  return (
    <Access access={!!access?.role(roles)} {...restProps}>
      {children}
    </Access>
  )
}
