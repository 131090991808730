import type { CheckboxProps, TreeDataNode } from 'antd'
import { Tabs } from 'antd'
import styled from '@emotion/styled'
import { useEffect, useMemo, useState } from 'react'
import type { GroupedApplication } from '@apis/application/type'
import type { ApplicationType, UserItem } from '@apis/authority/type'
import { Button, Checkbox, IconFont, Modal } from '@/components'
import { FLOW_DISPLAYNAME } from '@/constants/common'
import { AppSearchTree } from './AppSearchTree'

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav {
    padding-left: 20px;
    .ant-tabs-tab {
      padding: 16px 0;
    }
  }
`

interface AppAuthModalProps {
  user: UserItem | null
  open: boolean
  appList: GroupedApplication[]
  onClose: () => void
  onSave: (appIds: string[], allChecked: boolean) => void
}

export function AppAuthModal(props: AppAuthModalProps) {
  const { appList, open, user, onClose, onSave } = props
  const [allChecked, setAllChecked] = useState(false)
  const [authAgentIds, setAuthAgentIds] = useState<string[]>([])
  const [authFlowIds, setAuthFlowIds] = useState<string[]>([])

  const flowTree = useMemo(() => {
    const list = appList.filter(item => item.applicationType === 'AI')
    return transformGroupListToTreeData(list)
  }, [appList])

  const agentTree = useMemo(() => {
    const list = appList.filter(item => item.applicationType === 'AGENT')
    return transformGroupListToTreeData(list)
  }, [appList])

  const defaultCheckedFlowIds = useMemo(() => {
    if (user?.isAuthAllApp) {
      return appList
        .filter(item => item.applicationType === 'AI')
        .map(item => item.id)
    }
    if (!user || !user.applications) return []
    return user.applications
      .filter(app => app.applicationType === 'AI')
      .map(app => app.id as string)
  }, [user])

  const defaultCheckedAgentIds = useMemo(() => {
    if (user?.isAuthAllApp) {
      return appList
        .filter(item => item.applicationType === 'AGENT')
        .map(item => item.id)
    }
    if (!user || !user.applications) return []
    return user.applications
      .filter(app => app.applicationType === 'AGENT')
      .map(app => app.id as string)
  }, [user])

  const countText = useMemo(
    () => `已选${authAgentIds.length} Agent, ${authFlowIds.length} 工作流`,
    [authAgentIds, authFlowIds],
  )

  const onTreeCheckedChange = (type: ApplicationType, ids: string[]) => {
    const originAppIds = appList.map(item => item.id)
    const appIds = ids.filter(id => originAppIds.includes(id))
    if (type === 'AI') {
      setAuthFlowIds(appIds)
    } else {
      setAuthAgentIds(appIds)
    }
  }

  const onAllCheckChange: CheckboxProps['onChange'] = e => {
    setAllChecked(e.target.checked)
    if (e.target.checked) {
      setAuthAgentIds(
        appList
          .filter(item => item.applicationType === 'AGENT')
          .map(item => item.id),
      )
      setAuthFlowIds(
        appList
          .filter(item => item.applicationType === 'AI')
          .map(item => item.id),
      )
    } else {
      setAuthAgentIds([])
      setAuthFlowIds([])
    }
  }

  useEffect(() => {
    if (user?.isAuthAllApp && user?.applications?.length === 0) {
      setAllChecked(true)
      setAuthAgentIds(
        appList
          .filter(item => item.applicationType === 'AGENT')
          .map(item => item.id),
      )
      setAuthFlowIds(
        appList
          .filter(item => item.applicationType === 'AI')
          .map(item => item.id),
      )
      return
    }
    if (!user?.isAuthAllApp && user?.applications) {
      setAuthAgentIds(
        user.applications
          .filter(item => item.applicationType === 'AGENT')
          .map(item => item.id),
      )
      setAuthFlowIds(
        user.applications
          .filter(item => item.applicationType === 'AI')
          .map(item => item.id),
      )
      setAllChecked(user.applications.length === appList.length)
    }
  }, [user])

  return (
    <Modal
      width={600}
      open={open}
      footer={null}
      closable={false}
      className='auth-app-modal'
      destroyOnClose
      styles={{
        body: {
          padding: 0,
        },
      }}
    >
      <div className='flex'>
        <StyledTabs
          className='flex-1'
          destroyInactiveTabPane
          items={[
            {
              key: 'agent',
              label: <div className='text-16px'>Agent</div>,
              children: (
                <AppSearchTree
                  allChecked={allChecked}
                  treeData={agentTree}
                  defaultCheckedKeys={defaultCheckedAgentIds}
                  searchPlaceholder='搜索agent名称'
                  onChange={ids => onTreeCheckedChange('AGENT', ids)}
                />
              ),
            },
            {
              key: 'flow',
              label: <div className='text-16px'>{FLOW_DISPLAYNAME}</div>,
              children: (
                <AppSearchTree
                  allChecked={allChecked}
                  treeData={flowTree}
                  defaultCheckedKeys={defaultCheckedFlowIds}
                  searchPlaceholder='搜索工作流名称'
                  onChange={ids => onTreeCheckedChange('AI', ids)}
                />
              ),
            },
          ]}
        />
      </div>
      <div
        className='absolute right-20px top-12px flex-center w-24px h-24px b-rd-4px cursor-pointer hover:bg-#626999 hover:bg-op-12'
        onClick={onClose}
      >
        <IconFont name='guanbi' />
      </div>
      <div className='flex py-16px px-24px'>
        <div className='flex-1 flex items-center'>
          <Checkbox
            className='mb-0!'
            checked={allChecked}
            onChange={onAllCheckChange}
          >
            全部应用
          </Checkbox>
          <span className='text-#8d8d99 text-op-60'>{countText}</span>
        </div>
        <Button onClick={onClose}>取消</Button>
        <Button
          className='ml-12px'
          type='primary'
          onClick={() => {
            const ids = [...authAgentIds, ...authFlowIds]
            onSave(ids, ids.length === appList.length)
          }}
        >
          保存
        </Button>
      </div>
    </Modal>
  )
}

function transformGroupListToTreeData(list: GroupedApplication[]) {
  const tree: TreeDataNode[] = []
  const groupSortMap: Record<string, number> = {}
  const hasGroup = list.some(item => !!item.appGroupId)
  for (const item of list) {
    // 无任何分组
    if (!hasGroup) {
      tree.push({
        title: item.name,
        key: item.id,
      })
      continue
    }
    const currentGroup = tree.find(s => s.key === item.appGroupId)
    // 已有当前分组
    if (currentGroup) {
      if (!currentGroup.children) {
        currentGroup.children = []
      }
      currentGroup.children.push({
        title: item.name,
        key: item.id,
      })
      continue
    }
    // 未分组数据
    if (!item.appGroupId) {
      const targetGroup = tree.find(s => s.key === 'noGroup')
      if (!targetGroup) {
        groupSortMap.noGroup = Number.POSITIVE_INFINITY
        tree.push({
          title: '未分组',
          key: 'noGroup',
          children: [
            {
              title: item.name,
              key: item.id,
            },
          ],
        })
      } else {
        if (!targetGroup.children) {
          targetGroup.children = []
        }
        targetGroup.children.push({
          title: item.name,
          key: item.id,
        })
      }
      continue
    }

    groupSortMap[item.appGroupId] = item.groupSort as number
    tree.push({
      title: item.groupName,
      key: item.appGroupId,
      children: [
        {
          title: item.name,
          key: item.id,
        },
      ],
    })
  }

  return tree.sort(
    (a, b) => groupSortMap[a.key as string] - groupSortMap[b.key as string],
  )
}
