import { useCallback, useEffect, useMemo } from 'react'
import { Form } from 'antd'
import { useBoolean, useMemoizedFn } from 'ahooks'
import classNames from 'classnames'
import type { SegmentedValue } from 'antd/es/segmented'
import { uniqBy } from 'lodash-es'
import { LLMChannels } from '@apis/llm/model'
import type { JsonFormConfig } from '../components'
import { JsonForm, NodeFormItem, ShowMore } from '../components'
import {
  NodeType,
  type NodeComponent,
  NodeOperationTypes,
} from '@/features/nodes/base'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import { useNodeBranchRemoveConfirm } from '../hooks/useNodeBranchRemoveConfirm'
import type { LLMNodeData, LLMNodeDataForm } from '../llm'

// import { LLMContextType } from '../llm/const'
import { LLMContextType } from '../llm/const'
import type { Message } from '../hooks/useModelChange'
import { useModelChange } from '../hooks/useModelChange'
import { checkedJsonModel, LLMMessageStructType } from '../utils/llm'
import { LLMContextFormField } from '../llm/LLMNode/components/LLMContextFormField'
import {
  isLLMNodeFormValues,
  transformLLMNodeData2LLMNodeFormData,
} from '../llm/LLMNodePanel/utils'
import {
  BranchTypes,
  IntentForm,
  type IntentItem,
  LLMModelSelect,
  INTENT_MAX_CONTENT_SIZE,
  IntentAlertNode,
  showIntentAlertNode,
} from './IntentForm'
import type { IntentExampleItemProps } from './IntentForm/IntentExampleItem'
import { IntentExampleItem } from './IntentForm/IntentExampleItem'

export interface IntentNodeData {
  name: string
  conditions: IntentItem[]
  relation: {
    conditionResultId: string
    branchId?: string
    [o: string]: any
  }
  [k: string]: any
  inputs: {
    question: string
    background: string
    intent_example?: IntentExampleItemProps['value']
  } & LLMNodeData['inputs']
}

export const IntentNode: NodeComponent<IntentNodeData> = props => {
  const { data, nodeElement, variables = [], isOpenedPanel } = props

  const { conditions, inputs } = data
  const [expanded, { toggle: toggleExpanded }] = useBoolean(false)
  const [form] = Form.useForm<LLMNodeDataForm>()

  const addBranch = useFlowDraftStore(s => s.addBranch)
  const removeBranch = useFlowDraftStore(s => s.removeBranch)
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const contextType = useMemo(() => {
    return inputs?.context_type || LLMContextType.JSON_VARIABLE
  }, [inputs?.context_type])

  const { beforeChange, modelMessageStructType } = useModelChange(
    form,
    inputs?.channel!,
  )
  useEffect(() => {
    if (form) {
      const fieldValue = form.getFieldValue('conditions')
      // 当删除或增减的时候立即更新表单值
      if (fieldValue?.length !== props.data.conditions.length) {
        form.setFieldValue('conditions', props.data.conditions)
      }
    }
  }, [props.data.conditions])

  const chatValidator = (_rule: any, value: any, callback: any) => {
    const hasEmptyContent = value.some((item: Message) => {
      if (
        item.role !== 'system' &&
        modelMessageStructType !== LLMMessageStructType.NO_CONTEXT
      ) {
        return !item.content
      }
      return false
    })
    if (hasEmptyContent) {
      callback('Chat不能有空值')
    } else {
      callback()
    }
  }

  const formConditionsVal = Form.useWatch('conditions', form)
  const modelVal = Form.useWatch(['inputs', 'model'], form)

  const options = useMemo(() => {
    const opt = (formConditionsVal as IntentItem[])
      ?.filter(item => !!item.statement)
      ?.map(item => {
        return {
          label: item.statement,
          value: item.statement,
        }
      })
      .concat([
        {
          label: '其他意图',
          value: '其他意图',
        },
      ])
    return uniqBy(opt, 'value')
  }, [formConditionsVal])

  const { beforeRemove, cancel } = useNodeBranchRemoveConfirm(props.id)

  const onAdd = useCallback(() => {
    const insertIndex = conditions.findIndex(
      item => item.type === BranchTypes.ELSE,
    )
    if (insertIndex > -1) {
      addBranch({
        id: props.id,
        conditionIndex: insertIndex,
        condition: {
          type: BranchTypes.ELSEIF,
          statement: '',
        },
      })
    }
  }, [conditions, props.id])

  const onDelete = useCallback(
    (id: string) => {
      removeBranch({
        id: props.id,
        conditionId: id,
      })
    },
    [props.id],
  )

  const beforeJsonFormChange = useMemoizedFn((values: unknown) => {
    if (!isLLMNodeFormValues(values)) {
      console.error('数据格式错误')
      return values
    }
    const { modelSetting, stream, messages, context_type } = values.inputs
    const inputsData = beforeChange({
      modelSetting,
      stream,
      messages: context_type === LLMContextType.MSG_LIST ? messages : undefined,
    })

    inputsData.pre_defined_system_content = undefined
    inputsData.messages = inputsData.messages?.filter(
      item => item.role !== 'system',
    )
    if (!checkedJsonModel(inputsData.model)) {
      inputsData.plugin = {}
    }
    const newInput = { ...values.inputs, ...inputsData }
    delete (newInput as any).modelSetting

    const newValues = {
      ...values,
      inputs: newInput,
    }

    return newValues
  })

  const onTypeChange = useMemoizedFn((t: SegmentedValue) => {
    const values = form?.getFieldsValue()
    values.inputs.context_type = t as LLMContextType
    const newValues = beforeJsonFormChange(values)
    props?.onSaveChange(newValues as any)
  })

  const showAlertInfo = useMemo(() => {
    return showIntentAlertNode(formConditionsVal?.length)
  }, [formConditionsVal])

  const list = useMemo<JsonFormConfig[]>(() => {
    return [
      {
        required: true,
        label: '分类模型',
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        name: ['inputs'],
        render: () => <LLMModelSelect nodeElement={nodeElement} />,
      },
      {
        label: '用户问题',
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        name: ['inputs', 'question'],
        required: true,
        type: 'TextEditor',
        rules: [{ required: true, message: '用户问题不能为空' }],
        componentProps: {
          rows: 1,
          maxHeight: 20,
          minHeight: 20,
          placeholder: '输入需要识别意图的内容或者问题',
          variables,
          variableTipsContainer: nodeElement,
        },
      },
      {
        label: '意图分类',
        required: true,
        className: '!mb-0',
        render: () => {
          return (
            <div className='relative'>
              <NodeFormItem
                name={'conditions'}
                required
                rules={[
                  {
                    required: true,
                    validator: (_, value: IntentItem[]) => {
                      const hasEmptyStatement = value
                        .filter(v => v.type !== BranchTypes.ELSE)
                        .some(item => item.statement === '')

                      const hasLongStatement = value
                        .filter(v => v.type !== BranchTypes.ELSE)
                        .some(
                          item =>
                            item.statement?.length >= INTENT_MAX_CONTENT_SIZE,
                        )

                      if (hasLongStatement) {
                        return Promise.reject(
                          new Error(
                            `请检查意图分类，不能超过${INTENT_MAX_CONTENT_SIZE}字`,
                          ),
                        )
                      }

                      if (hasEmptyStatement) {
                        return Promise.reject(
                          new Error('请检查意图分类，不能为空'),
                        )
                      }

                      return Promise.resolve()
                    },
                  },
                ]}
              >
                <IntentForm
                  variables={props.variables}
                  variableTipsContainer={props.nodeElement}
                  onAdd={onAdd}
                  onDelete={onDelete}
                  onBeforeRemove={beforeRemove}
                  onCancel={cancel}
                  disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
                />
              </NodeFormItem>
              <NodeFormItem
                hidden={isOpenedPanel}
                className='absolute right-0 top-[-32px]'
                name={['inputs', 'intent_example']}
              >
                <IntentExampleItem options={options} />
              </NodeFormItem>
            </div>
          )
        },
      },
      {
        hidden: !showAlertInfo,
        className: classNames('mt-[-6px]', { 'mb-[-6px]px!': !expanded }),
        render: () => <IntentAlertNode />,
      },
      {
        label: '背景知识',
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        name: ['inputs', 'background'],
        type: 'TextEditor',
        hidden: !expanded,
        tooltip:
          '当分类不准时，输入分类的规则说明和背景知识，帮助AI知道如何更准确地分类',
        componentProps: {
          minHeight: 64,
          maxHeight: 64,
          placeholder:
            '输入分类说明或背景知识，如：打招呼：当用户表示问候、问好或寒暄时使用此意图（例如：“你好”、“2333”）。',
          variables,
          variableTipsContainer: nodeElement,
        },
      },
      {
        label: '上下文',
        hidden: !expanded,
        rules:
          contextType === LLMContextType.JSON_VARIABLE
            ? []
            : [
                {
                  validator: chatValidator,
                },
              ],
        name:
          contextType === LLMContextType.JSON_VARIABLE
            ? ['inputs', 'pre_defined_messages']
            : ['inputs', 'messages'],
        validateTrigger: ['onSubmit'],
        tooltip: '可创建或读取上下文故事线，让AI更准确的理解意图并处理',
        render: () => (
          <LLMContextFormField
            contextType={contextType}
            onTypeChange={onTypeChange}
            modelMessageStructType={modelMessageStructType}
            completions={variables}
            variableTipsContainer={nodeElement}
            model={modelVal}
            readOnly={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
          />
        ),
      },
    ]
  }, [
    props.nodeElement,
    props.variables,
    onAdd,
    onDelete,
    lockStatus,
    showAlertInfo,
    expanded,
    contextType,
    options,
    onTypeChange,
    modelMessageStructType,
    isOpenedPanel,
  ])
  return useMemo(
    () => (
      <>
        <div className='w-420px p-16 pb-0px'>
          <JsonForm
            form={form}
            list={list}
            beforeChange={beforeJsonFormChange}
            initialValues={transformLLMNodeData2LLMNodeFormData(data)}
            disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
            validateTrigger={['onSubmit']}
          />
        </div>
        <ShowMore
          text='更多配置'
          expandedText='收起更多配置'
          expanded={expanded}
          onClick={toggleExpanded}
        />
      </>
    ),
    [form, list, lockStatus, beforeJsonFormChange],
  )
}

IntentNode.meta = {
  type: NodeType.INTENT,
  operationType: NodeOperationTypes.MULTI_BRANCH_NODE,
  actionType: 'INTENT',
  typeName: '意图识别',
  icon: 'yitufenleijiedian',
  description: '可以对用户问题进行意图分类',
  backgroundColor: '#AA50F9',
  customSourceHandle: true,
  initialData: {
    name: 'intent_1',
    relation: {
      conditionResultId: '',
    },
    inputs: {
      model: 'gpt-4o-mini',
      channel: LLMChannels.GPT,
      question: '',
      background: '',
      stream: true,
      temperature: 0.7,
      messages: [],
      plugin: {
        json_mode: false,
      },
      context_type: LLMContextType.JSON_VARIABLE,
    },

    conditions: [
      {
        type: BranchTypes.IF,
        statement: '',
      },
      {
        type: BranchTypes.ELSE,
        statement: '',
      },
    ],
  },
  classNames: ['overflow-visible'],
}
