import { useRequest } from 'ahooks'
import type { AuthAppDingTalk } from '@bty/chat-types'
import { PUBLISH_MODE } from '@bty/chat-types'
import { useMemo, useRef, useState } from 'react'
import { Form, message, Modal } from 'antd'
import { omit } from 'lodash-es'
import {
  createApp,
  queryDingTalkAppConfigs,
  unbindApp,
  updateApp,
} from '@apis/agent-go'
import { useAgentEdit } from '../provider/AgentEditProvider'
import emptyImg from '@/assets/flow/empty.png'
import { Button } from '@/components'
import { AgentGoDingTalkRobotCard } from './AgentGoDingTalkRobotCard'
import { AgentGoDingTalkModal } from './AgentGoDingTalkModal'
import { DingTalkDocsURL } from './common'

const [http, _stream] = PUBLISH_MODE.dingtalk.split('|')

interface DingTalkBindRequest {
  AppKey: string
  AppSecret: string
  auth_app_name: string
  auth_app_source: AuthAppDingTalk['auth_app_source']
  AICardTemplateID?: string
  confirmBeforeComplete?: boolean
}

interface AgentGoDingTalkProps {
  mode?: string
  modeIcon: string
  onBindSuccess?: () => void
  onUnBindSuccess?: () => void
}

export default function AgentGoDingTalk(props: AgentGoDingTalkProps) {
  const { modeIcon, onBindSuccess, onUnBindSuccess } = props
  const [modalOpen, setModalOpen] = useState(false)
  const [currentRobotId, setCurrentRobotId] = useState<string>()
  const needTestRobotIdRef = useRef<string>()
  const { applicationInfo } = useAgentEdit()

  const [form] = Form.useForm<DingTalkBindRequest>()
  const { data: robots, runAsync: fetchRobots } = useRequest(() =>
    queryDingTalkAppConfigs(
      http as AuthAppDingTalk['auth_app_source'],
      applicationInfo?.flowId!,
    ),
  )

  const isEmpty = useMemo(() => !robots || robots.length <= 0, [robots])

  const onAdd = () => {
    setCurrentRobotId(undefined)
    form.resetFields()
    setModalOpen(true)
  }

  const onModalClose = () => {
    setModalOpen(false)
  }

  const handleRobotReset = (data: AuthAppDingTalk) => {
    const { auth_app_id, auth_app_name, auth_app_config } = data
    setCurrentRobotId(auth_app_id)
    form.setFieldsValue({
      auth_app_name,
      auth_app_source: data.auth_app_source,
      ...auth_app_config,
      confirmBeforeComplete: false,
    })
    setModalOpen(true)
  }

  const handleUnbind = async (id: string) => {
    Modal.confirm({
      className:
        '[&>.ant-modal-content]:p-20px [&>.ant-modal-content]:border [&>.ant-modal-content]:border-solid [&>.ant-modal-content]:border-[rgba(141,141,153,0.08)] [&>.ant-modal-content]:shadow-[0px_12px_32px_0px_rgba(153,162,176,0.24)]',
      width: 480,
      centered: true,
      content: (
        <p className='text-font font-medium text-14px/16px mt-4px mb-16px'>
          请确认是否要解除绑定？
        </p>
      ),
      icon: null,
      okText: '解绑',
      okButtonProps: {
        className:
          'inline-flex items-center justify-center p-0 w-60px h-36px text-white font-semibold !bg-error border-0 shadow-[0px_4px_8px_0px_rgba(255,82,25,0.24)] hover:!bg-op-60',
      },
      cancelButtonProps: {
        className:
          'inline-flex items-center justify-center p-0 w-60px h-36px !text-font font-semibold !border-[rgba(225,225,229,0.8)] shadow-[0px_2px_4px_0px_rgba(23,26,29,0.0784)]',
      },
      onOk: async () => {
        await unbindApp(id)
        onUnBindSuccess?.()
        form.resetFields()
        fetchRobots()
      },
    })
  }

  const handleComplete = async () => {
    onBindSuccess?.()
    await fetchRobots()
    setModalOpen(false)
  }

  const handleSubmit = async () => {
    if (currentRobotId) {
      try {
        const { auth_app_name, ...values } = form.getFieldsValue()
        await updateApp({
          auth_app_id: currentRobotId,
          auth_app_name,
          auth_app_config: omit(values, 'confirmBeforeComplete'),
        })
        message.success('配置完成')
        handleComplete()
        needTestRobotIdRef.current = currentRobotId
      } catch (error) {}
    } else {
      try {
        const agentId = applicationInfo?.flowId
        const { auth_app_name, auth_app_source, ...values } =
          form.getFieldsValue()
        if (agentId) {
          await createApp({
            agent_id: agentId,
            auth_app_name,
            auth_app_source,
            auth_app_config: omit(values, 'confirmBeforeComplete'),
          })
          message.success('配置完成')
          handleComplete()
        }
      } catch (error) {}
    }
  }

  return (
    <div className='flex flex-col h-full p-24px'>
      <div className='flex justify-between mb-24px'>
        <div className='flex items-end'>
          <h2 className='text-#17171d text-20px font-500'>钉钉机器人</h2>
          <p className='ml-4px text-#8D8D99 text-12px'>支持绑定多个</p>
        </div>
        {!isEmpty && (
          <div>
            <Button type='link' href={DingTalkDocsURL} target='_blank'>
              绑定教程
            </Button>
            <Button type='primary' onClick={onAdd}>
              新增绑定
            </Button>
          </div>
        )}
      </div>
      <div className='flex-1 bg-#fff b-rd-8px'>
        {isEmpty ? (
          <div className='w-full h-full flex-col flex-center'>
            <img className='w-140px h-140px' src={emptyImg} alt='' />
            <p className='mb-24px text-#626999 text-op-48'>
              还没有绑定钉钉机器人
            </p>
            <Button type='primary' className='mb-12px w-122px' onClick={onAdd}>
              去绑定
            </Button>
            <Button type='link' href={DingTalkDocsURL} target='_blank'>
              绑定教程
            </Button>
          </div>
        ) : (
          <div className='p-24px'>
            <div className='mb-18px text-#17171d text-16px font-500'>
              Agent已绑定的钉钉机器人
            </div>
            <div className='flex flex-wrap gap-24px'>
              {robots?.map(robot => (
                <AgentGoDingTalkRobotCard
                  key={robot.auth_app_id}
                  appId={robot.auth_app_id}
                  dingIcon={modeIcon}
                  agentAvatar={applicationInfo?.icon}
                  name={robot.auth_app_name}
                  confTime={robot.created_at}
                  operator={robot.updated_username}
                  enable={robot.is_enable}
                  connectState={robot.is_enable ? 'success' : 'off'}
                  needConnectTest={
                    needTestRobotIdRef.current === robot.auth_app_id
                  }
                  onReset={() => {
                    handleRobotReset(robot)
                  }}
                  onUnbind={() => {
                    handleUnbind(robot.auth_app_id)
                  }}
                  onRefresh={handleComplete}
                  onTestFinish={() => {
                    needTestRobotIdRef.current = undefined
                  }}
                />
              ))}
            </div>
          </div>
        )}
      </div>
      <AgentGoDingTalkModal
        form={form}
        open={modalOpen}
        onClose={onModalClose}
        onSubmit={handleSubmit}
      />
    </div>
  )
}
