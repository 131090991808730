import type { ChatMessagePCProps, ChatPCInstance } from '@bty/chat-renderer-pc'
import { ChatMessagePC } from '@bty/chat-renderer-pc'
import type { ReactNode } from 'react'
import { forwardRef, useRef } from 'react'
import { message } from 'antd'
import { useMemoizedFn } from 'ahooks'
import { useUserStore, useWorkspaceStore } from '@/store'
import { IssueReport, SceneTypes, usePageModal } from '@/components'
import { API_CODE_CODE } from '@/constants/request'
import { useGenerateMarkdownLimitedInfo } from '@/features/pay/LimitedAlert'
import { moreRef } from '@/utils/react'
import { FormMessageComponentMap } from '@/features/agent/components/FormMessage.tsx'
import { tokenStorage } from '@/utils/storage'
import { generateBotMessage } from './utils/chatMessage'
import { TaskLogModal } from '.'

export interface AgentChatMessageProps {
  robotId: ChatMessagePCProps['robotId']
  conversationId?: ChatMessagePCProps['conversationId']
  robotAvatarInfo?: ChatMessagePCProps['robotAvatarInfo']
  userAvatarInfo?: ChatMessagePCProps['robotAvatarInfo']
  botMessageProps?: ChatMessagePCProps['botMessageProps']
  userMessageProps?: ChatMessagePCProps['userMessageProps']
  runType?: ChatMessagePCProps['runType']
  clearText?: ChatMessagePCProps['clearText']
  paddingX?: ChatMessagePCProps['paddingX']
  showPlaceholder?: ChatMessagePCProps['showPlaceholder']
  placeholder?: ChatMessagePCProps['placeholder']
  getVariables?: ChatMessagePCProps['getVariables']
  onTitleGenerate?: ChatMessagePCProps['onTitleGenerate']
  onChatSend?: ChatMessagePCProps['onChatSend']
  onChatEnd?: ChatMessagePCProps['onChatEnd']
  onChatError?: ChatMessagePCProps['onChatError']
  onEditMessage?: ChatMessagePCProps['onEditMessage']
  onQuestionSend?: ChatMessagePCProps['onQuestionSend']
  onRegenerate?: ChatMessagePCProps['onRegenerate']
  pluginItemOnMessageRender: ChatMessagePCProps['pluginItemOnMessageRender']
}

export const AgentChatMessage = forwardRef(
  (props: AgentChatMessageProps, ref) => {
    const {
      robotId,
      conversationId,
      robotAvatarInfo,
      userAvatarInfo,
      botMessageProps,
      userMessageProps,
      runType,
      clearText,
      paddingX,
      showPlaceholder,
      placeholder,
      getVariables,
      onTitleGenerate,
      onChatSend,
      onChatEnd,
      onChatError,
      onEditMessage,
      onQuestionSend,
      onRegenerate,
      pluginItemOnMessageRender,
    } = props

    const chatRef = useRef<ChatPCInstance>(null)
    const modal = usePageModal()

    const user = useUserStore(state => state.user)
    const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)

    const [limitedMarkdownInfo, handleLimitedModalClick] =
      useGenerateMarkdownLimitedInfo({
        prefix: '抱歉我无法回答你的问题，我所在的',
      })

    const onHrefTagClick = useMemoizedFn((url: string) => {
      // TODO 后续优化代码 暂时使用写死方法
      if (url.includes('/api/v1/dataset/file/download')) {
        window.open(url, '_blank')
      } else if (url.includes('show-bty-limited-action')) {
        handleLimitedModalClick(url)
      } else {
        modal.show({ url })
      }
    })

    const onChatResponse = useMemoizedFn(data => {
      if (!data.success) {
        if (data.code === API_CODE_CODE.COMBO_NOT_ENOUGH) {
          chatRef.current?.chatMessage.replaceLast(
            generateBotMessage({
              content: limitedMarkdownInfo,
              showRegenerateBtn: false,
              hideActions: true,
            }),
          )
        } else {
          message.error(data.message)
        }
      }
    })

    return (
      <ChatMessagePC
        ref={moreRef(ref, chatRef)}
        env={__DEFINE_ENV__}
        workspaceId={workspaceId}
        robotId={robotId as string}
        conversationId={conversationId}
        robotAvatarInfo={robotAvatarInfo}
        userAvatarInfo={userAvatarInfo}
        botMessageProps={botMessageProps}
        userMessageProps={userMessageProps}
        runType={runType}
        clearText={clearText}
        paddingX={paddingX ?? 20}
        user={{
          id: user?.username || '',
          name: user?.username || '',
          avatar: user?.avatar,
        }}
        showPlaceholder={showPlaceholder}
        placeholder={placeholder}
        loggerComponent={TaskLogModal}
        getVariables={getVariables}
        onTitleGenerate={onTitleGenerate}
        onEditMessage={onEditMessage}
        onQuestionSend={onQuestionSend}
        onRegenerate={onRegenerate}
        onHrefTagClick={onHrefTagClick}
        onChatSend={onChatSend}
        onChatResponse={onChatResponse}
        onChatEnd={onChatEnd}
        onChatError={onChatError}
        formMessageComponentMap={FormMessageComponentMap}
        pluginItemOnMessageRender={pluginItemOnMessageRender}
        issueReportRenderer={({
          conversationId,
        }: {
          conversationId: string
        }) => {
          return (issue: string, data?: any, children?: ReactNode) => {
            return (
              <IssueReport
                sceneType={SceneTypes.AGENT_EDIT}
                defaultDesc={issue}
                className='ml-8px p-0! h-auto!'
                extraData={{
                  ...(data || {}),
                  conversationId,
                  agentId: robotId,
                }}
                getPopupContainer={() => document.body}
                reportCallback={() => {
                  chatRef.current?.refreshChatHistory(true)
                }}
              >
                {children}
              </IssueReport>
            )
          }
        }}
        authorization={tokenStorage.get()}
      />
    )
  },
)
