import type { Bot as BotType } from '@bty/global-types/bot'
import type {
  ChatActionType,
  ChatbotRunType,
  ConversationData,
  GenerateTitleParams,
} from '@bty/global-types/conversation'
import type {
  MessageHistoryParams,
  PaginatedMessageHistory,
  UnreadMessageCountMap,
} from '@bty/global-types/message'
import { request } from '../../client'
import type {
  AgentConversationData,
  AgentQuoteData,
  ExportAgentLogsParams,
} from './type'

export const CHAT_API_PREFIX = '/v1/chat'

export function getBotById(robotId: string) {
  return request.get(`${CHAT_API_PREFIX}/robot/${robotId}`)
}

export function getDefaultBot() {
  return request.get(`${CHAT_API_PREFIX}/robot/default`)
}

export function getBotList() {
  return request.get<BotType[]>(
    `${CHAT_API_PREFIX}/robot/list?include_system=false`,
  )
}

export function uploadFile2Bot(uploadData: {
  conversation: string
  file_type: string
}) {
  return request.post(`${CHAT_API_PREFIX}/upload`, uploadData)
}

export function getMessageHistory(params: MessageHistoryParams) {
  const query = new URLSearchParams(params as Record<string, any>)
  return request.get<PaginatedMessageHistory>(
    `${CHAT_API_PREFIX}/history?${query}`,
  )
}

export function refreshSession(
  conversation_id: string,
  action_type?: ChatActionType,
) {
  return request.get(
    `${CHAT_API_PREFIX}/session/refresh?conversation_id=${conversation_id}&action_type=${action_type}`,
  )
}

export function getConversationList(
  robot_id?: string,
  run_type?: ChatbotRunType,
) {
  return request.get<ConversationData[]>(
    `${CHAT_API_PREFIX}/conversation/list?robot_id=${robot_id || ''}&run_type=${
      run_type || ''
    }`,
  )
}

export function createConversation(data: {
  robot_id: string
  title: string
  run_type: ChatbotRunType
}) {
  return request.post<{ conversation_id: string; session_id: string }>(
    `${CHAT_API_PREFIX}/conversation/create`,
    data,
  )
}

export function deleteConversation(conversation_id: string) {
  return request.delete(
    `${CHAT_API_PREFIX}/conversation?conversation_id=${conversation_id}`,
  )
}

export function updateConversation(conversation_id: string, title: string) {
  return request.put(`${CHAT_API_PREFIX}/conversation`, {
    conversation_id,
    title,
  })
}

export function getCurrentSession(conversationId: string) {
  return request.get<string>(
    `${CHAT_API_PREFIX}/session/current?conversation_id=${conversationId}`,
  )
}

export function getAgentBot(robot_id: string) {
  return request.get(
    ` ${CHAT_API_PREFIX}/conversation/agent_testing/${robot_id}`,
  )
}

export function getAgentTestConversation(robotId: string) {
  return request.get<AgentConversationData>(
    `${CHAT_API_PREFIX}/conversation/agent_testing/${robotId}`,
  )
}

export function generateTitleFromConversation(params: GenerateTitleParams) {
  return request.post<{ title: string }>(`${CHAT_API_PREFIX}/get_title`, params)
}

export function generateTitleFromDiffConversation(params: GenerateTitleParams) {
  return request.post<{ title: string }>(
    `${CHAT_API_PREFIX}/get_title_for_multiple_models`,
    params,
  )
}

export function getAgentGlobalUnreadMessageCount() {
  return request.get<UnreadMessageCountMap>(
    `${CHAT_API_PREFIX}/record/count_unread_msgs`,
    {
      noRedirect: true,
      ignoreError: true,
    },
  )
}

export function getAgentGlobalUnreadMessageCountForAgentTest() {
  return request.get<UnreadMessageCountMap>(
    `${CHAT_API_PREFIX}/record/count_agent_testing_unread_msgs`,
    {
      noRedirect: true,
      ignoreError: true,
    },
  )
}

export function exportAgentLogs(body: ExportAgentLogsParams) {
  return request.post<string | null>(
    `${CHAT_API_PREFIX}/conversation/agent_log_export`,
    body,
  )
}

export function getConversationQuote(conversationId: string) {
  return request.get<AgentQuoteData>(
    `/v1/chat/conversation/base_quote/${conversationId}`,
  )
}
