import { useDebounceFn } from 'ahooks'
import { useCallback, useEffect, useState } from 'react'
import type { NodeProps } from 'reactflow'
import { deleteNodePanelVariablesApi } from '@apis/node-panel'
import { transformNodesModifiedVariable } from '@/features/nodes/utils'
import { useFlowDraftStore } from '@/store'
import { type PatchVariableChangeOptions } from '..'

export function useNodeData<T>(props: NodeProps<T>) {
  const { flowId, deleteNodes, updateNode, setNodes } = useFlowDraftStore(
    store => ({
      flowId: store.flowId,
      deleteNodes: store.deleteNodes,
      updateNode: store.updateNode,
      setNodes: store.setNodes,
    }),
  )

  const [data, setData] = useState(props.data)

  useEffect(() => {
    setData(props.data)
  }, [props.data])

  const { run: onUpdateNode } = useDebounceFn(
    (data, patchVariableChangeOptions?: PatchVariableChangeOptions) => {
      // 如果修改了节点名称，需要更新节点的变量
      if (patchVariableChangeOptions) {
        const nodes = useFlowDraftStore.getState().nodes
        const updateNodes = nodes.map(item =>
          item.id === data.id ? { ...item, ...data.node } : item,
        )
        const { newVariableName, oldVariableName } = patchVariableChangeOptions
        setNodes(
          transformNodesModifiedVariable(
            updateNodes,
            newVariableName,
            oldVariableName,
          ),
        )
      }
      updateNode(data)
    },
    { wait: 500 },
  )

  const onSaveChange = useCallback(
    (
      _data: any,
      isMergeUpdate = true,
      patchVariableChangeOptions?: PatchVariableChangeOptions,
    ) => {
      let fullData = _data
      if (isMergeUpdate) {
        fullData = Object.assign({}, props.data, _data)
      }
      const allNodeData = { id: props.id, node: { ...props, data: fullData } }
      // 理论上为了修改节点数据不需要这次渲染
      setData(fullData)
      onUpdateNode(allNodeData, patchVariableChangeOptions)
    },
    [props, onUpdateNode],
  )

  const onDelete = useCallback(async () => {
    deleteNodePanelVariablesApi({ flow_id: flowId, node_id: props.id })
    await deleteNodes([props.id])
  }, [props.id])

  return {
    onSaveChange,
    onDelete,
    data,
  }
}
