import { httpClientFactory } from '@bty/http-api'
import { onRequest } from './on-request'
import { onResponse } from './on-response'
import { onError } from './on-error'

export function injectHttp() {
  const removeRequest = httpClientFactory.addRequestHandle(onRequest)
  const removeResponse = httpClientFactory.addResponseHandle(onResponse)
  const removeError = httpClientFactory.addErrorHandle(onError)

  return () => {
    removeRequest()
    removeResponse()
    removeError()
  }
}
