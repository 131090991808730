import { useState } from 'react'
import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { useRequest } from 'ahooks'
import { Divider, Spin } from 'antd'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import type { MessageItem } from '@bty/chat-types'
import { AgentFeedbackStatusEn } from '@apis/agent/type'
import { getAgentChatFeedBackDetail, fetchHandleFeedback } from '@apis/agent'
import { IconFont, TextArea } from '@/components'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import { LogModal } from './LogModal'
import { FeedbackStatusSelect } from './FeedbackStatusSelect'

const ModalWrapper = styled.div`
  position: absolute;
  top: 8px;
  bottom: 8px;
  right: 8px;
  width: 600px;
  border-radius: 8px;
  background-color: #fff;
  backdrop-filter: blur(16px);
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.2);
  z-index: 6;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const ModalHeader = styled.div`
  height: 56px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px 0 16px;
  border-bottom: 1px solid rgba(225, 225, 229, 0.6);
`

interface FeedbackDetailModalProps {
  onClose: () => void
  feedbackId: string
  refresh: () => void
  avatarInfo: MessageItem['avatarInfo']
}

interface ListItemProps {
  title: string
  titleAction?: React.ReactNode
  content: React.ReactNode
}

const ListItem: React.FC<ListItemProps> = ({ title, titleAction, content }) => (
  <div>
    <div className='flex justify-between text-15px/16px font-500 mb-8px'>
      <span className='c-#8D8D99'>{title}</span>
      {titleAction}
    </div>
    <div className='text-14px/22px mb-32px'>{content}</div>
  </div>
)

export function FeedbackDetailModal({
  avatarInfo,
  onClose,
  feedbackId,
  refresh,
}: FeedbackDetailModalProps) {
  const [feedbackNote, setFeedbackNote] = useState('')
  const [feedbackStatus, setFeedbackStatus] = useState<AgentFeedbackStatusEn>(
    AgentFeedbackStatusEn.Pending,
  )

  const [agentLogDetailVisible, setAgentLogDetailVisible] = useState(false)

  const { data: feedbackDetail, loading } = useRequest(
    getAgentChatFeedBackDetail,
    {
      defaultParams: [feedbackId],
      ready: !!feedbackId,
      refreshDeps: [feedbackId],
      onSuccess: res => {
        setFeedbackNote(res?.handle_notes || '')
        setFeedbackStatus(res?.status || AgentFeedbackStatusEn.Pending)
      },
    },
  )

  const { run: handleFeedback } = useRequest(fetchHandleFeedback, {
    manual: true,
    onSuccess: () => {
      refresh?.()
    },
  })

  const handleFeedbackNote = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const value = e.target.value
    handleFeedback({
      feedback_id: feedbackId,
      handle_notes: value,
      status: feedbackStatus,
    })
  }

  const handleBack = () => {
    setAgentLogDetailVisible(false)
  }

  const handleStatusChange = (newStatus: AgentFeedbackStatusEn) => {
    setFeedbackStatus(newStatus)
    handleFeedback({
      feedback_id: feedbackId,
      handle_notes: feedbackNote,
      status: newStatus,
    })
  }

  return (
    <ModalWrapper>
      <ModalHeader>
        <div className='flex flex-items-center'>
          <span className='m-r-12 text-16px font-600 c-#17171d'>
            {agentLogDetailVisible ? (
              <div className='flex flex-items-center'>
                <div
                  className='w-24px h-24px b-rd-4px flex flex-items-center flex-justify-center cursor-pointer hover:bg-#626999 hover:bg-op-12 mr-8px'
                  onClick={handleBack}
                >
                  <IconFont name='fanhui' className='text-16px' />
                </div>
                上下文详情
              </div>
            ) : (
              '反馈详情'
            )}
          </span>
        </div>
        <div
          className='w-24px h-24px b-rd-4px flex flex-items-center flex-justify-center cursor-pointer hover:bg-#626999 hover:bg-op-12'
          onClick={onClose}
        >
          <IconFont name='guanbi' className='text-16px' />
        </div>
      </ModalHeader>
      <OverlayScrollbarsComponent
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
        element='div'
        className='flex-1 p-24px overflow-y-auto'
      >
        <Spin spinning={loading}>
          {agentLogDetailVisible ? (
            <LogModal
              recordId={feedbackDetail?.record_id}
              position='fixed'
              onClose={() => {
                setAgentLogDetailVisible(false)
              }}
              robotAvatarInfo={avatarInfo}
              mode='onlyContent'
              modalClassName='!top-56px !right-0px !shadow-none'
            />
          ) : (
            <>
              <ListItem
                title='反馈意见内容'
                content={feedbackDetail?.user_opinion || '-'}
              />
              <ListItem
                title='反馈人'
                content={feedbackDetail?.creator_name || '-'}
              />
              <ListItem
                title='反馈时间'
                content={
                  feedbackDetail?.created_at
                    ? dayjs(feedbackDetail.created_at).format(
                        'YYYY-MM-DD HH:mm:ss',
                      )
                    : '-'
                }
              />
              <ListItem
                title='状态'
                content={
                  <FeedbackStatusSelect
                    value={feedbackStatus}
                    onChange={handleStatusChange}
                  />
                }
              />
              <Divider />
              <ListItem
                title='问题'
                titleAction={
                  <a
                    className='c-#7B61FF cursor-pointer text-14px/16px'
                    onClick={() => {
                      setAgentLogDetailVisible(true)
                    }}
                  >
                    查看上下文
                  </a>
                }
                content={
                  <div className='whitespace-pre-wrap text-14px leading-22px break-words'>
                    {feedbackDetail?.content || '-'}
                  </div>
                }
              />
              <ListItem
                title='回答'
                content={
                  <div className='whitespace-pre-wrap text-14px leading-22px'>
                    {feedbackDetail?.response || '-'}
                  </div>
                }
              />
              <Divider />
              <ListItem
                title='处理备注'
                content={
                  <TextArea
                    placeholder='输入备注内容'
                    rows={3}
                    onBlur={handleFeedbackNote}
                    value={feedbackNote || ''}
                    onChange={e => setFeedbackNote(e.target.value)}
                  />
                }
              />
            </>
          )}
        </Spin>
      </OverlayScrollbarsComponent>
    </ModalWrapper>
  )
}
