import { invert } from 'lodash-es'
import { DocumentType } from '@apis/datastore/model'
import { FileIconMap, FileTypeIconMap, fileTypeMimeMap } from '../constant'

/**
 *
 * @param fileSize 文件大小
 * @param limit 限制大小 MB
 */
export function checkFileSize(fileSize: number, limit: number) {
  return fileSize / 1024 / 1024 < limit
}

/**
 * @desc 获取文件后缀
 */
export function getFileSuffix(fileName: string) {
  const index = fileName.lastIndexOf('.')
  return fileName.substring(index + 1)
}

/**
 *
 * @description 格式化文件大小，B，KB，MB，GB，TB
 * @param fileSize 文件大小
 */
export function formatFileSize(fileSize: number) {
  let temp = fileSize
  const units = ['B', 'KB', 'MB', 'GB', 'TB']
  let index = 0
  while (temp > 1024) {
    temp /= 1024
    index += 1
  }
  return `${temp.toFixed(2)}${units[index]}`
}

/**
 *
 * @description 格式显示数量 1000 => 1k, 10000 => 10k, 100000 => 100k, 1000000 => 1m， 保留两位小数
 * @param size 大小
 */
export function formatSize(size: number) {
  let temp = size
  const units = ['', 'k', 'm', 'b']
  let index = 0
  while (temp > 1000) {
    temp /= 1000
    index += 1
  }
  return `${temp.toFixed(2)}${units[index]}`
}

/**
 *
 * @description 根据mimetype获取文件类型
 * @param mimeType
 * @returns docx | pdf | txt | md | xlsx
 */
export function getFileSuffixByMime(mimeType: string): string | undefined {
  const map = invert(fileTypeMimeMap)
  return map[mimeType]
}

export function getFileIconByMimetype(mimetype: string) {
  const type = getFileSuffixByMime(mimetype)
  return FileIconMap[type || 'text'] ?? FileIconMap.text
}

// 扩展兼容FileType的icon逻辑
export function getFileIconByFileTypeOrMimeType(
  mimetype: string,
  fileType?: DocumentType,
) {
  return fileType === DocumentType.QA
    ? FileTypeIconMap.QA
    : getFileIconByMimetype(mimetype)
}

export function getFileDisplayName(name: string, fileType?: DocumentType) {
  const newName =
    fileType === DocumentType.QA ? name?.replace(/\.[^/.]+$/, '') : name
  return newName
}

export function escapeUnrecognizedUrls(inputString: string) {
  // 使用通用的正则表达式进行替换
  return inputString
    .replace(/[\\]/g, '\\\\')
    .replace(/[\n]/g, '\\n')
    .replace(/[\b]/g, '\\b')
    .replace(/[\f]/g, '\\f')
    .replace(/[\r]/g, '\\r')
    .replace(/[\t]/g, '\\t')
    .replace(/[\s]/g, '\\s')
}

export function unescapeUnrecognizedUrls(inputString: string) {
  // 使用正则表达式进行替换
  return inputString
    .replace(/\\n/g, '\n')
    .replace(/\\b/g, '\b')
    .replace(/\\f/g, '\f')
    .replace(/\\r/g, '\r')
    .replace(/\\t/g, '\t')
    .replace(/\\s/g, ' ')
}

export function insertAtCursor(
  myField: HTMLInputElement | HTMLTextAreaElement | undefined,
  myValue?: string,
  callback?: (value: string) => void,
): void {
  if (!myField || !myValue) return
  const startPos = myField.selectionStart ?? 0
  const endPos = myField.selectionEnd ?? 0
  myField.value =
    myField.value.substring(0, startPos) +
    myValue +
    myField.value.substring(endPos)
  const newPos = startPos + myValue.length
  myField.selectionStart = newPos
  myField.selectionEnd = newPos
  myField.focus()
  callback?.(myField.value)
}
