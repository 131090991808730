import { request } from '../../client'
import type { IFloatActionConfigRes } from './type'

const version = 'v1'

// 创建新增接口不在项目内使用 具体见 https://dev-ai-api.battleyeah.com/redoc#tag/%E7%B3%BB%E7%BB%9F%E9%85%8D%E7%BD%AE
/**
 * @description 获取动态配置信息
 */
export function getApolloConfig(key: string) {
  return request.get(`${version}/system-config/key/${key}`)
}

export async function fetchShowPromptReferenceLinkPromptConfig() {
  try {
    const data = await getApolloConfig('prompt_reference_link')
    return data.value
  } catch (e) {
    console.error(e, 'fetchShowPromptReferenceLinkPromptConfig fetch error')
  }
}

export async function getFloatActionEntryConfig(): Promise<IFloatActionConfigRes> {
  const res = await getApolloConfig('chatbot-float-icon-config')
  let data: any = {}

  try {
    data = JSON.parse(res.value)
  } catch (e) {
    console.error(e, 'getFloatActionEntryConfig-error')
  }
  return data
}
