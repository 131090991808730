import type { HttpDefine } from '@bty/http-client'
import { request } from '../../client'
import type { LogItem } from '../run/type'
import { FLOW_EXECUTE_TIMEOUT } from './constants'
import type {
  FlowData,
  FlowUpdateParams,
  FlowUpdateResponse,
  FlowPublishParams,
  FlowPublishResponse,
  FlowExecuteParams,
  FlowExecuteResponse,
  IUploadFileInfo,
  FlowSchema,
  ExportFlowLogsParams,
  ITemplateItem,
  IPluginItem,
  ISupportFileConfig,
  ISubFlowItem,
} from './type'

export function getFlowById(flowId: string, config?: HttpDefine) {
  return request.get<FlowData>(`/v1/flow/${flowId}`, config)
}

export function getDraftFlowById(flowId: string, config?: HttpDefine) {
  return request.get<FlowData>(`/v1/flow/draft/${flowId}`, config)
}

export function updateFlowDetail(body: FlowUpdateParams) {
  return request.post<FlowUpdateResponse>('v1/flow/update', body)
}

export function publishFlow(body: FlowPublishParams, config?: HttpDefine) {
  return request.post<FlowPublishResponse>('v1/flow/publish', body, config)
}

export function executeFlow(body: FlowExecuteParams) {
  return request.post<FlowExecuteResponse>('v1/flow/execute', body, {
    timeout: FLOW_EXECUTE_TIMEOUT,
  })
}

export function getFlowExecuteLog({
  flowId,
  taskId,
}: {
  flowId: string
  taskId: string | number
}) {
  return request.get<LogItem>(`v1/flow/execute_log/${flowId}/${taskId}`)
}

export function shareToChatBot(body: { flowId: string; isBot: boolean }) {
  return request.post<LogItem>('/v1/flow/is_bot', body)
}

export function exportFlowLogs(body: ExportFlowLogsParams) {
  return request.post<string | null>('v1/flow/export_log', body)
}

/**
 * @desc 获取flow的start节点支持的文件类型配置
 */
export function getStartNodeSupportFileConfig() {
  return request.get<ISupportFileConfig[]>('v1/chat/flow/support')
}

export async function fetchSubFlowList(flowId: string) {
  const list = await request.get<ISubFlowItem[]>(`/v1/flow/${flowId}/addition`)

  return list.filter(each => each.visible ?? true)
}

export function fetchTemplateList() {
  return request.get<ITemplateItem[]>('/v1/flow/suite-template')
}

export function forkTemplate(source_id: string) {
  return request.post(`v1/flow/suite-template/fork?source_id=${source_id}`)
}

export function fetchPluginList(_pluginId: string): Promise<any> {
  return request.get<IPluginItem[]>('/v1/flow/plugin_template')
}

// deprecated 废弃的
export function uploadFileApi(file: any) {
  return request.post<any>('/v1/chat/file_upload', file, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export function getUploadFileInfo(upload_record_id: any) {
  return request.get<IUploadFileInfo>(
    `/v1/chat/file/search?upload_record_id=${upload_record_id}`,
  )
}

export function generateFlowSchema(flowId: string, isDraft = false) {
  return request.get<FlowSchema>(
    `/v1/flow/skill/schema?flow_id=${flowId}${isDraft ? '&is_draft=true' : ''}`,
  )
}

export function getFlowRunResult(flowId: string) {
  return request.get<string | Record<string, unknown> | unknown[]>(
    `/v1/flow/task_result?flow_id=${flowId}`,
    {
      headers: {
        'Application-Id': flowId,
      },
    },
  )
}
