import { Radio } from 'antd'
import classNames from 'classnames'
import type { RoleCode } from '@apis/authority/type'
import type { OptionProps } from './InviteContent'

interface SelectUserProps {
  role: RoleCode
  setRole: (role: RoleCode) => void
  options: OptionProps[]
  isInviteUser: boolean
  inviteWithoutAdmin?: boolean
}

export function SelectUserContent(props: SelectUserProps) {
  const { setRole, role, options, isInviteUser } = props
  const onChange = (value: RoleCode) => {
    setRole(value)
  }
  return (
    <>
      {isInviteUser ? (
        <div className='flex mb-24px gap-x-12px'>
          {options?.map((option, index) => (
            <div
              className={classNames('border cursor-pointer p-12px flex-1', {
                '!border-#7B61FF': role === option.value,
              })}
              style={{
                borderColor: 'rgba(225, 225, 229, 0.6)',
                borderRadius: '8px',
              }}
              onClick={() => onChange(option.value)}
              key={index.toString()}
            >
              <div className='flex items-center justify-between mb-10px'>
                <span className='text-14px/16px mr-4px font-500'>
                  {option.label}
                </span>
                <Radio
                  key={option.value}
                  checked={role === option.value}
                ></Radio>
              </div>
              <span className='text-14px/20px c-#8D8D99'>
                {option.descrption}
              </span>
            </div>
          ))}
        </div>
      ) : (
        <div className='flex mb-24px gap-x-12px'>
          {options?.map((option, index) => (
            <div
              className={classNames(
                'border cursor-pointer p-12px flex-1 flex items-center justify-between',
                { '!border-#7B61FF': role === option.value },
              )}
              style={{
                borderColor: 'rgba(225, 225, 229, 0.6)',
                borderRadius: '8px',
              }}
              onClick={() => onChange(option.value)}
              key={index.toString()}
            >
              <div>
                <span className='text-14px/16px mr-4px'>{option.label}</span>
                <span className='text-14px/14px c-#8D8D99'>
                  {option.descrption}
                </span>
              </div>
              <Radio key={option.value} checked={role === option.value}></Radio>
            </div>
          ))}
        </div>
      )}
    </>
  )
}
