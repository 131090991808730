import {
  memo,
  type MouseEvent,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { Tooltip } from 'antd'
import { isUndefined, uniqueId } from 'lodash-es'
import download from 'downloadjs'
import { FlowStatus } from '@apis/flow/type'
import { DebugResultPanelContext } from '@/features/nodes/base/DebugResultPanel'
import { IconFont } from '@/components'
import { safeJSONStringify } from '../../../utils'
import { OverflowContent } from '../OverflowContent'
import { CodeLogSelect } from '../StyledComponent'
import { EmptyContent } from '../EmptyContent'
import { LogItem } from './components/LogItem'

interface CodeResultPanelProps {
  toggleTerminal: () => void
  nodeName: string
  leftPanelWidth: number
  rightPanelWidth: number
}

type logLevelTypeSingle = 'log' | 'warn' | 'error'
type logLevelType = logLevelTypeSingle | 'ALL'

export interface LogEntry {
  level: logLevelTypeSingle
  message: string
  timestamp: number
  collapseObject?: boolean | object
  from?: 'client' | 'server'
}

interface LogViewerProps {
  logs: LogEntry[] | []
  logLevel: logLevelType
  logPanelWidth: number
}

const logLevelSelect = [
  {
    label: (
      <span className='inline-flex flex-items-center line-height-23px text-14px'>
        <IconFont
          name='quanbu'
          color='rgba(98, 105, 153, 0.6)'
          className='text-16px mr-4px'
        />
        全部日志
      </span>
    ),
    value: 'ALL',
  },
  {
    label: (
      <span className='inline-flex flex-items-center line-height-23px text-14px'>
        <IconFont
          name='yonghuzidingyi'
          color='rgba(98, 105, 153, 0.6)'
          className='text-16px mr-4px'
        />
        用户自定义
      </span>
    ),
    value: 'log',
  },
  {
    label: (
      <span className='inline-flex flex-items-center line-height-23px text-14px'>
        <IconFont
          name='cuowurizhi'
          color='rgb(236,95,50)'
          className='text-16px mr-4px'
        />
        错误日志
      </span>
    ),
    value: 'error',
  },
  {
    label: (
      <span className='inline-flex flex-items-center line-height-23px text-14px'>
        <IconFont
          name='jinggaorizhi'
          color='rgba(254, 151, 0, 1)'
          className='text-16px mr-4px'
        />
        警告日志
      </span>
    ),
    value: 'warn',
  },
]

const LogLevelMap: Record<string, string> = {
  error: 'error',
  warn: 'warn',
  INFO: 'log',
}

function formatArrayMessage(message: string[]) {
  try {
    if (Array.isArray(message)) {
      return message
        .map(item => {
          if (typeof item === 'string') {
            return item
          } else {
            return safeJSONStringify(item)
          }
        })
        .join(' ')
    }
  } catch (e) {
    return message.join(' ')
  }
  return ''
}

function LogViewer(props: LogViewerProps) {
  const { logs, logLevel, logPanelWidth } = props

  const filteredLogs = useMemo(() => {
    if (logLevel === 'ALL') {
      return logs
    }
    return logs.filter(log => log.level === logLevel)
  }, [logs, logLevel])

  return (
    <>
      {filteredLogs.length === 0 && <EmptyContent />}
      {filteredLogs.map(log => (
        <LogItem
          key={uniqueId()}
          logEntry={log}
          itemWidth={logPanelWidth - 150} // 150 是日志项时间戳和icon所占的空间
        />
      ))}
    </>
  )
}

function downloadJson(jsonData: any, fileName: string): void {
  const jsonString: string = safeJSONStringify(jsonData)
  download(jsonString, fileName, 'application/json')
}

function CodeResult(props: CodeResultPanelProps) {
  const { toggleTerminal, nodeName, leftPanelWidth, rightPanelWidth } = props
  const { result, loading, setResult } = useContext(DebugResultPanelContext)

  const lastTaskIdRef = useRef<string | null>(null)

  const [logs, setLogs] = useState<LogEntry[]>([])

  const [logLevel, setLogLevel] = useState<logLevelType>('ALL')

  const showLoading = useMemo(() => loading, [loading, result?.output])

  const logEndRef = useRef<HTMLDivElement | null>(null)

  const [logPanelWidth, setLogPanelWidth] = useState(calcLogPanelWidth())

  function calcLogPanelWidth() {
    return document.body.clientWidth - leftPanelWidth - rightPanelWidth
  }

  const scrollToBottom = () => {
    logEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' })
  }

  useEffect(() => {
    if (showLoading) {
      setLogs([
        {
          level: 'log',
          message: '正在运行中...',
          timestamp: Date.now(),
          from: 'client',
        },
      ])
    }
  }, [showLoading])

  useEffect(() => {
    if (
      !result ||
      lastTaskIdRef.current === result.taskId ||
      result.isEmptyLog
    ) {
      return
    }
    lastTaskIdRef.current = result?.taskId

    const innerLogs: LogEntry[] = [
      {
        level: 'log',
        message: '正在运行中...',
        timestamp: result.startTime,
        from: 'client',
      },
    ]

    if (Array.isArray(result.logs) && result.logs.length > 0) {
      const formattedLogs = result.logs.map(item => {
        /**
         * 返回的 messgae 有几种形式
         * 1. message: 'xxx'
         * 2. message: ['xxx']
         * 3. message: [obj]
         * 4. message: [obj, obj]
         */
        let formattedMessage
        if (Array.isArray(item.message)) {
          if (item.message.length === 1) {
            formattedMessage = item.message[0]
          } else {
            formattedMessage = item.message
          }
        } else {
          formattedMessage = item.message
        }
        const isStringMessage = typeof formattedMessage === 'string'
        const message = isStringMessage
          ? item.message
          : formatArrayMessage(item.message)
        return {
          level: (LogLevelMap[item.level] ?? 'log') as logLevelTypeSingle,
          message,
          collapseObject: isStringMessage ? false : formattedMessage,
          timestamp: item.timestamp,
        }
      })
      innerLogs.push(...formattedLogs)
    }

    if (result.status === FlowStatus.FAIL) {
      innerLogs.push({
        level: 'error',
        message: result.message || '',
        timestamp: result.endTime,
      })
      setLogs(innerLogs)
      return
    }

    try {
      innerLogs.push({
        level: 'log',
        message: `输出： ${safeJSONStringify(result.output)}`,
        timestamp: result.endTime || Date.now(),
        collapseObject:
          typeof result.output === 'string' ? false : result.output,
      })
    } catch (e) {
      console.error('Error processing output:', e)
    }
    setLogs(innerLogs)
  }, [result])

  useEffect(() => {
    scrollToBottom()
  }, [logs])

  console.log(
    !showLoading && !isUndefined(result?.output) && logs.length === 0,
    logs,
  )

  const downloadLogs = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation()
    const downloadLogs = logs.filter(log => log.from !== 'client')
    downloadJson(downloadLogs, `${nodeName}_logs.json`)
  }

  useEffect(() => {
    const computedLogPanelWidth = () => {
      const width = calcLogPanelWidth()
      setLogPanelWidth(width)
    }

    window.addEventListener('resize', computedLogPanelWidth)
    computedLogPanelWidth()
    return () => {
      window.removeEventListener('resize', computedLogPanelWidth)
    }
  }, [leftPanelWidth, rightPanelWidth])

  return (
    <div
      className='w-100% h-100%'
      style={{ background: 'rgba(250, 250, 252)' }}
    >
      <div
        className='flex justify-between flex-1 px-16px py-8px'
        style={{ borderBottom: '1px solid rgba(225, 225, 229, 0.6)' }}
      >
        <div className='flex items-center'>
          <span className='text-16px c-#17171D font-500 mr-12px'>控制台</span>
          <CodeLogSelect
            style={{ width: 160 }}
            value={logLevel}
            options={logLevelSelect}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onChange={setLogLevel}
            size='small'
          />
          {!showLoading && logs.length > 0 && (
            <>
              <Tooltip title='清空日志'>
                <button
                  onClick={event => {
                    event.stopPropagation()
                    if (result?.taskId) {
                      setResult?.({
                        ...result,
                        logs: [],
                        isEmptyLog: true,
                      })
                    }
                    setLogs([])
                  }}
                >
                  <div className='bg-#fff border-width-1px cursor-pointer p-7px rounded-6px hover:bg-[rgba(98,105,109,0.08)] ml-8px'>
                    <IconFont className='text-16px' name='qingkongrizhi' />
                  </div>
                </button>
              </Tooltip>
              <Tooltip title='下载日志'>
                <button
                  onClick={downloadLogs}
                  disabled={logs.length === 0 || showLoading}
                >
                  <div className='bg-#fff  border-width-1px cursor-pointer p-7px rounded-6px hover:bg-[rgba(98,105,109,0.08)] ml-8px'>
                    <IconFont className='text-16px' name='xiazairizhi' />
                  </div>
                </button>
              </Tooltip>
            </>
          )}
        </div>
        <button
          onClick={event => {
            event.stopPropagation()
            toggleTerminal()
          }}
        >
          <div className='cursor-pointer p-7px rounded-4px hover:bg-[rgba(98,105,109,0.08)]'>
            <IconFont className='text-16px' name='guanbi' />
          </div>
        </button>
      </div>
      <OverflowContent occupiedHeight='48'>
        <div>
          {!showLoading && logs.length === 0 && isUndefined(result?.output) && (
            <EmptyContent />
          )}
          {showLoading && (
            <LogViewer
              logPanelWidth={logPanelWidth}
              logLevel={logLevel}
              logs={logs}
            />
          )}
          {!showLoading &&
            !isUndefined(result?.output) &&
            (status === FlowStatus.FAIL ? (
              <div className='flex flex-items-center flex-justify-center b-rd-8px bg-#ff5219 bg-op-8 px-12px py-8px'>
                <IconFont name='cuowu' className='text-16px m-r-8' />
                <span className='c-#99310f'>{result?.message}</span>
              </div>
            ) : (
              <LogViewer
                logPanelWidth={logPanelWidth}
                logs={logs}
                logLevel={logLevel}
              />
            ))}
          <div ref={logEndRef} />
        </div>
      </OverflowContent>
    </div>
  )
}

export const CodeResultPanel = memo(CodeResult)
