export const ApprovalStatus = {
  PENDING: 0,
  APPROVED: 1,
  REJECTED: 2,
} as const

export type ApprovalStatusValue =
  (typeof ApprovalStatus)[keyof typeof ApprovalStatus]

export const PermissionNotificationType = {
  /**
   * 审批者收到的申请
   */
  REQUEST: 0,
  /**
   * 申请者收到的结果
   */
  APPROVAL: 1,
} as const

export interface PermissionNotification {
  id: string
  role: (typeof PermissionNotificationType)[keyof typeof PermissionNotificationType]
  /**
   * 申请人
   */
  apply_user_name: string
  /**
   * 审批状态
   */
  approved_status: ApprovalStatusValue
  /**
   * 审批人
   */
  approved_user_name: string
  workspace_name: string
  flow_id: string
  flow_name: string
  create_at: string
  type: 3 | number
  application_id: string
}

export enum ApplyType {
  VIEWER = 1,
  DEVELOPER = 3,
}

export const ResultOfApply = {
  NO_ACTION: -1, // 未操作过
  APPLY: 0, // 已申请
  RESOLVE: 1, // 已经同意
  REJECTED: 2, // 拒绝
} as const
