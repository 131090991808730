import { request } from '../../client'
import type {
  AlarmRulesParams,
  AlarmRulesResponse,
  AlarmRuleConfig,
  AlarmRuleEditParams,
  ToggleAlarmRuleEnableParams,
  AlarmChannelResponse,
  AlarmRecordsParams,
  AlarmRecordsResponse,
} from './type'

export function fetchAlarmRules(body: AlarmRulesParams) {
  return request.post<AlarmRulesResponse>(
    '/v1/app/alarm_rule_config/list',
    body,
  )
}

export function createAlarmRule(body: AlarmRuleConfig) {
  return request.post('/v1/app/alarm_rule_config/save', body)
}

export function updateAlarmRule(body: AlarmRuleEditParams) {
  return request.post('/v1/app/alarm_rule_config/update', body)
}

export function toggleAlarmRuleEnable(body: ToggleAlarmRuleEnableParams) {
  return request.put('/v1/app/alarm_rule_config/enabled', null, {
    query: body,
  })
}

export function removeAlarmRule(id: string) {
  return request.delete(`/v1/app/alarm_rule_config/delete?alarm_rule_id=${id}`)
}

export function fetchAlarmChannelByRuleId(id: string) {
  return request.get<AlarmChannelResponse>(
    `/v1/app/alarm_rule_config/notify_channel_info?alarm_rule_id=${id}`,
  )
}

export function fetchAlarmRecords(body: AlarmRecordsParams) {
  return request.post<AlarmRecordsResponse>(
    '/v1/app/alarm_rule/alarm_record_list',
    body,
  )
}

export function testAlarmDingTalkConnection(body: {
  webhook_url: string
  secret: string
  notify_user?: string[]
}) {
  return request.post('/v1/app/alarm_rule/test_connection', body)
}
