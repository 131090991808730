import classNames from 'classnames'
import { PartitionCategoryType } from '@apis/datastore/type'
import document_repo from '@/assets/dataset/document_repo@2x.png'
import qa_repo from '@/assets/dataset/qa_repo@2x.png'

import { Button, IconFont } from '@/components'
import documentRepoIcon from '@/assets/dataset/document_repo_icon.png'
import qaRepoIcon from '@/assets/dataset/qa_repo_icon.png'
import { LimitedAccess } from '@/features/pay/LimitedAccess'

// import { Empty } from '@/components/empty'

const list = [
  {
    type: PartitionCategoryType.Document,
    img: document_repo,
    icon: documentRepoIcon,
    title: '文档知识库',
    description: '从文档、网页、图片、视频中获取知识信息',
  },
  {
    type: PartitionCategoryType.QA,
    img: qa_repo,
    icon: qaRepoIcon,
    title: '问答知识库',
    description: '从QA问答中获取问答知识，适合客服场景',
  },
]

export function CreateDataStoreCard(props: {
  create: (type: PartitionCategoryType) => void
  cardClassName?: string
}) {
  const { create, cardClassName } = props

  return (
    <div className='w-100% m-auto flex items-center gap-24px justify-center flex-1'>
      {list.map(item => {
        return (
          <div
            key={item.type}
            className={classNames(
              'flex flex-col items-center bg-#fff rounded-12px  box-border flex-1 max-w-480px max-h-480px px-24px',
              cardClassName,
            )}
          >
            <img
              src={item.img}
              className='w-[36%] h-[26.66%] min-w-150px min-h-128px mt-98px'
            />
            <div className='mt-82px flex items-center text-20px/36px c-#17171D'>
              <div className='flex flex-col items-center'>
                <div className='flex items-center'>
                  <div className='relative'>
                    <img
                      className='w-30px h-30px'
                      src={item.icon}
                      alt={item.title}
                    />
                  </div>

                  <p className='ml-8px'>{item.title}</p>
                </div>
                <p className='mt-12px c-#8D8D99 text-14px/16px'>
                  {item.description}
                </p>
              </div>
            </div>
            <div className='mt-24px mb-48px'>
              <LimitedAccess limitedType='dataStore'>
                <Button
                  size='middle'
                  icon={<IconFont name='add' />}
                  type='primary'
                  onClick={() => {
                    create(item.type)
                  }}
                >
                  <span>
                    添加
                    {item.type === PartitionCategoryType.QA ? '问答' : '文档'}
                  </span>
                </Button>
              </LimitedAccess>
            </div>
          </div>
        )
      })}
    </div>
  )
}
