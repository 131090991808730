import { useAuth } from '@bty/react-auth'
import styled from '@emotion/styled'
import { useBoolean, useRequest } from 'ahooks'
import { Modal as AntDModal, Form, message } from 'antd'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import copy from 'copy-to-clipboard'
import { RoleCode } from '@apis/authority/type'
import {
  deleteWorkSpace,
  generateWorkspaceApiKey,
  getWorkspaceApiKey,
  getWorkspaceList,
  updateWorkspace,
} from '@apis/workspace'
import { Input, Button, IconFont, Modal } from '@/components'
import { useWorkspaceStore } from '@/store'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

// const StyledInput = styled(Input)`
//   .ant-input-disabled {
//     color: ${({ theme }) => theme.colors.font};
//   }
// `
const StyledForm = styled(Form)`
  .ant-form-item-explain-error {
    margin-left: 0px;
  }
`

export default function Base() {
  const {
    currentWorkSpaceId,
    currentWorkspaceItem,
    fetchWorkspaceList,
    setCurrentWorkSpace,
  } = useWorkspaceStore(state => ({
    currentWorkSpaceId: state.currentWorkspaceId,
    currentWorkspaceItem: state.currentWorkspaceItem,
    fetchWorkspaceList: state.fetchWorkspaceList,
    setCurrentWorkSpace: state.setCurrentWorkspace,
  }))
  const [name, setName] = useState('')
  const navigate = useNavigate()
  const [regeneratePopVisible, { toggle: toggleVisible }] = useBoolean(false)
  const [
    confirmDeletionPopup,
    { setTrue: setConfirmModalTrue, setFalse: setConfirmModalFalse },
  ] = useBoolean(false)
  const [workspaceForm] = Form.useForm()
  const {
    state: { role },
  } = useAuth()

  const { runAsync: changeWorkspaceName, loading: changeLoading } = useRequest(
    updateWorkspace,
    { manual: true },
  )
  const { data: apiKeyData, refreshAsync: fetchApiKey } = useRequest(() =>
    getWorkspaceApiKey(currentWorkSpaceId),
  )
  const { runAsync: generateApiKey, loading: generateLoading } = useRequest(
    generateWorkspaceApiKey,
    { manual: true },
  )
  const { runAsync: obtainWorkSpaceList } = useRequest(getWorkspaceList, {
    manual: true,
  })

  const displayKey = useMemo(() => {
    if (!apiKeyData) return ''
    return apiKeyData
  }, [apiKeyData])

  const onNameChange = async () => {
    const res = await changeWorkspaceName({
      id: currentWorkSpaceId,
      name,
    })
    if (res) {
      await fetchWorkspaceList()
      setCurrentWorkSpace(currentWorkSpaceId)
    }
  }

  const onGenerate = async () => {
    if (displayKey) {
      toggleVisible()
      return
    }
    const res = await generateApiKey(currentWorkSpaceId)
    if (res) {
      await fetchApiKey()
    }
  }

  const onRegenerate = async () => {
    toggleVisible()
    const res = await generateApiKey(currentWorkSpaceId)
    if (res) {
      await fetchApiKey()
    }
  }
  const onDeleteWorkSpace = () => {
    workspaceForm.validateFields().then(async () => {
      const data = await deleteWorkSpace(currentWorkSpaceId)
      message.success('删除成功')
      if (data) {
        const res = await obtainWorkSpaceList()
        if (res.length > 0) {
          navigate('/select-workspace', { state: { workSpaceList: res ?? [] } })
        } else {
          navigate('/login?type=workspace')
        }
      }
      // setConfirmModalFalse()
    })
  }

  const onCopyKey = () => {
    copy(apiKeyData)
    message.success('已复制到剪贴板')
  }

  const onCopyId = () => {
    copy(currentWorkSpaceId)
    message.success('已复制到剪贴板')
  }

  useEffect(() => {}, [apiKeyData])

  useEffect(() => {
    if (currentWorkspaceItem) {
      setName(currentWorkspaceItem.name)
    }
  }, [currentWorkspaceItem])

  const { scrollRef } = useScrollBar()

  return (
    <div
      ref={scrollRef}
      className='flex-1 flex h-full flex-col overflow-scroll'
    >
      <div className='p-24 rounded-8px bg-white flex-1'>
        <div className='mb-24'>
          <div className='mb-12 text-14px font-500'>工作空间名称</div>
          <div className='flex flex-items-center'>
            <Input
              className='w-600px m-r-12'
              classNames={{ input: 'important-text-14px' }}
              // size='large'
              showCount
              maxLength={50}
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <Button
              type='primary'
              loading={changeLoading}
              onClick={onNameChange}
            >
              更新修改
            </Button>
          </div>
        </div>
        <div className='mb-24'>
          <div className='mb-12 text-14px font-500'>工作空间ID</div>
          <div
            className='flex-center-between w-600px flex-1 px-12 py-10 bg-bg_3 bg-op-5.88 rounded-8px cursor-pointer'
            onClick={onCopyId}
          >
            <span
              className='font-400 truncate flex-1 pr-12'
              title={currentWorkSpaceId}
            >
              {currentWorkSpaceId}
            </span>
            <span className='w-16px h-16px flex-center text-16px c-font_1 cursor-pointer'>
              <IconFont name='copy' className='c-#626999 opacity-60' />
            </span>
          </div>
        </div>
        <div>
          <div className='m-b-12 text-14px font-500'>API Key</div>
          <div className='flex flex-items-center'>
            <div
              className={classNames(
                'flex-center-between w-600px px-12 py-10 bg-bg_3 bg-op-5.88 rounded-8px mr-12px',
                { 'cursor-pointer': displayKey },
                { 'cursor-not-allowed': !displayKey },
              )}
              onClick={displayKey ? onCopyKey : undefined}
            >
              <span
                className='font-400 truncate flex-1 pr-12'
                title={displayKey}
              >
                {displayKey || (
                  <span className='color-#8D8D99 opacity-60'>未生成</span>
                )}
              </span>
              <span className='w-16px h-16px flex-center text-16px c-font_1'>
                {displayKey && (
                  <IconFont name='copy' className='c-#626999 opacity-60' />
                )}
              </span>
            </div>
            {/* <StyledInput
              className='w-600px m-r-12'
              classNames={{ input: 'important-text-14px' }}
              disabled
              suffix={
                displayKey ? (
                  <div
                    className='flex flex-items-center cursor-pointer'
                    onClick={onCopyKey}
                  >
                    <IconFont className='text-16px c-font_1' name='copy' />
                  </div>
                ) : null
              }
              value={displayKey}
            /> */}

            {role === RoleCode.ADMINISTRATOR ||
            (role === RoleCode.DEVELOPER && !displayKey) ? (
              <Button
                type={displayKey ? 'default' : 'primary'}
                loading={generateLoading}
                onClick={onGenerate}
              >
                {displayKey ? '重新生成' : '一键生成'}
              </Button>
            ) : (
              ''
            )}

            <Modal
              open={regeneratePopVisible}
              title='重新生成API Key'
              onCancel={toggleVisible}
              okButtonProps={{ loading: generateLoading }}
              onOk={onRegenerate}
            >
              <p>确定要重新生成 API 密钥吗？</p>
              <p className='leading-loose'>
                重新生成后，原有的 API 密钥将失效。
              </p>
            </Modal>
          </div>
          {role === RoleCode.ADMINISTRATOR && (
            <Button
              className='mt-40px hover:opacity-80 hover:bg-#FF5219! hover:text-#F7F7FF! hover:border-white!'
              onClick={setConfirmModalTrue}
            >
              删除空间
            </Button>
          )}
          <AntDModal
            open={confirmDeletionPopup}
            title={
              <div className='text-14px/22px font-500'>
                删除工作空间操作无法撤销，请输入空间名称：
                <span className='c-#7B61FF'>{name}</span>，进行确认
              </div>
            }
            centered
            closable={false}
            destroyOnClose={true}
            afterClose={() => {
              workspaceForm.resetFields()
            }}
            footer={[
              <Button key='back' onClick={setConfirmModalFalse}>
                取消
              </Button>,
              <Button
                key='submit'
                type='primary'
                onClick={onDeleteWorkSpace}
                style={{ background: '#FF5219' }}
                className='hover:opacity-80'
              >
                删除
              </Button>,
            ]}
          >
            <StyledForm form={workspaceForm}>
              <Form.Item
                name='workspaceName'
                validateTrigger='onBlur'
                rules={[
                  { required: true, message: '请输入空间名称' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('workspaceName') === name) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error('名称输入有误'))
                    },
                  }),
                ]}
              >
                <Input
                  placeholder='输入该空间名称'
                  className='mt-8px mb-12px'
                  style={{ marginBottom: '2px' }}
                />
              </Form.Item>
            </StyledForm>
          </AntDModal>
        </div>
      </div>
    </div>
  )
}
