import { Form } from 'antd'
import type { UploadFileStatus } from 'antd/es/upload/interface'
import { useRequest } from 'ahooks'
import { getQATemplate } from '@apis/datastore'
import { DocumentType } from '@apis/datastore/model'
import { IconFont, FormItem, Input, TextArea, Button } from '@/components'
import { DocumentUpload, defaultNormaleFileType } from './DocumentUpload.tsx'

interface Props {
  documentType?: DocumentType
  hashPath: string
  getFileStatus?: (e?: UploadFileStatus) => void
}
export function UploadFileForm(props: Props) {
  const { documentType, hashPath, getFileStatus } = props
  const { run: getQAOssUrl, loading: getQaLoading } = useRequest(
    getQATemplate,
    {
      manual: true,
    },
  )
  switch (documentType) {
    case DocumentType.FILE:
      return (
        <FormItem
          name='fileList'
          className='mb-0'
          rules={[
            {
              validator(_, value) {
                if (!value || value.length === 0) {
                  return Promise.reject(new Error('至少上传一个文件'))
                }
                for (const file of value) {
                  if (file.file_status === 'uploading') {
                    return Promise.reject(
                      new Error('文件正在上传中，上传完成后提交'),
                    )
                  }
                }
                return Promise.resolve()
              },
            },
          ]}
        >
          <DocumentUpload
            namespace='partition'
            business='dataset'
            hashPath={hashPath}
            fileAccept={defaultNormaleFileType}
            getFileStatus={getFileStatus}
          />
        </FormItem>
      )
    case DocumentType.TEXT:
      return (
        <>
          <FormItem
            label={
              <span>
                标题<span className='text-font_1'>（选填）</span>
              </span>
            }
            name='textTitle'
          >
            <Input showCount maxLength={20} />
          </FormItem>
          <FormItem
            className='mb-0'
            label='文本信息'
            name='textContent'
            required
            rules={[{ required: true, message: '文本信息不能为空' }]}
          >
            <TextArea rows={3} maxLength={1000} showCount />
          </FormItem>
        </>
      )
    case DocumentType.WEBPAGE:
      return (
        <div className='mt--10px'>
          <Form.List name='webUrl' initialValue={['']}>
            {(fields, { add, remove }) => {
              return (
                <>
                  <div className='flex justify-between items-center mb-8px'>
                    <div className='whitespace-nowrap'>
                      <p className='inline color-#17171D'>输入网页URL地址</p>
                      <p className='inline color-#FF5219'> *</p>
                    </div>
                    <Button
                      icon={<IconFont name='add' className='color-#62699998' />}
                      onClick={() => add('')}
                    >
                      添加
                    </Button>
                  </div>
                  {fields.map((field, index) => (
                    <FormItem
                      key={field.key}
                      name={field.name}
                      style={{ marginBottom: '8px' }}
                      rules={[
                        {
                          required: true,
                          message: '请输入URL地址',
                        },
                        {
                          type: 'url',
                          message:
                            '网页URL地址格式错误，请输入正确的网址URL地址',
                        },
                      ]}
                    >
                      <Input
                        suffix={
                          fields.length > 1 ? (
                            <IconFont
                              name='shanshu'
                              className='color-#62699998 cursor-pointer'
                              onClick={() => remove(index)}
                            />
                          ) : null
                        }
                      />
                    </FormItem>
                  ))}
                </>
              )
            }}
          </Form.List>
        </div>
      )
    case DocumentType.QA:
      return (
        <div>
          <Button
            size='large'
            style={{ height: '36px' }}
            loading={getQaLoading}
            className='mb-16'
            onClick={() => {
              getQAOssUrl('QA问答模版')
            }}
            icon={<IconFont name='xiazai' className='text-16px' />}
          >
            下载模板
          </Button>
          <FormItem
            name='fileList'
            className='mb-0'
            rules={[
              {
                validator(_, value) {
                  if (!value || value.length === 0) {
                    return Promise.reject(new Error('至少上传一个文件'))
                  }
                  for (const file of value) {
                    if (file.file_status === 'uploading') {
                      return Promise.reject(
                        new Error('文件正在上传中，上传完成后提交'),
                      )
                    }
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <DocumentUpload
              namespace='partition'
              hashPath={hashPath}
              business='dataset'
              placeholder='请使用模板上传，否则会解析失败'
              fileAccept={['.xlsx']}
            />
          </FormItem>
        </div>
      )
    case DocumentType.VIDEO:
      return (
        <div>
          <FormItem
            name='fileList'
            className='mb-0'
            rules={[
              {
                validator(_, value) {
                  if (!value || value.length === 0) {
                    return Promise.reject(new Error('至少上传一个文件'))
                  }
                  for (const file of value) {
                    if (file.file_status === 'uploading') {
                      return Promise.reject(
                        new Error('文件正在上传中，上传完成后提交'),
                      )
                    }
                  }
                  return Promise.resolve()
                },
              },
            ]}
          >
            <DocumentUpload
              namespace='partition'
              hashPath={hashPath}
              business='dataset'
              maxCount={5}
              placeholder='支持格式: MP4、MOV、WMV、FLV、AVI、WEBM、MKV；最大 150MB，最多5个'
              fileAccept={[
                '.mp4',
                '.mov',
                '.wmv',
                '.flv',
                '.avi',
                '.webm',
                '.mkv',
              ]}
            />
          </FormItem>
        </div>
      )

    default:
      return null
  }
}

export function UploadQAFileForm(props: Props) {
  const { hashPath } = props
  const { run: getQAOssUrl, loading: getQaLoading } = useRequest(
    getQATemplate,
    {
      manual: true,
    },
  )
  return (
    <div>
      <Button
        size='large'
        style={{ height: '36px' }}
        loading={getQaLoading}
        className='mb-16'
        onClick={() => {
          getQAOssUrl('QA问答模版')
        }}
        icon={<IconFont name='xiazai' className='text-16px' />}
      >
        下载模板
      </Button>
      <FormItem
        name='fileList'
        className='mb-0'
        rules={[
          {
            validator(_, value) {
              if (!value || value.length === 0) {
                return Promise.reject(new Error('至少上传一个文件'))
              }
              for (const file of value) {
                if (file.file_status === 'uploading') {
                  return Promise.reject(
                    new Error('文件正在上传中，上传完成后提交'),
                  )
                }
              }
              return Promise.resolve()
            },
          },
        ]}
      >
        <DocumentUpload
          namespace='partition'
          hashPath={hashPath}
          business='dataset'
          placeholder='请使用模板上传，否则会解析失败'
          fileAccept={['.xlsx']}
        />
      </FormItem>
    </div>
  )
}
