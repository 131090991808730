import { useRequest, useDebounceFn } from 'ahooks'
import { Form, Pagination, Popover } from 'antd'
import type { ColumnsType } from 'antd/es/table'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { filter, get, hasIn, isEmpty, isString, map } from 'lodash-es'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { exportFlowLogs } from '@apis/flow'
import type { LogItem, logType } from '@apis/run/type'
import { getLog } from '@apis/run'
import {
  Button,
  IconFont,
  Input,
  RangePickerWrapper,
  Select,
  Table,
} from '@/components'
import { PersonName } from '@/components/personName'
import { POINTS_CONSUME_NAME } from '@/constants/commercialization'
import theme from '@/constants/theme'
import { useLogsExport } from '@/hooks/useLogsExport'
import {
  DEFAULT_OVERLAY_SCROLLBAR_OPTIONS,
  useScrollBar,
} from '@/hooks/useScrollBar.ts'
import { useFlowLogStore } from '@/store'
import {
  GLobalSelectStyle,
  rangePresets,
  statusSelectOption,
  statusUI,
  typeSelectOption,
  typeSearchOption,
  SearchSelectContainer,
} from '../utils'
import { useGoDraftPageWithInput } from '@/pages/flowPage/hooks/useGoDraftPageWithInput'
import {
  LoggerTitle,
  LoggerModal,
} from '@/features/logger/components/loggerModal'
import { tryParseToJsonObject } from '@/utils/string'
import { useVersion } from '@/hooks/useVersion'
import { RunTypeGroupSelect } from '@/features/analyze/components/RunTypeGroupSelect'
import { LimitedAlertContent } from '@/features/pay/LimitedAlert'
import { disabledOneYearDate } from '@/utils/date'
import { FlowRunTypeGroup } from '@/features/analyze/constants'
import useQueryParams from '@/hooks/useQueryParams'

const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss'

export function getString(data: any): string {
  try {
    return JSON.stringify(data, null, 2)
  } catch (err) {
    return String(data)
  }
}

export default ({ flowName, flowId }: { flowName: string; flowId: string }) => {
  const queryParams = useQueryParams()
  const { goDraftPage } = useGoDraftPageWithInput()
  const setCurrentLog = useFlowLogStore(s => s.setCurrentLog)
  const [searchType, setSearchType] = useState(typeSearchOption[0].value)

  const searchPlaceHolder = useMemo(() => {
    return typeSearchOption?.find(item => searchType === item.value)?.label
  }, [searchType])
  const [currentItem, setCurrentItem] = useState<logType['data'][0] | null>(
    null,
  )
  const { isTeam } = useVersion()
  const [form] = Form.useForm()

  const [tablePage, setTablePage] = useState({
    pageSize: 10,
    total: 0,
    current: 1,
  })

  useEffect(() => {
    const searchValueFromParams = {
      ...(queryParams?.user_name ? { userName: queryParams?.user_name } : {}),
      ...(queryParams?.run_types
        ? { runTypes: queryParams?.run_types.split(',') }
        : {}),
      ...(queryParams?.start_time && queryParams?.end_time
        ? {
            time: [
              dayjs(queryParams?.start_time).startOf('d'),
              dayjs(queryParams?.end_time).endOf('d'),
            ],
          }
        : {}),
    }
    if (!isEmpty(searchValueFromParams)) {
      form.setFieldsValue(searchValueFromParams)
    }
  }, [])

  const { data, run, loading } = useRequest(
    () => {
      const formData = form.getFieldsValue()
      return getLog({
        flowId,
        pageNo: tablePage.current,
        pageSize: tablePage.pageSize,
        runStatus: formData.runStatus === -1 ? null : formData.runStatus,
        runTypes: formData.runTypes,
        startTime: formData.time?.[0]
          ? dayjs(formData.time?.[0]).format('YYYY-MM-DD HH:mm:ss')
          : null,
        endTime: formData.time?.[1]
          ? dayjs(formData.time?.[1]).format('YYYY-MM-DD HH:mm:ss')
          : null,
        [searchType]: formData.userName,
      })
    },
    {
      refreshDeps: [tablePage.current, tablePage.pageSize, searchType],
      onSuccess: e =>
        setTablePage({
          ...tablePage,
          total: e.total,
        }),
    },
  )

  const { exportLogs, loading: exporting } = useLogsExport({
    exportApi: exportFlowLogs,
  })

  const handleExport = () => {
    const searchData = form.getFieldsValue()
    const { userName, time, runTypes, runStatus } = searchData
    exportLogs(
      {
        flowId,
        [searchType]: userName,
        runStatus: runStatus !== -1 ? runStatus : undefined,
        runTypes,
        startTime: time?.[0] ? dayjs(time[0]).format(DATE_FORMAT) : undefined,
        endTime: time?.[1] ? dayjs(time[1]).format(DATE_FORMAT) : undefined,
      },
      `${flowName}-运行日志-${dayjs().format('YYYYMMDDHHmm')}`,
    )
  }

  const columns: ColumnsType<logType['data'][0]> = [
    {
      title: '任务类型',
      dataIndex: 'run_type',
      key: 'run_type',
      width: 128,
      render: (_: any, record) => {
        const type = typeSelectOption.find(
          item => item.value === record?.run_type,
        )
        return (
          <div>
            {type?.label || ''}
            {record.show_name && (
              <span className='ml-4px opacity-60'>{record.show_name}</span>
            )}
          </div>
        )
      },
    },
    {
      title: '状态',
      dataIndex: 'run_status',
      key: 'run_status',
      width: 100,
      render: (_: any, record) => (
        <span
          className='px-14px py-4px border-rd-4px text-12px'
          style={{
            color: statusUI?.[record?.run_status === 'SUCCEEDED' ? 0 : 1].color,
            background:
              statusUI?.[record?.run_status === 'SUCCEEDED' ? 0 : 1].background,
          }}
        >
          {statusUI?.[record?.run_status === 'SUCCEEDED' ? 0 : 1].text || ''}
        </span>
      ),
    },
    {
      title: '输入',
      key: 'input_info',
      dataIndex: 'run_status',
      render: (_: any, record) => {
        // 兼容 历史数据 form_config 被清掉了的情况
        // 注意 如果未空数组不应该走这段逻辑，只有 null/undefined 走
        if (!record.form_config) {
          // 1. 优先取 message
          const messageInput = get(record.input, 'message')
          if (messageInput) {
            return isString(messageInput)
              ? messageInput
              : JSON.stringify(messageInput)
          }
          // 2. 如果没有则取第一个
          const firstValue = Object.values(record.input ?? {})[0]
          if (firstValue) {
            return isString(firstValue)
              ? firstValue
              : JSON.stringify(firstValue)
          }
          // 3. 都没有则显示 -
          return '-'
        }

        const type = typeSelectOption.find(
          item => item.value === record?.run_type,
        )

        const hasValueConfigs = filter(record.form_config, each =>
          hasIn(record.input, each.variableName),
        )
        const firstConfig = hasValueConfigs[0]
        const value = get(record.input, firstConfig?.variableName ?? '')
        const showText = isString(value) ? value : JSON.stringify(value)

        if (!hasValueConfigs.length) {
          return '-'
        }

        return (
          <Popover
            placement='right'
            title={type?.value === 'STEP_RUN' ? '单节点输入' : '工作流输入'}
            content={
              <OverlayScrollbarsComponent
                className='w-250px max-h-360px overflow-auto mr-[-8px] pr-8px'
                element='div'
                options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
                defer
              >
                {map(hasValueConfigs, each => {
                  const sureValue = tryParseToJsonObject(
                    get(record.input, each.variableName, '-'),
                  )

                  return (
                    <div key={each.variableName} className='text-12px mb-8px'>
                      <p className='leading-18px'>{each.label}</p>
                      {isString(sureValue) ? (
                        <p className='leading-18px text-#8D8D99 break-all'>
                          {isEmpty(sureValue) ? '-' : sureValue}
                        </p>
                      ) : (
                        <pre className='leading-16px text-#8D8D99 break-all'>
                          {JSON.stringify(sureValue, null, 2)}
                        </pre>
                      )}
                    </div>
                  )
                })}
              </OverlayScrollbarsComponent>
            }
          >
            <span className='max-w-100% inline-block truncate'>{showText}</span>
          </Popover>
        )
      },
    },
    {
      title: '时间',
      dataIndex: 'start_time',
      key: 'start_time',
      width: 180,
      sortIcon: ({ sortOrder }) => {
        return (
          <div className='ml-8'>
            <IconFont
              name='asc'
              className={classNames('text-6px text-font_1', {
                'text-opacity-40': sortOrder !== 'ascend',
              })}
            />
            <IconFont
              name='desc'
              className={classNames('text-6px text-font_1', {
                'text-opacity-40': sortOrder !== 'descend',
              })}
            />
          </div>
        )
      },
      sorter: (a: any, b: any) =>
        dayjs(a.start_time).unix() - dayjs(b.start_time).unix(),
      render: (_: any, record) =>
        dayjs(record.start_time).format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      title: '用户',
      dataIndex: 'user_name',
      key: 'user_name',
      width: 160,
      render: (_: any, record) => <PersonName name={record.user_name} />,
    },
    {
      title: `消耗${POINTS_CONSUME_NAME}`,
      dataIndex: 'consume_points',
      key: 'consume_points',
      width: 128,
    },
    {
      title: '运行时长',
      dataIndex: 'durationTime',
      key: 'durationTime',
      width: 100,
      render: (_: any, record: any) => <>{record.durationTime}s</>,
    },
    {
      title: '操作',
      key: 'action',
      width: 60,
      render: (_: any, record) => (
        <a
          style={{ color: theme.colors.primary }}
          onClick={e => {
            e.stopPropagation()
            setCurrentItem(record)
            setCurrentLog(record)
          }}
        >
          详情
        </a>
      ),
    },
  ]

  const { scrollRef } = useScrollBar()

  const loggerTitle = useMemo(() => {
    const { start_time, run_status } = currentItem || {}
    return <LoggerTitle time={start_time} status={run_status} />
  }, [currentItem])

  const { run: debouncedSearch } = useDebounceFn(
    () => {
      if (tablePage.current !== 1) {
        setTablePage({
          ...tablePage,
          current: 1,
        })
      } else {
        run()
      }
    },
    { wait: 500 },
  )
  const handleSelectChange = (value: string) => {
    setSearchType(value)
  }

  return (
    <div className='h-full adapt:p-20 rounded-12px bg-#fff w-full flex flex-1 flex-col'>
      <GLobalSelectStyle />
      <Form
        form={form}
        initialValues={{
          runStatus: -1,
          runType: -1,
          time: [dayjs().add(-7, 'd').startOf('d'), dayjs().endOf('day')],
        }}
        className='flex flex-items-center flex-justify-between adapt:gap-20 flex-nowrap'
      >
        <SearchSelectContainer>
          <Select
            options={typeSearchOption}
            style={{ width: 72 }}
            value={searchType}
            onChange={handleSelectChange}
          />
          <Form.Item name='userName' className='mb-0 flex-1'>
            <Input
              prefix={<IconFont className='text-16px' name='search' />}
              className='important:text-14px'
              placeholder={`搜索${searchPlaceHolder}`}
              allowClear
              style={{ height: 36, width: 328 }}
              onChange={debouncedSearch}
            />
          </Form.Item>
        </SearchSelectContainer>
        <div className='flex flex-items-center adapt:gap-20 flex-1 flex-justify-end'>
          <Form.Item name='runStatus' className='m-b-0px flex-1 max-w-140px'>
            <Select
              size='middle'
              options={statusSelectOption}
              placeholder='状态'
              onChange={run}
              style={{ height: 36 }}
            />
          </Form.Item>
          <Form.Item name='runTypes' className='m-b-0px flex-1 max-w-140px'>
            <RunTypeGroupSelect data={FlowRunTypeGroup} onChange={run} />
          </Form.Item>
          <Form.Item name='time' id='test' className='mb-0 flex-2 max-w-320px'>
            <RangePickerWrapper
              disabledDate={isTeam ? disabledOneYearDate : undefined}
              showTime={true}
              presets={rangePresets}
              size='large'
              format='YYYY-MM-DD'
              className='h-36px border-rd-8px'
              onChange={() => {
                if (tablePage.current !== 1) {
                  setTablePage({
                    ...tablePage,
                    current: 1,
                  })
                } else {
                  run()
                }
              }}
            />
          </Form.Item>
          <Button
            style={{ height: 36 }}
            loading={exporting}
            onClick={handleExport}
          >
            导出
          </Button>
        </div>
      </Form>
      <div className='adapt:mt-20 flex-1 flex flex-col overflow-hidden'>
        <div className='flex-1 overflow-y-auto' ref={scrollRef}>
          <Table
            loading={loading}
            columns={columns as any}
            dataSource={data?.data || []}
            sticky
            rowKey='task_id'
            rowClassName={'cursor-pointer'}
            pagination={false}
            onRow={record => ({
              onClick: () => {
                setCurrentItem(record)
                setCurrentLog(record)
              },
            })}
          />
        </div>
        <div className='flex adapt:pt-20 items-center justify-between border-t border-solid border-font_1 border-op-16'>
          <span>
            <LimitedAlertContent
              prefix='团队版仅可查看近1年的日志'
              shouldCheckTeamVersion
            />
          </span>
          <Pagination
            {...tablePage}
            onChange={(page: number, pageSize: number) =>
              setTablePage({ ...tablePage, current: page, pageSize })
            }
          />
        </div>
      </div>
      {!!currentItem && (
        <LoggerModal
          defaultTab='output'
          title={loggerTitle}
          flowId={currentItem.flow_id}
          taskId={currentItem.task_id}
          onClose={() => setCurrentItem(null)}
          onReRun={item => {
            goDraftPage(item as unknown as LogItem)
          }}
        />
      )}
    </div>
  )
}
