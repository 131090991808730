import type { IPluginItem } from '@apis/flow/type'
import { NodeOptionItem } from './NodeOptionItem'

interface PluginLabelItem {
  label: string
  data: IPluginItem[]
}

interface PluginListProps {
  data: PluginLabelItem[]
  onSelect: (id: string) => void
}

export function PluginList(props: PluginListProps) {
  const { data, onSelect } = props

  return (
    data.length > 0 && (
      <>
        <div className='p-8px line-height-16px font-500 c-#17171d'>插件</div>
        {data.map(({ label, data: nodes }) => (
          <div key={label}>
            <div className='py-4px px-8px line-height-16px text-12px font-500 text-#8d8d99'>
              {label}
            </div>
            {nodes.map(({ function_id, display_name, icon, description }) => (
              <NodeOptionItem
                type='plugin'
                key={function_id}
                id={function_id}
                icon={
                  <img className='w-24px h-24px mr-8px' src={icon} alt='' />
                }
                name={display_name}
                description={description}
                onClick={id => {
                  onSelect(id)
                }}
              />
            ))}
          </div>
        ))}
      </>
    )
  )
}
