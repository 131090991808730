import type { Edge, Node } from 'reactflow'
import { v4 as uuidv4 } from 'uuid'
import { cloneDeep } from 'lodash-es'
import { FLOW_CLIPBOARD } from '@/constants/common'
import { getOperationByNodeType } from '../operation'
import type { NodeType } from '@/features/nodes/base'
import { SingleNodeOperation } from '../operation/SingleNodeOperation'
import {
  generateEdgeFromNodes,
  generateNodeRelation,
  isConditionNode,
  isIntentNode,
} from '../utils'
import { MultiBranchNodeOperation } from '../operation/MultiBranchNodeOperation'
import { LoopNodeOperation } from '../operation/LoopNodeOperation'
import { TO_BE_COPIED_ID } from '../constants'
import { generateNodeName } from '@/features/nodes/utils'

interface copyConfig {
  nodes: Node[]
  edges: Edge[]
  newNode?: Node | null
}

function pasteSingleNode(options: {
  nodes: Node[]
  edges: Edge[]
  source: string
  target: string
  sourceHandleId?: string
  cloneNode: Node
}) {
  const { nodes, edges, cloneNode, source, target, sourceHandleId } = options
  const config = {
    nodes,
    edges,
  }
  const { type, data } = cloneNode
  const nodeName = generateNodeName(nodes, type as NodeType, data.pluginName)

  const sourceNode = nodes.find(node => node.id === source)
  const targetNode = nodes.find(node => node.id === target)

  if (!sourceNode || !targetNode) {
    throw new Error('粘贴单节点失败')
  }

  const relation = generateNodeRelation(
    nodes,
    edges,
    sourceNode,
    sourceHandleId,
  )

  const newNode = {
    id: uuidv4(),
    type,
    position: {
      x: sourceNode.position.x,
      y: sourceNode.position.y,
    },
    data: {
      ...data,
      name: nodeName,
      relation: relation || data.relation,
      sourceHandleId,
    },
  }

  config.edges = config.edges.filter(e => {
    if (isConditionNode(sourceNode) || isIntentNode(sourceNode)) {
      return e.id !== `${newNode.data.relation?.branchId}-${target}`
    }
    return !(e.source === source && e.target === target && e.type === 'insert')
  })
  const newEdges = [
    generateEdgeFromNodes({
      source: sourceNode,
      target: newNode,
      sourceHandle: sourceHandleId,
    }),
    generateEdgeFromNodes({
      source: newNode,
      target: targetNode,
    }),
  ]

  config.edges = config.edges.concat(newEdges)
  config.nodes = config.nodes.concat(newNode)

  return {
    ...config,
    nextSourceId: newNode.id,
    nextSourceHandleId: newEdges[1].sourceHandle,
  }
}

function pasteMultiBranchNode(options: {
  nodes: Node[]
  edges: Edge[]
  source: string
  target: string
  sourceHandleId?: string
  cloneData: copyConfig
  cloneStartNode: Node
}) {
  const {
    nodes,
    edges,
    cloneData,
    source,
    target,
    sourceHandleId,
    cloneStartNode,
  } = options
  const config = {
    nodes: [...nodes],
    edges: [...edges, ...cloneData.edges],
  }
  const newNodeId = uuidv4()
  let nextSourceHandleId
  let nextSourceId

  const sourceNode = nodes.find(node => node.id === source)
  const targetNode = nodes.find(node => node.id === target)

  if (!sourceNode || !targetNode) {
    throw new Error('粘贴条件节点失败')
  }

  const cloneResultNode = cloneData.nodes.find(
    n => n.id === cloneStartNode.data.relation?.conditionResultId,
  )
  if (!cloneResultNode) {
    throw new Error('条件节点数据复制有误')
  }

  config.edges = config.edges
    .filter(e => {
      if (isConditionNode(sourceNode) || isIntentNode(sourceNode)) {
        return e.id !== `${sourceHandleId}-${target}`
      }
      return !(
        e.source === source &&
        e.target === target &&
        e.type === 'insert'
      )
    })
    .map(e => {
      return {
        ...e,
        source: e.source === cloneStartNode.id ? newNodeId : e.source,
      }
    })

  cloneData.nodes.forEach(n => {
    // 条件头节点
    if (n.id === cloneStartNode.id) {
      // 粘贴插入时获取头节点的节点关系即可
      const relation = generateNodeRelation(
        nodes,
        edges,
        sourceNode,
        sourceHandleId,
      )
      const newStartNode = {
        ...n,
        id: newNodeId,
        data: {
          ...n.data,
          name: generateNodeName(nodes, n.type as NodeType),
          relation: {
            ...n.data?.relation,
            ...relation,
          },
        },
      }
      config.nodes.push(newStartNode)
      config.edges.push(
        generateEdgeFromNodes({
          source,
          target: newStartNode,
          sourceHandle: sourceHandleId,
        }),
      )
      return
    }
    // 条件尾节点
    if (n.id === cloneResultNode.id) {
      const newResultNode = {
        ...n,
        data: {
          ...n.data,
          name: generateNodeName(nodes, n.type as NodeType),
          relation: {
            ...n.data.relation,
            conditionId: newNodeId,
          },
        },
      }

      const newResultEdge = generateEdgeFromNodes({
        source: newResultNode,
        target,
      })

      nextSourceId = newResultNode.id
      nextSourceHandleId = newResultEdge.sourceHandle
      config.nodes.push(newResultNode)
      config.edges.push(newResultEdge)
      return
    }
    // 条件子节点
    config.nodes.push({
      ...n,
      data: {
        ...n.data,
        name: generateNodeName(nodes, n.type as NodeType),
        relation: {
          ...n.data.relation,
          conditionNodeId:
            n.data.relation?.conditionNodeId === cloneStartNode.id
              ? newNodeId
              : n.data.relation?.conditionNodeId,
        },
      },
    })
  })

  return {
    ...config,
    nextSourceHandleId,
    nextSourceId,
  }
}

function pasteLoopNode(options: {
  nodes: Node[]
  edges: Edge[]
  source: string
  target: string
  sourceHandleId?: string
  cloneData: copyConfig
  cloneStartNode: Node
}) {
  const {
    nodes,
    edges,
    cloneData,
    source,
    target,
    sourceHandleId,
    cloneStartNode,
  } = options
  const config = {
    nodes: [...nodes],
    edges: [...edges, ...cloneData.edges],
  }
  const newNodeId = uuidv4()
  let nextSourceHandleId
  let nextSourceId

  const sourceNode = nodes.find(node => node.id === source)
  const targetNode = nodes.find(node => node.id === target)
  if (!sourceNode || !targetNode) {
    throw new Error('粘贴循环节点失败')
  }

  const cloneResultNode = cloneData.nodes.find(
    n => n.id === cloneStartNode.data.relation?.loopResultId,
  )
  if (!cloneResultNode) {
    throw new Error('条件节点数据复制有误')
  }

  config.edges = config.edges
    .filter(e => {
      if (isConditionNode(sourceNode) || isIntentNode(sourceNode)) {
        return e.id !== `${sourceHandleId}-${target}`
      }
      return !(
        e.source === source &&
        e.target === target &&
        e.type === 'insert'
      )
    })
    .map(e => {
      return e.source === cloneStartNode.id
        ? {
            ...e,
            source: newNodeId,
            sourceHandle: `${
              e.type === 'help' ? 'loopBoundary' : 'loopStart'
            }_${newNodeId}`,
            id: `${
              e.type === 'help' ? 'loopBoundary' : 'loopStart'
            }_${newNodeId}-${e.target}`,
          }
        : e
    })

  cloneData.nodes.forEach(n => {
    if (n.id === cloneStartNode.id) {
      // 粘贴插入时获取头节点的节点关系即可
      const relation = generateNodeRelation(
        nodes,
        edges,
        sourceNode,
        sourceHandleId,
      )
      const newStartNode = {
        ...n,
        id: newNodeId,
        data: {
          ...n.data,
          name: generateNodeName(nodes, n.type as NodeType),
          relation: {
            ...n.data?.relation,
            ...relation,
          },
        },
      }
      config.nodes.push(newStartNode)
      config.edges.push(
        generateEdgeFromNodes({
          source,
          target: newStartNode,
          sourceHandle: sourceHandleId,
        }),
      )
      return
    }
    if (n.id === cloneResultNode.id) {
      const newResultNode = {
        ...n,
        data: {
          ...n.data,
          name: generateNodeName(nodes, n.type as NodeType),
          relation: {
            ...n.data.relation,
            loopNodeId: newNodeId,
          },
        },
      }
      const newResultEdge = generateEdgeFromNodes({
        source: newResultNode,
        target,
      })

      nextSourceHandleId = newResultEdge.sourceHandle
      nextSourceId = newResultNode.id

      config.nodes.push(newResultNode)
      config.edges.push(newResultEdge)
      return
    }
    config.nodes.push({
      ...n,
      data: {
        ...n.data,
        name: generateNodeName(nodes, n.type as NodeType),
        relation: {
          ...n.data.relation,
          loopNodeId:
            n.data.relation?.loopNodeId === cloneStartNode.id
              ? newNodeId
              : n.data.relation?.loopNodeId,
        },
      },
    })
  })

  return {
    ...config,
    nextSourceHandleId,
    nextSourceId,
  }
}

export function pasteNodesFromLocalData(options: {
  nodes: Node[]
  edges: Edge[]
  source: string
  target: string
  sourceHandleId?: string
}) {
  const { nodes, edges, source, target, sourceHandleId } = options

  let copyList: copyConfig[] = []
  try {
    copyList = JSON.parse(localStorage.getItem(FLOW_CLIPBOARD) || '[]')
  } catch (error) {
    // err
  }
  if (!Array.isArray(copyList)) {
    throw new TypeError('复制数据格式错误')
  }
  console.log('要粘贴的数据: ', copyList)
  let resultEdges = cloneDeep(edges)
  let resultNodes = cloneDeep(nodes)
  let nextSourceId = source
  let nextSourceHandleId = sourceHandleId

  copyList.forEach(item => {
    const withCloneSignNodeList = item.nodes.filter(n =>
      n.id.includes(TO_BE_COPIED_ID),
    )
    if (withCloneSignNodeList.length <= 0 || withCloneSignNodeList.length > 1) {
      console.error('复制节点数据不正确')
      return
    }
    // 仅有一个带有复制标记的头节点
    const targetCloneNode = withCloneSignNodeList[0]
    if (!targetCloneNode) {
      console.error('未找到复制头节点')
      return
    }
    console.log('粘贴节点类型: ', targetCloneNode.type)
    const operation = getOperationByNodeType(targetCloneNode.type as NodeType)
    if (operation instanceof SingleNodeOperation) {
      const {
        nodes: pNodes,
        edges: pEdges,
        ...rest
      } = pasteSingleNode({
        nodes: resultNodes,
        edges: resultEdges,
        source: nextSourceId,
        target,
        sourceHandleId: nextSourceHandleId,
        cloneNode: targetCloneNode,
      })
      resultNodes = pNodes
      resultEdges = pEdges
      nextSourceId = rest.nextSourceId!
      nextSourceHandleId = rest.nextSourceHandleId!
    } else if (operation instanceof MultiBranchNodeOperation) {
      const {
        nodes: pNodes,
        edges: pEdges,
        ...rest
      } = pasteMultiBranchNode({
        nodes: resultNodes,
        edges: resultEdges,
        source: nextSourceId,
        target,
        sourceHandleId: nextSourceHandleId,
        cloneData: item,
        cloneStartNode: targetCloneNode,
      })
      resultNodes = pNodes
      resultEdges = pEdges
      nextSourceId = rest.nextSourceId!
      nextSourceHandleId = rest.nextSourceHandleId!
    } else if (operation instanceof LoopNodeOperation) {
      const {
        nodes: pNodes,
        edges: pEdges,
        ...rest
      } = pasteLoopNode({
        nodes: resultNodes,
        edges: resultEdges,
        source: nextSourceId,
        target,
        sourceHandleId: nextSourceHandleId,
        cloneData: item,
        cloneStartNode: targetCloneNode,
      })
      resultNodes = pNodes
      resultEdges = pEdges
      nextSourceId = rest.nextSourceId!
      nextSourceHandleId = rest.nextSourceHandleId!
    }
  })

  localStorage.setItem(FLOW_CLIPBOARD, '[]')

  return { resEdges: resultEdges, resNodes: resultNodes }
}
