import { useRequest } from 'ahooks'
import { useCallback } from 'react'
import OSS from 'ali-oss'
import type { STSTokenResponse } from '@apis/oss/type'
import { getSTSToken } from '@apis/oss'

const LESS_THAN_ONE_HOUR = 3500000

export function useStsToken() {
  const { data: stsTokenData = {} as STSTokenResponse, loading } = useRequest(
    () => getSTSToken(),
    {
      ready: true,
      pollingInterval: LESS_THAN_ONE_HOUR,
    },
  )

  const getImageUrlByToken = useCallback(
    (address: string) => {
      const regexp = /^(http|https|blob):/
      if (regexp.test(address)) {
        return address
      }
      const ossClient = new OSS({
        region: 'oss-cn-hangzhou',
        accessKeyId: stsTokenData!.access_key_id,
        accessKeySecret: stsTokenData!.access_key_secret,
        stsToken: stsTokenData!.security_token,
        bucket: import.meta.env.VITE_AI_DATASTORE_OSS_BUCKET,
      })
      const url = ossClient.signatureUrl(decodeURIComponent(address), {})
      return url
    },
    [stsTokenData.access_key_id],
  )

  return { getImageUrlByToken, stsTokenData, loading }
}
