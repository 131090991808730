import type { ReactNode } from 'react'
import { memo, useMemo, useState } from 'react'
import { Popover, Tooltip } from 'antd'
import { useMemoizedFn } from 'ahooks'
import { QuestionCircleOutlined } from '@ant-design/icons'
import type { TooltipPlacement } from 'antd/es/tooltip'
import type { LLMChannels } from '@apis/llm/model'
import { IconFont, Select, SliderSingle } from '@/components'
import { ModelSelect } from '@/features/nodes/components'
import { checkedJsonModel } from '@/features/nodes/utils/llm'

import { outputOptions } from '../const'
import { useLLMStore } from '@/store/llm'
import type { ModalSettingValue } from './type'

export interface ModalSettingProps {
  title?: string
  disabled?: boolean
  disabledOpen?: boolean
  onlyModelConfig?: boolean
  placement?: TooltipPlacement
  value?: ModalSettingValue
  width?: number
  onChange?: (value: ModalSettingValue) => void
  getPopupContainer?: () => HTMLElement
  children?: ReactNode
}

const Tip = memo(({ tip }: { tip: ReactNode }) => {
  return (
    <Tooltip title={tip}>
      <QuestionCircleOutlined className='cursor-pointer ml-4px text-12px text-[rgba(141,141,153,0.4)]' />
    </Tooltip>
  )
})

const defaultValue = {
  temperature: 0.7,
  top_p: 0.6,
  presence_penalty: 0,
  frequency_penalty: 0,
  outputType: 'text',
} as const

export const ModelSetting = memo((props: ModalSettingProps) => {
  const {
    title,
    disabled,
    disabledOpen,
    onlyModelConfig,
    placement,
    value,
    width,

    onChange,
    getPopupContainer,
    children,
  } = props

  const { llmModelList } = useLLMStore()

  const [open, setOpen] = useState(false)

  const handleClose = useMemoizedFn(() => {
    setOpen(false)
  })

  const handleChange = useMemoizedFn(
    (newValue: any, key: keyof ModalSettingValue) => {
      onChange?.({
        ...(value ?? ({} as any)),
        [key]: newValue,
      })
    },
  )

  const handleChangeModal = useMemoizedFn(
    (newValue: { channel: LLMChannels; model: string }) => {
      const newConfig = {
        ...defaultValue,
        ...(value ?? {}),
        ...newValue,
      }

      const modelConfig = llmModelList.find(
        each => each.model === newValue.model,
      )

      const limit = modelConfig?.feature?.param_config

      if (limit) {
        newConfig.presence_penalty = Math.max(
          limit.min ?? -2,
          Math.min(limit.max ?? 2, newConfig.presence_penalty ?? 0),
        )
      }

      onChange?.(newConfig)
    },
  )

  const canJson = useMemo(() => checkedJsonModel(value?.model!), [value?.model])

  const modelDetail = useMemo(() => {
    return llmModelList.find(each => each.model === value?.model)
  }, [llmModelList, value?.model])

  const defaultConfig = useMemo(() => {
    return {
      presencePenalty: modelDetail?.feature?.param_config ?? {},
    }
  }, [modelDetail?.feature])

  const content = useMemo(() => {
    const feature = modelDetail?.feature?.field_support ?? []

    return (
      <div style={{ width: width ?? 566 }} onClick={e => e.stopPropagation()}>
        <div
          className='h-46px px-14px text-16px flex flex-center'
          style={{
            borderBottom: '1px solid rgba(225, 225, 229, 0.6)',
          }}
        >
          <span className='mr-auto'>{title || '模型设置'}</span>
          <IconFont
            className='rounded-4px p-4px m-[-4px] box-content hover:bg-bg_3 hover:bg-op-8 cursor-pointer'
            name='guanbi'
            onClick={handleClose}
          />
        </div>
        <div className='p-20px flex flex-col gap-20px'>
          {!onlyModelConfig && (
            <div className='flex flex-center h-36px'>
              <span className='w-120px flex-none'>模型</span>
              <div className='flex-1 h-36px'>
                <ModelSelect
                  disabled={disabled}
                  className='h-36px! [&_.ant-select-selector]:h-36px! [&_.ant-select-selection-placeholder]:text-#17171D [&_.ant-select-selection-item]:text-14px!'
                  value={
                    value?.model
                      ? {
                          channel: value?.channel as LLMChannels,
                          model: value?.model || '',
                        }
                      : undefined
                  }
                  placeholder='选择模型'
                  onChange={handleChangeModal}
                />
              </div>
            </div>
          )}

          {feature.includes('temperature') && (
            <div className='flex flex-center h-36px'>
              <div className='w-120px flex-none'>
                <span>生成随机性</span>
                <Tip tip='temperature：调高数值模型的输出更多样性和创新性，反之，更加遵循提示词要求（建议不要和“Top P”同时调整）' />
              </div>
              <SliderSingle
                value={value?.temperature ?? 0.7}
                onChange={val => handleChange(val, 'temperature')}
                className='flex-1 h-36px!'
                min={0.1}
                max={1}
                step={0.1}
                disabled={disabled}
              />
            </div>
          )}

          {feature.includes('topP') && (
            <div className='flex flex-center h-36px'>
              <div className='w-120px flex-none'>
                <span>Top P</span>
                <Tip tip='Top P 累计概率：限制模型只选择高概率发生的词汇，从而控制输出的多样性（建议不要和“生成随机性”同时调整）' />
              </div>
              <SliderSingle
                value={value?.top_p ?? 0.6}
                onChange={val => handleChange(val, 'top_p')}
                className='flex-1 h-36px!'
                min={0.1}
                max={0.9}
                step={0.1}
                disabled={disabled}
              />
            </div>
          )}

          {feature.includes('presencePenalty') && (
            <div className='flex flex-center h-36px'>
              <div className='w-120px flex-none'>
                <span>重复语句惩罚</span>
                <Tip tip='frequency penalty：数值为正时，会阻止模型频繁使用相同的词汇和短语' />
              </div>
              <SliderSingle
                value={
                  value?.presence_penalty ??
                  defaultConfig.presencePenalty?.default ??
                  0.0
                }
                onChange={val => handleChange(val, 'presence_penalty')}
                className='flex-1 h-36px!'
                min={defaultConfig.presencePenalty?.min ?? -2.0}
                max={defaultConfig.presencePenalty?.max ?? 2.0}
                step={0.1}
                disabled={disabled}
              />
            </div>
          )}

          {feature.includes('frequencyPenalty') && (
            <div className='flex flex-center h-36px'>
              <div className='w-120px flex-none'>
                <span>重复主题惩罚</span>
                <Tip tip='presence penalty：数值为正时，会阻止模型频繁讨论相同的主题' />
              </div>
              <SliderSingle
                value={value?.frequency_penalty ?? 0.0}
                onChange={val => handleChange(val, 'frequency_penalty')}
                className='flex-1 h-36px!'
                min={-2.0}
                max={2.0}
                step={0.1}
                disabled={disabled}
              />
            </div>
          )}

          {!onlyModelConfig && feature.includes('responseFormat') && (
            <div className='flex flex-center h-36px'>
              <div className='w-120px flex-none'>
                <span>输出格式</span>
                <Tip
                  tip={
                    <div
                      style={{ whiteSpace: 'pre-wrap', lineHeight: 'inherit' }}
                    >
                      文本：使用普通文本格式回复
                      <br />
                      JSON：将引导模型使用JSON格式输出
                    </div>
                  }
                />
              </div>
              <Select
                defaultValue='text'
                value={value?.outputType}
                onChange={val => handleChange(val, 'outputType')}
                className='flex-1 h-36px'
                options={outputOptions}
                disabled={disabled}
              />
            </div>
          )}
        </div>
      </div>
    )
  }, [width, onlyModelConfig, canJson, value, disabled, defaultConfig])

  return (
    <Popover
      overlayClassName='p-0px'
      overlayInnerStyle={{
        padding: 0,
        border: '1px solid rgba(141, 141, 153, 0.08)',
      }}
      trigger='click'
      placement={placement || 'bottomRight'}
      content={content}
      arrow={false}
      open={open}
      onOpenChange={setOpen}
      getPopupContainer={getPopupContainer}
    >
      {children || (
        <div
          className='min-w-190px max-w-240px h-32px px-8px flex-none flex items-center bg-#F6F6F9 text-#17171D rounded-6px cursor-pointer b-1px border-[rgba(225,225,229,0.8)] hover:border-#7B67EE'
          style={{
            borderColor: open ? '#7B67EE' : '',
            pointerEvents: disabledOpen ? 'none' : 'auto',
            opacity: disabledOpen ? '.6' : '1',
          }}
        >
          <IconFont className='text-14px mr-6px' name='muxing' />
          <span className='flex-auto text-12px'>
            {value?.model || '选择模型'}
          </span>
          <IconFont className='text-14px ml-6px' name='muxingpeizhi' />
        </div>
      )}
    </Popover>
  )
})
