import axios from 'axios'
import { useState, useRef } from 'react'
import { Tooltip, Popover, message } from 'antd'
import { keyuXcAuth } from '@apis/plugins'
import { getUser } from '@apis/user'
import { IconFont, Input, TextArea, Button } from '@/components'
import { PLUGIN_DISPLAYNAME } from '@/constants/common'

export function Header(props: { handleSearch: (query: string) => void }) {
  const { handleSearch } = props
  const [requirement, setRequirement] = useState('')
  const [popoverOpen, setPopoverOpen] = useState(false)
  const header = useRef<HTMLDivElement>(null)

  const sendRequirement = async () => {
    try {
      const result = await getUser()
      const { username, phone } = result
      await axios.post(
        'https://salyut2.bantouyan.com/api/v1/db/data/v1/j4zu-t652q-p39wvisrg92sgjo1982h8mv58/requirements',
        { username, phone, desc: requirement },
        {
          headers: {
            'xc-auth': keyuXcAuth,
          },
        },
      )
      message.success('提交成功')
      setPopoverOpen(false)
    } catch (e) {
      message.error('提交失败，请重试')
    }
  }

  return (
    <div className='flex items-baseline justify-between pb-24px b-b-line b-op-40'>
      <div className='flex items-baseline'>
        <p className='whitespace-nowrap text-20px line-height-36px c-#17171D font-600'>
          {PLUGIN_DISPLAYNAME}
        </p>
        <Tooltip title='作为技能被Agent调用，执行特定任务' placement='top'>
          <IconFont
            name='jieshishuimeng'
            className='c-[rgba(141,141,153,0.4)] line-height-32px ml-3px cursor-pointer text-14px'
          />
        </Tooltip>
      </div>
      <div ref={header} className='flex items-center'>
        <Input
          prefix={<IconFont name='search' className='text-16px' />}
          className='flex-1 w-400px'
          placeholder='搜索插件'
          size='middle'
          onChange={e => handleSearch(e.target.value)}
          allowClear
        />
        <span className='mx-12 h-20px border-left self-center after:border-[rgba(225,225,229,0.6)]' />
        <Popover
          trigger='click'
          open={popoverOpen}
          placement='bottomRight'
          getPopupContainer={() => header.current as HTMLDivElement}
          content={
            <div className='p-12px box-border bg-[#ffffff] w-480px round-8px shadow-[0px_12px_32px_0px_rgba(153, 162, 176, 0.24)]'>
              <div className='h-20px flex justify-between items-center'>
                <p className='text-18px font-500 line-height-500 color-[#17171D]'>
                  插件需求
                </p>
                <IconFont
                  onClick={() => setPopoverOpen(false)}
                  name='guanbi'
                  className='cursor-pointer text-16px'
                />
              </div>
              <TextArea
                value={requirement}
                onChange={e => setRequirement(e.target.value.trim())}
                placeholder='请详细描述插件需求'
                className='mt-24px h-[304px]! resize-none!'
              />
              <div className='flex mt-24px justify-end'>
                <Button
                  onClick={sendRequirement}
                  disabled={requirement.length <= 0}
                  type='primary'
                >
                  提交
                </Button>
              </div>
            </div>
          }
        >
          <Button
            className='w-124px rounded-8px flex cursor-pointer items-center gap-6'
            icon={<IconFont name='shiruqiu' className='text-16px text-font' />}
            onClick={() => setPopoverOpen(!popoverOpen)}
          >
            <span className='select-none text-14px'>提插件需求</span>
          </Button>
        </Popover>
      </div>
    </div>
  )
}
