import type { ColumnType } from '@bty/smartsheet'
import { UITypes } from '@bty/smartsheet'
import { generalTableColumnDesc, generalTableDesc } from '@apis/database'

interface TableFormData {
  title: string
  description?: string
  columns?: ColumnType[]
}

export function checkTableHasDescription(
  data: TableFormData | TableFormData[],
): boolean {
  if (Array.isArray(data)) {
    // case when data is an array
    return data.some(item => checkTableHasDescription(item))
  } else {
    // case when data is a single object
    return !!(
      data.description?.trim() ||
      data.columns?.some(
        column => !!column.description && column.description.trim(),
      )
    )
  }
}

interface GeneralTableDescEvents {
  onDescGeneral?: (desc: string) => void
  onDescGeneralStart?: () => void
  onColumnDescGeneral?: (desc: string, index: number) => void
  onColumnDescGeneralStart?: (index: number) => void
}

export function generalTableDescription(
  data: TableFormData,
  events: GeneralTableDescEvents,
): Promise<any> {
  const generalDescAction = new Promise((resolve, reject) => {
    generalTableDesc(data.title, {
      onMessage: event => {
        event.column_description &&
          events.onDescGeneral?.(event.column_description)
        if (event.finishReason === 'stop') {
          resolve(true)
        }
      },
      onError: reject,
      onOpen: () => events.onDescGeneralStart?.(),
      onClose: () => resolve(true),
    })
  })

  const generalColumnActions =
    data.columns
      ?.filter(item => item.column_name.trim())
      .map(
        (column, index) =>
          new Promise((resolve, reject) => {
            generalTableColumnDesc(
              {
                column_name: column.column_name,
                column_type: column.uidt ?? UITypes.SingleLineText,
                is_required: !!column.rqd,
              },
              {
                onOpen: () => events.onColumnDescGeneralStart?.(index),
                onClose: () => resolve(true),
                onError: reject,
                onMessage: event => {
                  event.column_description &&
                    events.onColumnDescGeneral?.(
                      event.column_description,
                      index,
                    )
                  if (event.finishReason === 'stop') {
                    resolve(true)
                  }
                },
              },
            )
          }),
      ) ?? []

  return Promise.all([generalDescAction, ...generalColumnActions])
}
