import type { TableType } from '../database/smartsheet'
import type { Database } from '../database/type'
import type { DataStoreItem, DocumentItem } from '../datastore/type'
import type { ISubFlowItem } from '../flow/type'

export interface IKnowledge extends DataStoreItem {
  tags: string[]
  file_info: DocumentItem[]
}

export interface IDatabase extends Database {
  tables: TableType[]
}

export interface IIntergrationResource {
  database: {
    list: IDatabase[]
  }
  knowledge: IKnowledge[]
  plugin_template: ISubFlowItem[]
}

export interface IInstalledPackages {
  name: string
  version: string
  document: string
  github: string
}

export const labels = [
  { label: '场景示例', key: 'SCENARIO_EXAMPLE' },
  {
    label: '内置功能',
    key: 'BUILDIN_FEATURES',
  },
] as const

export type labelKeys = (typeof labels)[number]['key']

export type LabelCodeList = {
  [key in labelKeys]: CodeExampleType[]
}

export interface CodeExampleType {
  title: string
  code?: string
  children?: CodeExampleType[]
}

export interface CodeExampleListType {
  javascript: LabelCodeList
  python: LabelCodeList
}
