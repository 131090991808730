import { request } from '../../client'
import type { ISingleStepReq } from './type'

export async function runBySingleStepApi(
  params: ISingleStepReq,
): Promise<{ data: any }> {
  const result = await request.post<any>(
    '/v1/action/execute',
    {
      ...params,
      run_env: 'STEP_RUN',
    },
    {
      timeout: 1000 * 300,
    },
  )
  return result
}

export async function fetchNodePanelVariablesApi(params: ISingleStepReq) {
  const result = await request.get<any>(
    `/v1/action/variable/flow/${params.flow_id}/node/${params.node_id}`,
  )
  return result?.variable_list || {}
}

export async function updateNodePanelVariablesApi(
  params: ISingleStepReq & {
    variable_list?: Record<string, any>
  },
) {
  const result = await request.post<any>('/v1/action/variable/upsert', params)
  return result
}

export async function deleteNodePanelVariablesApi(params: ISingleStepReq) {
  const result = await request.delete<any>(
    `/v1/action/variable/flow/${params.flow_id}/node/${params.node_id}`,
  )
  return result
}

export async function fetchPythonCodeVariablesApi(params: ISingleStepReq) {
  const result = await request.get<any>(
    `/v1/action/variable/extract/flow/${params.flow_id}/node/${params.node_id}`,
  )
  return result
}
