import { BrowserRouter } from 'react-router-dom'
import classNames from 'classnames'
import { useMemo, useRef, useState } from 'react'
import { Divider } from 'antd'
import { useDebounceFn, useHover } from 'ahooks'
import type { PartitionCategoryType, DataStoreItem } from '@apis/datastore/type'
import {
  Modal,
  IconFont,
  usePageModal,
  Button,
  AppLogo,
  Input,
} from '@/components'
import { useWorkspaceStore } from '@/store'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import { getPartitionTypeName } from '../util'
import Empty from '@/features/database/components/Empty.tsx'
import { CreateDocumentsButton } from '@/features/datastore/components/CreateDocumentsButton'

interface DatasetSelectModalProps {
  open?: boolean
  createLoading?: boolean
  datasetList: DataStoreItem[]
  currentDataset?: number[]
  onChange?: (
    type: 'add' | 'delete',
    params: {
      partition_category?: PartitionCategoryType
      partition_id: number
      [key: string]: any
    },
  ) => void
  onCreate: (type: PartitionCategoryType) => void
  onCancel?: () => void
}

function ActionButton(props: { isSelected: boolean; onClick: () => void }) {
  const { isSelected, onClick } = props
  const [loading, setLoading] = useState(false)
  const btnWrapper = useRef<HTMLDivElement>(null)
  const isHovering = useHover(btnWrapper)

  const { run: debounceClickFn } = useDebounceFn(
    async () => {
      try {
        setLoading(true)
        await onClick?.()
      } finally {
        setLoading(false)
      }
    },
    {
      wait: 100,
    },
  )
  return (
    <div
      ref={btnWrapper}
      onClick={async () => {
        debounceClickFn()
      }}
    >
      {isSelected ? (
        <Button
          loading={loading}
          type='primary'
          className={classNames('w-80px! text-14px', {
            'bg-[rgba(98,105,153,0.08)]!': isSelected && !isHovering,
            'c-#8D8D99!': isSelected && !isHovering,
            'bg-error!': isSelected && isHovering,
          })}
          size='middle'
        >
          {isHovering ? '移除' : '已添加'}
        </Button>
      ) : (
        <Button type='primary' className='w-80px! text-14px' size='middle'>
          添加
        </Button>
      )}
    </div>
  )
}

export default function DatasetSelectModal(props: DatasetSelectModalProps) {
  const {
    open,
    datasetList,
    currentDataset = [],
    onChange,
    onCancel,
    onCreate,
  } = props
  const pageModal = usePageModal()
  const [selectedList, setSelectedList] = useState(currentDataset)
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const [searchInfo, setSearchInfo] = useState({
    partition_name: '',
  })

  const onSearchInfoChange = (params: Record<string, any>) => {
    setSearchInfo({
      ...searchInfo,
      ...params,
    })
  }

  const dataSource = useMemo(() => {
    const { partition_name } = searchInfo
    if (partition_name) {
      return datasetList.filter(item => {
        return item?.partition_name?.includes(partition_name)
      })
    }
    return datasetList
  }, [datasetList, searchInfo, selectedList])

  const handleCreate = (actionType: any) => {
    onCreate(actionType)
  }

  const { scrollRef } = useScrollBar()

  return (
    <Modal
      styles={{
        body: {
          padding: '24px 24px 0px',
        },
      }}
      footer={null}
      width={1120}
      title='选择知识库'
      open={open}
      onCancel={() => onCancel?.()}
    >
      <div className='flex flex-col'>
        <div className='flex items-center'>
          <Input
            className='w-240px important:text-14px'
            prefix={<IconFont name='search' />}
            placeholder='搜索知识库名称'
            onChange={event => {
              const searchKey = event.target.value
              onSearchInfoChange({ partition_name: searchKey })
            }}
          />
          <Divider type='vertical' className='mx-16 h-26px' />
          <BrowserRouter>
            <CreateDocumentsButton
              className='text-14px'
              size='middle'
              onCreate={handleCreate}
            />
          </BrowserRouter>
        </div>
        <div
          ref={scrollRef}
          className='mt-12px h-524px max-h-66vh overflow-y-scroll pb-20px'
        >
          <div className='flex flex-col'>
            {dataSource.length ? (
              dataSource.map(dataset => {
                const isSelected = selectedList?.includes(dataset.partition_id)
                const partitionTypeName = getPartitionTypeName(
                  dataset?.partition_category!,
                )
                return (
                  <div
                    className={classNames(
                      'h-72px flex-center-between p-16 b-0 rounded-[8px,8px,0,0] b-b-1 b-#E1E1E5 b-op-40 cursor-pointer hover:bg-bg_3 hover:bg-op-8 group',
                    )}
                    key={dataset.partition_id}
                  >
                    <div className='flex flex-1 items-center'>
                      <div className='flex items-center flex-1'>
                        <AppLogo
                          size={40}
                          fillSize={18}
                          className='!rounded-4px'
                          value={dataset.partition_icon}
                          color={dataset.partition_icon_color}
                          type='icon'
                        />
                        <div className='ml-12px flex flex-col flex-1 justify-center w-400px'>
                          <p className=' truncate overflow-hidden font-500 flex items-center'>
                            <span className='c-#17171D text-14px/16px mr-8px'>
                              {dataset?.partition_name}
                            </span>
                            <span className='b-1  b-solid b-#E1E1E5 rounded-4px text-11px/11px px-4px py-2px c-font_1 font-400!'>
                              {partitionTypeName}
                            </span>
                          </p>
                          <p className='mt-6px text-font_1 font-400 text-12px/16px'>
                            {dataset?.file_num} {partitionTypeName}
                          </p>
                        </div>
                      </div>
                      <span
                        className='link group-hover:op-100 op-0 text-16px ml-8 mr-16px op-0 cursor-pointer rounded-4px w-32px h-32px hover:bg-bg_3 hover:bg-op-12 c-#626999 c-op-60 flex-center'
                        onClick={() =>
                          pageModal.show({
                            url: `/datastores/${currentWorkspaceId}/${dataset.partition_id}/documents?independent=1`,
                          })
                        }
                      >
                        <IconFont name='super-link' />
                      </span>
                    </div>
                    <ActionButton
                      isSelected={isSelected}
                      onClick={async () => {
                        await onChange?.(isSelected ? 'delete' : 'add', {
                          partition_id: dataset.partition_id,
                          partition_category: dataset.partition_category,
                        })
                        setSelectedList(() => {
                          if (isSelected) {
                            return selectedList.filter(item => {
                              return item !== dataset.partition_id
                            })
                          } else {
                            return selectedList.concat([dataset.partition_id])
                          }
                        })
                      }}
                    />
                  </div>
                )
              })
            ) : (
              <div>
                <Empty desc='搜索结果为空' />
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  )
}
