import { request } from '../../client'
import type {
  PermissionNotification,
  ApplyType,
  ResultOfApply,
  ApprovalStatusValue,
} from './type'

export function fetchPermissionNotifications() {
  return request.get<PermissionNotification[]>('/v1/permission_apply', {
    query: {
      page: 1,
      page_size: 999,
    },
  })
}

export function applyForPermission(
  applicationId: string,
  workspaceId: string,
  type: ApplyType,
) {
  return request.post('/v1/permission_apply', {
    applicationId,
    workspaceId,
    type,
  })
}

export function processApproval(id: string, status: ApprovalStatusValue) {
  return request.put('/v1/permission_apply', {
    id,
    status,
  })
}

export function queryResultOfApply(applicationId: string, workspaceId: string) {
  return request.get<{
    status: (typeof ResultOfApply)[keyof typeof ResultOfApply]
    id: number
    approve_user_id: number
    approve_user_name: string
  }>('/v1/permission_apply_status', {
    query: {
      applicationId,
      workspaceId,
    },
  })
}
