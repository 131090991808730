import { Form } from 'antd'
import CryptoJS from 'crypto-js'
import { PUBLISH_MODE } from '@bty/chat-types'
import { enableApp, queryApp, updateApp, createApp } from '@apis/agent-go'
import type { AuthAppEnterpriseWebChat } from '@apis/agent-go/type'
import { useQueryAgentGoApp } from '@/features/agent/hooks/useQueryAgentGoApp.ts'
import type { AgentGoPublishConfProps } from '@/features/agent/components/AgentGoPublishConf.tsx'
import { AgentGoPublishConf } from '@/features/agent/components/AgentGoPublishConf.tsx'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider.tsx'
import { WECHAT_ENTERPRISE_DOCS } from '@/features/agent/AgentGo/common.tsx'
import { LinkText } from '@/features/agent/components/LinkText.tsx'
import { Input } from '@/components'
import { CopyBox } from '@/features/agent/components/CopyBox.tsx'
import renderLabel from '@/features/agent/components/renderLabel.tsx'
import { SERVER_IP_ADDRESSES } from '@/constants/common.ts'
import { AgentGoFormItem } from '@/features/agent/components/AgentGoFormItem.tsx'

interface AgentGoEnterpriseWeChatProps {
  mode?: string
  modeIcon?: string
  onBindSuccess?: () => void
  onUnBindSuccess?: () => void
}

interface AgentGoEnterpriseWeChatRequest {
  Token: string
  CorpID: string
  Secret: string
  AgentId: string
  EncodingAESKey: string
  auth_app_name: string
}

export default function AgentGoEnterpriseWeChat(
  props: AgentGoEnterpriseWeChatProps,
) {
  const { mode, modeIcon, onUnBindSuccess, onBindSuccess } = props
  const { applicationInfo } = useAgentEdit()
  const [form] = Form.useForm<AgentGoEnterpriseWeChatRequest>()

  const { app, loading, setApp, runQueryApp } =
    useQueryAgentGoApp<AuthAppEnterpriseWebChat>({
      agentId: applicationInfo?.flowId,
      source: PUBLISH_MODE.wechat_enterprise,
      onQuerySuccess: data => {
        const { auth_app_name, auth_app_config } = data
        form.setFieldsValue({
          auth_app_name,
          ...auth_app_config,
        })
      },
    })

  const handleComplete: NonNullable<
    AgentGoPublishConfProps['onComplete']
  > = appId => {
    onBindSuccess?.()
    if (appId && applicationInfo) {
      runQueryApp(applicationInfo.flowId)
    }
  }

  const handleUnBindSuccess = async () => {
    setApp({})
    onUnBindSuccess?.()
  }

  const handleValuesChange: NonNullable<
    AgentGoPublishConfProps['onValuesChange']
  > = changedValues => {
    if ('is_enable' in changedValues) {
      setApp({ ...app, is_enable: changedValues.is_enable })
      enableApp(app.auth_app_id!, changedValues.is_enable)
    }
  }

  const handleStepChange: NonNullable<
    AgentGoPublishConfProps['onStepChange']
  > = async step => {
    if (step !== 1) {
      return
    }
    if (app.auth_app_id) {
      const { auth_app_name, ...values } = form.getFieldsValue()
      const data = await updateApp({
        auth_app_id: app.auth_app_id,
        auth_app_name,
        auth_app_config: values,
      })
      setApp(data as unknown as AuthAppEnterpriseWebChat)
      return
    }
    // 微信需要第二步完成之后创建 app，点击完成的时候其实是修改应用了
    if (applicationInfo) {
      const { auth_app_name, ...values } = form.getFieldsValue()
      const data = await createApp({
        agent_id: applicationInfo.flowId,
        auth_app_name,
        auth_app_source: PUBLISH_MODE.wechat_enterprise,
        auth_app_config: Object.assign(values),
      })
      setApp(data as unknown as AuthAppEnterpriseWebChat)
    }
  }

  const handleCorpIdBlur = async (e: React.FocusEvent<HTMLInputElement>) => {
    const value = e.target.value
    if (!value) {
      console.warn('Generate Token & EncodingAESKey Error, Not App Id')
      return
    }
    if (applicationInfo) {
      const value = await queryApp(
        PUBLISH_MODE.wechat_enterprise,
        applicationInfo.flowId,
      )
      const config = value?.auth_app_config
        ? value.auth_app_config
        : {
            Token: CryptoJS.lib.WordArray.random(16).toString(),
            EncodingAESKey: CryptoJS.lib.WordArray.random(22)
              .toString()
              .slice(0, 43),
          }
      form.setFieldsValue(config)
    }
  }

  const steps = [
    {
      title: '填写应用信息',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium text-#17171d mb-24'>
            登录{' '}
            <LinkText href='https://work.weixin.qq.com/'>
              企业微信管理后台
            </LinkText>{' '}
            获取以下参数
          </div>
        </>
      ),
    },
    {
      title: '设置API接收信息',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium text-#17171d mb-24'>
            复制信息，去{' '}
            <LinkText href='https://work.weixin.qq.com/'>
              企业微信管理后台
            </LinkText>{' '}
            设置API接收消息
          </div>
        </>
      ),
    },
    {
      title: '配置企业可信IP',
      disabled: true,
      content: (
        <>
          <div className='text-16px font-medium text-#17171d mb-24'>
            去{' '}
            <LinkText href='https://work.weixin.qq.com/'>
              企业微信管理后台
            </LinkText>{' '}
            应用中配置企业可信IP
          </div>
          <AgentGoFormItem label={renderLabel('IP地址', false)}>
            <CopyBox value={SERVER_IP_ADDRESSES.join(';\n')}>
              {SERVER_IP_ADDRESSES.map(item => (
                <div key={item} className='line-height-20px'>
                  {item};
                </div>
              ))}
            </CopyBox>
          </AgentGoFormItem>
        </>
      ),
    },
  ]

  return loading ? null : (
    <AgentGoPublishConf
      form={form}
      mode={mode}
      app={app}
      steps={steps}
      guides={WECHAT_ENTERPRISE_DOCS}
      serviceAvatar={modeIcon}
      ignoreCompleteRequest
      onStepChange={handleStepChange}
      onUnBindSuccess={handleUnBindSuccess}
      onValuesChange={handleValuesChange}
      onComplete={handleComplete}
    >
      {step => (
        <>
          {steps[step].content}
          <AgentGoFormItem
            required
            name='CorpID'
            label={renderLabel('企业ID', true)}
            hidden={step !== 0}
            normalize={value => value?.trim()}
            rules={[{ required: true, message: '企业ID 不能为空' }]}
          >
            <Input
              placeholder='在我的企业中获取信息'
              spellCheck={false}
              onBlur={handleCorpIdBlur}
            />
          </AgentGoFormItem>
          <AgentGoFormItem
            required
            label={renderLabel('企微应用AgentID', true)}
            name='AgentId'
            hidden={step !== 0}
            normalize={value => value?.trim()}
            rules={[{ required: true, message: 'AgentId 不能为空' }]}
          >
            <Input placeholder='在应用详情中获取信息' spellCheck={false} />
          </AgentGoFormItem>
          <AgentGoFormItem
            required
            label={renderLabel('企微应用Secret', true)}
            name='Secret'
            hidden={step !== 0}
            normalize={value => value?.trim()}
            rules={[{ required: true, message: 'Secret 不能为空' }]}
          >
            <Input placeholder='在应用详情中获取信息' spellCheck={false} />
          </AgentGoFormItem>
          <AgentGoFormItem
            required
            label={renderLabel('应用名称', true)}
            name='auth_app_name'
            hidden={step !== 0}
            normalize={value => value?.trim()}
            rules={[{ required: true, message: '应用名称 不能为空' }]}
          >
            <Input placeholder='在应用详情中获取信息' spellCheck={false} />
          </AgentGoFormItem>
          <AgentGoFormItem
            label={renderLabel('URL', false)}
            dependencies={['CorpID', 'AgentId']}
            hidden={step !== 1}
          >
            {form => {
              const CorpID = form.getFieldValue('CorpID')
              const AgentId = form.getFieldValue('AgentId')
              return (
                <CopyBox
                  // prettier-ignore
                  value={CorpID ? `${import.meta.env.VITE_AI_API_BASE_URL}/v1/webhook/agent/weixin_work_app/${CorpID}_${AgentId}/receive_message` : ''}
                />
              )
            }}
          </AgentGoFormItem>
          <AgentGoFormItem
            name='Token'
            label={renderLabel('Token', false)}
            hidden={step !== 1}
          >
            <CopyBox />
          </AgentGoFormItem>
          <AgentGoFormItem
            name='EncodingAESKey'
            label={renderLabel('EncodingAESKey', false)}
            hidden={step !== 1}
          >
            <CopyBox />
          </AgentGoFormItem>
        </>
      )}
    </AgentGoPublishConf>
  )
}
