import { Switch, Tooltip } from 'antd'
import { IconFont } from '@/components'
import { RankingStrategy } from '../../constants'

export default (props: { value?: number; onChange?: (v: number) => void }) => {
  const { value, onChange } = props
  return (
    <div className='flex items-center mb-16px'>
      <div className='font-500 text-12px'>结果重排</div>
      <Tooltip title='可将查询结果精准排序，提高匹配率，但耗时较长'>
        <IconFont
          className='ml-4px mr-8px'
          style={{ color: 'rgba(141, 141, 153, 0.4)' }}
          name='jieshishuimeng'
        />
      </Tooltip>
      <Switch
        size='small'
        checked={value === RankingStrategy.ON}
        onChange={e => {
          onChange?.(e ? RankingStrategy.ON : RankingStrategy.OFF)
        }}
      />
    </div>
  )
}
