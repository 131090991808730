import type { ReactNode } from 'react'
import { useState } from 'react'
import { useRequest } from 'ahooks'
import type { ColumnsType } from 'antd/es/table'
import dayjs from 'dayjs'
import classNames from 'classnames'
import styled from '@emotion/styled'
import { Form } from 'antd'
import { getSearchHistory } from '@apis/datastore'
import type { SearchHistoryItem } from '@apis/datastore/type'
import { IconFont, Table } from '@/components'
import { HitTestTextArea } from '@/pages/datastores/components/HitTestTextArea.tsx'
import { SearchForm } from './SearchForm'

interface BaseHitTestOperationPanelProps {
  headerActionSlot?: ReactNode
  hitTestLoading?: boolean
  disabled?: boolean
  onSearch?: (e?: Record<string, any>) => Promise<void>
  searchText?: string
  onSearchTextChange?: (text: string) => void
  className?: string
  showSearchForm?: boolean
}

interface FileHitTestOperationPanelProps
  extends BaseHitTestOperationPanelProps {
  file_id: number
}

interface DatasetHitTestOperationPanelProps
  extends BaseHitTestOperationPanelProps {
  partition_id: number
}

const StyledTable = styled(Table)`
  .ant-table-tbody .ant-table-row .ant-table-cell {
    padding: 16px 0;
  }
  .ant-table-header .ant-table-cell {
    padding: 13px 0;
  }
`

const DivWrapper = styled.div`
  border-radius: 6px;
  &:hover {
    border: 1px solid #7b61ff !important;
  }
`

const defaultPageSize = 10
export function HitTestOperationPanel(
  props: FileHitTestOperationPanelProps | DatasetHitTestOperationPanelProps,
) {
  const [form] = Form.useForm()
  const {
    headerActionSlot,
    hitTestLoading,
    disabled,
    searchText,
    onSearch,
    onSearchTextChange,
  } = props

  // 查询历史
  const {
    data: searchHistoryData,
    loading: historyLoading,
    runAsync: getSearchHistoryApi,
  } = useRequest(
    (page: number, pageSize: number) =>
      getSearchHistory({
        page,
        pageSize,
        file_ids: 'file_id' in props ? props.file_id : undefined,
        partition_id: 'partition_id' in props ? props.partition_id : undefined,
      }),
    {
      defaultParams: [1, defaultPageSize],
    },
  )
  const [historyPage, setHistoryPage] = useState(1)
  const searchHistory = searchHistoryData || { rows: [], total: 0 }
  const columns: ColumnsType<object> = [
    {
      title: '测试文本',
      key: 'content',
      ellipsis: true,
      dataIndex: 'content',
    },
    {
      title: '耗时',
      key: 'cost_time',
      ellipsis: true,
      dataIndex: 'cost_time',
      render: cost_time => {
        return <span>{cost_time}s</span>
      },
    },
    {
      title: '时间',
      key: 'update_time',
      dataIndex: 'update_time',
      render: time => dayjs(time).format('YYYY-MM-DD HH:mm'),
    },
  ]
  const [blur, setBlur] = useState(true)
  return (
    <div className={props.className}>
      <div className='relative'>
        <div className='text-16px flex-center-between mb-12 font-600 flex'>
          <span className='line-height-28px shrink-0'>测试文本</span>
          {!props.showSearchForm && headerActionSlot}
        </div>
        <DivWrapper
          style={
            blur
              ? { border: '1px solid transparent' }
              : { border: '1px solid #7B61FF' }
          }
        >
          {props.showSearchForm && (
            <SearchForm
              form={form}
              onChange={async () => {
                if (!searchText) return
                await onSearch?.(form.getFieldsValue() || {})
                getSearchHistoryApi(1, defaultPageSize)
              }}
            />
          )}
          <HitTestTextArea
            rows={5}
            maxLength={1000}
            value={searchText}
            variant='borderless'
            onBlur={() => setBlur(true)}
            onFocus={() => setBlur(false)}
            onChange={e => onSearchTextChange?.(e.target.value)}
            placeholder='请输入测试文本，按 shift + enter 换行。'
            onKeyDown={async e => {
              if (e.code === 'Enter' && !e.shiftKey) {
                await onSearch?.(form.getFieldsValue() || {})
                getSearchHistoryApi(1, defaultPageSize)
              }
            }}
            onTest={async () => {
              await onSearch?.(form.getFieldsValue() || {})
              getSearchHistoryApi(1, defaultPageSize)
            }}
            disabled={disabled}
            loading={hitTestLoading}
          />
        </DivWrapper>
      </div>
      <div className='adapt:pt-24 flex-1 flex flex-col justify-between'>
        <div>
          <p className='text-16px mb-12 font-600 text-16px'>最近查询</p>
          <StyledTable
            loading={historyLoading}
            dataSource={searchHistory.rows}
            rowKey='id'
            sticky={{
              offsetHeader: -24,
            }}
            pagination={false}
            columns={columns}
            onRow={record => {
              return {
                onClick: () => {
                  onSearchTextChange?.((record as SearchHistoryItem).content)
                },
              }
            }}
          />
        </div>
        <div className='flex items-center justify-between'>
          <div className='font-400 text-font_1 h-28px flex items-end'>
            总计{' '}
            <span className='font-600'>&nbsp;{searchHistory.total}&nbsp;</span>{' '}
            条数据
          </div>
          {searchHistory.total > 0 && (
            <div className='flex items-center'>
              <span
                className={classNames(
                  'mr-8 cursor-pointer text-9px text-font_1 flex items-center justify-center w-32px h-28px rounded-4px',
                  {
                    'text-opacity-40': historyPage === 1,
                    'hover:bg-bg_3 hover:bg-opacity-8': historyPage !== 1,
                  },
                )}
                onClick={() => {
                  if (historyPage === 1 || historyLoading) return
                  setHistoryPage(historyPage - 1)
                  getSearchHistoryApi(historyPage - 1, defaultPageSize)
                }}
              >
                <IconFont name='right-arrow' />
              </span>
              <span className='font-500 inline-block w-18px text-center'>
                {historyPage}
              </span>
              <span className='text-font_1 font-500'>/</span>
              <span className='font-500 text-font_1 inline-block w-18px text-center'>
                {Math.ceil(searchHistory.total / defaultPageSize)}
              </span>
              <span
                className={classNames(
                  'ml-8 cursor-pointer text-9px text-font_1 flex items-center justify-center w-28px h-28px rounded-4px',
                  {
                    'text-opacity-40':
                      historyPage ===
                      Math.ceil(searchHistory.total / defaultPageSize),
                    'hover:bg-bg_3 hover:bg-opacity-8':
                      historyPage !==
                      Math.ceil(searchHistory.total / defaultPageSize),
                  },
                )}
                onClick={() => {
                  if (
                    historyPage ===
                      Math.ceil(searchHistory.total / defaultPageSize) ||
                    historyLoading
                  )
                    return
                  setHistoryPage(historyPage + 1)
                  getSearchHistoryApi(historyPage + 1, defaultPageSize)
                }}
              >
                <IconFont name='left-arrow' />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
