import { memo, useEffect, useMemo, useRef, useState } from 'react'
import dayjs from 'dayjs'
import { useBoolean } from 'ahooks'
import { queryAppStability, queryIrlTrend } from '@apis/monitor'
import { StaticTimeTypes } from '@apis/monitor/type'
import { ChartDashboardPane } from '@/features/agent/AgentAnalysis/ChartDashboardPane'
import { useWorkspaceStore } from '@/store'
import type { ChartDashboardPaneRef } from '../../../../features/agent/AgentAnalysis/ChartDashboardPane'
import type { StatisticsFlowType } from '..'
import type { StabilityTimeParams } from './StabilityDetails'
import { StabilityDetailsModel } from './StabilityDetails'
import { ErrorOverTimeDetailModal } from './ErrorOverTimeDetailModa'

interface WorkspaceStabilityPaneProps {
  flowType: StatisticsFlowType
  runType: string[]
  openLogPage: (flowId: string) => void
}

function InternalWorkspaceStabilityPane({
  flowType,
  runType,
}: WorkspaceStabilityPaneProps) {
  const [
    stabilityDetailModalVisible,
    { setTrue: openStabilityDetailModal, setFalse: closeStabilityDetailModal },
  ] = useBoolean(false)

  const [
    errorTimeDetailVisible,
    { setTrue: openErrorTimeDetailModal, setFalse: closeErrorTimeDetailModal },
  ] = useBoolean(false)

  const [timeParams, setTimeParams] = useState<StabilityTimeParams>({
    type: StaticTimeTypes.CUSTOM,
    start_date: dayjs()
      .subtract(8, 'day')
      .startOf('d')
      .format('YYYY-MM-DD HH:mm:ss'),
    end_date: dayjs()
      .subtract(1, 'day')
      .endOf('d')
      .format('YYYY-MM-DD HH:mm:ss'),
  })

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const errorTableRef = useRef<ChartDashboardPaneRef>(null)

  useEffect(() => {
    errorTableRef.current?.refresh()
  }, [currentWorkspaceId])

  const params = useMemo(
    () => ({
      flow_type: flowType,
      run_type: runType,
      statistic_time: 'weekly',
      display_time: 'daily',
    }),
    [flowType, runType],
  )

  const onDetailClick = async (index: number) => {
    if (index === 0) {
      openStabilityDetailModal()
      return
    }
    if (index === 1) {
      openErrorTimeDetailModal()
    }
  }

  return (
    <>
      <ChartDashboardPane
        ref={errorTableRef}
        className='mt-24'
        type='area'
        xField='display_time'
        yField='value'
        title='应用稳定性评估'
        color='#FE9700'
        detail={{
          onClick: onDetailClick,
        }}
        filter={{
          onChange: value => {
            if (
              value?.type === 'custom' &&
              !(value?.start_date && value?.end_date)
            ) {
              setTimeParams({
                type: StaticTimeTypes.CUSTOM,
                start_date: dayjs()
                  .subtract(7, 'day')
                  .startOf('d')
                  .format('YYYY-MM-DD HH:mm:ss'),
                end_date: dayjs().endOf('d').format('YYYY-MM-DD HH:mm:ss'),
              })
              return
            }
            setTimeParams(value as any)
          },
        }}
        service={{
          call: async params => {
            const [$0, $1] = await Promise.all([
              queryAppStability(params),
              queryIrlTrend(params),
            ])
            return $0.concat($1)
          },
          params,
        }}
        showTooltip
        onDetailClick={(date, _, chartId) => {
          const start_date = dayjs(date)
            .startOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          const end_date = dayjs(date)
            .endOf('day')
            .format('YYYY-MM-DD HH:mm:ss')
          setTimeParams({
            type: StaticTimeTypes.CUSTOM,
            start_date,
            end_date,
          })
          if (chartId === 0) {
            openStabilityDetailModal()
            return
          }
          if (chartId === 1) {
            openErrorTimeDetailModal()
          }
        }}
      />
      {stabilityDetailModalVisible && (
        <StabilityDetailsModel
          onClose={closeStabilityDetailModal}
          runType={runType}
          flowType={flowType}
          timeParams={timeParams}
        />
      )}
      {errorTimeDetailVisible && (
        <ErrorOverTimeDetailModal
          onClose={closeErrorTimeDetailModal}
          runType={runType}
          flowType={flowType}
          timeParams={timeParams}
        />
      )}
    </>
  )
}

export const WorkspaceStabilityPane = memo(InternalWorkspaceStabilityPane)
