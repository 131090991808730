import { Modal, message as AntdMessage } from 'antd'
import { useRequest } from 'ahooks'
import type { Options, Service, Plugin } from 'ahooks/lib/useRequest/src/types'
import { useAuth } from '@bty/react-auth'
import { useNavigate } from 'react-router-dom'
import type { ReactNode } from 'react'
import { RoleCode } from '@apis/authority/type'
import type { VersionInfo } from '@apis/version/type'
import { API_CODE_CODE, not_enough_llm } from '@/constants/request'
import { Version, useLimitedModal } from '@/features/pay'
import { useVersionStore, useWorkspaceStore } from '@/store'
import theme from '@/constants/theme'
import gpt4customer from '@/assets/gpt4customer.png'
import type { BusinessError } from '@/http-inject/error'
import { useWarningMessage } from './useWarningMessage'

function getActions(
  role: RoleCode,
  product_code?: string,
  message?: string,
  modal?: any,
) {
  const showModal = () =>
    Modal.info({
      title: '温馨提示',
      icon: null,
      content: (
        <div className='flex flex-col flex-items-center text-14px c-font'>
          {message || '大语言模型的运行次数不足'}
          <img className='h-126px w-126px mt-32px mb-12px' src={gpt4customer} />
          <div className='mb-30px'>微信扫一扫</div>
        </div>
      ),
      okText: '确定',
    })

  AntdMessage.destroy()
  if (
    product_code === Version.PERSONAL &&
    (role === RoleCode.ADMINISTRATOR || role === RoleCode.DEVELOPER)
  ) {
    modal.open({ role })
  }
  if (
    product_code === Version.TEAM &&
    (role === RoleCode.ADMINISTRATOR || role === RoleCode.DEVELOPER)
  ) {
    showModal()
  }
  if (role === RoleCode.VIEWER) {
    showModal()
  }
}

function getActionsString({
  role,
  versionInfo,
  applicationId,
  flowId,
  fetchErrorMessage,
  modal,
  navigate,
  LLM,
  code,
  workspaceId,
}: {
  workspaceId: string
  role: RoleCode
  versionInfo?: VersionInfo
  applicationId?: string
  flowId?: string
  fetchErrorMessage?: string
  modal?: any
  navigate?: (e: string) => void
  LLM?: string
  code: API_CODE_CODE
}): ReactNode {
  const gotoFlowPage = () => {
    AntdMessage.destroy()
    navigate?.(
      `/application/${workspaceId}${applicationId}/flow/${flowId}?errorCode=${code}`,
    )
  }
  const action = () =>
    getActions(role, versionInfo?.product_code, fetchErrorMessage, modal)
  const RenderButton = ({
    action,
    str,
  }: {
    action: () => void
    str: string
  }) => (
    <span
      onClick={action}
      className='cursor-pointer underline'
      style={{ color: theme.colors.primary }}
    >
      {str}
    </span>
  )

  if (
    versionInfo?.product_code !== Version.TEAM &&
    (role === RoleCode.ADMINISTRATOR || role === RoleCode.DEVELOPER)
  ) {
    return (
      <>
        请切换其他「模型」
        <RenderButton str={'去编辑 >'} action={gotoFlowPage} />
        ，如仍需使用「{LLM}」<RenderButton str={'升级版本 >'} action={action} />
      </>
    )
  }
  if (
    versionInfo?.product_code === Version.TEAM &&
    (role === RoleCode.ADMINISTRATOR || role === RoleCode.DEVELOPER)
  ) {
    return (
      <>
        请切换其他「模型」
        <RenderButton str={'去编辑 >'} action={gotoFlowPage} />
        ，如仍需使用「{LLM}」<RenderButton str={'联系客服 >'} action={action} />
      </>
    )
  }
  if (role === RoleCode.VIEWER) {
    // return <>请<RenderButton str={'联系空间管理员 >'} action={action} />，请切换其他「模型」或 升级空间版本等。</>
    return <>请联系空间管理员</>
  }
  return ''
}

export function useComboRequest<T>(
  service?: Service<T, any[]>,
  options?: Options<T, any[]> | undefined,
  plugins?: Plugin<T, any[]>[] | undefined,
  params?: { applicationId?: string; flowId?: string },
) {
  const { state } = useAuth()
  const [modal] = useLimitedModal()
  const { fetchVersionByWorkspaceId } = useVersionStore()
  const versionInfo = useVersionStore(state => state.versionInfo)
  const navigate = useNavigate()
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const errorMessage = (message: string) => {
    return state.role === RoleCode.VIEWER || !state.role
      ? '应用运行次数已达到上限，请联系管理员增加'
      : message || '应用运行次数已达到上限，请联系管理员增加'
  }
  const { open } = useWarningMessage()
  const handleNotEnoughCombo = (
    err: BusinessError,
    callback?: () => any,
    show_COMBO_NOT_ENOUGH_Message = true,
  ) => {
    const { code, message } = err
    if (code === API_CODE_CODE.COMBO_NOT_ENOUGH) {
      callback?.()
      if (state.role === RoleCode.VIEWER || !state.role /* 兼容分享页面 */) {
        Modal.info({
          title: '温馨提示',
          icon: null,
          content: errorMessage(message),
          okText: '确定',
        })
      } else if (show_COMBO_NOT_ENOUGH_Message) {
        AntdMessage.warning(errorMessage(message))
        // modal.open({ role: state.role })
      }
    } else if (
      code === API_CODE_CODE.COMBO_NOT_GPT4_TIME_ENOUGH ||
      code === API_CODE_CODE.COMBO_NOT_QWENPLUS_TIME_ENOUGH
    ) {
      callback?.()
      open({
        duration: 10,
        title: `${not_enough_llm[code]}次数已达本月上限`,
        content: getActionsString({
          workspaceId,
          role: state.role,
          versionInfo,
          applicationId: params?.applicationId,
          flowId: params?.flowId,
          fetchErrorMessage: message,
          modal,
          navigate,
          LLM: not_enough_llm[code],
          code,
        }),
      })
    }
  }
  const res = useRequest(
    service ? e => service(e) : () => Promise.resolve({} as T),
    {
      ...options,
      onSuccess: (data: T, params: any[]) => {
        fetchVersionByWorkspaceId(workspaceId)
        options?.onSuccess?.(data, params)
      },
      onError: (err: BusinessError, params: any[]) => {
        handleNotEnoughCombo(err)
        options?.onError?.(
          { ...err, message: errorMessage(err.message) },
          params,
        )
      },
    },
    plugins,
  )
  return {
    handleNotEnoughCombo,
    errorMessage,
    ...res,
  }
}
