import NiceModal from '@ebay/nice-modal-react'
import { memo, useEffect } from 'react'
import { Divider, Empty } from 'antd'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import { useMemoizedFn } from 'ahooks'
import type { TaskDetail } from '@apis/task/type'
import { Modal } from '@/components'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import { DeleteConfirm } from '@/components/DeleteConfirm'
import { TaskSetting } from './TaskSetting'
import { TaskInput } from './TaskInput'
import { TaskProvider, useTaskData } from './TaskContext'
import { DEFAULT_TASK_CONFIG, TaskType } from './const'

interface TaskModalProps {
  agentId: string
  versionId: string
  isNew?: boolean
  task?: any
  onConfirm: (task: TaskDetail) => void
  onDelete?: () => void
  toAddFlow?: () => void
  toAddPlugin?: () => void
}

const TaskModalInner = memo((props: TaskModalProps) => {
  const {
    agentId,
    versionId,
    isNew,
    task,
    onConfirm,
    onDelete,
    toAddFlow,
    toAddPlugin,
  } = props

  const { form, getRunParam, resetConfig } = useTaskData()

  const modal = NiceModal.useModal()

  const handleConfirm = useMemoizedFn(async () => {
    try {
      await form.validateFields()
      const taskConfig = form.getFieldsValue()
      const runParam = await getRunParam()

      modal.hide()
      onConfirm({
        flow_id: agentId,
        is_preset: true,
        preset_enable: true,
        ...DEFAULT_TASK_CONFIG,
        ...task,
        ...taskConfig,
        run_param:
          taskConfig.execution_method === TaskType.AGENT
            ? taskConfig.run_param
            : runParam,
        update_time: Date.now() / 1000,
      })
      resetConfig()
    } catch (err) {
      console.log(err)
    }
  })

  const handleDelete = useMemoizedFn(() => {
    modal.hide()
    onDelete?.()
  })

  const handleToFlow = useMemoizedFn(() => {
    modal.hide()
    toAddFlow?.()
  })

  const handleToPlugin = useMemoizedFn(() => {
    modal.hide()
    toAddPlugin?.()
  })

  useEffect(() => {
    resetConfig(task)
  }, [task])

  return (
    <Modal
      width={1120}
      title={isNew ? '创建预设任务' : '编辑预设任务'}
      extraFooter={
        !isNew && (
          <DeleteConfirm
            rootClassName='w-360px [&_.ant-popover-inner]:p-20px!'
            placement='topLeft'
            align={{ offset: [-8, -12] }}
            message='确认删除任务？删除后不再执行此任务'
            onDelete={handleDelete}
          >
            <span className='mr-auto text-#FF5219 cursor-pointer'>删除</span>
          </DeleteConfirm>
        )
      }
      footerClassName='py-12px [&_.ant-btn]:font-normal [&_.ant-btn]:w-80px [&_.ant-space]:gap-12px'
      centered
      destroyOnClose
      maskClosable={false}
      open={modal.visible}
      onCancel={modal.hide}
      cancelText='取消'
      okText='保存'
      onOk={handleConfirm}
    >
      <div className='h-600px mx-[-16px]'>
        <OverlayScrollbarsComponent
          className='overflow-auto h-full px-16px'
          element='div'
          options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
          defer
        >
          <TaskSetting
            agentId={agentId}
            versionId={versionId}
            title='任务设置'
            noFlowContent={
              <Empty
                className='ant-empty-small'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    请先在Agent中添加工作流，
                    <span
                      className='text-primary cursor-pointer'
                      onClick={handleToFlow}
                    >
                      去添加
                    </span>
                  </span>
                }
              />
            }
            noPluginContent={
              <Empty
                className='ant-empty-small'
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description={
                  <span>
                    请先在Agent中添加插件，
                    <span
                      className='text-primary cursor-pointer'
                      onClick={handleToPlugin}
                    >
                      去添加
                    </span>
                  </span>
                }
              />
            }
          />

          <Divider />

          <TaskInput title='输入参数' />
        </OverlayScrollbarsComponent>
      </div>
    </Modal>
  )
})

const TaskModalWitProvider = memo((props: TaskModalProps) => {
  return (
    <TaskProvider>
      <TaskModalInner {...props} />
    </TaskProvider>
  )
})

export const TaskModal = NiceModal.create(TaskModalWitProvider)
