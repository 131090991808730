import type {
  Dispatch,
  FC,
  PropsWithChildren,
  RefObject,
  SetStateAction,
} from 'react'
import { createContext, useRef, useContext, useState, useEffect } from 'react'
import { get, isEqual, noop } from 'lodash-es'
import { useParams } from 'react-router-dom'
import { useMemoizedFn, useRequest } from 'ahooks'
import type { ChatPCInstance } from '@bty/chat-renderer-pc'
import { getAppAbilityStatistics, getApplicationById } from '@apis/application'
import type { ApplicationBodyType } from '@apis/application/type'
import { useWorkspaceStore } from '@/store'
import { useSkillConfig } from '@/features/agent/hooks/useSkillConfig.ts'
import type { RuleConfigInstance } from '../Rule'
import { useAgentUploadFileConfig } from '../hooks/useAgentUploadFileConfig'

export interface IAgentEditContext {
  applicationInfo?: ApplicationBodyType
  refreshApplicationInfo: () => void
  skillConfigContext: ReturnType<typeof useSkillConfig>
  openInvitePopover?: boolean
  setOpenInvitePopover?: (e: boolean) => void
  chatRef: RefObject<ChatPCInstance>
  ruleRef: RefObject<RuleConfigInstance>
  copilotState: CopilotState
  setCopilotState: Dispatch<SetStateAction<CopilotState>>
  statistics: Record<string, number>
  updateStatistics: () => void
  transformMap: Record<string, string>
  updateTransformMap: (
    newMap: Record<string, string>,
    type?: 'add' | 'replace',
  ) => void
  uploadFileConfig: {
    fileAccepts: string[]
    allowFileTypeNameList: string[]
  }
  refreshAgentUploadLoadSupportFileTypes: () => void
}

export enum CopilotState {
  init = 'init',
  generate = 'generate',
  transform = 'transform',
  finish = 'finish',
}

export enum TransformKey {
  avatar = '头像变更',
  name = '名称变更',
  prompt = '角色设定变更',
  welcome = '开场白变更',
  knowledgeAdd = '知识库文件新增',
  knowledgeDelete = '知识库文件删除',
  knowledgeChange = '知识库查询设置变更',
  pluginList = '可用插件列表',
  pluginAdd = '插件/工作流新增',
  pluginChange = '指定技能设置',
}

export const TransformKeyList = Object.values(TransformKey)

export const AgentEditContext = createContext<IAgentEditContext>({
  refreshApplicationInfo: noop,
  skillConfigContext: {} as any,
  chatRef: { current: null },
  ruleRef: { current: null },
  copilotState: CopilotState.init,
  setCopilotState: noop,
  statistics: {},
  updateStatistics: noop,
  transformMap: {},
  updateTransformMap: noop,
  uploadFileConfig: {
    fileAccepts: [],
    allowFileTypeNameList: [],
  },
  refreshAgentUploadLoadSupportFileTypes: noop,
})

export const AgentEditProvider: FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams()
  const setCurrentWorkspace = useWorkspaceStore(s => s.setCurrentWorkspace)
  const skillConfigContext = useSkillConfig()

  const isFirstLoad = useRef(true)
  const chatRef = useRef<ChatPCInstance>(null)
  const ruleRef = useRef<RuleConfigInstance>(null)

  const [openInvitePopover, setOpenInvitePopover] = useState(false)
  const [copilotState, setCopilotState] = useState(CopilotState.init)
  const [statistics, setStatistics] = useState<Record<string, number>>({})
  const [transformMap, setTransformMap] = useState<Record<string, string>>({})

  const updateStatistics = useMemoizedFn(async () => {
    const data = await getAppAbilityStatistics(id!)
    setStatistics({
      knowledge: data.dataset_count,
      plugin: data.plugins,
      flow: data.flows,
      database: data.database_tables,
    })
  })

  const { data: applicationInfo, refresh: refreshApplicationInfo } = useRequest(
    getApplicationById,
    {
      ready: !!id,
      defaultParams: [id!],
      onSuccess: res => {
        setCurrentWorkspace(res.workspaceId)

        if (isFirstLoad.current) {
          skillConfigContext.runAsync(
            res.flowId,
            res.versionId,
            res.workspaceId,
          )
          // 初始判断是否需要展示助手生成页
          const hasPrompt = !!get(res, 'config.rule.messages.0.content')
          if (res.flow_lock) {
            setCopilotState(CopilotState.generate)
          } else if (hasPrompt) {
            setCopilotState(CopilotState.finish)
          } else {
            setCopilotState(CopilotState.init)
          }
        }
        isFirstLoad.current = false
      },
    },
  )

  const { uploadFileConfig, refreshAgentUploadLoadSupportFileTypes } =
    useAgentUploadFileConfig(applicationInfo?.flowId || '')

  const updateTransformMap = useMemoizedFn(
    async (newMap, type: 'add' | 'replace' = 'add') => {
      const temp =
        type === 'add' ? { ...transformMap, ...newMap } : { ...newMap }

      const loadingKeys = Object.keys(temp)

      if (isEqual(temp, transformMap)) return

      if (loadingKeys.length !== 0) {
        setCopilotState(CopilotState.transform)
      } else {
        await ruleRef.current?.refreshRuleConfig()
        await refreshApplicationInfo()
        await updateStatistics()
        await refreshAgentUploadLoadSupportFileTypes()
        await skillConfigContext.refresh()
        setCopilotState(CopilotState.finish)
      }

      setTransformMap(temp)
    },
  )

  useEffect(() => {
    updateStatistics()
  }, [])

  return (
    <AgentEditContext.Provider
      value={{
        skillConfigContext,
        applicationInfo,
        refreshApplicationInfo,
        openInvitePopover,
        setOpenInvitePopover,
        chatRef,
        ruleRef,
        copilotState,
        setCopilotState,
        statistics,
        updateStatistics,
        transformMap,
        updateTransformMap,
        uploadFileConfig,
        refreshAgentUploadLoadSupportFileTypes,
      }}
    >
      {applicationInfo && children}
    </AgentEditContext.Provider>
  )
}

export function useAgentEdit() {
  return useContext(AgentEditContext)
}
