import { useState, useMemo, useRef, useCallback } from 'react'
import type { TooltipProps } from 'antd'
import { Tag, Tooltip } from 'antd'
import { rgba } from 'polished'
import { DocumentStatus } from '@apis/datastore/model'
import { IconFont } from '@/components'

import { DocumentStatusMap } from '../constant'
import { ColorfulBg, ColorfulText } from './ColorfulStyle'

export function TagWithHover(props: {
  status: DocumentStatus
  tooltip?: string
  onClick: () => void
  getPopupContainer?: TooltipProps['getPopupContainer']
  className?: string
}) {
  const { status, onClick, tooltip, getPopupContainer, className } = props
  const ref = useRef(null)
  const [isHovering, setHover] = useState(false)

  const text = useMemo(() => {
    if (status === DocumentStatus.Fail) {
      return isHovering ? '重试' : DocumentStatusMap[status].text
    } else {
      return DocumentStatusMap[status].text
    }
  }, [status, isHovering])

  const icon = useMemo(() => {
    if (status === DocumentStatus.Fail) {
      return isHovering ? 'tongshi' : 'jieshishuimeng'
    } else if (status === DocumentStatus.Warning) {
      return 'jieshishuimeng'
    } else {
      return 'tongshi'
    }
  }, [status, isHovering])

  const getContainer = useCallback(
    (e: any) => {
      return (getPopupContainer?.(e) || document.body) as any
    },
    [getPopupContainer],
  )

  const renderContent = useMemo(() => {
    if (status === DocumentStatus.AIProcess) {
      return (
        <ColorfulBg className='flex-center py-5 px-8px rounded-4px cursor-pointer'>
          <ColorfulText className='text-12px/14px'>
            <span style={{ color: DocumentStatusMap[status].color }}>
              {text}
            </span>
          </ColorfulText>
        </ColorfulBg>
      )
    }
    return (
      <Tag
        onClick={() => {
          onClick?.()
        }}
        className={className}
        color={rgba(DocumentStatusMap[status].color, 0.12)}
      >
        <div className='flex-center py-5'>
          <span style={{ color: DocumentStatusMap[status].color }}>{text}</span>
          {tooltip && (
            <IconFont
              style={{
                color: DocumentStatusMap[status].color,
              }}
              className='ml-4 cursor-pointer text-11px shrink-0'
              name={icon}
            />
          )}
        </div>
      </Tag>
    )
  }, [status, onClick])

  return (
    <Tooltip
      ref={ref}
      getPopupContainer={getContainer}
      onOpenChange={status => {
        setHover(status)
      }}
      title={tooltip}
    >
      {renderContent}
    </Tooltip>
  )
}
