import type { HttpDefine, HttpInjectInfo } from './http/type'
import { http } from './http'
import { sse } from './sse'
import type { EventSourceDefined } from './sse/type'

export class HTTPClient {
  base: HttpDefine
  inject: HttpInjectInfo

  constructor(base: HttpDefine, inject: HttpInjectInfo) {
    this.base = base
    this.inject = inject
  }

  request<R = any>(config: HttpDefine): Promise<R> {
    return http({ ...this.base, ...config }, this.inject)
  }

  get<R = any>(url: string, config?: HttpDefine): Promise<R> {
    return this.request({ ...config, url, method: 'get' })
  }

  delete<R = any>(url: string, config?: HttpDefine): Promise<R> {
    return this.request({ ...config, url, method: 'delete' })
  }

  head<R = any>(url: string, config?: HttpDefine): Promise<R> {
    return this.request({ ...config, url, method: 'head' })
  }

  options<R = any>(url: string, config?: HttpDefine): Promise<R> {
    return this.request({ ...config, url, method: 'options' })
  }

  post<R = any>(url: string, body?: any, config?: HttpDefine): Promise<R> {
    return this.request({
      ...config,
      url,
      method: 'post',
      body,
    })
  }

  put<R = any>(url: string, body?: any, config?: HttpDefine): Promise<R> {
    return this.request({
      ...config,
      url,
      method: 'put',
      body,
    })
  }

  patch<R = any>(url: string, body?: any, config?: HttpDefine): Promise<R> {
    return this.request({
      ...config,
      url,
      method: 'patch',
      body,
    })
  }

  sse<M = any>(url: string, body?: any, config?: EventSourceDefined<M>) {
    return sse<M>(
      {
        ...this.base,
        ...config,
        url,
        method: 'post',
        body,
      },
      this.inject,
    )
  }
}
