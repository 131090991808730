import { Dropdown, Modal, message, Tooltip } from 'antd'
import { useState } from 'react'
import classNames from 'classnames'
import type { ItemType } from 'antd/es/menu/interface'
import { deleteDatabase } from '@apis/database'
import { deleteDataStore } from '@apis/datastore'
import { deleteApplication } from '@apis/application'
import type { FLOW_STATUS } from '@apis/flow/type'
import { Button, IconFont } from '@/components'
import { SelectType } from '@/pages/application/components/WorkSpaceSelect'
import { useVersionStore, useWorkspaceStore } from '@/store'

interface AppBodyType {
  id?: string
  color?: string
  applicationType?: string
  icon?: string
  name?: string
  description?: string
  status?: number | string
  isPublic?: boolean
  isBot?: boolean
  isEnable?: boolean
  version?: number
  applicationVersion?: number
  updateAt?: string
  modifyBy?: string
  modifiedAt?: string
  createdBy?: string
  contentCount?: number
  copilot_conversation_id?: string
  copilot_session_id?: string
  copilot_agent_id?: string
  config?: string
  isPublish?: boolean
  flowId?: string
  flow_lock?: FLOW_STATUS
  workspaceId?: string
  versionId?: string
  versionStatus?: 'DRAFT' | 'PUBLISHED'
  partition_id?: number
}

interface AppDropDownProps<T> {
  className?: string
  removeToGroup?: (app: T) => ItemType
  handleDelete?: () => void
  isRelateApp?: boolean
  app: T
  selectType?: SelectType
  handleFork: (app: T) => void
  openChange?: (e: boolean) => void
  handleCover?: (app: T) => void
}

export function AppDropDown<T = AppBodyType>({
  className,
  app,
  selectType,
  isRelateApp,
  handleFork,
  removeToGroup,
  handleDelete,
  openChange,
  handleCover,
}: AppDropDownProps<T>) {
  const [open, setOpen] = useState(false)
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const { fetchVersionByWorkspaceId } = useVersionStore()
  return (
    <Dropdown
      trigger={['hover']}
      onOpenChange={e => {
        openChange?.(e)
        setOpen(e)
      }}
      menu={{
        items: [
          ...(removeToGroup ? [removeToGroup(app)] : []),
          {
            label: '复制',
            style: { height: 32, padding: '0 28px 0 10px' },
            key: '3',
            onClick: ({ domEvent }) => {
              domEvent.stopPropagation()
              openChange?.(false)
              handleFork(app)
            },
          },
          [SelectType.DATABASE, SelectType.DATASTORE]?.includes(
            selectType || SelectType.AGENT,
          )
            ? null
            : {
                label: '覆盖',
                key: '4',
                style: { height: 32, padding: '0 28px 0 10px' },
                onClick: ({ domEvent }) => {
                  domEvent.stopPropagation()
                  handleCover && handleCover(app)
                },
              },
          {
            label: (
              <Tooltip title={isRelateApp ? '有关联应用不可删除' : ''}>
                <div
                  className={classNames('w-100%', {
                    'op-40': isRelateApp,
                  })}
                >
                  删除
                </div>
              </Tooltip>
            ),
            key: '2',
            style: {
              color: '#FF5219',
              height: 32,
              padding: '0 28px 0 10px',
              cursor: isRelateApp ? 'not-allowed' : 'pointer',
              ...(isRelateApp ? { background: 'transparent' } : {}),
            },
            onClick: ({ domEvent }) => {
              domEvent.stopPropagation()
              if (isRelateApp) {
                return
              }
              openChange?.(false)
              Modal.confirm({
                icon: <></>,
                content: '请确认是否要删除应用？',
                cancelText: '取消',
                okText: '确定',
                okButtonProps: { danger: true },
                onOk: () => {
                  const handleDeleteSuccess = () => {
                    message.success('删除成功！')
                    handleDelete?.()
                    fetchVersionByWorkspaceId(workspaceId)
                  }
                  switch (selectType) {
                    case SelectType.DATABASE: {
                      const id = (app as any).id
                      if (id) {
                        deleteDatabase(id).then(handleDeleteSuccess)
                      }
                      return
                    }
                    case SelectType.DATASTORE: {
                      const id = (app as any).partition_id
                      if (id) {
                        deleteDataStore(id).then(handleDeleteSuccess)
                      }
                      return
                    }
                    default: {
                      const id = (app as any).id
                      if (id) {
                        deleteApplication(id).then(handleDeleteSuccess)
                      }
                    }
                  }
                },
              })
            },
          },
        ],
      }}
    >
      <Button
        className={classNames('!p-0 !w-32px text-font_1', className)}
        style={{ background: open ? 'rgba(98, 105, 153, 0.12)' : '' }}
        size='small'
        icon={<IconFont name='gengduo' />}
        onClick={e => {
          e.stopPropagation()
        }}
      />
    </Dropdown>
  )
}
