import { http } from '@bty/http-client'
import { request } from '../../client'
import type {
  AgentListParameters,
  PushMessage,
  PluginInfoResponse,
  PluginDataFrom,
} from './type'
import { PluginDataType } from './type'

export const keyuXcAuth =
  'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiIxOTEwIiwiY2xpZW50Ijoibm9jb19hcGkiLCJleHAiOjUzMDQyNDczNDV9.eepR4YIDPc6EvJJYG21vh32_xvES0F8upqhjLmRDy7M'

export function getSkillList({ function_id }: AgentListParameters) {
  return request.get(`/v1/agent/utility/${function_id}/detail`)
}
export function getAddPluginToAgent(body: any) {
  return request.post('/v1/agent/utility/function/create', body)
}
export function getAgentList({
  function_id,
  workspace_id,
}: AgentListParameters) {
  return request.get(`/v1/agent/function/rel?function_id=${function_id}`, {
    headers: {
      workspace_id,
    },
  })
}
export function getPluginPageConfig() {
  return request.get('/v1/agent/plugin/template')
}
export function getPushMsg(body: PushMessage) {
  return request.post('/v1/chat/push_msg', body)
}

export function getPluginInfo(
  function_id: string,
): Promise<PluginInfoResponse> {
  return request.get(`/v1/completion_function/${function_id}/detail`)
}

// 插件数据统计，包括：查看详情页数据 & 添加数据
export function setPluginData(
  type: PluginDataType,
  plugin_id: string,
  from?: PluginDataFrom,
) {
  return http({
    url: `https://salyut2.bantouyan.com/api/v1/db/data/v1/1iuk5af6830x1fqrq37xyihcfqevmja2wu5f/${type === PluginDataType.add ? 'add_plugin_data' : 'view_info_data'}`,
    headers: { 'xc-auth': keyuXcAuth },
    body: { plugin_id, from, env: import.meta.env.MODE },
  })
}
