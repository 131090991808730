import { useEffect, useState } from 'react'
import { useReactFlow } from 'reactflow'
import { FlowStatus } from '@apis/flow/type'
import { useFlowDraftStore } from '@/store'
import type { FlowOutput } from '@/features/logger/types'

export function useErrorNodesMarkByLogs() {
  const [logs, setLogs] = useState<FlowOutput[]>([])

  const { getNodes } = useReactFlow()
  const { setNodeErrorStatusMap } = useFlowDraftStore()

  useEffect(() => {
    const nodes = getNodes()
    const errorNodeNames = logs
      .filter(item => item.status === FlowStatus.FAIL)
      .map(item => item.name)
    const errorNodes = nodes.filter(n => errorNodeNames.includes(n.data.name))
    const data = errorNodes.reduce<Record<string, boolean>>((pre, cur) => {
      pre[cur.id] = true
      return pre
    }, {})

    setNodeErrorStatusMap(data)
  }, [logs])

  return setLogs
}
