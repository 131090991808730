import { userCenterRequest, request } from '../../client'
import { LOGIN_AUTHORIZATION } from '../constants'
import type {
  User,
  LoginResData,
  LoginOrRegisterParams,
  SignUpParams,
  UserByPhoneRes,
  UpdatePasswordParams,
  UserProfileResponse,
  UserProfile,
} from './type'

export function getUser() {
  return request.get<User>('/v1/users/me')
}

export function updateUser(data: Pick<User, 'username' | 'avatar'>) {
  return request.put('/v1/users/me', data)
}

export function doLogin({
  phone,
  password,
}: {
  phone: string
  password: string
}) {
  const data = new FormData()

  data.append('phone', phone)
  data.append('password', password)
  return userCenterRequest.post<LoginResData>('/user-center/login', data, {
    headers: {
      Authorization: LOGIN_AUTHORIZATION,
    },
  })
}

export function loginOrRegister(data: LoginOrRegisterParams) {
  const formData = new FormData()
  formData.append('phone', data.phone)
  data.password && formData.append('password', data.password)
  data.verifyCode && formData.append('verifyCode', data.verifyCode)
  return userCenterRequest.post<LoginResData>('/user-center/login', formData, {
    headers: {
      Authorization: LOGIN_AUTHORIZATION,
    },
  })
}

export function signUp(data: SignUpParams) {
  return userCenterRequest.post<{ userId: string; phone: string }>(
    '/user-center/user/signUp',
    data,
  )
}

export function authorizeChatbot(userId: string) {
  return request.put(`/v1/users/feature?feature=CHATBOT&userId=${userId}`)
}

export function getUserByPhone(phone: string) {
  return userCenterRequest.get<UserByPhoneRes>(
    `/user-center/user/getUserByPhone?phone=${phone}`,
    {
      headers: {
        innerToken: 'c9ff2c02-82be-44fe-9e3b-2bbf6354525a',
      },
      ignoreError: true,
    },
  )
}

export function updatePasswordWithVerifyCode(data: UpdatePasswordParams) {
  const formData = new FormData()
  formData.append('password', data.password)
  formData.append('phone', data.phone)
  formData.append('verifyCode', data.verifyCode)
  return userCenterRequest.put(
    '/user-center/user/updatePasswordWithVerifyCode',
    formData,
  )
}

export function getUserProfile(userId: number) {
  return userCenterRequest.get<UserProfileResponse>(
    `/user-center/user/getUserProfileByUserId?userId=${userId}`,
  )
}

export function saveOrUpdateUserProfile(
  userId: number,
  profile: UserProfile,
  source?: string,
) {
  return userCenterRequest.post(
    '/user-center/user/saveOrUpdateProfile',
    {
      userId,
      source,
      profile,
    },
    { customErrorMessage: '系统开小差了，请刷新重试' },
  )
}

export function logout() {
  return userCenterRequest.post('/user-center/logout')
}

export function checkToken(token: string | null): Promise<UserProfileResponse> {
  const formData = new FormData()
  formData.append('token', token ?? '')

  return userCenterRequest.post('/user-center/auth/checkToken', formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    ignoreError: true,
  })
}
