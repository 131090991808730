import { useMemo, useRef } from 'react'
import { useBoolean, useHover, useMemoizedFn } from 'ahooks'
import { Panel } from 'reactflow'
import type { MenuProps } from 'antd'
import { Dropdown, message } from 'antd'
import dayjs from 'dayjs'
import classNames from 'classnames'
import NiceModal from '@ebay/nice-modal-react'
import type { ApplicationBodyType } from '@apis/application/type'
import type { Edge } from 'reactflow'
import type { FlowNode } from '@apis/flow/type'
import { IconFont } from '@/components'
import { useModal } from '@/hooks/useModal.tsx'
import { regainModal } from '../../components/Header'
import Empty from '@/features/database/components/Empty'
import { SkillConfModal } from '@/features/agent/SkillTabs/SkillConfModal'
import { useFlowDraftStore } from '@/store'

interface GraphData {
  edges: Edge[]
  nodes: FlowNode[]
}
interface FlowDataItemType {
  modifiedBy: string
  modifiedAt: string
  createBy: string
  applicationVersion: number
  flowId: string
  versionId: string
  versionStatus: string
  config: GraphData
}
interface VersionBarProps {
  onCloseVersion: () => void
  applicationInfo?: { applicationVersion?: number; config: GraphData }
  handleHistoryVersion: (config: GraphData) => void
  setShowTips: (isShowTips: boolean) => void
  selectedVersion: number
  onSelectedVersion: (version: number) => void
  flowVersionData: FlowDataItemType[]
  onRunFlowVersionList: () => void
  handleRecoverVersion: ({
    flowId,
    versionId,
  }: {
    flowId: string
    versionId: string
  }) => Promise<FlowDataItemType>
}
interface VersionItemProps {
  modifiedAt: string
  modifiedBy: string
  createBy: string
  versionId: string
  applicationVersion: number
  applicationInfo: ApplicationBodyType
  config: { nodes: any; edges: any }
  selectedVersion: number | undefined
  isCurrentVersion: boolean
  changeVersionItem: (config: GraphData, applicationVersion: number) => void
  handleRegainVersion: () => void
}

function VersionItem({
  modifiedAt,
  modifiedBy,
  createBy,
  applicationVersion,
  applicationInfo,
  versionId,
  config,
  selectedVersion,
  changeVersionItem,
  isCurrentVersion,
  handleRegainVersion,
}: VersionItemProps) {
  const [showIcon, { setTrue: setShowIcon, setFalse: setVisibleIcon }] =
    useBoolean(false)

  const [dropdownOpen, { set: setDropdownOpen, setFalse: hideDropdown }] =
    useBoolean(false)

  const ref = useRef(null)
  const itemRef = useRef(null)
  const isHover = useHover(ref, {
    onLeave: () => {
      setDropdownOpen(true)
    },
    // onEnter: () => {
    //   setDropdownOpen(true)
    // },
  })
  useHover(itemRef, {
    onEnter: () => {
      setDropdownOpen(false)
    },
  })
  const onRegainVersion: MenuProps['onClick'] = _e => {
    hideDropdown()
    handleRegainVersion()
  }

  const dropdownVisible = useMemo(() => {
    if (isHover) {
      return true
    }
    return dropdownOpen
  }, [isHover, dropdownOpen])

  const handleOpenConfigModal = useMemoizedFn(() => {
    hideDropdown()
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    NiceModal.show(SkillConfModal, {
      type: 'Flow',
      identifier: applicationInfo?.flowId,
      conf: applicationInfo,
      hideSuperLink: true,
      hideOutputConfig: true,
      disabled: true,
      isDraft: true,
      getExtraParams: () => ({
        flow_id: applicationInfo?.flowId,
        version_id: versionId,
      }),
      // onOk: () => {},
    })
  })
  return (
    <div
      className={classNames(
        'py-8px px-12px rounded-8px mb-3px cursor-pointer flex justify-between items-center hover:bg-#62699914',
        { 'bg-#7B61FF14': selectedVersion === applicationVersion },
      )}
      ref={itemRef}
      onClick={() => changeVersionItem(config, applicationVersion)}
      onMouseEnter={setShowIcon}
      onMouseLeave={setVisibleIcon}
    >
      <div className='flex flex-col'>
        <div className='flex'>
          <span className='text-14px/16px'>
            {dayjs(modifiedAt)?.format('YYYY年M月D日 HH:mm')}
          </span>
          {isCurrentVersion && (
            <span
              className='border-1 ml-12px w-48px h-16px rounded-4px text-10px/12px color-#FE9700 flex-center'
              style={{ borderColor: 'rgba(254, 151, 0, 0.48)' }}
            >
              线上版本
            </span>
          )}
        </div>
        <p className='c-#8D8D99 text-12px/12px mt-8px'>
          {modifiedBy || createBy}
        </p>
      </div>
      {showIcon && (
        // <Tooltip placement='top' title='恢复至此版本'>
        <Dropdown
          open={dropdownVisible}
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <div className='p-12px flex flex-center hover:bg-bg_3 hover:bg-op-4 text-14px text-font rd-6px'>
                    查看调用配置
                  </div>
                ),
                onClick: handleOpenConfigModal,
              },
              {
                key: '2',
                label: (
                  <div className='p-12px flex flex-center hover:bg-#62699914  text-14px text-font rd-6px'>
                    恢复至此版本
                  </div>
                ),
                onClick: onRegainVersion,
              },
            ],
          }}
          placement='bottom'
        >
          <div
            ref={ref}
            className='w-24px h-24px rounded-4px text-16px flex-center  hover:bg-#62699926'
            onClick={e => e.stopPropagation()}
          >
            <IconFont name='gengduo' />
          </div>
        </Dropdown>
        // </Tooltip>
      )}
    </div>
  )
}

export function VersionBar(props: VersionBarProps) {
  const {
    onCloseVersion,
    handleHistoryVersion,
    setShowTips,
    selectedVersion,
    applicationInfo,
    onSelectedVersion,
    flowVersionData,
    onRunFlowVersionList,
    handleRecoverVersion,
  } = props
  const { updateConfig } = useFlowDraftStore(s => ({
    updateConfig: s.updateConfig,
  }))
  const [modal] = useModal(regainModal)
  const changeVersionItem = (config: GraphData, selectedVersion: number) => {
    setShowTips(true)
    onSelectedVersion(selectedVersion)
    handleHistoryVersion(config)
  }

  const handleRegainVersion = async (flowId: string, versionId: string) => {
    modal.open({
      onOk: async () => {
        const res = await handleRecoverVersion({
          flowId,
          versionId,
        })
        res.config && updateConfig(res.config)
        onRunFlowVersionList()
        message.success('已恢复至此版本')
      },
      onCancel: () => {},
    })
  }
  const isDraftAndSingleVersion = useMemo(() => {
    return flowVersionData?.length === 0
  }, [flowVersionData])
  return (
    <Panel position='top-right' className='h-full m-0  b-l-1 b-#e1e1e5'>
      <div className='w-288px h-full bg-#fff flex flex-col'>
        <div className='w-full h-48px flex justify-between b-b-1 py-16px px-16px'>
          <span className='text-16px/16px font-500'>历史版本</span>
          <IconFont
            name='guanbi'
            className='text-16px cursor-pointer'
            onClick={onCloseVersion}
          />
        </div>
        {isDraftAndSingleVersion ? (
          <Empty
            desc='发布后当前草稿将保存为版本'
            className='text-#6269997A text-14px! justify-center! mt-0px! pb-96px'
          />
        ) : (
          <div className='p-8px overflow-y-auto'>
            {flowVersionData?.map(
              (
                {
                  modifiedAt,
                  modifiedBy,
                  applicationVersion,
                  config,
                  flowId,
                  versionId,
                  createBy,
                }: FlowDataItemType,
                index: number,
              ) => (
                <VersionItem
                  key={index.toString()}
                  modifiedAt={modifiedAt}
                  modifiedBy={modifiedBy}
                  createBy={createBy}
                  applicationInfo={
                    applicationInfo as unknown as ApplicationBodyType
                  }
                  versionId={versionId}
                  applicationVersion={applicationVersion}
                  config={config}
                  selectedVersion={selectedVersion}
                  changeVersionItem={changeVersionItem}
                  handleRegainVersion={() =>
                    handleRegainVersion(flowId, versionId)
                  }
                  isCurrentVersion={
                    applicationVersion ===
                    flowVersionData?.[0]?.applicationVersion
                  }
                />
              ),
            )}
          </div>
        )}
      </div>
    </Panel>
  )
}
