import { useCallback, useRef } from 'react'
import { useLatest, useMemoizedFn } from 'ahooks'
import type { HTTPClient } from '@bty/http-client'

export enum EventSourceStatus {
  CONNECTING = 0,
  OPEN = 1,
  CLOSED = 2,
}

export interface IDataRefConfig {
  duration_time?: number
  message: string
  message_type: 'aggregate' | 'piecewise' | 'error' | undefined
}

interface Callbacks {
  onConnecting?: (state: EventSourceStatus.CONNECTING) => void
  onOpen?: (state: EventSourceStatus.OPEN) => void
  onMessage?: (state: EventSourceStatus.OPEN, content: IDataRefConfig) => void
  onClose?: (state: EventSourceStatus.CLOSED) => void
  onError?: (state: EventSourceStatus.CLOSED, error?: Error) => void
}

export function useEventSource<TPayload extends IDataRefConfig>(
  client: HTTPClient,
  url: string,
  callbacks: Callbacks,
) {
  const { onConnecting, onOpen, onMessage, onClose, onError } = callbacks
  const onConnectingRef = useLatest(onConnecting)
  const onOpenRef = useLatest(onOpen)
  const onMessageRef = useLatest(onMessage)
  const onCloseRef = useLatest(onClose)
  const onErrorRef = useLatest(onError)

  const statusRef = useRef<EventSourceStatus>(EventSourceStatus.CLOSED)

  const errorRef = useRef<Error>()

  const dataRef = useRef<IDataRefConfig>({
    message: '',
    message_type: undefined,
  })

  const abortRef = useRef<() => void>()

  const run = useMemoizedFn((body: any) => {
    // sse 连接中
    statusRef.current = EventSourceStatus.CONNECTING
    onConnectingRef.current?.(EventSourceStatus.CONNECTING)
    abortRef.current = client.sse<TPayload>(url, body, {
      onOpen: () => {
        // sse 连接成功，开始通信
        statusRef.current = EventSourceStatus.OPEN
        onOpenRef.current?.(EventSourceStatus.OPEN)
      },
      onMessage: content => {
        if (content.message_type === 'aggregate') {
          dataRef.current = {
            duration_time: content.duration_time,
            message: content.message,
            message_type: 'aggregate',
          }
        } else if (content.message_type === 'piecewise') {
          dataRef.current = {
            duration_time: content.duration_time,
            message: dataRef.current.message + content.message,
            message_type: 'piecewise',
          }
        } else {
          dataRef.current = {
            duration_time: content.duration_time,
            message: dataRef.current.message + content.message,
            message_type: 'error',
          }
        }
        statusRef.current = EventSourceStatus.OPEN
        onMessageRef.current?.(EventSourceStatus.OPEN, dataRef.current)
      },
      onClose: () => {
        dataRef.current = {
          message: '',
          message_type: undefined,
        }
        statusRef.current = EventSourceStatus.CLOSED
        onCloseRef.current?.(EventSourceStatus.CLOSED)
      },
      onError: error => {
        dataRef.current = {
          message: '',
          message_type: undefined,
        }
        errorRef.current = error
        onErrorRef.current?.(EventSourceStatus.CLOSED, error)
      },
    })
  })

  // 用户主动调用的 abort 不再触发 onClose
  const cancel = useCallback(() => {
    dataRef.current = {
      message: '',
      message_type: 'piecewise',
    }
    statusRef.current = EventSourceStatus.CLOSED
    abortRef.current?.()
  }, [])

  return { run, cancel } as const
}
