import NiceModal from '@ebay/nice-modal-react'
import { useRequest } from 'ahooks'
import { bindDatabase, bindTableForAgent } from '@apis/agent'
import { createDatabase, createTable, getDBList } from '@apis/database'
import { Button, IconFont, usePageModal } from '@/components'
import EmptyImage from '@/assets/app/rocket2.png'
import { Empty } from '@/components/empty'
import TableCreateDropdown from '@/features/database/components/TableCreateDropdown'
import { useWorkspaceStore } from '@/store'
import { useVersion } from '@/hooks/useVersion'
import { DatabaseSelectModal } from './DatabaseSelectModal'

interface NoBindingProps {
  agent: {
    name: string
    flow_id: string
    version_id: string
  }
  afterBind: VoidFunction
}
export function NoBinding({ agent, afterBind }: NoBindingProps) {
  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)
  const { isTeam } = useVersion()

  const { data: allDatabases } = useRequest(getDBList, {
    refreshDeps: [workspaceId],
  })

  const modal = usePageModal()

  return (
    <Empty
      image={EmptyImage}
      text='添加数据，让 Agent 查询'
      button={
        isTeam ? (
          <div></div>
        ) : (
          <TableCreateDropdown
            database={async () => {
              const db = await createDatabase({
                title: `${agent.name}的数据库`,
                description: '暂无描述',
                icon: '😃',
                icon_color: '#BFE2FF',
              })
              return db
            }}
            useInAgent={agent}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onOk={async (...args) => {
              if (args.length === 3) {
                const [database_id, source_id, db_params] = args
                const table = await createTable(
                  database_id,
                  source_id,
                  db_params,
                )
                await bindTableForAgent({
                  ...agent,
                  database_id,
                  table_id: table.id,
                  source_id,
                })
                modal.show({
                  url: `/db/${workspaceId}/${database_id}/${table.id}?hide_tables`,
                })
              }
              afterBind()
            }}
          >
            <Button
              size='large'
              className='px-23! mt-24'
              type='primary'
              icon={<IconFont name='add' />}
            >
              添加数据表
            </Button>
          </TableCreateDropdown>
        )
      }
      bottom={
        allDatabases?.list?.length ? (
          <button
            className='text-14px/16px font-medium text-primary flex items-center hover:opacity-60 mt-12'
            onClick={() => {
              NiceModal.show(DatabaseSelectModal, {
                data: allDatabases.list,
                onOk: async database => {
                  const sourceId = database.sources?.[0].id
                  if (!sourceId) {
                    return
                  }
                  await bindDatabase({
                    ...agent,
                    database_id: database.id,
                    source_id: sourceId,
                  })
                  afterBind()
                },
              })
            }}
          >
            <IconFont className='text-16px mr-4' name='action-link' />
            选择已有数据库
          </button>
        ) : null
      }
    ></Empty>
  )
}
