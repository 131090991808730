import type {
  ParametersWithInput,
  ParametersWithProperty,
} from '@bty/global-types/agent'
import type { ChatMessageItem } from '@bty/global-types/message'

export interface AgentListParameters {
  function_id: string | undefined
  workspace_id?: string
}

export interface PushMessage {
  conversation_id: string | undefined
  content: string
  tips: any[]
  to_context: boolean
}

export interface ConsumeInfo {
  consume_mode: string
  consume?: string
  model_list?: Array<{ model: string; consume: string }>
}

export interface Parameters {
  type: string
  description: string
  label: string
  default: any
  example: any
}

export interface Metadata {
  type: 'string' | 'int' | 'bool'
  display_name: string
  component: string
  required: boolean
  description: string
  value: any
  options?: Array<{ label: string; value: any }>
}

export interface PluginInfoResponse {
  function_id: string
  name: string
  // type:
  description: string
  display_name: string
  label: string
  is_new: boolean
  parameters: ParametersWithInput | ParametersWithProperty
  metadata_config: {
    icon: string
    properties: {
      inputs: Record<string, Metadata>
      outputs: unknown
    }
  }
  consume_info: ConsumeInfo
  usage_demo: {
    context: ChatMessageItem[]
  }
  resp_sample: any
}

export enum PluginDataType {
  add = 'add',
  view = 'view',
}

export enum PluginDataFrom {
  agent = 'agent',
  plugin = 'plugin',
  flow = 'flow',
}
