import type { FC } from 'react'
import classNames from 'classnames'
import { DocumentType } from '@apis/datastore/model'
import { SUPPORTED_FILE_TYPE_TEXT } from '@/features/datastore/constant'

interface DocumentTypeSelectProps {
  withTemplate?: boolean
  onSelect: (type: DocumentType) => void
}
export const DocumentTypeSelect: FC<DocumentTypeSelectProps> = props => {
  const { withTemplate = false, onSelect } = props

  return (
    <div>
      {withTemplate && (
        <div
          className='p-16 bg-white border border-line border-opacity-80 hover:bg-bg_3 hover:bg-opacity-8 active:border-primary rounded-8px cursor-pointer'
          onClick={() => {
            onSelect(DocumentType.TEMPLATE_FILE)
          }}
        >
          <p className='font-500 text-14px line-height-16px'>示例文档</p>
          {/* <p className="text-12px mt-8 text-font_1 line-height-1em"></p> */}
        </div>
      )}
      <div
        className={classNames(
          'p-16 bg-white border border-line border-opacity-80 hover:bg-bg_3 hover:bg-opacity-8 active:border-primary rounded-8px cursor-pointer',
          {
            'mt-16': withTemplate,
          },
        )}
        onClick={() => {
          onSelect(DocumentType.FILE)
        }}
      >
        <p className='font-500 text-14px line-height-16px relative flex items-center justify-start'>
          <span>文档</span>
        </p>
        <p className='text-12px mt-8 text-font_1 line-height-22px'>
          支持格式：{SUPPORTED_FILE_TYPE_TEXT}
        </p>
      </div>
      <div
        className='p-16 bg-white border border-line border-opacity-80 hover:bg-bg_3 hover:bg-opacity-8 active:border-primary rounded-8px mt-16 cursor-pointer'
        onClick={() => {
          onSelect(DocumentType.TEXT)
        }}
      >
        <p className='font-500 text-14px line-height-16px'>手动输入</p>
        <p className='text-12px mt-8 text-font_1 line-height-1em'>
          手动输入一些文本信息
        </p>
      </div>
      <div
        className='p-16 bg-white border border-line border-opacity-80 hover:bg-bg_3 hover:bg-opacity-8 active:border-primary rounded-8px mt-16 cursor-pointer'
        onClick={() => {
          onSelect(DocumentType.WEBPAGE)
        }}
      >
        <p className='font-500 text-14px line-height-16px'>网页</p>
        <p className='text-12px mt-8 text-font_1 line-height-1em'>
          输入网页URL地址，获取网页数据
        </p>
      </div>
      <div
        className={classNames(
          'mt-16 p-16 bg-white border border-line border-opacity-80 hover:bg-bg_3 hover:bg-opacity-8 active:border-primary rounded-8px cursor-pointer',
        )}
        onClick={() => {
          onSelect(DocumentType.VIDEO)
        }}
      >
        <p className='font-500 text-14px line-height-16px relative flex items-center justify-start'>
          <span>视频</span>
        </p>
        <p className='text-12px mt-8 text-font_1 line-height-22px'>
          根据人声声轨解析视频内容
        </p>
      </div>
    </div>
  )
}
