import type { ISubFlowItem } from '@apis/flow/type'
import { FLOW_DISPLAYNAME } from '@/constants/common'
import { AppLogo } from '@/components'
import { NodeOptionItem } from './NodeOptionItem'

interface FlowListProps {
  data: ISubFlowItem[]
  onSelect: (id: string) => void
}

export function FlowList(props: FlowListProps) {
  const { data, onSelect } = props

  return (
    <>
      <div className='p-8px line-height-16px font-500 c-#17171d'>
        {FLOW_DISPLAYNAME}
      </div>
      {data.length ? (
        data.map(({ flow_id, flow_name, icon, color, description }) => (
          <NodeOptionItem
            key={flow_id}
            id={flow_id}
            icon={
              <AppLogo
                type='emoji'
                size={24}
                className='mr-8px'
                value={icon}
                fillSize={16}
                color={color}
                imgStyle={{ borderRadius: '6px' }}
                square
              />
            }
            name={flow_name}
            description={description}
            onClick={id => {
              onSelect(id)
            }}
          />
        ))
      ) : (
        <div className='mb-10px pl-8px line-height-18px text-#8d8d99 text-op-60'>
          暂无可用工作流
        </div>
      )}
    </>
  )
}
