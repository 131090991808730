import './index.css'

import type { ComponentType, MemoExoticComponent } from 'react'
import { omit } from 'lodash-es'
import { InputRender } from './input'
import { AreaRender } from './area'
import { SelectRender } from './single-select'
import { MultipleSelectRender } from './multiple-select'
import { UploadRender } from './upload'
import { JSONRender } from './json'

const typeMap: Record<
  string,
  ComponentType | MemoExoticComponent<ComponentType<any>>
> = {
  input: InputRender,
  textarea: AreaRender,
  select: SelectRender,
  multiSelect: MultipleSelectRender,
  json: JSONRender,
  file: UploadRender,
}

export function getRender(type: string, name: string, props: any) {
  const Component = typeMap[type] as any

  return <Component key={name} name={name} {...omit(props, 'key')} />
}
