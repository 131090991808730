import { memo, useEffect, useMemo, useState, useRef } from 'react'
import { message } from 'antd'
import { useMemoizedFn, useSize } from 'ahooks'
import { IconFont, Mermaid } from '@/components'
import ErrorPng from '@/assets/dataset/error.png'
import AutoProgress from '@/components/progress/AutoProgress.tsx'
import { tokenStorage } from '@/utils/storage'
import {
  State,
  useGeneratePromptSocket,
} from '@/features/agent/hooks/useGeneratePromptSocket'
import { tryGetObject } from '@/features/logger/components/loggerModal/util'
import { Track } from '@/features/track'
import type {
  ICacheStore,
  CacheGraphType,
} from './PromptOptimizationModal/OptimizationModal'

// import { useTrack } from '@/features/track/Track'

export const ImgContainerSize = 432

interface PromptMermaidProps {
  disabled?: boolean
  prompt: string
  setGraphData: (graphData: string | null) => void
  getContainer?: () => HTMLElement
  cacheStore: ICacheStore
  setOutStatusLoading: (loading: boolean) => void
}

export const PromptMermaid = memo<PromptMermaidProps>(props => {
  const { prompt, setGraphData, cacheStore, setOutStatusLoading } = props
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState<CacheGraphType>()
  const token = tokenStorage.get()
  const descriptionRef = useRef<HTMLDivElement>(null)
  const descriptionSize = useSize(descriptionRef)
  // const { doTrack } = useTrack()

  const socketUrl = useMemo(() => {
    return `${
      import.meta.env.VITE_AI_WS_BASE_URL
    }/v1/ws/prompt/generate_optimize?Authorization=${token}`
  }, [])

  const { state, content, handleSendMessage } =
    useGeneratePromptSocket(socketUrl)

  const getPromptAnalyzeResult = async (prompt: string) => {
    setLoading(true)
    setOutStatusLoading(true)
    handleSendMessage(
      JSON.stringify({
        action: 'gen_prompt_graph',
        params: {
          message: prompt,
        },
      }),
    )
  }

  const handleRetryAnalyze = () => {
    if (!prompt.trim()) {
      message.warning('未找到 Prompt，请编写后重试')
      return
    }
    setGraphData(null)
    getPromptAnalyzeResult(prompt)
  }

  useEffect(() => {
    if (prompt.trim()) {
      if (prompt !== cacheStore.prompt) {
        setGraphData(null)
        getPromptAnalyzeResult(prompt)
      } else if (cacheStore.cacheGraph) {
        setData(cacheStore.cacheGraph)
        if (cacheStore.cacheGraph?.graph?.includes('graph LR')) {
          setGraphData(cacheStore.cacheGraph?.graph)
        }
      }
    }
  }, [prompt])

  useEffect(() => {
    if ([State.end, State.result].includes(state)) {
      const data = tryGetObject(content)
      setData(data)
      // 如果包含graph LR，则表示是mermaid图示
      if (data?.graph?.includes('graph LR')) {
        setGraphData(data?.graph)
      }
      cacheStore.setCacheGraph(data)
      setLoading(false)
      setOutStatusLoading(false)
    }
  }, [state])

  const render = useMemoizedFn(() => {
    const maxTime = 10 * 1000
    const promptExpectationTime = prompt.length * 300 // 2~3个字1s
    if (loading) {
      return (
        <div
          className={`w-full flex flex-col h-${ImgContainerSize}px flex-center`}
        >
          {/* <img */}
          {/*  src='https://resource-bty.oss-cn-hangzhou.aliyuncs.com/betteryeah/chat/generating.gif' */}
          {/*  alt='loading' */}
          {/*  className='w-36px h-36px' */}
          {/* /> */}
          <AutoProgress
            className='w-100px'
            strokeColor={{
              '0%': '#7F55FF',
              '50%': '#7DD2FF',
              '100%': '#FF9BFD',
            }}
            expectationTime={
              promptExpectationTime > maxTime ? maxTime : promptExpectationTime
            }
            showInfo={false}
            finish={!loading}
          />
          <div className='font-400 line-height-16px c-font_1 mt-8px'>
            图示生成中
          </div>
        </div>
      )
    } else if (!data || typeof data !== 'object' || !data.graph?.trim()) {
      // AI 返回出错
      return (
        <div className='w-full flex flex-col h-454px flex-center'>
          <img src={ErrorPng} alt='error' className='w-117px' />
          <div className='font-400 line-height-16px c-font_1 mt-8px'>
            AI开小差了，试试重新生成
          </div>
        </div>
      )
    } else {
      // 渲染analyze结果
      return (
        <div className='h-432px flex flex-col'>
          <div
            className='p-12 w-full line-height-22px font-400 whitespace-pre-line'
            ref={descriptionRef}
          >
            {data.description}
          </div>
          <div className='px-16 flex-1 h-full flex flex-center'>
            <Mermaid
              value={data.graph ?? ''}
              descriptionSize={descriptionSize}
            />
          </div>
        </div>
      )
    }
  })

  const Content = (
    <div>
      <div className='flex items-center'>
        <span className='font-500 text-14px/24px'>提示词图示</span>
        <Track event='agent_prompt_graph_regenerate'>
          <button
            className={`c-primary ml-auto p-4px rd-6px flex hover:bg-[rgba(98,105,153,0.08)] ${loading ? 'op-40 cursor-not-allowed!' : ''}`}
            onClick={handleRetryAnalyze}
            disabled={loading}
          >
            <IconFont name='tongxinshengcheng-1' className='mr-4px' />
            重新生成
          </button>
        </Track>
      </div>
      {/* Content */}
      <div className='w-full mt-8px max-h-432px rd-8px b-line/80 b-1'>
        {render()}
      </div>
    </div>
  )

  return <div className='left-0px! w-full!'>{Content}</div>
})
