import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import type {
  TemplateApp,
  TemplateAppGroup,
  TemplateAppTypeMap,
} from '@apis/template/type'
import { getTemplateAppData } from '@apis/template'

interface TemplateStore {
  apps: TemplateApp[]
  app_groups: TemplateAppGroup[]
  types: TemplateAppTypeMap
  fetchTemplateAppData: () => Promise<void>
}

export const useTemplateStore = create<TemplateStore>(set => ({
  apps: [],
  app_groups: [],
  types: {},
  fetchTemplateAppData: async () => {
    const data = await getTemplateAppData()
    set(preState => ({ ...preState, ...data }))
  },
}))

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('templateStore', useTemplateStore)
}
