import type { RefObject } from 'react'
import { useEffect, useRef } from 'react'
import { useMemoizedFn, useRequest } from 'ahooks'
import { isEmpty } from 'lodash-es'
import type { ChatPCInstance } from '@bty/chat-renderer-pc'
import type { AgentRuleConfig } from '@bty/chat-types'
import { deleteAgentConversation, getAgentConfigByType } from '@apis/agent'
import type { ApplicationBodyType } from '@apis/application/type'
import type { AgentDiffConfig, AgentDiffModel } from '@/store/agentDiffStore'
import { useAgentDiffStore } from '@/store/agentDiffStore'
import type { RuleConfigInstance } from '@/features/agent/Rule'

const cacheMap = new Map<
  string,
  { open: boolean; configs: AgentDiffConfig[] }
>()

const AGENT_DIFF_OPEN_LOCAL = 'AGENT_DIFF_OPEN_LOCAL'

export function useAgentDiff(
  applicationInfo: ApplicationBodyType,
  ruleRef: RefObject<RuleConfigInstance>,
  chatRef: RefObject<ChatPCInstance>,
  onDiffOpen?: () => void,
) {
  const scope = applicationInfo.id!

  const diffHasOpened = useRef(false)
  const diffOpen = useAgentDiffStore(s => s.open)
  const diffConfigs = useAgentDiffStore(s => s.diffConfigs)
  const initOpen = useAgentDiffStore(s => s.initOpen)
  const openDiff = useAgentDiffStore(s => s.openDiff)
  const closeDiff = useAgentDiffStore(s => s.closeDiff)
  const initDiffConfigs = useAgentDiffStore(s => s.initDiffConfigs)
  const addDiffModel = useAgentDiffStore(s => s.addDiffModel)
  const clearDiffIds = useAgentDiffStore(s => s.clearDiffIds)
  const clearDiffConfigs = useAgentDiffStore(s => s.clearDiffConfigs)
  const subscribeRuleChange = useAgentDiffStore(s => s.subscribeRuleChange)
  const publishStop = useAgentDiffStore(s => s.publishStop)

  const { runAsync: getDiffConfig } = useRequest(
    () => {
      return getAgentConfigByType({
        robot_id: scope,
        run_type: 'MULTIPLE_MODELS',
      })
    },
    {
      manual: true,
    },
  )

  const handleDelete = useMemoizedFn(
    async (deleteIds: (string | undefined)[]) => {
      await Promise.all(
        deleteIds
          .filter(e => !!e)
          .map(id => {
            return deleteAgentConversation(id!)
          }),
      )
    },
  )

  const handleReNew = useMemoizedFn(async () => {
    publishStop()
    const deleteIds = clearDiffIds()
    await handleDelete(deleteIds)
  })

  const handleDiffUse = useMemoizedFn((config: AgentDiffModel) => {
    ruleRef.current?.changeRule(
      {
        model: config.model,
        channel: config.channel,
        temperature: config.temperature,
        prompt_plugins: {
          length_prompt: config.length_prompt,
          time_prompt: config.time_prompt,
        },
      },
      true,
    )
    closeDiff()

    const deleteIds = clearDiffConfigs()
    handleDelete(deleteIds)
    chatRef.current?.chat?.list?.scrollToBottom()
  })

  const handleDiffOpen = useMemoizedFn(() => {
    if (!diffConfigs || diffConfigs.length === 0) {
      const rule = (applicationInfo?.config as any)?.rule as AgentRuleConfig
      addDiffModel({
        model: rule?.model,
        channel: rule?.channel,
        temperature: rule?.temperature,
        length_prompt: rule?.prompt_plugins?.length_prompt,
        time_prompt: rule?.prompt_plugins?.time_prompt,
      })
      addDiffModel()
    }
    openDiff()
    onDiffOpen?.()
  })

  const handleDiffClose = useMemoizedFn(() => {
    if (!diffConfigs[0].modelConfig) return
    const config = diffConfigs[0].modelConfig
    ruleRef.current?.changeRule(
      {
        model: config.model,
        channel: config.channel,
        temperature: config.temperature,
        prompt_plugins: {
          length_prompt: config.length_prompt,
          time_prompt: config.time_prompt,
        },
      },
      true,
    )
    publishStop()
    closeDiff()
    chatRef.current?.chat?.list?.scrollToBottom()
  })

  const handleAddDiffConfig = useMemoizedFn(() => {
    addDiffModel()
  })

  useEffect(() => {
    if (diffHasOpened.current) return
    if (diffOpen) {
      diffHasOpened.current = true
    }
  }, [diffOpen])

  const initFromCache = useMemoizedFn(async () => {
    const cache = cacheMap.get(scope)
    const defaultOpen =
      localStorage.getItem(AGENT_DIFF_OPEN_LOCAL + scope) === '1' ?? false

    if (!isEmpty(cache?.configs)) {
      initDiffConfigs(cache?.configs ?? [])
      initOpen(cache?.open ?? defaultOpen)
      return
    } else {
      initDiffConfigs([])
      initOpen(false)
    }

    const res = await getDiffConfig()
    const configs = res
      .map(e => {
        const config = e.config.model_config
        return {
          index: e.config.index || 0,
          conversationId: e.conversation_id,
          modelConfig: {
            model: config.model,
            channel: config.channel,
            temperature: config.temperature,
            length_prompt: config.prompt_plugins?.length_prompt ?? 5,
            time_prompt: config.prompt_plugins?.time_prompt ?? true,
          },
        } as AgentDiffConfig
      })
      .sort((l, r) => l.index! - r.index!)

    if (!configs || configs.length === 0) {
      const rule = (applicationInfo?.config as any)?.rule as AgentRuleConfig
      addDiffModel({
        model: rule?.model,
        channel: rule?.channel,
        temperature: rule?.temperature,
        length_prompt: rule?.prompt_plugins?.length_prompt,
        time_prompt: rule?.prompt_plugins?.time_prompt,
      })
      addDiffModel()
    } else {
      initDiffConfigs(configs ?? [])
    }
    initOpen(defaultOpen)
  })

  const saveToCache = useMemoizedFn(() => {
    cacheMap.set(scope, {
      open: diffOpen,
      configs: diffConfigs,
    })
  })

  useEffect(() => {
    initFromCache()
    return saveToCache
  }, [])

  const handleRuleChange = useMemoizedFn(async () => {
    const rule = ruleRef.current?.getRule()
    const oldIds = diffConfigs.map(e => e.conversationId)

    initDiffConfigs(
      diffConfigs.map((e, index) => {
        if (index === 0) {
          return {
            conversationId: '',
            modelConfig: rule
              ? {
                  model: rule?.model,
                  channel: rule?.channel,
                  temperature: rule?.temperature,
                  length_prompt: rule?.prompt_plugins?.length_prompt,
                  time_prompt: rule?.prompt_plugins?.time_prompt,
                }
              : e.modelConfig,
          }
        }

        return {
          conversationId: '',
          modelConfig: e.modelConfig,
        }
      }),
    )

    await handleDelete(oldIds)
  })

  useEffect(() => {
    return subscribeRuleChange(handleRuleChange)
  }, [])

  useEffect(() => {
    localStorage.setItem(AGENT_DIFF_OPEN_LOCAL + scope, diffOpen ? '1' : '0')
  }, [diffOpen, scope])

  return {
    diffHasOpened,
    diffOpen,
    diffConfigs,

    handleDelete,
    handleReNew,
    handleDiffUse,
    handleDiffOpen,
    handleDiffClose,
    handleAddDiffConfig,
  }
}
