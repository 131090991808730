import classNames from 'classnames'
import { useLayoutEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { bindTableForAgent } from '@apis/agent'
import { createTable, tableReorder } from '@apis/database'
import TabItem from '@/features/database/components/TableTabs/TabItem.tsx'
import { IconFont } from '@/components'
import TableCreateDropdown from '@/features/database/components/TableCreateDropdown.tsx'
import { useTable } from '@/features/database/provider/TableProvider.tsx'
import { useDatabase } from '@/features/database/provider/DatabaseProvider'
import { setFirstUserColumnToPrimary } from '@/features/database/utils/column.ts'

const ScrollBox = styled.div`
  overflow-x: scroll;
  overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`

function canScrollElement(el: HTMLDivElement | null): boolean {
  if (!el) return false

  return el.scrollWidth > el.offsetWidth
}

export default function Tabs() {
  const { baseInfo: database } = useDatabase()

  const { tables, refreshTables, setCurrentTableId, updateTables } = useTable()

  const scrollRef = useRef<HTMLDivElement | null>(null)
  const [canScroll, setCanScroll] = useState(false)
  const [isScrollRefSet, setScrollRefSet] = useState(false)

  const onTriggerToScroll = (direction: 'left' | 'right') => {
    const scrollEl = scrollRef.current
    if (!scrollEl) return
    const delta = scrollEl.offsetWidth / 2
    scrollEl.scrollBy({
      left: direction === 'left' ? -delta : delta,
      behavior: 'smooth',
    })
  }

  useLayoutEffect(() => {
    const scrollEl = scrollRef.current
    const checkAndSetScroll = () => setCanScroll(canScrollElement(scrollEl))

    checkAndSetScroll()

    window.addEventListener('resize', checkAndSetScroll)
    return () => window.removeEventListener('resize', checkAndSetScroll)
  }, [isScrollRefSet])

  const onTableSort = async (newIndex: number, oldIndex: number) => {
    if (newIndex === oldIndex) return

    let order: number
    const beforeTable = tables[newIndex - 1]
    const afterTable = tables[newIndex + 1]

    if (tables.length - 1 === newIndex && beforeTable) {
      order = beforeTable.order! + 1
    } else if (newIndex === 0) {
      order = afterTable.order! / 2
    } else {
      order = (beforeTable.order! + afterTable.order!) / 2
    }

    tableReorder(tables[oldIndex].id, order)
    const newTables = [...tables]
    newTables[oldIndex].order = order
    const [removed] = newTables.splice(oldIndex, 1)
    newTables.splice(newIndex, 0, removed)
    updateTables(newTables)
  }

  return (
    <div className='px-16 flex overflow-hidden w-full h-full items-end'>
      <div className='max-w-full relative flex overflow-hidden'>
        {canScroll && (
          <div
            onClick={() => onTriggerToScroll('left')}
            className='left w-40px h-40px shrink-0 flex-center absolute left-0 top--1px  z-3 cursor-pointer'
            style={{
              background:
                'linear-gradient(90deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 100%)',
            }}
          >
            <span className='w-16px h-16px rd-full bg-font bg-op-80 c-white text-8px flex-center'>
              <IconFont name='right-arrow' />
            </span>
          </div>
        )}
        <ScrollBox
          ref={el => {
            setScrollRefSet(true)
            scrollRef.current = el
          }}
          className={classNames('max-w-full', canScroll ? 'px-40' : '')}
        >
          <DragDropContext
            onDragEnd={result => {
              if (!result.destination) return
              onTableSort(result.destination.index, result.source.index)
            }}
          >
            <Droppable
              direction='horizontal'
              droppableId='database-tables-droppable'
            >
              {provider => (
                <div
                  {...provider.droppableProps}
                  ref={provider.innerRef}
                  className='max-w-full flex'
                >
                  {tables.map((tab, index) => (
                    <Draggable
                      key={tab.id}
                      isDragDisabled={tables.length <= 1}
                      index={index}
                      draggableId={tab.id}
                    >
                      {(provider, snapshot) => (
                        <TabItem
                          {...tab}
                          source_id={tab.source_id as string}
                          draggableProvider={provider}
                          draggableSnapshot={snapshot}
                          next_id={tables[index + 1]?.id}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provider.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </ScrollBox>
        {canScroll && (
          <div
            onClick={() => onTriggerToScroll('right')}
            className='right w-40px h-40px shrink-0 flex-center z-3 absolute top--1px right-0 cursor-pointer'
            style={{
              background:
                'linear-gradient(270deg, #ffffff 50%, rgba(255, 255, 255, 0) 100%)',
            }}
          >
            <span className='w-16px h-16px rd-full bg-font bg-op-80 c-white text-8px flex-center'>
              <IconFont name='left-arrow' />
            </span>
          </div>
        )}
      </div>
      {!!tables.length && (
        <div className='w-40px h-40px flex-center'>
          <TableCreateDropdown
            database={database}
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-expect-error
            onOk={async (...args) => {
              if (args.length === 3) {
                const [database_id, source_id, db_params] = args
                const table = await createTable(
                  database_id,
                  source_id,
                  db_params,
                )
                await setFirstUserColumnToPrimary(table.columns ?? [])
                await refreshTables()
                setCurrentTableId(table.id)
                bindTableForAgent({
                  database_id,
                  table_id: table.id,
                  source_id,
                  flow_id: null,
                  version_id: null,
                })
                return
              }
              await refreshTables()
              setCurrentTableId(args[0])
            }}
          >
            {open => (
              <span
                className={classNames(
                  'w-32px h-32px rd-6px cursor-pointer hover:bg-#F2F2F7 flex-center c-bg_3',
                  {
                    'bg-#F2F2F7': open,
                  },
                )}
              >
                <IconFont name='fangtai' />
              </span>
            )}
          </TableCreateDropdown>
        </div>
      )}
    </div>
  )
}
