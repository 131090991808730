import { Button, Form, Space, message } from 'antd'
import type { PasswordProps } from 'antd/es/input'
import { useCallback } from 'react'
import { useRequest } from 'ahooks'
import type { User } from '@apis/user/type'
import { updatePasswordWithVerifyCode } from '@apis/user'
import { IconFont, Input, InputPassword } from '@/components'
import { LoginButton } from '@/features/login/components/LoginButton'
import type { PasswordResetFormParams } from '@/features/login/components/PasswordResetForm'
import { useSendVerifyCode } from '@/hooks/useSendVerifyCode'
import { passwordValidateRegexp } from '@/constants/common'

const commonInputPasswordProps: PasswordProps = {
  className:
    'w-278px [&>.ant-input-suffix]:!text-16px [&>.ant-input-suffix]:text-[rgba(98,105,153,0.6)] [&>.ant-input-suffix]:hover:text-primary [&>.ant-input-suffix]:cursor-pointer',
  size: 'large',
  iconRender: (visible: boolean) =>
    visible ? <IconFont name='eye-show' /> : <IconFont name='eye-hide' />,
}

interface FormModifyPasswordProps {
  user: User
  onOk: VoidFunction
}

export function FormModifyPassword({ user, onOk }: FormModifyPasswordProps) {
  const [form] = Form.useForm<PasswordResetFormParams>()

  const { sending, isSendingSMS, verifyButtonText, onSendVerifyCode } =
    useSendVerifyCode()

  const { runAsync: resetPasswordApi, loading } = useRequest(
    updatePasswordWithVerifyCode,
    { manual: true },
  )

  const handleSendVerifyCode = async () => {
    const { phone } = await form.validateFields(['phone'])
    onSendVerifyCode({ phone })
  }

  const handleSubmit = useCallback(
    async (values: PasswordResetFormParams) => {
      const { phone, verifyCode, password, confirmPassword } = values
      if (user.userId && password === confirmPassword) {
        await resetPasswordApi({
          phone,
          verifyCode,
          password,
        })
        message.success('密码修改成功')
        onOk()
      }
    },
    [user, onOk],
  )

  return (
    <Form
      className='flex-1 p-20'
      form={form}
      layout='vertical'
      requiredMark={false}
      initialValues={{ phone: user.phone }}
      onFinish={handleSubmit}
    >
      <Form.Item
        className='[&_.ant-form-item-label]:font-medium'
        name='phone'
        label='手机号'
        required
        rules={[
          { required: true, message: '请输入手机号' },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: '请输入正确的手机号',
            validateTrigger: 'onSubmit',
          },
        ]}
      >
        <Input
          className='w-278px'
          size='large'
          type='text'
          placeholder='输入手机号'
          maxLength={11}
          disabled={!!user.phone}
        />
      </Form.Item>
      <Form.Item
        className='[&_.ant-form-item-label]:font-medium'
        name='verifyCode'
        label='验证码'
        required
      >
        <Space>
          <Form.Item noStyle>
            <Input
              size='large'
              style={{ width: 164 }}
              placeholder='输入验证码'
              maxLength={6}
            />
          </Form.Item>
          <LoginButton
            type='primary'
            size='large'
            disabled={isSendingSMS}
            className='w-102px rd-8px!'
            loading={sending}
            onClick={handleSendVerifyCode}
          >
            {verifyButtonText}
          </LoginButton>
        </Space>
      </Form.Item>
      <Form.Item
        className='[&_.ant-form-item-label]:font-medium [&_.ant-form-item-explain-error]:ml-0'
        name='password'
        label='新密码'
        rules={[
          {
            required: true,
            message: '密码输入错误，请输入6-16位密码（数字、字母）',
          },
          {
            pattern: passwordValidateRegexp,
            message: '密码输入错误，请输入6-16位密码（数字、字母）',
          },
        ]}
        validateFirst
      >
        <InputPassword
          {...commonInputPasswordProps}
          placeholder='设置密码，6-16位密码（数字、字母）'
        />
      </Form.Item>
      <Form.Item
        className='[&_.ant-form-item-label]:font-medium [&_.ant-form-item-explain-error]:ml-0'
        name='confirmPassword'
        label='确认新密码'
        rules={[
          {
            required: true,
            message: '密码输入错误，请输入6-16位密码（数字、字母）',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('password') === value) {
                return Promise.resolve()
              }
              return Promise.reject(new Error('两次输入密码不一致'))
            },
          }),
        ]}
        dependencies={['password']}
        validateFirst
      >
        <InputPassword
          {...commonInputPasswordProps}
          placeholder='设置密码，6-16位密码（数字、字母）'
        />
      </Form.Item>
      <Form.Item noStyle>
        <Button
          className='w-92px rounded-8px !text-14px font-medium hover:opacity-80'
          type='primary'
          size='large'
          htmlType='submit'
          loading={loading}
        >
          保存
        </Button>
      </Form.Item>
    </Form>
  )
}
