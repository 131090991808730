import type {
  AuthAppBase,
  AuthAppDingTalk,
  AuthAppWeChat,
} from '@bty/global-types/agent-go'
import { request } from '../../client'
import type {
  AuthAppDingTalkWeb,
  AuthAppWebChatOpenApp,
  AuthAppEnterpriseWebChat,
  AuthAppFlyBook,
  CreateAppPayload,
  UpdateAppPayload,
  GetFlowsWithFormResponse,
  AuthAppSource,
  AuthApp,
} from './type'

export function queryApp(
  auth_app_source: AuthAppWeChat['auth_app_source'],
  agent_id: string,
): Promise<AuthAppWeChat>
export function queryApp(
  auth_app_source: AuthAppDingTalk['auth_app_source'],
  agent_id: string,
): Promise<AuthAppDingTalk>
export function queryApp(
  auth_app_source: AuthAppDingTalkWeb['auth_app_source'],
  agent_id: string,
): Promise<AuthAppDingTalkWeb>
export function queryApp(
  auth_app_source: AuthAppWebChatOpenApp['auth_app_source'],
  agent_id: string,
): Promise<AuthAppWebChatOpenApp>
export function queryApp(
  auth_app_source: AuthAppEnterpriseWebChat['auth_app_source'],
  agent_id: string,
): Promise<AuthAppEnterpriseWebChat>
export function queryApp(
  auth_app_source: AuthAppFlyBook['auth_app_source'],
  agent_id: string,
): Promise<AuthAppFlyBook>
export function queryApp(auth_app_source: AuthAppSource, agent_id: string) {
  return request.get<AuthApp>(
    `/v1/agent/auth_app/${auth_app_source}/${agent_id}`,
  )
}

export function queryDingTalkAppConfigs(
  auth_app_source: AuthAppDingTalk['auth_app_source'],
  agent_id: string,
): Promise<AuthAppDingTalk[]> {
  return request.get(`/v1/agent/auth_app/${auth_app_source}/${agent_id}`)
}

export function queryWeChatAppConfig(corpId: string) {
  return request.get<AuthAppWeChat['auth_app_config']>(
    `/v1/agent/weixin_kf/find_by_corp_id?corp_id=${corpId}`,
  )
}

export function createApp(data: CreateAppPayload) {
  return request.post<AuthApp>('/v1/agent/auth_app/new', data)
}

export function updateApp(data: UpdateAppPayload) {
  return request.post<AuthApp>('/v1/agent/auth_app/edit', data)
}

export function appConnectTest(auth_app_id: string) {
  return request.get<AuthAppBase['conn_status']>(
    `/v1/agent/auth_app/conn_test/${auth_app_id}`,
  )
}

export function unbindApp(auth_app_id: string) {
  return request.delete(`/v1/agent/auth_app/${auth_app_id}`)
}

export function enableApp(auth_app_id: string, enabled = true) {
  return request.post('/v1/agent/auth_app/enable', { auth_app_id, enabled })
}

export function getBindAuthAppList(agent_id: string) {
  return request.get<Array<AuthAppDingTalk | AuthAppWeChat>>(
    `/v1/agent/auth_app/find_by_agent_id?agent_id=${agent_id}`,
  )
}

export function getFlowsWithForm(agent_id: string) {
  return request.get<GetFlowsWithFormResponse>(
    `/v1/flow/query_form_flow?agent_pk=${agent_id}`,
  )
}

export function updateFormTemplateForFlows(
  templates: { flow_id: string; template_id: string }[],
) {
  return request.post('/v1/flow/update/template_id', templates)
}
