import type { FC, PropsWithChildren } from 'react'
import { createContext, useState, useContext } from 'react'
import { useEventEmitter, useRequest } from 'ahooks'
import { noop } from 'lodash-es'
import type { TableType } from '@bty/smartsheet'
import { useNavigate, useParams } from 'react-router-dom'
import type { EventEmitter } from 'ahooks/lib/useEventEmitter'
import { getTables } from '@apis/database'
import { useTableRename } from '@/features/database/hooks/useTableRename.ts'
import useTableDetail from '../hooks/useTableDetail'

/**
 * table 基本数据相关
 */
interface Base {
  reloadDataEvent?: EventEmitter<void>
  tables: TableType[]
  currentTable?: TableType
  reloadCurrentTable: VoidFunction
  refreshTables: () => Promise<{ list: TableType[] }>
  updateTables: (tables: TableType[]) => void
  tablesLoading: boolean
  /**
   * table 激活
   */
  currentTableId?: string
  setCurrentTableId: (id: string) => void
}

type ContextProps = Base & ReturnType<typeof useTableRename>

export const TableContext = createContext<ContextProps>({
  tablesLoading: true,
  tables: [],
  renamingTableId: null,
  refreshTables: noop as any,
  updateTables: noop as any,
  setRenamingTableId: noop,
  onRename: noop as any,
  setCurrentTableId: noop,
  reloadCurrentTable: noop,
})

export const TableProvider: FC<PropsWithChildren<{ hideTables?: boolean }>> = ({
  hideTables,
  children,
}) => {
  const reloadData$ = useEventEmitter()

  const navigate = useNavigate()

  const { id: databaseId, workspaceId, tableId } = useParams()

  const [currentTableId, _setCurrentTableId] = useState<string>()

  const tableRename = useTableRename()

  const [tables, setTables] = useState<TableType[]>([])

  const setCurrentTableId = (tableId: string) => {
    navigate(
      `/db/${workspaceId}/${databaseId}/${tableId}${
        hideTables ? '?hide_tables' : ''
      }`,
    )
    _setCurrentTableId(tableId)
  }

  const { refreshAsync: refreshTables, loading: tablesLoading } = useRequest(
    getTables,
    {
      defaultParams: [databaseId!],
      onSuccess: data => {
        setTables(data.list ?? [])
        if (data.list.length && !currentTableId) {
          if (
            tableId &&
            data.list.findIndex(item => item.id === tableId) !== -1
          ) {
            setCurrentTableId(tableId)
          } else {
            setCurrentTableId(data.list[0].id)
          }
        }
      },
    },
  )

  const { table: activatedTable, reload: reloadCurrentTable } =
    useTableDetail(currentTableId)

  const index = tables.findIndex(el => el.id === activatedTable?.id)

  if (index >= 0 && activatedTable) {
    tables[index] = activatedTable
  }

  return (
    <TableContext.Provider
      value={{
        reloadDataEvent: reloadData$,
        tablesLoading,
        tables,
        currentTable: activatedTable,
        reloadCurrentTable,
        currentTableId,
        setCurrentTableId,
        refreshTables,
        updateTables: setTables,
        ...tableRename,
      }}
    >
      {children}
    </TableContext.Provider>
  )
}

export function useTable() {
  const context = useContext(TableContext)
  if (context === null) {
    throw new Error('useTable 必须在 TableProvider 中使用')
  }

  return context
}
