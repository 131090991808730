import type { PromptStructItem } from '@apis/prompt/type'
import type { Variable } from '@/features/editor'
import { PromptStructEditor } from '@/features/prompt/components/PromptStructEditor/PromptStructEditor'

interface LLMPromptStructEditorProps {
  variables?: Variable[]
  variableTipsContainer?: HTMLElement | null
  value?: PromptStructItem[]
  onChange?: (value: PromptStructItem[]) => void
}

export function LLMPromptStructEditor(props: LLMPromptStructEditorProps) {
  const { value = [], onChange, variables, variableTipsContainer } = props

  return (
    <div className='max-h-400px overflow-auto scrollbar scrollbar-w-0 nopan nowheel'>
      <PromptStructEditor
        mini
        value={value}
        variables={variables}
        variableTipsContainer={variableTipsContainer}
        anchor='left'
        onChange={onChange}
      />
    </div>
  )
}
