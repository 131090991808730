import { useRequest } from 'ahooks'
import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { getDraftFlowById } from '@apis/flow'
import { useFlowDraftStore } from '@/store'

export function useFlowPreviewInit() {
  const { id } = useParams()
  const { initFlow } = useFlowDraftStore()

  const { data: flowDataRes, runAsync: fetchDraftFlow } = useRequest(
    () =>
      getDraftFlowById(id as string, {
        headers: {
          'Application-Id': id,
        },
      }),
    {
      refreshDeps: [id],
      ready: !!id,
    },
  )

  useEffect(() => {
    if (flowDataRes) {
      initFlow(flowDataRes)
    }
  }, [flowDataRes])

  return {
    flowDataRes,
    fetchDraftFlow,
  }
}
