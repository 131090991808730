import { useBoolean, useCountDown, useRequest } from 'ahooks'
import { useMemo, useState } from 'react'
import { sendVerifyCode } from '@apis/sms'

const COUNT_DOWN_TIME = 60

interface SendVerifyCodeOptions {
  // sendApi?: Service<U, [T]>
  countdown?: number
}

export function useSendVerifyCode(options: SendVerifyCodeOptions = {}) {
  const { countdown = COUNT_DOWN_TIME } = options
  const [isSendingSMS, { toggle: toggleSendingSMS }] = useBoolean(false)
  const [leftTime, setLeftTime] = useState<number>()
  const { runAsync: sendVerifyCodeApi, loading: sending } = useRequest(
    sendVerifyCode,
    { manual: true },
  )

  const [_, formattedRes] = useCountDown({
    leftTime: leftTime ? leftTime * 1000 : undefined,
    onEnd() {
      toggleSendingSMS()
      setLeftTime(0)
    },
  })

  const verifyButtonText = useMemo(
    () =>
      sending
        ? '获取中'
        : isSendingSMS
          ? `${formattedRes.seconds} S`
          : '获取验证码',
    [isSendingSMS, formattedRes, sending],
  )

  const onCountdownStart = () => {
    toggleSendingSMS()
    setLeftTime(countdown)
  }

  const onSendVerifyCode = async (data: { phone: string }) => {
    await sendVerifyCodeApi(data)
    onCountdownStart()
  }

  return {
    sending,
    isSendingSMS,
    verifyButtonText,
    onCountdownStart,
    onSendVerifyCode,
  }
}
