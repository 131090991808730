import { Button, Popover, Tooltip, type TooltipProps } from 'antd'
import styled from '@emotion/styled'
import { useAuth } from '@bty/react-auth'
import { RoleCode } from '@apis/authority/type'
import type { litmitedTitleConfig } from '../limitedConfig'
import { Version } from '../../const'
import customerServiceQRCode from '@/assets/customerServiceQRCode.png'
import { useVersion } from '@/hooks/useVersion'

interface getActionButtonProps {
  product_code: Version | undefined
  config: litmitedTitleConfig
  onButtonClick: () => void
  createOrderLoading?: boolean
}

const StyledButton = styled(Button)`
  border: none;
  width: 100%;
  padding-top: 13px;
  padding-bottom: 13px;
  height: 40px;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  line-height: 14px;
  text-align: center;
  letter-spacing: 0px;
  color: #fff;
  background: #7b61ff;
  cursor: pointer;
  &.enterprise-action-button {
    background: #3860ff;
  }
  &.ant-btn-default:not(:disabled):not(.ant-btn-disabled):hover {
    opacity: 0.6;
    color: #fff;
    background: #7b61ff;
    &.enterprise-action-button {
      background: #3860ff;
    }
  }
  &.ant-btn-default:disabled {
    background: rgba(98, 105, 153, 0.12);
    color: rgba(141, 141, 153, 1);
    cursor: not-allowed;
    border-color: rgba(98, 105, 153, 0.12);
    &:hover {
      opacity: 1;
      color: rgba(141, 141, 153, 0.4);
    }
  }
`

function EnterpriseActionButton() {
  const {
    state: { role },
  } = useAuth()

  return (
    <Popover
      trigger='click'
      placement='bottom'
      content={
        <div>
          <img className='w-136px h-136px' src={customerServiceQRCode} alt='' />
          <div className='mt-12px text-center'>微信扫码咨询</div>
        </div>
      }
    >
      <Tooltip
        title={role !== RoleCode.ADMINISTRATOR && '请联系管理员升级版本'}
        placement='bottom'
      >
        <StyledButton
          className='enterprise-action-button'
          disabled={role !== RoleCode.ADMINISTRATOR}
        >
          立刻咨询
        </StyledButton>
      </Tooltip>
    </Popover>
  )
}

interface ActionTooltipButtonProps {
  title: TooltipProps['title']
  onClick: () => void
  loading?: boolean
  disabled?: boolean
  children?: React.ReactNode
}

function ActionTooltipButton({
  title,
  onClick,
  loading = false,
  disabled = false,
  children,
}: ActionTooltipButtonProps) {
  return (
    <Tooltip title={title} placement='bottom'>
      <StyledButton onClick={onClick} loading={loading} disabled={disabled}>
        {children}
      </StyledButton>
    </Tooltip>
  )
}

export function ActionButton(props: getActionButtonProps) {
  const { product_code, config, onButtonClick, createOrderLoading } = props
  const {
    state: { role },
  } = useAuth()

  const { isExpired, isPointExhausted } = useVersion()
  const isAdmin = role === RoleCode.ADMINISTRATOR
  const disabledTooltip = '请联系管理员升级版本'

  if (product_code === Version.FREE) {
    switch (config.version) {
      case Version.FREE:
        return (
          <StyledButton disabled>
            {isExpired ? '已过期' : '当前版本'}
          </StyledButton>
        )
      case Version.TEAM:
        return (
          <ActionTooltipButton
            title={!isAdmin && disabledTooltip}
            onClick={onButtonClick}
            loading={createOrderLoading}
            disabled={!isAdmin}
          >
            立刻订购
          </ActionTooltipButton>
        )
      case Version.ENTERPRISE:
        return <EnterpriseActionButton />
    }
  }

  if (product_code === Version.TEAM) {
    switch (config.version) {
      case Version.FREE:
        return ''
      case Version.TEAM:
        return isExpired ? (
          <ActionTooltipButton
            title={!isAdmin && disabledTooltip}
            onClick={onButtonClick}
            loading={createOrderLoading}
            disabled={!isAdmin}
          >
            立刻订购
          </ActionTooltipButton>
        ) : (
          <StyledButton disabled>当前版本</StyledButton>
        )
      case Version.ENTERPRISE:
        return <EnterpriseActionButton />
    }
  }

  if (product_code === Version.ENTERPRISE) {
    switch (config.version) {
      case Version.FREE:
        return ''
      case Version.TEAM:
        return ''
      case Version.ENTERPRISE:
        // 企业版的已过期和积分用尽都显示立即咨询
        return isExpired || isPointExhausted ? (
          <EnterpriseActionButton />
        ) : (
          <StyledButton disabled>当前版本</StyledButton>
        )
    }
  }

  return <StyledButton>正在试用</StyledButton>
}
