import React, { useCallback, useEffect, useState } from 'react'
import { generateBotMessage } from '@bty/chat-renderer-pc'
import { flatten } from 'lodash-es'
import { getPushMsg } from '@apis/plugins'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider'
import {
  PluginList,
  type TipDataProps,
} from '@/features/agent/SkillTabs/PluginList'

function InternalAgentPlugin() {
  const { chatRef, ruleRef, applicationInfo, skillConfigContext } =
    useAgentEdit()
  const { toolList, skillConfigMap, refresh } = skillConfigContext

  const [tipsData, setTipData] = useState<TipDataProps[]>([])

  const handleTipsData = useCallback((value: TipDataProps[]) => {
    setTipData(value || [])
  }, [])

  const handleListChange = useCallback(() => {
    refresh()
    ruleRef.current?.refreshRuleConfig()
    setTimeout(() => {
      chatRef?.current?.refetch()
    }, 500)
  }, [refresh])

  const insertPluginTips = useCallback(async (newTipsData: any[]) => {
    const res = await chatRef.current?.fetchConversationList()

    const conversationId = res?.data[0]?.conversation_id

    if (newTipsData?.length && conversationId) {
      const content = `我学会了${newTipsData.length}个新技能：${newTipsData
        .map(v => v.label)
        ?.join('、')}，你可以这样问我试试`

      chatRef.current?.chatMessage.insert([
        generateBotMessage({
          content,
          questionGuide: flatten(newTipsData.map(v => v.content)),
          showRegenerateBtn: false,
        }),
      ])
      setTimeout(() => {
        getPushMsg({
          content,
          tips: flatten(newTipsData.map(v => v.content)) ?? [],
          to_context: false,
          conversation_id: conversationId,
        })
      }, 2000)
    }
  }, [])

  useEffect(() => {
    const tipsString = sessionStorage.getItem('tipsData')
    if (tipsString) {
      setTipData(JSON.parse(tipsString) || [])
      sessionStorage.removeItem('tipsData')
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      insertPluginTips(tipsData)
    }, 1000)
  }, [tipsData])

  if (!applicationInfo) return null

  return (
    <PluginList
      flowInfo={applicationInfo}
      list={toolList}
      selectedSkillMap={skillConfigMap}
      onListChange={handleListChange}
      handleTipsData={handleTipsData}
    />
  )
}

export const AgentPlugin = React.memo(InternalAgentPlugin)
