import { useMemoizedFn, useRequest } from 'ahooks'
import { Spin, message } from 'antd'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import classNames from 'classnames'
import { fetchEmbeddingModelList, getDataStoreList } from '@apis/datastore'
import { PartitionCategoryType } from '@apis/datastore/type'
import type { IEmbeddingModalItem, DataStoreItem } from '@apis/datastore/type'
import { forkDataSet } from '@apis/application'
import {
  CreateDataStoreDatasetModal,
  getPartitionAvatarInfo,
} from '@/features/datastore/CreateDataStoreModal/DatasetModal'
import { useDataStoreCreate } from '@/features/datastore/hooks/useDataStoreCreate.ts'
import { useModal } from '@/hooks/useModal.tsx'
import { useVersionStore, useWorkspaceStore } from '@/store'
import { ResponsiveCardGrid } from '@/components/list/ResponsiveCardGrid.tsx'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar.ts'
import { useWorkspaceSelectModal } from '../application/components/WorkSpaceSelect'
import { LimitedAlertBar } from '@/features/pay/LimitedAlert'
import { CreateDataStoreDocumentsModal } from '@/features/datastore/CreateDataStoreModal/DocumentsModal'
import { CreateType } from '@/features/datastore/constant'
import { DATASTORE_GROUP } from '@/constants/storage'
import { noGroupId, useGroup } from '@/hooks/useGroup'
import { CreateDataStoreCard } from './CreateDataStoreCard'
import { DatastorePageHeader } from './PageHeader'
import { DatastoreListItem } from './DatastoreListItem'
import { DatastoreListGroupHeader } from './DatastoreListGroupHeader'

export default function Page() {
  const navigate = useNavigate()

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const {
    data: datastores,
    loading: datastoresLoading,
    runAsync: reloadDatastoresInner,
  } = useRequest(getDataStoreList, {
    refreshDeps: [currentWorkspaceId],
  })

  const [searchKey, setSearchKey] = useState<string>()

  const filteredDatastores = useMemo(() => {
    if (!searchKey) {
      return datastores?.partitions
    }
    return datastores?.partitions?.filter(item =>
      item.partition_name.toLowerCase().includes(searchKey.toLowerCase()),
    )
  }, [searchKey, datastores])

  const reloadDatastores = useMemoizedFn(async () => {
    const res = await reloadDatastoresInner()
    return res.partitions
  })

  const {
    list,
    createGroup,
    updateGroup,
    groupLoading,
    DragType,
    setDragType,
    dragItem,
    dragOverItem,
    setDragOverItem,
    phantomItem,
    handleDragStart,
    handleDrop,
    removeToGroup,
    DropdownMenu,
    reSet,
    openGroupMap,
    setOpenGroupMap,
  } = useGroup<DataStoreItem>({
    originList: filteredDatastores,
    applicationType: 'DATASET',
    refresh: reloadDatastores,
    groupType: DATASTORE_GROUP,
    appIdKey: 'partition_id',
    groupIdKey: 'partition_group_id',
  })

  const { runAsync: forkDataSetApi, loading: copyLoading } = useRequest(
    forkDataSet,
    {
      manual: true,
    },
  )
  const { fetchVersionByWorkspaceId } = useVersionStore()

  const [searchParams] = useSearchParams()

  const { data: embeddingModelList } = useRequest(fetchEmbeddingModelList)

  const embeddingModelInfo = useMemo(() => {
    return embeddingModelList?.reduce((re, item: IEmbeddingModalItem) => {
      re[item.model_name] = {
        name: item.model_display_name,
        icon: item.black_icon,
      }
      return re
    }, {})
  }, [embeddingModelList])

  const { onCreate: onDataStoreCreateApi, loading: datastoreCreateLoading } =
    useDataStoreCreate()

  const [createDatastoreDatasetModal] = useModal(CreateDataStoreDatasetModal, {
    okButtonProps: { loading: datastoreCreateLoading },
    embeddingModelList,
  })

  const [createDataStoreDocumentsModal] = useModal(
    CreateDataStoreDocumentsModal,
    {
      okButtonProps: {
        loading: datastoreCreateLoading,
      },
    },
  )

  const handleCreate = useMemoizedFn(
    (
      type: PartitionCategoryType = PartitionCategoryType.Document,
      actionType?: CreateType,
      groupId?: string,
    ) => {
      const isDataset = actionType !== CreateType.CREATE_PARTITION
      const name = type === PartitionCategoryType.QA ? '问答' : '文档'
      const title = isDataset ? `新建${name}知识库` : '添加文档'
      const index = (filteredDatastores?.length || 0) + 1

      if (isDataset) {
        createDatastoreDatasetModal.open({
          title,
          defaultValue: {
            partition_name: `未命名${name}知识库${index}`,
            partition_category: type ?? PartitionCategoryType.Document,
            partition_group_id: groupId,
          },
          onFinish: async value => {
            const res = await onDataStoreCreateApi(value)
            createDatastoreDatasetModal.close()
            navigate(
              `/datastores/${currentWorkspaceId}/${res.partition_id}/documents`,
            )
          },
        })
      } else {
        createDataStoreDocumentsModal.open({
          title,
          datastoreType: type,
          onFinish: async value => {
            if (!value) {
              return
            }
            const res = await onDataStoreCreateApi({
              ...value,
              partition: {
                ...value?.partition,
                partition_name: `未命名${name}知识库${index}`,
                partition_category: type,
                ...getPartitionAvatarInfo(type),
                partition_group_id: groupId,
              },
            })
            createDataStoreDocumentsModal.close()
            navigate(
              `/datastores/${currentWorkspaceId}/${res.partition_id}/documents`,
            )
          },
        })
      }
    },
  )

  useEffect(() => {
    fetchVersionByWorkspaceId(currentWorkspaceId)
    if (searchParams.get('create')) {
      handleCreate(PartitionCategoryType.Document)
    }
  }, [])

  const handleDataBaseCopy = async (
    target_workspace_id: string,
    partition_id: number,
  ) => {
    await forkDataSetApi({
      source_partition_id: partition_id?.toString() || '',
      source_workspace_id: currentWorkspaceId,
      target_workspace_id,
    })
    onForkSuccess()
    reloadDatastores()
    message.success('复制成功')
  }

  const [copyModal] = useWorkspaceSelectModal({
    onConfirm: handleDataBaseCopy,
    defaultWorkspaceId: currentWorkspaceId,
    copyType: '复制知识库',
    loading: copyLoading,
  })

  function onForkSuccess() {
    copyModal.close()
  }

  const emptyElement = useMemo(() => {
    return (
      <div className='h-full flex w-full flex-1'>
        <CreateDataStoreCard
          create={type => handleCreate(type, CreateType.CREATE_PARTITION)}
        />
      </div>
    )
  }, [])

  const handleNavigate = useCallback(
    (partition_id: number) => {
      navigate(`/datastores/${currentWorkspaceId}/${partition_id}/documents`)
    },
    [currentWorkspaceId],
  )

  const onOpenGroupChange = useCallback((groupId: string) => {
    setOpenGroupMap(prev => ({
      ...prev,
      [groupId]: !prev[groupId],
    }))
  }, [])

  const pageLoading = datastoresLoading || groupLoading

  return (
    <div className='adapt:pt-40 bg-bg rounded-12px flex-1 overflow-hidden w-full h-full flex flex-col'>
      <DatastorePageHeader
        searchKey={searchKey}
        onSearch={setSearchKey}
        onCreate={handleCreate}
        onGroupCreate={createGroup}
      />
      <OverlayScrollbarsComponent
        className='adapt:px-64 flex-1 overflow-y-auto'
        element='div'
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
      >
        <LimitedAlertBar className='mb-20' />
        {pageLoading ? (
          <div className='absolute top-0 left-0 w-full h-full flex-center'>
            <Spin spinning={pageLoading} />
          </div>
        ) : filteredDatastores?.length ? (
          <div>
            {list.map((group, index, self) => {
              const hasGroupHeader =
                self.length > 1 &&
                (group.id !== noGroupId ||
                  (group.id === noGroupId && !!group.children?.length))
              const notLast = index !== self.length - 1

              return (
                <div
                  key={group.id}
                  className={classNames(
                    'group-item px-4 py-0',
                    dragItem.id === group.id ? 'op-40' : 'op-100',
                    {
                      'border-top after:border-line after:border-op-32':
                        index > 0,
                      'bg-primary bg-op-4 border-2px border-solid border-primary rounded-8px':
                        dragOverItem.id === group.id && DragType === 'join',
                      'border-t-2px border-t-solid border-t-primary':
                        dragOverItem.id === group.id && DragType === 'sort',
                    },
                  )}
                  onDragOver={e => {
                    e.preventDefault()
                    group.id !== dragItem?.id && setDragOverItem(group)
                  }} // 解决拖拽结束后渲染滞后的问题
                  onDrop={async e => handleDrop(e, group)}
                  onDragEnd={reSet}
                >
                  {hasGroupHeader && (
                    <DatastoreListGroupHeader
                      data={group}
                      collapsed={!openGroupMap[group.id]}
                      draggable={!!DragType}
                      showDragHandle={notLast}
                      showDragOverlay={phantomItem.id === group.id}
                      showMoreActions={notLast}
                      dropdownComponent={DropdownMenu}
                      onDragTypeChange={setDragType}
                      onDragStart={handleDragStart}
                      onDragEnd={reSet}
                      onClick={onOpenGroupChange}
                      onCreate={handleCreate}
                      onRename={updateGroup}
                    />
                  )}
                  {openGroupMap[group.id] && !!group.children?.length && (
                    <ResponsiveCardGrid
                      className={classNames('content-start pb-20', {
                        'pt-20': !hasGroupHeader,
                      })}
                    >
                      {group.children.map(item => (
                        <DatastoreListItem
                          key={item.partition_id}
                          data={item as unknown as DataStoreItem}
                          dragging={dragItem.partition_id === item.partition_id}
                          embeddingModels={embeddingModelInfo}
                          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                          // @ts-expect-error
                          moveToGroup={removeToGroup}
                          onDragStart={handleDragStart}
                          onClick={handleNavigate}
                          onFork={copyModal.open}
                          onDelete={reloadDatastores}
                        />
                      ))}
                    </ResponsiveCardGrid>
                  )}
                </div>
              )
            })}
          </div>
        ) : (
          emptyElement
        )}
      </OverlayScrollbarsComponent>
    </div>
  )
}
