import type { CollapseProps } from 'antd'
import { useEffect, useMemo, useState } from 'react'
import { FlowStatus } from '@apis/flow/type'
import { LoggerLabel } from './components/LoggerLabel'
import { StepItem } from './components/StepItem'
import { LoggerCollapse } from './components/LoggerCollapse'
import type { FlowOutput } from './types'

interface FlowLoggerProps {
  width?: string
  defaultActiveKey?: string[]
  activeKey?: Array<string | number>
  outputs: FlowOutput[]
  onChange?: (flowOutput: FlowOutput, open: boolean) => void
  onEditorEnter?: () => void
  onEditorLeave?: () => void
}

function diffArray(array1: any[], array2: any[]) {
  const diff = array1
    .filter(x => !array2.includes(x))
    .concat(array2.filter(x => !array1.includes(x)))

  return diff
}

function generateItems(
  outputs: FlowOutput[],
  onEditorEnter?: () => void,
  onEditorLeave?: () => void,
  onChange?: (flowOutput: FlowOutput, open: boolean) => void,
): CollapseProps['items'] {
  return outputs.map((flowOutput, index) => {
    const { name, status, duration, children, value } = flowOutput

    return {
      key: value || name,
      label: (
        <LoggerLabel
          isNode
          name={name}
          status={status}
          duration={duration}
          showTime={flowOutput.showTime}
        />
      ),
      children: (
        <>
          <div className='ml-12 relative  my-4'>
            <div className='h-[calc(100%_+_6px)] w-1px b-l-1 b-line absolute left--8px top--2px'></div>
            {children?.length ? (
              <FlowLogger outputs={children} onChange={onChange} />
            ) : (
              <StepItem
                flowOutput={flowOutput}
                ignoreInput={
                  outputs[index - 1]?.status === FlowStatus.FAIL &&
                  status === FlowStatus.FAIL
                }
                onEditorEnter={onEditorEnter}
                onEditorLeave={onEditorLeave}
              />
            )}
          </div>
        </>
      ),
    }
  })
}

export function FlowLogger(props: FlowLoggerProps) {
  const {
    width,
    outputs,
    defaultActiveKey = [],
    activeKey,
    onChange,
    onEditorEnter,
    onEditorLeave,
  } = props

  const items = useMemo(() => {
    return generateItems(outputs, onEditorEnter, onEditorLeave, onChange)
  }, [outputs])

  const trueDefaultActiveKey = useMemo(() => {
    return defaultActiveKey.map(
      key => outputs.find(output => output.name === key)?.value || key,
    )
  }, [defaultActiveKey, outputs])

  const [expandedNodeKeys, setExpandedNodeKeys] =
    useState<CollapseProps['defaultActiveKey']>(trueDefaultActiveKey)

  useEffect(() => {
    activeKey && setExpandedNodeKeys(activeKey)
  }, [activeKey])

  const onCollapseChange = (keys: string | string[]) => {
    const isOpen = keys.length > (expandedNodeKeys as string[])?.length
    const diff = diffArray(keys as string[], expandedNodeKeys as string[])

    const diffOutput = outputs.find(v => v.value === diff?.[0])
    if (diffOutput) {
      onChange?.(diffOutput, isOpen)
    }

    setExpandedNodeKeys(keys as string[])
  }

  return (
    <div className='bg-white' style={{ width: width ?? '100%' }}>
      <LoggerCollapse
        defaultActiveKey={trueDefaultActiveKey}
        activeKey={expandedNodeKeys}
        size='small'
        items={items}
        onChange={onCollapseChange}
      />
    </div>
  )
}
