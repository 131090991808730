import { useMemo, useState } from 'react'
import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import type { TableColumnsType } from 'antd'
import { getFlowExecuteLog } from '@apis/flow'
import type {
  AgentChatRecordItem,
  AppMonitorType,
  AppStabilityType,
  OvertimeRecordItem,
} from '@apis/monitor/type'
import { StaticTimeTypes } from '@apis/monitor/type'
import {
  fetchAgentChatRecords,
  getAppMonitorOvertimeDetail,
  queryAppStability,
  queryIrlTrend,
} from '@apis/monitor'
import type { LogItem, logType } from '@apis/run/type'
import { getLog } from '@apis/run'
import {
  ChartDashboardPane,
  DEFAULT_CUSTOM_DATE,
} from '@/features/agent/AgentAnalysis/ChartDashboardPane'
import MonitorDrawer from '@/components/MonitorDrawer'
import { useAgentEdit } from '@/features/agent/provider/AgentEditProvider'
import { useMonitorDetailReq } from '../hooks/useMonitorDetailReq'
import {
  LoggerTitle,
  LoggerModal,
} from '@/features/logger/components/loggerModal'
import { useGoDraftPageWithInput } from '@/pages/flowPage/hooks/useGoDraftPageWithInput'
import { AgentRunTypeMap, FlowRunTypeMap, TIME_FORMAT } from '../constants'
import { LogModal } from '@/pages/agent/components/LogModal'
import { AppStabilityPaneContext } from './AppStabilityPaneContext'

interface AppStabilityPaneProps {
  flowId: string
  flowType: AppMonitorType
  runType?: string[]
  onLogJump: (date: string) => void
}

function getErrorRecordColumns(options: {
  flowType: AppMonitorType
  onOvertimeItemEnter: (value: {
    id: string | number
    overtime?: number
  }) => void
}) {
  const { flowType, onOvertimeItemEnter } = options

  const runTypeMap = flowType === 'AGENT' ? AgentRunTypeMap : FlowRunTypeMap

  const columns: TableColumnsType<
    AgentChatRecordItem | logType['data'][number]
  > = [
    {
      title: '错误记录时间',
      dataIndex: flowType === 'AGENT' ? 'create_time' : 'created_at',
      render: value =>
        value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '',
    },
    {
      title: '用户',
      dataIndex: 'user_name',
    },
    {
      title: flowType === 'AGENT' ? '运行方式' : '任务类型',
      dataIndex: 'run_type',
      render: value => {
        const label = runTypeMap[value]
        return label || value
      },
    },
    {
      title: '操作',
      key: 'action',
      render: (_, record) => {
        return (
          <div
            className='c-#7b61ff cursor-pointer'
            onClick={e => {
              e.stopPropagation()
              onOvertimeItemEnter({
                id:
                  flowType === 'AGENT'
                    ? (record as AgentChatRecordItem).record_id
                    : (record as logType['data'][number]).task_id,
              })
            }}
          >
            查看详情
          </div>
        )
      },
    },
  ]
  return columns
}

function getOvertimeDetailColumns(options: {
  type: AppMonitorType
  onOvertimeItemEnter: (value: {
    id: string | number
    overtime: number
  }) => void
}) {
  const { onOvertimeItemEnter } = options

  const runTypeMap = options.type === 'AGENT' ? AgentRunTypeMap : FlowRunTypeMap

  const columns: TableColumnsType<OvertimeRecordItem> = [
    {
      title: '异常记录时间',
      width: 170,
      dataIndex: 'created_at',
      key: 'created_at',
      render: (_, record) => {
        return dayjs(record.created_at).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: '响应时长',
      dataIndex: 'irl_time',
      key: 'irl_time',
      render: (_, { irl_time }) => `${irl_time}s`,
    },
    {
      title: '用户',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: '应用名称',
      dataIndex: 'flow_name',
      key: 'flow_name',
    },
    {
      title: '运行方式',
      dataIndex: 'run_type',
      render: value => {
        const label = runTypeMap[value]
        return label || value
      },
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { biz_id, irl_time }) => {
        return (
          <div
            className='c-#7b61ff cursor-pointer'
            onClick={e => {
              e.stopPropagation()
              onOvertimeItemEnter({
                id: biz_id,
                overtime: irl_time,
              })
            }}
          >
            查看详情
          </div>
        )
      },
    },
  ]
  return columns
}

function transformStatisticTime(
  flowType: AppMonitorType,
  value: StaticTimeTypes,
) {
  const startDateKey = flowType === 'AGENT' ? 'start_date' : 'startTime'
  const endDateKey = flowType === 'AGENT' ? 'end_date' : 'endTime'

  switch (value) {
    case StaticTimeTypes.WEEKLY: {
      const start = dayjs().subtract(1, 'week')
      const end = dayjs()
      return {
        [startDateKey]:
          flowType === 'AGENT'
            ? start.toISOString()
            : start.format(TIME_FORMAT),
        [endDateKey]:
          flowType === 'AGENT' ? end.toISOString() : end.format(TIME_FORMAT),
      }
    }
    case StaticTimeTypes.MONTHLY: {
      const start = dayjs().subtract(1, 'month')
      const end = dayjs()
      return {
        [startDateKey]:
          flowType === 'AGENT'
            ? start.toISOString()
            : start.format(TIME_FORMAT),
        [endDateKey]:
          flowType === 'AGENT' ? end.toISOString() : end.format(TIME_FORMAT),
      }
    }
    case StaticTimeTypes.QUARTERLY: {
      const start = dayjs().subtract(3, 'month')
      const end = dayjs()
      return {
        [startDateKey]:
          flowType === 'AGENT'
            ? start.toISOString()
            : start.format(TIME_FORMAT),
        [endDateKey]:
          flowType === 'AGENT' ? end.toISOString() : end.format(TIME_FORMAT),
      }
    }
    case StaticTimeTypes.YEARLY: {
      const start = dayjs().subtract(1, 'year')
      const end = dayjs()
      return {
        [startDateKey]:
          flowType === 'AGENT'
            ? start.toISOString()
            : start.format(TIME_FORMAT),
        [endDateKey]:
          flowType === 'AGENT' ? end.toISOString() : end.format(TIME_FORMAT),
      }
    }
  }
}

export function AppStabilityPane({
  flowId,
  flowType,
  runType,
}: AppStabilityPaneProps) {
  const { applicationInfo } = useAgentEdit()
  const { goDraftPage } = useGoDraftPageWithInput()

  const [timeParams, setTimeParams] = useState(() => {
    const startDate = DEFAULT_CUSTOM_DATE[0]
      ?.startOf('d')
      .format('YYYY-MM-DD HH:mm:ss')
    const endDate = DEFAULT_CUSTOM_DATE[1]
      ?.endOf('d')
      .format('YYYY-MM-DD HH:mm:ss')
    return { type: StaticTimeTypes.CUSTOM, startDate, endDate }
  })

  const [errorRecordModalOpen, setErrorRecordModalOpen] = useState(false)

  const [errorDetailModalOpen, setErrorDetailModalOpen] = useState(false)

  const [chatRecordsModalOpen, setChatRecordsModalOpen] = useState(false)

  const [flowLogModalOpen, setFlowLogModalOpen] = useState(false)

  const [recordId, setChatRecordId] = useState<string>()

  // const { runAsync: fetchChatRecords, data: chatRecords } = useRequest(
  //   getPrevChatRecordsById,
  //   { manual: true },
  // )
  const { runAsync: fetchFlowLogDetail, data: logData } = useRequest(
    getFlowExecuteLog,
    { manual: true },
  )

  const handleOvertimeItemEnter = async (value: {
    id: string | number
    overtime?: number
  }) => {
    if (flowType === 'AGENT') {
      setChatRecordId(value.id as string)
      // await fetchChatRecords({
      //   record_id: value.id,
      //   limit: 5,
      // })
      setChatRecordsModalOpen(true)
    } else {
      await fetchFlowLogDetail({
        flowId,
        taskId: value.id,
      })
      setFlowLogModalOpen(true)
    }
  }

  const baseParams = useMemo(() => {
    return {
      flow_id: flowId,
      flow_type: flowType,
      run_type: runType,
      page: 1,
      page_size: 10,
    }
  }, [flowId, flowType, runType])

  const errorRecordsParams = useMemo(() => {
    const runTypeKey = flowType === 'AGENT' ? 'run_types' : 'runTypes'
    const idKey = flowType === 'AGENT' ? 'robot_id' : 'flowId'
    const runStatusKey = flowType === 'AGENT' ? 'record_status' : 'run_status'
    const ret = {
      [idKey]: flowId,
      [runTypeKey]: runType,
      [runStatusKey]: 'FAILED',
      page: 1,
      page_size: 10,
    }
    return ret
  }, [flowId, flowType, runType])

  const { fetchList, ...rest } = useMonitorDetailReq({
    api: getAppMonitorOvertimeDetail,
    defaultParams: {
      ...baseParams,
      // statistic_time: StaticTimeTypes.YEARLY,
    },
    startDateKey: 'start_date',
    endDateKey: 'end_date',
    outerTimeType: timeParams.type,
    open: errorDetailModalOpen,
    transformDateString: date => {
      return dayjs(date).format(TIME_FORMAT)
    },
  })

  const { fetchList: fetchErrorRecords, ...restForAgent } = useMonitorDetailReq(
    {
      // api: flowType === 'AGENT' ? fetchAgentLogs : (getLog as any),
      api: flowType === 'AGENT' ? fetchAgentChatRecords : (getLog as any),
      open: errorRecordModalOpen,
      defaultParams: errorRecordsParams,
      outerTimeType: timeParams.type,
      dataKey: 'data',
      totalKey: 'total',
      pageKey: flowType === 'AGENT' ? 'page_no' : 'pageNo',
      pageSizeKey: flowType === 'AGENT' ? 'page_size' : 'pageSize',
      startDateKey: flowType === 'AGENT' ? 'start_time' : 'startTime',
      endDateKey: flowType === 'AGENT' ? 'end_time' : 'endTime',
      transformDateString: date => {
        return dayjs(date).format(TIME_FORMAT)
      },
      transformStatisticTime: value => transformStatisticTime(flowType, value),
    },
  )

  const onDetailClick = async (index: number) => {
    if (index === 0) {
      const startDateKey = flowType === 'AGENT' ? 'start_date' : 'startTime'
      const endDateKey = flowType === 'AGENT' ? 'end_date' : 'endTime'
      const time =
        timeParams.type === StaticTimeTypes.CUSTOM
          ? {
              [startDateKey]: timeParams.startDate,
              [endDateKey]: timeParams.endDate,
            }
          : transformStatisticTime(flowType, timeParams.type)

      await fetchErrorRecords({
        ...errorRecordsParams,
        ...time,
        page: 1,
        page_size: 10,
      } as any)

      setErrorRecordModalOpen(true)

      return
    }

    if (index === 1) {
      const params = {
        ...baseParams,
        statistic_time: timeParams.type,
        page: 1,
        page_size: 10,
      }
      if (timeParams.type === StaticTimeTypes.CUSTOM) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        params.start_date = timeParams.startDate
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        params.end_date = timeParams.endDate
      }
      await fetchList(params)
      setErrorDetailModalOpen(true)
    }
  }

  const params = useMemo(
    () => ({
      flow_id: flowId,
      flow_type: flowType,
      run_type: runType,
      statistic_time: 'weekly',
      display_time: 'daily',
    }),
    [flowId, flowType, runType],
  )

  const contextValue = useMemo(
    () => ({
      id: flowId,
    }),
    [flowId],
  )

  return (
    <AppStabilityPaneContext.Provider value={contextValue}>
      <ChartDashboardPane<AppStabilityType>
        className='mt-24'
        type={['area', 'abnormal-stats']}
        color='rgb(254, 151, 0)'
        xField='display_time'
        yField='value'
        title='应用稳定性评估'
        filter={{
          onChange: value => {
            setTimeParams({
              type: value.type,
              startDate: dayjs(value.start_date)
                .startOf('d')
                .format(TIME_FORMAT),
              endDate: dayjs(value.end_date).endOf('d').format(TIME_FORMAT),
            })
          },
        }}
        service={{
          call: async params => {
            const [$0, $1] = await Promise.all([
              queryAppStability(params),
              queryIrlTrend(params),
            ])
            return $0.concat($1)
          },
          params,
        }}
        detail={{
          onClick: onDetailClick,
        }}
        showTooltip
        // onDetailClick={xFieldValue => {
        //   if (xFieldValue) {
        //     onLogJump(xFieldValue)
        //   }
        // }}
      />
      {errorDetailModalOpen && (
        <MonitorDrawer
          title={
            flowType === 'AGENT'
              ? '对话首字响应时长异常记录'
              : '运行响应时长异常记录'
          }
          columns={getOvertimeDetailColumns({
            type: flowType,
            onOvertimeItemEnter: handleOvertimeItemEnter,
          })}
          {...rest}
          filter={{
            defaultValue: timeParams.type,
            defaultSubValue: [
              dayjs(timeParams.startDate),
              dayjs(timeParams.endDate),
            ],
          }}
          onClose={() => {
            setErrorDetailModalOpen(false)
          }}
        />
      )}
      {errorRecordModalOpen && (
        <MonitorDrawer
          title={flowType === 'AGENT' ? '对话错误记录明细' : '运行错误记录'}
          columns={getErrorRecordColumns({
            flowType,
            onOvertimeItemEnter: handleOvertimeItemEnter,
          })}
          {...restForAgent}
          filter={{
            defaultValue: timeParams.type,
            defaultSubValue: [
              dayjs(timeParams.startDate),
              dayjs(timeParams.endDate),
            ],
          }}
          onClose={() => {
            setErrorRecordModalOpen(false)
          }}
        />
      )}
      {chatRecordsModalOpen && (
        <LogModal
          recordId={recordId}
          robotAvatarInfo={{
            icon: applicationInfo?.icon,
            color: applicationInfo?.color,
            name: applicationInfo?.name,
          }}
          position='fixed'
          onClose={() => {
            setChatRecordsModalOpen(false)
          }}
        />
      )}
      {flowLogModalOpen && logData && (
        <LoggerModal
          defaultTab='output'
          title={
            <LoggerTitle
              time={logData.start_time}
              status={logData.run_status}
            />
          }
          flowId={flowId}
          taskId={logData.task_id}
          onClose={() => {
            setFlowLogModalOpen(false)
          }}
          onReRun={item => {
            goDraftPage(item as unknown as LogItem)
          }}
        />
      )}
    </AppStabilityPaneContext.Provider>
  )
}
