import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { message } from 'antd'
import { uniqBy } from 'lodash-es'
import {
  getLLMNodePromptRecordList,
  getLLMNodePromptFavorList,
  addPrompt2CollectedById,
  removePrompt2CollectedById,
  updatePrompt2CollectedById,
} from '@apis/prompt'

export interface IItemInfoChangeParams {
  type: 'record' | 'collect'
  history_id: string
  favorite_id: string
  action: 'add' | 'remove' | 'update'
  favorite_reason?: string
}

interface NodePanelStore {
  usedKeyListV1: string[]
  getDynamicValuesV1: (
    applicationId: string,
    nodeId: string,
  ) => Record<string, any>
  setUsedKeyListV1: (keyList: string[]) => void
  dynamicValuesV1: Record<string, Record<string, Record<string, any>>>
  setDynamicValuesV1: (
    applicationId: string,
    nodeId: string,
    key: string,
    value: any,
  ) => void

  allRecordList: any[]
  allPageNumber: number
  recordListCount: number
  fetchAllRecordList: (flow_id: string, node_id: string) => Promise<any>
  fetchLastedRecord: (flow_id: string, node_id: string) => Promise<any>
  collectList: any[]
  collectPageNumber: number
  collectListCount: number
  fetchCollectedList: (flow_id: string, node_id: string) => Promise<any>
  fetchLastedCollected: (flow_id: string, node_id: string) => Promise<any>
  initRecordList: () => void
  initCollectedList: () => void
  setCollectItemStatus: (params: IItemInfoChangeParams) => void
}

export const useNodePanelStore = create<NodePanelStore>((set, get) => {
  return {
    usedKeyListV1: [],
    dynamicValuesV1: {},
    setUsedKeyListV1: keyList => set({ usedKeyListV1: keyList }),

    setDynamicValuesV1: (applicationId, nodeId, key, value) => {
      set(state => {
        const values = state.dynamicValuesV1
        if (!values[applicationId]) {
          values[applicationId] = {}
        }
        if (!values[applicationId][nodeId]) {
          values[applicationId][nodeId] = {}
        }
        values[applicationId][nodeId][key] = value
        return { dynamicValuesV1: { ...values } }
      })
    },

    // 更新getDynamicValues方法
    getDynamicValuesV1: (applicationId, nodeId) => {
      const values = get().dynamicValuesV1
      const appValues = values[applicationId] || {}
      const nodeValues = appValues[nodeId] || {}
      return nodeValues
    },

    allRecordList: [],
    allPageNumber: 1,
    recordListCount: 1,
    fetchAllRecordList: async (flow_id: string, node_id: string) => {
      const page_number = get().allPageNumber
      const listInfo = await getLLMNodePromptRecordList({
        flow_id,
        node_id,
        page_number,
        page_size: 10,
      })
      const allRecordInfo = get().allRecordList
      const newList = uniqBy([...allRecordInfo, ...listInfo.list], 'history_id')
      set({
        allRecordList: newList,
        recordListCount: listInfo.total,
        allPageNumber: page_number + 1,
      })
      return listInfo
    },
    fetchLastedRecord: async (flow_id: string, node_id: string) => {
      const listInfo = await getLLMNodePromptRecordList({
        flow_id,
        node_id,
        page_number: 1,
        page_size: 20,
      })
      const newList = uniqBy([...listInfo.list], 'history_id')

      set({
        allRecordList: newList,
        recordListCount: listInfo.total,
        allPageNumber: 3,
      })
    },

    collectList: [],
    collectPageNumber: 1,
    collectListCount: 1,
    fetchCollectedList: async (flow_id: string, node_id: string) => {
      const page_number = get().collectPageNumber
      const listInfo = await getLLMNodePromptFavorList({
        flow_id,
        node_id,
        page_number,
        page_size: 10,
      })
      const collectInfo = get().collectList
      set({
        collectList: uniqBy([...collectInfo, ...listInfo.list], 'history_id'),
        collectListCount: listInfo.total,
        collectPageNumber: page_number + 1,
      })
      return listInfo
    },
    fetchLastedCollected: async (flow_id: string, node_id: string) => {
      const listInfo = await getLLMNodePromptFavorList({
        flow_id,
        node_id,
        page_number: 1,
        page_size: 20,
      })
      const newList = uniqBy([...listInfo.list], 'history_id')

      set({
        collectList: newList,
        collectListCount: listInfo.total,
        collectPageNumber: 3,
      })
    },
    setCollectItemStatus: async (params: IItemInfoChangeParams) => {
      const {
        history_id,
        favorite_id,
        type,
        action,
        favorite_reason = '',
      } = params
      let invokeApi
      switch (action) {
        case 'add':
          invokeApi = addPrompt2CollectedById
          break
        case 'remove':
          invokeApi = removePrompt2CollectedById
          break
        case 'update':
        default:
          invokeApi = updatePrompt2CollectedById
          break
      }
      try {
        const result = await invokeApi(
          action === 'add' ? history_id : favorite_id,
          favorite_reason,
        )
        const stateKey = type === 'record' ? 'allRecordList' : 'collectList'
        const list = get()[stateKey]

        const targetItem = list.find(
          item =>
            (action === 'update' && item.favorite_id === favorite_id) ||
            (action !== 'update' && item.history_id === history_id),
        )

        if (targetItem) {
          targetItem.favorite_id = action === 'remove' ? null : result.id
        }

        set({ [stateKey]: [...list] })

        message.success(action === 'remove' ? '取消收藏成功' : '收藏成功')
      } catch (e) {
        message.error('操作失败')
      }
    },

    initRecordList: () => {
      set({ allRecordList: [], allPageNumber: 1, recordListCount: 1 })
    },
    initCollectedList: () => {
      set({ collectList: [], collectPageNumber: 1, collectListCount: 1 })
    },
  }
})

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('nodePanelStore', useNodePanelStore)
}
