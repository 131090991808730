import { RoleCode } from '@apis/authority/type'
import type { AuthRoute } from '@/auth.ts'
import { ErrorElement } from '@/pages/error.tsx'
import SelectEnterWorkspace from '@/pages/workspace/settings/SelectEnterWorkspace'

export const selectWorkSpaceRoutes: AuthRoute[] = [
  {
    path: '/select-workspace',
    element: <SelectEnterWorkspace />,
    auth(s) {
      return s.role([RoleCode.ADMINISTRATOR])
    },
    redirect: '/notAllowed',
    errorElement: <ErrorElement />,
  },
]
