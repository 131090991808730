import { useAuth } from '@bty/react-auth'
import { Tooltip } from 'antd'
import React from 'react'
import type { PartitionCategoryType } from '@apis/datastore/type'
import { Button, IconFont } from '@/components'
import { CreateDocumentsButton } from '@/features/datastore/components/CreateDocumentsButton'
import { PrivateDeployGuard } from '@/features/guard/PrivateDeployGuard'
import { useLimitedModal } from '@/features/pay'
import { useVersionStore } from '@/store'
import { PageHeader as MainPageHeader } from '@/pages/home/PageHeader'

interface DatastorePageHeaderProps {
  searchKey?: string
  onSearch: (searchKey?: string) => void
  onCreate: (type: PartitionCategoryType) => void
  onGroupCreate: (title: string) => Promise<void>
}

export function InternalDatastorePageHeader({
  searchKey,
  onSearch,
  onCreate,
  onGroupCreate,
}: DatastorePageHeaderProps) {
  const versionInfo = useVersionStore(state => state.versionInfo)

  const [modal] = useLimitedModal()

  const { state } = useAuth()

  const handleButtonClick = () => {
    window.open('/chrome-plugin', '_blank')
  }

  return (
    <MainPageHeader
      title='知识库'
      searchKey={searchKey}
      renderCreate={() => (
        <CreateDocumentsButton className='ml-12 gap-6' onCreate={onCreate} />
      )}
      extraActions={
        <Tooltip
          title={
            <span className='block px-4 py-2 leading-18px'>
              直接从 Chrome 浏览器 <br /> 解析网页数据
            </span>
          }
          placement='top'
          destroyTooltipOnHide
        >
          <Button
            className='gap-6 w-82px'
            icon={<IconFont className='text-16px' name='zhajian-1' />}
            onClick={handleButtonClick}
          >
            插件
          </Button>
        </Tooltip>
      }
      showCreateGroup
      onSearch={onSearch}
      onGroupCreate={onGroupCreate}
    >
      <PrivateDeployGuard>
        <div className='pr-16 ml-12'>
          剩余容量：
          {Math.max(
            (versionInfo?.memory_count_limit || 0) +
              (versionInfo?.dosage_package_memory_total || 0) -
              (versionInfo?.memory_consume_num || 0),
            0,
          ).toFixed(1)}
          MB
          <span
            onClick={() => modal.open({ role: state.role })}
            className='ml-12 cursor-pointer text-14px font-semibold text-primary'
          >
            扩容
          </span>
        </div>
      </PrivateDeployGuard>
    </MainPageHeader>
  )
}

export const DatastorePageHeader = React.memo(InternalDatastorePageHeader)
