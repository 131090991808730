import { Spin } from 'antd'
import type { DocumentItem } from '@apis/datastore/type'
import { ProgressStage } from '@apis/datastore/model'
import { progressStageMap } from '@/pages/datastores/provider/DocumentProvider.tsx'
import loadingPng from '@/assets/loading.png'

interface LoadingProps {
  progress_stage?: DocumentItem['progress_stage']
  reprocessDocumentLoading?: boolean
}

const ALL_PROGRESS_STEP_COUNT = 5

export default function Loading(props: LoadingProps) {
  const { reprocessDocumentLoading, progress_stage } = props

  const progress = progress_stage
    ? progressStageMap[progress_stage]
    : progressStageMap[ProgressStage.PENDING]

  return (
    <div className='op-100 bg-white pointer-events-none transition-ease-in-out transition-all-200 loading flex-center w-full h-full rounded-8px z-10'>
      <div className='w-fit text-center flex-col'>
        {reprocessDocumentLoading ? (
          <img src={loadingPng} alt='加载中...' className='w-124px' />
        ) : (
          <Spin />
        )}
        {reprocessDocumentLoading && (
          <div className='c-bg_3 c-op-60'>
            {progress.step}/{ALL_PROGRESS_STEP_COUNT}：{progress.label}
          </div>
        )}
      </div>
    </div>
  )
}
