import { Dropdown } from 'antd'
import { useMemo, useRef } from 'react'
import styled from '@emotion/styled'
import type { ButtonProps } from 'antd/lib'
import { PartitionCategoryType } from '@apis/datastore/type'
import { Button, IconFont } from '@/components'
import { LimitedAccess } from '@/features/pay/LimitedAccess'

const AnimateButton = styled(Button)`
  .rotate-btn {
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  :hover {
    .rotate-btn {
      transform: rotate(45deg);
    }
  }
`

interface CreateDocumentsButtonProps {
  className?: string
  size?: ButtonProps['size']
  onCreate: (type: PartitionCategoryType) => void
}

export function CreateDocumentsButton(props: CreateDocumentsButtonProps) {
  const { onCreate, className, size } = props
  const btnRef = useRef<any>()
  const actionList = useMemo(() => {
    return [
      {
        key: '1',
        label: (
          <LimitedAccess limitedType='dataStore'>
            <div
              className='h-36px cursor-pointer text-14px/36px flex items-center px-8px'
              onClick={() => {
                onCreate(PartitionCategoryType.Document)
              }}
            >
              <IconFont name='add' />
              <p className='ml-4px c-#17171D font-500'>文档知识库</p>
            </div>
          </LimitedAccess>
        ),
      },
      {
        key: '2',
        label: (
          <LimitedAccess limitedType='dataStore'>
            <div
              className='h-36px cursor-pointer text-14px/36px  flex items-center px-8px'
              onClick={() => {
                onCreate(PartitionCategoryType.QA)
              }}
            >
              <IconFont name='add' />
              <p className='ml-4px c-#17171D font-500'>问答知识库</p>
            </div>
          </LimitedAccess>
        ),
      },
    ]
  }, [onCreate])

  return (
    <Dropdown
      getPopupContainer={() => btnRef.current}
      menu={{ items: actionList }}
      placement='bottomLeft'
    >
      <AnimateButton
        ref={btnRef}
        className={className}
        type='primary'
        size={size}
        icon={<IconFont className='rotate-btn' name='add' />}
      >
        新建知识库
      </AnimateButton>
    </Dropdown>
  )
}
