import classNames from 'classnames'
import type { ChangeEvent } from 'react'
import { memo, useEffect, useMemo, useRef, useState } from 'react'
import { useBoolean, useDebounceFn, useRequest } from 'ahooks'
import type { InputRef } from 'antd'
import { Button, message } from 'antd'
import {
  getCorrectResult,
  labelingTestResult,
  remarksTestResult,
} from '@apis/batch-test'
import { IconFont, Input } from '@/components'
import { Output } from './Output'

export interface RunResultPanelProps {
  className?: string
  cellKey?: string
  data?: Record<string, any>
  onLogClick?: (testResultId: string) => void
  onReTry?: (testResultId: string) => void
  onLabelChange?: (label: string | null) => void
  onRemarkChange?: (remarks: string) => void
  onClose?: () => void
}

export const RunResultPanel: React.FC<RunResultPanelProps> = memo(props => {
  const {
    className,
    cellKey = '',
    data,
    onClose,
    onLogClick,
    onLabelChange,
    onRemarkChange,
    onReTry,
  } = props

  const inputRef = useRef<InputRef>(null)

  const outputInfo = useMemo(() => {
    if (!data) return {}
    return data.runtimeOutput ?? data?.[cellKey]
  }, [data, cellKey])

  const testResultId = useMemo(() => {
    return outputInfo?.test_result_id
  }, [outputInfo])

  const [diff, { toggle: toggleDiff, setFalse: hideDiff, setTrue: showDiff }] =
    useBoolean(!!data?.correct_result)

  const { runAsync: doLabel } = useRequest(
    (label: 'PASSED' | 'NOT_PASSED' | null) => {
      return labelingTestResult(testResultId, label)
    },
    {
      manual: true,
      ready: !!testResultId,
    },
  )

  const { runAsync: doRemarks } = useRequest(
    (remarks: string) => remarksTestResult(testResultId, remarks),
    {
      manual: true,
    },
  )

  const { run: doRemarksDebounced } = useDebounceFn(doRemarks)

  const { data: testResult } = useRequest(
    () => getCorrectResult(testResultId),
    {
      refreshDeps: [testResultId],
    },
  )

  const correctResult = useMemo(() => {
    return testResult?.correct_result
  }, [testResult])

  const outputResult = useMemo(() => {
    return testResult?.result?.output
  }, [testResult])

  useEffect(() => {
    if (!correctResult) {
      hideDiff()
    } else {
      showDiff()
    }
  }, [correctResult])

  const [label, setLabel] = useState<'PASSED' | 'NOT_PASSED' | null>(null)
  const [remarks, setRemarks] = useState<string>('')

  useEffect(() => {
    setLabel(data?.similarity?.result_label)
    setRemarks(data?.remarks)
  }, [data, outputInfo])

  const outputTitle = useMemo(() => {
    if (!data) return '批量调试'
    return `批量调试#${data?.index + 1}`
  }, [data])

  const handlePass = async () => {
    const newLabel = label === 'PASSED' ? null : 'PASSED'
    await doLabel(newLabel)
    onLabelChange?.(newLabel)
    setLabel(newLabel)

    if (newLabel === 'PASSED') {
      message.success(
        <span>
          标记为<span className='text-#2CB969 '>通过</span>
        </span>,
      )
    } else {
      message.success('已取消标记')
    }
  }

  const handleNotPass = async () => {
    const newLabel = label === 'NOT_PASSED' ? null : 'NOT_PASSED'
    await doLabel(newLabel)
    onLabelChange?.(newLabel)
    setLabel(newLabel)

    if (newLabel === 'NOT_PASSED') {
      message.success(
        <span>
          标记为<span className='text-#FF5219'>不通过</span>
        </span>,
      )
    } else {
      message.success('已取消标记')
    }
  }

  const handleRemarks = (event: ChangeEvent<HTMLInputElement>) => {
    setRemarks(event.target.value)
  }

  const handleConfirmRemarks = (event: any) => {
    event.stopPropagation()
    inputRef.current?.blur()
    doRemarksDebounced(remarks)
    onRemarkChange?.(remarks)
  }

  const handleCancelRemarks = (event: any) => {
    event.stopPropagation()
    inputRef.current?.blur()
    setRemarks(data?.remarks)
  }

  return (
    <div
      className={classNames(
        className,
        'min-w-600px p-16px pt-0px b-t-1 b-line b-op-80 flex flex-col bg-#F7F7FA',
      )}
    >
      <div className='h-48px flex items-center justify-between'>
        <div className='flex items-center'>
          <div className='text-16px text-font font-600 mr-12px'>运行结果</div>
        </div>
        <div className='flex gap-16px'>
          <Button
            className='flex flex-center h-22px! px-4px!'
            size='small'
            type='text'
            onClick={() => onLogClick?.(testResultId)}
          >
            <IconFont className='mr-4px' name='yulanyuanwenjian1x' />
            日志
          </Button>
          <Button
            className='flex flex-center h-22px! px-4px!'
            size='small'
            type='text'
            onClick={() => onReTry?.(testResultId)}
          >
            <IconFont className='mr-4px' name='muxingpeizhi' />
            去调试
          </Button>
          <Button
            className='flex flex-center h-22px! text-14px px-4px!'
            size='small'
            type='text'
            onClick={e => {
              e.stopPropagation()
              onClose?.()
            }}
          >
            <IconFont name='guanbi' />
          </Button>
        </div>
      </div>

      <div
        className='flex-1 flex flex-col overflow-hidden bg-#FFFFFF rounded-8px'
        style={{ border: '1px solid rgba(225, 225, 229, 0.6)' }}
      >
        <div className='flex-auto overflow-hidden flex'>
          {diff && (
            <div className='w-49% flex-1 pt-20px'>
              <Output
                className='border-r-1px'
                key={data?.key}
                title='正确答案'
                result={correctResult}
                operations={['copy']}
              />
            </div>
          )}
          <div
            className={classNames({
              'w-49% flex-1 py-20px': diff,
              'w-full py-20px': !diff,
            })}
          >
            <Output
              key={data?.key}
              title={outputTitle}
              result={outputResult}
              diff={diff}
              onDiff={toggleDiff}
              operations={['copy']}
            />
          </div>
        </div>

        <div className='flex-none h-72px flex flex-center px-20px flex items-center justify-center gap-16px'>
          <div
            onClick={handlePass}
            className={classNames(
              'flex-none cursor-pointer flex flex-center gap-6px w-115px h-40px rounded-8px text-12px b-line b-1 b-op-80',
              {
                'bg-[rgba(30,205,176,0.12)] border-[rgba(30,205,176,0.8)]! text-#1ECDB0':
                  label === 'PASSED',
                'hover:bg-[rgba(98,105,153,0.06)]': label !== 'PASSED',
              },
            )}
          >
            <span className='inline-block'>👍</span> 通过
          </div>

          <div
            onClick={handleNotPass}
            className={classNames(
              'flex-none cursor-pointer flex flex-center gap-6px w-115px h-40px rounded-8px text-12px b-1 b-op-80',
              {
                'bg-[rgba(255,82,25,0.12)] border-[rgba(255,82,25,0.8)]! text-#FF5219':
                  label === 'NOT_PASSED',
                'hover:bg-[rgba(98,105,153,0.06)]': label !== 'NOT_PASSED',
              },
            )}
          >
            <span className='rotate-180 inline-block'>👍</span> 不通过
          </div>

          <Input
            ref={inputRef}
            className='h-40px'
            placeholder='备注'
            value={remarks}
            onChange={handleRemarks}
            onPressEnter={handleConfirmRemarks}
            suffix={
              <span
                className={classNames('flex text-#8D8D99 mr-[-4px]', {
                  hidden: remarks === data?.remarks,
                })}
              >
                <IconFont
                  name='guanbi'
                  className='text-16px mr-12px cursor-pointer hover:bg-#efefef rounded-4px m-[-4px] p-4px box-content'
                  onClick={handleCancelRemarks}
                />
                <IconFont
                  name='queren'
                  className='text-16px cursor-pointer hover:bg-#efefef rounded-4px m-[-4px] p-4px box-content'
                  onClick={handleConfirmRemarks}
                />
              </span>
            }
          />
        </div>
      </div>
    </div>
  )
})
