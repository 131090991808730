import { useRequest } from 'ahooks'
import { useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  fetchTemplateList,
  getRecommendedTemplateList,
  getTemplateLabels,
} from '@apis/application'
import { IconFont, Input, LabelTabs, ResponsiveCardGrid } from '@/components'
import bannerImg from '@/assets/banner.png'
import { useBackBefore, useScrollBar } from '@/hooks/useScrollBar.ts'
import { useCache } from '@/hooks/useCache'
import { EXPLORE_LEAVE_TAB, EXPLORE_LIST_SCROLL_TOP } from '@/constants/storage'
import { RecommendCard } from './components/RecommendCard'
import { TemplateCard } from './components/TemplateCard'
import { EmptyTemplates } from './components/Empty'

export function Explore() {
  const navigate = useNavigate()
  const headerRef = useRef<HTMLDivElement>(null)
  const [activeLabel, setActiveLabel] = useCache(EXPLORE_LEAVE_TAB, 'all')
  const [name, setName] = useState('')
  const { data: recommedData = [] } = useRequest(getRecommendedTemplateList)
  const { data: labels = [], refreshAsync: fetchLabels } =
    useRequest(getTemplateLabels)
  const { data: originTemplateList = [] } = useRequest(
    () =>
      fetchTemplateList({
        labels: activeLabel === 'all' ? undefined : activeLabel,
      }),
    {
      refreshDeps: [activeLabel],
    },
  )

  const templateList = useMemo(
    () => originTemplateList?.filter(item => item.name.includes(name)),
    [originTemplateList, name],
  )
  const displayLabels = useMemo(
    () =>
      [{ label: '全部', key: 'all' }].concat(
        labels.map(item => ({ label: item, key: item })),
      ),
    [labels],
  )

  const handleCardClick = (id: string) => {
    navigate(`/chatbot/${id}`, {
      state: {
        fromRoute: 'templates',
      },
    })
  }

  const handleTabChange = (key: string) => {
    setActiveLabel(key)
  }

  const { scrollRef: pageScrollRef, scrollBar: pageScrollBar } = useScrollBar()
  const { scrollRef: labelScrollRef } = useScrollBar()
  useBackBefore(pageScrollBar, EXPLORE_LIST_SCROLL_TOP, [!!templateList.length])

  return (
    <div
      className='adapt:px-64px adapt:py-40px h-full overflow-y-auto'
      ref={pageScrollRef}
    >
      <div className='relative'>
        <h2 className='mb-24px text-20px line-height-36px font-600'>发现</h2>
        <img src={bannerImg} alt='' />
        <div className='mt-40px mb-24px text-16px text-#17171d font-600'>
          精选推荐
        </div>
        {recommedData.length > 0 ? (
          <div className='flex gap-24px pb-16px'>
            {recommedData.map(item => (
              <div key={item.id} className='flex-1'>
                <RecommendCard
                  id={item.id}
                  image={item.icon}
                  title={item.name}
                  desc={item.description}
                  skillCount={
                    (item.skillStatistics?.flows || 0) +
                    (item.skillStatistics?.plugins || 0)
                  }
                  docCount={
                    (item.skillStatistics?.knowledge_files || 0) +
                    (item.skillStatistics?.database_tables || 0)
                  }
                  heat={item.popularity!}
                  onClick={() => handleCardClick(item.flowId)}
                />
              </div>
            ))}
          </div>
        ) : null}
        {labels && (
          <div
            ref={headerRef}
            className='flex flex-items-center flex-justify-between py-24px bg-#f7f7fa z-4'
          >
            <span className='mr-16px text-16px text-#17171d font-600'>
              Agent模板
            </span>
            <div ref={labelScrollRef} className='flex-1 overflow-x-auto'>
              <LabelTabs
                labels={displayLabels}
                activeKey={activeLabel}
                onChange={handleTabChange}
              />
            </div>
            <Input
              prefix={<IconFont name='search' className='text-16px' />}
              className='flex-1 max-w-400px ml-12px'
              placeholder='搜索Agent名称'
              value={name}
              size='middle'
              onKeyDown={e => {
                console.log(e.code === 'Enter')
              }}
              onChange={e => {
                setName(e.target.value)
                fetchLabels()
              }}
            />
          </div>
        )}

        {templateList.length > 0 ? (
          <ResponsiveCardGrid>
            {templateList.map(item => (
              <TemplateCard
                key={item.id}
                id={item.flowId}
                image={item.icon}
                title={item.name}
                desc={item.description}
                skillCount={
                  (item.skillStatistics?.flows || 0) +
                  (item.skillStatistics?.plugins || 0)
                }
                docCount={
                  (item.skillStatistics?.knowledge_files || 0) +
                  (item.skillStatistics?.database_tables || 0)
                }
                heat={item.popularity!}
                onClick={handleCardClick}
              />
            ))}
          </ResponsiveCardGrid>
        ) : (
          <EmptyTemplates />
        )}
      </div>
    </div>
  )
}
