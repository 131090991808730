import { nocoRequest } from '../../client'
import type { AccessRecord, AgentGonocoRequest, NewPackageForm } from './type'

export function getAccessInfo(userId: number) {
  return nocoRequest.get('/wait-list/find-one', {
    query: {
      where: `(userId, eq, ${userId})`,
    },
  })
}

export function applyAccess(req: AccessRecord) {
  return nocoRequest.post('/wait-list', req)
}

export function addAgentGoRecord(req: AgentGonocoRequest) {
  return nocoRequest.post('/agent-go', req)
}

export function addCodeEditorPackage(req: NewPackageForm) {
  return nocoRequest.post('/code-package-submission', req)
}
