import { mountStoreDevtool } from 'simple-zustand-devtools'
import { create } from 'zustand'
import { sum, isEqual } from 'lodash-es'
import type { UnreadMessageCountMap } from '@bty/chat-types'
import {
  getAgentGlobalUnreadMessageCount,
  getAgentGlobalUnreadMessageCountForAgentTest,
} from '@apis/chat-bot'

export interface ChatStore {
  unreadMessageCount: number
  unreadMessageCountMap: UnreadMessageCountMap
  unreadMessageCountForAgentTest: number
  unreadMessageCountForAgentTestMap: UnreadMessageCountMap
  fetchUnreadMessageCount: (agentTest?: boolean) => Promise<void>
}

export const useChatStore = create<ChatStore>((set, get) => ({
  unreadMessageCount: 0,
  unreadMessageCountMap: {
    robot: {},
    conversation: {},
  },
  unreadMessageCountForAgentTest: 0,
  unreadMessageCountForAgentTestMap: {
    robot: {},
    conversation: {},
  },
  fetchUnreadMessageCount: async agentTest => {
    const isAgentTest = agentTest === true
    const api = isAgentTest
      ? getAgentGlobalUnreadMessageCountForAgentTest
      : getAgentGlobalUnreadMessageCount
    const res = await api()
    const robot = res.robot ?? {}
    const conversation = res.conversation ?? {}

    if (agentTest) {
      // 这个模式下消息立刻被标记为已读，返回的都是空对象
      set({
        unreadMessageCountForAgentTest: sum(Object.values(robot)),
        unreadMessageCountForAgentTestMap: { robot, conversation },
      })
      return
    }

    const isEqualRobot = isEqual(robot, get().unreadMessageCountMap.robot)
    const isEqualConversation = isEqual(
      conversation,
      get().unreadMessageCountMap.conversation,
    )

    if (!isEqualRobot || !isEqualConversation) {
      const count = sum(Object.values(robot))
      set({
        unreadMessageCount: count,
        unreadMessageCountMap: { robot, conversation },
      })
    }
  },
}))

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('chatStore', useChatStore)
}
