import React, { useCallback, useRef, useState } from 'react'
import classNames from 'classnames'
import { CloseCircleFilled } from '@ant-design/icons'
import {
  DocumentType,
  ParagraphExtraInfoStatus,
  ExtraParagraphExtraInfoStatus,
} from '@apis/datastore/model'
import { Input, IconFont, Select } from '@/components'
import type { IParagraphSearchParams } from '../../hooks/useParagraph'

export const paragraphStatusOptions: {
  label: string
  value: ParagraphExtraInfoStatus | ExtraParagraphExtraInfoStatus
}[] = [
  {
    label: '全部类型',
    value: ExtraParagraphExtraInfoStatus.All,
  },
  {
    label: '正常',
    value: ParagraphExtraInfoStatus.Done,
  },
  {
    label: '异常',
    value: ParagraphExtraInfoStatus.Error,
  },
  {
    label: 'AI处理中',
    value: ParagraphExtraInfoStatus.Process,
  },
]

export default (props: {
  onSearch?: (params?: IParagraphSearchParams) => void
  documentType: DocumentType
  pageInfo?: {
    total: number
    extra_info: Record<string, any>
  }
  extraNode?: React.ReactNode
  className?: string
}) => {
  const { onSearch, pageInfo, documentType, extraNode, className } = props
  const extra_info = pageInfo?.extra_info || {
    warningCount: 3,
    aiProcessCount: 2,
    aiProcessTotalCount: 5,
  }
  const isSearched = useRef(false)
  const searchInfo = useRef(
    documentType === DocumentType.QA
      ? {
          search_words: '',
        }
      : {
          search_words: '',
          chunk_status: ExtraParagraphExtraInfoStatus.All,
        },
  )

  const [searchInfoSnapshot, setSearchInfoSnapshot] = useState(
    searchInfo.current,
  )

  const onSearchInfoChange = useCallback(
    (params: any) => {
      searchInfo.current = { ...searchInfo.current, ...params }

      isSearched.current = true
    },
    [searchInfo],
  )

  const handleSearch = async () => {
    if (isSearched.current) {
      const formatParams = {
        search_words: searchInfo.current.search_words,
        chunk_status:
          searchInfo.current.chunk_status === ExtraParagraphExtraInfoStatus.All
            ? undefined
            : searchInfo.current.chunk_status,
      }
      await onSearch?.(formatParams)
      setSearchInfoSnapshot(searchInfo.current)
      isSearched.current = false
    }
  }

  return (
    <div
      className={classNames(
        'ml-24px mr-30px flex flex-items-center flex-justify-between',
        className,
      )}
    >
      <div className='flex items-center overflow-hidden'>
        <Input
          prefix={<IconFont name='search' className='text-15px' />}
          placeholder='搜索内容'
          className={classNames(' bg-[rgba(98,105,153,0.06)] adapt:w-400px')}
          allowClear={{
            clearIcon: (
              <CloseCircleFilled
                onClick={() => {
                  onSearchInfoChange({ search_words: '' })
                  handleSearch?.()
                }}
              />
            ),
          }}
          onPressEnter={() => {
            handleSearch()
          }}
          onChange={e => {
            onSearchInfoChange({ search_words: e.target.value })
          }}
          onBlur={() => {
            handleSearch()
          }}
        />
        {documentType !== DocumentType.QA && (
          <Select
            className='ml-12px text-14px w-140px h-36px  rounded-6px'
            options={paragraphStatusOptions}
            value={searchInfo.current.chunk_status}
            onChange={e => {
              onSearchInfoChange({ chunk_status: e })
              handleSearch()
            }}
          />
        )}
      </div>
      <div className='flex items-center text-14px/14px c-#17171D items-center'>
        <div className='flex items-center flex-1 whitespace-nowrap ml-12px'>
          <p>
            {searchInfoSnapshot.search_words || searchInfoSnapshot.chunk_status
              ? '搜索到'
              : '共'}
          </p>
          <p className='flex items-center flex-nowrap'>
            <span className='mx-4px'>{pageInfo?.total}</span>
            <span>{documentType !== DocumentType.QA ? '段落' : '问答'}</span>
            {!(
              searchInfoSnapshot.search_words || searchInfoSnapshot.chunk_status
            ) && (
              <>
                {!!extra_info.error_count && (
                  <p>
                    <span>，异常</span>
                    <span className='mx-4px c-#FE9700'>
                      {extra_info.error_count}
                    </span>
                    <span>段落</span>
                  </p>
                )}
                {!!extra_info.process_content_count && (
                  <p>
                    <span>，AI处理中</span>
                    <span className='mx-4px c-#7B61FF'>
                      {extra_info.process_content_count}
                    </span>
                    <span>段落</span>
                  </p>
                )}
              </>
            )}
          </p>
        </div>
        {extraNode}
      </div>
    </div>
  )
}
