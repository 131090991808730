import { DocumentStatus } from '@apis/datastore/model'
import PDF from '@/assets/dataset/pdf.svg?url'
import EXCEL from '@/assets/dataset/excel.svg?url'
import MD from '@/assets/dataset/md.svg?url'
import WORD from '@/assets/dataset/word.svg?url'
import ZIP from '@/assets/dataset/zip.svg?url'
import TEXT from '@/assets/dataset/text.svg?url'
import BOOK from '@/assets/dataset/book.svg?url'
import HTML from '@/assets/dataset/html.svg?url'
import IMAGE from '@/assets/dataset/image.svg?url'
import PPT from '@/assets/dataset/ppt.svg?url'
import AUDIO from '@/assets/dataset/audio.svg?url'
import VIDEO from '@/assets/dataset/video.svg?url'
import EMAIL from '@/assets/dataset/email.svg?url'
import QAIcon from '@/assets/dataset/qa.svg?url'
import UnKnow from '@/assets/dataset/un-know.svg?url'

export const FileIconMap: Record<string, string> = {
  pdf: PDF,
  xlsx: EXCEL,
  md: MD,
  docx: WORD,
  doc: WORD,
  txt: TEXT,
  html: HTML,
  zip: ZIP,
  book: BOOK,
  jpg: IMAGE,
  jpeg: IMAGE,
  png: IMAGE,
  ppt: PPT,
  pptx: PPT,
  msg: EMAIL,

  mp3: AUDIO,
  mpeg: AUDIO,
  wav: AUDIO,
  aac: AUDIO,
  ogg: AUDIO,
  webm: AUDIO,
  m4a: AUDIO,
  wma: AUDIO,
  'x-m4a': AUDIO,
  'x-ms-wma': AUDIO,
  amr: AUDIO,
  flac: AUDIO,
  mp4: VIDEO,
  flv: VIDEO,
  wmv: VIDEO,
  mov: VIDEO,
  avi: VIDEO,
  'x-avi': VIDEO,
  'v-webm': VIDEO,
  'v-ogg': VIDEO,
  'x-msvideo': VIDEO,
  'x-mpeg': VIDEO,
  'x-matroska': VIDEO,
  'x-ms-asf': VIDEO,
}

export const FileTypeIconMap = {
  QA: QAIcon,
}

export const fileTypeMimeMap: Record<string, string> = {
  md: 'text/markdown',
  html: 'text/html',
  htm: 'text/htm',
  txt: 'text/plain',
  css: 'text/css',
  js: 'application/javascript',
  json: 'application/json',
  xml: 'application/xml',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  gif: 'image/gif',
  svg: 'image/svg+xml',
  pdf: 'application/pdf',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  ppt: 'application/vnd.ms-powerpoint',
  pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  msg: 'application/vnd.ms-outlook',
  // 音频
  mp3: 'audio/mp3',
  mpeg: 'audio/mpeg',
  wav: 'audio/wav',
  aac: 'audio/aac',
  ogg: 'audio/ogg',
  webm: 'audio/webm',
  m4a: 'audio/m4a',
  wma: 'audio/wma',
  'x-m4a': 'audio/x-m4a',
  'x-ms-wma': 'audio/x-ms-wma',
  amr: 'audio/amr',
  flac: 'audio/flac',
  // 视频
  mp4: 'video/mp4',
  avi: 'video/avi',
  'x-avi': 'video/x-msvideo',
  mov: 'video/quicktime',
  flv: 'video/x-flv',
  wmv: 'video/x-ms-wmv',
  'v-webm': 'video/webm',
  'v-ogg': 'video/ogg',
  'x-msvideo': 'video/x-msvideo',
  'x-mpeg': 'video/mpeg',
  'x-matroska': 'video/x-matroska',
  'x-ms-asf': 'video/x-ms-asf',

  zip: 'application/zip',
  rar: 'application/x-rar-compressed',
  csv: 'text/csv',
}

export const DocumentStatusMap = {
  [DocumentStatus.Wait]: {
    text: '处理中', // 产品需求：待处理和处理中合并状态，方便用户理解
    color: '#14ABF1',
  },
  [DocumentStatus.Ing]: {
    text: '处理中',
    color: '#14ABF1',
  },
  [DocumentStatus.Done]: {
    text: '可用',
    color: '#2CB969',
  },
  [DocumentStatus.Fail]: {
    text: '失败',
    color: '#FF5219',
  },
  [DocumentStatus.Warning]: {
    text: '异常',
    color: '#FE9700',
  },
  [DocumentStatus.AIProcess]: {
    text: 'AI处理中',
    color: '#6B4EFF',
  },
}

export const SUPPORTED_FILE_TYPE_TEXT =
  'pdf、doc、docx、ppt、pptx、xlsx、markdown、png、jpg、txt、msg'

export const UN_KNOW_ICON = UnKnow

export const MAX_SPLIT_SIZE = 4000

export const MAX_KEYWORDS_SIZE = 20

export const DATASTORE_MAX_TAG_COUNT = 9

export const DEFAULT_AGENT_DATASET_CONFIG = {
  num: 6,
  description: '这是你的知识库，当你需要查询数据时,请从这里查询',
  threshold: 0.8,
}

export enum CreateType {
  CREATE_DOCUMENT = 'CREATE_DOCUMENT',
  CREATE_PARTITION = 'CREATE_PARTITION',
}
