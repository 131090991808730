import { Button, Modal, type ModalProps } from 'antd'
import { useState } from 'react'
import classNames from 'classnames'
import NiceModal from '@ebay/nice-modal-react'
import type { User } from '@apis/user/type'
import { IconFont } from '@/components'
import { FormModifyPassword } from './FormModifyPassword'
import { FormProfile } from './FormProfile'

const menus = [
  {
    key: 'profile',
    label: '个人信息',
  },
  {
    key: 'modifyPassword',
    label: '重置密码',
  },
] as const

export interface UserSettingsModalProps
  extends Omit<ModalProps, 'onOk' | 'onCancel'> {
  user: User
  onPasswordUpdated: VoidFunction
  onProfileUpdated: (user: User) => void
  onCancel?: VoidFunction
}

function InternalUserSettingsModal({
  user,
  onProfileUpdated,
  onPasswordUpdated,
}: UserSettingsModalProps) {
  const modal = NiceModal.useModal()

  const [currentMenu, setCurrentMenu] = useState<'profile' | 'modifyPassword'>(
    'profile',
  )

  return (
    <Modal
      open={modal.visible}
      className='pointer-events-auto h-576px bg-white rounded-12px shadow-[0px_8px_24px_0px_rgba(0,0,0,0.1)]'
      rootClassName='[&>.ant-modal-mask]:backdrop-blur-24 [&>.ant-modal-mask]:!bg-black [&>.ant-modal-mask]:!bg-opacity-2'
      width={768}
      title={null}
      footer={null}
      modalRender={() => (
        <div className='flex h-full'>
          <div className='bg-bg_3 bg-opacity-2 w-160px border-right after:border-[rgba(225,225,229,.6)]'>
            <h2 className='text-font text-base leading-48px font-semibold pl-20 h-48px border-bottom after:border-[rgba(225,225,229,.6)]'>
              用户设置
            </h2>
            <ul className='p-12 [&>li+li]:mt-8'>
              {menus.map(m => (
                <li
                  key={m.key}
                  className={classNames(
                    'h-40px leading-40px pl-16 rounded-8px text-font cursor-pointer hover:bg-primary hover:bg-opacity-8',
                    {
                      'bg-primary bg-opacity-8': currentMenu === m.key,
                    },
                  )}
                  onClick={event => {
                    event.stopPropagation()
                    setCurrentMenu(m.key)
                  }}
                >
                  {m.label}
                </li>
              ))}
            </ul>
          </div>
          <div className='flex-1'>
            <div className='h-48px flex items-center justify-between pl-20 pr-12'>
              <h2 className='text-font text-base font-semibold'>
                {menus.find(el => el.key === currentMenu)?.label}
              </h2>
              <Button
                className='p-0 border-none !w-24px h-24px flex-center'
                icon={<IconFont name='guanbi' />}
                type='text'
                onClick={modal.hide}
              />
            </div>
            {currentMenu === 'modifyPassword' ? (
              <FormModifyPassword user={user} onOk={onPasswordUpdated} />
            ) : currentMenu === 'profile' ? (
              <FormProfile user={user} onOk={onProfileUpdated} />
            ) : null}
          </div>
        </div>
      )}
      afterClose={modal.remove}
      onCancel={modal.hide}
      maskClosable
      centered
    />
  )
}

export const UserSettingsModal = NiceModal.create(InternalUserSettingsModal)
