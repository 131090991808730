import { isString } from 'lodash-es'
import MarkdownIt from 'markdown-it'
import { FlowStatus } from '@apis/flow/type'
import type { LogItem } from '@apis/run/type'

export function isMarkdown(text: string) {
  const md = new MarkdownIt()
  try {
    md.parse(text, {})
    return true
  } catch (error) {
    return false
  }
}

export function isEmptyString(str: any) {
  return isString(str) && !str.trim()
}

export function isJSONString(text: string) {
  try {
    JSON.parse(text)
    return true
  } catch (error) {
    return false
  }
}

export function getString(data: any): string {
  try {
    return JSON.stringify(data, null, 2)
  } catch (err) {
    return String(data)
  }
}

export function getOutput(flowLog?: LogItem) {
  const message = flowLog?.run_result?.message as any
  return {
    result: flowLog?.run_result?.actionOutputs?.output?.output,
    status: flowLog?.run_status,
    errorStepName: message?.step_name,
    errorMsg:
      flowLog?.run_status === FlowStatus.SUCCEEDED
        ? flowLog?.run_result?.actionOutputs?.output?.output
        : getString(
            message?.step_name
              ? `运行流程节点在${message?.step_name}出错, 详情请查看日志`
              : message,
          ),
  }
}
