import { isNumber } from 'lodash-es'
import { message } from 'antd'
import { useNavigate } from 'react-router-dom'
import type { TemplateApp } from '@apis/template/type'
import TemplatePage from '@/pages/templates/components/TemplatePage'

export default function Templates() {
  const navigate = useNavigate()
  const onTemplateSetup = (item: TemplateApp) => {
    // TODO 临时用来显示列表的，模板应用搭建完成后，去掉该限制
    if (isNumber(item.id)) {
      message.warning('不可访问该应用')
      return
    }
    navigate(`/templates/run/${item.id}?isTemplate=true`)
  }
  return <TemplatePage onTemplateSetup={onTemplateSetup} />
}
