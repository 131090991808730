import styled from '@emotion/styled'
import { memo, useCallback, useEffect } from 'react'
import { useModal } from '@ebay/nice-modal-react'
import { useBoolean, useRequest } from 'ahooks'
import { Modal, message } from 'antd'
import {
  exportBatchTestData,
  resetBatchTestData,
  runBatchTest,
  stopBatchTest,
} from '@apis/batch-test'
import { Button, IconFont } from '@/components'
import { BatchProgress } from '@/features/flow/components/BatchProgress'
import { useBatchTestProgress } from '../hooks/useBatchTestProgress'
import { FLOW_DRAFT_LOCK_STATUS, useFlowDraftStore } from '@/store'
import { useSocket } from '@/hooks/useSocket'
import { ImportTestDataModal } from './ImportTestDataModal'

const StyledButton = styled(Button)`
  &.ant-btn {
    padding-left: 8px !important;
    width: 32px !important;
  }
`

export interface BatchTestHeaderProps {
  flowId: string
  onUpdate?: (type?: 'start' | 'end' | 'now') => void
  onImport?: () => void
  onExport?: () => void
  onClear?: () => void
  beforeRun?: () => boolean
  onRun?: (batchId: string) => void
  afterRun?: () => void
  onClose?: () => void
}

export const BatchTestHeader = memo((props: BatchTestHeaderProps) => {
  const {
    flowId,

    beforeRun,
    onRun,
    afterRun,
    onClose,
    onUpdate,
    onClear,
  } = props

  const { subscribe, open, close } = useSocket()

  const modal = useModal(ImportTestDataModal)

  const lockStatus = useFlowDraftStore(state => state.lockStatus)

  const [
    doneProgressVisible,
    { toggle: toggleProgressVisible, setTrue: showDoneProgressVisible },
  ] = useBoolean(false)

  const { loading: resetLoading, runAsync: resetData } = useRequest(
    () => resetBatchTestData(flowId),
    {
      manual: true,
      refreshDeps: [flowId],
      onSuccess() {
        onUpdate?.('start')
        message.success('重置成功')
      },
    },
  )

  const { runAsync: runBatchTestApi } = useRequest(() => runBatchTest(flowId), {
    manual: true,
    debounceWait: 400,
    refreshDeps: [flowId],
  })

  const [runLoading, { setTrue: showRunLoading, setFalse: hideRunLoading }] =
    useBoolean(false)

  const {
    progress,
    runningDoneSteps,
    totalSteps,
    refresh: refreshBatchTestProgress,
  } = useBatchTestProgress({
    flowId,
    onSuccess(data) {
      if (data.batch_test_run_status === 'FINISH') {
        close()
        showDoneProgressVisible()
        afterRun?.()
        onUpdate?.('now')
      }
    },
  })

  useEffect(() => {
    return subscribe(refreshBatchTestProgress)
  }, [])

  const runStatus = progress?.batch_test_run_status

  useEffect(() => {
    if (lockStatus === FLOW_DRAFT_LOCK_STATUS.LOCK_BATCH_TEST_RUN) {
      open()
      refreshBatchTestProgress()
    }
  }, [])

  const handleImport = useCallback(() => {
    modal.show({
      flowId,
      onConfirm() {
        onUpdate?.('end')
      },
    })
  }, [flowId])

  const handleExport = async () => {
    const data = await exportBatchTestData(flowId)

    if (data) {
      window.open(data)
    }
  }

  const handleReset = () => {
    Modal.confirm({
      icon: <></>,
      content: '确认要重置所有数据？',
      cancelText: '取消',
      okText: '确认',
      okButtonProps: { danger: true },
      onOk: async () => {
        await resetData()
        onClear?.()
      },
    })
  }

  const handleRun = async () => {
    const pass = beforeRun ? beforeRun?.() : true
    if (!pass) return
    open()
    showRunLoading()
    setTimeout(() => hideRunLoading(), 2000)
    const res = await runBatchTestApi()
    onRun?.(res.batch_test_run_id)
    refreshBatchTestProgress()
  }

  const handleStop = async () => {
    await stopBatchTest(flowId)
    refreshBatchTestProgress()
  }

  return (
    <div className='flex-none flex w-full h-48px items-center px-16 justify-between'>
      <div className='text-16px text-font font-600'>批量调试</div>

      {runStatus === 'RUNNING' && (
        <div className='fixed top-1px left-50% -translate-x-1/2'>
          <BatchProgress
            status='running'
            totalSteps={totalSteps}
            currentStep={runningDoneSteps}
            onStop={handleStop}
          />
        </div>
      )}

      {runStatus === 'FINISH' && doneProgressVisible && (
        <div className='fixed top-8px left-50% -translate-x-1/2'>
          <BatchProgress
            status='done'
            successCount={progress?.SUCCEEDED}
            errorCount={(progress?.total || 0) - (progress?.SUCCEEDED || 0)}
            onStop={handleStop}
            onClose={toggleProgressVisible}
          />
        </div>
      )}
      <div className='flex gap-8px items-center '>
        <Button
          className='c-#17171D flex-center text-12px w-66px'
          size='small'
          disabled={runStatus === 'RUNNING'}
          icon={<IconFont name='wenjiandaoru1x' className='text-14px' />}
          onClick={handleImport}
        >
          导入
        </Button>
        <Button
          className='c-#17171D flex-center text-12px w-66px'
          onClick={handleExport}
          disabled={runStatus === 'RUNNING'}
          size='small'
          icon={<IconFont name='wenjiandaochu' className='text-14px' />}
        >
          导出
        </Button>
        <Button
          className='c-#17171D flex-center text-12px w-66px'
          size='small'
          icon={<IconFont name='qingkongrizhi' className='text-14px' />}
          loading={resetLoading}
          disabled={runStatus === 'RUNNING'}
          onClick={handleReset}
        >
          重置
        </Button>
        <div className='h-16px b-l-1 b-l-line b-op-80 mx-10px'></div>
        <Button
          size='small'
          type='primary'
          loading={runLoading || progress?.batch_test_run_status === 'RUNNING'}
          icon={<IconFont name='yunxing' className='text-12px' />}
          onClick={handleRun}
        >
          {runLoading || progress?.batch_test_run_status === 'RUNNING'
            ? '运行中'
            : '批量运行'}
        </Button>
        <StyledButton
          size='small'
          className='flex items-center w-32px px-8px!'
          onClick={onClose}
          icon={<IconFont name='tuichuquanping1x' className='text-14px' />}
        ></StyledButton>
      </div>
    </div>
  )
})
