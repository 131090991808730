import type { FormInstance } from 'antd'
import { useState } from 'react'
import type { ColumnType } from '@bty/smartsheet'
import { UITypes } from '@bty/smartsheet'
import { get } from 'lodash-es'
import { generalTableAndColumnDesc } from '@apis/database'

function generalColumnDescReq(columns: ColumnType[]) {
  return columns
    .filter((c: ColumnType) => c.column_name.trim())
    .map(c => ({
      column_name: c.column_name,
      column_type: c.uidt ?? UITypes.SingleLineText,
      is_required: !!c.rqd,
    }))
}

interface TableFormData {
  title: string
  description?: string
  columns: ColumnType[]
}

async function generalDescription(
  tableData: TableFormData,
  form: FormInstance,
  fieldName?: (string | number)[],
) {
  const res = await generalTableAndColumnDesc(
    tableData.title,
    generalColumnDescReq(tableData.columns),
  )
  const columnDescMap: Record<string, string> = {}
  res.columns.forEach(item => {
    columnDescMap[item.column_name] = item.column_description
  })
  const newFormData = { ...tableData }
  newFormData.description = res.table_description ?? ''
  newFormData.columns = newFormData.columns.map((item: ColumnType) => {
    return {
      ...item,
      description: columnDescMap[item.column_name] ?? item.description,
    }
  })

  if (fieldName) {
    form.setFieldValue(fieldName, newFormData)
  } else {
    form.setFieldsValue(newFormData)
  }
  form.validateFields()
}

export function useTableDescGeneral(form: FormInstance<any>) {
  const [loading, setLoading] = useState(false)

  const onTableDescriptionGeneral = async (namePath?: (string | number)[]) => {
    const formData = form.getFieldsValue()
    const tableData = namePath ? get(formData, namePath) : formData
    if (!loading) {
      setLoading(true)
      const generalFunc = async () => {
        try {
          await generalDescription(tableData, form, namePath)
        } catch (e) {}
      }
      await generalFunc()
      setLoading(false)
    }
  }

  return {
    loading,
    onTableDescriptionGeneral,
  }
}
