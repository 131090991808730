import { isNil } from 'lodash-es'
import type { HitItem } from '@apis/datastore/type'

export function HitExtraInfo({
  relevance_score,
  rank,
}: {
  relevance_score: HitItem['relevance_score']
  rank: number
}) {
  return (
    <div className='rounded-4px px-8px py-2px bg-[rgba(0,200,83,0.08)] flex items-center c-#00C853 text-12px line-height-24px gap-16px h-24px'>
      <p>
        <span>排名 </span>
        <span>{rank}</span>
      </p>
      {!isNil(relevance_score.reranker) && (
        <p>
          <span>重排 </span>
          <span>{relevance_score.reranker?.toFixed(2)}分</span>
        </p>
      )}
      {!isNil(relevance_score.vector) && (
        <p>
          <span>语义 </span>
          <span>{relevance_score.vector?.toFixed(2)}分</span>
        </p>
      )}
      {!isNil(relevance_score.keywords) && (
        <p>
          <span>关键词 </span>
          <span>{relevance_score.keywords?.toFixed(2)}分</span>
        </p>
      )}
    </div>
  )
}
