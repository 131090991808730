import {
  useBoolean,
  useDebounceFn,
  useRequest,
  useSize,
  useUnmount,
} from 'ahooks'
import type { FC, ReactNode } from 'react'
import {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'
import { Input, message } from 'antd'
import classNames from 'classnames'
import { find } from 'lodash-es'
import { Resizable } from 'react-resizable'
import {
  fetchNodePanelVariablesApi,
  runBySingleStepApi,
  updateNodePanelVariablesApi,
} from '@apis/node-panel'
import { IconFont } from '@/components'
import theme from '@/constants/theme'
import { transformNodesModifiedVariable } from '@/features/nodes/utils'
import { useFlowDraftStore, useNodeStore } from '@/store'
import { useVariableDeps } from '../hooks/useVariableDeps'
import type { FiledType } from '../start'
import { DebugResultPanel, DebugResultPanelContext } from './DebugResultPanel'
import { NodeContext } from './context'
import {
  NodeType,
  type NodeConfig,
  type PatchVariableChangeOptions,
} from './types'
import { fullscreenNodePanelType } from './const'

interface NodePanelProps {
  panels: Omit<NodeConfig, 'node'>[]
}

const PanelWrapper = styled.div`
  position: fixed;
  padding-top: 48px;

  bottom: 0;
  right: 0;
  top: 0;
  z-index: 99;
  background: #ffffff;
  box-sizing: border-box;
  border-width: 0px;
  /* border-top-width: 1px; */
  border-color: rgba(225, 225, 229, 0.6);
  border-style: solid;
  display: flex;
  flex-direction: column;
`

export const PanelHeader = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  opacity: 1;
  padding: 12px 16px;
  width: 100%;
  border-width: 0px;
  border-bottom-width: 1px;
  border-color: rgba(225, 225, 229, 0.6);
  border-style: solid;

  box-sizing: border-box;
  background: #fff;
  z-index: 9;
`

const PanelContent = styled.div`
  height: calc(100% - 48px);
`

const StyledInput = styled(Input)`
  width: 220px;
  height: 24px;
  &:focus {
    border: 1px solid rgba(255, 255, 255, 0.6);
  }
  &:disabled {
    color: #fff;
  }
`

const Handler = styled.div<{ axis?: 'x' | 'y'; showHoverLine: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1000;
  border: 0px solid red;

  ${({ axis, showHoverLine }) => {
    switch (axis) {
      case 'x':
        return `
          height: 100%;
          cursor: ${showHoverLine ? 'ew-resize' : 'inherit'};
          border-left-width: ${showHoverLine ? '1px' : '0px'};
          :hover{
            border-right-width: ${showHoverLine ? '1px' : '0px'};
          }
        `
      case 'y':
        return `
          width: 100%;
          cursor: ${showHoverLine ? 'ns-resize' : 'inherit'};
          border-top-width: ${showHoverLine ? '1px' : '0px'};
          :hover{
            border-bottom-width:${showHoverLine ? '1px' : '0px'};
          }
        `
      default:
        return ''
    }
  }}

  border-style: solid;
  border-color: rgba(225, 225, 229, 0.6);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.18);

  :hover {
    border-color: ${props =>
      props.showHoverLine ? props.theme.colors.primary : 'transparent'};
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: transparent;
  }
`

function useBaseNodePanel(nodes: NodePanelProps['panels']) {
  const activatedNodeMeta = useNodeStore(state => state.activatedNodeMeta)
  const activatedNodeData = useNodeStore(state => state.activatedNodeData)
  const activatedNodeId = useNodeStore(state => state.activatedNodeId)
  const activatedNodeType = useNodeStore(state => state.activatedNodeType)

  const Panel = useMemo(() => {
    return nodes?.find(node => node.meta.type === activatedNodeMeta?.type)
      ?.panel
  }, [nodes, activatedNodeId, activatedNodeMeta?.type])

  const CustomPanelHeader = useMemo(() => {
    return nodes?.find(node => node.meta.type === activatedNodeMeta?.type)
      ?.panelHeader
  }, [nodes, activatedNodeId, activatedNodeMeta?.type])

  return {
    Panel,
    CustomPanelHeader,
    activatedNodeMeta,
    activatedNodeId,
    activatedNodeType,
    nodeData: activatedNodeData,
  }
}

export function useEditName(_name: string) {
  const [name, setName] = useState(_name)
  const validateName = (text: string) => {
    const isValidate = /^[A-Za-z0-9_]+$/.test(text)

    return {
      pass: isValidate,
      validateMessage: !isValidate
        ? '名称必须是英文字符、数字、下划线且不包含特殊字符'
        : '',
    }
  }
  return {
    name,
    setName,
    validateName,
  }
}

export const NodeConfigPanel: FC<NodePanelProps> = memo(props => {
  const { panels = [] } = props
  const containerRef = useRef<HTMLElement>(null)
  const {
    Panel,
    CustomPanelHeader,
    nodeData = {},
    activatedNodeType,
    activatedNodeId,
    activatedNodeMeta: meta,
  } = useBaseNodePanel(panels)
  const {
    icon,
    isStart,
    isEnd,
    backgroundColor = theme.colors.bg_1,
  } = meta || {}
  const deactivateNode = useNodeStore(state => state.deactivateNode)

  const { flowId, nodes, edges, updateNode, setNodes, setViewPortNodeId } =
    useFlowDraftStore()
  const [data, setData] = useState<any>(nodeData)
  const [isEditTitle, { toggle: toggleTitleEdit }] = useBoolean(false)

  const size = useSize(document.body)

  const [panelSize, setPanelSize] = useState({
    width:
      document.documentElement.clientWidth *
      (fullscreenNodePanelType.includes(activatedNodeType!) ? 1 : 0.35),
    differenceX: document.documentElement.clientWidth * 0.65,
    differenceY: document.documentElement.clientHeight * 0.5,
    height: document.documentElement.clientHeight * 0.5,
  })

  useLayoutEffect(() => {
    if (fullscreenNodePanelType.includes(activatedNodeType!)) {
      setPanelSize({
        ...panelSize,
        width: size?.width as any,
      })
    } else {
      setPanelSize({
        ...panelSize,
        width: (size?.width as any) - panelSize.differenceX,
        height: (size?.height as any) - panelSize.differenceY,
      })
    }
  }, [activatedNodeType, size])

  const [triggerNode, registerTriggerNode] = useState<ReactNode>(null)
  const [debuggerPanelExtraButton, setDebuggerPanelExtraButton] =
    useState<ReactNode>(null)
  const [extraLeftButton, setExtraLeftButton] = useState<ReactNode>(null)
  const [extraRightButton, setExtraRightButton] = useState<ReactNode>(null)
  const [debuggerPanelVisible, setDebuggerPanelVisible] = useState(false)
  const [debuggerNodePanelVariable, setDebuggerNodePanelVariable] = useState({})
  const [debuggerPanelResult, setDebuggerPanelResult] = useState<Record<
    string,
    any
  > | null>(null)

  const { runAsync: runBySingleStep, loading: executeNodePanelLoading } =
    useRequest(runBySingleStepApi, {
      manual: true,
      onSuccess: data => {
        setDebuggerPanelResult(data)
      },
    })

  const { runAsync: fetchNodePanelVariable } = useRequest(
    fetchNodePanelVariablesApi,
    {
      manual: true,
      onSuccess: data => {
        setDebuggerNodePanelVariable(data)
      },
    },
  )

  const { runAsync: updateNodePanelVariables } = useRequest(
    updateNodePanelVariablesApi,
    {
      manual: true,
      onSuccess: data => {
        console.log(data, 'nodePanelVariableData')
      },
    },
  )

  const { run: debounceUpdateNodePanelVariables } = useDebounceFn(
    async params => {
      return await updateNodePanelVariables({
        variable_list: params,
        flow_id: flowId,
        node_id: activatedNodeId!,
      })
    },
    {
      wait: 500,
    },
  )

  useEffect(() => {
    fetchNodePanelVariable({
      flow_id: flowId,
      node_id: activatedNodeId!,
    })
  }, [activatedNodeId, flowId])

  useEffect(() => {
    setData(nodeData as any)
  }, [activatedNodeId])

  useEffect(() => {
    setDebuggerPanelVisible(false)
    setDebuggerPanelResult(null)
  }, [activatedNodeId])

  const startNodeFormItemType = useMemo(() => {
    const startNodeConfig = find(nodes, { type: NodeType.START })
    const formConfig = startNodeConfig?.data?.formConfig || []

    return formConfig.map(
      (item: {
        variableName: any
        type: FiledType
        required: boolean
        supportFileTypes: unknown[]
        options?: { label: string; value: string }[]
        placeholder?: string
      }) => {
        return {
          key: item.variableName,
          type: item.type,
          required: item.required,
          supportFileTypes: item?.supportFileTypes || [],
          options: item?.options || [],
          placeholder: item?.placeholder || '',
        }
      },
    )
  }, [nodes])

  const { name, setName, validateName } = useEditName(data.name)

  const { run: onUpdateNode } = useDebounceFn(
    (data, patchVariableChangeOptions?: PatchVariableChangeOptions) => {
      // 如果修改了节点名称，需要更新节点的变量
      if (patchVariableChangeOptions) {
        const updateNodes = nodes.map(item =>
          item.id === activatedNodeId ? { ...item, ...data.node } : item,
        )
        const { newVariableName, oldVariableName } = patchVariableChangeOptions
        setNodes(
          transformNodesModifiedVariable(
            updateNodes,
            newVariableName,
            oldVariableName,
          ),
        )
      }
      updateNode(data)
    },
    { wait: 500 },
  )

  const { variables } = useVariableDeps(activatedNodeId || '', nodes, edges)

  const onSaveChange = useCallback(
    (
      _data: any,
      isMergeUpdate = true,
      patchVariableChangeOptions?: PatchVariableChangeOptions,
    ) => {
      let fullData = _data
      const { inputs: newInputs } = _data
      const { inputs: originInputs } = data

      if (isMergeUpdate) {
        const fullInputs = { ...originInputs, ...newInputs }
        fullData = Object.assign({}, data, _data, { inputs: fullInputs })
      }
      const allNodeData = { id: activatedNodeId, node: { data: fullData } }
      onUpdateNode(allNodeData, patchVariableChangeOptions)
      setData(fullData)
    },
    [data, onUpdateNode],
  )

  const onClosePanel = () => {
    setViewPortNodeId('')
    deactivateNode()
  }

  useUnmount(() => {
    onClosePanel()
  })

  const onSaveTitle = useCallback(() => {
    const { pass, validateMessage } = validateName(name)
    if (!pass || !name) {
      message.error(validateMessage)
      return
    }
    if (
      nodes
        .filter(v => v.id !== activatedNodeId)
        .some(v => v.data.name === name)
    ) {
      message.error('该节点名已存在')
      setName(data.name)
      toggleTitleEdit()
      return
    }
    onSaveChange({ name } as any, true, {
      newVariableName: name,
      oldVariableName: data.name,
    })
    toggleTitleEdit()
  }, [name, data, onSaveChange, activatedNodeId])

  const isNotLLMNode = meta?.type !== NodeType.LLM
  const isCodeNode = [NodeType.PYTHON, NodeType.JAVASCRIPT].includes(
    meta?.type as any,
  )
  const isTemplateNode = [NodeType.TEMPLATE].includes(meta?.type as any)

  const isNotDatabaseNodeOrLLMNodeOrCodeNode = ![
    NodeType.PYTHON,
    NodeType.JAVASCRIPT,
    NodeType.DATABASE,
    NodeType.LLM,
    NodeType.TEMPLATE,
  ].includes(meta?.type as NodeType)

  const onXAxisResize = (_event: any, { size: currentSize }: any) => {
    _event.stopPropagation()
    if (!fullscreenNodePanelType.includes(activatedNodeType!)) {
      setPanelSize({
        ...currentSize,
        differenceX: (size?.width as any) - currentSize.width,
        height: panelSize.height,
      })
    }
  }

  const onYAxisResize = (_event: any, { size: currentSize }: any) => {
    _event.stopPropagation()
    if (!fullscreenNodePanelType.includes(activatedNodeType!)) {
      setPanelSize({
        ...currentSize,
        differenceY: panelSize.differenceY,
        width: panelSize.width,
      })
    }
  }

  const maxWidth = useMemo(() => {
    return (size?.width || 1336) * 0.75
  }, [size])

  const maxHeight = useMemo(() => {
    return (size?.height || 1080) * 0.75
  }, [size])

  const nodeHeaderName = useMemo(() => {
    if ([NodeType.PLUGIN, NodeType.TEMPLATE].includes(meta?.type!)) {
      const pluginDisplayName = (data?.displayName as string) || ''
      return pluginDisplayName
    } else {
      return meta?.typeName
    }
  }, [meta, data])

  const nodeHeaderIcon = useMemo(() => {
    if ([NodeType.PLUGIN, NodeType.TEMPLATE].includes(meta?.type!)) {
      const nodeIcon = (data?.icon as string) || ''
      return nodeIcon
    } else {
      return icon
    }
  }, [meta, icon, data?.icon])

  const iconEle = useMemo(() => {
    return nodeHeaderIcon?.startsWith('http') ? (
      <img
        className={classNames(
          'w-32px h-32px rounded-6px object-contain b-rd-6px',
          {},
        )}
        src={nodeHeaderIcon}
      />
    ) : (
      <div
        className='flex items-center justify-center w-32px h-32px  b-rd-6px'
        style={{
          background: backgroundColor,
        }}
      >
        <IconFont
          className={classNames('text-white line-height-32px text-20px', {
            '!text-#17171D': meta?.type === NodeType.PLUGIN,
          })}
          name={icon || ''}
        />
      </div>
    )
  }, [icon, nodeHeaderIcon, meta?.type, backgroundColor])

  return (
    <>
      {Panel && (
        <PanelWrapper
          style={{
            width: panelSize.width,
          }}
          className={classNames({
            '!h-100vh overflow-hidden': isCodeNode || isTemplateNode,
          })}
          ref={containerRef as any}
        >
          <Resizable
            axis='x'
            resizeHandles={['w']}
            minConstraints={[500, 100]}
            maxConstraints={[maxWidth, Number.POSITIVE_INFINITY]}
            width={panelSize.width}
            onResize={onXAxisResize}
            handle={<Handler axis='x' showHoverLine />}
          >
            <DebugResultPanelContext.Provider
              value={{
                run: async () => {
                  return runBySingleStep({
                    flow_id: flowId,
                    node_id: activatedNodeId!,
                  })
                },
                loading: executeNodePanelLoading,

                result: debuggerPanelResult,
                setResult: newResult => {
                  setDebuggerPanelResult(newResult)
                },
                visible: debuggerPanelVisible,
                setVisible: vis => setDebuggerPanelVisible(vis),
                variables: debuggerNodePanelVariable,
                setVariables: async (params, isMergeUpdate = true) => {
                  const newVariables = isMergeUpdate
                    ? {
                        ...debuggerNodePanelVariable,
                        ...params,
                      }
                    : params
                  await setDebuggerNodePanelVariable(newVariables)
                  await debounceUpdateNodePanelVariables(newVariables)
                },

                registerTriggerNode: (node: any, callback) => {
                  registerTriggerNode(() => {
                    return (
                      <div
                        onClick={async () => {
                          await callback()
                          await fetchNodePanelVariable({
                            node_id: activatedNodeId!,
                            flow_id: flowId,
                          })
                          setDebuggerPanelVisible(true)
                        }}
                      >
                        {node()}
                      </div>
                    )
                  })
                },
                setDebuggerPanelExtraButton,
                setExtraLeftButton,
                setExtraRightButton,
              }}
            >
              {CustomPanelHeader ? (
                <CustomPanelHeader
                  data={data}
                  onClosePanel={onClosePanel}
                  onSaveChange={onSaveChange}
                  activatedNodeId={activatedNodeId}
                  triggerNode={triggerNode}
                />
              ) : (
                <PanelHeader className='flex justify-between items-center text-14px h-48px'>
                  <div>
                    <div className='flex items-center'>
                      <div className='bg py-6 b-rd-4px'>
                        {nodeHeaderIcon && iconEle}
                      </div>
                      <div className='ml-8 mr-8 text-16px font-500'>
                        {nodeHeaderName}
                      </div>

                      <div>
                        {isEditTitle ? (
                          <StyledInput
                            size='small'
                            disabled={isStart || isEnd}
                            value={name}
                            maxLength={16}
                            onDoubleClick={e => {
                              e && e.stopPropagation()
                            }}
                            onChange={e => setName(e.target.value)}
                            onBlur={onSaveTitle}
                            style={{
                              borderColor: '#7b61ff',
                            }}
                            className='nodrag text-12px flex items-center text-#8D8D99 p-4 b-1px  b-rd-4px b-opacity-12 bg-opacity-6  hover:bg-opacity-8 hover:bg-#626999 hover:b-transparent'
                          />
                        ) : (
                          <div
                            onClick={() => {
                              if (isStart || isEnd) return
                              toggleTitleEdit()
                            }}
                            style={{
                              borderColor: 'rgba(225, 225, 229, 0.8)',
                            }}
                            className={classNames(
                              'nodrag text-12px flex items-center text-#8D8D99 h-20px p-4 b-1px b-rd-4px b-#E1E1E5 b-opacity-12 bg-opacity-6 hover:bg-opacity-0 hover:bg-opacity-8 hover:bg-#626999  hover:b-transparent',
                              {
                                'cursor-pointer': !isStart && !isEnd,
                                'cursor-not-allowed': isStart || isEnd,
                              },
                            )}
                          >
                            {data.name}
                          </div>
                        )}
                      </div>

                      {extraLeftButton}
                    </div>
                  </div>
                  <div className='flex items-center'>
                    {extraRightButton}
                    {triggerNode}
                    <div className='flex flex-items-center'>
                      <div
                        onClick={onClosePanel}
                        className=' b-1px b-rd-4px m-l-8px w-32px h-32px box-border b-rd-6px flex flex-items-center flex-justify-center cursor-pointer hover:bg-#626999 hover:bg-op-12'
                      >
                        <IconFont name='guanbi' className='text-16px' />
                      </div>
                    </div>
                  </div>
                </PanelHeader>
              )}

              <PanelContent
                className={classNames({
                  'overflow-y-auto': isNotLLMNode,
                  '!h-100%': isCodeNode || isTemplateNode,
                })}
              >
                <NodeContext.Provider value={{ onSaveChange, data }}>
                  <>
                    <Panel
                      data={data}
                      variables={variables}
                      activatedNodeId={activatedNodeId}
                      nodeElement={containerRef.current}
                      onSaveChange={onSaveChange}
                      onClosePanel={onClosePanel}
                      startNodeFormItemType={startNodeFormItemType}
                    />
                    {isNotDatabaseNodeOrLLMNodeOrCodeNode && (
                      <>
                        <div className='absolute bottom-0 right-0px w-full z-9 will-change-height'>
                          <Resizable
                            axis='y'
                            resizeHandles={['n']}
                            minConstraints={[100, 300]}
                            maxConstraints={[
                              Number.POSITIVE_INFINITY,
                              maxHeight,
                            ]}
                            height={panelSize.height}
                            onResize={onYAxisResize}
                            handle={
                              <Handler
                                axis='y'
                                // showHoverLine={false}
                                showHoverLine={debuggerPanelVisible}
                              />
                            }
                          >
                            <>
                              <DebugResultPanel
                                height={panelSize.height}
                                resultExtraButton={debuggerPanelExtraButton}
                              />
                            </>
                          </Resizable>
                        </div>
                        {debuggerPanelVisible ? (
                          <div
                            style={{
                              height: panelSize.height + 100,
                            }}
                          ></div>
                        ) : (
                          <div
                            style={{
                              height: 100,
                            }}
                          ></div>
                        )}
                      </>
                    )}
                  </>
                </NodeContext.Provider>
              </PanelContent>
            </DebugResultPanelContext.Provider>
          </Resizable>
        </PanelWrapper>
      )}
    </>
  )
})
