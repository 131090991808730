import { useEffect, useMemo } from 'react'
import { useAuth } from '@bty/react-auth'
import dayjs from 'dayjs'
import { useRequest } from 'ahooks'
import { getWorkspaceAdminMembers } from '@apis/authority'
import { RoleCode } from '@apis/authority/type'
import { getVersionInfoByWorkspaceId } from '@apis/version'
import { useVersionStore, useWorkspaceStore } from '@/store'
import { VersionStatus } from '../../const'

export function useAlertInfo() {
  const {
    state: { role },
  } = useAuth()

  const {
    data: botVersionInfo,
    runAsync: getAsyncVersionInfoByWorkspaceId,
    mutate: mutateBotVersionInfo,
  } = useRequest(getVersionInfoByWorkspaceId, {
    manual: true,
  })

  const { versionInfo, botWorkspaceItem, fetchVersionByWorkspaceId } =
    useVersionStore(state => ({
      fetchVersionByWorkspaceId: state.fetchVersionByWorkspaceId,
      versionInfo: state.versionInfo,
      botWorkspaceItem: state.botWorkspaceItem,
    }))

  const { currentWorkspaceItem, workspaceList, currentWorkspaceId } =
    useWorkspaceStore(state => ({
      currentWorkspaceItem: state.currentWorkspaceItem,
      workspaceList: state.workspaceList,
      currentWorkspaceId: state.currentWorkspaceId,
    }))

  const { data: adminMembers, runAsync: getAsyncWorkspaceAdminMembers } =
    useRequest(getWorkspaceAdminMembers, {
      manual: true,
      cacheKey: 'get_workspace_admin_members',
    })

  useEffect(() => {
    if (currentWorkspaceId) {
      getAsyncWorkspaceAdminMembers(currentWorkspaceId)
    }
  }, [currentWorkspaceId])

  useEffect(() => {
    // 如果打开的是其他空间的 chatbot， 获取其他空间的 versionInfo
    if (botWorkspaceItem?.workspaceId) {
      getAsyncVersionInfoByWorkspaceId(botWorkspaceItem.workspaceId)
    } else {
      mutateBotVersionInfo(undefined)
    }
  }, [botWorkspaceItem?.workspaceId])

  useEffect(() => {
    if (currentWorkspaceId && !versionInfo) {
      fetchVersionByWorkspaceId(currentWorkspaceId)
    }
  }, [versionInfo, currentWorkspaceId])

  const alertInfo = useMemo(() => {
    const workspaceName = currentWorkspaceItem?.name
    const isAdministrator = role === RoleCode.ADMINISTRATOR
    const adminName =
      (adminMembers || []).find(
        admin => admin.userId === currentWorkspaceItem?.creator,
      )?.username ||
      adminMembers?.[0]?.username ||
      ''
    const isExpired =
      versionInfo &&
      (versionInfo?.status === VersionStatus.LoseEffect ||
        dayjs().isAfter(dayjs(versionInfo.lose_effect_time)))
    const isPointExhausted =
      versionInfo &&
      versionInfo.points_consume_num +
        (versionInfo.dosage_package_points_consume_num || 0) >=
        versionInfo.points_count_limit +
          (versionInfo.dosage_package_points_count_total || 0)
    if (isExpired || isPointExhausted) {
      return {
        product_code: versionInfo.product_code,
        type: isExpired ? 'expired' : 'exhausted',
        isAdmin: isAdministrator,
        workspaceName,
        adminName,
      }
    }
    return null
  }, [currentWorkspaceItem, versionInfo, role, adminMembers])

  const botAlertInfo = useMemo(() => {
    if (botWorkspaceItem && botVersionInfo) {
      const workspaceName = botWorkspaceItem?.workspaceName
      const isAdministrator =
        workspaceList.find(el => el.id === botWorkspaceItem?.workspaceId)
          ?.roleCode === RoleCode.ADMINISTRATOR
      const adminName = botWorkspaceItem?.developerName || ''
      const isExpired =
        botVersionInfo && botVersionInfo?.status === VersionStatus.LoseEffect
      const isPointExhausted =
        botVersionInfo &&
        botVersionInfo.points_consume_num +
          (botVersionInfo.dosage_package_points_consume_num || 0) >=
          botVersionInfo.points_count_limit +
            (botVersionInfo.dosage_package_points_count_total || 0)
      if (isExpired || isPointExhausted) {
        return {
          type: isExpired ? 'expired' : 'exhausted',
          isAdmin: isAdministrator,
          workspaceName,
          adminName,
        }
      }
    }
    return null
  }, [versionInfo, botWorkspaceItem, botVersionInfo])

  return {
    alertInfo,
    botAlertInfo,
    currentWorkspaceId,
    getAsyncWorkspaceAdminMembers,
    role,
    currentWorkspaceName: currentWorkspaceItem?.name,
    botWorkspaceId: botWorkspaceItem?.workspaceId,
  }
}
