import { request } from '../../client'

const version = 'v1'

/**
 * @description 收藏模板应用
 */
export function addFavoriteTemplateApp(application_id: string) {
  return request.post(`${version}/favorite_app/add`, { application_id })
}

/**
 * @description 删除模板应用
 */
export function deleteFavoriteTemplateApp(application_id: string) {
  return request.delete(`${version}/favorite_app/delete/${application_id}`)
}

export function getFavoriteTemplateAppList() {
  return request.get<string[]>(`${version}/favorite_app/list`)
}
