import cn from 'classnames'
import { Popover, Radio } from 'antd'
import type { SkillFlowViewMode } from '@apis/run/type'
import { skillFlowViewSelectOptions } from '@/features/agent/constant'

export interface InputsViewModeSelectSelectProps {
  value?: SkillFlowViewMode
  onChange?: (value: SkillFlowViewMode) => void
}

export function InputsViewModeSelect(props: InputsViewModeSelectSelectProps) {
  const { value, onChange } = props

  return (
    <div className='flex items-center gap-12'>
      {skillFlowViewSelectOptions.map(option => {
        const comp = (
          <div
            key={option.value}
            className={cn(
              'flex items-center px-12px py-8 b-1 hover:bg-bg_3/6 b-#EDEDF0 b-rd-8px flex-1 cursor-pointer',
              {
                'b-primary!': value === option.value,
              },
            )}
            onClick={() => {
              onChange?.(option.value)
            }}
          >
            <Radio checked={option.value === value} />
            <span className='ml-8px'>{option.label}</span>
          </div>
        )
        if (option.viewImage) {
          return (
            <Popover
              trigger={['hover']}
              key={option.value}
              arrow={false}
              align={{ offset: [40, 0] }}
              content={
                <div className='p-12'>
                  <img
                    className='w-200px h-221px'
                    alt='示例预览'
                    src={option.viewImage}
                  />
                  <div className='font-400 c-font_1 mt-11 text-center'>
                    表单形式调用
                  </div>
                </div>
              }
              placement='right'
            >
              {comp}
            </Popover>
          )
        }
        return comp
      })}
    </div>
  )
}
