import { SearchOutlined } from '@ant-design/icons'
import { useAuth } from '@bty/react-auth'
import { useRequest } from 'ahooks'
import { message } from 'antd'
import React, { useImperativeHandle, useMemo, useState } from 'react'
import { getGroupedAppList } from '@apis/application'
import {
  changeUserRole,
  getWorkspaceUsers,
  removeUserFromWorkspace,
  sendInviteMsg,
  updatePermittedApplicationsForUser,
} from '@apis/authority'
import type {
  InviteMembersRequestBody,
  RemoveUserRequest,
  RoleBodyRequest,
  UpdatePermittedApplicationsRequestBody,
  UserItem,
} from '@apis/authority/type'
import { RoleCode } from '@apis/authority/type'
import { updateMemberInfo, workspaceCheckMember } from '@apis/workspace'
import { useVersionStore, useWorkspaceStore } from '@/store'
import { Button, IconFont, Input, Select, Table, useConfirmModal } from '..'

import { PrivateDeployGuard } from '@/features/guard/PrivateDeployGuard.tsx'
import { useLimitedModal } from '@/features/pay'
import { LimitedAccess } from '@/features/pay/LimitedAccess'
import { useComboRequest } from '@/hooks/useComboRequest'
import { MemberTypes } from '@/pages/workspace/constant'
import { InvitePopover } from '@/pages/workspace/settings/components/InvitePopover'
import { getRoleOptions } from '@/pages/workspace/settings/util'
import { AppAuthModal } from '@/pages/workspace/settings/components/AppAuthModal'
import { UserList, getUserColumns } from './columns'

enum UserType {
  ADMINISTRATOR = 4,
  DEVELOPER = 3,
  VIEWER = 2,
  OTHER = 1,
}

interface UserSearchTableProps {
  applicationId?: string
  needRefreshList?: boolean
  type?: 'PopoverShare' | 'list'
  tableHeight?: number | string
  onListChange?: () => void
  role?: RoleCode
  appOptions?: {
    label: string
    value: string
    applicationType: string
  }[]
}

export const UserSearchTable = React.forwardRef(
  (props: UserSearchTableProps, ref) => {
    const {
      applicationId,
      needRefreshList,
      onListChange,
      type = 'list',
      role,
    } = props
    const currentWorkspaceId = useWorkspaceStore(
      state => state.currentWorkspaceId,
    )
    const versionInfo = useVersionStore(state => state.versionInfo)
    const {
      state: { role: workspaceRole },
    } = useAuth()

    const hasReachedUserLimit = useMemo(() => {
      return (
        versionInfo &&
        versionInfo?.exist_user_count >=
          versionInfo?.total_user_count_limit +
            versionInfo?.dosage_package_user_count_total
      )
    }, [versionInfo])

    const [comboModal] = useLimitedModal()
    const userRoleCode = useWorkspaceStore(
      state => state.currentWorkspaceItem!.roleCode,
    )
    const [modal] = useConfirmModal()
    const [searchValue, setSearchValue] = useState('')
    const [selectRole, setSelectRole] = useState<'all' | RoleCode>('all')
    const [authOpenRowId, setAuthOpenRowId] = useState<number | undefined>()
    const { fetchWorkspaceList, setCurrentWorkspace } = useWorkspaceStore()
    const [authModalOpen, setAuthModalOpen] = useState(false)
    const [currentUserItem, setCurrentUserItem] = useState<UserItem | null>(
      null,
    )

    const {
      data: memberList = [],
      refreshAsync: refreshMemberList,
      loading,
    } = useRequest(
      () =>
        getWorkspaceUsers({
          workspaceId: currentWorkspaceId,
          applicationType: 'AI',
          ...(applicationId ? { applicationId } : {}),
        }),
      { ready: needRefreshList ?? true, refreshDeps: [currentWorkspaceId] },
    )

    const { data: groupedList } = useRequest(() =>
      getGroupedAppList(currentWorkspaceId),
    )

    const { runAsync: changeUserRoleRequest, loading: changeRoleLoading } =
      useComboRequest(
        async ({ sourceRoleCode, ...params }) => {
          const res = await changeUserRole(params)
          await updateMemberInfo({
            workspace_id: currentWorkspaceId,
            source_role_code: sourceRoleCode,
            target_role_code: params.roleCode,
          })
          return res
        },
        {
          manual: true,
          onSuccess: async () => {
            await fetchWorkspaceList()
            setCurrentWorkspace(currentWorkspaceId)
          },
        },
      )
    const { runAsync: updateApplications } = useRequest(
      updatePermittedApplicationsForUser,
      {
        manual: true,
      },
    )
    const { runAsync: removeUser, loading: removeLoading } = useComboRequest(
      async ({ sourceRoleCode, ...params }) => {
        const res = await removeUserFromWorkspace(params)
        await updateMemberInfo({
          workspace_id: currentWorkspaceId,
          target_role_code: sourceRoleCode,
          deleted: true,
        })
        return res
      },
      {
        manual: true,
      },
    )
    const { runAsync: inviteMember, loading: isInviting } = useComboRequest(
      sendInviteMsg,
      {
        manual: true,
      },
    )

    const onRoleChange = async (
      params: Omit<RoleBodyRequest, 'workspaceId'>,
    ) => {
      await workspaceCheckMember({
        targetRoleCode: params.roleCode,
        sourceRoleCode: params.sourceRoleCode,
      })
      const res = await changeUserRoleRequest({
        ...params,
        workspaceId: currentWorkspaceId,
      })
      if (res) {
        await refreshMemberList()
      }
      onListChange?.()
    }

    const onApplicationsChange = async (
      params: Omit<UpdatePermittedApplicationsRequestBody, 'workspaceId'>,
    ) => {
      const res = await updateApplications({
        ...params,
        workspaceId: currentWorkspaceId,
      })
      if (res) {
        await refreshMemberList()
      }
      onListChange?.()
    }

    const onUserRemove = async (params: RemoveUserRequest) => {
      const res = await removeUser(params)
      if (res) {
        modal.close()
        await refreshMemberList()
        onListChange?.()
      }
    }

    const onRemoveConfirm = (params: RemoveUserRequest) => {
      modal.open({
        title: '请确认是否要移除用户',
        onOk: () => {
          onUserRemove(params)
        },
        onCancel: () => {},
      })
    }

    const onInviteAgain = async (
      params: Omit<InviteMembersRequestBody, 'workspaceId' | 'url'>,
    ) => {
      const res = await inviteMember({
        ...params,
        workspaceId: currentWorkspaceId,
        url: `templates?workspaceId=${currentWorkspaceId}`,
      })
      if (res) {
        message.success('邀请成功')
        await refreshMemberList()
      }
    }

    const appOptions = useMemo(() => {
      if (!groupedList) return []
      return groupedList.map(item => ({
        label: item.name,
        value: item.id,
        applicationType: item.applicationType,
      }))
    }, [groupedList])

    const users = useMemo(() => {
      return selectRole !== 'all'
        ? memberList.filter(item => item.roleCode === selectRole)
        : searchValue
          ? memberList.filter(
              item =>
                item.phone.includes(searchValue) ||
                item.username.includes(searchValue),
            )
          : memberList
    }, [memberList, selectRole, searchValue])

    const invitableDevelopersCount = useMemo(() => {
      if (!versionInfo) {
        return 0
      }
      return Math.max(
        versionInfo.total_user_count_limit +
          versionInfo.dosage_package_user_count_total -
          versionInfo.exist_user_count,
        0,
      )
    }, [versionInfo])
    const dataSource = useMemo(() => {
      return users
        ?.map(item => ({
          ...item,
          sort:
            item.roleCode === RoleCode.ADMINISTRATOR
              ? UserType.ADMINISTRATOR
              : item.roleCode === RoleCode.DEVELOPER
                ? UserType.DEVELOPER
                : item.roleCode === RoleCode.VIEWER
                  ? UserType.VIEWER
                  : UserType.OTHER,
        }))
        ?.sort((a: any, b: any) => {
          return b.sort - a.sort
        })
    }, [users, applicationId])

    const onAuthModalOpen = (userItem: UserItem) => {
      setCurrentUserItem(userItem)
      setAuthModalOpen(true)
    }

    const onAppsAuth = async (appIds: string[], allChecked: boolean) => {
      if (currentUserItem) {
        const { userId, roleCode } = currentUserItem
        const params = {
          userId,
          roleCode,
          is_auth_all_app: allChecked,
          workspaceId: currentWorkspaceId,
          applicationIds: allChecked ? [] : appIds, // 服务端处理不了无该字段的情况，临时带上
        }
        const res = await updateApplications(params)
        if (res) {
          setAuthModalOpen(false)
          await refreshMemberList()
        }
      }
    }

    const columnsProps = {
      isInApplication: !!applicationId,
      appOptions,
      onRoleChange,
      onApplicationsChange,
      onRemoveConfirm,
      onInviteAgain,
      isInviting,
      userRoleCode,
      authOpenRowId,
      onAuthAppPopoverOpenChange: (rowId?: number) => {
        setAuthOpenRowId(rowId)
      },
      onAuthModalOpen,
    }

    useImperativeHandle(ref, () => ({
      refreshMemberList,
    }))

    return type === 'list' ? (
      <div className='flex flex-col flex-1 overflow-hidden'>
        <div className='flex items-center adapt:mb-20 pr-7px'>
          <Input
            className='w-400px m-r-12 bg-op-6'
            prefix={<SearchOutlined />}
            placeholder='搜索手机号、用户名称'
            value={searchValue}
            size='large'
            onChange={e => setSearchValue(e.target.value)}
          />
          <Select
            className='min-w-116px m-r-12 bg-op-6'
            options={MemberTypes}
            value={selectRole}
            size='large'
            onChange={value => setSelectRole(value)}
          />
          {!applicationId && (
            <div className='m-l-auto flex flex-items-center'>
              <PrivateDeployGuard>
                <>
                  {`还可邀请用户数量：${invitableDevelopersCount}个`}
                  <span
                    onClick={() => comboModal.open({ role: workspaceRole })}
                    className='ml-12px cursor-pointer text-14px font-500 c-primary'
                  >
                    扩容
                  </span>
                </>
              </PrivateDeployGuard>
              {/* 没有直接使用 limitedAccess 包裹的原因是避免在用户列表页面切换到其他空间，邀请 popover 会打开 */}
              {hasReachedUserLimit ? (
                <LimitedAccess limitedType='inviteUser'>
                  <Button
                    type='primary'
                    className='ml-24px'
                    icon={<IconFont name='add' />}
                  >
                    邀请用户
                  </Button>
                </LimitedAccess>
              ) : (
                <InvitePopover
                  onInviteCallback={refreshMemberList}
                  title='邀请用户'
                  workspaceId={currentWorkspaceId}
                  appOptions={appOptions}
                  roleOptions={getRoleOptions(false)}
                  onUserListChange={refreshMemberList}
                  showTable={false}
                  isInviteUser={true}
                  inviteWithoutAdmin={true}
                >
                  <Button
                    type='primary'
                    className='ml-24px'
                    icon={<IconFont name='add' />}
                  >
                    邀请用户
                  </Button>
                </InvitePopover>
              )}
            </div>
          )}
        </div>
        <Table
          sticky
          rowKey='userId'
          className='flex-1 overflow-y-scroll'
          pagination={false}
          columns={getUserColumns(columnsProps)}
          dataSource={dataSource}
          loading={loading || changeRoleLoading || removeLoading}
        />

        <AppAuthModal
          user={currentUserItem}
          appList={groupedList || []}
          open={authModalOpen}
          onSave={onAppsAuth}
          onClose={() => {
            setAuthModalOpen(false)
          }}
        />
      </div>
    ) : (
      <UserList
        dataSource={
          role === RoleCode.DEVELOPER
            ? dataSource?.filter(item => item.roleCode !== RoleCode.VIEWER)
            : dataSource
        }
        {...columnsProps}
      />
    )
  },
)
