import { Select } from 'antd'
import styled from '@emotion/styled'
import { AgentFeedbackStatusEn } from '@apis/agent/type'
import { IconFont } from '@/components'

const statusColorMap = {
  [AgentFeedbackStatusEn.Processed]: {
    backgroundColor: 'rgba(44, 185, 105, 0.12)',
    fontColor: '#2CB969',
  },
  [AgentFeedbackStatusEn.Pending]: {
    backgroundColor: 'rgba(254, 151, 0, 0.12)',
    fontColor: '#FE9700',
  },
  [AgentFeedbackStatusEn.Ignored]: {
    backgroundColor: 'rgba(225, 225, 229, 0.4)',
    fontColor: '#8D8D99',
  },
}

const StyledSelect = styled(Select<AgentFeedbackStatusEn>)<{
  value: AgentFeedbackStatusEn
}>`
  && {
    .ant-select-selector {
      background-color: ${props =>
        statusColorMap[props.value as keyof typeof statusColorMap]
          ?.backgroundColor || 'rgba(225, 225, 229, 0.4)'};
      border-color: transparent;
      box-shadow: none;
      padding: 0 4px 0px 8px;
      transition: none;
    }
    .ant-select-selection-item {
      line-height: 20px;
    }
    &.ant-select-single {
      height: 20px;
      font-size: 12px;
      font-weight: 500;
    }
    &.ant-select-single .ant-select-selector {
      font-size: 12px;
      border-radius: 4px;
      color: ${props =>
        statusColorMap[props.value as keyof typeof statusColorMap]?.fontColor ||
        '#8D8D99'};
    }
    &.ant-select-single.ant-select-open .ant-select-selection-item {
      color: ${props =>
        statusColorMap[props.value as keyof typeof statusColorMap]?.fontColor ||
        '#8D8D99'};
    }
    &.ant-select .ant-select-selection-item {
      font-weight: 500;
    }
    &.ant-select-dropdown .ant-select-item-option-content {
      justify-content: center;
    }
    &.ant-select-single .ant-select-selector .ant-select-selection-item {
      transition: none;
    }
  }
`

interface FeedbackStatusSelectProps {
  value: AgentFeedbackStatusEn
  onChange: (value: AgentFeedbackStatusEn) => void
}

export const FeedbackStatusSelect: React.FC<FeedbackStatusSelectProps> = ({
  value,
  onChange,
}) => {
  return (
    <StyledSelect
      value={value}
      onChange={onChange}
      suffixIcon={
        <IconFont
          name='arrow'
          className='text-7px mr--2px'
          style={{
            color: statusColorMap[value]?.fontColor || '#1677FF',
          }}
        />
      }
      variant='borderless'
      allowClear={false}
      showSearch={false}
      dropdownStyle={{
        width: '74px',
      }}
    >
      <Select.Option value={AgentFeedbackStatusEn.Processed}>
        已处理
      </Select.Option>
      <Select.Option value={AgentFeedbackStatusEn.Pending}>
        待处理
      </Select.Option>
      <Select.Option value={AgentFeedbackStatusEn.Ignored}>
        不处理
      </Select.Option>
    </StyledSelect>
  )
}
