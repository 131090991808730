import { useRequest } from 'ahooks'
import {
  Table,
  type TablePaginationConfig,
  type TableColumnsType,
  Tooltip,
} from 'antd'
import dayjs from 'dayjs'
import { memo, useMemo, useState } from 'react'
import { getFlowExecuteLog } from '@apis/flow'
import { getAppMonitorOvertimeDetail } from '@apis/monitor'
import { StaticTimeTypes } from '@apis/monitor/type'
import type { OvertimeRecordItem } from '@apis/monitor/type'
import type { LogItem } from '@apis/run/type'
import { LogModal } from '@/pages/agent/components/LogModal'
import {
  LoggerTitle,
  LoggerModal,
} from '@/features/logger/components/loggerModal'
import type { StatisticsFlowType } from '..'
import { useGoDraftPageWithInput } from '@/pages/flowPage/hooks/useGoDraftPageWithInput'
import { AgentRunTypeMap, FlowRunTypeMap } from '@/features/analyze/constants'
import type { StabilityTimeParams } from './StabilityDetails'

interface ErrorDetailTableProps {
  record: any
  flowType: StatisticsFlowType
  filterParams?: StabilityTimeParams
  runType: string[]
}

function InternalOverTimeDetailTable(props: ErrorDetailTableProps) {
  const { record, flowType, filterParams, runType } = props
  const { flow_id } = record

  const { goDraftPage } = useGoDraftPageWithInput()

  const [chatRecordsModalOpen, setChatRecordsModalOpen] = useState(false)

  const [flowLogModalOpen, setFlowLogModalOpen] = useState(false)

  const [recordId, setChatRecordId] = useState<string>()

  const { runAsync: fetchFlowLogDetail, data: logData } = useRequest(
    getFlowExecuteLog,
    { manual: true },
  )

  const runTypeMap = flowType === 'AGENT' ? AgentRunTypeMap : FlowRunTypeMap

  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: 10,
  })

  const { data, loading } = useRequest(
    async () => {
      if (
        filterParams?.type === StaticTimeTypes.CUSTOM &&
        !filterParams?.start_date &&
        !filterParams?.end_date
      ) {
        return { rows: [], totals: 0 }
      }
      return await getAppMonitorOvertimeDetail({
        flow_id,
        page: pagination.current || 1,
        page_size: pagination.pageSize || 10,
        statistic_time: filterParams?.type ?? StaticTimeTypes.WEEKLY,
        start_date: filterParams?.start_date,
        end_date: filterParams?.end_date,
        run_type: runType,
      })
    },
    {
      refreshDeps: [flow_id, filterParams, runType, pagination],
      ready: !!flow_id,
    },
  )

  const { dataSource, total } = useMemo(() => {
    return {
      total: data?.totals || 0,
      dataSource: data?.rows || [],
    }
  }, [data])

  const handleOvertimeItemEnter = async (value: {
    id: string | number
    overtime?: number
  }) => {
    if (flowType === 'AGENT') {
      setChatRecordId(value.id as string)
      setChatRecordsModalOpen(true)
    } else {
      await fetchFlowLogDetail({
        flowId: flow_id,
        taskId: value.id,
      })
      setFlowLogModalOpen(true)
    }
  }

  const columns: TableColumnsType<OvertimeRecordItem> = [
    {
      title: '异常记录时间',
      dataIndex: 'created_at',
      key: 'created_at',
      ellipsis: {
        showTitle: true,
      },
      render: (_, record) => {
        const time = dayjs(record.created_at).format('YYYY-MM-DD HH:mm:ss')
        return (
          <Tooltip placement='topLeft' title={time}>
            {time}
          </Tooltip>
        )
      },
    },
    {
      title: '响应时长',
      dataIndex: 'irl_time',
      key: 'irl_time',
      render: (_, { irl_time }) => `${irl_time}s`,
    },
    {
      title: '用户',
      dataIndex: 'user_name',
      key: 'user_name',
    },
    {
      title: '运行方式',
      dataIndex: 'run_type',
      key: 'run_type',
      ellipsis: true,
      render: (_, { run_type }) => {
        const label = runTypeMap[run_type] || run_type
        return (
          <Tooltip placement='topLeft' title={label}>
            {label}
          </Tooltip>
        )
      },
    },
    {
      title: '操作',
      key: 'action',
      render: (_, { biz_id, irl_time }) => {
        return (
          <div
            className='c-#7b61ff cursor-pointer'
            onClick={e => {
              e.stopPropagation()
              handleOvertimeItemEnter({ id: biz_id, overtime: irl_time })
            }}
          >
            查看详情
          </div>
        )
      },
    },
  ]

  return (
    <>
      <Table
        loading={loading}
        columns={columns}
        dataSource={dataSource}
        pagination={{ total, size: 'small' }}
        onChange={setPagination}
      />
      {chatRecordsModalOpen && (
        <LogModal
          recordId={recordId}
          position='fixed'
          onClose={() => {
            setChatRecordsModalOpen(false)
          }}
        />
      )}
      {flowLogModalOpen && logData && (
        <LoggerModal
          defaultTab='output'
          title={
            <LoggerTitle
              time={logData.start_time}
              status={logData.run_status}
            />
          }
          flowId={flow_id}
          taskId={logData.task_id}
          onClose={() => {
            setFlowLogModalOpen(false)
          }}
          onReRun={item => {
            goDraftPage(item as unknown as LogItem)
          }}
        />
      )}
    </>
  )
}

export const OverTimeDetailTable = memo(InternalOverTimeDetailTable)
