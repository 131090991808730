import { Tooltip } from 'antd'
import type { MessageItem } from '@bty/chat-ui'
import type { ReactNode } from 'react'
import React, { useMemo, useState } from 'react'
import classNames from 'classnames'
import type { Bot, ConversationData } from '@bty/chat-types'
import { IconFont, Badge } from '@/components'
import { useChatStore } from '@/store/chat.ts'
import { TaskChatIcon } from '@/features/agent/Task/TaskChatIcon'
import { ShareChat } from './ShareChat'

export interface ChatHeaderProps {
  bot: Bot
  currentConversation?: ConversationData
  typingTitle: string
  typingFinished: boolean
  collapsed: {
    bot: boolean
    history: boolean
    task: boolean
  }
  // todo: 💩
  prefixButton?: React.ReactNode
  canShare?: boolean
  isLogin?: boolean
  showDetailIcon?: boolean
  showTask?: boolean
  extraButton?: ReactNode
  onCollapse: (type: 'bot' | 'history' | 'task', collapsed: boolean) => void
  onConversationCreate: VoidFunction
  getMessageList?: () => MessageItem[]
}

export default function ChatHeader({
  bot,
  currentConversation,
  collapsed,
  prefixButton,
  canShare,
  isLogin,
  typingTitle,
  typingFinished,
  showDetailIcon = true,
  showTask,
  extraButton,
  onCollapse,
  // onConversationCreate,
  getMessageList,
}: ChatHeaderProps) {
  const { unreadMessageCountMap } = useChatStore()
  const [showModal, setShowModal] = useState(false)

  const currentBotUnreadMessageCount = currentConversation
    ? (unreadMessageCountMap.robot[currentConversation.robot_id] ?? 0)
    : 0
  const handleHistoryListCollapse = () => {
    onCollapse('history', !collapsed.history)
  }

  const handleBotDetailCollapse = () => {
    onCollapse('bot', !collapsed.bot)
  }

  const handleTaskCollapse = () => {
    onCollapse('task', !collapsed.task)
  }

  const displayTitle = useMemo(
    () =>
      typingFinished ? (currentConversation?.title ?? '新会话 1') : typingTitle,
    [typingFinished, typingTitle, currentConversation],
  )

  return (
    <header className='flex items-center h-64px px-16 bg-white'>
      <div className='w-150px flex gap-8px'>
        {collapsed.history && isLogin ? (
          <>
            {prefixButton}
            <button
              className='chat-icon-btn relative'
              onClick={handleHistoryListCollapse}
            >
              <IconFont
                className='color-[#17171D]  text-16px'
                name='zhankaichouti'
              />
              {!!currentBotUnreadMessageCount && (
                <span className='b-2 b-white rounded-full flex-center absolute top--4px right--8px'>
                  <Badge
                    count={currentBotUnreadMessageCount}
                    color='#ff5219'
                    showZero={false}
                  />
                </span>
              )}
            </button>
          </>
        ) : undefined}
      </div>
      <div className='mx-auto flex gap-8'>
        {/* <Avatar size={32} icon={getAvatarFromBot(bot)} color={bot.color} /> */}
        <div className='flex flex-col justify-between'>
          {/* <span className='text-12px/16px text-font_1'>{bot.name}</span> */}
          <span
            className={classNames('text-16px/20px text-font font-semibold', {
              'title-typing': !typingFinished,
            })}
          >
            {displayTitle}
          </span>
        </div>
      </div>
      <div className='w-140px justify-end flex flex-items-center'>
        {extraButton}
        {showTask && currentConversation?.conversation_id && (
          <TaskChatIcon
            agentId={currentConversation.robot_id}
            conversationId={currentConversation.conversation_id}
            open={!collapsed.task}
            onClick={handleTaskCollapse}
          />
        )}
        {canShare && !showModal && currentConversation?.conversation_id && (
          <Tooltip title='分享会话内容'>
            <button
              className='chat-icon-btn mr-8px'
              onClick={
                () =>
                  setShowModal(
                    true,
                  ) /* getSnapshotId().then(() => setShowModal(true)) */
              }
            >
              <IconFont
                className='color-[#17171D]  text-16px cursor-pointer'
                name='fenxiang'
              />
            </button>
          </Tooltip>
        )}
        {showDetailIcon && (
          <Tooltip title='Agent 详情'>
            <button
              className={classNames('chat-icon-btn', {
                'bg-bg_3 bg-op-8': !collapsed.bot,
              })}
              onClick={handleBotDetailCollapse}
            >
              <IconFont
                className='color-[#17171D]  text-16px'
                name='botxiangqing'
              />
            </button>
          </Tooltip>
        )}
      </div>
      <ShareChat
        open={showModal}
        onCancel={() => setShowModal(false)}
        currentConversation={currentConversation}
        // snapshotId={snapshotId}
        bot={bot}
        getMessageList={() => getMessageList?.() || []}
      />
    </header>
  )
}

export function ChatOperation({
  showTask,
  currentConversation,
  collapsed,
  canShare,
  showDetailIcon,
  getMessageList,
  onCollapse,
  bot,
}: Pick<
  ChatHeaderProps,
  | 'showTask'
  | 'currentConversation'
  | 'collapsed'
  | 'canShare'
  | 'showDetailIcon'
  | 'getMessageList'
  | 'onCollapse'
  | 'bot'
>) {
  const [showModal, setShowModal] = useState(false)

  const handleTaskCollapse = () => {
    onCollapse('task', !collapsed.task)
  }
  const handleBotDetailCollapse = () => {
    onCollapse('bot', !collapsed.bot)
  }

  return (
    <div className='w-140px justify-end flex flex-items-center'>
      {showTask && currentConversation?.conversation_id && (
        <TaskChatIcon
          agentId={currentConversation.robot_id}
          conversationId={currentConversation.conversation_id}
          open={!collapsed.task}
          onClick={handleTaskCollapse}
        />
      )}
      {canShare && !showModal && currentConversation?.conversation_id && (
        <Tooltip title='分享会话内容'>
          <button
            className='chat-icon-btn mr-8px'
            onClick={
              () =>
                setShowModal(
                  true,
                ) /* getSnapshotId().then(() => setShowModal(true)) */
            }
          >
            <IconFont
              className='color-[#17171D]  text-16px cursor-pointer'
              name='fenxiang'
            />
          </button>
        </Tooltip>
      )}
      {showDetailIcon && (
        <Tooltip title='Agent 详情'>
          <button
            className={classNames('chat-icon-btn', {
              'bg-bg_3 bg-op-8': !collapsed.bot,
            })}
            onClick={handleBotDetailCollapse}
          >
            <IconFont
              className='color-[#17171D]  text-16px'
              name='botxiangqing'
            />
          </button>
        </Tooltip>
      )}

      <ShareChat
        open={showModal}
        onCancel={() => setShowModal(false)}
        currentConversation={currentConversation}
        // snapshotId={snapshotId}
        bot={bot}
        getMessageList={() => getMessageList?.() || []}
      />
    </div>
  )
}
