import { Access, useAuth } from '@bty/react-auth'
import { useRequest, useTitle } from 'ahooks'
import { Dropdown, Modal, Switch, message } from 'antd'
import classNames from 'classnames'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteApplication, forkApplication } from '@apis/application'
import type { ApplicationBodyType } from '@apis/application/type'
import { RoleCode } from '@apis/authority/type'
import { ApplyType } from '@apis/notification/type'
import type { nodeConfigType } from '@apis/run/type'
import { enableFlow } from '@apis/run'
import {
  Button,
  DetailComponent,
  ForkTaskLoading,
  IconFont,
} from '@/components'
import { PRODUCT_TITLE } from '@/constants/common'
import { useForkTaskCheck } from '@/hooks/useForkTaskCheck'
import { useWorkspaceSelectModal } from '@/pages/application/components/WorkSpaceSelect.tsx'
import type { AppDetailInstance } from '@/pages/application/components/appDetail/utils'
import { useApplicationStore, useWorkspaceStore } from '@/store'
import { InvitePopover } from '../workspace/settings/components/InvitePopover'
import { getRoleOptions } from '../workspace/settings/util'
import { AgentNotAllowed } from '../agent/components/AgentNotAllowed'
import useShareAgentAuthentic from '@/hooks/useShareAgentAuthentic'
import { status } from './utils'
import type { tabsType } from './constant'

export default () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { access } = useAuth()
  const { authentic } = useShareAgentAuthentic({
    bot: { app_id: id },
  })
  const setCurrentApplication = useApplicationStore(
    state => state.setCurrentApplication,
  )
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const ref = useRef<
    | (AppDetailInstance & {
        associatedCount?: number
      })
    | null
  >(null)
  const [data, setData] = useState<{
    app: ApplicationBodyType
    firstNodeConfig: nodeConfigType
  }>()
  const { runAsync: handleEnableFlow } = useRequest(
    (enable: boolean) => enableFlow(data?.app?.flowId || '', enable),
    { manual: true },
  )
  const currentStatus = useMemo(() => {
    return status?.[data?.app?.isEnable ? 1 : 0]
  }, [data?.app?.isEnable])

  useEffect(() => {
    if (id) {
      setCurrentApplication(id)
    }
  }, [id])

  useEffect(() => {
    return () => localStorage.setItem('noRedirect', 'false')
  }, [])

  let tabs: tabsType[] = ['singleRun', 'batchRun', 'timingRun', 'Webhook']
  if (access.role([RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER])) {
    tabs = tabs.concat(['log', 'analyze', 'API'])
  }

  useTitle(data?.app?.name ? `${data?.app?.name}` : PRODUCT_TITLE, {
    restoreOnUnmount: true,
  })

  const forkTaskLoading = useForkTaskCheck('forkTaskId')

  const { runAsync: forkApplicationApi } = useRequest(forkApplication, {
    manual: true,
  })
  const handleFork = useCallback(
    async (workspaceId: string) => {
      const res = await forkApplicationApi({
        source_appid: id as string,
        source_workspace_id: currentWorkspaceId,
        target_workspace_id: workspaceId,
      })
      window.open(
        `/application/${res.application_id}?forkTaskId=${res.task_id}`,
        '_blank',
      )
      onForkSuccess()
    },
    [currentWorkspaceId, id],
  )

  const [workspaceSelectModal] = useWorkspaceSelectModal({
    onConfirm: handleFork,
    defaultWorkspaceId: currentWorkspaceId,
    copyType: '复制工作流',
    appId: id,
    showConnectComponent: true,
    checkLimit: 'app',
  })

  function onForkSuccess() {
    workspaceSelectModal.close()
  }

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (authentic === false) setOpen(true)
  }, [authentic])

  return (
    <div className='h-full flex' style={open ? { pointerEvents: 'none' } : {}}>
      <div className='relative bg-bg rounded-12px flex-1 overflow-hidden flex flex-col'>
        {forkTaskLoading && <ForkTaskLoading text='App资源拷贝中，请稍等' />}
        <div className='flex-1 flex flex-col overflow-hidden'>
          <DetailComponent
            canEditInfo
            getAppDataOnSuccess={setData}
            id={id || ''}
            ref={ref}
            tabs={tabs}
          >
            {data?.app?.flowId && (
              <div className='flex items-center gap-12px'>
                <span
                  className={classNames(
                    'color-#8D8D99 border-rd-4px text-12px px-8px h-20px text-center line-height-20px font-500',
                    {
                      'bg-green c-green bg-op-12':
                        data?.app?.versionStatus === 'PUBLISHED',
                      'bg-bg_3 c-font_1 bg-op-12':
                        data?.app?.versionStatus !== 'PUBLISHED',
                    },
                  )}
                >
                  {data?.app?.applicationVersion > 0 ? '已发布' : '未发布'}
                </span>
                <div className='w-1px bg-#E1E1E5 h-20px mx-8px'></div>
                <InvitePopover
                  onClose={() => ref.current?.refreshAppInfo()}
                  title='分享'
                  flowId={data?.app?.flowId}
                  refreshAppInfo={() => ref.current?.refreshAppInfo()}
                  workspaceId={currentWorkspaceId}
                  roleOptions={getRoleOptions(false)}
                  isBot={data?.app?.isBot}
                  applicationId={id}
                  isPublic={data?.app.isPublic}
                >
                  <Button
                    style={{ height: 36 }}
                    icon={
                      <span role='img' className='anticon anticon-edit'>
                        <IconFont name='fenxiang' />
                      </span>
                    }
                  >
                    分享
                  </Button>
                </InvitePopover>
                <Access
                  access={access.role([
                    RoleCode.DEVELOPER,
                    RoleCode.ADMINISTRATOR,
                  ])}
                  hide
                >
                  <Button
                    type='primary'
                    style={{ height: 36 }}
                    icon={<IconFont name='edit-app'></IconFont>}
                    onClick={() =>
                      navigate(
                        `/application/${currentWorkspaceId}/${data.app.id}/flow/${data.app.flowId}`,
                      )
                    }
                  >
                    编辑
                  </Button>
                </Access>
                <Dropdown
                  trigger={['click']}
                  menu={{
                    items: [
                      ...(access.role([
                        RoleCode.ADMINISTRATOR,
                        RoleCode.DEVELOPER,
                      ])
                        ? [
                            {
                              style: { padding: '0' },
                              label: (
                                <div
                                  onClick={e => e.stopPropagation()}
                                  className='flex flex-items-center gap-60px pl-12px py-10px justify-between'
                                >
                                  {currentStatus?.text}
                                  <Switch
                                    checked={data?.app?.isEnable}
                                    className='m-r-12px'
                                    size='small'
                                    onChange={e =>
                                      handleEnableFlow(e).then(() =>
                                        ref.current?.refreshAppInfo(),
                                      )
                                    }
                                  />
                                </div>
                              ),
                              key: '1',
                            },
                          ]
                        : []),
                      {
                        label: '复制',
                        style: { height: 32, padding: '0 28px 0 10px' },
                        key: '3',
                        onClick: ({ domEvent }) => {
                          domEvent.stopPropagation()
                          workspaceSelectModal.open()
                        },
                      },
                      {
                        label: '删除',
                        key: '2',
                        disabled: !!ref?.current?.associatedCount,
                        style: {
                          color: '#FF5219',
                          height: 32,
                          padding: '0 28px 0 10px',
                          opacity: ref?.current?.associatedCount ? 0.4 : 1,
                        },
                        onClick: ({ domEvent }) => {
                          domEvent.stopPropagation()
                          Modal.confirm({
                            icon: <></>,
                            content: '请确认是否要删除应用？',
                            cancelText: '取消',
                            okText: '确定',
                            okButtonProps: { danger: true },
                            onOk: () => {
                              id &&
                                deleteApplication(id).then(() => {
                                  message.success('删除成功！')
                                  navigate('/application')
                                })
                            },
                          })
                        },
                      },
                    ],
                  }}
                >
                  <Button
                    style={{ height: 36 }}
                    onClick={e => e.stopPropagation()}
                    className='important:p-0 !w-36px'
                    icon={<IconFont name='gengduo' className='c-font_1' />}
                  ></Button>
                </Dropdown>
              </div>
            )}
          </DetailComponent>
        </div>
      </div>
      <Modal open={open} closable={false} footer={null} width={600}>
        <div className='py-16px'>
          <AgentNotAllowed
            showLogo={false}
            type={ApplyType.DEVELOPER}
            agentId={id}
            roleCode={RoleCode.DEVELOPER}
          />
        </div>
      </Modal>
    </div>
  )
}
