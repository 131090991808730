import { memo, useMemo, useState } from 'react'
import type { TreeDataNode, TreeProps } from 'antd'
import { Spin } from 'antd'
import { useDebounce, useRequest } from 'ahooks'
import classNames from 'classnames'
import { labels } from '@apis/resources/type'
import type { CodeExampleType, labelKeys } from '@apis/resources/type'
import { getCodeExample, getIntegrationResources } from '@apis/resources'
import { useWorkspaceStore } from '@/store'
import { Input } from '@/components/input'
import { IconFont } from '@/components'
import {
  CodeExampleSegmented,
  CodeExampleTabs,
  StyledTree,
} from '../StyledComponent'
import { OverflowContent } from '../OverflowContent'
import { EmptyContent } from '../EmptyContent'
import { TreeTitleRender } from './TreeTitleRender'

type HeaderActiveKeyType = 'EXAMPLE' | 'RESOURCE'

interface CodeExampleContentProps {
  language: 'javascript' | 'python'
  flowId: string
  trackMetaData: object
  leftBarWidth?: number
}

const DEFAULT_CODE_EXAMPLE_LIST = {
  javascript: {
    SCENARIO_EXAMPLE: [],
    BUILDIN_FEATURES: [],
  },
  python: {
    SCENARIO_EXAMPLE: [],
    BUILDIN_FEATURES: [],
  },
}

function CodeExampleContent(props: CodeExampleContentProps) {
  const { language, trackMetaData, leftBarWidth, flowId } = props

  const [headerActiveKey, setHeaderActiveKey] =
    useState<HeaderActiveKeyType>('EXAMPLE')

  const [codeExampleActiveKey, setCodeExampleActiveKey] =
    useState<labelKeys>('SCENARIO_EXAMPLE')

  const [searchValue, setSearchValue] = useState('')
  const debouncedSearchValue = useDebounce(searchValue, { wait: 300 })

  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([])

  const handleTabChange = (key: string) => {
    setCodeExampleActiveKey(key as labelKeys)
  }

  const workspaceId = useWorkspaceStore(state => state.currentWorkspaceId)

  const { data: allResources, loading: getIntegrationResourcesLoading } =
    useRequest(getIntegrationResources, {
      refreshDeps: [workspaceId],
      defaultParams: [flowId],
    })

  const filename = useMemo(() => {
    let envMark: string
    switch (import.meta.env.NODE_ENV) {
      case 'pre':
        envMark = '-pre'
        break
      case 'production':
        envMark = ''
        break
      default:
        envMark = '-development'
        break
    }
    return `code-example${envMark}`
  }, [])

  const { data: CodeExampleList = DEFAULT_CODE_EXAMPLE_LIST } = useRequest(() =>
    getCodeExample(filename),
  )

  const handleSegmentedChange = (value: HeaderActiveKeyType) => {
    setHeaderActiveKey(value)
    // 顶部 segment 切换时，重置面板的状态
    setCodeExampleActiveKey('SCENARIO_EXAMPLE')
    setExpandedKeys([])
    setSearchValue('')
  }

  const onSelect: TreeProps['onSelect'] = (_, info) => {
    if (!info.node.children) {
      return
    }
    setExpandedKeys(prev => {
      return prev.includes(info.node.key)
        ? prev.filter(key => key !== info.node.key)
        : [...prev, info.node.key]
    })
  }

  const treeData = useMemo<TreeDataNode[]>(() => {
    if (headerActiveKey === 'EXAMPLE') {
      const exampleData = CodeExampleList[language][codeExampleActiveKey] ?? []
      const transformExampleData = (
        data: CodeExampleType[],
        level = 0,
      ): TreeDataNode[] => {
        return data.map(item => {
          if (item.children) {
            return {
              title: item.title,
              key: item.title,
              children: transformExampleData(item.children, level + 1),
            }
          } else {
            return {
              title: item.title,
              key: item.title,
              code: item.code,
              type: 'EXAMPLE',
              level,
              language,
            }
          }
        })
      }
      return transformExampleData(exampleData)
    }
    if (headerActiveKey === 'RESOURCE') {
      const { database, knowledge, plugin_template } = allResources || {}
      const dbList = {
        title: '数据库',
        key: 'db',
        children: (database?.list || [])
          .filter(item =>
            item.title
              .toLocaleUpperCase()
              .includes(searchValue?.toLocaleUpperCase()),
          )
          .map(item => ({
            language,
            title: item.title,
            key: item.id,
            value: item.id,
            copyable: true,
            type: 'DATASTORE',
            tableList: item.tables.map(table => ({
              name: table.title,
              value: table.title,
            })),
          })),
      }
      const datastoreList = {
        title: '知识库',
        key: 'knowledge',
        children: (knowledge || [])
          .filter(item =>
            item.partition_name
              .toLocaleUpperCase()
              .includes(searchValue?.toLocaleUpperCase()),
          )
          .map(item => ({
            language,
            title: item.partition_name,
            key: item.partition_id,
            value: item.partition_id,
            copyable: true,
            type: 'DATASTORE',
            tags: item.tags,
            fileList: item.file_info.map(file => ({
              name: file.file_name,
              value: file.file_id,
            })),
          })),
      }
      const subFlowList = {
        title: '工作流',
        key: 'sub-flow',
        children: (plugin_template ?? [])
          .filter(subFlow =>
            subFlow.flow_name
              .toLocaleUpperCase()
              .includes(searchValue?.toLocaleUpperCase()),
          )
          .map(subFlow => ({
            language,
            title: subFlow.flow_name,
            key: subFlow.flow_id,
            value: subFlow.flow_id,
            params: subFlow.form_config,
            type: 'DATASTORE',
            copyable: true,
          })),
      }
      setExpandedKeys(['db', 'knowledge', 'sub-flow'])
      return [datastoreList, dbList, subFlowList].filter(
        list => list.children.length > 0,
      )
    }
    return []
  }, [
    headerActiveKey,
    allResources,
    codeExampleActiveKey,
    CodeExampleList,
    debouncedSearchValue,
  ])

  const notFoundSearchResult = useMemo(() => {
    return (
      headerActiveKey === 'RESOURCE' &&
      debouncedSearchValue &&
      treeData.length === 0
    )
  }, [headerActiveKey, debouncedSearchValue, treeData])

  const showLoading = useMemo(() => {
    if (headerActiveKey === 'RESOURCE') {
      return getIntegrationResourcesLoading
    }
    return false
  }, [headerActiveKey, getIntegrationResourcesLoading])

  const handleSearchInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target
    setSearchValue(value)
  }

  return (
    <>
      <div className='px-16px py-10px'>
        <CodeExampleSegmented
          block
          size='small'
          options={[
            { label: '代码示例', value: 'EXAMPLE' },
            { label: '资源', value: 'RESOURCE' },
          ]}
          value={headerActiveKey}
          onChange={value =>
            handleSegmentedChange(value as HeaderActiveKeyType)
          }
        />
      </div>
      {headerActiveKey === 'EXAMPLE' && (
        <CodeExampleTabs
          activeKey={codeExampleActiveKey}
          items={labels.map(label => ({ label: label.label, key: label.key }))}
          onChange={handleTabChange}
        />
      )}
      {headerActiveKey === 'RESOURCE' && (
        <div
          className='py-12px px-16px'
          style={{ borderTop: '1px solid rgba(225, 225, 229, 0.6)' }}
        >
          <Input
            prefix={<IconFont name='search' className='text-16px' />}
            placeholder='搜索资源'
            allowClear
            value={searchValue}
            onChange={handleSearchInputChange}
          />
        </div>
      )}
      <OverflowContent
        occupiedHeight='118'
        className={classNames('px-8px', {
          // 无搜索项时，居中显示占位图
          'flex items-center justify-center':
            (headerActiveKey === 'RESOURCE' && notFoundSearchResult) ||
            showLoading,
        })}
      >
        <Spin spinning={showLoading}>
          {notFoundSearchResult ? (
            <EmptyContent emptyText='无搜索结果' />
          ) : (
            <StyledTree
              blockNode
              autoExpandParent={true}
              expandedKeys={expandedKeys}
              // @ts-expect-error treeData is not assignable to type TreeDataNode[]
              onSelect={onSelect}
              treeData={treeData}
              // TODO: 需要优化下 any 类型
              titleRender={(node: any) => {
                return (
                  <TreeTitleRender
                    title={node?.title}
                    copyable={node?.copyable}
                    tags={node?.tags}
                    value={node?.value}
                    fileList={node?.fileList}
                    params={node?.params}
                    type={node?.type}
                    code={node?.code}
                    language={node?.language}
                    tableList={node?.tableList}
                    level={node.level}
                    trackMetaData={trackMetaData}
                    searchValue={searchValue}
                    leftBarWidth={leftBarWidth}
                  />
                )
              }}
            />
          )}
        </Spin>
      </OverflowContent>
    </>
  )
}

export const CodeExample = memo(CodeExampleContent)
