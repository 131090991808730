import { isNil } from 'lodash-es'
import { SliderSingle } from '@/components'
import type { KnowledgeDataValue } from '../../KnowledgeNode'

export default (props: {
  value?: KnowledgeDataValue['similarity']
  onChange?: (values: KnowledgeDataValue['similarity']) => void
}) => {
  const { value, onChange } = props
  return (
    <div className='flex flex-col justify-between'>
      <SliderSingle
        size='small'
        min={0}
        max={1}
        step={0.01}
        maxLength={4}
        controls={false}
        value={
          // 如果老数据中没有similarity字段，则默认值为0.4
          isNil(value) ? 0.4 : value
        }
        onChange={e => onChange?.(Number(e))}
      />
    </div>
  )
}
