import styled from '@emotion/styled'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useState } from 'react'
import type { ApplicationBodyType } from '@apis/application/type'
import { editTimeText } from '@/pages/application/utils'
import { AppLogo, IconFont } from '@/components'

const RenderItemWrapper = styled.div<{ open: boolean }>`
  cursor: grab;
  width: 100%;
  .imgBorder {
    box-sizing: border-box;
    background: linear-gradient(
      to bottom,
      rgba(0, 216, 216, 0.6),
      rgba(109, 80, 255, 0.6)
    );
    border-radius: 50%;
    img {
      border: 3px solid #fff;
    }
  }
  .messageWrapper {
    display: flex;
  }
  .buttonWrapper {
    visibility: ${({ open }) => (open ? 'visible' : 'hidden')};
    display: flex;
    button {
      border: none;
      box-shadow: none;
      font-size: 20px !important;
      svg {
        color: #17171d !important;
      }
    }
  }
  &:hover {
    .content {
      box-shadow: 0px 12px 20px 0px rgba(23, 26, 29, 0.12);
      .buttonWrapper {
        visibility: visible;
      }
    }
  }
`

function Ability({ icon, num }: { icon: string; num: number }) {
  return (
    <div className='text-12px c-font_1 flex'>
      <IconFont name={icon} />
      <span className='px-4px'>{num}</span>
    </div>
  )
}

export function AgentItem(
  props: Partial<ApplicationBodyType> & {
    onClick?: () => void
    buttonWrapper?: (e: { setOpen: (e: boolean) => void }) => JSX.Element
    className?: string
    data: ApplicationBodyType
  } & React.HTMLAttributes<HTMLDivElement>,
) {
  const {
    onClick,
    className,
    buttonWrapper: CombuttonWrapper,
    data,
    ...otherProps
  } = props

  const [open, setOpen] = useState(false)

  return (
    <RenderItemWrapper
      {...otherProps}
      open={open}
      onClick={() => onClick?.()}
      className={classNames(
        'relative h-212px flex flex-col justify-end border-rd-12px',
        className,
      )}
    >
      <div className='cursor-grab content border-rd-12px p-20 h-168px bg-#fff flex flex-col justify-between'>
        <div
          style={{
            pointerEvents: 'none',
            left: '50%',
            top: -0,
            transform: 'translate(-50%, 0)',
          }}
          className='absolute w-88px h-88px flex-center border-rd-50% overflow-hidden p-1px bg-#fff imgBorder'
        >
          <AppLogo
            type='image'
            value={data.icon ?? ''}
            color={data.color}
            size={86}
          />
        </div>
        <div
          onClick={e => {
            e.stopPropagation()
            e.preventDefault()
          }}
          className='buttonWrapper w-100% flex justify-end h-32px'
        >
          {CombuttonWrapper && <CombuttonWrapper setOpen={setOpen} />}
        </div>
        <div className='flex flex-col justify-between items-center ml-8px my-4px'>
          <div
            title={data.name}
            className='truncate text-16px font-600 line-height-18px mb-12px w-100% text-center'
          >
            {data.name}
          </div>
          <div className='messageWrapper flex justify-between flex-items-center mb-32px c-#8d8d99 c-op-60'>
            <span
              title={`${data.modifyBy}更新于${editTimeText(
                dayjs(data.updateAt),
              )}`}
              className='truncate flex-1 text-12px flex flex-items-center'
            >
              <span className='truncate inline-block p-r-3px'>
                {data.modifyBy}
              </span>
              <span>更新于{editTimeText(dayjs(data.updateAt))}</span>
            </span>
          </div>
        </div>
        <div className='flex gap-16px justify-center'>
          <Ability
            icon='zhishi'
            num={data.skillStatistics?.knowledge_files || 0}
          />
          <Ability icon='zhajian-1' num={data.skillStatistics?.plugins || 0} />
          <Ability icon='flow-1' num={data.skillStatistics?.flows || 0} />
          <Ability
            icon='data-1'
            num={data.skillStatistics?.database_tables || 0}
          />
        </div>
      </div>
    </RenderItemWrapper>
  )
}
