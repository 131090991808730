import { Form, message } from 'antd'
import styled from '@emotion/styled'
import { useRequest } from 'ahooks'
import { addAgentGoRecord } from '@apis/noco'
import { Input, FormItem } from '@/components'
import PlatformSelect from '@/pages/agentGo/components/PlatformSelect.tsx'
import { useUserStore } from '@/store'
import ClickStyle from '@/assets/agent-go/click.png'
import Next from '@/assets/agent-go/next.png'

const GoButton = styled.div`
  &:active {
    transform: scale(0.95);
  }
  &:hover {
    background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0.2),
        rgba(255, 255, 255, 0.2)
      ),
      #7b61ff;
    &:before {
      display: none;
    }
  }
  background: linear-gradient(0deg, #7b61ff, #7b61ff),
    linear-gradient(
      270deg,
      rgba(62, 200, 255, 0.12) 0%,
      rgba(123, 97, 255, 0.12) 100%
    );
  &:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    width: 90px;
    height: 90px;
    border-radius: 8px;
    opacity: 1;
    background: rgba(123, 97, 255, 0.5);
    filter: blur(30px);
  }
`

function Label(props: { tagText: string; text: string }) {
  const { tagText, text } = props
  return (
    <div className='title flex items-center'>
      <span className='inline-block line-height-18px py-2 px-6 font-600 c-white bg-primary rounded-6px rounded-rb-14px'>
        {tagText}
      </span>
      <div className='font-600 ml-18 text-18px'>{text}</div>
    </div>
  )
}

export default function ResearchForm(props: { onSubmitFinish: () => void }) {
  const user = useUserStore(state => state.user)
  const [form] = Form.useForm<{
    platforms: string[]
    scene: string
    role: string
  }>()

  const { runAsync: onAgentGoRecordAdd, loading } = useRequest(
    addAgentGoRecord,
    {
      manual: true,
    },
  )

  const onSubmit = async () => {
    if (!user) {
      message.error('用户信息获取失败，请刷新重试')
      return
    }
    const value = await form.validateFields()
    if (!value.platforms.length) {
      message.warning('至少选择一个平台')
      return
    }
    if (!loading) {
      await onAgentGoRecordAdd({
        userId: user.userId,
        username: user.username,
        role: value.role,
        scene: value.scene,
        platforms: value.platforms.join(','),
      })
      props.onSubmitFinish()
    }
  }

  return (
    <Form
      initialValues={{
        platforms: [],
        role: '',
        scene: '',
      }}
      form={form}
      requiredMark={false}
      layout='vertical'
      className='relative'
    >
      <div className='px-40 pt-26 pb-30 bg-white rounded-12px w-full'>
        <Label tagText='STEP1' text='请选择您要使用Agent的平台(多选)' />
        <FormItem name='platforms' className='mb-0 mt-20'>
          <PlatformSelect />
        </FormItem>
      </div>
      <img src={Next} alt='' className='w-42px absolute right--42px mt--90px' />
      <div className='px-40 pt-26 pb-30 bg-white rounded-12px w-full mt-40'>
        <Label tagText='STEP2' text='请描述使用场景' />
        <div className='flex mt-20 gap-20'>
          <FormItem
            label='使用场景'
            required
            name='scene'
            className='mb-0 flex-1'
            rules={[{ required: true, message: '请填写使用场景' }]}
          >
            <Input placeholder='请您的使用场景（比如：客服场景、HR招聘场景）' />
          </FormItem>
          <FormItem
            label='使用角色'
            required
            name='role'
            className='mb-0 flex-1'
            rules={[{ required: true, message: '请填写使用角色' }]}
          >
            <Input placeholder='请填写使用角色（比如：客服、企业管理员）' />
          </FormItem>
        </div>
      </div>
      <div className='flex-center mt-60 relative'>
        <img
          src={ClickStyle}
          alt=''
          className='w-281px absolute ml--100px mt--60px'
        />
        <GoButton
          onClick={onSubmit}
          className='transition-transform-200 ease-in-out select-none cursor-pointer relative w-90px h-90px rounded-full flex-center font-400 c-white text-24px'
        >
          <span className='z-2'>GO</span>
        </GoButton>
      </div>
    </Form>
  )
}
