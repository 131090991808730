export enum RoleCode {
  ADMINISTRATOR = 'Administrator',
  DEVELOPER = 'Developer',
  VIEWER = 'Viewer',
}

export const RoleName: Record<RoleCode, string> = {
  [RoleCode.ADMINISTRATOR]: '管理员',
  [RoleCode.DEVELOPER]: '开发者',
  [RoleCode.VIEWER]: '使用者',
}

export const RoleWeight: Record<RoleCode, number> = {
  [RoleCode.ADMINISTRATOR]: 3,
  [RoleCode.DEVELOPER]: 2,
  [RoleCode.VIEWER]: 1,
}

export enum AppJoined {
  JOINED = 1,
  NOT_JOINED = 0,
}

// 字符串组合枚举
export type Combination<A extends string, B extends string> =
  | A
  | B
  | `${A},${B}`
  | `${B},${A}`

export type AllCombinations<
  A extends string,
  B extends string = A,
> = A extends A ? Combination<A, AllCombinations<Exclude<B, A>>> : never

export type ApplicationType = AllCombinations<
  'AI' | 'WEB' | 'AGENT' | 'DATASET'
>

export interface WorkspaceUsersRequest {
  workspaceId: string
  applicationId?: string
  applicationType?: ApplicationType
}

export interface UserItem {
  activatedStatus: AppJoined
  permissionGroupId: string
  permissionGroupName: string
  phone: string
  roleCode: RoleCode
  userId: number | string
  username: string
  avatar?: string
  applications?: any[]
  isAuthAllApp?: boolean
}

export interface RoleBodyRequest {
  workspaceId: string
  userId: string | number
  roleCode: RoleCode
  applicationType: ApplicationType
  sourceRoleCode: RoleCode
  phone: string
}

export interface RemoveUserRequest {
  permissionGroupId: string
  userId: string | number
  sourceRoleCode: RoleCode
}

export interface SearchUserItem {
  accountNonExpired: boolean
  accountNonLocked: boolean
  activatedStatus: AppJoined
  credentialsNonExpired: boolean
  enabled: boolean
  isAnonymous: boolean
  isEnabled: boolean
  name: string
  new: boolean
  phone: string
  source: string
  ucUserId: number | string
}

export interface InviteMembersRequestBody {
  phones: string[]
  role: RoleCode
  applicationIds: (number | string)[]
  applicationType: ApplicationType
  workspaceId: string
  url: string
}

export interface UpdatePermittedApplicationsRequestBody {
  workspaceId: string
  userId: string | number
  roleCode: RoleCode
  applicationIds: string[]
  applicationType?: ApplicationType
  is_auth_all_app?: boolean
}
