import { useRequest } from 'ahooks'
import { isBoolean } from 'lodash-es'
import { useState } from 'react'
import { searchData } from '@apis/datastore'
import type { HitItem } from '@apis/datastore/type'

export function useHitTest(partition_id: number, file_ids?: number[] | number) {
  const [list, setList] = useState<HitItem[]>([])
  const [costTime, setCostTime] = useState<number>()

  const { loading, runAsync, refresh } = useRequest(
    (
      text: string,
      topK: number,
      tags?: string[],
      params?: Record<string, any>,
    ) =>
      searchData({
        top_k: params?.top_k || topK,
        content: text,
        tags,
        partition_id,
        file_ids,
        ...(params || {}),
        ...(isBoolean(params?.ranking_strategy)
          ? { ranking_strategy: params?.ranking_strategy ? 1 : 2 }
          : {}),
      }),
    {
      manual: true,
      onSuccess: res => {
        setList(res?.match_contents ?? [])
        setCostTime(res?.cost_time ?? undefined)
      },
    },
  )

  return {
    list,
    updateList: setList,
    loading,
    onSearch: runAsync,
    refresh,
    costTime,
  }
}
