import styled from '@emotion/styled'
import { Tag } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { useEffect, useMemo, useState } from 'react'
import { useRequest } from 'ahooks'
import type { UserItem } from '@apis/authority/type'
import { AppJoined, RoleCode } from '@apis/authority/type'
import { getUserByPhone } from '@apis/user'
import { Select, IconFont } from '@/components'
import { JoinStatusEnum, UserOption, getName } from './UserOption'

const TagWrapper = styled(Tag)`
  background: rgba(98, 105, 153, 0.08) !important;
  margin: 0;
  display: flex;
  justify-items: center;
  align-items: center;
  gap: 8px;
`

const StyledSelect = styled(Select)`
  .ant-select-selector {
    padding: 3px 80px 3px 30px;
    padding-inline-end: 75px !important;
    height: auto !important;
  }

  .ant-select-selection-placeholder {
    left: 38px !important;
  }

  .ant-select-selection-overflow
    .ant-select-selection-overflow-item:first-of-type {
    .ant-tag {
      margin-left: 10px !important;
    }
  }

  .ant-select-selection-overflow {
    gap: 4px;
    max-height: 87px;
    overflow: auto;
    .ant-select-selection-search-mirror {
      overflow: hidden;
      height: 100%;
    }
  }

  .ant-select-selection-item,
  .ant-select-selection-overflow-item .ant-tag {
    height: 26px;
    line-height: 26px;
    background: #fff;
    border: none;
  }

  .ant-select-arrow {
    height: fit-content;
    pointer-events: all;
    font-size: 14px;
    color: #dcdce0;
    margin-top: -7px;
  }
`

export type usersItem = UserItem & { status?: JoinStatusEnum }

export default ({
  applicationId,
  users,
  setUsers,
  role,
  lastInviteNum,
  originUserList,
  selectedUsers,
  setSelectedUsers,
}: {
  applicationId?: string
  lastInviteNum: number
  role: RoleCode
  originUserList?: usersItem[]
  users: usersItem[]
  setUsers: (e: usersItem[]) => void
  selectedUsers: number[]
  setSelectedUsers: (e: number[]) => void
}) => {
  const [searchValue, setSearchValue] = useState('')
  const { runAsync: fetchGetUserByPhone, loading: getUserByPhoneLoading } =
    useRequest(getUserByPhone, { manual: true })
  const getJoinStatus = (item?: usersItem): JoinStatusEnum => {
    if (
      item?.status === JoinStatusEnum.otherWorkspace ||
      item?.status === JoinStatusEnum.nothing
    ) {
      return item.status
    }
    if (item?.activatedStatus === AppJoined.NOT_JOINED) {
      return JoinStatusEnum.noJoin
    }
    if (
      item?.roleCode === RoleCode.DEVELOPER ||
      item?.roleCode === RoleCode.ADMINISTRATOR
    ) {
      return applicationId ? JoinStatusEnum.canUse : JoinStatusEnum.joined
    }
    if (applicationId) {
      return item?.applications?.find(item => item?.id === applicationId)
        ? applicationId
          ? JoinStatusEnum.canUse
          : JoinStatusEnum.joined
        : JoinStatusEnum.noAppPermission
    }
    return item?.status || applicationId
      ? JoinStatusEnum.canUse
      : JoinStatusEnum.joined
  }

  const searchOptions = useMemo(() => {
    if (!searchValue) return []
    if (
      /^1[3456789]\d{9}$/.test(searchValue) &&
      !users.some(item => item.phone === searchValue)
    ) {
      return [
        {
          value: searchValue,
          label: (
            <UserOption
              activatedStatus={AppJoined.NOT_JOINED}
              searchValue={searchValue}
              phone={searchValue}
            />
          ),
        },
      ]
    }
    return users
      .filter(
        item =>
          (item.phone.includes(searchValue) ||
            item.username.includes(searchValue)) &&
          (item.roleCode === role || !item.roleCode),
      )
      .map(item => {
        const status = getJoinStatus(item)
        return {
          value: item.userId,
          status,
          label: (
            <UserOption
              activatedStatus={item.activatedStatus}
              name={item.username}
              phone={item.phone}
              avatar={item.avatar}
              searchValue={searchValue}
              status={status}
            />
          ),
        }
      })
      .sort((a, b) => a.status - b.status)
  }, [users, searchValue, role])

  const onChangeSearchValue = (e: string) => {
    setSearchValue(e)
    if (/^1[3456789]\d{9}$/.test(e) && !users.some(item => item.phone === e)) {
      fetchGetUserByPhone(e).then(res => {
        !users.find(item => item.phone === res.phone) &&
          setUsers([
            {
              username:
                res.activatedStatus === AppJoined.JOINED ? res.username : '',
              phone: res.phone,
              avatar: res.avatar,
              userId: res.userId,
              activatedStatus: res.activatedStatus,
              status:
                res.activatedStatus === AppJoined.JOINED
                  ? JoinStatusEnum.otherWorkspace
                  : JoinStatusEnum.nothing,
            } as usersItem,
            ...users,
          ])
      })
    } else {
      setUsers(
        users.filter(
          k =>
            !(
              k.status === JoinStatusEnum.otherWorkspace &&
              !selectedUsers.find(i => i === k.userId)
            ),
        ),
      )
    }
  }

  const onBlur = () => {
    const findItem = users.find(item => item.phone === searchValue)
    if (
      !/^1[3456789]\d{9}$/.test(searchValue as string) ||
      getUserByPhoneLoading ||
      (findItem?.status &&
        ![JoinStatusEnum.nothing, JoinStatusEnum.otherWorkspace].includes(
          findItem?.status,
        ))
    ) {
      return
    }
    const tag = findItem?.userId || searchValue
    const hasSelect = selectedUsers.includes(tag as unknown as number)
    if (!hasSelect) {
      setSelectedUsers([...selectedUsers, tag as unknown as number])
      setSearchValue('')
    }
  }

  useEffect(() => {
    lastInviteNum <= 0 && setSearchValue('')
  }, [lastInviteNum])

  return (
    <StyledSelect
      className='w-full'
      value={selectedUsers}
      mode='multiple'
      showSearch
      disabled={lastInviteNum <= 0}
      filterOption={false}
      suffixIcon={getUserByPhoneLoading ? <LoadingOutlined /> : null}
      options={searchOptions}
      placeholder='请输入手机号'
      searchValue={searchValue}
      popupClassName='invitePopupClassName'
      popupMatchSelectWidth={328}
      open={!!searchValue}
      listHeight={350}
      tagRender={({ value, closable, onClose }) => {
        const user = users.find(item => item.userId === value)
        return (
          <TagWrapper
            closable={closable}
            closeIcon={
              <IconFont name='guanbi' className='text-16px cursor-pointer' />
            }
            onClose={e => {
              onClose(e)
              !originUserList?.find(item => item.userId === user?.userId) &&
                setUsers(users.filter(item => item.userId !== user?.userId))
            }}
          >
            <span className='truncate text-14px font-500'>
              {getName(
                user?.username || user?.phone || value,
                getJoinStatus(user),
              )}
            </span>
          </TagWrapper>
        )
      }}
      onSearch={onChangeSearchValue}
      autoClearSearchValue={false}
      onBlur={onBlur}
      onInputKeyDown={e =>
        e.keyCode === 8 &&
        (e.target as HTMLInputElement).value.length === 0 &&
        e.stopPropagation()
      }
      onChange={e => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        setSelectedUsers(e)
        setSearchValue('')
      }}
    />
  )
}
