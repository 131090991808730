import React, { useState } from 'react'
import { Access, useAuth } from '@bty/react-auth'
import { Tooltip } from 'antd'
import { RoleCode } from '@apis/authority/type'
import { Input, Button, IconFont } from '@/components'

import { NewGroupModal } from '@/features/home/NewGroupModal'

interface PageHeaderProps extends React.PropsWithChildren {
  title: string
  tooltip?: string
  showCreate?: boolean
  renderCreate?: () => React.ReactNode
  showCreateGroup?: boolean
  renderCreateGroup?: () => React.ReactNode
  extraActions?: React.ReactNode
  searchKey?: string
  onSearch: (searchKey?: string) => void
  onGroupCreate: (title: string) => Promise<void>
}

export function PageHeader({
  children,
  title,
  tooltip,
  showCreate,
  renderCreate,
  showCreateGroup,
  renderCreateGroup,
  extraActions,
  searchKey,
  onSearch,
  onGroupCreate,
}: PageHeaderProps) {
  const { access } = useAuth()

  const innerShowCreate = !renderCreate && showCreate !== false

  const innerShowCreateGroup = !renderCreateGroup && showCreateGroup !== false

  const [groupModalOpen, setGroupModalOpen] = useState(false)

  return (
    <div className='adapt:px-64 flex justify-between pb-24 relative'>
      <div className='flex items-center h-36px flex-wrap'>
        <span className='font-semibold text-20px/36px inline-flex items-center'>
          {title}
          {tooltip ? (
            <Tooltip title={tooltip} placement='top'>
              <IconFont
                className='text-font_1 text-op-40 ml-4 cursor-pointer text-14px'
                name='jieshishuimeng'
              />
            </Tooltip>
          ) : null}
        </span>
        {children}
      </div>
      <div className='flex flex-justify-end w-60%'>
        <Input
          className='max-w-400px !text-14px'
          value={searchKey}
          prefix={<IconFont name='search' />}
          placeholder={`搜索${title}`}
          onChange={e => {
            const searchkey = e.target.value.trim()
            onSearch(searchkey)
          }}
        />
        <span className='mx-12 h-20px border-left self-center after:border-[rgba(225,225,229,0.6)]' />
        {renderCreateGroup?.() ||
          (innerShowCreateGroup && (
            <Access
              access={access.role([RoleCode.DEVELOPER, RoleCode.ADMINISTRATOR])}
              hide
            >
              <Button
                className='gap-6 w-110px !text-primary !border-line !border-op-80 hover:bg-bg_3 hover:bg-op-8'
                icon={<IconFont className='text-16px' name='add' />}
                onClick={() => setGroupModalOpen(true)}
              >
                新建分组
              </Button>
            </Access>
          ))}
        {renderCreate?.() || (innerShowCreate && <Button></Button>)}
        {extraActions ? (
          <>
            <span className='mx-12 h-20px border-left self-center after:border-[rgba(225,225,229,0.6)]' />
            {extraActions}
          </>
        ) : null}
      </div>
      <div className='absolute adapt:left-64 adapt:right-64 bottom-0 border-bottom after:border-line after:border-op-40' />
      <NewGroupModal
        open={groupModalOpen}
        title='新建分组'
        onOk={async title => {
          await onGroupCreate(title)
          setGroupModalOpen(false)
        }}
        onCancel={() => {
          setGroupModalOpen(false)
        }}
      />
    </div>
  )
}
