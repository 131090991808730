/**
 * for agent go
 */
import type { FormInstance, FormProps, StepProps } from 'antd'
import styled from '@emotion/styled'
import { Form, Modal, Steps, Switch, message } from 'antd'
import type { ReactNode } from 'react'
import React, { useCallback, useEffect, useState } from 'react'
import {
  appConnectTest,
  createApp,
  enableApp,
  unbindApp,
  updateApp,
} from '@apis/agent-go'
import type { AuthApp } from '@apis/agent-go/type'
import { Button } from '@/components'
import type { AgentGoConnectTestProps } from '../AgentGo/AgentGoConnectTest'
import { AgentGoConnectTest } from '../AgentGo/AgentGoConnectTest'
import { useAgentEdit } from '../provider/AgentEditProvider'
import type { AgentGoPublishGuideProps } from '../AgentGo/AgentGoPublishGuide'
import { AgentGoPublishGuide } from '../AgentGo/AgentGoPublishGuide'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

export interface AgentGoPublishConfProps {
  form: FormInstance
  /**
   * 发布模式是钉钉或者微信
   */
  mode?: string
  /**
   * 授权应用详情
   */
  app: Partial<AuthApp>
  steps?: StepProps[]
  guides: AgentGoPublishGuideProps['item'][]
  serviceAvatar?: string
  children: (step: number) => React.ReactNode
  /**
   * 步骤改变时触发
   */
  onStepChange?: (step: number) => void
  /**
   * 是否忽略点击完成时的请求，如果在STEP的过程中已经请求过接口，想跳过点击完成时的接口请求，可以使用这个参数
   */
  ignoreCompleteRequest?: boolean
  /**
   * 点击完成时触发
   */
  onBeforeComplete?: (values: any) => any // 提交表单前对表单进行操作
  onComplete?: (appId?: string) => void
  onUnBindSuccess?: () => void
  onValuesChange?: FormProps['onValuesChange']
  /**
   * 连接测试的额外操作插槽
   */
  connectTestCardActionSlot?: ReactNode
  completeDeps?: string[]
  /**
   * 在完成阶段额外追加组件
   */
  addonComplete?: React.FunctionComponent<any>
}

export const InternalSteps = styled(Steps)`
  &.ant-steps-navigation .ant-steps-item::before {
    display: none;
  }
  &.ant-steps-navigation .ant-steps-item::after {
    width: 8px;
    height: 8px;
  }
  &.ant-steps-navigation
    .ant-steps-item.ant-steps-item-active
    .ant-steps-item-title::before {
    inset-inline-start: 0;
    width: 100%;
  }
  &.ant-steps-navigation .ant-steps-item {
    .ant-steps-item-icon {
      width: 22px;
      height: 22px;
      line-height: 22px;
      font-weight: 500;
      margin-inline-start: 45px;
      border: none !important;
      padding-top: 1px;
    }
    .ant-steps-item-title {
      color: #17171d;
      font-size: 12px;
      font-weight: 500;
      line-height: 14px;
      overflow: visible;
    }
    .ant-steps-item-title::before {
      position: absolute;
      bottom: -12px;
      z-index: 1;
      inset-inline-start: 50%;
      display: inline-block;
      width: 0;
      height: 2px;
      background-color: #7b61ff;
      transition:
        width 0.3s,
        inset-inline-start 0.3s;
      transition-timing-function: ease-out;
      content: '';
    }
    .ant-steps-item-content {
      position: relative;
      margin-top: 8px;
    }
  }
  &.ant-steps-navigation .ant-steps-item.ant-steps-item-wait {
    .ant-steps-item-title {
      color: #8d8d99;
    }
    .ant-steps-item-icon {
      background: rgba(98, 105, 153, 0.06);
    }
    .ant-steps-item-icon .ant-steps-icon {
      color: #8d8d99;
    }
  }
  &.ant-steps-navigation .ant-steps-item.ant-steps-item-finish {
    .ant-steps-item-icon {
      font-size: 12px;
      background: #7b61ff;
    }
    .ant-steps-icon {
      color: #fff;
    }
  }
`

export function AgentGoPublishConf({
  form,
  mode,
  app,
  steps,
  guides,
  serviceAvatar,
  children,
  onStepChange,
  ignoreCompleteRequest,
  onComplete,
  onBeforeComplete,
  onValuesChange,
  onUnBindSuccess,
  connectTestCardActionSlot,
  completeDeps,
  addonComplete,
}: AgentGoPublishConfProps) {
  const ctx = useAgentEdit()

  const appId = app.auth_app_id

  const { scrollRef: scrollRefLeft } = useScrollBar()
  const { scrollRef: scrollRefRight, scrollBar: scrollBarRight } =
    useScrollBar()

  const [complete, setComplete] = useState(!!appId)

  const [errorMessage, setErrorMessage] = useState('')

  const [currentStep, setCurrentStep] = useState(0)

  const [isReset, setIsReset] = useState(false)

  const [connectState, setConnectState] = useState<
    AgentGoConnectTestProps['connectState']
  >(app.is_enable ? 'success' : 'off')

  const stepNumber = steps?.length ?? 0

  const handleCancel = () => {
    setIsReset(false)
    setComplete(true)
    setCurrentStep(stepNumber - 1)
  }

  const handleStepChange = (delta: number) => {
    const current = currentStep + delta
    setComplete(false)
    setCurrentStep(current)
    onStepChange?.(current)
    scrollBarRight.current?.scrollTo(0, 0, 100)
  }

  const handleComplete = async () => {
    if (appId) {
      // 修改发布配置
      try {
        let { auth_app_name, ...values } = form.getFieldsValue()
        values = onBeforeComplete ? onBeforeComplete(values) : values

        if (!ignoreCompleteRequest) {
          await updateApp({
            auth_app_id: appId,
            auth_app_name,
            auth_app_config: values,
          })
        }
        message.success('配置完成')

        onComplete?.(appId)
        setIsReset(false)
        setComplete(true)
      } catch (_) {}

      return
    }
    // 创建应用
    const agent_id = ctx.applicationInfo?.flowId
    const source = app.auth_app_source
    if (agent_id && source) {
      try {
        let { auth_app_name, ...values } = form.getFieldsValue()
        values = onBeforeComplete ? onBeforeComplete(values) : values

        if (!ignoreCompleteRequest) {
          const data = await createApp({
            agent_id,
            auth_app_name,
            auth_app_source: source,
            auth_app_config: values,
          })
          onComplete?.(data.auth_app_id)
          enableApp(data.auth_app_id)
        }

        message.success('配置完成')

        setIsReset(false)
        setComplete(true)

        setConnectState('success')
      } catch (_) {}
    }
  }

  const handleTest = useCallback(
    async (force?: boolean) => {
      if ((appId && app.is_enable) || (appId && force)) {
        setConnectState('testing')
        const data = await appConnectTest(appId).catch(e => {
          setErrorMessage(e.message ?? '未知原因')
          setConnectState('fail')
        })
        if (data === 'FAILED') {
          setConnectState('fail')
        } else if (data === 'SUCCEED') {
          setConnectState('success')
        }
      }
    },
    [appId, app.is_enable],
  )

  const handleReset = useCallback(() => {
    setIsReset(true)
    setComplete(false)
    setCurrentStep(0)
    window.setTimeout(() => {
      // 保证表单有值的情况下，校验能通过，按钮可点击
      const { auth_app_name, auth_app_config } = app
      // 针对后端返回的数据做兼容，出现过没传 completeDeps 中的 key ，但是后端返回老数据，值是 true 的情况
      const completeDepsObject: Record<string, boolean> = {}
      if (completeDeps && completeDeps?.length > 0) {
        completeDeps.forEach(key => {
          completeDepsObject[key] = false
        })
      }
      form.setFieldsValue({
        auth_app_name,
        ...auth_app_config,
        ...completeDepsObject,
      })
    })
  }, [app.auth_app_name, app.auth_app_config])

  const [nextDisabled, setNextDisabled] = useState(true)

  const handleUnbind = useCallback(async () => {
    if (appId) {
      Modal.confirm({
        className:
          '[&>.ant-modal-content]:p-20px [&>.ant-modal-content]:border [&>.ant-modal-content]:border-solid [&>.ant-modal-content]:border-[rgba(141,141,153,0.08)] [&>.ant-modal-content]:shadow-[0px_12px_32px_0px_rgba(153,162,176,0.24)]',
        width: 480,
        centered: true,
        content: (
          <p className='text-font font-medium text-14px/16px mt-4px mb-16px'>
            请确认是否要解除绑定？
          </p>
        ),
        icon: null,
        okText: '解绑',
        okButtonProps: {
          className:
            'inline-flex items-center justify-center p-0 w-60px h-36px text-white font-semibold !bg-error border-0 shadow-[0px_4px_8px_0px_rgba(255,82,25,0.24)] hover:!bg-op-60',
        },
        cancelButtonProps: {
          className:
            'inline-flex items-center justify-center p-0 w-60px h-36px !text-font font-semibold !border-[rgba(225,225,229,0.8)] shadow-[0px_2px_4px_0px_rgba(23,26,29,0.0784)]',
        },
        onOk: async () => {
          await unbindApp(appId)
          onUnBindSuccess?.()
          form.resetFields()
          setNextDisabled(true)
          setIsReset(false)
          setComplete(false)
          setCurrentStep(0)
        },
      })
    }
  }, [appId])

  const values = Form.useWatch([], form)

  useEffect(() => {
    if (complete) {
      handleTest(true)
    }
  }, [])

  useEffect(() => {
    setTimeout(() => {
      form.validateFields({ validateOnly: true }).then(
        () => setNextDisabled(false),
        () => {
          setNextDisabled(true)
        },
      )
    }, 0)
  }, [values, currentStep])

  const guide = complete ? guides[guides.length - 1] : guides[currentStep]

  return (
    <div className='h-full flex flex-col p-24px'>
      <h2 className='flex items-center text-20px/32px font-medium text-font mb-24px'>
        {mode}
        {appId ? (
          <Switch
            className='ml-8px'
            checked={app.is_enable}
            onChange={async checked => {
              await onValuesChange?.({ is_enable: checked }, undefined)
              if (checked) {
                if (complete) {
                  handleTest(true)
                }
              } else {
                setConnectState('off')
              }
            }}
          />
        ) : null}
      </h2>
      <div className='flex flex-1 overflow-hidden'>
        <div
          className='flex-1 rounded-8px bg-white overflow-y-auto'
          ref={scrollRefLeft}
        >
          {complete ? (
            <div className='p-24px'>
              <h3 className='text-16px font-medium text-font mb-24px'>
                {mode}已接入 Agent
              </h3>
              <AgentGoConnectTest
                agentAvatar={ctx.applicationInfo?.icon}
                serviceAvatar={serviceAvatar}
                serviceName={app?.bot_info?.app_name || app.auth_app_name}
                connectState={connectState}
                connectTime={app.created_at}
                operator={app.updated_username}
                onTest={handleTest}
                errorMessage={errorMessage}
                onReset={handleReset}
                onUnbind={handleUnbind}
                authAppEnable={app.is_enable}
                actionSlot={connectTestCardActionSlot}
              />
              {addonComplete &&
                app.agent_id &&
                React.createElement(addonComplete, {
                  agentId: app.agent_id,
                })}
            </div>
          ) : (
            <div>
              <div className='pt-12px border-bottom after:border-[rgba(225,225,229,0.6)]'>
                <InternalSteps
                  items={steps}
                  current={currentStep}
                  type='navigation'
                  labelPlacement='vertical'
                />
              </div>
              <Form
                className='p-24px pt-40px'
                form={form}
                layout='vertical'
                requiredMark={false}
                preserve={false}
                validateTrigger='onBlur'
                onValuesChange={onValuesChange}
              >
                {children(currentStep)}
                <div className='flex pt-16px'>
                  {isReset && (
                    <Button
                      className='shrink-0 mr-12px !border-[rgba(225,225,229,0.8)] w-100px !h-40px'
                      onClick={handleCancel}
                    >
                      取消
                    </Button>
                  )}
                  {stepNumber > 0 && currentStep > 0 && (
                    <Button
                      className='shrink-0 mr-12px !border-[rgba(225,225,229,0.8)] w-100px !h-40px'
                      onClick={() => handleStepChange(-1)}
                    >
                      上一步
                    </Button>
                  )}
                  {stepNumber > 0 &&
                    (currentStep < stepNumber - 1 ? (
                      <Form.Item key='next' noStyle>
                        <Button
                          className='flex-1 !h-40px'
                          type='primary'
                          disabled={nextDisabled}
                          onClick={() => handleStepChange(1)}
                        >
                          下一步
                        </Button>
                      </Form.Item>
                    ) : (
                      <Form.Item key='complete' shouldUpdate noStyle>
                        {form => {
                          let disabled = false
                          if (completeDeps?.length) {
                            const values = form.getFieldsValue(completeDeps)
                            disabled = completeDeps.some(key => !values[key])
                          }
                          return (
                            <Button
                              className='flex-1 !h-40px'
                              type='primary'
                              disabled={disabled || nextDisabled}
                              onClick={handleComplete}
                            >
                              完成
                            </Button>
                          )
                        }}
                      </Form.Item>
                    ))}
                </div>
              </Form>
            </div>
          )}
        </div>
        <div
          className='flex-1 ml-24px rounded-8px bg-white overflow-y-auto'
          ref={scrollRefRight}
        >
          <AgentGoPublishGuide item={guide} />
        </div>
      </div>
    </div>
  )
}
