import { Tag, Tooltip, Space, Divider } from 'antd'
import { useNavigate } from 'react-router-dom'
import { clone } from 'lodash-es'
import { useRequest } from 'ahooks'
import { DocumentType, DocumentStatus } from '@apis/datastore/model'
import { updateDocument } from '@apis/datastore'
import type { DocumentItem } from '@apis/datastore/type'
import { DocumentNameEdit } from '@/pages/datastores/components/DocumentNameEdit.tsx'
import { IconFont, Button, ConfirmModal } from '@/components'
import { useModal } from '@/hooks/useModal.tsx'
import { TagEditorModal } from '@/pages/datastores/components/TagEditorModal.tsx'
import { Routers } from '@/pages/datastores/document-detail/Layout.tsx'

import { TagWithHover } from '@/features/datastore/components/DocumentTagWithHover'

import { DocumentTagList } from '@/features/datastore/components/DocumentTagList'
import {
  DATASTORE_MAX_TAG_COUNT,
  fileTypeMimeMap,
} from '@/features/datastore/constant'
import IframeNestedAuthComponent from '@/hoc/IframeNestedAuthComponent'
import { useTrack } from '@/features/track/Track'

interface HeaderProps {
  documentInfo: DocumentItem
  workspaceId: string
  dataset_id: number
  dataLoading: boolean
  saveLoading: boolean
  onTagsChange: () => void
  onRetry: () => void
  hasUnSaveData: boolean
  onSave: () => void
  toContent: () => void
  route: string
  handleGenerateAISummary: (id?: number) => void
  isEditing?: boolean
}

export default function Header(props: HeaderProps) {
  const {
    dataset_id,
    workspaceId,
    hasUnSaveData,
    saveLoading,
    dataLoading,
    onTagsChange,
    onRetry,
    route,
    toContent,
    handleGenerateAISummary,
    documentInfo,
    isEditing,
    onSave,
  } = props

  const {
    mimetype,
    file_id,
    file_name,
    file_type,
    tags,
    status,
    failed_reason = '',
  } = documentInfo

  const navigate = useNavigate()

  const [tagEditorModal] = useModal(TagEditorModal)

  const { run: updateDocumentApi } = useRequest(updateDocument, {
    manual: true,
  })

  const viewStatus = saveLoading ? DocumentStatus.Ing : status
  const { doTrack } = useTrack()

  const handleEditTag = () => {
    tagEditorModal.open({
      title: tags?.length ? '编辑标签' : '添加标签',
      defaultValue: tags,
      documentId: file_id,
      datasetId: dataset_id,
      onFinish: onTagsChange,
    })
  }

  const [confirmModal] = useModal(ConfirmModal, {
    okButtonProps: {
      danger: false,
      loading: saveLoading,
    },
  })

  const handleBack = () => {
    navigate(
      `/datastores/${workspaceId}/${dataset_id}/documents${location.search}`,
    )
  }

  const onBack = () => {
    if (isEditing && hasUnSaveData) {
      confirmModal.open({
        title: '修改的内容尚未保存，请确认是否保存？',
        okText: '保存',
        cancelText: '取消并退出',
        onOk: async () => {
          await onSave()
          doTrack('datastore_doc_save', { type: 'back_save' })
          confirmModal.close()
          setTimeout(() => {
            handleBack()
          }, 200)
        },
        onCancel: () => {
          handleBack()
        },
      })
    } else {
      handleBack()
    }
  }

  return (
    <div className='pl-16 pr-24 bg-white py-17.5px flex-center-between flex-wrap gap-8 b-b b-line b-op-60'>
      <div className='flex items-center flex-1'>
        <IframeNestedAuthComponent>
          <span
            onClick={onBack}
            className='mr-12 w-32px h-32px hover:bg-bg_3 hover:bg-op-8 rounded-6px flex-center cursor-pointer'
          >
            <IconFont name='fanhui' className='text-16px' />
          </span>
        </IframeNestedAuthComponent>
        <DocumentNameEdit
          mimetype={mimetype}
          file_name={file_name}
          file_id={file_id}
          file_type={file_type}
          onSave={updateDocumentApi}
        />
        <div className='ml-12 font-400 shrink-0'>
          <div className='flex items-center gap-8 w-100%'>
            <div className='flex flex-1 items-center overflow-hidden ml-8px'>
              {(tags?.length || 0) < DATASTORE_MAX_TAG_COUNT && (
                <Tag
                  className='rounded-4px b-dashed bg-white hover:bg-bg_3 hover:bg-op-8 cursor-pointer ,'
                  onClick={() => handleEditTag()}
                >
                  添加标签
                </Tag>
              )}
              <DocumentTagList
                className='h-22px! !text-12px'
                list={clone?.(tags || [])?.reverse() || []}
                showMaxCount={
                  (tags?.length || 0) < DATASTORE_MAX_TAG_COUNT ? 4 : 6
                }
                onClick={() => {
                  handleEditTag()
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <Space size={12}>
        <TagWithHover
          status={viewStatus}
          className='rounded-4px font-500 text-12px min-w-52px mr-0 text-center ml-12 cursor-pointer'
          tooltip={
            ((viewStatus === DocumentStatus.Fail ||
              viewStatus === DocumentStatus.Warning) &&
              failed_reason) ||
            ''
          }
          onClick={() => {
            if (viewStatus === DocumentStatus.Fail) {
              onRetry()
            }
          }}
        />
        {viewStatus === DocumentStatus.Warning && (
          <Tooltip title='一键AI处理所有异常段落'>
            {!isEditing && (
              <div className='w-24px h-24px m-l-[-8px] m-r-[-8px] cursor-pointer hover:bg-[rgba(98,105,153,0.08)] rounded-4px flex justify-center items-center text-16px/16px'>
                <IconFont
                  name='AIcengjiangchuli'
                  onClick={async () => {
                    await handleGenerateAISummary()
                  }}
                />
              </div>
            )}
          </Tooltip>
        )}
        {(file_type === DocumentType.FILE ||
          status === DocumentStatus.Done) && (
          <Divider type='vertical' className='mx-8' />
        )}
        {(file_type === DocumentType.FILE ||
          file_type === DocumentType.VIDEO) &&
          mimetype !== fileTypeMimeMap.msg && (
            <Button
              onClick={() => {
                if (route === Routers.SourcePreview) {
                  navigate(
                    `/datastores/${workspaceId}/${dataset_id}/${file_id}/${Routers.Content}${location.search}`,
                  )
                } else {
                  navigate(
                    `/datastores/${workspaceId}/${dataset_id}/${file_id}/${Routers.SourcePreview}${location.search}`,
                  )
                }
              }}
            >
              预览源文件
            </Button>
          )}
        {(status === DocumentStatus.Done ||
          status === DocumentStatus.Warning) && (
          <>
            {DocumentType.QA !== file_type &&
              DocumentType.VIDEO !== file_type && (
                <Button
                  onClick={() => {
                    if (route === Routers.Split) {
                      toContent()
                    } else {
                      navigate(
                        `/datastores/${workspaceId}/${dataset_id}/${file_id}/${Routers.Split}${location.search}`,
                      )
                    }
                  }}
                  disabled={dataLoading || props.isEditing}
                >
                  重新分段
                </Button>
              )}
          </>
        )}
        {(status === DocumentStatus.Done ||
          status === DocumentStatus.Warning ||
          status === DocumentStatus.AIProcess) && (
          <Tooltip placement='bottom' title={hasUnSaveData ? '请先保存' : ''}>
            <div>
              <Button
                disabled={hasUnSaveData || props.isEditing}
                onClick={() => {
                  if (route === Routers.HitTest) {
                    toContent()
                  } else {
                    navigate(
                      `/datastores/${workspaceId}/${dataset_id}/${file_id}/${Routers.HitTest}${location.search}`,
                    )
                  }
                }}
              >
                命中测试
              </Button>
            </div>
          </Tooltip>
        )}
      </Space>
    </div>
  )
}
