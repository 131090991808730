import { useRequest } from 'ahooks'
import { getLog } from '@apis/run'

export function useLastFlowTaskId(flowId: string) {
  const { runAsync: fetchLogs } = useRequest(
    () =>
      getLog({
        flowId,
        pageNo: 1,
        pageSize: 1,
      }),
    { manual: true },
  )

  const getLastFlowTaskId = async () => {
    try {
      const { data: logs } = await fetchLogs()
      if (!logs || !logs.length) {
        throw new Error('没找到最近的运行记录')
      }
      return logs[0].task_id
    } catch (error) {}
  }

  return getLastFlowTaskId
}
