import classNames from 'classnames'
import { useMemo } from 'react'
import { useAuth } from '@bty/react-auth'
import { RoleCode } from '@apis/authority/type'
import { IconFont } from '@/components'
import type { NodeType } from '@/features/nodes/base'
import { useMovableOptionStore, useVersionStore } from '@/store'
import { type Version } from '@/features/pay'
import { useLimitedAccessModal } from '@/features/pay/LimitedAccess'

interface BaseNodeCardProps {
  type: NodeType
  icon: string
  iconColor: string
  name: string
  description: string
  selected: boolean
  allowVersion: Version[]
  onClick: (type: NodeType) => void
}

export function BaseNodeCard(props: BaseNodeCardProps) {
  const {
    type,
    icon,
    iconColor,
    name,
    description,
    selected,
    onClick,
    allowVersion,
  } = props

  const { init } = useMovableOptionStore()
  const versionInfo = useVersionStore(state => state.versionInfo)
  const { state } = useAuth()
  const [limitedAccessModal] = useLimitedAccessModal({
    hideShowUpdateModal: !(state.role === RoleCode.ADMINISTRATOR),
  })

  const needUpdateVersion = useMemo(() => {
    if (
      Array.isArray(allowVersion) &&
      allowVersion?.length > 0 &&
      versionInfo
    ) {
      return !allowVersion.includes(versionInfo?.product_code)
    }
    return false
  }, [allowVersion, versionInfo])

  return (
    <div
      className={classNames(
        'mb-12px p-12px b-rd-8px b-1 b-op-60 hover:bg-#626999 hover:bg-op-8 cursor-pointer',
        {
          'b-#7b61ff': selected,
          'b-#e1e1e5': !selected,
        },
      )}
      onClick={e => {
        if (needUpdateVersion) {
          // 现在只有团队版的限制
          limitedAccessModal.open({
            title: `团队版无法使用${name}功能，请升级到企业版使用。`,
          })
          return
        }
        const { clientX, clientY } = e
        init({
          x: clientX,
          y: clientY,
        })
        onClick(type)
      }}
    >
      <div className='flex items-center mb-4px'>
        <div
          className='flex-center mr-8px w-24px h-24px b-rd-4px'
          style={{ backgroundColor: iconColor }}
        >
          <IconFont name={icon} />
        </div>
        <div className='flex-1 font-500 text-#17171d'>{name}</div>
        <div className='flex-center w-24px h-24px b-rd-4px'>
          <IconFont
            name={
              needUpdateVersion ? 'suoding' : selected ? 'jiediansixuan' : 'add'
            }
            className={classNames('text-16px text-#7861ff', {
              'text-#626999/60!': needUpdateVersion,
            })}
          />
        </div>
      </div>
      <div className='pl-32px line-height-18px text-12px text-#8D8D99'>
        {description}
      </div>
    </div>
  )
}
