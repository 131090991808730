import React, { useEffect, useRef } from 'react'
import { Switch } from 'antd'
import { Smartsheet, type SmartsheetInstance } from '@bty/smartsheet'
import { useRequest } from 'ahooks'
import NiceModal from '@ebay/nice-modal-react'
import type { AgentDetailDatabase, AgentDetailTable } from '@bty/chat-types'
import { getDataBaseInfo } from '@apis/database'
import type { Database } from '@apis/database/type'
import { IconFont, SuperLink } from '@/components'
import TableIcon from '@/assets/agent/table.svg?url'
import useTableDetail from '@/features/database/hooks/useTableDetail'
import type { BindingTableEvents } from '../types'
import TableImportModal from '@/features/database/components/TableImportModal'

export interface BindingTableDataGridProps extends BindingTableEvents {
  database: AgentDetailDatabase
  table: AgentDetailTable
  onBack: VoidFunction
}

function InternalBindingTableDataGrid({
  database,
  table: tableInAgent,
  onBack,
  onLink,
  onUpdate,
  onEnableChange,
}: BindingTableDataGridProps) {
  const { data: base } = useRequest(getDataBaseInfo, {
    defaultParams: [database.database_id],
    cacheKey: `database_${database.database_id}`,
    staleTime: -1,
  })

  const { table, reload } = useTableDetail(tableInAgent.table_id)

  const smartsheetRef = useRef<SmartsheetInstance | null>(null)

  useEffect(
    () => () => {
      smartsheetRef.current?.unmount()
    },
    [],
  )

  useEffect(() => {
    if (!base || !table) {
      return
    }

    if (smartsheetRef.current) {
      smartsheetRef.current.update({
        base,
        table,
      })
      return
    }

    smartsheetRef.current = Smartsheet({
      dom: '#agent-smartsheet',
      base,
      table,
      toolbar: {
        canUpdateTableMeta: false,
      },
      onTableMetaUpdated: reload,
      onImportRow: (tableId, databaseId, sourceId, afterImport) => {
        NiceModal.show(TableImportModal, {
          delta: true,
          tableId,
          database: { id: databaseId, sources: [{ id: sourceId }] } as Database,
          onOk: async () => {
            afterImport?.()
          },
        })
      },
    })
  }, [base, table])

  return (
    <>
      <div className='text-font_1 flex items-center mb-12 shrink-0'>
        <span
          className='text-16px/20px font-medium cursor-pointer hover:text-font'
          onClick={event => {
            event.stopPropagation()
            onBack()
          }}
        >
          {database.database_name}
        </span>
        <IconFont className='mx-12' name='left-arrow' />
        <div className='flex items-center'>
          <img className='w-20px object-contain' src={TableIcon} />
          <span className='mx-4 text-16px/20px font-medium text-font'>
            {table?.title}
          </span>
          <SuperLink
            onClick={() => onLink(database.database_id, tableInAgent.table_id)}
          />
        </div>
        <span
          className='w-28px h-28px ml-auto mr-4 text-16px text-[rgba(98,105,153,0.6)] flex-center cursor-pointer hover:text-primary'
          onClick={event => {
            event.stopPropagation()
            onUpdate(database, tableInAgent.table_id, reload)
          }}
        >
          <IconFont name='kongjianshezhi' />
        </span>
        <Switch
          size='small'
          defaultChecked={tableInAgent.is_enable}
          onChange={(checked, event) => {
            event.stopPropagation()
            onEnableChange(tableInAgent.id, checked)
          }}
        />
      </div>
      <div id='agent-smartsheet' className='flex-1 overflow-hidden' />
    </>
  )
}

export const BindingTableDataGrid = React.memo(InternalBindingTableDataGrid)
