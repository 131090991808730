import { Global, css } from '@emotion/react'
import type { ChatPCInstance, ChatPCProps } from '@bty/chat-renderer-pc'
import { forwardRef, memo, useEffect, useRef } from 'react'
import { ChatbotRunType } from '@bty/chat-types'
import type { ApplicationBodyType } from '@apis/application/type'
import { AgentChat } from '@/features/chat/AgentChat'
import { IconFont } from '@/components'
import { useUnreadMessageListener } from '@/pages/home/hooks/useUnreadMessageListener'
import { useAgentDiffStore } from '@/store/agentDiffStore'
import { moreRef } from '@/utils/react'

function GlobalAgentChartStyle() {
  return (
    <Global
      styles={css`
        .ChatHeader {
          background-color: #fafafc !important;
        }
        .chat-input-container {
          background: #fafafc;
        }
        .chat-input-container:focus-within {
          box-shadow: none;
        }
        .chat-input-wrapper {
          background: #fafafc;
          textarea {
            background: transparent;
          }
        }
        .ShortcutTipsWrapper {
          background: transparent;
        }
      `}
    />
  )
}

function ChatPlaceholder(props: { content: string }) {
  return (
    <div className='flex justify-center items-center flex-col'>
      <IconFont
        name='openSidebar'
        className='text-font_1 text-opacity-60 text-[42px] mb-[16px]'
      />
      <div className='text-font_1 text-opacity-60'>{props.content}</div>
    </div>
  )
}

interface AgentChatProps {
  applicationInfo: ApplicationBodyType
  chatVisible: boolean
  initVariablesValue?: (newValue: Record<string, any>) => void
  onMessageTaskClick?: ChatPCProps['onMessageTaskClick']
  uploadFileConfig: ChatPCProps['uploadFileConfig']
}

const AgentChatComp = forwardRef<ChatPCInstance, AgentChatProps>(
  (
    {
      applicationInfo,
      chatVisible,
      initVariablesValue,
      onMessageTaskClick,
      uploadFileConfig,
    },
    ref,
  ) => {
    const chatRef = useRef<ChatPCInstance>(null)

    const subscribeVariableValue = useAgentDiffStore(
      s => s.subscribeVariableValue,
    )

    useUnreadMessageListener({
      agentTest: true,
    })

    useEffect(() => {
      return subscribeVariableValue(
        (newValue: Record<string, any>, update: boolean) => {
          if (!update) {
            chatRef.current?.saveVariables(newValue)
          } else {
            chatRef.current?.refreshByVariables(newValue)
          }
        },
      )
    }, [])

    return (
      <>
        <GlobalAgentChartStyle />
        <AgentChat
          ref={moreRef(ref, chatRef)}
          robotAvatarInfo={{
            name: applicationInfo.name,
            icon: applicationInfo.icon,
            color: applicationInfo.color,
          }}
          botMessageProps={{
            backgroundColor: 'rgba(247, 247, 250, 0.6)',
          }}
          hiddenPadding={true}
          // autoCreateConversation
          background='transparent'
          showPlaceholder={!chatVisible}
          paddingX={40}
          placeholder={<ChatPlaceholder content='请先配置「规则」属性' />}
          runType={ChatbotRunType.AGENT_TESTING}
          actionType='AGENT_RULE_CHANGE'
          inputTips={['enter', 'shift + enter']}
          clearText='规则已修改，新会话开始'
          robotId={applicationInfo.flowId}
          hideHeader
          hiddenVarModal
          initVariablesValue={initVariablesValue}
          testMode
          onMessageTaskClick={onMessageTaskClick}
          uploadFileConfig={uploadFileConfig}
        />
      </>
    )
  },
)

export default memo(AgentChatComp)
