import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import {
  getAsyncSupportLLMModelListLimited,
  getLLMModelList,
  getLLMModelListLimited,
} from '@apis/llm'
import type { LLMModalItem, LLMModelLimitedItem } from '@apis/llm/type'
import { workspaceStorage } from '@/utils/storage.ts'

interface LLMStore {
  llmModelList: LLMModalItem[]
  llmModelLimitedList: LLMModelLimitedItem[]
  llmAsyncSupportModelLimitedList: LLMModelLimitedItem[]
  getLlmModelListLoading: boolean
  getAsyncSupportModelListLoading: boolean
  initLLMData: () => Promise<void>
}

export const useLLMStore = create<LLMStore>(set => ({
  llmModelList: [],
  llmModelLimitedList: [],
  llmAsyncSupportModelLimitedList: [],
  getLlmModelListLoading: true,
  getAsyncSupportModelListLoading: true,
  initLLMData: async () => {
    getLLMModelList('textGenerate').then(res => {
      set({ llmModelList: res })
    })
    set({ getLlmModelListLoading: true })
    getLLMModelListLimited(workspaceStorage.get()!)
      .then(list => {
        set({ getLlmModelListLoading: false })
        return list.length && set({ llmModelLimitedList: list })
      })
      .catch(() => set({ getLlmModelListLoading: false }))
    set({ getAsyncSupportModelListLoading: true })
    getAsyncSupportLLMModelListLimited(workspaceStorage.get()!)
      .then(list => {
        return list.length && set({ llmAsyncSupportModelLimitedList: list })
      })
      .finally(() => {
        set({ getAsyncSupportModelListLoading: false })
      })
  },
}))

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('llmStore', useLLMStore)
}
