import './index.css'

import classNames from 'classnames'
import type { FocusEvent, KeyboardEvent, MouseEvent } from 'react'
import {
  forwardRef,
  memo,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useMemoizedFn } from 'ahooks'
import { isEqual } from 'lodash-es'
import { wait } from '@/utils/wait'
import { useResize } from '@/components/scrollbar/hooks'
import type { TextEditPureProps, TipItem, TrieNode } from './type'
import { createTireTip, getTireTips } from './util'
import { Scrollbar } from './components/scrollbar'
import type { TipRef } from './components/tip'
import { Tip } from './components/tip'
import { Editable, isPlaintext } from './components/contenteditable'
import { TextHighlight } from './components/highlight'

export interface TextEditorRef {
  insertText: (text: string) => void
}

export const TextEditorInner = forwardRef<TextEditorRef, TextEditPureProps>(
  (props, ref) => {
    const {
      minHeight,
      height,
      maxHeight,
      onHeightChange,

      value: outValue,
      onChange,
      onFocus,
      onBlur,
      onKeyDown,

      placeholder,
      disabled,
      readonly,

      tipMap,
      tipsContainer,
      rows,

      updateToBottom,
      focusScroll,
      className,
      contentClassName,
    } = props

    const editorDomRef = useRef<HTMLDivElement>(null)
    const editorRef = useRef<HTMLDivElement>(null)
    const contentRef = useRef<HTMLDivElement>(null)
    const scrollContentRef = useRef<HTMLDivElement>(null)
    const trieText = useRef<string>('')
    const trieTree = useRef<TrieNode | null | undefined>(null)
    const tipRef = useRef<TipRef>(null)
    const [focus, setFocus] = useState<boolean>(false)
    const [value, setValue] = useState(outValue ?? '')
    const [tips, setTips] = useState<TipItem[]>([])

    const canEdit = useMemo(() => !disabled && !readonly, [disabled, readonly])
    const tipTire = useMemo(() => createTireTip(tipMap), [tipMap])
    const allTips = useMemo(() => getTireTips(tipTire), [tipMap])

    const handleValueChange = useMemoizedFn((newValue: string) => {
      if (value == null) {
        setValue(newValue)
      }
      onChange?.(newValue)
    })

    const resetTips = useMemoizedFn(async (newTips: TipItem[]) => {
      if (!newTips.length) {
        trieTree.current = null
        trieText.current = ''
      }
      setTips(prev => {
        if (isEqual(newTips, prev)) return prev
        return newTips
      })
      tipRef.current?.setTipSelect(0)
    })

    const handleChange = useMemoizedFn((newValue: string) => {
      handleValueChange(newValue)
    })

    const handleFocusInsert = useMemoizedFn(async (text: string) => {
      if (!editorRef.current) return
      const selection = window.getSelection()
      const range = selection?.getRangeAt(0)
      if (!selection || !range) return
      const textLength = trieText.current?.length ?? 0
      for (let i = 0; i < textLength; i++) {
        document.execCommand('delete', false)
      }
      document.execCommand('insertText', false, text.replaceAll('\n', ''))
    })

    const handleTipSelect = useMemoizedFn(async (index: number) => {
      const text = tips[index].value
      if (!text) return
      handleFocusInsert(text)
      resetTips([])
    })

    const handleArrow = useMemoizedFn(
      (event: KeyboardEvent<HTMLDivElement>) => {
        const key = event.key
        if (key === 'Enter') {
          const nowRows = value.split('\n').length
          if (rows && nowRows >= rows) {
            event.preventDefault()
          }
        }

        if (!isPlaintext) return true

        if (!tips || !tips.length) return false
        if (key !== 'ArrowUp' && key !== 'ArrowDown' && key !== 'Enter') {
          return false
        }

        event.preventDefault()

        if (key === 'ArrowUp') {
          tipRef.current?.setTipSelect(prev => (prev === 0 ? prev : prev - 1))
        }

        if (key === 'ArrowDown') {
          tipRef.current?.setTipSelect(prev =>
            prev === tips.length - 1 ? prev : prev + 1,
          )
        }

        if (key === 'Enter') {
          handleTipSelect(tipRef.current?.getTipSelect() ?? 0)
          const nowRows = value.split('\n').length
          if (rows && nowRows > rows) {
            return false
          }
        }

        return true
      },
    )

    const handleKeyDown = useMemoizedFn(
      (event: KeyboardEvent<HTMLDivElement>) => {
        onKeyDown?.(event)
        const key = event.key
        const block = handleArrow(event)

        if (block) return

        if (key.length !== 1) {
          resetTips([])
          return
        }

        const parentTrie = trieTree.current || tipTire
        if (!parentTrie.children?.some(each => each.char === key)) {
          resetTips([])
          return
        }
        trieTree.current = parentTrie.children.find(each => each.char === key)
        trieText.current += key
        resetTips(getTireTips(trieTree.current))
      },
    )

    const handleFocus = useMemoizedFn((e: FocusEvent<HTMLDivElement>) => {
      setFocus(true)
      onFocus?.(e)
    })

    const handleBlur = useMemoizedFn(async (e: FocusEvent<HTMLDivElement>) => {
      onBlur?.(e)
      setFocus(false)
      await wait(300)
      resetTips([])
    })

    const focusToEnd = useMemoizedFn((e?: MouseEvent) => {
      if (!editorRef.current) return
      if (e?.target === editorRef.current) return
      const range = document.createRange()
      const selection = window.getSelection()
      if (!selection) return
      range.selectNodeContents(editorRef.current)
      range.collapse(false)
      selection.removeAllRanges()
      selection.addRange(range)
      editorRef.current.focus()
    })

    useEffect(() => {
      setValue(outValue ?? '')

      if (updateToBottom) {
        if (!scrollContentRef.current) return
        scrollContentRef.current.scrollTop =
          scrollContentRef.current.scrollHeight
      }
    }, [outValue])

    useEffect(() => {
      const handleMouse = async (event: any) => {
        event.stopPropagation()
        await wait(30)
        resetTips([])
      }

      const handleWheel = (event: any) => {
        if (!event.ctrlKey && focusScroll && focus) {
          event.stopPropagation()
        }
      }

      editorDomRef.current?.addEventListener('mousedown', handleMouse)
      editorDomRef.current?.addEventListener('wheel', handleWheel)

      return () => {
        editorDomRef.current?.removeEventListener('mousedown', handleMouse)
        editorDomRef.current?.removeEventListener('wheel', handleWheel)
      }
    }, [focus])

    useImperativeHandle(ref, () => ({
      insertText: handleFocusInsert,
      focus: () => editorRef.current?.focus(),
    }))

    useResize(contentRef, () => {
      if (!contentRef.current) return
      onHeightChange?.(contentRef.current?.offsetHeight)
    })

    const sureValue = useMemo(() => outValue ?? value, [outValue, value])

    return (
      <div
        ref={editorDomRef}
        className={classNames('text-editor', className, {
          'text-editor-disabled': disabled,
        })}
        onClick={focusToEnd}
      >
        <div className='text-editor-scroll-wrapper'>
          <div
            ref={scrollContentRef}
            className={classNames(contentClassName, 'text-editor-scroll')}
            style={{
              minHeight,
              height,
              maxHeight,
              overflow: focusScroll && !focus ? 'hidden' : 'auto',
            }}
          >
            <div
              className='text-editor-wrapper'
              ref={contentRef}
              style={{ minHeight }}
            >
              <Editable
                editable={canEdit}
                className={classNames('text-editor-input')}
                onFocus={handleFocus}
                onBlur={handleBlur}
                divRef={editorRef}
                onKeyDown={handleKeyDown}
                value={sureValue}
                onChange={handleChange}
              />

              {!sureValue && placeholder && (
                <div className='text-editor-placeholder'>{placeholder}</div>
              )}

              <TextHighlight
                editorRef={editorRef}
                text={sureValue}
                tips={allTips}
              />
            </div>
          </div>

          {isPlaintext && (
            <Tip
              ref={tipRef}
              tips={tips}
              tipsContainer={tipsContainer}
              onSelect={handleTipSelect}
              editorDomRef={editorDomRef}
            />
          )}

          <Scrollbar scrollRef={scrollContentRef} />
        </div>
      </div>
    )
  },
)

export const TextEditorPure = memo(TextEditorInner)
TextEditorPure.displayName = 'TextEditorPure'
