import type { ISubFlowItem } from '@apis/flow/type'
import { FieldTypes } from '@/features/nodes/start'

export function getFlowFormItemType(fieldType: string) {
  switch (fieldType) {
    case FieldTypes.MultiSelect:
      return 'string[]'
    case FieldTypes.Json:
      return 'json'
    default:
      return 'string'
  }
}

export function getFlowConfigCopyValue(
  formConfig: ISubFlowItem['form_config'],
): string {
  const value: Record<string, any> = {}
  formConfig.forEach(item => {
    let defaultValue: any = ''
    switch (item.type) {
      case FieldTypes.Json:
        defaultValue = {}
        break
      case FieldTypes.MultiSelect:
        defaultValue = []
        break
      default:
        break
    }
    value[item.variableName] = defaultValue
  })
  return JSON.stringify(value, null, 2)
}
