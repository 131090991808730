import { LoadingOutlined } from '@ant-design/icons'
import { useKeyPress, useMemoizedFn, useRequest } from 'ahooks'
import type { FormInstance } from 'antd'
import { Divider, Form, Tooltip } from 'antd'
import classNames from 'classnames'
import type { RefObject } from 'react'
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import type { OverlayScrollbarsComponentRef } from 'overlayscrollbars-react'
import { OverlayScrollbarsComponent } from 'overlayscrollbars-react'
import dayjs from 'dayjs'
import { getLLMNodePromptRecordList } from '@apis/prompt'
import type { PromptStructItem } from '@apis/prompt/type'
import { PromptType } from '@apis/prompt/type'
import { IconFont } from '@/components'
import type { IStartNodeFormItemType } from '@/features/nodes/start'
import type { LLMNodeData, Message } from '../../LLMNode'
import { isMac } from '../utils'
import {
  getModelMessageStructTypeByChannel,
  LLMMessageStructType,
} from '@/features/nodes/utils/llm.ts'
import { DEFAULT_OVERLAY_SCROLLBAR_OPTIONS } from '@/hooks/useScrollBar'
import {
  contextOptions,
  LLM_NODE_PROMPT_FULLSCREEN,
  LLMContextType,
} from '../../const'
import { useCache } from '@/hooks/useCache'
import { LLMNodePanelContext, TaskStatus } from '../type'
import { ModelSetting } from '../../components/ModelSetting'
import { typeSelectOption } from '@/pages/application/components/appDetail/utils'
import type { InfiniteSelectOption } from '../../components/InfiniteSelect'
import { InfiniteSelect } from '../../components/InfiniteSelect'
import { PromptStructEditor } from '@/features/prompt/components/PromptStructEditor/PromptStructEditor.tsx'
import { EditorTypeSelector } from '@/features/prompt/components/PromptStructEditor/EditorTypeSelector'
import { PromptSyncTransformer } from '@/features/prompt/components/PromptStructEditor/PromptSyncTransformer'
import { PromptAICreateModal } from '@/features/prompt/components/PromptAICreateModal'
import { getPromptFromStructPrompt } from '@/features/prompt/utils/prompt'
import { usePromptTypeChange } from '@/features/prompt/hooks/usePromptTypeChange'
import { Prompt } from './Prompt'

import { ExecuteBtn } from './StyleComponent'
import { Card, Select } from './Card'
import { Chat } from './FormChat'
import { JSONField } from './JSONField'
import { getRender } from './VariableRender'
import { PromptMermaidWithFormItem } from './PromptMermaidWithFormItem'

interface IPromptPanelProps {
  scrollRef?: RefObject<OverlayScrollbarsComponentRef>
  form: FormInstance<LLMNodeData>
  flowId: string
  activatedNodeId: string
  readOnly?: boolean
  onCompare?: boolean
  variables: any[]
  usedKeyList: string[]
  variableValues: Record<string, any>
  startNodeFormItemType: IStartNodeFormItemType[] | undefined
  onChange: (values: Record<string, any>) => void
  setVariableValues: (params: Record<string, any>) => void
  getPopupContainer?: () => HTMLElement
}

const DEFAULT_OPTION = {
  value: 'manual',
  label: '手动输入',
  variable: {},
}

export const PromptPanel = memo((props: IPromptPanelProps) => {
  const {
    scrollRef,
    form,
    flowId,
    activatedNodeId,
    readOnly,
    onCompare,
    variables,
    usedKeyList,
    startNodeFormItemType = [],
    variableValues,
    setVariableValues,
    onChange,
    getPopupContainer,
  } = props

  const promptTypeValue = Form.useWatch(['inputs', 'promptType'], form)
  const wathcedPromptStructValue = Form.useWatch(
    ['inputs', 'structPrompt'],
    form,
  )
  const watchedPromptTextValue = Form.useWatch(
    ['inputs', 'system_content'],
    form,
  )

  const { onRun, subscribeState } = useContext(LLMNodePanelContext)

  const pageNo = useRef(1)
  const dom = useRef<HTMLDivElement>(null)
  const [varForm] = Form.useForm()

  const [historyList, setHistoryList] = useState<InfiniteSelectOption[]>([
    DEFAULT_OPTION,
  ])
  const [total, setTotal] = useState(2)

  const [varHistory, setVarHistory] = useState<InfiniteSelectOption>()
  const { runAsync: getHistoryData } = useRequest(
    (page_number: number) => {
      return getLLMNodePromptRecordList({
        flow_id: flowId,
        node_id: activatedNodeId,
        page_number,
        page_size: 10,
      })
    },
    {
      manual: true,
    },
  )

  const [loading, setLoading] = useState(false)

  const [fullscreen, setFullscreen] = useCache(
    `${LLM_NODE_PROMPT_FULLSCREEN}_${activatedNodeId}`,
    false,
  )

  const contextType = Form.useWatch(['inputs', 'context_type'], form)
  const channel = Form.useWatch(['inputs', 'modelSetting', 'channel'], form)
  const modelMessageStructType = useMemo(
    () => getModelMessageStructTypeByChannel(channel),
    [channel],
  )

  const chatValidator = useMemoizedFn((_: any, value: any, callback: any) => {
    const hasEmptyContent = value.some((item: Message) => {
      if (
        item.role !== 'system' &&
        modelMessageStructType !== LLMMessageStructType.NO_CONTEXT
      ) {
        return !item.content
      }
      return false
    })
    if (hasEmptyContent) {
      callback('Chat不能有空值')
    }
    callback()
  })

  const handleVarValueChange = useMemoizedFn((value: any) => {
    if (readOnly) return
    setVariableValues(value)
    setVarHistory(DEFAULT_OPTION)
  })

  const handleRun = useMemoizedFn(async () => {
    await form.validateFields()
    await varForm.validateFields()
    onRun()
  })

  const handleLoadMore = useMemoizedFn(async () => {
    const data = await getHistoryData(pageNo.current)
    setTotal(data.total)
    pageNo.current += 1
    setHistoryList(prev => {
      const newList = data.list.map(each => {
        const type = typeSelectOption.find(e => e.value === each?.run_env)

        return {
          value: String(each.history_id),
          label: (
            <span>
              <span>{type?.label || ''}</span>
              {each.show_name && <span>{each.show_name}</span>}
              <span className='text-#8D8D99 ml-8px'>
                {dayjs(each.created_time).format('MM-DD HH:mm:ss')}
              </span>
            </span>
          ),
          variable: each.variable,
          runType: each.run_env,
          showVariable:
            each.run_env === 'STEP_RUN'
              ? each.variable
              : each.flow_input || each.variable,
        }
      })

      return [...prev, ...newList]
    })
  })

  const handleChange = useMemoizedFn(
    (_newValue: string, option: InfiniteSelectOption) => {
      setVarHistory(option)
      setVariableValues({ ...variableValues, ...option.variable })
    },
  )

  const handleChatChange = useMemoizedFn(() => {
    const all = form.getFieldsError()
    const cleared = all.map(each => {
      if (each.name.join('.') === 'inputs.messages') {
        return {
          ...each,
          errors: [],
        }
      }
      return each
    })
    form.setFields(cleared)
  })

  useKeyPress([isMac() ? 'meta.enter' : 'ctrl.enter'], handleRun, {
    exactMatch: true,
  })

  const executeIconDisplay = useMemo(() => {
    return {
      text: loading ? '终止运行' : '运行',

      icon: loading ? (
        <LoadingOutlined />
      ) : (
        <IconFont className='align-text-bottom text-12px' name='yunxing' />
      ),
    }
  }, [loading])

  const contextContent = useMemo(() => {
    const extraCls = classNames('bg-#fff border-#E1E1E5 mb-8px', {
      'hover:border-#7b61ff': !readOnly,
    })

    if (contextType === LLMContextType.MSG_LIST) {
      return (
        <Form.Item
          className='mb-0px'
          name={['inputs', 'messages']}
          rules={[
            {
              validator: chatValidator,
            },
          ]}
          validateTrigger={['onSubmit']}
        >
          <Chat
            disabled={readOnly}
            anchor='right'
            modelMessageStructType={modelMessageStructType}
            completions={variables}
            extraCls={extraCls}
            btnCls={classNames('h-36px text-14px', {
              'hover:bg-[rgba(98,105,153,0.08)]!': !readOnly,
            })}
            onChange={handleChatChange}
          />
        </Form.Item>
      )
    }

    return (
      <Form.Item className='mb-0px' name={['inputs', 'pre_defined_messages']}>
        <JSONField
          disabled={readOnly}
          anchor='right'
          modelMessageStructType={modelMessageStructType}
          completions={variables}
          extraCls={extraCls}
          getPopupContainer={getPopupContainer}
        />
      </Form.Item>
    )
  }, [contextType, variables, readOnly, modelMessageStructType])

  const startConfigMap = useMemo(() => {
    return new Map(startNodeFormItemType.map(e => [e.key, e]))
  }, [startNodeFormItemType])

  const varContent = useMemo(() => {
    const usedKeyListWithType = usedKeyList.map(key => {
      const config = startConfigMap.get(key)
      if (!config) {
        return {
          key,
          type: 'textarea',
        } as IStartNodeFormItemType
      }

      return config
    })

    if (!usedKeyListWithType.length) return null

    const list = usedKeyListWithType.map((each, index) => {
      const content = getRender(each.type, each.key, {
        ...each,
        className: 'w-full',
        disabled: readOnly,
      })

      return (
        <div
          className={index + 1 !== usedKeyListWithType.length ? 'mb-12px' : ''}
          key={each.key}
        >
          <div className='text-14px mb-8px'>
            {each.key}
            {each.required && <span className='text-#FF5219 ml-4px'>*</span>}
          </div>
          <Form.Item
            name={each.key}
            className='mb-0px'
            rules={
              each.required
                ? [{ required: true, message: `${each.key} 不能为空` }]
                : []
            }
          >
            {content}
          </Form.Item>
        </div>
      )
    })

    return (
      <Form
        form={varForm}
        initialValues={variableValues}
        onValuesChange={handleVarValueChange}
      >
        {list}
      </Form>
    )
  }, [usedKeyList, startConfigMap, variableValues])

  const { promptTypeChangeEffect } = usePromptTypeChange(
    form,
    ['inputs', 'structPrompt'],
    ['inputs', 'system_content'],
    'Flow',
  )

  const getContainer = useCallback(() => {
    return scrollRef?.current?.getElement() ?? document.body
  }, [])

  const getPromptType = useCallback(() => {
    return form.getFieldValue(['inputs', 'promptType'])
  }, [])

  const getPrompt = useCallback(() => {
    return form.getFieldValue(['inputs', 'system_content'])
  }, [])

  const getPromptStruct = useCallback(() => {
    return getPromptFromStructPrompt(
      form.getFieldValue(['inputs', 'structPrompt']) ?? [],
    )
  }, [])

  const getPromptWithStruct = useCallback(() => {
    if (form.getFieldValue('promptType') === PromptType.STRUCT) {
      return getPromptFromStructPrompt(
        form.getFieldValue(['inputs', 'structPrompt']) ?? [],
      )
    }
    return form.getFieldValue(['inputs', 'system_content'])
  }, [])

  const handleUseAiStruct = (struct: PromptStructItem[]) => {
    const promptType = form.getFieldValue(['inputs', 'promptType'])
    if (promptType === PromptType.STRUCT) {
      form.setFieldValue(['inputs', 'structPrompt'], struct)
    } else {
      form.setFieldValue(
        ['inputs', 'system_content'],
        getPromptFromStructPrompt(struct),
      )
    }
    onChange(form.getFieldsValue())
  }

  const onPromptTypeChange = (
    value: PromptType,
    prompt?: { raw?: string; struct?: PromptStructItem[] },
  ) => {
    promptTypeChangeEffect(value, prompt)
    // 手动触发form更新
    const values = form.getFieldsValue()
    onChange(values)
  }

  useEffect(() => {
    return subscribeState((map: Map<string, TaskStatus>) => {
      setLoading([...map.values()].includes(TaskStatus.loading))
    })
  }, [])

  useEffect(() => {
    if (!loading) {
      pageNo.current = 1
      setHistoryList([DEFAULT_OPTION])
      setTotal(2)
    }
  }, [loading])

  useEffect(() => {
    varForm.setFieldsValue(variableValues)
  }, [variableValues])

  return (
    <div
      ref={dom}
      className='basis-0 grow-9 min-w-406px flex flex-col overflow-hidden llm-node-panel'
    >
      <OverlayScrollbarsComponent
        className='px-24px flex-1 flex-shrink-0 overflow-auto'
        element='div'
        options={DEFAULT_OVERLAY_SCROLLBAR_OPTIONS}
        defer
        ref={scrollRef}
      >
        {readOnly && (
          <div className='mt-12px h-56px border-1px border-#FE9700 rounded-8px flex items-center bg-[rgba(254,151,0,0.08)] px-14px'>
            <IconFont
              name='shuimeng'
              className='text-#FE9700 text-20px mr-8px'
            />
            <span className='text-14px'>历史记录预览状态下不可编辑</span>
          </div>
        )}

        <Form form={form} onValuesChange={onChange}>
          <div className='h-60px flex items-center'>
            <div className='mr-auto text-16px text-#17171D font-medium'>
              编排
            </div>

            <Form.Item name={['inputs', 'modelSetting']} noStyle>
              <ModelSetting
                disabled={onCompare || readOnly}
                disabledOpen={onCompare}
              />
            </Form.Item>
          </div>
          <Card
            headLeft='提示词'
            headTip={
              <div className='flex items-center'>
                <span className='text-#FF5219 ml-4px relative top-3px'>*</span>
                <Form.Item noStyle name={['inputs', 'promptType']}>
                  <EditorTypeSelector
                    className='ml-4'
                    onChange={onPromptTypeChange}
                  />
                </Form.Item>
              </div>
            }
            headRight={
              <div className='ml-auto flex items-center'>
                <PromptSyncTransformer
                  className='mr-8px'
                  promptType={promptTypeValue}
                  getPromptType={getPromptType}
                  getPromptText={getPrompt}
                  getPromptStructText={getPromptStruct}
                  onPromptTypeChange={onPromptTypeChange}
                />
                <PromptMermaidWithFormItem
                  promptType={promptTypeValue}
                  struct={wathcedPromptStructValue}
                  structText={watchedPromptTextValue}
                  getContainer={getContainer}
                />
                <Divider className='mx-4px' type='vertical' />
                <PromptAICreateModal
                  templateType='Flow'
                  getPrompt={getPromptWithStruct}
                  onConfirm={handleUseAiStruct}
                />
              </div>
            }
            content={
              <Form.Item
                noStyle
                shouldUpdate={(prev, curr) =>
                  prev.inputs.promptType !== curr.inputs.promptType
                }
              >
                {form => {
                  const promptType = form.getFieldValue([
                    'inputs',
                    'promptType',
                  ])

                  // if (promptType === PromptType.STRUCT) {
                  //   return (
                  //     <Form.Item name={['inputs', 'structPrompt']}>
                  //       <PromptStructEditor />
                  //     </Form.Item>
                  //   )
                  // } else {
                  //   return (
                  //     <Form.Item
                  //       className='mb-0px'
                  //       name={['inputs', 'system_content']}
                  //       rules={[
                  //         { required: true, message: 'System Prompt不能为空' },
                  //       ]}
                  //     >
                  //       <Prompt
                  //         disabled={readOnly}
                  //         variables={variables}
                  //         fullscreen={fullscreen}
                  //         setFullscreen={setFullscreen}
                  //       />
                  //     </Form.Item>
                  //   )
                  // }

                  return (
                    <>
                      <Form.Item
                        className='mb-0!'
                        hidden={promptType !== PromptType.STRUCT}
                        name={['inputs', 'structPrompt']}
                      >
                        <PromptStructEditor variables={variables} />
                      </Form.Item>
                      <Form.Item
                        className='mb-0px'
                        hidden={promptType === PromptType.STRUCT}
                        name={['inputs', 'system_content']}
                        rules={[
                          { required: true, message: 'System Prompt不能为空' },
                        ]}
                      >
                        <Prompt
                          disabled={readOnly}
                          variables={variables}
                          fullscreen={fullscreen}
                          setFullscreen={setFullscreen}
                        />
                      </Form.Item>
                    </>
                  )
                }}
              </Form.Item>
            }
          />

          <div className={fullscreen ? 'hidden' : ''}>
            {modelMessageStructType !== LLMMessageStructType.NO_CONTEXT && (
              <Card
                headLeft='上下文'
                headTip='添加对话上下文，以确保AI能更准确的理解人类的意图'
                headRight={
                  <Form.Item
                    name={['inputs', 'context_type']}
                    initialValue={LLMContextType.JSON_VARIABLE}
                    noStyle
                  >
                    <Select disabled={readOnly} options={contextOptions} />
                  </Form.Item>
                }
                content={contextContent}
              />
            )}
          </div>
        </Form>

        <div className={fullscreen ? 'hidden' : ''}>
          <Card
            headLeft='输入变量'
            headCenter={
              !usedKeyList.length ? (
                <span className='text-#8D8D99 ml-8px'>
                  {'暂无变量，请在提示词或上下文中输入 {{}} 引用变量'}
                </span>
              ) : undefined
            }
            headRight={
              usedKeyList.length ? (
                <InfiniteSelect
                  disabled={readOnly}
                  options={historyList}
                  hasMore={historyList.length < total + 1}
                  loadMore={handleLoadMore}
                  placeholder='手动输入'
                  value={varHistory || DEFAULT_OPTION}
                  onChange={handleChange}
                  getContainer={getPopupContainer}
                />
              ) : undefined
            }
            content={varContent}
          />
        </div>
      </OverlayScrollbarsComponent>

      <div className='px-24px pt-12px pb-24px'>
        {!readOnly && (
          <Tooltip
            placement='top'
            arrow={false}
            title={`快捷键${isMac() ? ' Cmd + Enter' : ' Ctrl + Enter'}`}
          >
            <ExecuteBtn
              type='primary'
              icon={executeIconDisplay.icon}
              className='run-btn w-100% c-#f7f7ff'
              onClick={handleRun}
            >
              {executeIconDisplay.text}
            </ExecuteBtn>
          </Tooltip>
        )}
      </div>
    </div>
  )
})
