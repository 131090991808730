import type { StepsProps } from 'antd'
import { Steps } from 'antd'
import { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRequest } from 'ahooks'
import type { OrderDetail } from '@apis/order/type'
import { cancelPay, getOrderDetailById } from '@apis/order'
import type { Version } from '../const'
import { OrderStatus, PayStep } from '../const'
import { useWorkspaceStore } from '@/store'
import { VersionSelect } from './VersionSelect'
import { OrderConfirm } from './OrderConfirm'
import { PayResult } from './PayResult'

const steps: StepsProps['items'] = [
  { title: '版本选择' },
  { title: '订单确认' },
  { title: '支付结果' },
]

const OrderStatusStepMap: Array<[OrderStatus[], PayStep]> = [
  [[OrderStatus.PLACE_ORDER], PayStep.OrderConfirm],
  [
    [
      OrderStatus.WAIT_PAY,
      OrderStatus.CLOSED,
      OrderStatus.AUDIT_DISMISS,
      OrderStatus.COMPLETED,
      OrderStatus.WAIT_AUDIT,
      OrderStatus.WAIT_UPLOAD_CERT,
    ],
    PayStep.PayResult,
  ],
]

function getOrderStep(orderStatus: OrderStatus) {
  return (
    OrderStatusStepMap[
      OrderStatusStepMap.findIndex(v => v[0].includes(orderStatus))
    ]?.[1] ?? PayStep.VersionSelect
  )
}

export function PayPanel() {
  const [searchParams] = useSearchParams()

  const versionCode = searchParams.get('versionCode') as Version
  const orderType = searchParams.get('orderType') as any
  const step = searchParams.get('step')

  const orderId = searchParams.get('orderId')

  const [current, setCurrent] = useState(
    step ? +step : orderId ? -1 : PayStep.VersionSelect,
  )

  const { runAsync: cancelPayApi, loading: cancelLoading } = useRequest(
    cancelPay,
    { manual: true },
  )

  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )

  const navigate = useNavigate()

  const { runAsync: getOrderApi, data } = useRequest(getOrderDetailById, {
    manual: true,
    pollingInterval: 5000,
    refreshOnWindowFocus: true,
    ready: !!searchParams.get('orderId'),
  })

  const handleOrderCallback = async (orderId: string) => {
    navigate(`/pay?orderId=${orderId}`)
    await getOrderApi(orderId)
  }

  const refresh = (data: OrderDetail) => {
    if (data) {
      const { orderStatus } = data
      const step = searchParams.get('step')
      if (step) {
        setCurrent(+step)
        return
      }
      setCurrent(getOrderStep(orderStatus as OrderStatus))
    }
  }

  const handleCancel = async (orderId: string) => {
    await cancelPayApi(orderId)
    navigate(`/workspace/${currentWorkspaceId}/settings/orders`)
  }

  useEffect(() => {
    if (data) {
      refresh(data)
    }
  }, [data])

  useEffect(() => {
    // 在这里处理 searchParams 的变化
    const searchParams = new URLSearchParams(location.search)
    const orderId = searchParams.get('orderId')

    if (step) {
      setCurrent(+step)
    }
    if (orderId) {
      getOrderApi(orderId)
    }
  }, [location.search])

  return (
    <div className='w-1000px flex flex-col items-center'>
      <Steps size='small' className='w-880px' current={current} items={steps} />
      <div className='mt-40'>
        {current === PayStep.VersionSelect && (
          <VersionSelect
            onOrderCallback={handleOrderCallback}
            version={versionCode}
            orderType={+orderType}
          />
        )}
        {current === PayStep.OrderConfirm && data && (
          <OrderConfirm
            order={data}
            onCancelOrder={handleCancel}
            cancelLoading={cancelLoading}
          />
        )}
        {current === PayStep.PayResult && data && (
          <PayResult
            order={data}
            onCancelOrder={handleCancel}
            cancelLoading={cancelLoading}
            onRefresh={getOrderApi}
          />
        )}
      </div>
    </div>
  )
}
