import { FlowStatus } from '@apis/flow/type'
import { NodeType } from '@/features/nodes/base'
import { adaptURLBodyOutput, transformCodeNodeLogsToJSON } from '../utils'
import type { FlowOutput } from '../types'
import { LoggerLabel } from './LoggerLabel'
import { StepOutput } from './StepOutput'
import { JSONEditor } from './JSONEditor'
import { LoggerCollapse } from './LoggerCollapse'

interface StepItemProps {
  flowOutput: FlowOutput
  ignoreInput?: boolean
  onEditorEnter?: () => void
  onEditorLeave?: () => void
}

export function StepItem(props: StepItemProps) {
  const {
    flowOutput,
    onEditorEnter,
    onEditorLeave,
    ignoreInput = false,
  } = props

  const { name, status, duration, real_inputs, input } = flowOutput

  const items = []

  if (!ignoreInput) {
    if (real_inputs) {
      items.push({
        key: '0',
        label: (
          <LoggerLabel
            name='输入'
            showRun={false}
            showIcon={false}
            status={FlowStatus.SUCCEEDED}
            duration={duration}
            showTime={false}
          />
        ),
        children: (
          <div className='relative'>
            <div
              className='h-[calc(100%_+_6px)] w-1px b-l-1 b-line absolute left--17px top--2px'
              onMouseEnter={onEditorEnter}
              onMouseLeave={onEditorLeave}
            ></div>
            <JSONEditor
              className='pt-10px!'
              value={JSON.stringify(adaptURLBodyOutput(real_inputs), null, 2)}
            />
          </div>
        ),
      })
    }

    items.push({
      key: '1',
      label: (
        <LoggerLabel
          name='运行参数'
          showRun={false}
          showIcon={false}
          status={FlowStatus.SUCCEEDED}
          duration={duration}
          showTime={false}
        />
      ),
      children: (
        <div className='relative'>
          <div
            className='h-[calc(100%_+_6px)] w-1px b-l-1 b-line absolute left--17px top--2px'
            onMouseEnter={onEditorEnter}
            onMouseLeave={onEditorLeave}
          ></div>
          <JSONEditor
            className='pt-10px!'
            value={JSON.stringify(adaptURLBodyOutput(input), null, 2)}
          />
        </div>
      ),
    })
  }

  if (
    flowOutput.type === NodeType.CODE &&
    Array.isArray(flowOutput?.logs) &&
    flowOutput.logs?.length > 0
  ) {
    items.push({
      key: '3',
      label: (
        <LoggerLabel
          name='日志'
          showIcon={false}
          showRun={false}
          status={FlowStatus.SUCCEEDED}
          duration={duration}
          showTime={false}
        />
      ),
      children: (
        <div className='relative'>
          <div
            className='h-[calc(100%_+_6px)] w-1px b-l-1 b-line absolute left--17px top--2px'
            onMouseEnter={onEditorEnter}
            onMouseLeave={onEditorLeave}
          ></div>
          <JSONEditor
            className='pt-10px!'
            value={transformCodeNodeLogsToJSON(flowOutput.logs || [])}
          />
        </div>
      ),
    })
  }
  items.push({
    key: '2',
    label: (
      <LoggerLabel
        name={status === FlowStatus.FAIL ? 'Error' : '输出'}
        showIcon={false}
        showRun={false}
        status={status}
        duration={duration}
        showTime={false}
      />
    ),
    children: (
      <div className='relative'>
        <div className='h-[calc(100%_+_6px)] w-1px b-l-1 b-line absolute left--17px top--2px'></div>
        <StepOutput
          flowOutput={{ ...flowOutput, hideOutputLabel: true }}
          onEditorEnter={onEditorEnter}
          onEditorLeave={onEditorLeave}
        />
      </div>
    ),
  })

  if (name === 'start' || name === 'output') {
    return (
      <StepOutput
        flowOutput={{ ...flowOutput, hideOutputLabel: true }}
        onEditorEnter={onEditorEnter}
        onEditorLeave={onEditorLeave}
      />
    )
  }
  return (
    <LoggerCollapse defaultActiveKey={['0', '1', '2', '3']} items={items} />
  )
}
