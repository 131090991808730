import type { ChangeEvent, ReactNode } from 'react'
import { memo, useMemo, useRef, useState } from 'react'
import classNames from 'classnames'
import { Input, Button, Modal, Divider } from 'antd'
import { useMemoizedFn, useRequest } from 'ahooks'
import styled from '@emotion/styled'
import { createAgentByTemplate, getTemplateList } from '@apis/authority'
import CopilotIcon from '@/assets/agent/copilot-progress-icon.svg?url'
import { useUploadFileToOss } from '@/hooks/useOssUpload'
import { wait } from '@/utils/wait'
import { IconFont } from '@/components'
import type { FileInfo } from './CopilotUpload'
import { CopilotDragger, CopilotFileInfo, CopilotUpload } from './CopilotUpload'

const { TextArea } = Input

interface GradBorderProps {
  className?: string
  children: ReactNode
}

const GradBorder = memo(({ className, children }: GradBorderProps) => {
  return (
    <div
      className={classNames(
        'flex-none rounded-8px cursor-pointer overflow-hidden relative p-2px',
        className,
      )}
    >
      <div
        className='absolute top-0 right-0 bottom-0 left-0'
        style={{
          zIndex: -1,
          background:
            'linear-gradient(299deg, #29C3F6 3%, #8C09FF 10%, #519DFA 85%, #DA60FB 100%)',
        }}
      ></div>
      <div className='h-full flex flex-center flex-col bg-white rounded-6px'>
        {children}
      </div>
    </div>
  )
})

export const TextareaWrapper = styled(TextArea)`
  &.ant-input {
    border: none;
    padding: 4px 0;
    box-shadow: none;
    border-radius: 0;
    background: transparent;
    line-height: 24px;
    min-height: 24px;
    caret-color: #7d63ff;
  }
`

interface CopilotInitProps {
  appId: string
  onStart?: (start: boolean) => void
  onFinish: () => void
}

export const CopilotInitConfig = memo(
  ({ appId, onStart, onFinish }: CopilotInitProps) => {
    const time = useRef(200)
    const parentRef = useRef<HTMLDivElement>(null)
    const [loading, setLoading] = useState(false)
    const [fileUpload, setFileUpload] = useState(false)
    const [prompt, setPrompt] = useState('')
    const [fileInfo, setFileInfo] = useState<FileInfo>()
    const [isModalOpen, setIsModalOpen] = useState(false)
    const { data: templateList } = useRequest<any[], null[]>(getTemplateList)
    const { runAsync: createAgent } = useRequest(createAgentByTemplate, {
      manual: true,
    })

    const { uploadFileToOssApi } = useUploadFileToOss()

    const handleChangeValue = useMemoizedFn(
      (event: ChangeEvent<HTMLTextAreaElement>) => {
        setPrompt(event.target.value)
      },
    )

    const handleClearFile = useMemoizedFn(() => {
      setFileInfo(undefined)
    })

    const handleUseTemplate = useMemoizedFn(async ({ prompt, files } = {}) => {
      setPrompt(prompt)
      const file = files?.[0]
      if (!file) {
        setFileInfo(undefined)
        return
      }

      setFileUpload(true)
      try {
        // 根据返回的文件地址，重新上传文件获取 id
        const fileFetch = await fetch(file.file_url)
        const blob = await fileFetch.blob()
        const formData = new FormData()
        formData.append('file', blob, file.file_name)
        const res = await uploadFileToOssApi(formData)

        setFileInfo({
          id: res.upload_file_id,
          name: file.file_name,
          size: file.size,
          type: file.file_type || file.file_name.split('.').pop(),
        })
      } catch (err) {}
      setFileUpload(false)
    })

    const handleLeave = useMemoizedFn(async () => {
      if (!parentRef.current) return
      const children = parentRef.current?.children
      for (let i = 0; i < children.length; i++) {
        const child = children[i] as HTMLElement
        child.style.transition = `.2s ${time.current * i}ms ease-out`
        child.style.opacity = '1'
      }

      await wait(10)
      for (const each of children) {
        const child = each as HTMLElement
        child.style.opacity = '0'
      }

      await wait(time.current * children.length + 100)
      onFinish()
    })

    const handleCreate = useMemoizedFn(async (skipCheck?: boolean) => {
      if (loading) return
      setLoading(true)
      onStart?.(true)
      try {
        const data = await createAgent({
          agent_id: appId,
          file_ids: fileInfo ? [fileInfo.id] : [],
          parameters: { prompt },
          is_skip_file_check: skipCheck,
        })
        if (data.is_need_file) {
          setLoading(false)
          setIsModalOpen(true)
          return
        }
        handleLeave()
      } catch (err) {
        onStart?.(false)
        console.error(err)
      }
      setLoading(false)
    })

    const handleConfirm = useMemoizedFn(() => {
      handleCreate()
    })

    const handleModalCancel = useMemoizedFn(() => {
      setIsModalOpen(false)
      handleCreate(true)
    })

    const handleModalConfirm = useMemoizedFn(() => {
      setIsModalOpen(false)
      handleCreate()
    })

    const buttonDisableStyle = useMemo(() => {
      return {
        color: '#fff',
        opacity: '0.5',
      }
    }, [])

    return (
      <div ref={parentRef} className='flex flex-col w-620px'>
        <div className='mt-32px text-center font-bold text-18px'>
          你好，你希望构建什么样的 Agent？
        </div>

        <GradBorder className='mt-24px'>
          <div className='w-full p-12px'>
            <div className='flex w-full'>
              <TextareaWrapper
                autoFocus
                autoSize={{ maxRows: 6 }}
                value={prompt}
                placeholder='描述你的需求，例如：门店导购助手，辅助店员提供服务'
                onChange={handleChangeValue}
              ></TextareaWrapper>
              <CopilotUpload
                disabled={loading}
                loading={fileUpload}
                onLoadingChange={setFileUpload}
                value={fileInfo}
                onChange={setFileInfo}
              />
            </div>

            <CopilotFileInfo fileInfo={fileInfo} onClear={handleClearFile} />
          </div>
        </GradBorder>

        <Button
          type='primary'
          className='mt-36px h-44px'
          block
          disabled={!prompt}
          onClick={handleConfirm}
          style={!prompt ? buttonDisableStyle : undefined}
          loading={loading || fileUpload}
        >
          {fileUpload ? '文件上传中' : '开始构建'}
        </Button>

        <Divider className='!mt-70' style={{ color: '#9E9E9E' }} plain>
          试试这些例子来快速构建 Agent
        </Divider>

        <div className='flex flex-center gap-16px flex-wrap'>
          {(templateList || []).map(each => {
            return (
              <Button
                key={each.id}
                className='min-w-150px h-40px !c-primary !border-primary flex flex-center !hover:bg-bg_3 !hover:bg-op-8 cursor-pointer'
                ghost
                disabled={loading}
                onClick={() => handleUseTemplate(each.form_configuration)}
              >
                {each.icon?.startsWith('http') ? (
                  <img className='w-14px mr-7px' src={each.icon} />
                ) : (
                  <IconFont className='text-14px mr-7px' name={each.icon} />
                )}
                {each.name}
              </Button>
            )
          })}
        </div>

        <Modal
          width={600}
          open={isModalOpen}
          okButtonProps={{
            disabled: !fileInfo?.id,
            style: !fileInfo?.id ? buttonDisableStyle : undefined,
          }}
          cancelText='暂不上传，直接构建'
          onCancel={handleModalCancel}
          onOk={handleModalConfirm}
        >
          <div className='flex flex-col items-center h-full relative pt-50px pb-25px'>
            <img className='h-90px' src={CopilotIcon} />

            <div className='mt-16px text-center font-bold text-16px'>
              正在为你构建问答类 Agent，建议上传你的知识文档供 Agent 学习
            </div>

            <CopilotDragger
              className='w-full h-180px mt-16px'
              value={fileInfo}
              onChange={setFileInfo}
            />
          </div>
        </Modal>
      </div>
    )
  },
)
