import { useRequest } from 'ahooks'
import dayjs from 'dayjs'
import { omit } from 'lodash-es'
import { StaticTimeTypes } from '@apis/monitor/type'
import type { MonitorDrawerProps } from '@/components/MonitorDrawer'

interface OptionsProps {
  dataKey?: string
  api: any
  defaultParams: any
  lastTimeRange: {
    start_date: string | undefined
    end_date: string | undefined
  }
  transformStatisticTime?: (value: StaticTimeTypes) => any
  transformDateString?: (value: any) => any
}

export function useStabilityDetailTable(options: OptionsProps) {
  const { api, defaultParams, lastTimeRange } = options
  const { runAsync: fetchList, data } = useRequest(api, {
    manual: true,
    defaultParams,
  })
  const onFilterChange: MonitorDrawerProps['onFilterChange'] = async value => {
    const time = options.transformStatisticTime
      ? options.transformStatisticTime(value as any)
      : {
          statistic_time: value,
        }
    const params =
      time.statistic_time === StaticTimeTypes.CUSTOM
        ? {
            ...defaultParams,
            start_date: defaultParams?.start_date ?? lastTimeRange.start_date,
            end_date: defaultParams?.end_date ?? lastTimeRange.end_date,
          }
        : omit(defaultParams, ['start_date', 'end_date'])
    await fetchList({
      ...params,
      ...time,
    })
  }

  const onRangePickerChange: MonitorDrawerProps['onRangePickerChange'] =
    async value => {
      if (value) {
        await fetchList({
          ...defaultParams,
          start_date: options?.transformDateString
            ? options?.transformDateString(dayjs(value[0])?.startOf('d'))
            : dayjs(value[0])?.startOf('d')?.format('YYYY-MM-DD HH:mm:ss'),
          end_date: options?.transformDateString
            ? options?.transformDateString(dayjs(value[1])?.endOf('d'))
            : dayjs(value[1])?.endOf('d')?.format('YYYY-MM-DD HH:mm:ss'),
        })
      }
    }

  const onSearchChange: MonitorDrawerProps['onSearchChange'] = async key => {
    await fetchList({
      ...defaultParams,
      search_content: key,
    })
  }

  const onTableChange: MonitorDrawerProps['onTableChange'] = async (
    pagination,
    _filters,
    sorter: any,
  ) => {
    const params: any = { ...defaultParams }
    if (sorter.field) {
      params.sort_field =
        sorter.field === 'usage_count' ? 'run_count' : sorter.field
      params.sort_order = sorter.order === 'ascend' ? 'asc' : 'desc'
    }
    if (pagination.current) {
      params.page = pagination.current
    }
    if (pagination.pageSize) {
      params.page_size = pagination.pageSize
    }
    await fetchList(params)
  }

  return {
    dataSource: (data as any)?.[options.dataKey || 'rows'] || [],
    total: (data as any)?.totals || 0,
    fetchList,
    onTableChange,
    onSearchChange,
    onRangePickerChange,
    onFilterChange,
  }
}
