import { useMemo } from 'react'
import { groupBy, map } from 'lodash-es'
import type { ITemplateItem } from '@apis/flow/type'
import { TEMPLATE_DISPLAYNAME } from '@/constants/common'
import { AppLogo } from '@/components'
import { NodeOptionItem } from './NodeOptionItem'

interface TemplateListProps {
  data: ITemplateItem[]
  onSelect: (id: string) => void
}

export function TemplateList(props: TemplateListProps) {
  const { data, onSelect } = props

  const groupedData = useMemo(() => {
    const res = groupBy(data, each => each.category)
    return res
  }, [data])

  return (
    <>
      <div className='p-8px line-height-16px font-500 c-#17171d'>
        {TEMPLATE_DISPLAYNAME}
      </div>
      {data.length ? (
        map(groupedData, (list, key) => {
          return (
            <div key={key}>
              <div className='py-4px px-8px line-height-16px text-12px font-500 text-#8d8d99'>
                {key}
              </div>
              {list.map(({ id, name, icon, color, description }) => (
                <NodeOptionItem
                  key={id}
                  id={id}
                  icon={
                    <AppLogo
                      type='emoji'
                      size={24}
                      className='mr-8px'
                      value={icon}
                      fillSize={16}
                      color={color}
                      imgStyle={{ borderRadius: '6px' }}
                      square
                    />
                  }
                  name={name}
                  description={description}
                  onClick={onSelect}
                />
              ))}
            </div>
          )
        })
      ) : (
        <div className='mb-10px pl-8px line-height-18px text-#8d8d99 text-op-60'>
          暂无可用模板
        </div>
      )}
    </>
  )
}
