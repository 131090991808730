import { useRequest } from 'ahooks'
import { useNavigate } from 'react-router-dom'
import { fetchTemplateList } from '@apis/application'
import { Button, IconFont } from '@/components'
import { AgentCardItem } from '@/pages/agent/list/AgentCardItem.tsx'
import { useScrollBar } from '@/hooks/useScrollBar.ts'

export default function ChatBotTemplates() {
  const navigate = useNavigate()

  const { data: templateList } = useRequest(fetchTemplateList)
  const { scrollRef } = useScrollBar()

  return (
    <div className='h-full p-y-20px p-r-20px'>
      <div className='h-full py-40px px-60px b-rd-12px bg-#f7f7fa'>
        <div
          className='w-fit m-b-12px line-height-1.4em text-24px font-900 bg-clip-text text-transparent-fill bg-gradient-to-l from-#6D50FF via-#3A92FF to-#00CBCB'
          // style={{ WebkitTextFillColor: 'transparent' }}
        >
          探索 Agent
        </div>
        <div className='c-#17171d'>
          使用这些模版，或者基于他们，构建自己的专属 Agent。
        </div>
        <div
          ref={scrollRef}
          className='flex-1 m-t-32px overflow-y-scroll'
          style={{ height: 'calc(100% - 100px)' }}
        >
          <div className='flex content-start flex-wrap adapt:gap-20'>
            {templateList?.length
              ? templateList.map(item => (
                  <div
                    key={item.id}
                    className='flex justify-center flex-items-center w-[calc(25%_-_15px)] at-md:w-[calc((100%_/_3)_-_10px)] at-sm:w-[calc(50%_-_10px)] lt-sm:w-[calc(50%_-_10px)]'
                  >
                    <AgentCardItem
                      {...item}
                      messageWrapper={
                        <div className='h-40px line-height-20px text-12px c-#8d8d99 multi-line-text'>
                          {item.description}
                        </div>
                      }
                      buttonWrapper={() => (
                        <Button
                          className='w-full'
                          type='primary'
                          size='small'
                          icon={<IconFont name='huihua' />}
                        >
                          开启会话
                        </Button>
                      )}
                      onClick={() =>
                        navigate(`/chatbot/${item.flowId}`, {
                          state: {
                            fromRoute: 'templates',
                          },
                        })
                      }
                    />
                  </div>
                ))
              : null}
          </div>
        </div>
      </div>
    </div>
  )
}
