import { Form } from 'antd'
import { useRequest } from 'ahooks'
import { useMemo } from 'react'
import styled from '@emotion/styled'
import { getAppList } from '@apis/application'
import { getFlowById } from '@apis/flow'
import { StartNodeTypes } from '../start'
import {
  NodeType,
  type NodeComponent,
  NodeOperationTypes,
} from '@/features/nodes/base'
import { CodeEditor } from '@/features/editor'
import { FormItem, Select } from '@/components'

const { Item: AntFormItem } = Form

const Item = styled(AntFormItem)`
  .ant-form-item-label {
    .ant-form-item-tooltip {
      font-size: 16px;
      color: #d8d8d8 !important;
    }
  }
  .ant-form-item-label > label {
    font-weight: 500;
    font-size: 12px;
  }
`
export interface ApplicationNodeData {
  name: string
  appId?: string // 子应用appId
}

export const ApplicationNode: NodeComponent<ApplicationNodeData> = props => {
  const { appId } = props.data
  const { data: appData } = useRequest(getAppList)
  const { data: startNodeData } = useRequest(
    () =>
      getFlowById(appId as string, {
        headers: {
          'Application-Id': appId,
        },
      }),
    {
      refreshDeps: [appId],
      ready: Boolean(appId),
    },
  )

  const appListOptions = useMemo(() => {
    const appList = appData?.list || []
    return appList.map(v => ({
      label: v.flow_name,
      value: v.flow_id,
      icon: v.icon,
    }))
  }, [appData])

  const appStartNodeType = useMemo(() => {
    return startNodeData?.config.nodes?.[0]?.data?.type
  }, [startNodeData])

  const appFormConfig = useMemo(() => {
    return startNodeData?.config.nodes?.[0]?.data?.formConfig as any[]
  }, [startNodeData])

  return (
    <div className='w-420px'>
      <div className='p-16 pb-0'>
        <Form
          layout='vertical'
          initialValues={props.data}
          requiredMark={false}
          onValuesChange={allValues => {
            props.onSaveChange(allValues)
          }}
        >
          <Item label='选择应用' name='appId'>
            <Select
              size='small'
              options={appListOptions}
              placeholder='请选择应用'
            />
          </Item>
        </Form>
      </div>
      <div className='w-100% h-1 b-line b-t-style-solid b-t-1px'></div>
      {/** form 表单渲染 */}
      <div className='p-16'>
        {appStartNodeType === 'form' && (
          <Form
            layout='vertical'
            initialValues={props.data}
            requiredMark={false}
          >
            {appFormConfig.map((v, i) => {
              return (
                <FormItem
                  label={v.label}
                  name={v.variable}
                  required={v.required}
                  key={i}
                >
                  {v.type === 'input' && (
                    <CodeEditor
                      className='ace-gray'
                      singleLine
                      placeholder={v.placeholder}
                    ></CodeEditor>
                  )}
                  {v.type === 'select' && (
                    <Select
                      placeholder={v.placeholder}
                      options={v.options as any}
                    ></Select>
                  )}
                  {v.type === 'textarea' && (
                    <CodeEditor
                      className='ace-gray'
                      placeholder={v.placeholder}
                    ></CodeEditor>
                  )}
                  {v.type === 'multiSelect' && (
                    <Select
                      placeholder={v.placeholder}
                      options={v.options as any}
                    ></Select>
                  )}
                </FormItem>
              )
            })}
          </Form>
        )}
        {/** form 表单渲染 */}

        {/** webhooks 渲染 */}
        {appStartNodeType === StartNodeTypes.Webhook && (
          <div>
            <div className='text-12px text-font mb-12'>
              以JSON格式传递给子应用的数据
            </div>
            <CodeEditor height='300px'></CodeEditor>
          </div>
        )}
        {/** webhooks 渲染 */}
      </div>
    </div>
  )
}

ApplicationNode.meta = {
  type: NodeType.APP,
  operationType: NodeOperationTypes.SINGLE_NODE,
  typeName: 'App',
  icon: 'app',
  backgroundColor: '#B87332',
  initialData: {
    name: 'app_1',
    appId: undefined,
  },
}
