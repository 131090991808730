import { useRequest } from 'ahooks'
import type { FC, PropsWithChildren } from 'react'
import { createContext } from 'react'
import { noop } from 'lodash-es'
import { useMatch } from 'react-router-dom'
import { getDataStoreDetail } from '@apis/datastore'
import type { DataStoreItem } from '@apis/datastore/type'

export const DataStoreContext = createContext<{
  dataStoreInfo?: DataStoreItem
  refresh: () => void
}>({ refresh: noop })

export const DataStoreProvider: FC<PropsWithChildren> = ({ children }) => {
  const match = useMatch('/datastores/:workspaceId/:id/:page')
  const id = match?.params.id
  const { data: dataStoreInfoData, refresh } = useRequest(getDataStoreDetail, {
    defaultParams: [Number(id)],
  })

  return (
    <DataStoreContext.Provider
      value={{
        dataStoreInfo: dataStoreInfoData?.partition,
        refresh,
      }}
    >
      {children}
    </DataStoreContext.Provider>
  )
}
