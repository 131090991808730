import { useRequest } from 'ahooks'
import { Spin, message } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { getApplicationById } from '@apis/application'
import { getInviteUserQuery, linkInviteMembers } from '@apis/authority'
import { RoleCode } from '@apis/authority/type'
import { getUser } from '@apis/user'
import inviteEmpty from '@/assets/inviteEmpty.png'
import { useLimitedAccessModal } from '../pay/LimitedAccess'
import { Button } from '@/components'
import { useWorkspaceStore } from '@/store'
import { API_CODE_CODE } from '@/constants/request'

let time: NodeJS.Timeout

export function Invite({ link_id }: { link_id?: string }) {
  const navigate = useNavigate()
  const [isInvalidation, setIsInvalidation] = useState(false)
  const [effectiveEndTime, setEffectiveEndTime] = useState<number>(0)
  const { setCurrentWorkspace } = useWorkspaceStore()

  const { data: inviteData, loading } = useRequest(getInviteUserQuery, {
    refreshDeps: [link_id],
    ready: !!link_id,
    defaultParams: [link_id!],
  })
  const { data: user } = useRequest(getUser)
  const { runAsync: getApplication } = useRequest(getApplicationById, {
    manual: true,
  })
  const { runAsync } = useRequest(
    () =>
      linkInviteMembers(
        { phones: [user?.phone!], id: link_id! },
        { ignoreError: true },
      ),
    { manual: true },
  )

  const [LimitedAccessModal] = useLimitedAccessModal({
    title: `${inviteData?.workspace_name || ''}空间户数量已至上限，无法加入`,
    content: '',
    hideShowUpdateModal: true,
  })

  useEffect(() => {
    inviteData?.effective_end_time &&
      setEffectiveEndTime(dayjs(inviteData?.effective_end_time).valueOf())
  }, [inviteData?.effective_end_time])

  useEffect(() => {
    if (effectiveEndTime === 0) return
    if (time) clearTimeout(time)
    if (effectiveEndTime > new Date().getTime()) {
      time = setTimeout(
        () => setEffectiveEndTime(effectiveEndTime - 1000),
        1000,
      )
    } else {
      setIsInvalidation(true)
    }
  }, [effectiveEndTime])

  useEffect(() => {
    return () => clearTimeout(time)
  }, [])

  const handleNavigate = async () => {
    if (inviteData?.role_code === RoleCode.VIEWER) {
      const id = inviteData.app_info_list[0]?.application_id
      const data = await getApplication(id)
      data.applicationType === 'AGENT' && navigate('/agent')
      data.applicationType === 'AI' && navigate('/application')
    } else {
      navigate('/agent')
    }
  }

  const handleInvite = async () => {
    if (!user?.phone) {
      navigate(`/login?redirect=/${location.search}?channel=ai-share`)
    } else {
      runAsync()
        .then(res => {
          handleNavigate()
          setCurrentWorkspace(res?.[0]?.workspaceId)
          message.success(`已加入${res?.[0].workspaceName}，已切换到新空间`)
        })
        .catch(err => {
          if (err.code === API_CODE_CODE.WORKSPACE_MEMBER_LIMIT_REACHED) {
            LimitedAccessModal.open()
            return
          }
          err.code === API_CODE_CODE.USER_ALREADY_EXISTS && handleNavigate()
        })
    }
  }

  if (!link_id || !inviteData) {
    return <Spin />
  }
  if (isInvalidation) {
    return (
      <div className='flex flex-col items-center w-300px text-center'>
        <img src={inviteEmpty} className='h-140px w-140px mb-15px' />
        <span className='mb-8px font-600 text-20px'>链接已过期</span>
        <span className='text-14px line-height-22px'>{`请联系需向“${inviteData.invite_user_name}”获取新链接`}</span>
      </div>
    )
  }
  return (
    <div className='flex flex-col items-center w-300px'>
      <div className='w-117px h-117px flex items-center justify-center bg-#E2DCFF border-rd-50% text-40px font-600 mb-16px'>
        {inviteData.invite_user_name?.[0]}
      </div>
      <span className='line-height-28px font-600 text-20px mb-24px text-center'>
        {inviteData.invite_user_name}邀请你加入 {inviteData.workspace_name}
      </span>
      <Button
        type='primary'
        loading={loading}
        className='w-200px mb-24px'
        onClick={handleInvite}
      >
        加入空间
      </Button>
      <span className='c-#9e9e9e text-14px'>
        此链接将在{' '}
        {dayjs(inviteData.effective_end_time).format('YYYY-MM-DD HH:mm:ss')}{' '}
        失效
      </span>
    </div>
  )
}
