import NiceModal from '@ebay/nice-modal-react'
import { useRequest } from 'ahooks'
import { useState } from 'react'
import { message } from 'antd'
import { getBatchTestTemplate, importBatchTestData } from '@apis/batch-test'
import { Button, IconFont, Modal } from '@/components'
import type { DocumentUploadProps } from '@/features/datastore/components/DocumentUpload'
import { DocumentUpload } from '@/features/datastore/components/DocumentUpload'

export interface ImportTestDataModalProps {
  flowId: string
  onConfirm?: (url: string) => void
}

function Step({ children }: { children: React.ReactNode }) {
  return (
    <div className='w-24px h-24px bg-primary text-white rounded-50% flex-center text-12px'>
      {children}
    </div>
  )
}

export function ImportTestDataInnerModal(props: ImportTestDataModalProps) {
  const { flowId, onConfirm } = props

  const modal = NiceModal.useModal()

  const [fileUrl, setFileUrl] = useState('')

  const { runAsync: getTemplate, loading: downloading } = useRequest(
    () => getBatchTestTemplate(flowId),
    {
      manual: true,
      refreshDeps: [flowId],
    },
  )

  const { runAsync: importData, loading: importLoading } = useRequest(
    () => importBatchTestData(flowId, fileUrl),
    {
      manual: true,
      refreshDeps: [flowId, fileUrl],
    },
  )

  console.log('flowId', flowId)

  const handleDownload = () => {
    getTemplate().then(url => url && window.open(url))
  }

  const onFileUploadChange: DocumentUploadProps['onChange'] = ([file]) => {
    if (file?.oss_path) {
      setFileUrl(file.oss_path)
      console.log('file', file)
    }
  }

  const handleOk = async () => {
    if (!fileUrl) {
      message.warning('请上传文件')
      return
    }
    await importData()
    onConfirm?.(fileUrl)
    modal.hide()
  }
  return (
    <Modal
      open={modal.visible}
      title='导入数据集'
      onCancel={modal.hide}
      onOk={handleOk}
      destroyOnClose
      okButtonProps={{
        loading: importLoading,
      }}
    >
      <div>
        <div className='flex justify-between items-center'>
          <div className='flex gap-16px  items-center  h-36px'>
            <Step>1</Step>
            <div className='text-16px text-font '>下载模板</div>
          </div>
          <Button
            loading={downloading}
            icon={<IconFont name='xiazai' />}
            onClick={handleDownload}
          >
            下载模板
          </Button>
        </div>
        <div className='h-8px b-l-1 b-l-line ml-12px'></div>
        <div className='flex items-center mt-12px gap-16px'>
          <Step>2</Step>
          <div className='text-16px text-font '>上传文件</div>
        </div>
        <div className='mt-16px'>
          <DocumentUpload
            hashPath='batch_test'
            namespace='flow'
            onChange={onFileUploadChange}
            placeholder='支持xls、xlsx；推荐使用【测试集模板】上传，否则会解析失败'
            fileAccept={['.xlsx', '.xls']}
            maxCount={1}
          />
        </div>
      </div>
    </Modal>
  )
}

export const ImportTestDataModal = NiceModal.create(ImportTestDataInnerModal)
