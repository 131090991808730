import { Segmented } from '@/components'

import { KnowledgeNode, type KnowledgeDataValue } from '../../KnowledgeNode'

export default (props: {
  disabled?: boolean
  onChange?: (values: KnowledgeDataValue) => void
  value?: KnowledgeDataValue
}) => {
  const { disabled, onChange, value } = props
  return (
    <>
      <div className='font-500 text-12px mb-8px'>类型</div>
      <Segmented
        block
        size='small'
        style={{ padding: 2 }}
        disabled={disabled}
        options={[
          { label: '查询知识库', value: 'searchMemory' },
          { label: '插入知识库', value: 'insertMemory' },
        ]}
        className='mb-16px'
        value={value?.memoryType}
        onChange={(e: KnowledgeDataValue['memoryType']) => {
          onChange?.({
            ...(KnowledgeNode.meta.initialData?.inputs as KnowledgeDataValue),
            memoryType: e,
            memory: undefined,
            file_id: undefined,
            fileIds: [],
            tags: [],
          })
        }}
      />
      <div
        className='h-1px w-[calc(100%+32px)] mb-16px'
        style={{
          transform: 'translate(-16px, 0)',
          background: 'rgba(225, 225, 229, 0.6)',
        }}
      />
    </>
  )
}
