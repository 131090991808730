import { LLMChannels } from '@apis/llm/model'
import type { Message } from '@/features/nodes/llm'

export enum LLMMessageStructType {
  LIKE_GPT = 'likeGpt', // 支持 system、user、assistant结构
  LIKE_BAIDU = 'likeBaidu', // 只支持 user、assistant结构
  NO_CONTEXT = 'no-context', // 不支持上下文
}
const LLMMessageStructTypeRecord: Record<string, LLMMessageStructType> = {
  [LLMChannels.GPT]: LLMMessageStructType.LIKE_GPT,
  [LLMChannels.TongYiQWen]: LLMMessageStructType.LIKE_GPT,
  [LLMChannels.ZhiPu]: LLMMessageStructType.LIKE_GPT,
  [LLMChannels.QianFan]: LLMMessageStructType.LIKE_GPT,
  [LLMChannels.XunFei]: LLMMessageStructType.LIKE_BAIDU,
  [LLMChannels.Tencent]: LLMMessageStructType.LIKE_BAIDU,
  [LLMChannels.Amazon]: LLMMessageStructType.LIKE_GPT,
  [LLMChannels.Moonshot]: LLMMessageStructType.LIKE_GPT,
  [LLMChannels.Volcano]: LLMMessageStructType.LIKE_GPT,
  [LLMChannels.Amazon_Llama]: LLMMessageStructType.NO_CONTEXT,
}

export function checkedJsonModel(model: string) {
  return [
    'gpt-4-turbo',
    'gpt-3.5-turbo',
    'gpt-3.5-turbo-16k',
    'gpt-4o',
    'gpt-4o-mini',
    'moonshot-v1-8k',
    'moonshot-v1-32k',
    'moonshot-v1-128k',
  ].includes(model)
}

export function getModelMessageStructTypeByChannel(channel: LLMChannels) {
  return LLMMessageStructTypeRecord[channel] ?? LLMMessageStructType.LIKE_BAIDU
}

export function transformLLMMessage(
  message: Message[],
  oldStructType: LLMMessageStructType,
  newStructType: LLMMessageStructType,
) {
  if (!message || !message.length) return []
  // 从百度切到GPT模型可以不兼容，因为GPT模型默认兼容百度模型
  // ps：之前下面的兼容是因为百度和GPT的system prompt 的role不一样，现在的system_prompt已经在服务端兼容了

  // if (
  //   oldStructType === LLMMessageStructType.LIKE_BAIDU &&
  //   newStructType === LLMMessageStructType.LIKE_GPT
  // ) {
  //   // 从类百度模型往GPT模型切换，只需要改prompt的role即可
  //   const newMessage = [...message]
  //   newMessage[0].role = 'system'
  //   return newMessage
  // }
  if (
    oldStructType !== newStructType &&
    newStructType === LLMMessageStructType.LIKE_BAIDU
  ) {
    /**
     * 从GPT模型往类百度模型切换，需要做以下处理：
     * 1、将prompt的role从system改为user
     * 2、消息类型需要严格按照 assistant -> user -> assistant -> user 的顺序处理，Message数量要是偶数个,并且最后一项为user
     */
    const newMessage = [...message]
    for (let i = 0; i < newMessage.length; i++) {
      if (i % 2 === 0) {
        newMessage[i].role = 'assistant'
      } else {
        newMessage[i].role = 'user'
      }
    }
    if (newMessage.length % 2 !== 0) {
      newMessage.push({
        role: 'user',
        content: '',
      })
    }
    return newMessage
  }
  return message
}

export function checkLLMMessage(message: Message[]) {
  if (message.length % 2 !== 0) return false

  // 偶数个为 assistant
  if (
    !message
      .filter((_, i) => i % 2 === 0)
      .every(item => item.role === 'assistant')
  ) {
    return false
  }

  // 奇数个为 user
  if (
    !message.filter((_, i) => i % 2 === 1).every(item => item.role === 'user')
  ) {
    return false
  }

  return true
}
