import { request } from '../../client'
import type { IUploadFileInfoRequest, IUploadFileInfoResponse } from './type'

// 上传文件信息至gemini
export function uploadFileInfo(fileInfo: IUploadFileInfoRequest) {
  return request.post<IUploadFileInfoResponse>(
    '/v1/chat/file_upload_oss',
    fileInfo,
  )
}
