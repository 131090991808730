import { Form, Modal, Popover, message, Button as AntdButton } from 'antd'
import { useState } from 'react'
import { useRequest } from 'ahooks'
import { Global, css } from '@emotion/react'
import { createHelp } from '@apis/help'
import { Button, IconFont, TextArea } from '@/components'
import productDiscussionGroup from '@/assets/productDiscussionGroup.png'
import { DOC_URL } from '@/constants/url'

function GLobalSelectStyle() {
  return (
    <Global
      styles={css`
        .productDiscussionGroup {
          .ant-popover-content {
            .ant-popover-inner {
              padding: 12px;
            }
          }
        }

        .helpPopover {
          .ant-popover-content {
            .ant-popover-inner {
              padding: 8px;
            }
          }
        }
      `}
    />
  )
}

export function HelpPopover() {
  const [openHelpPopover, setOpenHelpPopover] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [productDiscussionGroupPopover, setProductDiscussionGroupPopover] =
    useState(false)

  const [form] = Form.useForm()
  const { loading, runAsync: fetchSuggest } = useRequest(createHelp, {
    manual: true,
    onSuccess: () => {
      setOpenModal(false)
      message.success('提交成功！')
      form.resetFields()
    },
  })

  return (
    <>
      <GLobalSelectStyle />
      <Popover
        open={openHelpPopover}
        arrow={false}
        rootClassName={
          productDiscussionGroupPopover
            ? 'productDiscussionGroup'
            : 'helpPopover'
        }
        trigger='click'
        placement='topLeft'
        title={null}
        onOpenChange={setOpenHelpPopover}
        afterOpenChange={() => setProductDiscussionGroupPopover(false)}
        content={
          productDiscussionGroupPopover ? (
            <div className='flex flex-col flex-items-center text-#17171d'>
              <img
                src={productDiscussionGroup}
                className='w-136px h-136px mb-12px'
              />
              扫码加入产品讨论群
            </div>
          ) : (
            <div className='w-140px'>
              <div
                className='p-8px cursor-pointer flex flex-items-center gap-8px  hover:bg-#626999 hover:bg-opacity-8 hover:border-rd-4px'
                onClick={() => {
                  setOpenHelpPopover(false)
                  window.open(DOC_URL, '__blank')
                }}
              >
                <IconFont
                  name='question'
                  className='text-16px text-#626999 text-op-60'
                />
                帮助文档
              </div>
              <div
                onClick={() => setProductDiscussionGroupPopover(true)}
                className='p-8px cursor-pointer flex flex-items-center gap-8px  hover:border-rd-4px hover:bg-#626999 hover:bg-opacity-8'
              >
                <IconFont
                  name='chanpintaolunqun'
                  className='text-16px text-#626999 text-op-60'
                />
                产品讨论群
              </div>
              <div
                className='p-8px cursor-pointer flex flex-items-center gap-8px hover:bg-#626999 hover:bg-opacity-8 hover:border-rd-4px'
                onClick={() => {
                  setOpenModal(true)
                  setOpenHelpPopover(false)
                }}
              >
                <IconFont
                  name='fankuiyujianyi'
                  className='text-16px text-#626999 text-op-60'
                />
                反馈与建议
              </div>
            </div>
          )
        }
      >
        <AntdButton
          className='hover:!bg-white hover:!bg-opacity-60 text-0'
          type='text'
          icon={
            <IconFont
              name='question'
              className='text-18px text-#626999 text-op-60'
            />
          }
        />
      </Popover>
      <Modal
        title='反馈与建议'
        open={openModal}
        onCancel={() => setOpenModal(false)}
        footer={
          <div className='px-24px pb-24px mt--16px'>
            <Button
              loading={loading}
              type='default'
              onClick={() => setOpenModal(false)}
            >
              取消
            </Button>
            <Button
              loading={loading}
              type='primary'
              onClick={() => form.validateFields().then(fetchSuggest)}
            >
              提交
            </Button>
          </div>
        }
      >
        <Form form={form} layout='vertical'>
          <Form.Item
            className='px-8px'
            name='suggestions'
            label='留言'
            rules={[{ required: true, message: '请输入反馈与建议' }]}
          >
            <TextArea
              placeholder='请输入具体描述使用反馈与建议'
              rows={10}
              showCount
              maxLength={100}
              autoSize={false}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
