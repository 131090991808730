import { useRequest } from 'ahooks'
import type { TableColumnsType, TableProps } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import { fetchAlarmRecords } from '@apis/alarm'
import type {
  AlarmLevels,
  AlarmRecordItem,
  AlarmRuleConfigType,
  AlarmTypes,
} from '@apis/alarm/type'
import { Button, Input, Select } from '@/components'
import noRecordsImg from '@/assets/alarm/noRecords@2x.png'
import { useWorkspaceStore } from '@/store'
import { useTableBodyScroll } from '@/hooks/useTableBodyScroll'
import { getAlarmConditionText } from './util'
import {
  AlarmLevelIcons,
  AlarmTypeNames,
  AlarmTypeTargets,
  configTypeMap,
  DisabledAlarmTypes,
  SortOrderMap,
} from './constants'
import { StyledTable } from './styledComponents'

const PAGE_SIZE = 20

const levelOptions = Object.entries(AlarmLevelIcons).map(([k, v]) => ({
  label: <img className='w-44px h-20px' src={v} />,
  value: k,
}))

const ruleTypeOptions = Object.entries(configTypeMap).map(([k, v]) => ({
  label: v,
  value: k,
}))

export function AlarmRecords() {
  const [searchParams] = useSearchParams()
  const [recordId, setRecordId] = useState<string>()
  const [level, setLevel] = useState<AlarmLevels>()
  const [ruleType, setRuleType] = useState<AlarmRuleConfigType>()
  const [alarmType, setAlarmType] = useState<AlarmTypes>()
  const [page, setPage] = useState(1)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>()

  const { tableBodyHeight, tableRef } = useTableBodyScroll()

  const alarmTypeOptions = useMemo(() => {
    if (!ruleType) {
      return Object.entries(AlarmTypeNames).map(([k, v]) => ({
        label: v,
        value: k as AlarmTypes,
        disabled: DisabledAlarmTypes.includes(k as AlarmTypes),
      }))
    }
    return Object.entries(AlarmTypeNames)
      .filter(([k]) => {
        const types = AlarmTypeTargets[k as AlarmTypes]
        return types.includes(ruleType)
      })
      .map(([k, v]) => ({
        label: DisabledAlarmTypes.includes(k as AlarmTypes)
          ? `${v}(即将上线)`
          : v,
        value: k as AlarmTypes,
        disabled: DisabledAlarmTypes.includes(k as AlarmTypes),
      }))
  }, [ruleType])

  const { currentWorkspaceId } = useWorkspaceStore()

  const { data: totalRecordsData } = useRequest(
    () =>
      fetchAlarmRecords({
        page_no: 1,
        page_size: 9999,
      }),
    {
      refreshDeps: [currentWorkspaceId],
    },
  )

  const { data: recordsData, loading } = useRequest(
    params =>
      fetchAlarmRecords({
        ...params,
        alarm_rule_type: ruleType,
        alarm_type: alarmType,
        alarm_level: level,
        record_id: recordId ?? undefined,
        page_no: page,
        page_size: 20,
        sort_order: sortOrder,
      }),
    {
      refreshDeps: [
        level,
        alarmType,
        ruleType,
        recordId,
        page,
        sortOrder,
        currentWorkspaceId,
      ],
    },
  )

  const noRecords = useMemo(() => {
    return totalRecordsData ? totalRecordsData.total <= 0 : false
  }, [totalRecordsData])

  const onTableChange: TableProps<AlarmRecordItem>['onChange'] = (
    pagination,
    _,
    sorter,
  ) => {
    if (sorter && !Array.isArray(sorter)) {
      const { field, order } = sorter
      // 仅创建时间可排序
      if (
        field === 'created_at' ||
        (order && SortOrderMap[order] !== sortOrder)
      ) {
        order && setSortOrder(SortOrderMap[order])
        setPage(1)
        return
      }
    }
    if (pagination) {
      const { current } = pagination
      if (current && current !== page) {
        setPage(current)
      }
    }
  }

  const onJump = (record: AlarmRecordItem) => {
    const { alarm_rule_type, alarm_object } = record
    if (alarm_rule_type === 'Workspace') {
      window.open('/statistics/resourceStatistics')
    } else if (alarm_rule_type === 'Agent' && alarm_object?.app_id) {
      window.open(`/agent/${alarm_object.app_id}/analysis`)
    } else if (alarm_rule_type === 'AI' && alarm_object?.app_id) {
      window.open(`/application/${alarm_object.app_id}?tab=analyze`)
    }
  }

  const columns: TableColumnsType<AlarmRecordItem> = [
    {
      title: '告警等级',
      width: 100,
      key: 'alarm_level',
      dataIndex: 'alarm_level',
      render: (_, record) => {
        return (
          <img
            className='w-44px h-20px'
            src={AlarmLevelIcons[record.alarm_level]}
            alt=''
          />
        )
      },
    },
    {
      title: '告警触发时间',
      width: 180,
      key: 'created_at',
      dataIndex: 'created_at',
      sorter: true,
      render: (_, record) => {
        return dayjs(record.created_at).format('YYYY-MM-DD HH:mm:ss')
      },
    },
    {
      title: '记录ID',
      width: 240,
      key: 'record_id',
      dataIndex: 'record_id',
    },
    {
      title: '场景',
      width: 120,
      key: 'alarm_rule_type',
      dataIndex: 'alarm_rule_type',
      render: (_, record) => configTypeMap[record.alarm_rule_type],
    },
    {
      title: '告警对象',
      key: 'alarm_object',
      dataIndex: 'alarm_object',
      render: (_, record) => {
        if (record.alarm_rule_type === 'Workspace') {
          return '工作空间'
        }
        return record.alarm_object?.app_name || '-'
      },
    },
    {
      title: '告警类型',
      key: 'alarm_type',
      dataIndex: 'alarm_type',
      render: (_, record) => AlarmTypeNames[record.alarm_type],
    },
    {
      title: '触发条件',
      key: 'threshold_config',
      dataIndex: 'threshold_config',
      render: (_, record) => {
        const { threshold_config, alarm_type } = record
        return getAlarmConditionText({
          alarmType: alarm_type,
          time: threshold_config?.alarm_time || 0,
          count: threshold_config?.threshold,
          unit: !threshold_config.trigger_type
            ? '%积分'
            : threshold_config.trigger_type === 'NUMBER'
              ? '积分'
              : '%积分',
        })
      },
    },
    {
      title: '操作',
      fixed: 'right',
      width: 120,
      key: 'action',
      render: (_, record) => {
        return (
          <Button className='p-0!' type='link' onClick={() => onJump(record)}>
            查看详情
          </Button>
        )
      },
    },
  ]

  useEffect(() => {
    const queryRecordId = searchParams.get('record_id')
    if (queryRecordId) {
      setRecordId(queryRecordId)
    }
  }, [searchParams])

  return (
    <div className='p-24px h-full flex flex-col'>
      {noRecords ? (
        <div className='flex-col flex-center w-full h-full'>
          <img className='w-154px' src={noRecordsImg} alt='' />
          <div className='text-#8d8d99 tex-op-48'>暂无告警记录，运行正常</div>
        </div>
      ) : (
        <>
          <div className='mb-16px flex items-center gap-12px'>
            <Select
              className='w-200px!'
              placeholder='告警等级'
              allowClear
              options={levelOptions}
              value={level}
              onChange={val => {
                setLevel(val)
              }}
            />
            <Select
              className='w-200px!'
              placeholder='告警场景'
              allowClear
              options={ruleTypeOptions}
              value={ruleType}
              onChange={val => {
                setRuleType(val)
              }}
            />
            <Select
              className='w-200px!'
              popupMatchSelectWidth={220}
              virtual={false}
              placeholder='告警类型'
              allowClear
              options={alarmTypeOptions}
              value={alarmType}
              onChange={val => {
                setAlarmType(val)
              }}
            />
            <Input
              className='w-200px!'
              placeholder='告警记录ID'
              prefix={<SearchOutlined />}
              value={recordId}
              onChange={e => {
                setRecordId(e.target.value)
              }}
            />
          </div>
          <div ref={tableRef} className='flex-1'>
            <StyledTable
              rowKey='record_id'
              loading={loading}
              columns={columns}
              dataSource={recordsData?.data || []}
              pagination={{
                pageSize: PAGE_SIZE,
                total: recordsData?.total || 0,
                showSizeChanger: false,
              }}
              scroll={{
                x: 1800,
                y: tableBodyHeight,
              }}
              onChange={onTableChange}
            />
          </div>
        </>
      )}
    </div>
  )
}
