import { useMatch } from 'react-router-dom'
import { useMemo, useState } from 'react'
import classNames from 'classnames'
import { FlowStatus } from '@apis/flow/type'
import type { FlowOutput } from '../types'
import { parseCardData } from '../utils/parse'
import { ErrorContent } from './ErrorContent'
import { ReproduceErrorBtn } from './ReproduceErrorBtn'
import { JSONEditor } from './JSONEditor'
import { StringContent, SwitchOutputDisplay } from './StringContent'

interface StepOutputProps {
  flowOutput: FlowOutput
  onEditorEnter?: () => void
  onEditorLeave?: () => void
}

export function StepOutput(props: StepOutputProps) {
  const { flowOutput, onEditorEnter, onEditorLeave } = props
  const {
    input,
    output,
    type,
    status,
    mulitOutput = [],
    outputLabel,
    hideOutputLabel,
  } = flowOutput
  const match = useMatch('/application/:workspaceId/:appId/flow/:id')

  const isKnowledgeSkill = useMemo(() => type === 'dataset', [type])

  const isKnowledgeType = useMemo(() => {
    return (
      // 知识库调用日志
      type === 'dataset' ||
      // flow 中调用知识库日志
      (input?.memory && input?.memoryType === 'searchMemory')
    )
  }, [type, input])

  const showCardType = useMemo(() => {
    if (!isKnowledgeType || !output || typeof output !== 'string') return false

    // 这里根据 mimetype 判断是否是不合理，但上下文环境中只能获取到这个
    const cardList = parseCardData(output)
    if (cardList.some(each => !each.mimetype)) return false
    return true
  }, [type, input])

  const [showCard, setShowCard] = useState(showCardType)

  const formattedOutput = mulitOutput.length
    ? mulitOutput
    : [
        {
          label: hideOutputLabel ? '' : outputLabel || 'output',
          output,
          hideOutputLabel,
        },
      ]

  if (status !== FlowStatus.SUCCEEDED) {
    return (
      <ErrorContent
        content={flowOutput.errorMessage || ''}
        extra={
          match?.params ? null : (
            <ReproduceErrorBtn nodeName={flowOutput.name} />
          )
        }
        report={null}
      />
    )
  }

  return formattedOutput.map(
    ({ label, output: _output, hideOutputLabel }, index) => {
      return (
        <div
          key={index}
          className={classNames(
            'rounded-4px text-12px text-#5B5B5B line-height-24px',
            { 'bg-bg_3 bg-opacity-8': !showCard },
          )}
        >
          {!hideOutputLabel && !showCard && (
            <h3 className='px-12px pt-12px'>{label}:</h3>
          )}

          {typeof output === 'string' && showCardType && (
            <SwitchOutputDisplay
              className='absolute top-[-38px] right-0'
              value={showCard}
              onChange={setShowCard}
            />
          )}

          {typeof output === 'string' && (
            <StringContent
              className={classNames('relative', {
                'px-12px py-8px pt-32px': !showCard,
              })}
              content={output}
              bucketId={input?.memory || input?.partition_id}
              showCard={showCard}
              openInAgent={isKnowledgeSkill}
            />
          )}

          {typeof output !== 'string' && (
            <div onMouseEnter={onEditorEnter} onMouseLeave={onEditorLeave}>
              <JSONEditor
                value={JSON.stringify(_output ?? '', null, 2)}
                className='pt-10px!'
              />
            </div>
          )}
        </div>
      )
    },
  )
}
