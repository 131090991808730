import { useEffect, useMemo, useState } from 'react'
import type { FormInstance } from 'antd'
import { Divider, Form } from 'antd'
import { PUBLISH_MODE } from '@bty/chat-types'
import classNames from 'classnames'
import { Button, Checkbox, Input, Modal } from '@/components'
import { LinkText } from '../components/LinkText'
import { InternalSteps } from '../components/AgentGoPublishConf'
import { AgentGoFormItem } from '../components/AgentGoFormItem'
import renderLabel from '../components/renderLabel'
import { DingTalkMessageReceivingMode } from '../components/DingTalkMessageReceivingMode'
import { CopyBox } from '../components/CopyBox'
import { CheckContainer } from '../components/CheckContainer'
import { DingTalkDocsURL } from './common'

const [http, stream] = PUBLISH_MODE.dingtalk.split('|')

interface DingTalkModalProps {
  isEdit?: boolean
  form: FormInstance
  open: boolean
  onClose: () => void
  onSubmit: () => void
}

const steps = [
  {
    title: '填写应用信息',
    docsTitle: '一、新建钉钉应用',
    disabled: true,
    content: (
      <>
        <div className='text-16px font-medium text-font'>
          登录{' '}
          <LinkText href='https://open-dev.dingtalk.com/'>
            钉钉开放平台
          </LinkText>{' '}
          获取信息
        </div>
        <p className='mt-8 text-12px text-font_2 font-normal mb-24'>
          注意！需要使用<span className='text-font'>企业管理员</span>
          的钉钉账号扫码登录
        </p>
      </>
    ),
  },
  {
    title: '完成消息接收配置',
    docsTitle: '二、完成消息接受配置',
    disabled: true,
    content: (
      <>
        <div className='text-16px font-medium'>
          为应用添加机器人能力，完成消息配置并发布
        </div>
        <p className='mt-8 text-12px text-font_2 font-normal mb-24'>
          在钉钉应用开发-应用能力中可找到机器人
        </p>
      </>
    ),
    confirminfo: {
      fieldName: 'confirmOnMessage',
      title: '已完成「机器人配置」并发布',
      description: '未完成「机器人配置」将无法正常使用',
    },
  },
  {
    title: '发布钉钉应用',
    docsTitle: '三、发布钉钉应用',
    disabled: true,
    content: (
      <div className='text-16px font-medium text-font mb-24'>
        在{' '}
        <LinkText href='https://open-dev.dingtalk.com/'>钉钉开放平台</LinkText>{' '}
        版本管理中发布当前应用
      </div>
    ),
    confirminfo: {
      fieldName: 'confirmBeforeComplete',
      title: '钉钉应用已发布',
      description: '未发布的应用将无法正常使用',
    },
  },
]

const stepsLen = steps.length

export function AgentGoDingTalkModal(props: DingTalkModalProps) {
  const { open, form, onClose, onSubmit } = props

  const [currentStep, setCurrentStep] = useState(0)
  const [nextDisabled, setNextDisabled] = useState(true)

  const notStep0 = currentStep !== 0
  const step1 = currentStep === 1

  const authAppSource = Form.useWatch('auth_app_source', form)
  const isStream = authAppSource === stream

  const appKey = Form.useWatch('AppKey', form)
  const url = useMemo(
    () =>
      `${
        import.meta.env.VITE_AI_API_BASE_URL
      }/v1/webhook/agent/dingtalk_orgapp_robot/${appKey}/receive_message`,
    [appKey],
  )

  const completeDepsValues = Form.useWatch('confirmBeforeComplete', form)

  const handleStepChange = (delta: number) => {
    const current = currentStep + delta
    setCurrentStep(current)
  }

  const footer = useMemo(() => {
    return (
      <div className='flex items-center justify-between h-60px px-24px b-t-1 b-#E1E1E5 b-op-60'>
        <p className='text-12px text-#979797'>
          填写遇到问题，可参考{' '}
          <LinkText
            href={`${DingTalkDocsURL}#${encodeURIComponent(steps[currentStep].docsTitle)}`}
          >
            教程
          </LinkText>
        </p>
        <div className='flex items-center gap-12px'>
          {currentStep > 0 && stepsLen > 1 && (
            <Button
              onClick={() => {
                handleStepChange(-1)
              }}
            >
              上一步
            </Button>
          )}
          {currentStep < stepsLen - 1 ? (
            <Button
              type='primary'
              disabled={nextDisabled}
              onClick={() => {
                handleStepChange(1)
              }}
            >
              下一步
            </Button>
          ) : (
            <Button
              type='primary'
              disabled={!completeDepsValues || nextDisabled}
              onClick={onSubmit}
            >
              完成
            </Button>
          )}
        </div>
      </div>
    )
  }, [currentStep, nextDisabled, completeDepsValues])

  const values = Form.useWatch([], form)

  useEffect(() => {
    setTimeout(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          setNextDisabled(false)
        },
        () => {
          setNextDisabled(true)
        },
      )
    }, 0)
  }, [values, currentStep])

  useEffect(() => {
    if (!open) {
      setCurrentStep(0)
      setNextDisabled(true)
    }
  }, [open])

  return (
    <Modal
      title='绑定钉钉机器人'
      open={open}
      width={748}
      footer={footer}
      destroyOnClose
      closable
      styles={{
        body: {
          height: 512,
          padding: 24,
          overflow: 'auto',
        },
      }}
      onClose={onClose}
      onCancel={onClose}
    >
      <div className='mb-24px bg-#626999 bg-op-6 b-rd-8px'>
        <InternalSteps
          items={steps}
          current={currentStep}
          type='navigation'
          labelPlacement='vertical'
        />
      </div>
      {steps[currentStep].content}
      <Form
        form={form}
        layout='vertical'
        requiredMark={false}
        preserve={false}
        validateTrigger='onBlur'
      >
        <AgentGoFormItem
          label={renderLabel('Client ID', true)}
          name='AppKey'
          hidden={notStep0}
          normalize={(value: string) => value?.trim()}
          rules={[{ required: true, message: 'Client ID 不能为空' }]}
        >
          <Input
            placeholder='在钉钉应用-凭证与基础信息中获取参数'
            spellCheck={false}
          />
        </AgentGoFormItem>
        <AgentGoFormItem
          label={renderLabel('Client Secret', true)}
          name='AppSecret'
          hidden={notStep0}
          normalize={(value: string) => value?.trim()}
          rules={[{ required: true, message: 'Client Secret 不能为空' }]}
        >
          <Input
            placeholder='在钉钉应用-凭证与基础信息中获取参数'
            spellCheck={false}
          />
        </AgentGoFormItem>
        <AgentGoFormItem
          label={renderLabel('应用名称', true)}
          name='auth_app_name'
          hidden={notStep0}
          normalize={(value: string) => value?.trim()}
          rules={[{ required: true, message: '应用名称不能为空' }]}
        >
          <Input placeholder='在钉钉应用-凭证与基础信息中获取参数' />
        </AgentGoFormItem>

        <AgentGoFormItem
          label={renderLabel('消息接收模式', false)}
          name='auth_app_source'
          initialValue={http}
          hidden={!step1}
          required
        >
          <DingTalkMessageReceivingMode
          // onChange={value => setApp({ ...app, auth_app_source: value })}
          />
        </AgentGoFormItem>
        <AgentGoFormItem
          label={renderLabel('消息接收地址', false)}
          hidden={!step1 || isStream}
        >
          <CopyBox value={url} />
        </AgentGoFormItem>
        {step1 && (
          <div className='text-14px/16px font-medium mb-8'>发布机器人</div>
        )}

        <div
          className={classNames(
            'bg-bg_3 bg-op-6 rounded-8px p-12',
            steps[currentStep]?.confirminfo ? '' : 'hidden',
          )}
        >
          <Form.Item
            name={steps[currentStep]?.confirminfo?.fieldName}
            valuePropName='checked'
            preserve={true}
            hidden={!steps[currentStep]?.confirminfo}
            rules={[
              {
                validator: (_, value) => {
                  if (steps[currentStep]?.confirminfo) {
                    return value ? Promise.resolve() : Promise.reject()
                  }
                  return Promise.resolve()
                },
              },
            ]}
            noStyle
          >
            <Checkbox className='!mb-4'>
              <span className='text-font text-14px/20px'>
                {steps[currentStep].confirminfo?.title}
              </span>
            </Checkbox>
          </Form.Item>
          <p className='text-font_2 text-12px/20px ml-24'>
            {steps[currentStep].confirminfo?.description}
          </p>
        </div>

        {step1 && isStream && (
          <div className='mt-12px text-14px/16px font-medium mb-8'>
            开通权限
          </div>
        )}
        <AgentGoFormItem
          name='addEvent'
          rules={
            step1 && isStream
              ? [
                  {
                    validator: (_, value) =>
                      value
                        ? Promise.resolve()
                        : Promise.reject(
                            new Error('使用前必须确保配置事件订阅'),
                          ),
                  },
                ]
              : undefined
          }
          hidden={!step1 || !isStream}
        >
          <CheckContainer
            title='已确认开通以下权限'
            label={[
              'AI卡片流式更新权限',
              '互动卡片实例写权限',
              '企业内机器人发送消息权限',
            ]}
          />
        </AgentGoFormItem>

        {step1 && isStream && (
          <>
            <Divider />
            <div className='text-16px font-medium text-font'>
              去{' '}
              <LinkText href='https://open-dev.dingtalk.com/fe/card'>
                卡片平台
              </LinkText>{' '}
              创建 AI 卡片并关联应用
            </div>
            <p className='mt-8 text-12px text-font_2 font-normal mb-24'>
              在钉钉开放平台-开放能力中可找到卡片平台
            </p>
          </>
        )}
        <AgentGoFormItem
          label={renderLabel('卡片模版 ID')}
          name='AICardTemplateID'
          hidden={!step1 || !isStream}
          rules={[
            {
              required: step1 && isStream,
              message: '卡片模版 ID 不能为空',
            },
          ]}
        >
          <Input placeholder='在钉钉开放平台-卡片平台中获取参数' />
        </AgentGoFormItem>
      </Form>
    </Modal>
  )
}
