import { Dropdown } from 'antd'
import classNames from 'classnames'
import { useMemo, useState } from 'react'
import dayjs from 'dayjs'
import type { RangePickerProps } from 'antd/es/date-picker'
import { StaticTimeTypes } from '@apis/monitor/type'
import { IconFont, RangePickerWrapper } from '@/components'

const TimeRangeMap: Partial<Record<StaticTimeTypes, string>> = {
  // cumulative: '累计',
  monthly: '本月',
  quarterly: '本季度',
  yearly: '本年',
  custom: '自定义',
}

const DEFAULT_CUSTOM_DATE: RangePickerProps['value'] = [
  dayjs().subtract(8, 'day'),
  dayjs().subtract(1, 'day'),
]

interface AnalyzeTimeFilterProps {
  onTypeChange: (data: {
    type: StaticTimeTypes
    startDate?: string
    endDate?: string
  }) => void
  dafaultType?: StaticTimeTypes
  titleClassName?: string
  suffixText?: string
}

export function AnalyzeTimeFilter(props: AnalyzeTimeFilterProps) {
  const { onTypeChange } = props
  const [type, setType] = useState<keyof typeof TimeRangeMap>(
    () => props?.dafaultType || StaticTimeTypes.YEARLY,
  )

  const [dates, setDates] =
    useState<RangePickerProps['value']>(DEFAULT_CUSTOM_DATE)

  const title = useMemo(() => {
    if (type === 'custom') {
      return TimeRangeMap[type]
    }
    return `${TimeRangeMap[type]}${props?.suffixText || '用量'}`
  }, [type])

  return (
    <div
      className={classNames('flex items-center', {
        '-mt-8 -mb-8': type === StaticTimeTypes.CUSTOM,
      })}
    >
      <Dropdown
        overlayClassName='w-125px'
        trigger={['click']}
        menu={{
          rootClassName: 'p-4px!',
          items: Object.entries(TimeRangeMap).map(([k, v]) => ({
            className: 'ccdd',
            label: (
              <div className='h-36px line-height-36px px-8px b-rd-6px hover:bg-#7b69ff hover:bg-op-8'>
                {v}
              </div>
            ),
            key: k,
          })),
          onClick: ({ key }) => {
            setType(key as any)
            onTypeChange({
              type: key as any,
              ...(key === StaticTimeTypes.CUSTOM
                ? {
                    startDate: dates?.[0]?.toISOString(),
                    endDate: dates?.[1]?.toISOString(),
                  }
                : {}),
            })
          },
        }}
      >
        <div className='flex items-center cursor-pointer'>
          <span
            className={classNames(
              'text-#17171d text-16px font-600',
              props?.titleClassName,
            )}
          >
            {title}
          </span>
          <IconFont name='yongqi211' className='ml-4px' />
        </div>
      </Dropdown>
      {type === StaticTimeTypes.CUSTOM && (
        <RangePickerWrapper
          className='ml-8px'
          value={dates}
          onChange={dates => {
            if (dates) {
              setDates(dates)
              onTypeChange({
                type: StaticTimeTypes.CUSTOM,
                startDate: dates[0]?.toISOString(),
                endDate: dates[1]?.toISOString(),
              })
            }
          }}
        />
      )}
    </div>
  )
}
