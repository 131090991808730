import { useAuth } from '@bty/react-auth'
import { Global, css } from '@emotion/react'
import { useRequest } from 'ahooks'
import { Popover, message } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { getApplicationsList } from '@apis/application'
import { getWorkspaceUsers, inviteMembers } from '@apis/authority'
import type { InviteMembersRequestBody } from '@apis/authority/type'
import { RoleCode } from '@apis/authority/type'
import { updateMemberInfo, workspaceCheckMember } from '@apis/workspace'
import { Button, IconFont, RoleAccess, UserSearchTable } from '@/components'
import { useLimitedModal } from '@/features/pay'
import { useComboRequest } from '@/hooks/useComboRequest'
import { useVersionStore, useWorkspaceStore } from '@/store'
import type { ModalProps } from '../util'
import { InviteContent } from './InviteContent'
import { PopoverShareApp } from './PopoverShareApp'
import type { usersItem } from './UserSelect'
import UserSelect from './UserSelect'

function GlobalPopoverStyle() {
  return (
    <Global
      styles={css`
        .invitePopover {
          z-index: 999;
          .ant-popover-inner {
            overflow: hidden;
            padding: 0px;
          }
        }
        .invitePopupClassName {
          .ant-select-item {
            padding: 0 !important;
            .ant-select-item-option-state {
              padding: 0 10px;
            }
          }
        }
        .selectAccessDropdown {
          .ant-dropdown-menu-root {
            border-radius: 8px;
            width: 200px;
            box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.12);
            padding: 4px !important;
          }
          .ant-dropdown-menu-item:hover {
            background: rgba(127, 105, 255, 0.08) !important;
          }
        }
      `}
    />
  )
}

export function InvitePopoverContent(
  props: ModalProps & {
    handleSetOpen?: (e: boolean) => void
    showTitle?: boolean
    showPublicLink?: boolean
    showLine?: boolean
    showTable?: boolean
    isInviteUser?: boolean
  },
) {
  const {
    isAgent = false,
    applicationId,
    isPublic,
    workspaceId,
    appOptions,
    flowId,
    title,
    showTitle = true,
    showPublicLink = true,
    showLine = false,
    showTable = true,
    isInviteUser = false,
    inviteWithoutAdmin = false,
    handleSetOpen,
    onInviteCallback,
    onUserListChange,
  } = props
  const UserSearchTableRef = useRef<any>()
  const [comboModal] = useLimitedModal()
  const {
    state: { role: authRole },
  } = useAuth()
  // const userRoleCode = useWorkspaceStore(
  //   state => state.currentWorkspaceItem?.roleCode,
  // )
  const [applicationOptions, setApplicationOptions] = useState<
    { value: string; label: string; applicationType: string }[]
  >([])
  const versionInfo = useVersionStore(state => state.versionInfo)
  const [users, setUsers] = useState<usersItem[]>([]) // 当前空间的用户列表
  const [selectedUsers, setSelectedUsers] = useState<number[]>([])
  const [role, setRole] = useState<RoleCode>(
    inviteWithoutAdmin ? RoleCode.DEVELOPER : RoleCode.VIEWER,
  )
  const [appIds, setAppIds] = useState<string[]>(
    applicationId ? [applicationId] : [],
  )
  const workspace_id = useWorkspaceStore(state => state.currentWorkspaceId)
  const [open, setOpen] = useState(false)

  const { runAsync: fetchAppList } = useRequest(
    () => getApplicationsList(workspaceId!),
    { manual: true },
  )
  const { runAsync: fetchGetWorkspaceUsers, data: originUserList } = useRequest(
    getWorkspaceUsers,
    { manual: true, onSuccess: res => res && setUsers(res) },
  )
  const { runAsync: inviteUsers, loading: isInviting } = useComboRequest(
    async e => {
      const data = await inviteMembers(e)
      await updateMemberInfo({ workspace_id, target_role_code: e.role })
      return data
    },
    { manual: true },
  )

  const onInvite = async () => {
    const phones = selectedUsers
      .map(id => users.find(item => item.userId === id)?.phone || id)
      .filter(item => !!item) as string[]
    await workspaceCheckMember({ targetRoleCode: role, phone: phones })
    const params: InviteMembersRequestBody = {
      workspaceId,
      phones,
      applicationIds: appIds,
      applicationType: 'AI',
      role,
      url: `templates?workspaceId=${workspaceId}`,
    }
    const res = await inviteUsers(params)
    if (res) {
      message.success('邀请成功')
      setSelectedUsers([])
      !applicationId && setAppIds([])
      onInviteCallback?.()
      fetchGetWorkspaceUsers({ applicationType: 'AI', workspaceId })
      UserSearchTableRef.current?.refreshMemberList?.()
    }
  }

  const lastInviteNum = useMemo(() => {
    return (
      (versionInfo?.total_user_count_limit || 0) +
      (versionInfo?.dosage_package_user_count_total || 0) -
      (versionInfo?.exist_user_count || 0)
    )
  }, [versionInfo, role])

  const inviteStr = useMemo(
    () =>
      lastInviteNum <= 0 ? (
        <>
          还可邀请用户：0个（
          <span
            className='cursor-pointer c-primary underline font-500 mx-4px'
            onClick={() => {
              comboModal.open?.({ role: authRole })
              handleSetOpen?.(false)
            }}
          >
            升级版本
          </span>
          可以邀请更多用户 ）
        </>
      ) : (
        `还可邀请用户：${
          lastInviteNum >= 9999 ? '无限制' : `${lastInviteNum}个`
        }`
      ),
    [
      lastInviteNum,
      authRole,
      comboModal,
      setOpen,
      versionInfo?.product_code,
      inviteWithoutAdmin,
    ],
  )
  useEffect(() => {
    lastInviteNum <= 0 && setSelectedUsers([])
  }, [lastInviteNum])

  useEffect(() => {
    appOptions && setApplicationOptions(appOptions)
    !appOptions &&
      fetchAppList().then(
        res =>
          res &&
          setApplicationOptions(
            res.map(item => ({
              label: item.name,
              value: item.id,
              applicationType: item.applicationType,
            })),
          ),
      )
  }, [appOptions])

  useEffect(() => {
    if (open) {
      fetchGetWorkspaceUsers({ applicationType: 'AI', workspaceId })
    } else {
      setSelectedUsers([])
    }
  }, [open])

  return (
    <div className='w-600px'>
      {showTitle && (
        <div
          className='flex justify-between flex-items-center p-20px'
          style={{ borderBottom: '1px solid rgba(225, 225, 229, 0.6)' }}
        >
          <div className='text-16px font-600'>{title || '邀请用户'}</div>
          <div className='flex flex-items-center gap-12px'>
            <IconFont
              onClick={() => handleSetOpen?.(false)}
              name='guanbi'
              className='text-16px cursor-pointer'
            />
          </div>
        </div>
      )}
      <div className='px-32px pt-24px'>
        <InviteContent
          role={role}
          setRole={setRole}
          inviteStr={inviteStr}
          lastInviteNum={lastInviteNum}
          isInviteUser={isInviteUser}
          inviteWithoutAdmin={inviteWithoutAdmin}
          applicationId={applicationId}
          applicationOptions={appOptions || applicationOptions}
          appIds={appIds}
          setAppIds={setAppIds}
        />
        <div className='flex flex-items-start mb-24px'>
          <div className='relative flex items-center flex-1'>
            <IconFont
              name='search'
              className='absolute top-10 left-12 text-16px'
            />
            <UserSelect
              role={role}
              applicationId={applicationId}
              originUserList={originUserList}
              lastInviteNum={lastInviteNum}
              selectedUsers={selectedUsers}
              setSelectedUsers={setSelectedUsers}
              users={users}
              setUsers={setUsers}
            />
          </div>
          <Button
            className='m-l-6px w-90px'
            type='primary'
            disabled={selectedUsers.length <= 0}
            loading={isInviting}
            onClick={onInvite}
          >
            添加
          </Button>
        </div>
        {showLine && (
          <div
            className='h-1px mb-24px'
            style={{ background: 'rgba(225, 225, 229, 0.6)' }}
          ></div>
        )}
        {showTable && (
          <UserSearchTable
            ref={UserSearchTableRef}
            type='PopoverShare'
            applicationId={applicationId}
            onListChange={onUserListChange}
            role={role}
          />
        )}
      </div>
      {applicationId && showPublicLink && (
        <div
          className='px-32px py-24px'
          style={{ background: 'rgba(98, 105, 153, 0.06)' }}
        >
          <RoleAccess roles={[RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER]} hide>
            <PopoverShareApp
              isAgent={isAgent}
              isPublic={isPublic}
              flowId={flowId}
              role={role}
              applicationId={applicationId}
              workspaceId={workspace_id}
              onCopy={() => {
                message.success('复制成功')
              }}
            />
          </RoleAccess>
        </div>
      )}
    </div>
  )
}

export function InvitePopover(props: ModalProps) {
  const [open, setOpen] = useState(false)
  const { onClose, openInvitePopover, setOpenInvitePopover } = props
  const handleSetOpen = (e: boolean) => {
    setOpen(e)
    setOpenInvitePopover?.(e)
    !e && onClose?.()
  }

  return (
    <>
      <GlobalPopoverStyle />
      <Popover
        arrow={false}
        trigger='click'
        onOpenChange={handleSetOpen}
        open={open || openInvitePopover}
        placement='bottomRight'
        overlayClassName='invitePopover'
        content={
          <InvitePopoverContent {...props} handleSetOpen={handleSetOpen} />
        }
      >
        <div onClick={() => handleSetOpen(!open)}>{props?.children}</div>
      </Popover>
    </>
  )
}
