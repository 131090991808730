import styled from '@emotion/styled'
import { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { Divider, message } from 'antd'
import { linkInviteMembers } from '@apis/authority'
import { POINTS_CONSUME_NAME } from '@/constants/commercialization'
import { Segmented } from '@/components'
import { useUserStore, useWorkspaceStore } from '@/store'
import { defaultRouterPath, PRODUCT_BASE_NAME } from '@/constants/common'
import { PrivateDeployGuard } from '@/features/guard/PrivateDeployGuard.tsx'
import { VerifyLoginForm } from './components/VerifyLoginForm'
import { PasswordLoginForm } from './components/PasswordLoginForm'
import { PasswordResetModal } from './components/PasswordResetModal'
import { useUpdateSourceForNewUsers } from './hooks/useUpdateSourceForNewUsers'

const StyledDesc = styled.span`
  background: linear-gradient(270deg, #ff602b 0%, #ff8f17 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-weight: 500;
`

const StyledSegmented = styled(Segmented)`
  padding: 2px;
  .ant-segmented-item {
    padding-top: 10px;
    padding-bottom: 10px;
    .ant-segmented-item-label {
      font-size: 14px;
    }
  }
`

export enum LoginType {
  VERIFYCODE = 'verifyCode',
  PASSWORD = 'password',
}

const options = [
  {
    value: LoginType.VERIFYCODE,
    label: '验证码登录',
  },
  {
    value: LoginType.PASSWORD,
    label: '密码登录',
  },
]

export function LoginOrRegister({
  noRedirect,
  onSuccess,
}: {
  noRedirect?: boolean
  onSuccess?: () => void
}) {
  const [open, setOpen] = useState(false)
  const [loginType, setLoginType] = useState(LoginType.VERIFYCODE)
  const { fetchUser } = useUserStore()
  const { fetchWorkspaceList, setCurrentWorkspace } = useWorkspaceStore()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { updateSource } = useUpdateSourceForNewUsers()

  // 分享链接邀请用户登录
  const InviteUserLogin = async () => {
    const link_id = searchParams.get('link_id')
    if (link_id) {
      try {
        const res = await linkInviteMembers({ id: link_id })
        message.success(`已加入${res?.[0].workspaceName}，已切换到新空间`)
        return res?.[0].workspaceId
      } catch (error) {}
    }
  }

  const onLoginSuccess = async () => {
    const inviteWorkspaceId = await InviteUserLogin()
    const [workspaceList, user] = await Promise.all([
      fetchWorkspaceList(),
      fetchUser(),
    ])
    if (inviteWorkspaceId) {
      setCurrentWorkspace(inviteWorkspaceId)
    }
    await updateSource(user!)
    onSuccess?.()
    if (!workspaceList.length) {
      setSearchParams({ type: 'workspace' })
    } else {
      if (noRedirect) {
        return
      }
      const redirect = searchParams.get('redirect')
      const innerRedirect =
        !redirect || redirect === '/' ? defaultRouterPath : redirect
      navigate(innerRedirect)
    }
  }

  const onResetSuccess = () => {
    message.success('密码重置成功')
    setOpen(false)
  }

  return (
    <div className='w-400px'>
      <h3 className='text-center text-28px font-800 text-#17171d'>
        欢迎登录{PRODUCT_BASE_NAME}
      </h3>
      <div className='mt-20px mb-40px text-center text-14px text-#17171d text-op-80'>
        <PrivateDeployGuard>
          注册即享
          <StyledDesc className='ml-4px'>1000 {POINTS_CONSUME_NAME}</StyledDesc>
          <Divider type='vertical' />
          平台<StyledDesc className='mx-4px'>全功能</StyledDesc>限时体验
        </PrivateDeployGuard>
      </div>
      <StyledSegmented
        className='mb-50px'
        block
        size='large'
        value={loginType}
        options={options}
        onChange={type => setLoginType(type as LoginType)}
      />
      {loginType === LoginType.VERIFYCODE && (
        <VerifyLoginForm onSuccess={onLoginSuccess} />
      )}
      {loginType === LoginType.PASSWORD && (
        <PasswordLoginForm
          onSuccess={onLoginSuccess}
          onForgetPwd={() => setOpen(true)}
        />
      )}
      <PasswordResetModal
        open={open}
        onClose={() => setOpen(false)}
        onSuccess={onResetSuccess}
      />
    </div>
  )
}
