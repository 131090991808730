import { request } from '../../client'
import type {
  executeDetailres,
  nodeConfigType,
  singleExecuteParams,
  singleExecuteType,
  SkillFlowViewMode,
  logType,
  scheduledTaskDetailType,
  timRuning,
  ScheduledTaskOptionsType,
  scheduledType,
  scheduledTaskLog,
} from './type'

// 下载模版
export function generateExcel(flow_id: string) {
  return request.get<string>(`/v1/execute/batch/generate_excel/${flow_id}`)
}

// 批量运行
export function batchExecute(body: { flow_id: string; file_url: string }) {
  return request.post(
    `/v1/execute/batch/${body.flow_id}`,
    { ...body },
    {
      timeout: 1000 * 60 * 60 * 24,
      ignoreError: true,
      ignoreShowLimitedModal: true,
    },
  )
}

// 批量运行结果
export function executeDetail(flow_id: string) {
  return request.get<executeDetailres>(`/v1/execute/batch/detail/${flow_id}`)
}

// 停止运行
export function executeCancel(body: { flow_id: string }) {
  return request.post(`/v1/execute/batch/cancel/${body.flow_id}`)
}

// 重置批量任务
export function batchResetting(body: { flow_id: string }) {
  return request.post(`/v1/execute/batch/resetting/${body.flow_id}`)
}

// API运行详情
export function APIdetail(flow_id: string) {
  return request.get<nodeConfigType['form_config']>(
    `/v1/execute/rest-api/detail/${flow_id}`,
  )
}

// 单次运行
export function singleExecute(body: singleExecuteParams, workspaceId?: string) {
  return request.post<singleExecuteType>('/v1/flow/execute', body, {
    headers: workspaceId ? { 'workspace-id': workspaceId } : {},
  })
}

// 获取首节点formConfig配置
export function getFirstNodeConfig({
  version_id,
  flow_id,
  isDraft,
  workspace_id,
}: {
  flow_id: string
  version_id?: string
  isDraft?: boolean
  workspace_id?: string
}) {
  return version_id
    ? getFirstNodeConfigByVersion({
        flow_id,
        version_id,
        isDraft,
      })
    : request.get<nodeConfigType>(
        `/v1/flow/query_form_config/${flow_id}?${
          isDraft ? 'is_draft=true' : ''
        }`,
        {
          headers: workspace_id ? { 'workspace-id': workspace_id } : {},
        },
      )
}

export function getFirstNodeConfigByVersion({
  version_id,
  flow_id,
}: {
  flow_id: string
  version_id?: string
  isDraft?: boolean
}) {
  return request.get<nodeConfigType>(
    `/v1/flow/version_form_config?flow_id=${flow_id}${`&version_id=${version_id}`}`,
  )
}

export interface FLowFormConfigType {
  flow_id: string
  skill_desc: string
  inputs?: Array<{
    key: string
    description: string
    default_value?: string
    required: boolean
    field_type: string
  }>
  inputs_properties?: {
    inputsViewMode: SkillFlowViewMode
    formWelcome: string
  }
}

export function saveFlowFormConfig(values: FLowFormConfigType) {
  return request.post('/v1/flow/save_skill_desc', values)
}

// 获取日志
export function getLog(body: {
  flowId: string
  pageNo: number
  pageSize: number
  runStatus?: string
  runTypes?: string[]
  startTime?: string | null
  endTime?: string | null
  userName?: string
}) {
  return request.post<logType>('/v1/flow/query_all_execute_log', body)
}

// 启停应用
export function enableFlow(flowId: string, enable: boolean) {
  return request.put<logType>(`/v1/flow/${flowId}/enable?enable=${enable}`)
}

// 定时任务运行详情
export function scheduledTaskDetail(task_id: string, flow_id: string) {
  return request.get<scheduledTaskDetailType>(
    `/v1/execute/scheduled/task/result/${task_id}?flow_id=${flow_id}`,
  )
}

// 保存定时任务
export function saveScheduledTask(body: timRuning) {
  return request.post<any>('/v1/execute/scheduled/task', body)
}

// 获取定时任务配置查询
export function getScheduledTaskOptions(task_id: string) {
  return request.get<ScheduledTaskOptionsType>(
    `/v1/execute/scheduled/task/${task_id}`,
  )
}

// 编辑定时任务
export function setScheduledTaskOptions(body: timRuning, task_id: string) {
  return request.put<any>(`/v1/execute/scheduled/task/${task_id}`, body)
}

// 删除定时任务
export function deleteScheduledTask(task_id: string) {
  return request.delete<any>(`/v1/execute/scheduled/task/${task_id}`)
}

// 停止或启用定时任务
export function openOrStopScheduledTask(state: any, task_id: string) {
  return request.put<any>(
    `/v1/execute/scheduled/task/stop/${task_id}?state=${state}`,
  )
}

// 分页查询定时任务
export function getScheduledTaskList(flow_id: string, name = '') {
  return request.get<scheduledType[]>(
    `/v1/execute/scheduled/task/page/${flow_id}?name=${name}`,
  )
}

// 分页查询定时日志
export function getScheduledTaskLogList(
  task_id: string,
  flow_id: string,
  page_no: number,
) {
  return request.get<{
    page_no: number
    total: number
    result_log: scheduledTaskLog[]
  }>(`/v1/execute/scheduled/task/result_log/${task_id}`, {
    query: { flow_id, page_no, page_size: 20 },
  })
}
