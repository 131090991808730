import { Empty, Spin } from 'antd'
import classNames from 'classnames'
import type { BaseParagraphItem } from '@apis/datastore/type'
import EmptyImage from '@/assets/dataset/empty@2x.png'

import { IconFont } from '@/components'
import MarkdownStyle from '@/pages/datastores/components/MarkdownStyle.tsx'
import { useScrollBar } from '@/hooks/useScrollBar.ts'
import ParagraphPreview from './paragraph/ParagraphListItem/components/ParagraphPreview'

interface HitListProps {
  list: BaseParagraphItem[]
  loading: boolean
}

export default function SplitPreviewList(props: HitListProps) {
  const { list, loading } = props
  const { scrollRef } = useScrollBar()

  return (
    <div className='relative h-full overflow-hidden flex-1 flex flex-col'>
      <MarkdownStyle />
      {!loading && (
        <div className='py-19 px-24 z-1 bg-bg w-full'>
          <h3 className='font-600 text-18px'>分段预览效果（预览3段）</h3>
        </div>
      )}
      <div
        className={classNames(
          'bg-#000 bg-op-1 w-full h-full absolute top-0 left-0 items-center justify-center hidden',
          {
            '!flex': loading,
          },
        )}
      >
        <Spin />
      </div>
      <div
        ref={scrollRef}
        className='flex-1 pb-20 h-full overflow-y-auto px-24 '
      >
        <div className='flex flex-col gap-20'>
          {!loading && list.length > 0
            ? list.map(item => <ItemComp item={item} key={item.chunk_id} />)
            : !loading && (
                <div className='w-full h-full flex-center mt--35px'>
                  <Empty
                    description={
                      <span className='text-font_1'>暂无可预览数据</span>
                    }
                    image={EmptyImage}
                    imageStyle={{
                      width: 128,
                      height: 128,
                    }}
                  />
                </div>
              )}
        </div>
      </div>
    </div>
  )
}

function ItemComp(props: { item: BaseParagraphItem }) {
  const { item } = props

  return (
    <div className='rounded-8px py-20 bg-white b-1 b-white'>
      <div className='flex-center-between px-20 gap-8'>
        <IconFont
          name='paragraph'
          className='c-bg_3 c-op-32 text-16px font-bold'
        />
      </div>
      <div className='mt-12 editor-wrap betteryeah-markdown px-20px'>
        <ParagraphPreview value={item.content} />
      </div>
    </div>
  )
}
