import { useEffect, useMemo, useRef, useState } from 'react'
import { message } from 'antd'
import type { RcFile } from 'antd/lib/upload'
import classNames from 'classnames'
import { useRequest } from 'ahooks'
import { getUploadFileInfo } from '@apis/flow'
import type { ISupportFileConfig } from '@apis/flow/type'
import { getFileIconByMimetype } from '@/features/datastore/utils'
import { IconFont } from '@/components'
import FileUpload from './Upload'

const loadingImg = 'https://resource.bantouyan.com/betteryeah/chat/circle.png'

// const allowFileMimeTypes = [
//   fileTypeMimeMap.docx,
//   fileTypeMimeMap.doc,
//   fileTypeMimeMap.pdf,
//   fileTypeMimeMap.xlsx,
//   fileTypeMimeMap.md,
//   fileTypeMimeMap.txt,
//   fileTypeMimeMap.jpg,
//   fileTypeMimeMap.png,
//   fileTypeMimeMap.ppt,
//   fileTypeMimeMap.pptx,
//   fileTypeMimeMap.mp3,
//   fileTypeMimeMap.wav,
//   fileTypeMimeMap.aac,
//   fileTypeMimeMap.ogg,
//   fileTypeMimeMap.webm,
//   fileTypeMimeMap.m4a,
//   fileTypeMimeMap.wma,
//   fileTypeMimeMap.amr,
//   fileTypeMimeMap.flac,
//   fileTypeMimeMap.mp4,
// ]

const allowFileType = [
  '.docx',
  '.doc',
  '.pdf',
  '.xlsx',
  '.md',
  '.txt',
  '.jpg',
  '.png',
  '.ppt',
  '.pptx',
  '.mp3',
  '.wav',
  '.aac',
  '.ogg',
  '.webm',
  '.m4a',
  '.wma',
  '.amr',
  '.flac',
  '.mp4',
]

interface IFile {
  name?: string
  size?: number
  type?: string
  url?: string
}

function _extractFileUploadFileId(url?: string) {
  if (!url) return
  const regex = /\/v1\/chat\/file\/([^\/]+)/
  const match = url?.match?.(regex)
  if (match && match[1]) {
    return match[1]
  } else {
    return null
  }
}

export function FlowFileUpload(props: {
  onChange?: (url: string) => void
  clear?: boolean
  disabled?: boolean
  defaultFile?: IFile
  value?: any
  syncValue?: any
  supportFileTypes?: unknown[]
  supportFileConfig?: ISupportFileConfig[]
  className?: string
  hasError?: boolean
}) {
  const {
    clear,
    disabled,
    onChange,
    defaultFile,
    value,
    syncValue,
    supportFileConfig,
    supportFileTypes: supportTypes,
    className,
    hasError,
  } = props
  const [uploading, setUploading] = useState<boolean>(false)
  const [reupload, setReUpload] = useState<boolean>(true)
  const currentRef = useRef(false)
  const supportFileText = useMemo(() => {
    // 老数据兼容
    const _supportFileConfig = Array.isArray(supportFileConfig)
      ? supportFileConfig
      : [supportFileConfig]
    return supportTypes
      ? (`请上传${_supportFileConfig?.map(i => i?.name)?.join('、')}文件` ?? '')
      : ''
  }, [supportTypes, supportFileConfig])

  const supportFileAccetps = useMemo(() => {
    if (!supportFileConfig) {
      return {
        accepts: allowFileType,
        mimeTypeSizeMap: {},
      }
    }
    // 老数据兼容
    const _supportFileConfig = Array.isArray(supportFileConfig)
      ? supportFileConfig
      : [supportFileConfig]
    const accepts: string[] = []
    const mimeTypeSizeMap: Record<string, number> = {}
    _supportFileConfig.forEach(item => {
      item.support.forEach(mimeType => {
        mimeTypeSizeMap[mimeType] = item.size ? item.size * 1024 * 1024 : 0
      })
      accepts.push(...item.support)
      accepts.push(...(item?.extension || []))
    })

    return {
      accepts,
      mimeTypeSizeMap,
    }
  }, [supportFileConfig])

  const defaultValue: any = useMemo(() => {
    if (defaultFile) {
      const initFile = defaultFile
        ? {
            url: defaultFile.url,
            name: defaultFile.name,
          }
        : undefined
      return initFile
    }
    // 发现 value 存在数组情况，服务端默认值是 []
    if (Array.isArray(value) && value.length === 0) {
      return undefined
    }
    if (value) {
      return {
        url: value,
        name: '',
      }
    }
    return undefined
  }, [defaultFile, value])

  const [file, setFile] = useState<IFile | undefined>(defaultValue)

  const { runAsync: fetchFileInfo } = useRequest(getUploadFileInfo, {
    manual: true,
    onSuccess: data => {
      console.log('fetchFileInfo', data)
      setFile({
        name: data.file_name,
        type: data.file_type,
        size: data.byte_size,
      })
    },
  })

  const fileAccept = useMemo(() => {
    return allowFileType
  }, [])

  useEffect(() => {
    if (defaultValue?.url && !currentRef.current) {
      currentRef.current = true
      if (!defaultValue?.name) {
        const upload_id = _extractFileUploadFileId(defaultValue?.url)
        if (upload_id) {
          fetchFileInfo(upload_id)
        }
      }
      setFile(
        defaultValue
          ? {
              url: defaultValue.url,
              name: defaultValue.name,
            }
          : undefined,
      )
    }
  }, [defaultValue?.url])

  useEffect(() => {
    if (syncValue === file?.url) return

    if (!syncValue) {
      setFile(undefined)
      return
    }

    const upload_id = _extractFileUploadFileId(syncValue)
    if (upload_id) {
      fetchFileInfo(upload_id)
    }
  }, [syncValue])

  const beforeUpload: any = (_file: RcFile) => {
    currentRef.current = true
    setReUpload(false)
    setUploading(true)
    return true
  }

  const onFileFail = (_: any, _content: any) => {
    setUploading(false)
    setFile(undefined)
    message.error(_content || '文件上传失败')
  }

  const onStart = (file: RcFile) => {
    setFile({
      name: file.name,
      type: file.type,
      size: file.size,
    })
  }

  const onFileOK = (file: any) => {
    setUploading(false)
    const upload_file_id = file?.upload_file_id || ''
    const fileUrl = `${
      import.meta.env.VITE_AI_API_BASE_URL
    }/v1/chat/file/${upload_file_id}`
    onChange?.(fileUrl)
    message.success('文件上传成功')
    setReUpload(true)
  }

  const onClear = (event: any) => {
    event.stopPropagation()
    setFile(undefined)
    onChange?.('')
  }

  return (
    <div className={`overflow-hidden ${className}`}>
      <FileUpload
        fileAccept={supportFileAccetps.accepts || fileAccept}
        disabled={disabled}
        onStart={onStart}
        onFail={onFileFail}
        onOk={onFileOK}
        file={[file]}
        hasError={hasError}
        mimeTypeSizeMap={supportFileAccetps?.mimeTypeSizeMap}
        beforeFileUpload={beforeUpload}
      >
        <div className='flex items-center justify-center'>
          <div
            onClick={e => {
              e.stopPropagation()
            }}
            className={classNames('mr-[16px]', { hidden: !uploading })}
          >
            <img
              className='ui-w-[16px] ui-h-[16px] ui-animate-spin'
              src={loadingImg}
            />
          </div>
          {file && (
            <div className='flex items-center flex-1'>
              <div className='flex items-center mr-auto'>
                {file?.type && (
                  <img
                    className='w-20px h-24px shrink-0 mr-6px'
                    src={getFileIconByMimetype(file?.type!)}
                    alt=''
                  />
                )}

                <p
                  className='truncate c-#17171D text-12px line-height-16px max-w-400px'
                  onClick={e => {
                    e.stopPropagation()
                  }}
                >
                  {file?.name || file?.url}
                </p>
              </div>

              {reupload && !disabled && (
                <p className='c-#7B61FF text-12px min-w-48px line-height-16px ml-16px'>
                  重新上传
                </p>
              )}

              {clear && (
                <IconFont
                  name='guanbi'
                  className='text-14px ml-8px cursor-pointer hover:bg-#efefef rounded-4px m-[-4px] p-4px box-content'
                  onClick={onClear}
                />
              )}
            </div>
          )}
          {!file && (
            <div className='py-14px'>
              <p className='text-14px line-height-14px'>
                拖拽文件到这里，或者{' '}
                <span className='text-primary'>选择文件</span>
              </p>
              {supportFileText && (
                <p className='text-12px text-[rgba(141,141,153,0.6)] mt-8 line-height-20px '>
                  格式：{supportFileText}
                </p>
              )}
            </div>
          )}
        </div>
      </FileUpload>
    </div>
  )
}
