import type { HttpDefine } from '@bty/http-client'
import { message as antdMessage } from 'antd'
import { tokenStorage } from '@/utils/storage'
import { API_STATUS_CODES, NO_AUTH_API_LIST } from './constants'
import { handleGeneralBusinessError } from './error'

export async function onError(error: Error, config: HttpDefine) {
  const { message, code, status } = error as any
  const {
    url,
    ignoreError,
    ignoreShowLimitedModal,
    noRedirect = false,
  } = config

  if (!ignoreShowLimitedModal) {
    const handled = handleGeneralBusinessError(code)
    if (handled) return
  }

  const handleNoRedirect = decodeURIComponent(window.location.search)?.includes(
    'noRedirect=true',
  )
  if (handleNoRedirect) {
    localStorage.setItem('noRedirect', 'true')
  }
  const storageNoRedirect = localStorage.getItem('noRedirect') // 邀请开发者时候不需要直接跳转到没权限的页面而是弹出申请权限的弹窗

  if (!ignoreError && storageNoRedirect !== 'true') {
    antdMessage.warning(message)
  }

  if (noRedirect || storageNoRedirect === 'true') return

  if (
    status === API_STATUS_CODES.REQUEST_NOT_AUTH &&
    !NO_AUTH_API_LIST.some(api => url?.includes(api))
  ) {
    tokenStorage.clear()
    const redirectParams = `redirect=${encodeURIComponent(
      window.location.pathname + window.location.search,
    )}`

    window.location.replace(
      `/login${
        window.location.search
          ? `${window.location.search}&${redirectParams}`
          : `?${redirectParams}`
      }`,
    )
    return
  }

  if (status === API_STATUS_CODES.APPLICATION_NOT_AUTH) {
    window.location.replace('/notAllowed')
  }
}
