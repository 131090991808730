import { useMemo } from 'react'
import styled from '@emotion/styled'
import type { HitItem } from '@apis/datastore/type'
import { DocumentType } from '@apis/datastore/model'
import { getFileIconByFileTypeOrMimeType } from '@/features/datastore/utils'
import { IconFont } from '@/components'
import { DocumentTypeMap } from '@/features/datastore/Documents'
import KeywordsView from './KeywordsView'
import { HitExtraInfo } from './HitExtraInfo'

export interface ParagraphHitCardProps {
  rate: number
  paragraph: string
  filename: string
  actionName: string
  mimetype: string
  extra_info: HitItem['extra_info']
  file_type: HitItem['file_type']
  relevance_score: HitItem['relevance_score']
  keywords: HitItem['keywords']
  level: number
  enable?: boolean
  onClick?: () => void
  matched_keywords?: string[]
}

const WrapBox = styled.div`
  .keywords-view {
    display: none;
  }

  .__act_btn {
    display: none;
  }

  &:hover {
    .keywords-view {
      display: block;
    }
    .icon {
      display: none;
    }

    .__act_btn {
      display: block;
    }
  }

  .multi-line-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 5; /* 控制文本的行数 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const ParagraphHitCard: React.FC<ParagraphHitCardProps> = props => {
  const {
    level,
    paragraph,
    filename,
    actionName,
    mimetype,
    onClick,
    extra_info,
    file_type,
    keywords,
    relevance_score,
    matched_keywords = [],
  } = props

  const renderContent = useMemo(() => {
    if (file_type === DocumentType.QA) {
      return (
        <div>
          <div className='inline-block py-3px px-4px bg-#7B61FF/12 c-#7B61FF text-12px/14px! font-500 px-4px rounded-4px'>
            问题
          </div>

          <div className='mt-4px text-14px line-height-20px font-500 c-#17171D'>
            {paragraph}
          </div>

          {Object.entries(extra_info ?? {}).map(([key, value], index) => {
            return (
              <div key={index}>
                <div className='mt-14px'>
                  <span className='inline-block py-3px px-4px bg-#38A6FF/12 c-#38A6FF text-12px/14px! font-500 px-4px rounded-4px'>
                    {key}
                  </span>
                </div>

                <div className='mt-4px text-14px line-height-20px font-500 c-#17171D'>
                  {value}
                </div>
              </div>
            )
          })}
        </div>
      )
    } else {
      return (
        <div className='flex flex-col'>
          <div className='mt-12 ml-8px text-14px line-height-20px c-#17171D h-68px multi-line-ellipsis flex-1'>
            {paragraph}
          </div>
        </div>
      )
    }
  }, [paragraph, extra_info])

  return (
    <WrapBox
      onClick={onClick}
      className='p-20 w-100% border border-solid border-line border-op-50 rounded-8px bg-white hover:shadow-[0px_8px_24px_0px_rgba(23,23,29,0.12)] flex flex-col justify-between cursor-pointer'
    >
      <div className='flex justify-between items-center'>
        <div className='flex-center-between w-full overflow-hidden gap-8 items-center'>
          <div className='flex-1 overflow-hidden'>
            <div className='flex-1 overflow-hidden h-26px flex items-center'>
              <IconFont
                name='mingzhong'
                className='c-bg_3 c-op-32 text-16px font-bold icon'
              />
              <div className='hidden keywords-view w-full'>
                <KeywordsView
                  value={keywords}
                  highlightValue={matched_keywords}
                  empty={
                    file_type === DocumentType.QA
                      ? '该问答暂无关键词'
                      : '该段落暂无关键词'
                  }
                />
              </div>
            </div>
          </div>
          <div className='shrink-0 line-height-20px'>
            <div className='flex items-end'>
              <HitExtraInfo relevance_score={relevance_score} rank={level} />
            </div>
          </div>
        </div>
      </div>
      {/* <div className='text-14px font-400 line-height-22px mt-18 max-h-66px overflow-hidden line-clamp-3 break-all'> */}
      <div className='my-14px'>{renderContent}</div>
      <div className='text-12px flex items-center justify-between'>
        <span className='flex items-center'>
          <img
            className='w-17px h-20px'
            src={getFileIconByFileTypeOrMimeType(mimetype, file_type)}
            alt=''
          />
          <span className='ml-8 mt-2 text-font_1 max-w-180px text-one-line inline-block'>
            {filename}
          </span>
          <span className='ml-8 px-6px mt-2 text-font_1 max-w-180px text-one-line inline-block b-1 b-[rgba(225,225,229,0.6)] line-height-16px text-12px rounded-4px'>
            {DocumentTypeMap[file_type]}
          </span>
        </span>
        <a className='text-primary cursor-pointer __act_btn text-14px'>
          {actionName}
        </a>
      </div>
    </WrapBox>
  )
}
