import { Button } from 'antd'
import classNames from 'classnames'
import dayjs from 'dayjs'
import { useRequest } from 'ahooks'
import { useNavigate } from 'react-router-dom'
import { getApplicationById } from '@apis/application'
import { processApproval } from '@apis/notification'
import {
  ApprovalStatus,
  PermissionNotificationType,
  type PermissionNotification,
} from '@apis/notification/type'
import { LimitedAccess } from '@/features/pay/LimitedAccess'

enum ActionType {
  USE = 1, // 申请使用权限
  DEVELOP_AND_EDIT = 3, // 申请开发编辑权限
  ADMIN = 5, // 邀请加入空间为管理员
  DEVELOP = 6, // 邀请加入空间为开发者
  VIEW = 7, // 邀请加入空间为使用者
}

interface PushNotificationListItemProps {
  className: string
  notificationId: string
  role: PermissionNotification['role']
  applyUserName: string
  approvedStatus: PermissionNotification['approved_status']
  approvedUserName: string
  workspaceName: string
  flowId: string
  flowName: string
  createAt: string
  refresh?: VoidFunction
  type?: number
  application_id: string
}

export function PushNotificationListItem({
  className,
  notificationId,
  role,
  applyUserName,
  approvedStatus,
  approvedUserName,
  workspaceName,
  flowId,
  flowName,
  createAt,
  refresh,
  application_id,
  type,
}: PushNotificationListItemProps) {
  const { runAsync: fetchApplication } = useRequest(
    () => getApplicationById(application_id),
    {
      manual: true,
    },
  )
  const navigate = useNavigate()
  const handleAction = async () => {
    const res = await fetchApplication()
    if (res?.applicationType === 'AGENT') {
      navigate(
        type === ActionType.DEVELOP_AND_EDIT
          ? `/agent/${flowId}`
          : `/chat/${flowId}`,
      )
    } else {
      navigate(
        type === ActionType.DEVELOP_AND_EDIT
          ? `/application/${res?.workspaceId}/${res?.id}/flow/${res?.id}`
          : `/application/${flowId}`,
      )
    }
  }
  let username
  let message
  let result
  let actions
  const isApplicationRecord = role === PermissionNotificationType.APPROVAL
  const isPending = approvedStatus === ApprovalStatus.PENDING
  const _approvedStatus = isPending
    ? null
    : approvedStatus === ApprovalStatus.APPROVED
      ? '同意'
      : '拒绝'
  if (isApplicationRecord) {
    username = applyUserName
    if (
      [ActionType.ADMIN, ActionType.DEVELOP, ActionType.VIEW].includes(
        type || ActionType.DEVELOP_AND_EDIT,
      )
    ) {
      message = (
        <>
          通过邀请链接加入 <span className='font-medium'>{workspaceName}</span>{' '}
          ，成为
          <span className='font-medium'>
            {type === ActionType.ADMIN && '管理员'}
            {type === ActionType.DEVELOP && '开发者'}
            {type === ActionType.VIEW && '使用者'}
          </span>
        </>
      )
    } else {
      message = (
        <>
          申请{type === ActionType.USE ? '使用' : '协同开发'}{' '}
          <span className='font-medium'>{workspaceName}</span> 的{' '}
          <span className='font-medium'>{flowName}</span>
        </>
      )
      result = isPending ? null : (
        <p className='text-12px text-font_1 mt-12'>
          {approvedUserName}已{_approvedStatus}
        </p>
      )
    }
    actions = isPending && (
      <div className='mt-8'>
        <LimitedAccess limitedType='inviteUser'>
          <Button
            className='!px-10 !rounded-8px hover:!bg-#9980ff shadow-none'
            size='small'
            type='primary'
            onClick={() =>
              processApproval(notificationId, ApprovalStatus.APPROVED).then(
                refresh,
              )
            }
          >
            同意
          </Button>
        </LimitedAccess>
        <Button
          className='!px-10 !rounded-8px border-[rgba(225,225,229,0.8)] text-font_1 ml-8 shadow-none'
          size='small'
          onClick={() =>
            processApproval(notificationId, ApprovalStatus.REJECTED).then(
              refresh,
            )
          }
        >
          拒绝
        </Button>
      </div>
    )
  } else {
    username = approvedUserName
    message = (
      <>
        {_approvedStatus}
        了你对 <span className='font-medium'>
          {flowName || workspaceName}
        </span>{' '}
        的
        {[ActionType.DEVELOP_AND_EDIT, ActionType.DEVELOP].includes(
          type || ActionType.USE,
        )
          ? '开发'
          : '使用'}
        申请
      </>
    )
    actions =
      approvedStatus === ApprovalStatus.APPROVED ? (
        <div className='mt-8'>
          <span
            className='text-12px/14px text-primary hover:text-op-40 cursor-pointer'
            onClick={handleAction}
          >
            {type === ActionType.DEVELOP_AND_EDIT && '去编辑'}
            {type === ActionType.USE && '去使用'}
          </span>
        </div>
      ) : approvedStatus === ApprovalStatus.REJECTED ? (
        <span className='inline-block c-font_1 text-12px mt-8px'>
          {approvedUserName}已拒绝
        </span>
      ) : (
        ''
      )
  }

  return (
    <li className={classNames('flex p-12', className)}>
      <div className='shrink-0 rounded-full w-36px h-36px leading-36px text-center text-14px font-medium text-#202020 bg-#e4f4fd'>
        {username?.slice(0, 1)}
      </div>
      <div className='ml-12 flex-1'>
        <p className='text-12px/16px text-font_1'>
          {username}
          <span className='ml-8'>
            {createAt ? dayjs(createAt).format('M月D日 HH:mm') : null}
          </span>
        </p>
        <p className='mt-8 text-14px/18px text-bg_dark'>{message}</p>
        {result}
        {actions}
      </div>
    </li>
  )
}
