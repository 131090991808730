import { RoleCode } from '@apis/authority/type'
import type { AuthRoute } from '@/auth'
import FlowPage from '@/pages/flowPage'
import FlowPagePreview from '@/pages/flowPage/preview'
import { ErrorElement } from '@/pages/error'

export const applicationRoutes: AuthRoute[] = [
  {
    path: '/application/:workspaceId/:appId/flow/:id/preview',
    auth(s: any) {
      return s.role([RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER])
    },
    redirect: '/notAllowed',
    errorElement: <ErrorElement />,
    element: <FlowPagePreview />,
  },
  {
    path: '/application/:workspaceId/:appId/flow/:id',
    auth(s: any) {
      const data = new URLSearchParams(window.location.search)
      const noRedirect = data.get('noRedirect')
      localStorage.setItem(
        'noRedirect',
        noRedirect === 'true' ? 'true' : 'false',
      )
      if (noRedirect) {
        return true
      }
      return s.role([RoleCode.ADMINISTRATOR, RoleCode.DEVELOPER])
    },
    redirect: '/notAllowed',
    errorElement: <ErrorElement />,
    element: <FlowPage />,
  },
]
