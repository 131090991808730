import { useMemo, useRef } from 'react'

import type { ActionTypesForNode } from '@apis/flow/type'
import {
  NodeOperationTypes,
  NodeType,
  type NodeComponent,
} from '@/features/nodes/base'
import type { JsonFormConfig } from '../components'
import { JsonForm } from '../components'
import { useFlowDraftStore, FLOW_DRAFT_LOCK_STATUS } from '@/store'

export interface TextNodeData {
  name: string
  packageName: string
  comment?: string
  inputs?: {
    textarea_value: string
  }
  actionType?: ActionTypesForNode.INTEGRATION
}

export const TextNode: NodeComponent<TextNodeData> = props => {
  const containerRef = useRef<HTMLDivElement>(null)
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const { data, variables, nodeElement } = props

  const list = useMemo<JsonFormConfig[]>(() => {
    return [
      {
        label: '内容',
        required: true,
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        name: ['inputs', 'textarea_value'],
        type: 'TextEditor',
        tooltip: '文本内容会作为当前节点的输出',
        rules: [{ required: true, message: '文本内容不能为空' }],
        componentProps: {
          minHeight: 302,
          placeholder: '输入文本内容，作为本节点的输出信息',
          variables,
          variableTipsContainer: nodeElement,
        },
      },
    ]
  }, [nodeElement, variables, containerRef.current])

  return (
    <>
      <div ref={containerRef} className='p-16px w-420px pb-0'>
        <JsonForm
          list={list}
          disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
          initialValues={{
            inputs: data.inputs,
          }}
        />
      </div>
    </>
  )
}

const TextPackageName = 'integration.text_editor.TextEditorAction'

TextNode.meta = {
  type: NodeType.TEXT,
  operationType: NodeOperationTypes.SINGLE_NODE,
  typeName: 'TEXT',
  actionType: 'INTEGRATION',
  icon: 'wenbenjiedian-1',
  description: '可以填写文本知识',
  backgroundColor: '#C7782D',
  canDelete: true,
  initialData: {
    inputs: {
      textarea_value: '',
    },
    name: 'text_1',
    packageName: TextPackageName,
  },
}
