import { memo } from 'react'
import { Form, Switch, Tooltip } from 'antd'
import { useMemoizedFn } from 'ahooks'
import NiceModal from '@ebay/nice-modal-react'
import { useNavigate } from 'react-router-dom'
import type { TaskDetail } from '@apis/task/type'
import { IconFont } from '@/components'
import { TaskModal } from '../Task/TaskModal'

interface TaskConfigProps {
  agentId: string
  versionId: string
  name: number
  value?: TaskDetail
  onChange?: (value: TaskDetail) => void
  onRemove?: (index: number) => void
  toAddFlow?: () => void
  toAddPlugin?: () => void
}

const TaskConfig = memo((props: TaskConfigProps) => {
  const {
    agentId,
    versionId,
    name,
    value,
    onChange,
    onRemove,
    toAddFlow,
    toAddPlugin,
  } = props

  const handleEdit = useMemoizedFn(() => {
    NiceModal.show(TaskModal, {
      agentId,
      versionId,
      isNew: false,
      task: value,
      toAddFlow,
      toAddPlugin,
      onConfirm: newTask => {
        onChange?.(newTask)
      },
      onDelete: () => {
        onRemove?.(name)
      },
    })
  })

  const handleUpdate = useMemoizedFn((key: string, keyValue: any) => {
    if (!value) return
    onChange?.({
      ...value,
      update_time: Date.now() / 1000,
      [key]: keyValue,
    })
  })

  return (
    <div className='h-36px bg-white border-1px border-op-80 rounded-6px flex flex-center px-10px pr-6px'>
      <div>{value?.task_name}</div>

      <div className='ml-auto flex flex-center text-[rgba(98,105,153,0.6)]'>
        <Switch
          className='mr-10px'
          size='small'
          checked={value?.preset_enable}
          onChange={checked => handleUpdate('preset_enable', checked)}
        />

        <button
          className='flex justify-center items-center w-24px h-24px mr-6px text-16px rounded-6px hover:bg-#E4E5ED'
          type='button'
        >
          <IconFont name='zaicishuru' onClick={handleEdit} />
        </button>
        <button
          className='flex justify-center items-center w-24px h-24px text-16px rounded-6px hover:bg-#E4E5ED'
          type='button'
        >
          <IconFont name='shanshu2' onClick={() => onRemove?.(name)} />
        </button>
      </div>
    </div>
  )
})

interface FormListVariableProps {
  agentId: string
  versionId: string
}

export const FormTaskList = memo((props: FormListVariableProps) => {
  const { agentId, versionId } = props

  const navigate = useNavigate()

  const onToFlow = useMemoizedFn(() => {
    navigate('flow')
  })

  const onToPlugin = useMemoizedFn(() => {
    navigate('plugin')
  })

  const handleAdd = useMemoizedFn((add: (task: TaskDetail) => void) => {
    NiceModal.show(TaskModal, {
      agentId,
      versionId,
      isNew: true,
      toAddFlow: onToFlow,
      toAddPlugin: onToPlugin,
      onConfirm: task => {
        add(task)
      },
    })
  })
  const PresetTaskTips = memo(() => {
    return <div>提前设置好任务，使用者开箱即用</div>
  })
  return (
    <Form.List name='preset_tasks'>
      {(fields, { add, remove }) => (
        <>
          <div className='flex items-center h-48px'>
            <span className='text-14px text-font font-medium'>预设任务</span>
            <Tooltip placement='top' title={<PresetTaskTips />}>
              <IconFont
                className='text-font_1 text-14px text-opacity-40 ml-4 cursor-pointer'
                name='jieshishuimeng'
              />
            </Tooltip>
            <button
              className='flex justify-center items-center w-24px h-24px ml-auto text-16px rounded-6px hover:bg-#E4E5ED'
              type='button'
              onClick={event => {
                event.preventDefault()
                handleAdd(add)
              }}
            >
              <IconFont name='fangtai' />
            </button>
          </div>

          {fields.map(({ key, name }) => {
            return (
              <div key={key} className='mb-8 last:mb-16'>
                <Form.Item name={[name]} noStyle>
                  <TaskConfig
                    agentId={agentId}
                    versionId={versionId}
                    name={name}
                    onRemove={remove}
                  />
                </Form.Item>
              </div>
            )
          })}
        </>
      )}
    </Form.List>
  )
})
