import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import mime from 'mime'
import { uploadFileInfo } from '@apis/common'
import { useUserStore } from '@/store'
import { useOSSClient } from './useOSSClient'

export function useUploadFileToOss() {
  // 勿修改oss_key 获取逻辑
  const { ossClient } = useOSSClient()
  const user = useUserStore(state => state.user)

  const filePrefixPath = `chat/${user?.userId ?? -1000}`

  const uploadFileToOssApi = async (params: Record<string, any>) => {
    const isFormData = params instanceof FormData
    const file = isFormData
      ? (params.get('file') as File)
      : (params.file as File)
    const filePath = `${filePrefixPath}/_${dayjs().valueOf()}_${uuidv4()}__${
      file.name
    }`
    if (!ossClient) {
      throw new Error('ossClient not exist')
    }
    // 使用VITE_AI_DATASTORE_OSS_BUCKET 勿修改
    ossClient.useBucket(import.meta.env.VITE_AI_DATASTORE_OSS_BUCKET)
    try {
      await ossClient?.multipartUpload(filePath, file, {})
      const file_type = file?.type || mime.getType(file?.name) || ''
      const resInfo = await uploadFileInfo({
        oss_path: filePath,
        file_type,
        file_name: file?.name,
        file_size: file?.size,
      })
      return resInfo
    } catch (e) {
      console.error(e, 'upload file fail')
      throw e
    }
  }

  return { uploadFileToOssApi }
}
