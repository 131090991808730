import { useMemo } from 'react'
import { Tooltip } from 'antd'
import { AppJoined } from '@apis/authority/type'
import defaultAvatarImg from '@/assets/user/default-avatar.png'
import { Avatar } from '@/components'

interface UserOptionProps {
  isNew?: boolean
  searchValue: string
  phone: string
  name?: string
  avatar?: string
  status?: JoinStatusEnum
  activatedStatus?: AppJoined
}

export enum JoinStatusEnum {
  noAppPermission, // 加入了工作空间但是没有在该应用下的使用者
  noJoin, // 曾经被邀请过的用户，但未加入空间的人
  joined, // 已经加入的
  nothing, // 已邀请，未注册
  otherWorkspace, // 其他空间用户，但未邀请过
  canUse, // 管理者、开发者、有此应用权限的使用者（同joined这个是在有传入应用id的情况下使用）
}

const JoinStatusStr: Record<JoinStatusEnum, string> = {
  [JoinStatusEnum.joined]: '已加入',
  [JoinStatusEnum.noJoin]: '待加入',
  [JoinStatusEnum.canUse]: '可使用',
  [JoinStatusEnum.nothing]: '',
  [JoinStatusEnum.otherWorkspace]: '',
  [JoinStatusEnum.noAppPermission]: '',
}

export function getName(name: string, status: JoinStatusEnum) {
  return `${
    status === JoinStatusEnum.otherWorkspace && name?.length > 1 ? '*' : ''
  }${status === JoinStatusEnum.otherWorkspace ? name?.slice(1) || name : name}`
}

export function UserOption(props: UserOptionProps) {
  const {
    phone,
    searchValue,
    name = '',
    avatar = defaultAvatarImg,
    status = JoinStatusEnum.nothing,
    activatedStatus = AppJoined.NOT_JOINED,
  } = props
  const matchIndex = phone.indexOf(searchValue)
  const disabled = useMemo(
    () =>
      status === JoinStatusEnum.joined ||
      status === JoinStatusEnum.noJoin ||
      status === JoinStatusEnum.canUse,
    [status],
  )
  const Wrapper = useMemo(() => {
    return (
      <div
        onClick={e => disabled && e.stopPropagation()}
        className='flex flex-nowrap flex-items-center px-12px py-5px'
        style={{
          opacity: disabled ? 0.4 : 1,
          cursor: disabled ? 'not-allowed' : 'auto',
        }}
      >
        <div className='flex flex-items-center flex-1'>
          <Avatar className='mr-8' name={name} src={avatar} />
          <div>
            {status !== JoinStatusEnum.nothing &&
              activatedStatus === AppJoined.JOINED && (
                <div className='m-b-6 font-500 text-font'>
                  {getName(name, status)}
                </div>
              )}
            <div className='text-12px text-font_1'>
              {status === JoinStatusEnum.nothing && matchIndex > -1 ? (
                <>
                  {phone.slice(0, matchIndex)}
                  <span className='text-primary'>
                    {phone.slice(matchIndex, matchIndex + searchValue.length)}
                  </span>
                  {phone.slice(matchIndex + searchValue.length)}
                </>
              ) : (
                phone
              )}
            </div>
          </div>
        </div>
        {disabled ? <div>{JoinStatusStr[status] || ''}</div> : ''}
      </div>
    )
  }, [status, phone, searchValue, name, avatar])
  return disabled ? (
    <Tooltip arrow={false} title='无需重复邀请'>
      {Wrapper}
    </Tooltip>
  ) : (
    Wrapper
  )
}
