import { Alert, Form } from 'antd'
import { useMemo, useState } from 'react'
import { useDebounceFn } from 'ahooks'
import { Handle, Position } from 'reactflow'
import { UpOutlined } from '@ant-design/icons'
import classNames from 'classnames'
import { ActionTypesForNode } from '@apis/flow/type'
import type { NodeComponent } from '../base'
import { NodeOperationTypes, NodeType } from '../base'
import type { JsonFormConfig } from '../components'
import { JsonForm } from '../components'
import { IconFont, Segmented } from '@/components'
import { DefaultHandle } from '@/features/flow/components/DefaultHandle'

import {
  FLOW_DRAFT_LOCK_STATUS,
  useFlowDraftStore,
  useFlowInteractionStore,
} from '@/store'

export enum LoopTypes {
  INCREAMENT = 'incremental',
  FOREACH = 'array',
  CONDITION = 'condition',
}

export interface LoopNodeData {
  name: string
  inputs: {
    loop_type: LoopTypes
    loop_variable?: string
    start_value?: number
    end_value?: number
    increment?: number
  }
  relation: {
    loopResultId?: string
  }
}

interface LoopNodeDataForm {
  inputs: {
    loop_type: LoopTypes
    loop_variable?: string
    start_value?: number
    end_value?: number
    increment?: number
  }
}

export const LoopNode: NodeComponent<LoopNodeData> = props => {
  const { id, data, variables, nodeElement, onSaveChange } = props
  const [form] = Form.useForm<LoopNodeDataForm>()
  const [loopType, setLoopType] = useState(data.inputs.loop_type)
  const lockStatus = useFlowDraftStore(s => s.lockStatus)

  const { dangerStateNodeIds, activeHandles } = useFlowInteractionStore()

  const handleTypeChange = useDebounceFn(
    (v: LoopTypes) => {
      const formData = form.getFieldsValue()
      const newData = {
        ...formData,
        inputs: {
          ...formData.inputs,
          loop_type: v,
        },
      }
      onSaveChange(newData)
    },
    {
      wait: 100,
    },
  )

  const list = useMemo<JsonFormConfig[]>(
    () => [
      {
        noStyle: true,
        render: () => (
          <>
            <div className='font-500 text-12px mb-8px'>循环类型</div>
            <Segmented
              block
              size='small'
              style={{ padding: 2 }}
              className='mb-16px'
              disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
              options={[
                {
                  label: '数组循环',
                  value: LoopTypes.FOREACH,
                },
                {
                  label: '递增循环',
                  value: LoopTypes.INCREAMENT,
                },
                {
                  label: '条件循环',
                  value: LoopTypes.CONDITION,
                },
              ]}
              // value={data.inputs.loop_type}
              value={loopType}
              onChange={v => {
                setLoopType(v as LoopTypes)
                handleTypeChange.run(v as LoopTypes)
              }}
            />
            <div
              className='h-1px w-[calc(100%+32px)] mb-16px'
              style={{
                transform: 'translate(-16px, 0)',
                background: 'rgba(225, 225, 229, 0.6)',
              }}
            ></div>
          </>
        ),
      },
      {
        hidden: loopType !== LoopTypes.FOREACH,
        render: () => (
          <Alert
            className='mt-8px b-0px text-12px bg-[rgba(56,166,255,0.08)] rounded-6px'
            showIcon
            icon={
              <IconFont
                name='info'
                className='c-#38A6FF!  text-14px/20px mr-4px'
              />
            }
            message={
              <p className='font-400 text-12px/16px c-#17171D'>
                数组循环最多循环20次
              </p>
            }
            type='info'
          />
        ),
      },
      {
        label: '循环变量',
        required: true,
        rules:
          loopType === LoopTypes.FOREACH
            ? [{ required: true, message: '循环变量不能为空' }]
            : [],
        name: ['inputs', 'loop_variable'],
        type: 'TextEditor',
        hidden: loopType !== LoopTypes.FOREACH,
        disabled: lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK,
        componentProps: {
          placeholder: '填写或选择需要循环的变量',
          variables,
          variableTipsContainer: nodeElement,
        },
      },
      {
        label: '初始值',
        required: true,
        rules:
          loopType === LoopTypes.INCREAMENT
            ? [{ required: true, message: '初始值不能为空' }]
            : [],
        name: ['inputs', 'start_value'],
        type: 'InputNumber',
        hidden: loopType !== LoopTypes.INCREAMENT,
        componentProps: {
          className: 'nodrag nopan',
          wheel: false,
        },
      },
      {
        label: '递增值',
        required: true,
        rules:
          loopType === LoopTypes.INCREAMENT
            ? [{ required: true, message: '递增值不能为空' }]
            : [],
        name: ['inputs', 'increment'],
        type: 'InputNumber',
        hidden: loopType !== LoopTypes.INCREAMENT,
        componentProps: {
          className: 'nodrag nopan',
          wheel: false,
        },
      },
      {
        label: '循环终值',
        required: true,
        rules:
          loopType === LoopTypes.INCREAMENT
            ? [{ required: true, message: '循环终值不能为空' }]
            : [],
        name: ['inputs', 'end_value'],
        type: 'InputNumber',
        hidden: loopType !== LoopTypes.INCREAMENT,
        componentProps: {
          className: 'nodrag nopan',
          wheel: false,
          max: 10000,
        },
      },
      {
        hidden: loopType !== LoopTypes.CONDITION,
        render: () => (
          <div className='text-12px/12px c-font_1 text-center'>
            请在终止循环节点设置终止条件
          </div>
        ),
      },
    ],
    [data, variables, nodeElement, loopType],
  )

  const iconClass = useMemo(() => {
    const isDanger = dangerStateNodeIds.includes(id)
    if (isDanger) {
      return 'c-error!'
    }
    return 'c-#A8A8AE!'
  }, [id, dangerStateNodeIds, activeHandles])

  return (
    <div className='relative p-16 pb-0 w-420px'>
      <JsonForm
        form={form}
        list={list}
        initialValues={{ ...data }}
        disabled={lockStatus !== FLOW_DRAFT_LOCK_STATUS.UNLOCK}
        beforeChange={(values: any) => {
          return {
            ...values,
            inputs: {
              ...values.inputs,
              loop_type: loopType,
            },
          }
        }}
      />
      <DefaultHandle
        nodeId={id}
        id={`loopStart_${id}`}
        type='source'
        position={Position.Right}
        style={{
          top: -22,
          right: -18,
        }}
      />
      <div>
        <UpOutlined
          className={classNames(
            'absolute bottom-[-26px] left-50% transform-translate-x-[-50%] text-10px',
            iconClass,
          )}
        />
        <div className='w-0px! h-0px! overflow-hidden'>
          <Handle
            id={`loopBoundary_${id}`}
            type='source'
            className='!w-10px !h-10px min-w-1px min-h-1px c-font_1! bg-transparent! rounded-50%!'
            position={Position.Bottom}
            style={{
              bottom: -26,
              top: undefined,
            }}
          />
        </div>
      </div>
    </div>
  )
}

LoopNode.meta = {
  type: NodeType.LOOP,
  operationType: NodeOperationTypes.LOOP_NODE,
  actionType: ActionTypesForNode.LOOP,
  typeName: '循环',
  icon: 'xunhuanjiedian',
  backgroundColor: '#387dff',
  description: '可以设置节点的循环计划',
  classNames: ['overflow-visible'],
  customSourceHandle: true,
  initialData: {
    name: 'loop_1',
    inputs: {
      loop_type: LoopTypes.FOREACH,
      start_value: 1,
      end_value: 10,
      increment: 1,
    },
    relation: {
      // loopResultId: ''
    },
  },
}
