import { create } from 'zustand'
import { mountStoreDevtool } from 'simple-zustand-devtools'
import { getVersionInfoByWorkspaceId, getVersionList } from '@apis/version'
import type { VersionConfig, VersionInfo } from '@apis/version/type'

type BotWorkspaceItemProps =
  | { workspaceId: string; developerName: string; workspaceName: string }
  | undefined

interface VersionStore {
  versionInfo?: VersionInfo
  versionConfigList: VersionConfig[]
  workspaceIdToCurrentVersionInfo: string
  currentWorkspaceId: string
  botWorkspaceItem: BotWorkspaceItemProps
  fetchVersionByWorkspaceId: (
    workspaceId: string,
  ) => Promise<VersionInfo | undefined>
  fetchVersionConfigList: () => Promise<VersionConfig[]>
  fetchVersionLoading: boolean
  setBotWorkspaceItem: (botWorkspaceItem: BotWorkspaceItemProps) => void
}

export const useVersionStore = create<VersionStore>((set, state) => ({
  versionInfo: undefined,
  versionConfigList: [],
  fetchVersionLoading: false, // 多次请求节流
  workspaceIdToCurrentVersionInfo: '', // 当前版本对应的工作空间id
  currentWorkspaceId: '', // 当前全局的工作空间id
  botWorkspaceItem: undefined,
  fetchVersionByWorkspaceId: async workspaceId => {
    if (!workspaceId) {
      return
    }
    set({ currentWorkspaceId: workspaceId })
    if (state().fetchVersionLoading) {
      return state().versionInfo
    }
    set({ workspaceIdToCurrentVersionInfo: workspaceId })
    try {
      set({ fetchVersionLoading: true })
      const versionInfo = await getVersionInfoByWorkspaceId(workspaceId)
      set({ fetchVersionLoading: false })
      set({ versionInfo })
      if (
        state()?.workspaceIdToCurrentVersionInfo !== state()?.currentWorkspaceId
      ) {
        return await state()?.fetchVersionByWorkspaceId(
          state()?.currentWorkspaceId,
        )
      } else {
        return versionInfo
      }
    } catch {
      set({ fetchVersionLoading: false })
      return state().versionInfo
    }
  },
  fetchVersionConfigList: async () => {
    const res = await getVersionList()
    set({ versionConfigList: res })
    return res
  },
  setBotWorkspaceItem: (botWorkspaceItem: BotWorkspaceItemProps) =>
    set({ botWorkspaceItem }),
}))

if (import.meta.env.MODE === 'development') {
  mountStoreDevtool('versionStore', useVersionStore)
}
