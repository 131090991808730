import { Access, useAuth } from '@bty/react-auth'
import { useRequest } from 'ahooks'
import { Spin, message } from 'antd'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import {
  forkApplication,
  getApplicationsList,
  overWriteApplication,
} from '@apis/application'
import type { ApplicationBodyType, GroupType } from '@apis/application/type'
import { RoleCode } from '@apis/authority/type'
import { Button, IconFont } from '@/components'
import {
  ResponsiveCard,
  ResponsiveCardGrid,
} from '@/components/list/ResponsiveCardGrid.tsx'
import {
  AGENT_GROUP,
  AGENT_LIST_SCROLL_TOP,
  AGENT_LIST_SEARCH,
} from '@/constants/storage'
import { DragDiv, GroupWrap, noGroupId, useGroup } from '@/hooks/useGroup'
import { useModal } from '@/hooks/useModal.tsx'
import { AgentCreateModal } from '@/pages/agent/list/AgentCreateModal.tsx'
import { AgentItem } from '@/pages/agent/list/AgentItem.tsx'
import Empty from '@/pages/application/components/Empty'
import { useWorkspaceSelectModal } from '@/pages/application/components/WorkSpaceSelect.tsx'
import { useWorkspaceStore } from '@/store'
import { AppDropDown } from '../components/AppDropDown'
import { NewGroupModal } from '@/features/home/NewGroupModal'
import { useCoverModal } from '../components/CoverModal'
import { SelectType } from '@/pages/application/components/ShowConnectContent'
import { useCache } from '@/hooks/useCache'
import { LimitedAlertBar } from '@/features/pay/LimitedAlert'
import { LimitedAccess } from '@/features/pay/LimitedAccess'
import { useBackBefore, useScrollBar } from '@/hooks/useScrollBar'
import { PageHeader } from '@/pages/home/PageHeader'

export function AgentList() {
  const navigate = useNavigate()
  const { access } = useAuth()
  const [searchName, setSearchName] = useCache(AGENT_LIST_SEARCH, '')
  const [open, setOpen] = useState(false)
  const currentItemRef = useRef<Partial<ApplicationBodyType>>({})
  const currentWorkspaceId = useWorkspaceStore(
    state => state.currentWorkspaceId,
  )
  const { setCurrentWorkspace } = useWorkspaceStore()
  const { runAsync: forkApplicationApi } = useRequest(forkApplication, {
    manual: true,
  })
  const { runAsync: overWriteApplicationApi } = useRequest(
    overWriteApplication,
    {
      manual: true,
    },
  )
  const [data, setData] = useState<ApplicationBodyType[]>([])

  const { runAsync, loading } = useRequest(
    () => getApplicationsList(currentWorkspaceId, { applicationType: 'AGENT' }),
    {
      ready: !!currentWorkspaceId,
      refreshDeps: [currentWorkspaceId],
      onSuccess: (e: ApplicationBodyType[]) => setData(e),
    },
  )
  const [createAgentModal] = useModal(AgentCreateModal)
  const onAgentCreate = (groupId?: string) => {
    createAgentModal.open({
      access,
      agentList: data,
      groupId,
      onCancel: runAsync,
    })
  }

  const {
    list,
    createGroup,
    updateGroup,
    groupLoading,
    DragType,
    setDragType,
    dragItem,
    setDragItem,
    dragOverItem,
    setDragOverItem,
    phantomItem,
    handleDragStart,
    handleDrop,
    removeToGroup,
    DropdownMenu,
    handleGroup,
    reSet,
    openGroupMap,
    setOpenGroupMap,
    openAllGroup,
  } = useGroup({
    originList: data,
    applicationType: 'AGENT',
    refresh: runAsync,
    groupType: AGENT_GROUP,
  })
  const handleFork = async (workspaceId: string, selectedConnect?: boolean) => {
    const res = await forkApplicationApi({
      source_appid: currentItemRef.current?.id || '',
      source_workspace_id: currentWorkspaceId,
      target_workspace_id: workspaceId,
      is_force_fork: selectedConnect,
    })
    setOpenGroupMap({ ...openGroupMap, [res?.app_group_id]: true })
    window.open(
      `/agent/${res.application_id}?forkTaskId=${res.task_id}`,
      '_blank',
    ) // @todo 跳转到agent详情
    onForkSuccess()
    setCurrentWorkspace(workspaceId)
  }
  const [workspaceSelectModal] = useWorkspaceSelectModal({
    onConfirm: handleFork,
    defaultWorkspaceId: currentWorkspaceId,
    showConnectComponent: true,
    appId: currentItemRef.current?.id,
    copyType: '复制Agent',
    checkLimit: 'app',
  })
  const handleCoverAgent = async (
    source_appid: string,
    target_appid?: string,
  ) => {
    await overWriteApplicationApi({
      source_appid,
      target_appid: target_appid || '',
    })
    message.success('覆盖成功')
    onOverSuccess()
  }
  const [coverModal] = useCoverModal({
    onConfirm: handleCoverAgent,
    dataList: data,
    coverText: '覆盖Agent',
    selectType: SelectType.AGENT,
    appId: currentItemRef.current.id || '',
  })
  function onOverSuccess() {
    coverModal.close()
  }
  function onForkSuccess() {
    workspaceSelectModal.close()
  }
  const [group, setGroup] = useState<Partial<GroupType>>({}) // 重命名分组
  const [openDropdownMenuId, setOpenDropdownMenuId] = useState('')

  useEffect(() => {
    !!openDropdownMenuId && setOpen(false)
  }, [openDropdownMenuId])

  useEffect(() => {
    open && setOpenDropdownMenuId('')
  }, [open])

  const renderCreateButton = (showLine = true) => (
    <Access
      access={access.role([RoleCode.DEVELOPER, RoleCode.ADMINISTRATOR])}
      hide
    >
      <LimitedAccess limitedType='agent'>
        {showLine && <div className='h-20px mr-8px w-1px bg-#E1E1E5'></div>}
        <Button
          type='primary'
          onClick={() => onAgentCreate()}
          icon={<IconFont name='fangtai' />}
        >
          新建 Agent
        </Button>
      </LimitedAccess>
    </Access>
  )

  const filterSearch = (list: ApplicationBodyType[]) =>
    list?.filter((item: any) =>
      item.name.toLowerCase().includes?.(searchName?.toLowerCase()),
    )

  const nothing = useMemo(() => {
    const filterList = filterSearch(data || [])
    return (
      (list.length <= 1 && filterList.length <= 0) ||
      (searchName && filterList.length === 0)
    )
  }, [list, data, searchName])

  const { scrollRef, scrollBar } = useScrollBar()

  useBackBefore(scrollBar, AGENT_LIST_SCROLL_TOP, [!!list.length])

  const innerLoading = loading && groupLoading

  return (
    <div className='w-full h-full'>
      <div className='adapt:pt-40 flex h-full bg-bg border-rd-12px flex flex-col relative'>
        {innerLoading && (
          <div className='flex-center absolute top-0 left-0 h-full w-full'>
            <Spin />
          </div>
        )}
        <PageHeader
          title='Agent'
          searchKey={searchName}
          renderCreate={() => (
            <Access
              access={access.role([RoleCode.DEVELOPER, RoleCode.ADMINISTRATOR])}
              hide
            >
              <LimitedAccess limitedType='agent'>
                <Button
                  className='ml-12'
                  type='primary'
                  onClick={() => onAgentCreate()}
                  icon={<IconFont name='fangtai' />}
                >
                  新建 Agent
                </Button>
              </LimitedAccess>
            </Access>
          )}
          showCreateGroup
          onSearch={searchKey => {
            setSearchName(searchKey || '')
            openAllGroup()
          }}
          onGroupCreate={createGroup}
        />
        <div ref={scrollRef} className='adapt:px-64 h-full overflow-y-scroll'>
          <div>
            {!innerLoading && (
              <>
                <LimitedAlertBar className='mt-20px' />
                <GroupWrap>
                  {list?.map((item, index) => {
                    const filteredChildren = filterSearch(item.children || [])
                    return (
                      ((searchName && filteredChildren.length > 0) ||
                        !searchName) && (
                        <div
                          className={classNames(
                            'group-item px-4 py-0',
                            dragItem.id === item.id ? 'op-40' : 'op-100',
                            {
                              'bg-primary bg-op-4 border-2px border-solid border-primary rounded-8px':
                                dragOverItem.id === item.id &&
                                DragType === 'join',
                              'border-t-2px border-t-solid border-t-primary':
                                dragOverItem.id === item.id &&
                                DragType === 'sort',
                            },
                          )}
                          key={item.id}
                          onDragOver={e => {
                            e.preventDefault()
                            item.id !== dragItem?.id && setDragOverItem(item)
                          }} // 解决拖拽结束后渲染滞后的问题
                          onDrop={e => handleDrop(e, item)}
                          onDragEnd={() => reSet()}
                        >
                          {list.length !== 1 &&
                          (item.id !== noGroupId ||
                            (item.id === noGroupId &&
                              (item.children || []).length > 0)) ? (
                            <DragDiv
                              open={openDropdownMenuId === item.id}
                              onClick={() =>
                                setOpenGroupMap({
                                  ...openGroupMap,
                                  [item.id]: !openGroupMap[item.id],
                                })
                              }
                              className='group-item-header cursor-pointer flex gap-8px flex-items-center relative py-20px'
                            >
                              {index !== list.length - 1 && (
                                <span
                                  className='cursor-grab p-7px dragBtn absolute left--24px'
                                  onMouseDown={() => setDragType('join')}
                                  onMouseUp={() => setDragType(null)}
                                  onDragEnd={() => setDragItem({})}
                                  draggable={
                                    !!DragType && index !== list.length - 1
                                  }
                                  onDragStart={e =>
                                    handleDragStart({
                                      item,
                                      DragType: 'sort',
                                      e,
                                    })
                                  }
                                >
                                  {phantomItem.id === item.id ? (
                                    <div
                                      className='bg-primary px-24px py-12px text-14px font-600 border-rd-6px color-#fff'
                                      key={item.id}
                                    >
                                      移动一个分组
                                    </div>
                                  ) : (
                                    <IconFont
                                      onClick={e => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                      }}
                                      name='drag'
                                      style={{
                                        color: 'rgba(98, 105, 153, 0.6)',
                                        boxSizing: 'content-box',
                                      }}
                                    />
                                  )}
                                </span>
                              )}
                              {phantomItem.id !== item.id && (
                                <div className='flex gap-4px'>
                                  <IconFont
                                    name='arrow-1'
                                    style={{
                                      transition: 'all .2s',
                                      transform: openGroupMap[item.id]
                                        ? 'rotate(0deg)'
                                        : 'rotate(-90deg)',
                                    }}
                                  />
                                  <div className='text-16px font-600'>
                                    {item.name}
                                  </div>
                                </div>
                              )}
                              {index !== list.length - 1 && (
                                <DropdownMenu
                                  open={openDropdownMenuId === item.id}
                                  openChange={setOpenDropdownMenuId}
                                  item={item}
                                  title={'Agent'}
                                  createAppToAgent={() =>
                                    onAgentCreate(item.id)
                                  }
                                  handleReName={e => {
                                    setGroup(e)
                                    setOpen(true)
                                  }}
                                />
                              )}
                            </DragDiv>
                          ) : (
                            <div className='h-20px'></div>
                          )}
                          {openGroupMap[item.id] &&
                            filteredChildren.length > 0 && (
                              <ResponsiveCardGrid className='flex-1 content-start relative w-full items-start pb-20'>
                                {filteredChildren.map(item => (
                                  <ResponsiveCard
                                    onDragStart={e =>
                                      handleDragStart({
                                        item,
                                        DragType: 'join',
                                        e,
                                      })
                                    }
                                    onDragEnd={() => reSet()}
                                    draggable={true}
                                    className='flex justify-center flex-items-center relative'
                                    style={{
                                      opacity:
                                        dragItem.id === item.id ? 0.4 : 1,
                                    }}
                                    key={item.id}
                                  >
                                    {phantomItem.id === item.id && (
                                      <div
                                        className='bg-primary px-24px py-12px text-14px font-600 border-rd-6px color-#fff h-38px w-100% whitespace-nowrap'
                                        key={item.id}
                                      >
                                        移动一个Agent
                                      </div>
                                    )}
                                    <AgentItem
                                      style={{
                                        visibility:
                                          phantomItem.id === item.id
                                            ? 'hidden'
                                            : 'visible',
                                      }}
                                      onClick={() =>
                                        navigate(`/agent/${item.id}`)
                                      }
                                      buttonWrapper={({ setOpen }) =>
                                        phantomItem.id !== item.id ? (
                                          <Access
                                            access={access.role([
                                              RoleCode.DEVELOPER,
                                              RoleCode.ADMINISTRATOR,
                                            ])}
                                            hide
                                          >
                                            <AppDropDown
                                              openChange={setOpen}
                                              removeToGroup={removeToGroup}
                                              handleDelete={() =>
                                                runAsync().then(handleGroup)
                                              }
                                              app={item}
                                              handleFork={() => {
                                                currentItemRef.current = item
                                                workspaceSelectModal.open()
                                              }}
                                              handleCover={() => {
                                                currentItemRef.current = item
                                                coverModal.open()
                                              }}
                                            />
                                          </Access>
                                        ) : (
                                          <></>
                                        )
                                      }
                                      data={item}
                                    />
                                  </ResponsiveCard>
                                ))}
                              </ResponsiveCardGrid>
                            )}
                        </div>
                      )
                    )
                  })}
                  {!!nothing &&
                    (!access.role([
                      RoleCode.DEVELOPER,
                      RoleCode.ADMINISTRATOR,
                    ]) ? (
                      <Empty />
                    ) : (
                      <Empty viewer={true} desc='暂无 Agent'>
                        {renderCreateButton(false)}
                      </Empty>
                    ))}
                </GroupWrap>
              </>
            )}
          </div>
        </div>
      </div>
      <NewGroupModal
        open={open}
        initValue={{ groupName: group.name }}
        title={group?.id ? '分组重命名' : undefined}
        onCancel={() => {
          setOpen(false)
          setGroup({})
        }}
        onOk={async e => {
          if (group.id) {
            await updateGroup({ id: group.id, AppGroup: { name: e } })
          }
          setOpen(false)
          setGroup({})
        }}
      />
    </div>
  )
}
