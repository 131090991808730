import type { HttpInjectInfo } from './type'
import { defaultGetResult, HttpDefine } from './type'

export async function http<R = any>(
  define: HttpDefine,
  inject?: HttpInjectInfo,
): Promise<R> {
  let sureDefined = define
  if (inject?.onRequest) {
    sureDefined = await inject.onRequest(sureDefined)
  }

  const url = HttpDefine.getUrl(sureDefined)
  const headers = HttpDefine.getHeader(sureDefined)
  const body = HttpDefine.getBody(sureDefined)

  const fetchOption: RequestInit = {
    method: (sureDefined.method || 'get').toUpperCase(),
    headers,
  }

  // 设置任务超时时间
  if (sureDefined.timeout) {
    const abort = define.abort || new AbortController()

    window.setTimeout(() => {
      abort.abort()
    }, sureDefined.timeout)

    fetchOption.signal = abort.signal
  }

  if (
    !['get', 'head', 'options', 'GET', 'HEAD', 'OPTIONS'].includes(
      fetchOption.method!,
    ) &&
    body != null
  ) {
    fetchOption.body = body
  }

  try {
    // 不能使用 Request 的形式，arms 会改写 fetch 导致 header 丢失
    const response = await fetch(url, fetchOption)
    if (inject?.onResponse) {
      await inject.onResponse(response, define)
    }

    return (inject?.getResult ?? defaultGetResult)(response, define)
  } catch (error) {
    if (inject?.onError) {
      inject.onError(error as Error, define)
    }
    throw error
  }
}
