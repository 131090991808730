import { Alert } from 'antd'
import { SliderSingle } from '@/components'
import type { KnowledgeDataValue } from '../../KnowledgeNode'

export default (props: {
  value?: KnowledgeDataValue['maxResultNum']
  onChange?: (values: KnowledgeDataValue['maxResultNum']) => void
}) => {
  const { value, onChange } = props
  return (
    <div className='flex flex-col justify-between'>
      <SliderSingle
        min={1}
        max={30}
        size='small'
        step={1}
        controls={false}
        value={value}
        onChange={e => onChange?.(Number(e))}
      />
      {value && value > 10 && (
        <Alert
          className='mt-8px b-0px text-12px'
          showIcon
          message={
            <p className='font-400 text-12px/16px c-#17171D'>
              建议最大结果数不超过10，避免超出大模型支持的最大Token数
            </p>
          }
          type='warning'
        />
      )}
    </div>
  )
}
